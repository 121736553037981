import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';
import { useSelector } from 'react-redux';
import TravelHistory from '../common/travelHistory';
import { useState } from 'react';
import { getUploadedImage } from '../../../redux-reducer/reducer';
const MeetingScheduledStatusHistory = (props) => {
    const { history, ActivityIDlabel, ActivityIDlabelAgency } = props;
    const [fileData, setfileData] = useState(null)
    const [loader, setLoader] = useState(true)
    const historyDate = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('DD MMM YYYY') : ''
    const historyTime = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('h:mma') : ""
    const remarks = history.Remarks ? history.Remarks : "N/A";
    // const ExpectedLeadClosureDate = history.ExpectedLeadClosureDate ? history.ExpectedLeadClosureDate : null;
    const commonState = useSelector(state => state);
    
    const userState = commonState.userReducer;
    const getBase64Data = async () => {
        if (history.UploadBillFileName) {
            setLoader(false)
            const res = await getUploadedImage(history.UploadBillFileName);
            if (res.status === 200 && res.data) {
                setfileData(`${"data:image/png;base64,"}${res.data}`);
                var image = new Image();
                image.src = "data:image/jpg;base64," + res.data;
                var w = window.open("");
                w.document.write(image.outerHTML);
                setLoader(true)
            } else {
                setLoader(true)
            }
            setLoader(true)
        }
        setLoader(true)
    };
    return <>
        {history.LeadStatus === "Meeting Scheduled" ?
            <>
                {userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 && <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        {userState.profileInfo.channelid === 3 &&
                            <label>{ActivityIDlabelAgency}</label>
                        }
                        {userState.profileInfo.channelid !== 3 &&
                            <label>{ActivityIDlabel}</label>
                        }
                    </TimelineContent>
                </TimelineItem>}
                {userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 &&
                    <TimelineItem sx={props.styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Scheduled Time</label>
                            <label>
                                {historyDate} {' '} {historyTime}
                            </label>
                        </TimelineContent>
                    </TimelineItem>}

                {/* {
                    (userState.profileInfo.channelid === 1 && ExpectedLeadClosureDate != null) &&
                    <TimelineItem sx={props.styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Expected lead closure date</label>
                            <label>
                                {moment(history.ExpectedLeadClosureDate).format('DD MMM YYYY')}
                            </label>
                        </TimelineContent>
                    </TimelineItem>
                } */}
                {(userState.profileInfo.channelid == 13 || userState.profileInfo.channelid == 79) &&
                    <TimelineItem sx={props.styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Scheduled Time</label>
                            <label>
                                {historyDate} {' '} {historyTime}
                            </label>
                        </TimelineContent>
                    </TimelineItem>}
                {(userState.profileInfo.channelid === 13 || userState.profileInfo.channelid === 79) &&
                    <TimelineItem sx={props.styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Duration</label>
                            <label>
                                {history.GCLCallDuration}
                            </label>
                        </TimelineContent>
                    </TimelineItem>
                }
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Remarks</label>
                        <label>
                            {remarks}
                        </label>
                    </TimelineContent>
                </TimelineItem>
                {(userState.profileInfo.channelid !== 13 || userState.profileInfo.channelid !== 79) && history.IsClaimRequired &&
                    <TravelHistory history={history} styletimeline={props.styletimeline} />
                }
                {(userState.profileInfo.channelid !== 13 || userState.profileInfo.channelid !== 79) && history.UploadBill &&
                    <TimelineItem sx={props.styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            {loader == true ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
                            {
                                fileData &&
                                <img className='bill-copy' alt="uploaded bill" src={fileData} />
                            }
                        </TimelineContent>
                    </TimelineItem>
                }
            </>
            : ''
        }
    </>
}

export default MeetingScheduledStatusHistory
