
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';

const TravelHistory = (props) => {
    const { history } = props
    const { TravelExpense, TransportMode, TravelDistance, StartLocation, EndLocation,IsClaimRequired } = history

    return (
        <>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Do you want claim expense</label>
                    <label>
                        {IsClaimRequired?"Yes":"No"}
                    </label>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Mode Of Transport</label>
                    <label>
                        {TransportMode}
                    </label>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Travel Expense(In Rupees)</label>
                    <label>
                        {TravelExpense}
                    </label>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Travel Distance(kms)</label>
                    <label>
                        {TravelDistance}
                    </label>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Start Location</label>
                    <label>
                        {StartLocation}
                    </label>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>End Location</label>
                    <label>
                        {EndLocation}
                    </label>
                </TimelineContent>
            </TimelineItem>
        </>
    )
}

export default TravelHistory
