const EDIT_SALES_MANAGEMENT_ACTIVITY = 'EDIT_SALES_MANAGEMENT_ACTIVITY';
const EDIT_SALES_MANAGEMENT_ACTIVITY_COMPLETE = 'EDIT_SALES_MANAGEMENT_ACTIVITY_COMPLETE';
const EDIT_SALES_MANAGEMENT_ACTIVITY_CANCEL = 'EDIT_SALES_MANAGEMENT_ACTIVITY_CANCEL';
const EDIT_SALES_MANAGEMENT_ACTIVITY_UPDATE = 'EDIT_SALES_MANAGEMENT_ACTIVITY_UPDATE';


const initialState = {
    activityName:"Complete",
    activityData:[],
    activityComplete:false,
    activityCancelled:false,
    activityReshedule:false
}

function updateSalesManagementActivityReducer(state = initialState, action) {
    switch (action.type) {
        case EDIT_SALES_MANAGEMENT_ACTIVITY:
            return{
                ...state, activityName: action.payload, activityData: action.payloadEle
            }
        case EDIT_SALES_MANAGEMENT_ACTIVITY_COMPLETE:
            return {
                ...state,
                activityComplete:action.payload,
                activityCancelled:false,
                activityReshedule:false,
            };
        case EDIT_SALES_MANAGEMENT_ACTIVITY_CANCEL:
            return {
                ...state,
                activityComplete:false,
                activityCancelled:action.payload,
                activityReshedule:false,
            };
        case EDIT_SALES_MANAGEMENT_ACTIVITY_UPDATE:
            return {
                ...state,
                activityComplete:false,
                activityCancelled:false,
                activityReshedule:action.payload,
            };
        // case "activitydata":
        //     return {
        //         ...state,
        //         activitydata:action.payload,
        //     };
        default:
            return state;
    }
}

export default updateSalesManagementActivityReducer;