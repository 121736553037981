import { CUSTOM_FIELDSET } from "../../../../utils/config";
import { isAlphabetOnly, isEmailValid, numberStartWith6789Validator } from "../../../../utils/validators";

export const customValidators = [
    {
        key: 'FirstName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'LastName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'MiddleName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'Email',
        validator: isEmailValid,
        message: 'invalid email id with missing domain name, @, .com, gmail id etc.'
    },
    {
        key: 'MobileNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
    }
]
export const resetField = (formFields) => {
    for (const [index] of formFields.entries()) {
        if (index == 0) {
            continue;
        }
        formFields[index].hide = true
    }
    return formFields;
}
export const BrocaLeadForm = [
    {
        "fieldSetClassName": CUSTOM_FIELDSET,
        "fieldSetLabel": "Lead Personal Details",
        "fieldSetLabelType": "accordian",
        "fielsetId": "fieldset1",
        "columns": [
            {

                "rows": [

                    [
                        {
                            "colmd": "12", "key": "SalutationID", "type": "select", "disabled": false, "id": "SalutationID", "label": "Salutation",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "FirstName", "type": "text", "id": "FirstName", "label": "First Name",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "MiddleName", "type": "text", "id": "MiddleName", "label": "Middle Name",
                            "validators": [
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "LastName", "type": "text", "id": "LastName", "label": "Last Name",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required" },
                                { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
                                { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "MobileNo", "type": "number", "id": "MobileNo", "label": "Mobile No.",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required." },
                                { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
                                { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
                        },
                        {
                            "colmd": "12", "key": "Email", "type": "email", "id": "Email", "label": "Email", "hide": false,
                            "validators": [
                                { "name": "required", "value": true }, { "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }
                            ]
                        },
                        {
                            "colmd": "12", "key": "CampaigningDoneId", "hide": false, "type": "select", "disabled": false, "id": "CampaigningDoneId", "label": "Activity Type", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select activity type" }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "ActivityID", "hide": false, "type": "autocomplete", "disabled": false, "id": "ActivityID", "label": "Activity ID", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select activity" }],
                            "options": []
                        },
                        {
                            "key": "PriorityID", "type": "select", "disabled": false, "id": "PriorityID", "label": "Lead Status", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select lead status." }],
                            "options": []
                        }
                    ],]
            },
        ]

    }
];
export const getFormTitle = () => {
    return 'BROCA Lead';
};
export const getForm = () => {
    return BrocaLeadForm;
};


