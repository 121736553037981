import {
  CLAIM_TRAVEL_EXPENCE_OPTIONS, ACTIVITY_RESCHEDULED,
  ACTIVITY_CANCELLED, ACTIVITY_COMPLETED, CONFIGS, ROLE_BDM, ALLOWED_FUTURE_DAYS, ACTIVITY_UPDATE_CALLS
} from "../../../../utils/config";

import { ActivityCompletedMeetingVisitForm } from './jsonForms/completeActivity/activityCompleteMeetingVisit';

import { format } from 'date-fns';

import { getDateAfterDays } from "../../../../utils/utils";

const MeetingVisit = "Meeting/Visit";
const MonthlyReview = "Monthly Review";
const Training = "Training";
const CallingToVLE = "Calling to VLE/RAP/DM"
const dateFormat = 'yyyy-MM-dd';
const max100Charmessage = "Maximum 100 charactors allowed.";
const claimtravelExpenseLabel = "Do You Want to Claim Travel Expense?";
const optionSelectValidationMessage = "Please select one option.";
const cssBorder = "1px solid #ddd";
const cssactiveBoxShadow = "0px 0px 1px 2px #fffc35";
const modeOfTransportLabel = "Mode of Transport";
const travelExpenseLabel = "Travel Expense";
const travelExpenseValidationmessage = "Travel expense is required.";
const fourDigitAllowed = "Maximum 4 digits allowed";
const fiveDigitAllowed = "Maximum 5 digits allowed";
const billPhoto = "Upload bill photo";
const uploadBill = "Please Upload Bill Copy";
const acceptFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const vehicleType = "Vehicle Type";
const modeOftransportRequired = "mode of Trasport is required.";
const DateTimeLocalType = "datetime-local";
const RescheduleMeetingStartDateAndTimeLabel = "Reschedule Meeting Start Date & Time";
const MeetingStartTimeRequiredValidationmessage = "Meeting start time is required.";
const RescheduleMeetingEndDateAndTimeLabel = "Reschedule Meeting End Date & Time";
const MeetingEndTimeRequiredValidationmessage = "Meeting end time is required.";
const ReasonRequiredmessage = "Reason is required.";
const ThisFieldIsRequiredMessage = "This field is required.";

export const ActivityRescheduledForm = [
  {
    "key": "MeetingStartDateTime",
    "hide": false, "type": DateTimeLocalType,
    "disabled": false,
    "id": "MeetingStartDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T${"23:59"}`,
    "label": RescheduleMeetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": MeetingStartTimeRequiredValidationmessage }]
  },
  {
    "key": "MeetingEndDateTime",
    "hide": false,
    "type": DateTimeLocalType, "disabled": false, "id": "MeetingEndDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T${"23:59"}`,
    "label": RescheduleMeetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": MeetingEndTimeRequiredValidationmessage }]
  },
  {
    "key": "Reason",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Reason",
    "label": "Reason",
    "validators": [{ "name": "required", "value": true, "validationMessage": ReasonRequiredmessage },
    { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  {
    "key": "Remarks",
    "hide": true,
    "type": "text",
    "disabled": false,
    "id": "Remarks",
    "label": "Remarks",
    "validators": [{ "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]

export const ActivityRescheduledCallingToVLEForm = [
  {
    "key": "MeetingStartDateTime",
    "hide": false, "type": DateTimeLocalType,
    "disabled": false,
    "id": "MeetingStartDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T${"23:59"}`,
    "label": RescheduleMeetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": MeetingStartTimeRequiredValidationmessage }]
  },
  {
    "key": "MeetingEndDateTime",
    "hide": false,
    "type": DateTimeLocalType, "disabled": false, "id": "MeetingEndDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T${"23:59"}`,
    "label": RescheduleMeetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": MeetingEndTimeRequiredValidationmessage }]
  },
  {
    "key": "Reason",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Reason",
    "label": "Reason",
    "validators": [{ "name": "required", "value": true, "validationMessage": ReasonRequiredmessage },
    { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  }
]

export const ActivityRescheduledFormPart4 = [
  {
    "key": "MeetingStartDateTime",
    "hide": false, "type": DateTimeLocalType,
    "disabled": false,
    "id": "MeetingStartDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T${"23:59"}`,
    "label": RescheduleMeetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": MeetingStartTimeRequiredValidationmessage }]
  },
  {
    "key": "MeetingEndDateTime",
    "hide": false,
    "type": DateTimeLocalType, "disabled": false, "id": "MeetingEndDateTime",
    "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
    "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T${"23:59"}`,
    "label": RescheduleMeetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": MeetingEndTimeRequiredValidationmessage }]
  },
  {
    "key": "Reason",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Reason",
    "label": "Reason",
    "validators": [{ "name": "required", "value": true, "validationMessage": ReasonRequiredmessage },
    { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  }
]

export const ActivityRescheduledFormPart1 = [
  {
    "key": "TransportModeID",
    "type": "radio",
    "label": modeOfTransportLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": "Mode of transport is required." }], "options": []
  },

]

export const ActivityRescheduledFormPart2 = [
  {
    "key": "TravelExpence",
    "hide": false,
    "type": "number",
    "disabled": false,
    "id": "TravelExpence",
    "label": travelExpenseLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage }, {
      "name": "maxLength",
      "value": 4,
      "validationMessage": fourDigitAllowed
    }]
  },
  {
    "key": "UploadBill",
    "name": "UploadBill",
    "hide": false,
    "type": "file",
    "dropTitle": billPhoto,
    "acceptFileTypes": acceptFileType,
    "componentType": "DRAG_DROP_BROWSE",
    "layout": "1column",
    "restrictMultipleFiles": true,
    "disabled": false,
    "id": "UploadBill",
    "label": uploadBill,
    "validators": []
  }
]
export const ActivityRescheduledFormPart3 = [
  {
    "key": "VehicleTypeID",
    "hide": false,
    "type": "radio",
    "disabled": false,
    "id": "VehicleType",
    "label": vehicleType,
    "validators": [{ "name": "required", "value": true, "validationMessage": modeOftransportRequired }], "options": []
  },
]
//Activity Cancel JSON start
export const ActivityCancelledForm = [
  {
    "key": "Reason",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Reason",
    "label": "Reason for Cancellation",
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Reason for cancellation is required." },
      { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  {
    "key": "Remarks",
    "hide": true,
    "type": "text",
    "disabled": false,
    "id": "Remarks",
    "label": "Remarks",
    "validators": [{ "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
  },
  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]

export const ActivityCancelledCallingToVLEForm = [
  {
    "key": "ReasonForCancellation",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "ReasonForCancellation",
    "label": "Reason for Cancellation",
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Reason for cancellation is required." },
      { "name": "maxLength", "value": 50, "validationMessage": "Maximum 50 charactors allowed." }]
  }
]

export const ActivityCancelledMonthlyReviewForm = [
  // {
  //   "key": "PremiumAmount",
  //   "props": { "min": "100", "max": "99999" }, "hide": false, "type": "number", "disabled": false, "id": "PremiumAmount", "label": "Premium Amount", "validators": [
  //     {
  //       "name": "required",
  //       "value": true,
  //       "validationMessage": "Premium Amount is required."
  //     },
  //     { "name": "maxLength", "value": 5, "validationMessage": fiveDigitAllowed }]
  // },
  // {
  //   "key": "NoOfProposals",
  //   "hide": false, "type": "number",
  //   "disabled": false,
  //   "id": "NoOfProposals",
  //   "label": "Number of Proposals",
  //   "props": { "min": "0", "max": "99999" },
  //   "validators": [
  //     {
  //       "name": "required",
  //       "value": true,
  //       "validationMessage": "# of Leads is required."
  //     },
  //     { "name": "maxLength", "value": 5, "validationMessage": fiveDigitAllowed }]
  // },
  // {
  //   "key": "RAPActivated",
  //   "hide": false, "type": "number",
  //   "disabled": false,
  //   "id": "RAPActivated",
  //   "label": "RAP activated",
  //   "props": { "min": "10", "max": "99999" },
  //   "validators": [
  //     {
  //       "name": "required",
  //       "value": true,
  //       "validationMessage": "# of Leads is required."
  //     },
  //     { "name": "maxLength", "value": 5, "validationMessage": fiveDigitAllowed }]
  // },
  {
    "key": "ReasonForCancellation",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "ReasonForCancellation",
    "label": "Reason for Cancellation",
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Reason for cancellation is required." },
      { "name": "maxLength", "value": 50, "validationMessage": "Maximum 50 charactors allowed." }]
  },
  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]

export const ActivityCancelledFormPart1 = [
  {
    "key": "TransportModeID",
    "type": "radio",
    "label": modeOfTransportLabel,
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Mode of transport is required." }]
    , "options": []
  }
]

export const ActivityCancelledFormPart2 = [
  {
    "key": "TravelExpence",
    "hide": false,
    "type": "number",
    "disabled": false,
    "id": "TravelExpence",
    "label": travelExpenseLabel,
    "validators": [
      { "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage },
      { "name": "maxLength", "value": 4, "validationMessage": fourDigitAllowed }]
  },
  {
    "key": "UploadBill",
    "name": "UploadBill",
    "hide": false,
    "type": "file",
    "dropTitle": billPhoto,
    "acceptFileTypes": acceptFileType,
    "componentType": "DRAG_DROP_BROWSE",
    "layout": "1column",
    "restrictMultipleFiles": true,
    "disabled": false,
    "id": "UploadBill",
    "label": uploadBill,
    "validators": []
  }
]
export const ActivityCancelledFormPart3 = [{
  "key": "VehicleTypeID",
  "hide": false,
  "type": "radio",
  "disabled": false,
  "id": "VehicleType",
  "label": vehicleType,
  "validators": [{
    "name": "required",
    "value": true,
    "validationMessage": modeOftransportRequired
  }], "options": []
},
]
//Activity Cancel JSON End

//Activity Complet JSON start
export const ActivityCompletedForm = [
  {
    "key": "PremiumAmount",
    "props": { "min": "100", "max": "99999" }, "hide": false, "type": "number", "disabled": false, "id": "PremiumAmount", "label": "Premium Amount", "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": "Premium Amount is required."
      },
      { "name": "maxLength", "value": 5, "validationMessage": fiveDigitAllowed }]
  },
  {
    "key": "NoOfProposals",
    "hide": false, "type": "number",
    "disabled": false,
    "id": "NoOfProposals",
    "label": "Number of Proposals",
    "props": { "min": "0", "max": "99999" },
    "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": ThisFieldIsRequiredMessage
      },
      { "name": "maxLength", "value": 5, "validationMessage": fiveDigitAllowed }]
  },
  {
    "key": "RAPActivated",
    "hide": false, "type": "number",
    "disabled": false,
    "id": "RAPActivated",
    "label": "RAP Activated",
    "props": { "min": "10", "max": "99999" },
    "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": ThisFieldIsRequiredMessage
      },
      { "name": "maxLength", "value": 5, "validationMessage": fiveDigitAllowed }]
  },
  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]
//Training
export const ActivityCompletedTrainingForm = [
  {
    "key": "ActualNoOfTrainees",
    "name": "ActualNoOfTrainees",
    "props": { "min": "1" }, "hide": false, "type": "number", "disabled": false, "id": "ActualNoOfTrainees", "label": "Actual Number of Trainees", "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": "Actual number of trainees is required"
      },
      { "name": "maxLength", "value": 3, "validationMessage": "Maximum 3 digits allowed" }]
  }, {
    "key": "UploadTrainingAttendancePhoto1",
    "name": "UploadTrainingAttendancePhoto1",
    "hide": false,
    "type": "file",
    "dropTitle": "Training attendance photos",
    "acceptFileTypes": acceptFileType,
    "componentType": "DRAG_DROP_BROWSE",
    "layout": "1column", "restrictMultipleFiles": true, "disabled": false,
    "id": "UploadTrainingAttendancePhoto1",
    "label": "Training attendance photos",
    "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }]
  },

  {
    "key": "UploadTrainingAttendanceDocument1",
    "name": "UploadTrainingAttendanceDocument1",
    "hide": false,
    "type": "file",
    "dropTitle": "Training attendance Documents",
    "acceptFileTypes": acceptFileType,
    "componentType": "DRAG_DROP_BROWSE",
    "layout": "1column",
    "restrictMultipleFiles": false, "disabled": false,
    "id": "UploadTrainingAttendanceDocument1", "label": "Training attendance Documents",
    "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }]
  },


  {
    "key": "IsClaimRequired",
    "name": "IsClaimRequired",
    "type": "CustomSwitch",
    "disabled": false,
    "id": "IsClaimRequired",
    "label": claimtravelExpenseLabel,
    "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }
]

export const ActivityCompletedCallingToVLEForm = [
  {
    "key": "NumberOfCallsDone",
    "props": { "min": 0 },
    "hide": false, "type": "number", "disabled": true, "id": "NumberOfCallsDone", "label": "Number Of Calls Done", "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": "Number Of Calls Done is required."
      }
    ]
  },
  {
    "key": "CallingToVLEUploadPhoto",
    "name": "CallingToVLEUploadPhoto",
    "hide": false,
    "type": "file",
    "dropTitle": "Upload Photo",
    "acceptFileTypes": acceptFileType,
    "componentType": "DRAG_DROP_BROWSE",
    "layout": "1column",
    "restrictMultipleFiles": true, "disabled": false, "id": "CallingToVLEUploadPhoto", "label": "Upload Photo",
    "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }]
  }
]
export const ActivityCompletedFormPart1 = [
  {
    "key": "TransportModeID",
    "hide": false,
    "type": "radio",
    "disabled": false,
    "id": "ModeOfTransport",
    "label": modeOfTransportLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": modeOftransportRequired }], "options": []
  },
]

export const ActivityCompletedFormPart2 = [
  {
    "key": "TravelExpence",
    "hide": false,
    "type": "number",
    "disabled": false,
    "id": "TravelExpence",
    "label": travelExpenseLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage },
    { "name": "maxLength", "value": 4, "validationMessage": fourDigitAllowed }]
  },

  {
    "key": "UploadBill",
    "name": "UploadBill",

    "hide": false,
    "type": "file",
    "dropTitle": billPhoto,
    "acceptFileTypes": acceptFileType,
    "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": true, "disabled": false, "id": "UploadBill", "label": uploadBill, "validators": []
  }
]

export const ActivityCompletedFormPart3 = [{
  "key": "VehicleTypeID",
  "hide": false,
  "type": "radio",
  "disabled": false,
  "id": "VehicleType",
  "label": vehicleType,
  "validators": [{ "name": "required", "value": true, "validationMessage": modeOftransportRequired }], "options": []
},
]

export const ActivityUpdateCallsForm = [
  {
    "key": "CallingToVLERAPDM",
    "props": { "min": "1" },
    "hide": false, "type": "text",
    "disabled": false, "id": "CallingToVLERAPDM", "label": "Calling to VLE/RAP/DM", "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": ThisFieldIsRequiredMessage
      },
      { "name": "maxLength", "value": 50, "validationMessage": "Maximum 50 digits allowed" }]
  },
  {
    "key": "VLERAPDMMobile", "props": { "min": "1" },
    "hide": false, "type": "number", "disabled": false,
    "id": "VLERAPDMMobile", "label": "VLE/RAP/DM Mobile Number",
    "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": "VLE/RAP/DM Mobile Number is required."
      },
      { "name": "minLength", "value": 10, "validationMessage": "Min length for VLE/RAP/DM Mobile No is 10 digits" },
      { "name": "maxLength", "value": 10, "validationMessage": "VLE/RAP/DM Mobile number field require 10 digits." }]
  },
  {
    "key": "OutcomeOfCall", "props": { "min": "1" }, "hide": false, "type": "text", "disabled": false, "id": "OutcomeOfCall", "label": "Outcome of the call", "validators": [
      {
        "name": "required",
        "value": true,
        "validationMessage": ThisFieldIsRequiredMessage
      },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 digits allowed" }]
  }
]




//Activity Complet JSON End
export class ActivityFormBuilder {
  constructor(actionType, cscType) {
    this.actionType = actionType;
    this.cscType = cscType
  }

  getFormTitle = () => {
    let formTitle = ''
    switch (this.actionType) {
      case ACTIVITY_RESCHEDULED:
        formTitle = 'Activity Rescheduled';
        break;
      case ACTIVITY_CANCELLED:
        formTitle = 'Activity Cancelled';
        break;
      case ACTIVITY_COMPLETED:
        formTitle = 'Activity Completed';
        break;
      case ACTIVITY_UPDATE_CALLS:
        formTitle = 'Activity Update Calls';
        break;
      default:
        formTitle = 'Activity Completed';
        break;
    }
    return formTitle;
  }

  getForm = () => {
    let form = []
    switch (this.actionType) {
      case ACTIVITY_RESCHEDULED:
        if (this.cscType === "Calling to VLE/RAP/DM") {
          form = ActivityRescheduledCallingToVLEForm;
        }
        else {
          form = ActivityRescheduledForm;
        }
        break;
      case ACTIVITY_CANCELLED:
        switch (this.cscType) {
          case MeetingVisit:
            form = ActivityCancelledForm;
            break;

          case MonthlyReview:
            form = ActivityCancelledMonthlyReviewForm;
            break;
          case Training:
            form = ActivityCancelledForm;
            break;

          case CallingToVLE:
            form = ActivityCancelledCallingToVLEForm;
            break;
          default:
            break;
        }
        break;
      case ACTIVITY_COMPLETED:
        switch (this.cscType) {
          case MeetingVisit:
            form = ActivityCompletedMeetingVisitForm;
            break;
          case MonthlyReview:
            form = ActivityCompletedForm;
            break;
          case Training:
            form = ActivityCompletedTrainingForm;
            break;
          case CallingToVLE:
            form = ActivityCompletedCallingToVLEForm;
            break;
          default:
            break;
        }
        break;
      case ACTIVITY_UPDATE_CALLS:
        form = ActivityUpdateCallsForm;
        break;
      default:
        form = ActivityCompletedForm;
        break;
    }
    return form;
  }
}
export const initFormData = (formFields, masterDataState, actionType, cscType) => {
  const formData = [...formFields]
  switch (actionType) {
    case ACTIVITY_RESCHEDULED:
      if (cscType !== "Calling to VLE/RAP/DM") {
        formData[3].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
      }
      break;

    case ACTIVITY_CANCELLED:
      switch (cscType) {
        case MeetingVisit:
          formData[2].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
          break;
        case MonthlyReview:
          formData[1].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
          break;
        case Training:
          formData[1].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
          break;
        case CallingToVLE:
          break;
        default:
          break;
      }
      break;
    case ACTIVITY_COMPLETED:
      switch (cscType) {
        case MeetingVisit:
          formData[2].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
          break;
        case MonthlyReview:
          formData[3].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
          break;
        case Training:
          formData[3].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
          break;
        case CallingToVLE:
          break;
        default:
          break;
      }
      break;
    case ACTIVITY_UPDATE_CALLS:
      break;
    default:
      formData[3].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
      break;
  }
  return formData
}
export const removeSubArrayFromArray = (subArray, keyForSearch, arrOfObjects) => {
  let newArray = [...arrOfObjects]
  for (const object of subArray) {
    newArray = newArray.filter((item) => item[keyForSearch] !== object[keyForSearch])
  }
  return newArray
}
export const handleDropdownSelection = (event, key, formFields, masterDataState, actionType, cscType, role) => {
  let formData = [...formFields]
  switch (actionType) {
    case ACTIVITY_RESCHEDULED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityRescheduledFormPart1)
            formData[4].options = masterDataState['ModeofTransport']
          } else {
            const arrayToRemove1 = ActivityRescheduledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = ActivityRescheduledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = ActivityRescheduledFormPart1
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData)
          }
          break;
        case "TransportModeID":
          if (event.target.value === '1') {
            formData = formData.concat(ActivityRescheduledFormPart2);
            const arrayToRemove = ActivityRescheduledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === '2') {
            const arrayToRemove = ActivityRescheduledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            let roleValidation = [ROLE_BDM];

            if (!roleValidation.includes(cscType)) {
              formData = formData.concat(ActivityRescheduledFormPart3);
              formData.find((val, index) => {
                if (val.key === "VehicleTypeID") {
                  formData[index].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
                }
              })
            } else {
              const arrayToRemove1 = ActivityRescheduledFormPart3
              formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            }
          }
          else {
            const arrayToRemove = ActivityRescheduledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        default:
          break;
      }
      break;
    case ACTIVITY_CANCELLED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityCancelledFormPart1);
            formData[2].options = masterDataState['ModeofTransport']
          } else {
            const arrayToRemove = ActivityCancelledFormPart1
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

            const arrayToRemove1 = ActivityCancelledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

            const arrayToRemove2 = ActivityCancelledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData)
          }
          break;
        case "TransportModeID":
          if (event.target.value === '1') {
            formData = formData.concat(ActivityCancelledFormPart2);

            const arrayToRemove1 = ActivityCancelledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
          } else if (event.target.value === '2') {
            const arrayToRemove = ActivityCancelledFormPart2
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            let roleValidation = [ROLE_BDM];

            if (!roleValidation.includes(cscType)) {
              formData = formData.concat(ActivityCancelledFormPart3);
              formData.find((val, index) => {
                if (val.key === "VehicleTypeID") {
                  formData[index].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
                }
              })
            } else {
              const arrayToRemove1 = ActivityCancelledFormPart3
              formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            }
          }
          else {
            const arrayToRemove = ActivityCancelledFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        default:
          break;
      }
      break;
    case ACTIVITY_COMPLETED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            if (cscType === MeetingVisit) {
              formData = formData.concat(ActivityCompletedFormPart1);
              formData[2].options = masterDataState['ModeofTransport']
            } else {
              formData = formData.concat(ActivityCompletedFormPart1);
              formData[3].options = masterDataState['ModeofTransport']
            }
          } else {
            const arrayToRemove1 = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = ActivityCompletedFormPart2
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = ActivityCompletedFormPart1
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        case "TransportModeID":
          if (event.target.value === '1') {
            formData = formData.concat(ActivityCompletedFormPart2);
            const arrayToRemove = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === '2') {
            const arrayToRemove = ActivityCompletedFormPart2
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            let roleValidation = [ROLE_BDM];
            if (!roleValidation.includes(cscType)) {
              formData = formData.concat(ActivityCompletedFormPart3);
              formData.find((val, index) => {
                if (val.key === "VehicleTypeID") {
                  formData[index].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
                }
              })
            } else {
              const arrayToRemove1 = ActivityCompletedFormPart3
              formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            }
          }
          else {
            const arrayToRemove = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return formData
}
