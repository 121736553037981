import React, { useState, useEffect, useRef, Suspense } from "react";
import Grid from "@mui/material/Grid";
import "../../../components/listing/lisiting.scss";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";


import { useDispatch, useSelector } from "react-redux";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ChecklistFilter from "./checklistFilter";

import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { setABHByRBH } from "../../../redux-reducer/reducer/pehlaKadamReducer"; //setPehlaKdamOverview,setBDMChecklist
// import PehlaKadamListing from "../../../components/listing/pehlaKadamListing";
import { getAllBDMCheckList } from '../../../redux-reducer/reducer/index';

const PehlaKadamListing = React.lazy(() => import('../../../components/listing/pehlaKadamListing'));

export default function PehlaKadamBdmList() {
    const dispatch = useDispatch();
    const isOnline = useOnlineStatus();
    const formRef = useRef();
    const { getPehlaKdamOverview, getBDMChecklist, getABHByRBH } = useSelector(
        (pahleKdmData) => pahleKdmData.pehlaKadamReducer
    );
    // const { viewForStatus, GetBDMByABH } = useSelector(state => state.commonReducer)

    const { Data, TotalnoofRows } = getBDMChecklist;
    const [dataList, setDataList] = useState([]); //getExportDataFile

    const userData = JSON.parse(localStorage.getItem("persist:root"));

    const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
    const userId = JSON.parse(userData.userReducer).profileInfo.userId;
    const [state, setState] = React.useState({
        bottom: false,
    });
    const [filterApplied, setfilterApplied] = useState(false); //getExportDataFile

    // console.log(getPehlaKdamOverview,getABHByRBH)
    // let userId;
    // if (roleId === 'RBH') {
    //      userId = getABHByRBH ? getABHByRBH : 0;
    // } else {
    //      userId = JSON.parse(userData.userReducer).profileInfo.userId;
    // }

    const [NoOfRecordperPage, setNoOfRecordperPage] = useState(5);
    const [filterState, setfilterState] = useState({
        BDMUserId: '',
        CheckListType: 0,
        Status: '',
    });
    // console.log(GetBDMByABH,getABHByRBH,NoOfRecordperPage)

    const [checkListReq, setCheckListReq] = useState({
        BDMUserId: '',
        CheckListType: 0,
        Status: ""
    });

    const getChecklistOverviewSelection = (type) => {
        switch (type) {
            case "Pending":
                return {
                    UserId: userId,
                    PageNo: 1,
                    NoOfRecordperPage: NoOfRecordperPage,
                    filterReq: { ...checkListReq, Status: "Pending" },
                };
            case "Completed":
                return {
                    UserId: userId,
                    PageNo: 1,
                    NoOfRecordperPage: NoOfRecordperPage,
                    filterReq: { ...checkListReq, Status: "Completed" },
                };
            case "Not-Completed":
                return {
                    UserId: userId,
                    PageNo: 1,
                    NoOfRecordperPage: NoOfRecordperPage,
                    filterReq: { ...checkListReq, Status: "Not-Completed" },
                };
            case 'all':
            case '':
                return {
                    UserId: userId,
                    PageNo: 1,
                    NoOfRecordperPage: NoOfRecordperPage,
                    filterReq: { ...checkListReq, Status: "" },
                };
        }
    };
    useEffect(() => {
        if (isOnline) {
            const payload = getChecklistOverviewSelection(getPehlaKdamOverview);
            // console.log("firstMount", payload);
            setCheckListReq({ ...checkListReq, Status: payload.filterReq.Status });
            if (roleId === 'ABH') {
                dispatch(getAllBDMCheckList(payload.UserId, roleId,
                    payload.PageNo,
                    payload.NoOfRecordperPage,
                    payload.filterReq));
            }
            if (roleId === 'RBH') {
                const { BDMUserId, CheckListType, Status } = payload.filterReq
                const RBHpayload = {
                    // ABHUserId: `${BDMUserId ? BDMUserId : (getABHByRBH !== 0 ? getABHByRBH : '')}`,
                    CheckListType: CheckListType,
                    Status: Status,
                }
                dispatch(getAllBDMCheckList(
                    (`${BDMUserId ? BDMUserId : (getABHByRBH !== 0 ? getABHByRBH : userId)}`),
                    (BDMUserId ? 'ABH' : (getABHByRBH !== 0 ? 'ABH' : roleId))
                    , 1, 5, RBHpayload));

            }
        }
        // setDataList([]);

        return () => {
            // Anything in here is fired on component unmount.
            // dispatch(setPehlaKdamOverview("all"));
            if (roleId === 'RBH') {
                dispatch(setABHByRBH(0));
            }

        }
        

    }, []);
    useEffect(() => {
        setDataList(Data);

    }, [Data]);
    const loadMoreRecord = () => {
        const payload = {
            UserId: userId,
            PageNo: 1,
            NoOfRecordperPage: NoOfRecordperPage + 5,
            filterReq: {
                ...checkListReq,
            },
        };
        if (isOnline) {
            if (roleId === 'ABH') {
                dispatch(getAllBDMCheckList(payload.UserId, roleId,
                    payload.PageNo,
                    payload.NoOfRecordperPage,
                    payload.filterReq));
            }
            if (roleId === 'RBH') {
                const { BDMUserId, CheckListType, Status } = payload.filterReq
                const RBHpayload = {
                    // ABHUserId: `${BDMUserId ? BDMUserId : (getABHByRBH !== 0 ? getABHByRBH : '')}`,
                    CheckListType: CheckListType,
                    Status: Status,
                }
                dispatch(getAllBDMCheckList(
                    (`${BDMUserId ? BDMUserId : (getABHByRBH !== 0 ? getABHByRBH : userId)}`),
                    (BDMUserId ? 'ABH' : (getABHByRBH !== 0 ? 'ABH' : roleId))
                    , payload.PageNo, payload.NoOfRecordperPage, RBHpayload));
            }
            setNoOfRecordperPage(NoOfRecordperPage + 5);
        }
    };

    const getFilteredList = (filterRequest) => {
        setfilterApplied(true)
        const { BDMUserId, CheckListType, Status } = filterRequest
        scrollToTop();
        const req = {
            // "BDMUserId": BDMUserId,
            "CheckListType": CheckListType ? CheckListType : 0,
            "Status": Status ? Status : '',
        }
        setCheckListReq({ ...req })
        if (isOnline) {
            // setDataList([]);
            if (roleId === 'RBH') {
                // const RBHpayload = {
                //     // ABHUserId: `${BDMUserId ? BDMUserId : (getABHByRBH !== 0 ? getABHByRBH : '')}`,
                //     CheckListType: CheckListType,
                //     Status: Status,
                // }

                // dispatch(getAllRBHCheckList(userId, 1, 5, RBHpayload));
                dispatch(getAllBDMCheckList((`${BDMUserId ? BDMUserId : (getABHByRBH !== 0 ? getABHByRBH : userId)}`), (BDMUserId ? 'ABH' : (getABHByRBH !== 0 ? 'ABH' : roleId)), 1, NoOfRecordperPage, req));

            }
            if (roleId === 'ABH') {
                dispatch(getAllBDMCheckList(BDMUserId ? BDMUserId : userId, BDMUserId ? 'BDM' : roleId, 1, NoOfRecordperPage, req));
            }
        }
    };
    const scrollToTop = () => {
        formRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleLeadFilterState = (item) => {
        setfilterState({
            ...item,
        });
    };


    return (
        <>
            <div ref={formRef}>
                <Grid container columns={{ xs: 4, md: 12 }}>
                    {/* <PehlaKadamListing
                        roleId={roleId}
                        userId={userId}
                        data={Data}
                        filterState={filterState}
                        toggleDrawer={toggleDrawer}
                        loadMoreRecord={loadMoreRecord}
                        NoOfRecordperPage={NoOfRecordperPage}
                        TotalnoofRows={TotalnoofRows}
                    /> */}
                    <Suspense fallback={<div>Loading...</div>}>
                        <PehlaKadamListing
                            roleId={roleId}
                            userId={userId}
                            data={Data}
                            filterState={dataList}
                            toggleDrawer={toggleDrawer}
                            loadMoreRecord={loadMoreRecord}
                            NoOfRecordperPage={NoOfRecordperPage}
                            TotalnoofRows={TotalnoofRows}
                            filterApplied = {filterApplied}
                        />
                    </Suspense>

                    <Grid
                        item
                        xs={12}
                        md={3}
                        className="rightCol"
                        sx={{ display: { xs: "none", md: "flex" } }}
                    >
                        <ChecklistFilter
                            filterState={filterState}
                            setFilterState={handleLeadFilterState}
                            getFilteredList={getFilteredList}
                            handleClose={toggleDrawer(state.bottom, false)}
                            setfilterApplied ={setfilterApplied}
                        />
                    </Grid>
                </Grid>
                {/* <Loader></Loader> */}
            </div>
            <span>
                {["bottom"].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            <ChecklistFilter
                                anchor={anchor}
                                filterState={filterState}
                                setFilterState={setfilterState}
                                getFilteredList={getFilteredList}
                                handleClose={toggleDrawer(anchor, false)}
                                setfilterApplied ={setfilterApplied}
                            />
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </span>
        </>
    );
}
