import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';


const MedicalCompletedHistory = (props) => {
    const { history } = props
    const historyDate = history.MedicalcompletedDate ? moment(history.MedicalcompletedDate).format('DD MMM YYYY') : ''
    return <>
        {history.LeadStatusId === 21 ?
            <>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Medical Completed Date</label>
                        <label>
                            {historyDate}
                        </label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Remark</label>
                        <label><p>{history.Remarks ? history.Remarks : "N/A"}</p></label>
                    </TimelineContent>
                </TimelineItem>
            </>
            : ''
        }
    </>
}

export default MedicalCompletedHistory
