import React, {  useEffect } from "react"; //useState
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import Stack from '@mui/material/Stack';
// import StarIcon from '@mui/icons-material/Star';
import moment from "moment";
// import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
// import { validateExtraValidations } from "../activity/gcl/createActivityForm";
// import { blue } from "@mui/material/colors";
import { getChecklistForBDMView, getPehlaKadamGridList } from '../../../redux-reducer/reducer/index'
import HistoryDetails from "./historyDetails";
import './pehlaKadamQuestion.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


const CheckListViewForRBH = () => {
    const { checklistType, id } = useParams();
    const [formData, setformData] = React.useState([]);
    const { getBDMView, getBDMViewProfile, getPehlaKadamGridQus } = useSelector(state => state.pehlaKadamReducer); //getBDMData
    // const commonState = useSelector(state => state);
    // const userState = commonState.userReducer;
    // const [UserId] = useState(userState.profileInfo.userId);
    const dispatch = useDispatch();

    // const history = useHistory();
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        activityLogo: {
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    const classes = useStyles();
    const leadlistlable = {
        'word-break': 'break-all',
        'margin-right': '17px',
    }
    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 19
            },
            "&:before": {
                content: '"No"',
                color: "white",
                left: 12
            },
            "&:after": {
                content: '"Yes"',
                color: `white`,
                right: 12
            }
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 20,
            height: 20,
            margin: 1
        }
    }));
    useEffect(() => {
        if (navigator.onLine) {
            dispatch(getChecklistForBDMView(id, checklistType));
            dispatch(getPehlaKadamGridList(id, checklistType));

        }
    }, [checklistType])
    useEffect(() => {
        dispatch(getChecklistForBDMView(id, checklistType));
    }, []);

    useEffect(() => {
        loadSectionWithQuestion();
    }, [getBDMView]);

    const loadSectionWithQuestion = () => {
        const sequence_arr = [];
        const all_sequence_arr = [];
        getBDMView.length > 0 && getBDMView.map((val) => {
            sequence_arr.push(val.Sequence)
        })
        const uniqueSequence = [...new Set(sequence_arr)];
        let responseArr = [];
        uniqueSequence.map((Seq) => {
            console.log("hi")
            const queArr = [];
            let ActivityType;
            getBDMView.length > 0 && getBDMView.map((question) => {
                if (question.Sequence === Seq) {
                    ActivityType = question.ActivityType;
                    queArr.push(question);
                }
            })
            const section = {
                'Sequence': Seq,
                'ActivityType': ActivityType,
                'ques': queArr
            }
           return responseArr.push(section);
        })
        if (checklistType != 10) {
            responseArr.map((item) => {
                return (
                    item.ques.map((val) => {
                        return all_sequence_arr.push(val)
                    })
                )
            })

            setformData(all_sequence_arr);
        } else {
            setformData(responseArr)
        }
    }

    const getIntials = (name) => {
        let BDMname = name.split(' ');
        let Letter;
        if (BDMname[0] != null) {
            Letter = BDMname[0].slice(0, 1).toUpperCase();
        } else {
            Letter = "";
        }

        if (BDMname[1] != null) {
            Letter += BDMname[1].slice(0, 1).toUpperCase();
        }
        return Letter;
    };
    // const onChangeAccordion = (e) => {
    //     const Questionid = e.currentTarget.id;
    //     setAccordion({ ...accordion,
    //          [Questionid]: true })
    // }
    return (
        <div className="mainContainer leadDetails pehlaKadamQuestionSec">
            <Box>
                <Grid className="leadDetailsSec" container spacing={1} item xs={12} md={6}>
                    <Paper className={classes.paper} sx={{ mb: 1 }}>
                        <Grid item xs={12} md={12} >
                            <Grid item xs={12} md={4}>
                                <Paper className={classes.activityLogo} style={{ background: '#E9F4FC', color: "#00427E" }}>
                                    <Typography variant="h5" component="h2">
                                        {getBDMViewProfile.Name ? getIntials(getBDMViewProfile.Name) : ""}{" "}
                                    </Typography>
                                </Paper>

                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h5" component="h2" className={classes.heading} style={leadlistlable}>
                                    <b> {getBDMViewProfile.Name ? getBDMViewProfile.Name : ""}</b>
                                </Typography>
                                <span className="subTitle">Date of Joining :
                                    {getBDMViewProfile.JoiningDate
                                        ? moment(getBDMViewProfile.JoiningDate).format(
                                            "DD MMM YYYY"
                                        )
                                        : "N/A"}{" "}
                                    <span className="content">
                                        {getBDMViewProfile.JoiningDate
                                            ? moment(getBDMViewProfile.JoiningDate).format("h:mma")
                                            : " "}
                                    </span>
                                </span>
                                <span className="subTitle">Manager : {getBDMViewProfile.Manager ? getBDMViewProfile.Manager : ""} </span>
                                <span className="subTitle">Checklist Type :  {getBDMViewProfile.Manager ? getBDMViewProfile.ChecklistType : ""}</span>
                                <span className="subTitle">Checklist Status : {getBDMViewProfile.Manager ? getBDMViewProfile.Status : ""} </span>
                            </Grid>
                        </Grid>
                        <div className="clearfix"></div>
                    </Paper>
                    <div className="accordionlist">
                        <Paper className={classes.paper} sx={{ mb: 1 }}>
                            <form>
                                <FormControl>
                                    <div>
                                        {checklistType !== '10' && <>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}S
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography >Review Actual vs Target</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                            <TableContainer component={Paper}>
                                                                <Table >
                                                                    <TableHead>
                                                                        <TableRow
                                                                            sx={{ '&:last-child th, &:last-child th': { border: 1 } }}>
                                                                            <TableCell>Parameter</TableCell>
                                                                            <TableCell align="right">Actual</TableCell>
                                                                            <TableCell align="right">Target</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {getPehlaKadamGridQus.length > 0 && getPehlaKadamGridQus.map((row) => (
                                                                            <TableRow sx={{ 'td,th': { border: 1 } }} >
                                                                                <TableCell component="th" scope="row">
                                                                                    {row.Question}
                                                                                </TableCell>
                                                                                <TableCell align="right"> {row.Actual}</TableCell>
                                                                                <TableCell align="right"> {row.Target}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </List>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>

                                            <Accordion
                                                name={"valueSequence"}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography >Other Parameters</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className="backWhiteColor">
                                                    <Typography>
                                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                            <>
                                                                {formData.map((option, index) => (

                                                                    <div>
                                                                        {/* {console.log(option)} */}

                                                                        <ListItem
                                                                            key={index}
                                                                            disableGutters
                                                                        >
                                                                            <CircleIcon sx={{ fontSize: 7 }} />
                                                                            <ListItemText primary={option.Question} />
                                                                        </ListItem>
                                                                        <FormGroup>

                                                                            <div className="radioBoxDiv">
                                                                                <FormControlLabel
                                                                                    disabled
                                                                                    control={<Android12Switch />}
                                                                                    checked={option.IsYes}
                                                                                />
                                                                            </div>
                                                                            <label style={{ display: option.IsYes ? "none" : "block" }}>If No, specify the reason and provide resolution<label style={{ color: "red", fontSize: 20 }}>*</label></label>
                                                                            <textarea rows="4" cols="40" style={{ display: option.IsYes ? "none" : "block" }} disabled>{option.Remarks}</textarea>
                                                                        </FormGroup>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        </List>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>



                                        </>}

                                        {checklistType == "10" && formData.map((value) => (
                                            <Accordion
                                                id={value.Sequence}
                                                name={"valueSequence"}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography >{value.ActivityType}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className="backWhiteColor">
                                                    <Typography>
                                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                            <>
                                                                {value.ques.map((option) => (

                                                                    <div>
                                                                        {/* {console.log(option)} */}

                                                                        <ListItem
                                                                            key={value}
                                                                            disableGutters
                                                                        >
                                                                            <CircleIcon sx={{ fontSize: 7 }} />
                                                                            <ListItemText primary={option.Question} />
                                                                        </ListItem>
                                                                        <FormGroup>

                                                                            <div className="radioBoxDiv">
                                                                                <FormControlLabel
                                                                                    disabled
                                                                                    control={<Android12Switch />}
                                                                                    checked={option.IsYes}
                                                                                />
                                                                            </div>
                                                                            <label style={{ display: option.IsYes ? "none" : "block" }}>If No, specify the reason and provide resolution<label style={{ color: "red", fontSize: 20 }}>*</label></label>
                                                                            <textarea rows="4" cols="40" style={{ display: option.IsYes ? "none" : "block" }} disabled>{option.Remarks}</textarea>
                                                                        </FormGroup>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        </List>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                </FormControl>
                            </form>
                        </Paper>

                    </div>
                    <HistoryDetails classes={classes} Item={Item} data={getBDMViewProfile} />
                </Grid>
            </Box>
        </div >
    )
}
export default CheckListViewForRBH;
