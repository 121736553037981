export const calenderFilterForm = [{
    "key": "userType",
    "label": "User Type",
    "type": "select",
    "id": "userType",
    "name": "userType",
    "options": [
    ],
    "validators": [{ "name": "required", "value": false }]
},
    {
        "key": "teamMember",
        "label": "Team Member",
        "hide":true,
        "type": "select",
        "id": "teamMember",
        "name": "teamMember",
        "options": [
        ],
        "validators": [{ "name": "required", "value": false }]
    },
];
