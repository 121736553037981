import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import '../createLead.scss';
import '../leadDetails.scss';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import VideocamIcon from '@mui/icons-material/Videocam';
import SmsIcon from '@mui/icons-material/Sms';
import NavigationIcon from '@mui/icons-material/Navigation';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import HistoryCard from "../historyCard";
import CheckinMarker from '../../../../assets/img/checkInMarkerDetails.svg';
import { getMobileOS } from '../../../../utils/utils';
import { Drawer } from "@mui/material";
import MapRoute from "../mapRoute";
import { useParams } from "react-router";
import { ALLOWED_CHECK_IN_LEAD } from "../../../../utils/config";
import useOnlineStatus from "../../../../hooks/useOnlineStatus";
import ModalPopup from "f1-modal-react";
import { Button } from "@material-ui/core";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { loggInfo } from "../../../../redux/actions/userAction";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const LeadLayout = (props) => {
    const { id } = useParams();
    const isOnline = useOnlineStatus();
    const locationData = useLocation();
    const { AccountNo, BranchCode, BranchName, CollectionPremium,
        CustomerName, DueDate, DuePremium, Frequency,
        Paymentname, PolicyNumber, PolicyStatus,
        MobileNo, ProductName
        , LeadID, CreatedDate, MeetingDateTime, AlternateNo, LeadStatus, StatusofCases, ActivityID,
        CRO_CODE, StateCode, DistrictName, ChannelID, Pincode, VLEMobileNo, VLEName, Remarks, RAP_ID,
        CheckinCount, LeadStatusId, IsClaimRequired, IsCheckin, MeetingStartDateTime,ExpectedLeadClosureDate,
        MeetingEndDateTime, AssignedToName, BankName, MeetingDuration, CustomerAddress } = props.leadDetails;
    const ActivityIDlabel = `${BranchName}-${moment(MeetingDateTime).format('MMM-YY')}`;
    const BranchCodeDetails = `${BranchName}-${BankName ? BankName : "N/A"}-${BranchCode}`;

    const [drawerList, setDrwerList] = useState({
        right: false
    })
    const [coords, setCoords] = useState({})
    const [showModalSetup, setShowModalSetup] = useState(false);
    const commonState = useSelector(state => state)
    const userState = commonState.userReducer;

    useEffect(()=>{
        console.log("coords",coords);
    },[])
    const getIntials = (name) => {
        if (name != null) {
            return name.slice(0, 1).toUpperCase();
        } else {
            return "";
        }
    }
    const leadlistlable = {
        'word-break': 'break-all',
        'margin-right': '17px',
    }
    const icons = {
        'font-size': ' 0.6rem',
        //'margin-left': '15%',
    }
    const iconalign = {
        'text-align': 'center',
    }
    const formatPhoneNumber = (str) => {
        const cleaned = `( ${str})`.replace(/\D/g, '');

        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `( ${match[1]} ) ${match[2]} ${match[3]}`.replace(/\d{4}$/, '****');
        }

        return null
    };
    const dateformat = "DD MMM YYYY";
    const AlternateNoForm = formatPhoneNumber(AlternateNo);


    const toggleDrawer = (anchor, open) => (event) => {
        if (isOnline) {
            navigator.geolocation.getCurrentPosition((res) => {
                setCoords({ lat: res.coords.latitude, lng: res.coords.longitude })
                setShowModalSetup(false)
                if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                    return;
                }
                setDrwerList({ ...drawerList, [anchor]: open })
            }, (error) => {
                console.log('getCurrentPosition error PL leadLayout', error)
                setCoords({ lat: null, lng: null })
                setDrwerList(false)
                setShowModalSetup(true)
            })

        }
        if (open) {
            const employeeCode = userState.profileInfo.employeeCode;
            const deviceId = userState.profileInfo.deviceId;
            const logInfoRequest = {
                processname: 'Check-in drawer open',
                employeeCode: employeeCode,
                ipAddress: "",
                requestFor: `${'Check-In checkinpersistencylead'}`,
                leadActivityId: id,
                deviceId: deviceId,
                routUrl: locationData.pathname
            }
            loggInfo(logInfoRequest);
        }
    };
    const handleClose = () => {
        setShowModalSetup(false)
        toggleDrawer('right', true)
    }

    const handleAudioCall = (MobileNum) => {
        MobileNum && window.open(`tel:${MobileNum}`)
    }

    const handleSMS = (MobileNum) => {
        MobileNum && window.open(`sms:${MobileNum}`)

    }

    const handleVideoCall = () => {
        //video
    }

    const handleNavigate = () => {
        //naviagate

    }



    const getUpdatedHistory = () => {
        props.onGetUpdatedHistory();
    }
    const isMobileOS = getMobileOS()
    const getSplittedName = (customer_name) => {
        let n = "";
        const regex = /(Mrs|MR|Ms|Miss|Mr|Dr|Sir)(\.?)?/
        const match = regex.exec(customer_name);
        (match !== null) ? n = customer_name.replace(match[0], "") : n = customer_name;
        return n?.split(' ')?.filter((data) => data !== '');
    }
    return < div >
        <Box sx={{ flexGrow: 1 }}>
            <Grid className="leadDetailsSec" container spacing={3}>
                <Grid item xs={12} md={4} >

                    <Paper className={props.classes.paper} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid className="branchDiv" item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={4} md={4} >
                                        <Paper className={props.classes.activityLogo} style={{ color: "blue" }}>
                                            <Typography variant="h5" component="h2">
                                                {CustomerName ? getIntials(getSplittedName(CustomerName)[0]) : ""}
                                                {CustomerName ?
                                                    getSplittedName(CustomerName).length > 1 && getIntials(getSplittedName(CustomerName)[getSplittedName(CustomerName).length - 1])
                                                    : ""}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={8} md={8}>
                                        <Typography variant="h5" component="h2" className={props.classes.heading} style={leadlistlable}>
                                            <b> {CustomerName}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Lead ID</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {LeadID}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Current Status</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {LeadStatus}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Assigned To</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {AssignedToName ? AssignedToName : 'N/A'}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Created Date</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span className="date">{CreatedDate ? moment(CreatedDate).format(dateformat) : ""}</span>
                                                <span className="time">{CreatedDate ? moment(CreatedDate).format('h:mma') : ""}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Meeting Date</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span className="date">
                                                    {MeetingStartDateTime ? moment(MeetingStartDateTime).format(dateformat) : ""}
                                                </span>
                                                <span className="time">
                                                    {MeetingStartDateTime ? moment(MeetingStartDateTime).format('h:mma') : ""}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                {  userState.profileInfo.channelid == 1   || userState.profileInfo.channelid == 3  ?
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                        <Grid container spacing={4}>
                                                            <Grid item xs={5} md={5} >
                                                                <label> Expected lead closure date </label>
                                                            </Grid>
                                                            <Grid className="lastitem" item xs={7} md={7} >
                                                                {ExpectedLeadClosureDate ? moment(ExpectedLeadClosureDate).format(dateformat) : ""}
                                                            </Grid>
                                                        </Grid>
                                    </ListItemText>:''
                                }
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                    {!ALLOWED_CHECK_IN_LEAD.includes(LeadStatus) && (isMobileOS !== "Other") &&
                        <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                            <Grid container spacing={1}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <ListItem alignItems="flex-start" onClick={toggleDrawer('right', true)}>
                                        <Grid className='checkInDiv' container spacing={4}>
                                            <Grid item xs={4} md={4} >
                                                <img src={CheckinMarker} alt={'Marker'}></img>
                                            </Grid>
                                            <Grid item xs={7} md={7} >
                                                <label style={{ color: 'black' }}><b>Check In</b></label>
                                            </Grid>
                                            <Grid item xs={1} md={1} >
                                                <span style={{ cursor: isOnline ? 'pointer' : 'not-allowed' }} onClick={toggleDrawer('right', true)}>
                                                    <ArrowRightAltIcon />
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    {['right'].map((anchor) => (
                                        <Fragment key={anchor}>
                                            <Drawer
                                                anchor={anchor}
                                                open={drawerList[anchor]}
                                                onClose={toggleDrawer(anchor, false)}>

                                                <MapRoute status="checkinpersistencylead"
                                                    checkinCount={CheckinCount}
                                                    id={id} statusId={LeadStatusId}
                                                    setDrwerList={setDrwerList}
                                                    onCheckInComplete={getUpdatedHistory}
                                                />

                                            </Drawer>
                                        </Fragment>
                                    ))}
                                </List>
                            </Grid>
                        </Paper>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>Lead Personal Details </b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="leadPersonalDiv" item xs={12} md={12} >

                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Customer Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {CustomerName}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Mobile No.</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {'+91 '}{MobileNo?.replace(/\d{4}$/, '****')}
                                                <Grid container>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={() => handleAudioCall(MobileNo)}>
                                                                <WifiCalling3Icon />
                                                                <em>Audio Call</em>
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={handleVideoCall}>
                                                                <VideocamIcon />
                                                                <em>Video Call</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={() => handleSMS(MobileNo)}>
                                                                <SmsIcon />
                                                                <em>SMS</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Alternate Mobile No.</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {AlternateNoForm ? `+1 ${AlternateNoForm}` : ''}
                                                <Grid container>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className={AlternateNoForm ? 'bxSvg' : 'bxSvg activeClass'} onClick={() => handleAudioCall(AlternateNoForm)}>
                                                                <WifiCalling3Icon />
                                                                <em>Audio Call</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className={AlternateNoForm ? 'bxSvg' : 'bxSvg activeClass'} onClick={handleVideoCall}>
                                                                <VideocamIcon />
                                                                <em>Video Call</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className={AlternateNoForm ? 'bxSvg' : 'bxSvg activeClass'} onClick={() => handleSMS(AlternateNoForm)}>
                                                                <SmsIcon />
                                                                <em>SMS</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Customer Address</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >

                                                {CustomerAddress}
                                                <Grid container spacing={4}>
                                                    <Grid item xs={4} md={5} className="buttonBox" style={iconalign}>
                                                        <span className="bxSvg" onClick={handleNavigate}>
                                                            <NavigationIcon />
                                                            <em style={icons}>Navigate</em>
                                                        </span>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>
                                            Product Details</b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="listPT0 prodList" item xs={12} md={12} >
                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Product Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {ProductName}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Policy Number</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {PolicyNumber}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Policy Status</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {PolicyStatus}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Frequency</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {Frequency}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Due Date</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {DueDate}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Due Premium</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <CurrencyRupeeIcon fontSize="small" />
                                                {parseInt(DuePremium).toLocaleString()}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Collection Premium</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <CurrencyRupeeIcon fontSize="small" />
                                                {parseInt(CollectionPremium).toLocaleString()}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Payment Method</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {Paymentname}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Account No</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {AccountNo}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>

                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                {userState.profileInfo.channelid ==1 || userState.profileInfo.channelid ==3 ?
                                                <label>Premium status</label> : <label>Status of Cases</label>  }
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {StatusofCases}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>Business Details</b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="listPT0" item xs={12} md={12} >
                                <Grid item xs={12} md={12} >
                                    <List>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Activity ID</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {ActivityID}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <Divider />
                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>CRO Code</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {CRO_CODE}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />
                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Channel</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        CSC
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />
                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>StateCode</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {StateCode}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />

                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>District Name</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {DistrictName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />

                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Pincode</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {Pincode}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />
                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>RAP ID</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {RAP_ID}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />
                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Remarks</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {Remarks}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />
                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>VLE NAME</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {VLEName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        <Divider />
                                        {ChannelID === 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>VLE Mobile Number</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {VLEMobileNo}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        {ChannelID !== 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Branch Name</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {BranchName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        {ChannelID !== 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Branch Code</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {BranchCode}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />
                                        {ChannelID !== 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Bank Name</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {BankName ? BankName : "N/A"}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                        <Divider />

                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Meeting Date & Time</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span className="date">
                                                        {MeetingStartDateTime ? moment(MeetingStartDateTime).format(dateformat) : "N/A"}
                                                    </span>
                                                    <label className='time'>
                                                        {MeetingStartDateTime ? moment(MeetingStartDateTime).format('h:mma') : ""}
                                                        {" "}
                                                        {MeetingStartDateTime ? moment(MeetingEndDateTime).format('h:mma') : ""}
                                                    </label><br></br>

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Meeting Duration</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {MeetingDuration}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        {ChannelID!== 2 &&
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Select Branch Code</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {BranchCodeDetails}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        }
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <HistoryCard classes={props.classes} leadHistoryData={props.hitory}
                        Item={Item} ActivityIDlabel={ActivityIDlabel}
                        IsCheckin={IsCheckin} IsClaimRequired={IsClaimRequired} />
                </Grid>

            </Grid>
        </Box>
        <ModalPopup
            show={showModalSetup}
            onHide={() => setShowModalSetup(!showModalSetup)}
            size={"sm"}
            centered={true}
            modelHeader={"Please enable your location"}
            modelContent={
                <Button
                    onClick={handleClose}
                >
                    CLOSE
                </Button>
            }
            backdrop={"static"}
        />
    </div >
}
export default LeadLayout;
