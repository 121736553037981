import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { getPehlaKadamCheckList, savePehlaKadamCheckList, getPehlaKadamBDMData, getPehlaKadamGridList } from '../../../redux-reducer/reducer/index'
import HistoryDetails from "./historyDetails";
import './pehlaKadamQuestion.scss';

import { activateErrorMessageBar} from "../../../redux/actions/common";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { onlyAlphanumericSpacesMinMax } from "../../../utils/validators";

const PehlaKadamQuestion = () => {
    const { id, checklistType, status } = useParams();
    const [formData, setformData] = React.useState([]);
    const { getPehlaKadamCheckListQus, getBDMData, getPehlaKadamGridQus } = useSelector(state => state.pehlaKadamReducer);
    // console.log(id,checklistType,status);
    const commonState = useSelector(state => state);
    const userState = commonState.userReducer;
    const [UserId] = useState(userState.profileInfo.userId);
    // const [roleId] = useState(userState.profileInfo.roleId);
    const roleId = userState.profileInfo.roleId;
    const dispatch = useDispatch();
    const {CheckListType } = commonState.commonReducer; //CheckListStatus

    const [accordion, setAccordion] = React.useState({});
    const [disablegrid, setdisablegrid] = React.useState(false);
    const history = useHistory();
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
        },
        activityLogo: {
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    const classes = useStyles();
    // const disabled = {
    //     'cursor': 'not-allowed',
    // }

    const leadlistlable = {
        'word-break': 'break-all',
        'margin-right': '17px',
    }
    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 19
            },
            "&:before": {
                content: '"No"',
                color: "white",
                left: 12
            },
            "&:after": {
                content: '"Yes"',
                color: `white`,
                right: 12
            }
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 20,
            height: 20,
            margin: 1
        }
    }));
    useEffect(() => {
        if (navigator.onLine) {
            // dispatch(getPehlaKadamBDMData(UserId, 1, 5, { "BDMUserId": id }));
            // console.log(id,checklistType,status);
            dispatch(getPehlaKadamBDMData(id, 'BDM', 1, 5, { "CheckListType": parseInt(checklistType), "Status": status }));
        }
        setdisablegrid(roleId === "RBH" ? true : false)
    }, [id, checklistType, status])
    useEffect(() => {
        dispatch(getPehlaKadamBDMData(id, 'BDM', 1, 5, { "CheckListType": parseInt(checklistType), "Status": status }));
        loadSectionWithQuestion();
        setdisablegrid(roleId === "RBH" ? true : false)
    }, []);
    useEffect(() => {
        if (getBDMData.length !== 0) {
            dispatch(getPehlaKadamCheckList(ids[0]?.key));//ids[0].key
            if (ids[0].key !== '') {
                dispatch(getPehlaKadamGridList(id, ids[0]?.key));//ids[0].key
            }
        }
        loadSectionWithQuestion();
        if (getBDMData.Status === "Completed") {
            document.getElementById("submitbutton").disabled = true;
            document.getElementById("submitbutton").classList.add('disabled')

        } else {
            document.getElementById("submitbutton").disabled = false;
            document.getElementById("submitbutton").classList.remove('disabled')
        }
    }, [getBDMData]);

    useEffect(() => {
        loadSectionWithQuestion();
    }, [getPehlaKadamCheckListQus]);

    const ids = CheckListType.filter((obj) => {
        if (obj.label === getBDMData?.ChecklistType) {
            return obj.key
        }
    });
    const loadSectionWithQuestion = () => {

        const sequence_arr = [];
        getPehlaKadamCheckListQus.map((val) => {
            sequence_arr.push(val.Sequence)
        })

        const uniqueSequence = [...new Set(sequence_arr)];
        let responseArr = [];
        if (ids[0]?.key == 10 || ids[0]?.key == 60 || ids[0]?.key == 30) {
            uniqueSequence.map((Seq) => {
                const queArr = [];
                let ActivityType;
                getPehlaKadamCheckListQus.map((question) => {
                    if (question.Sequence === Seq) {
                        // console.log(Object.keys(question))
                        ActivityType = question.ActivityType;
                        queArr.push(question);
                    }
                })
                const section = {
                    'Sequence': Seq,
                    'ActivityType': ActivityType,
                    'ques': queArr
                }
                responseArr.push(section);
            })
        } else {
            uniqueSequence.map((Seq) => {
                const queArr = [];
                let ActivityType;
                getPehlaKadamCheckListQus.map((question) => {
                    if (question.Subsequence === Seq) {
                        // console.log(Object.keys(question))
                        ActivityType = question.ActivityType;
                        queArr.push(question.Question);
                    }
                })
                const section = {
                    'Sequence': Seq,
                    'ActivityType': ActivityType,
                    'ques': queArr
                }
                responseArr.push(section);
            })
        }
        setformData(responseArr);
    }

    const onChangebutton = (event) => {
        const sequence = event.target.name.split("/")[0];
        const quesId = event.target.name.split("/")[1];
        let replicaData = [...formData];
        replicaData.map((val, index) => {
            if (val.Sequence === parseInt(sequence)) {
                val.ques.map((qesList, indexid) => {
                    if (qesList.QuestionId === parseInt(quesId)) {
                        let qesListobj = { ...qesList }
                        qesListobj.IsYesNo = event.target.checked;
                        val.ques.splice(indexid, 1);
                        val.ques.splice(indexid, 0, qesListobj);
                    }
                })
            }
        })
        setformData(replicaData)
    };

    const onChangeRemark = (event) => {
        const sequence = event.target.name.split("/")[0];
        const quesId = event.target.name.split("/")[2];
        var textarea = document.getElementById(event.target.id).value;
        let replicaDataForRemark = [...formData];
        const data = replicaDataForRemark.map((val, index) => {
            if (val.Sequence === parseInt(sequence)) {
                const quesData = val.ques.map((qesList, index1) => {
                    if (qesList.QuestionId === parseInt(quesId)) {
                        document.getElementById(
                            `${qesList.Sequence}_remark_${qesList.QuestionId}`,
                        ).style.borderColor = "black";
                        document.getElementById(
                            `${qesList.Sequence}_remarkLabel_${qesList.QuestionId}`,
                        ).style.color = "black";
                        return {
                            ...qesList,
                            Remark: textarea,
                            error: onlyAlphanumericSpacesMinMax(textarea)
                        };
                        // qesList['Remark'] = textarea;
                    } else {
                        return qesList;
                    }
                });
                return {
                    ...val,
                    ques: quesData,
                };
            } else {
                return val;
            }
        });

        setformData(data)
    }

    const submitCheckList = () => {
        let flag = true;
        let setflag = true;
        const payload = [];
        formData.map((val) => {
            val.ques.map((ques) => {
                let question = {}

                if (!ques.IsYesNo) {

                    if (ques.Remark == undefined) {
                        dispatch(activateErrorMessageBar("Please enter the required details"));
                        document.getElementById(`${ques.Sequence}_remark_${ques.QuestionId}`).style.borderColor = "red";
                        const element = document.getElementById(`${ques.Sequence}_remark_${ques.QuestionId}`);
                        if (element) {
                            element.scrollIntoView({
                                block: 'center'
                            });
                        }
                        document.getElementById(`${ques.Sequence}_remarkLabel_${ques.QuestionId}`).style.color = "red";

                        flag = false;
                        return false;
                    }
                    if (!ques.error) {
                        document.getElementById(`${ques.Sequence}_remark_${ques.QuestionId}`).style.borderColor = "red";
                        flag = false;
                        return false;
                    }
                }
                question['QuesId'] = ques.QuestionId;
                question['IsYes'] = `${ques.IsYesNo}`;
                question['Remark'] = ques.Remark ? ques.Remark : "null";
                question['UserId'] = id;
                question['CreatedBy'] = UserId;
                question['ChecklistType'] = ids[0].key ? ids[0].key : "";
                payload.push(question);
            })
        })
        const sequence_arr = [];
        getPehlaKadamCheckListQus.map((val) => {
            sequence_arr.push(val.Sequence)
        })
        const uniqueSequence = [...new Set(sequence_arr)];
        if (ids[0]?.key == 10) {
            uniqueSequence.map((val) => {
                if (accordion[val]) {
                    setflag = true;
                } else {
                    setflag = false;
                }
            })
        }

        if (setflag) {
            if (navigator.onLine) {
                if (flag) {
                    dispatch(savePehlaKadamCheckList(payload, history));
                    loadSectionWithQuestion();
                }
            }
        } else {
            dispatch(activateErrorMessageBar("Please Review The All Checklist Question First"));
        }
    }

    const cancelCheckList = () => {
        history.push('/app/pehlaKadam/all')
    }
    const getIntials = (name) => {
        let BDMname = name.split(' ');
        let Letter;
        if (BDMname[0] != null) {
            Letter = BDMname[0].slice(0, 1).toUpperCase();
        } else {
            Letter = "";
        }

        if (BDMname[1] != null) {
            Letter += BDMname[1].slice(0, 1).toUpperCase();
        }
        return Letter;
    };
    const onChangeAccordion = (e) => {

        const Questionid = e.currentTarget.id;
        setAccordion({
            ...accordion,
            [Questionid]: true
        })
    }

    useEffect(() => {
        if (roleId === "ABH" && getBDMData?.Status === "Not-Completed")
            setdisablegrid(true)
    }, [])
    return (
        <div className="mainContainer leadDetails pehlaKadamQuestionSec">
            <Box>
                <Grid className="leadDetailsSec" container spacing={1} item xs={12} md={6}>
                    <Paper className={classes.paper} sx={{ mb: 1 }}>
                        <Grid item xs={12} md={12} >
                            <Grid item xs={12} md={4}>
                                <Paper className={classes.activityLogo} style={{ background: '#E9F4FC', color: "#00427E" }}>
                                    <Typography variant="h5" component="h2">
                                        {getBDMData?.Name ? getIntials(getBDMData.Name) : ""}{" "}
                                    </Typography>
                                </Paper>

                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h5" component="h2" className={classes.heading} style={leadlistlable}>
                                    <b> {getBDMData?.Name}</b>
                                </Typography>
                                <span className="subTitle">Date of Joining :
                                    {getBDMData?.JoiningDate
                                        ? moment(getBDMData?.JoiningDate).format(
                                            "DD MMM YYYY"
                                        )
                                        : "N/A"}{" "}
                                    <span className="content">
                                        {getBDMData?.JoiningDate
                                            ? moment(getBDMData?.JoiningDate).format("h:mma")
                                            : " "}
                                    </span>
                                </span>
                                <span className="subTitle">Manager : {getBDMData?.Manager} </span>
                                <span className="subTitle">Checklist Type  :  {getBDMData?.ChecklistType}</span>
                                <span className="subTitle">Checklist Status : {getBDMData?.Status} </span>
                            </Grid>
                        </Grid>
                        <div className="clearfix"></div>
                    </Paper>
                    <div className="accordionlist">
                        <Paper className={classes.paper} sx={{ mb: 1 }}>
                            <form>
                                <FormControl>
                                    <div>
                                        {ids.length > 0 && ids[0].key !== 10 && <>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography >Review Actual vs Target</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                            <TableContainer component={Paper}>
                                                                <Table >
                                                                    <TableHead>
                                                                        <TableRow
                                                                            sx={{ '&:last-child th, &:last-child th': { border: 1 } }}>
                                                                            <TableCell style={{ fontSize: "1rem" }}>Parameter</TableCell>
                                                                            <TableCell style={{ fontSize: "1rem" }} align="right" >Actual</TableCell>
                                                                            <TableCell style={{ fontSize: "1rem" }} align="right">Target</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {getPehlaKadamGridQus.length > 0 && getPehlaKadamGridQus.map((row) => (
                                                                            <TableRow sx={{ 'td,th': { border: 1 } }} >
                                                                                <TableCell component="th" scope="row" style={{ fontSize: "1rem" }}>
                                                                                    {row.Question}
                                                                                </TableCell>
                                                                                <TableCell align="right" style={{ fontSize: "1rem" }}> {row.Actual}</TableCell>
                                                                                <TableCell align="right" style={{ fontSize: "1rem" }}> {row.Target}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </List>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>

                                        </>}
                                        {ids.length > 0 && ids[0].key != 30 && ids[0].key != 60 && formData.map((value) => (
                                            <div id={value.Sequence} onClick={onChangeAccordion}>
                                                <Accordion
                                                    id={value.Sequence}
                                                    name={"valueSequence"}


                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography >{value.ActivityType}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                                <>
                                                                    {value.ques.map((option) => (

                                                                        <div style={{ marginBottom: "10px" }}>

                                                                            <ListItem
                                                                                key={value}
                                                                                disableGutters
                                                                            >
                                                                                <CircleIcon sx={{ fontSize: 7 }} />
                                                                                <ListItemText primary={option.Question} />
                                                                            </ListItem>
                                                                            <FormGroup>

                                                                                <div className="radioBoxDiv">
                                                                                    <FormControlLabel
                                                                                        disabled={disablegrid}
                                                                                        control={<Android12Switch />}
                                                                                        label=""
                                                                                        onChange={onChangebutton}
                                                                                        id={`${value.Sequence}/${option.QuestionId}`}
                                                                                        checked={option.IsYesNo}
                                                                                        name={`${value.Sequence}/${option.QuestionId}`} />
                                                                                </div>
                                                                                <label style={{ display: option.IsYesNo ? "none" : "block" }} id={`${value.Sequence}_remarkLabel_${option.QuestionId}`}>If No, specify the reason and provide resolution<label style={{ color: "red", fontSize: 20 }}>*</label></label>
                                                                                <textarea disabled={disablegrid} id={`${value.Sequence}_remark_${option.QuestionId}`} onChange={onChangeRemark} name={`${value.Sequence}/remark/${option.QuestionId}`}
                                                                                    rows="4" cols="40" style={{ display: option.IsYesNo ? "none" : "block" }} />
                                                                                {option.error == false && <span style={{ color: "red ", display: option.IsYesNo ? "none" : "block" }}>Remark should be min 3 and max 50 characters and Special characters not allowed.  </span>}
                                                                            </FormGroup>
                                                                            <div className="clearfix"></div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            </List>
                                                            <div className="clearfix"></div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        ))}

                                        {ids.length > 0 && ids[0].key != 10 &&



                                            <Accordion
                                                // id={value.Sequence}
                                                name={"valueSequence"}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography >Other Parameter</Typography>
                                                </AccordionSummary>

                                                {ids.length > 0 && ids[0].key != 10 && formData.map((value) => {
                                                    return (
                                                        <AccordionDetails>
                                                            <Typography>
                                                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                                                    <>
                                                                        {value.ques.map((option) => (

                                                                            <div>
                                                                                <ListItem
                                                                                    key={value}
                                                                                    disableGutters
                                                                                >
                                                                                    <CircleIcon sx={{ fontSize: 7 }} />
                                                                                    <ListItemText primary={option.Question} />
                                                                                </ListItem>
                                                                                <FormGroup>

                                                                                    <div className="radioBoxDiv">
                                                                                        <FormControlLabel
                                                                                            disabled={disablegrid}
                                                                                            control={<Android12Switch />}
                                                                                            label=""
                                                                                            onChange={onChangebutton}
                                                                                            id={`${value.Sequence}/${option.QuestionId}`}
                                                                                            checked={option.IsYesNo}
                                                                                            name={`${value.Sequence}/${option.QuestionId}`} />
                                                                                    </div>
                                                                                    <label style={{ display: option.IsYesNo ? "none" : "block" }} id={`${value.Sequence}_remarkLabel_${option.QuestionId}`}>If No, specify the reason and provide resolution<label style={{ color: "red", fontSize: 20 }}>*</label></label>
                                                                                    <textarea disabled={disablegrid} id={`${value.Sequence}_remark_${option.QuestionId}`} onChange={onChangeRemark} name={`${value.Sequence}/remark/${option.QuestionId}`}
                                                                                        rows="4" cols="40" style={{ display: option.IsYesNo ? "none" : "block" }} />
                                                                                    {option.error == false && <span style={{ color: "red ", display: option.IsYesNo ? "none" : "block" }}>Remark should be min 3 and max 50 characters and Special characters not allowed.  </span>}

                                                                                </FormGroup>
                                                                                <div className="clearfix"></div>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                </List>
                                                                <div className="clearfix"></div>
                                                            </Typography>
                                                        </AccordionDetails>
                                                    )
                                                })}
                                            </Accordion>


                                        }

                                    </div>
                                </FormControl>

                                <Grid item className="btnBottom">
                                    <button type="button" class="btn btn-primary no-drop" onClick={cancelCheckList}
                                        style={{ visibility: ((status == "pending" || status == "Pending") && roleId == "ABH") ? "visible" : "hidden" }}>Cancel</button>
                                    <button type="button" class="btn btn-dark" id="submitbutton" onClick={submitCheckList}
                                        style={{ visibility: ((status == "pending" || status == "Pending") && roleId == "ABH") ? "visible" : "hidden" }}>Update</button>
                                </Grid>

                            </form>
                        </Paper>

                    </div>
                    <HistoryDetails classes={classes} Item={Item} data={getBDMData} />
                </Grid>
            </Box>
        </div >
    )
}
export default PehlaKadamQuestion;
