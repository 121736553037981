import React, { useEffect, useState } from 'react';

export const Paginator = ({
	totalRecoards,
	recordsPerPage,
	onPageChange,
	onChangeRowsPerPage,
	rowsPerPageList = [],
	maxPageLimit,
	enablePerPageDropDown = false,
	initialPage,
	rowCount,
	...props
}) => {
	let [currentPage, setCurrentPage] = useState(0);
	let [pages, setPages] = useState([]);
	let totalPages = Math.ceil(totalRecoards / recordsPerPage);
	const dataFromChild = 0;
	useEffect(() => {
		if (props.onMount) {
			props.onMount(setCurrentPage(dataFromChild));
		}
		else {
			setCurrentPage(initialPage);
		}
		calculatePageRange('right', totalPages, 0);
	}, [initialPage, totalRecoards, rowCount]);
	const calculatePageRange = (direction, totalPagesVar, currentPageVar) => {
		let totalPagesRange = Array.from(Array(totalPages).keys());
		let lastRangeIndex = currentPageVar + maxPageLimit;
		let startIndex;
		if (direction === 'right') {
			if (totalPagesVar - maxPageLimit > currentPageVar) {
				startIndex = currentPageVar;
			} else {
				startIndex = totalPagesVar - maxPageLimit;
			}
		} else {
			startIndex =
				currentPageVar - maxPageLimit + 1 >= 0
					? currentPageVar - maxPageLimit + 1
					: 0;
			lastRangeIndex = Math.max(startIndex + maxPageLimit, currentPageVar + 1);
		}

		let range = totalPagesRange.slice(startIndex >= 0 ? startIndex : 0, lastRangeIndex);
		setPages(range);
	};
	const handleClick = (e, page) => {
		let direction = currentPage > page ? 'left' : 'right';
		setCurrentPage(page);
		calculatePageRange(direction, totalPages, page);
		onPageChange(e, page);
	};

	return (

		<div className="row paginatorDiv">
			<div className="col-md-2">
				{enablePerPageDropDown ? (
					<>
						<label>Rows Per Page</label>
						<select
							onChange={e => {
								onChangeRowsPerPage(e, e.target.value);
							}}
							value={recordsPerPage}
						>
							{rowsPerPageList.map((item, index) => {
								return <option value={item}>{item}</option>;
							})}
						</select>{' '}
					</>
				) : (
						''
					)}
			</div>
			<div className="col-md-10 paginator">
				<button
					disabled={0 === currentPage}
					className={0 === currentPage ? 'disabled' : ''}
					onClick={e => {
						handleClick(e, currentPage - 1);
					}}
				>
					&#x2190;
        </button>
				{pages.map((page, index) => {
					return (
						<button
							disabled={page === currentPage}
							className={page === currentPage ? 'disabled' : ''}
							onClick={e => {
								handleClick(e, page);
							}}
						>
							{page + 1}
						</button>
					);
				})}
				<button
					disabled={totalPages - 1 === currentPage}
					className={totalPages - 1 === currentPage ? 'disabled' : ''}
					onClick={e => {
						handleClick(e, currentPage + 1);
					}}
				>
					&#x2192;
        </button>

			</div>
		</div>
	);
};
