import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Breadcrumb from '../../../../components/breadcrumb/breadcrumb'
import MessageBar from '../../../../components/messageBar/messageBar'
import DynamicForm from "f1-dynamic-form-react"
import { BANCA_LEAD, BUSSINESS_LEAD, checkInConfig, LEAD_HAS_MEETING, RECRUITMENT_LEAD, MEETING_TYPE_ZMA_PZMA, GENDER } from '../../../../utils/config';
import { customValidators, getForm } from './createLeadFormAgency';
import { connect } from "react-redux"
import moment from "moment"
import { t } from "i18next";
import { checkDateIsValid } from "../../../../utils/validators";
import Loader from "../../../../components/loader/loader";
import './createLead.scss';
import { saveLeadOffline, isLeadValid } from "../../../../offlineManager/leadOfflineManager";
import { v4 as uuidv4 } from 'uuid';
import Footer from '../../../../layouts/footer/footer';
import {
    checkMobileNumberReducer, getLGValueReducer, saveLeadForm,
    validateMeetingDateStatusInCreate, getLeadDetails, UpdateLeadForm, createLeadDeDupeValidation, validateMeetingDateForCreateLead, getSelectedDraftLeadData, getProfessionData, getEngagementData, getIncomeGroupDetails, getAgencyLeadSourceData, getRecruitmentLeadType
} from '../../../../redux-reducer/reducer';
import { setBreadcrumbs, activateLoader, deActivateLoader, activateSuccessMessageBar, activateErrorMessageBar, setZMAList, setAdvisorList, setPremierZMAList } from '../../../../redux/actions/common';
import { LEADS_PATH } from '../../../../route/routePath';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './updateLead.scss';
import SaveAsDraftDrawer from '../../activity/saveAsDraftDrawer';
import IdleTimer from 'react-idle-timer';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { minusMinuteFromDate } from '../../../../utils/utils';
import { getAdvisorZMAPremierZMAListing } from "../../../../redux/actions";
const _formatDate = "YYYY-MM-DDTHH:mm";
const createformat = "YYYY-MM-DD HH:mm:ss";
class AgencyCreateLead extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.dyFormRef = React.createRef();
        this.stateType = this.props.history.location.pathname.split('/')[2] === "businesslead" ? 'Business' : this.props.history.location.pathname.split('/')[2] === "recruitmentlead" ? 'Recruitment' : ""
        this.formName = this.props.history.location.pathname.split('/')[2] === "businesslead" ? BUSSINESS_LEAD : this.props.history.location.pathname.split('/')[2] === "recruitmentlead" ? RECRUITMENT_LEAD : "";
        this.LeadTitle = this.id ? `Update ${this.stateType} Lead` : `Create ${this.stateType} Lead`
        this.userData = JSON.parse(localStorage.getItem('persist:root'));
        this.userId = JSON.parse(this.userData.userReducer).profileInfo.userId;
        this.employeeCode = JSON.parse(this.userData.userReducer).profileInfo.employeeCode;
        this.channelID = JSON.parse(this.userData.userReducer).profileInfo.channelid;
        this.idleTimerData= this.props.masterDataState.Configuration_Setting //  new requirment changes for change idleTimerTiming BRD Activity new Lead flow v1.2
        this.idleTimer = null
        this.state = {
            current: {
                id: 1,
                MeetingEndDateTime: '',
            },
            formData: getForm(this.formName),
            AssignedToName: '',
            AssignedTo: '',
            uploadedFile: '',
            originalFileName: '',
            extErrors: {},
            selectActivityID: "",
            selectProductID: "",
            startMeetingDate: "",
            endMeetingDate: "",
            defaultFormVal: {
                id: 1,
                MeetingStartDateTime: moment(new Date()).format(_formatDate),
                MeetingEndDateTime: moment(new Date()).add(30, "m")
                    .format(_formatDate),
                ISleadHasMeeting: false
            },
            autoCompleteItems: {
                ActivityID: [],
                ProductID: [],
            },
            collapseStates: {},
            LEAD_BRANCH: [],
            leadDetail: {},
            isActiveMobileDraft: false,
            isFormSUbmitDisabled: false,
            timeOutMsg: false,
            labelForId: {
                ActivityIDLabel: '',
                ProductIDLabel: '',
                BranchIDLabel: '',
            },
            idleTimerTiming:10, //  new requirment changes for change idleTimerTiming BRD Activity new Lead flow v1.2
            accordianFieldSet: { fieldset1: false, fieldset2: false, fieldset3: false },
        }
        const getRecruitementFormOptions = async () => {
            const professionData = this.getDropdownDataWithoutCombination(await this.props.getProfessionData(), "ProfessionCode", "Profession", "ProfessionID");
            const engagementData = this.getDropdownDataWithoutCombination(await this.props.getEngagementData(), "EngagementCode", "EngagmentName", "EngagementID")
            const incomeGroupDetails = this.getDropdownDataWithoutCombination(await this.props.getIncomeGroupDetails(), "IncomeGroupCode", "IncomeGroup", "IncomeGroupID")
            this.state.formData[2].columns[0].rows[0][0].options = this.getDropdownDataWithoutCombination(await this.props.getRecruitmentLeadType(), 'LeadTypeCode', 'LeadTypeName', 'LeadTypeID')
            this.state.formData[2].columns[0].rows[0][2].options = professionData;
            this.state.formData[2].columns[0].rows[0][3].options = engagementData;
            this.state.formData[2].columns[0].rows[0][4].options = incomeGroupDetails;
        }
        if (this.formName === RECRUITMENT_LEAD) {
            getRecruitementFormOptions()
        }
        const getBuisnessFormOptions = async () => {
            const agencyLeadSources = this.getDropdownDataWithoutCombination(await this.props.getAgencyLeadSource(), "LeadSourceCode", "LeadSourceName", "LeadSourceID")
            this.state.formData[2].columns[0].rows[0][0].options = agencyLeadSources
        }
        if (this.formName === BUSSINESS_LEAD) {
            getBuisnessFormOptions()
        }
    }
    getDropdownDataWithoutCombination = (optionsArr, key, label, value) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr?.map((option) => {
                return { key: option[key], label: `${option[label]}`, value: option[value] }
            })
        }
        arr.unshift({ "key": "select", "label": "select", "value": "" })
        return arr;
    }
    loadBreadCrumbs = () => {
        const breadcrumbs = [
            { name: 'Leads', url: '/app/lead/all' },
            { name: this.LeadTitle, url: '/createLead' }
        ]
        this.props.setBreadcrumbs(breadcrumbs);
    }
    getSelectedAutocompletList = (fullList, ids) => {
        const filteredList = fullList?.filter(e => ids?.includes(e.UserID?.toString())).map(el => {
            return { key: `${el['Name'] + " " + el['Code']}`, label: `${el['Name']}`, value: el['UserID'] }
        })
        return filteredList
    }

    getLeadDetail = async () => {
        setTimeout(() => {
            this.props.activateLoader();
        }, 2000);
        const [leadDetails, AgencyAdvisorMappingListData, AgencyAdvisorMappingListPremier, AgencyAdvisorMappingList] = await Promise.all([
            this.props.getLeadDetails({ id: this.id }),
            this.getAgencyAdvisorMapping("ZMA"),
            this.getAgencyAdvisorMapping("Premier ZMA"),
            this.getAgencyAdvisorMapping("Advisor")
        ]);
        const { AssignedTo, AssignedToName } = leadDetails
        this.setState({
            AssignedTo: AssignedTo,
            AssignedToName: AssignedToName
        })
        const { SalutationID, AlternateNo, Salutation, FirstName, MiddleName, LastName, MobileNo, Email, Location, ProductID, ProductName, ExpectedPremium,
            LeadTypeID, LeadTypeName, ActivityID, BranchID, BranchName, MeetingStartDateTime, MeetingEndDateTime, SPTypeID, PriorityID, EngagementID, ProfessionID,
            SPTypeName, ISleadHasMeeting, LeadSourceName, LeadSourceID, SPID, Remarks,
            CreatedDate, CreatedBy, ActivityName, FinanicalBackroundID, PinCode, Gender, DOB, IncomeGroupID, AdvisorID, ZmaID, PremierZMAID } = leadDetails.data;
        const ActiveLabel = this.state.autoCompleteItems.ActivityID.filter((Activate) => Activate.id === ActivityID)
        if (BranchID) {
            this.props.getLGValueReducer(BranchID)
        }
        console.log('this.props.masterDataState?.ZMAList ', this.props.masterDataState?.ZMAList);
        const zmaPremierZmalist = ZmaID ? this.getSelectedAutocompletList(this.props.masterDataState?.ZMAList, ZmaID?.split(","))
            : PremierZMAID ? this.getSelectedAutocompletList(this.props.masterDataState?.PremierZMA, PremierZMAID?.split(","))
                : []
        if (this.formName === BUSSINESS_LEAD) {

            this.setState({
                leadDetail: {
                    SalutationID: SalutationID?.toString(),
                    Salutation: Salutation,
                    FirstName: FirstName,
                    MiddleName: MiddleName,
                    LastName: LastName,
                    MobileNo: MobileNo,
                    Email: Email,
                    Location: Location,
                    ProductID: ProductID?.toString(),
                    ProductName: ProductName,
                    ExpectedPremium: ExpectedPremium,
                    LeadTypeID: LeadTypeID?.toString(),
                    LeadTypeName: LeadTypeName,
                    // ActivityName: ActivityName,
                    //  ActivityIDlabel: ActiveLabel[0]?.label,
                    SPTypeID: SPTypeID?.toString(), SPTypeName: SPTypeName,
                    ISleadHasMeeting: ISleadHasMeeting, LeadSourceID: LeadSourceID?.toString(),
                    LeadSourceName: LeadSourceName, BranchID: BranchID?.toString(), BranchName: BranchName, SPID: SPID?.toString(),
                    // LGID: LGID?.toString(),
                    PriorityID: PriorityID,
                    AlternateNo: AlternateNo,
                    Remarks: Remarks,
                    MeetingStartDateTime: MeetingStartDateTime,
                    MeetingEndDateTime: MeetingEndDateTime,
                    CreatedDate: CreatedDate, CreatedBy: CreatedBy,
                    AdvisorID: AdvisorID ? AdvisorID : this.getSelectedAutocompletList(this.props.masterDataState?.AdvisorList, AdvisorID?.split(",")),
                    AdvisorName: AdvisorID ? AdvisorID : this.getSelectedAutocompletList(this.props.masterDataState?.AdvisorList, AdvisorID?.split(",")),
                    ZmaID: ZmaID ? ZmaID ? ZmaID : zmaPremierZmalist : PremierZMAID ? PremierZMAID : zmaPremierZmalist,
                    ActivityDoneWith: ZmaID ? "1" : PremierZMAID ? "2" : ""
                }
            }, () => {
                let formData = this.initFormData(this.state.formData, this.formName)
                if (ZmaID && !PremierZMAID) {
                    formData[2].columns[0].rows[0][3].hide = false;
                    formData[2].columns[0].rows[0][4].hide = false;
                    formData[2].columns[0].rows[0][3].options = AgencyAdvisorMappingListData
                    formData[2].columns[0].rows[0][4].options = AgencyAdvisorMappingList

                }
                if (!ZmaID && PremierZMAID) {
                    formData[2].columns[0].rows[0][3].hide = false;
                    formData[2].columns[0].rows[0][4].hide = false;
                    formData[2].columns[0].rows[0][3].options = AgencyAdvisorMappingListPremier
                    formData[2].columns[0].rows[0][4].options = AgencyAdvisorMappingList

                }
                if (!ISleadHasMeeting) {
                    formData[2].columns[0].rows[0][7].validators = ""
                }
                console.log('BL after set ', this.state.leadDetail);
                this.setState({ formData: formData })
            })
        } else if (this.formName === RECRUITMENT_LEAD) {
            this.setState({
                leadDetail: {
                    SalutationID: SalutationID?.toString(),
                    Salutation: Salutation,
                    FirstName: FirstName,
                    MiddleName: MiddleName,
                    LastName: LastName,
                    MobileNo: MobileNo,
                    Email: Email,
                    PinCode: PinCode,
                    LeadTypeID: LeadTypeID?.toString(),
                    LeadTypeName: LeadTypeName,
                    ActivityName: ActivityName,
                    DOB: moment(DOB).format("YYYY-MM-DD"),
                    ActivityID: ActivityID?.toString(), ActivityIDlabel: ActiveLabel[0]?.label,
                    AlternateNo: AlternateNo,
                    ProfessionID: ProfessionID?.toString(),
                    EngagementID: EngagementID?.toString(),
                    IncomeGroupID: IncomeGroupID?.toString(),
                    FinanicalBackroundID: FinanicalBackroundID?.toString(),
                    Gender: Gender
                }
            }, () => {
                const formData = this.initFormData(this.state.formData, this.formName)
                this.setState({ formData: formData })
            })
        }
    }

    handleOnAction = (event) => {
        if (this.state.timeOutMsg) {
            setTimeout(() => {
                this.props.history.push("/app/home")
            }, 3000)
        }
    }
    handleOnActive = (event) => {
        if (this.idleTimer.getRemainingTime() === 0) {
            this.setState({
                ...this.state, timeOutMsg: this.idleTimer.getRemainingTime() === 0
            })
        }
    }
    handleOnIdle = (event) => {
    }

    getDropdownData = (optionsArr, id, label) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { "id": `${option[id]}`, "label": `${option[label]}` }
            })
        }
        arr.shift({ "id": " ", "label": "select" })
        arr.unshift({ "id": " ", "label": "select" })
        return arr;
    }

    componentDidMount = async () => {
        this.initMasterData();
        this.loadBreadCrumbs();
        if (this.id) {
            this.getLeadDetail()
        }
        const autoCompleteItems = { ...this.state.autoCompleteItems };
        autoCompleteItems.ProductID = this.getDropdownData(this.props.masterDataState.ProductDetails, "key", "label");
        if (this.formName === RECRUITMENT_LEAD) {
            autoCompleteItems.ActivityID = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 14)

        } else {
            autoCompleteItems.ActivityID = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 13)

        }
        this.setState({
            autoCompleteItems: autoCompleteItems,
            leadDetail: {
                ...this.state.leadDetail,
                MeetingStartDateTime: moment(new Date()).format(_formatDate),
                MeetingEndDateTime: moment(new Date()).add(30, "m")
                    .format(_formatDate),
                min: moment(new Date()).format(_formatDate),
                max: moment(new Date()).add(30, "m").format(_formatDate),
            }
        }, () => {
            if (!this.id) {
                const formData = this.initFormData(this.state.formData, this.formName)
                this.setState({ formData: formData })
            }
        })
        let getActualTiming = this.idleTimerData?.filter((elem)=>elem.key =="TimerSettingsForRedirect")
            //  new requirment changes for change idleTimerTiming BRD Activity new Lead flow v1.2
        if(getActualTiming.length >0){
            this.setState({
                idleTimerTiming:Number(getActualTiming[0].value)
            })
        }
    }
    initMasterData = () => {
        if (!this.id) {
            this.props.deActivateLoader()
        }
    }
    componentWillUnmount() {
        const formData = this.state.formData;
        formData[2].columns[0].rows[0].map((option) => {
            if (option.key === 'ZmaID' || option.key === 'AdvisorName') {
                option.hide = true;
            }
        })
        this.setState({ formData: formData })
    }

    onAutocompleteSelectHandler = (key, value, item) => {
        // this.setState({ selectActivityID: item.id })
        if (key === "ProductID") {
            this.setState({ selectProductID: item.id });
        } else {
            this.setState({ selectActivityID: item.id });
        }
    }
    onSubmitHandler = async (model) => {
        
        let isValidActivitiyLabel
        if (this.formName === RECRUITMENT_LEAD) {
            isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 14).some(ele => ele.label === model.ActivityIDlabel);

        } else {
            isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 13).some(ele => ele.label === model.ActivityIDlabel);

        }
        const isValidProductLabel = this.props.masterDataState.ProductDetails.some(ele => ele.label === model.ProductIDlabel);

        if (model.PinCode !== "") {
            model.errors.PinCode === "This field is required" && delete model.errors.PinCode
        }
        if (model.PriorityID !== "") {
            model.errors.PriorityID === "Please select lead status." && delete model.errors.PriorityID
        }
        // const modelData =[]
        if (this.formName === BUSSINESS_LEAD) {
            // const modelData = model.AdvisorName.filter((ele) => {
            //     return ele.key === "select"
            // })
            // if (modelData.length > 0) {
            //     const extError = { ...this.state.extErrors, 'AdvisorName': 'Please select valid advisor name' }
            //     this.setState({
            //         ...this.state,
            //         extErrors: extError
            //     })
            //     this.props.activateErrorMessageBar("Please select valid advisor name");
            //     return false;
            // }
        }
        if (!model.isValidForm || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors) > 0) {
            const errorListClasess = Object.keys(model.errors);
            const selectedErrorClass = errorListClasess[0] === "extError" ? errorListClasess[1] : errorListClasess[0];
            const errorElement = document.getElementsByClassName(`${'control-'}${selectedErrorClass}`)[0];
            if (errorElement) {
                errorElement.scrollIntoView({
                    block: 'center'
                });
                this.props.activateErrorMessageBar("Please fill all required fields");
            }
            if (model.ActivityIDlabel !== '' && isValidActivitiyLabel) {
                delete model.errors.ActivityIDlabel;
                const tempext = {
                    ...this.state.extErrors
                }
                delete tempext?.ActivityID
                this.setState({
                    ...this.state,
                    extErrors: tempext
                })
            }
            else if (!isValidActivitiyLabel) {
                const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }
                this.setState({
                    ...this.state,
                    extErrors: extError
                })
                return false;
            } else {
                const errorElementFirst = document.getElementsByClassName(`${'control-'}${errorListClasess[1]}`)[0];
                if (errorElementFirst) {
                    errorElementFirst.scrollIntoView({
                        block: 'center'
                    });
                    this.props.activateErrorMessageBar("Please fill all required fields");
                    return false;
                }
            }

            if (model.ProductIDlabel !== '' && isValidProductLabel) {
                delete model.errors.ProductIDlabel;
            }
            else if (!isValidProductLabel && this.formName !== RECRUITMENT_LEAD) {
                const extError = { ...this.state.extErrors, 'ProductID': 'Invalid ProductID' }
                this.setState({
                    ...this.state,
                    extErrors: extError
                })
                this.props.activateErrorMessageBar("Please fill all required fields");
                return false;
            }

            if (!model.isValidForm && Object.keys(model.errors).length > 0) {
                return false;
            }
        }
        if (model.ActivityIDlabel !== '' && isValidActivitiyLabel) {
            delete model.errors.ActivityIDlabel;
        }
        else if (!isValidActivitiyLabel) {
            const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }

            this.setState({
                ...this.state,
                extErrors: extError
            })
        }
        // if (
        //     !model.isValidForm ||
        //     (Object.keys(this.state.extErrors).length > 0 &&
        //         this.state.extErrors.MobileNo == undefined) ||
        //     Object.keys(model.errors).length > 0 ||
        //     !isValidActivitiyLabel
        // ) {
        //     this.props.activateErrorMessageBar(this.state.extErrors.MobileNo ? this.state.extErrors.MobileNo : "Please fill all required fields");
        //     return false;
        // }
        const data = { ...model };
        delete data['advanceLayoutFields'];
        delete data['collapseStates'];
        delete data['errors'];
        delete data['isValidForm'];
        delete data['id'];
        delete data['ActivityIDlabel'];
        data['LeadStatusId'] = 1; //'NEW';
        data['UserId'] = this.userId;
        data['EmployeeCode'] = this.employeeCode;
        data["CreatedBy"] = this.userId;
        data["ModifiedBy"] = this.userId;
        data["ChannelID"] = parseInt(this.channelID);

        // if (data.ZmaID) {
        //     const takeIdStringForLead = data.ZmaID?.map(el => {
        //         return el.value
        //     })
        //     data.ZmaID = takeIdStringForLead?.toString()
        // }
        if (model.ActivityDoneWith === "2") {
            //    data.ZmaID =takeIdStringForLead?.toString()
            data['PremierZMAID'] = data['ZmaID']
            delete data['ZmaID']
        }
        // if (data.AdvisorName) {
        //     const takeIdStringsForLeadAdvisor = []
        //     data.AdvisorName.map(adv => {
        //         takeIdStringsForLeadAdvisor.push(adv.value)
        //     })
        //     data.AdvisorName = takeIdStringsForLeadAdvisor?.toString()
        // }
        data["AssignedTo"] = this.userId;
        if (this.id) {
            data['IsBacklog'] = 0;
            data['LeadId'] = this.id;

        }
        if (data.ISleadHasMeeting) {
            data["MeetingStartDateTime"] = data["MeetingStartDateTime"].replace("T", ' ')
        } else {
            data["MeetingStartDateTime"] = null;
        }
        if (data.ISleadHasMeeting) {
            data["MeetingEndDateTime"] = data["MeetingEndDateTime"].replace("T", ' ')
        } else {
            data["MeetingEndDateTime"] = null;
        }
        console.log('on save ', data);
        // const { ProductDetails, Salutation } = this.props.masterDataState;
        // return false
        if (navigator.onLine && data.ISleadHasMeeting) {
            const res = await this.props.createLeadDeDupeValidation(data)
            if (res) {
                const validationResponse = await this.props.validateMeetingDateForCreateLead({
                    startDate:
                        this.state.startMeetingDate || data["MeetingStartDateTime"],
                    endDate:
                        this.state.endMeetingDate || data["MeetingEndDateTime"],
                    id: this.userId,
                    formData: data,
                    formBuilder: this.formName,
                    history: this.props.history,
                    leadId: this?.id,
                    leadTypeId: 1
                });
                const { Responsestatus, Responsemessage } = validationResponse.data;
                if (!Responsestatus) {
                    const extError = { ...this.state.extErrors, 'MeetingStartDateTime': 'Meeting already exits.', 'MeetingEndDateTime': 'Meeting already exits.' }
                    this.setState({ extErrors: extError })
                    this.props.activateErrorMessageBar(Responsemessage);
                    this.props.deActivateLoader();
                } else {
                    // lead id is present, so call Update lead
                    if (this.id) {
                        data['AssignedTo'] = this.state.AssignedTo;
                        data['AssignedToName'] = this.state.AssignedToName;
                        if (this.formName === RECRUITMENT_LEAD) {
                            data['IsRecruitmentLead'] = 1
                            this.props.UpdateLeadForm({ model: data, formBuilder: this.formBuilder, history: this.props.history })

                        } else {
                            this.props.UpdateLeadForm({ model: data, formBuilder: this.formBuilder, history: this.props.history })
                        }
                    } else {
                        if (this.formName === RECRUITMENT_LEAD) {
                            data['IsRecruitmentLead'] = 1
                            const result = await this.props.saveLeadForm({ 'data': data, 'formName': this.formName })
                            this.handleSaveLeadResponse(result, 'Created');
                        }
                        else {
                            const result = await this.props.saveLeadForm({ 'data': data, 'formName': this.formName })
                            this.handleSaveLeadResponse(result, 'Created');
                        }
                    }
                }
            }
        } else {
            if (this.id) {  // need to add it for save draft
                if (navigator.onLine) {
                    data['IsBacklog'] = 0;
                    data['LeadId'] = this.id;
                    const res = await this.props.createLeadDeDupeValidation(data);
                    if (res) {
                        data['AssignedTo'] = this.state.AssignedTo;
                        data['AssignedToName'] = this.state.AssignedToName;
                        if (this.formName === RECRUITMENT_LEAD) {
                            data['IsRecruitmentLead'] = 1
                            this.props.UpdateLeadForm({ model: data, formBuilder: this.formBuilder, history: this.props.history })

                        } else {
                            this.props.UpdateLeadForm({ model: data, formBuilder: this.formBuilder, history: this.props.history })
                        }
                    }
                }
            } else {
                if (navigator.onLine) {
                    const res = await this.props.createLeadDeDupeValidation(data)
                    if (res) {
                        if (this.formName === RECRUITMENT_LEAD) {
                            data['IsRecruitmentLead'] = 1
                            this.props.activateLoader();
                            const result = await this.props.saveLeadForm({ 'data': data, 'formName': this.formName })
                            this.handleSaveLeadResponse(result, 'Created');

                        } else {
                            this.props.activateLoader();
                            const result = await this.props.saveLeadForm({ 'data': data, 'formName': this.formName })
                            this.handleSaveLeadResponse(result, 'Created');
                        }

                    }
                }
            }
        }
    }

    onSaveDraft = async (e) => {
        const formData = { ...this.dyFormRef.state };
        e.stopPropagation();
        e.preventDefault();
        delete formData['advanceLayoutFields'];
        delete formData['collapseStates'];
        delete formData['errors'];
        delete formData['isValidForm'];
        delete formData['id'];
        delete formData['ActivityIDlabel'];
        formData['LeadStatusId'] = 11
        formData['UserId'] = this.userId;
        formData['AssignedTo'] = this.userId;
        formData['EmployeeCode'] = this.employeeCode;
        formData['ChannelID'] = parseInt(this.channelID);
        if (this.id) {
            formData['LeadId'] = this.id
        }
        formData['AssignedTo'] = this.userId;
        if (formData['SalutationID'] === undefined) {
            formData['SalutationID'] = null;
        }
        if (formData['ISleadHasMeeting']) {
            if (formData['MeetingStartDateTime']) {
                formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"].replace("T", ' ')
            } else {
                formData["MeetingStartDateTime"] = null;
            }
            if (formData["MeetingEndDateTime"]) {
                formData["MeetingEndDateTime"] = minusMinuteFromDate(formData["MeetingEndDateTime"]).replace("T", ' ')
            } else {
                formData["MeetingEndDateTime"] = null;
            }
        } else {
            formData["MeetingStartDateTime"] = null;
            formData["MeetingEndDateTime"] = null;
        }
        if (this.formName === RECRUITMENT_LEAD) {
            formData['IsRecruitmentLead'] = 1
        }
        // if (formData.ZmaID) {
        //     const takeIdStringForLead = formData.ZmaID?.map(el => {
        //         return el.value
        //     })
        //     formData.ZmaID = takeIdStringForLead?.toString()
        // }
        if (formData.ActivityDoneWith === "2") {
            //    data.ZmaID =takeIdStringForLead?.toString()
            formData['PremierZMAID'] = formData['ZmaID']
            delete formData['ZmaID']
        }
        // if (formData.AdvisorName) {
        //     const takeIdStringsForLeadAdvisor = []
        //     formData.AdvisorName.map(adv => {
        //         takeIdStringsForLeadAdvisor.push(adv.value)
        //     })
        //     formData.AdvisorName = takeIdStringsForLeadAdvisor?.toString()
        // }

        const { ProductDetails, Salutation } = this.props.masterDataState
        const mmData = {
            ...formData,
            "CreatedDate": moment(new Date()).format(createformat),
            "CreatedBy": this.userId,
            "ModifiedDate": moment(new Date()).format(createformat),
            "ModifiedBy": this.userId,
            "LmsleadGuid": uuidv4(),
            "LmsleadlastSyncTime": moment(new Date()).format(createformat),// Date.parse(new Date().toUTCString()),
            "IsLmsSync": navigator.onLine,
            "ActivityID": formData.ActivityID,
            "LeadStatus": 'Drafted',
            "ProductName": this.getNameForId(ProductDetails, formData.ProductID),
            "SalutationName": this.getNameForId(Salutation, formData.SalutationID),
            "IsBacklog": false
        }
        if (navigator.onLine) {
            this.props.activateLoader()
            const result = await this.props.saveLeadForm({ 'data': mmData, 'formName': this.formName })
            this.handleSaveLeadResponse(result, 'Drafted');
        } else {
            saveLeadOffline(mmData).then((res) => {
                if (res) {
                    this.props.history.push(`/app/lead/all`);
                }
            })
        }
    }
    getAgencyAdvisorMapping = async (status) => {
        this.props.activateLoader();
        const AgencyAdvisorMappingList = await this.props.getAdvisorZMAPremierZMAListing({
            userId: this.userId,
            status: status
        });
        this.props.deActivateLoader();
        let arr = [];
        if (AgencyAdvisorMappingList && AgencyAdvisorMappingList.data.length > 0) {
            if (status == "ZMA") {
                this.props.setZMAList(AgencyAdvisorMappingList.data)
            }
            if (status == "Advisor") {
                this.props.setAdvisorList(AgencyAdvisorMappingList.data)
            }
            if (status == "Premier ZMA") {
                this.props.setPremierZMAList(AgencyAdvisorMappingList.data)
            }
            arr = AgencyAdvisorMappingList.data.map((option) => {
                if (status == "ZMA" || status == "Advisor" || status == "Premier ZMA") {
                    return { key: `${option['Name']} - ${option['Code']}`, label: `${option['Name']}`, value: option['UserID'] }
                } else {
                    return { key: option['UserID'], label: `${option['Name']}`, value: option['UserID'] }
                }
            })
        }

        arr.unshift({ "key": "select", "label": "select", "value": "" })
        return arr;
    }
    onChangeHandler = async (event, key, model, newValue) => {
        
        const { formData } = this.state;
        if (this.id) {
            if (key === "ZmaID" || key === "PremierZMAID" || key === "AdvisorName") {
                const details = { ...this.state.leadDetail, [key]: newValue?.length > 0 ? event?.target?.value : [] }
                this.setState({ leadDetail: details })
            } else {
                const details = { ...this.state.leadDetail, [key]: event?.target?.value }
                this.setState({ leadDetail: details })
            }
        }
        let isValidActivitiyLabel;
        if (this.formName === RECRUITMENT_LEAD) {
            isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 14)
                .some(ele => ele.id === event.target?.value);

        } else if (this.formName === BUSSINESS_LEAD) {
            isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 13)
                .some(ele => ele.id === event.target?.value);

        } else {
            if (newValue != undefined && newValue?.length > 0 && (key === "ZmaID" || key === "PremierZMAID" || key === "AdvisorName")) {
                let uniqueIds = [];
                const unique = newValue.filter(element => {
                    const isDuplicate = uniqueIds.includes(element.key);
                    if (!isDuplicate) {
                        uniqueIds.push(element.key);
                        return true;
                    }
                    return false;
                });
                const details = { ...this.state.leadDetail, [key]: unique }
                this.setState({ leadDetail: details })
            }

        }
        if (this.formName === BUSSINESS_LEAD || this.formName === RECRUITMENT_LEAD) {
            switch (key) {
                case 'ProductID':
                    if (isNaN(event.target.value)) {
                        const extError = { ...this.state.extErrors, 'ProductID': 'Invalid ProductID.' }
                        this.setState({ extErrors: extError })
                    } else {
                        // this.setState({ extErrors: {} })
                        delete this.state.extErrors.ProductID;
                        delete model.errors.PriorityID;
                        delete model.errors.ProductIDlabel;
                    }
                    break;
                case 'ActivityID':
                    if (!isValidActivitiyLabel) {
                        const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }
                        this.setState({
                            ...this.state,
                            extErrors: extError
                        })
                    } else {
                        console.log(event.target.value, typeof (event.target.value))
                        if (this.id) {
                            this.setState({
                                leadDetail: {
                                    ...this.state.leadDetail,
                                    ActivityID: event.target.value
                                }
                            })
                        }
                        let activityIdLabelList
                        if (this.formName === RECRUITMENT_LEAD) {
                            activityIdLabelList = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 14)
                                .filter(ele => ele.id === event.target?.value)

                        } else {
                            activityIdLabelList = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid === 13)
                                .filter(ele => ele.id === event.target?.value)

                        }
                        // this.setState({ extErrors: {} })
                        delete this.state.extErrors.ActivityID;
                        this.setState({
                            labelForId: {
                                ...this.state.labelForId,
                                ActivityIDLabel: activityIdLabelList[0]?.label
                            }
                        })
                    }
                    break;
                case 'MobileNo':
                    if (event.target.value.length === 10) {
                        const mobileNo = event.target.value;
                        if (navigator.onLine) {
                            const mobileStatus = await this.props.checkMobileNumberReducer({ mobileNumber: event.target.value, userId: this.userId, formData: formData })
                            if (!mobileStatus) {
                                const extError = { 'MobileNo': 'Mobile number already exits.' }
                                this.setState({ extErrors: extError })
                            }
                            else {
                                // this.setState({ extErrors: {} })
                                delete this.state.extErrors.MobileNo;
                            }
                        } else {
                            isLeadValid(mobileNo).then((res) => {
                                if (!res) {
                                    const extError = { 'MobileNo': 'Mobile number already exits.' }
                                    this.setState({ extErrors: extError })
                                }
                                else {
                                    // this.setState({ extErrors: {} })
                                    delete this.state.extErrors.MobileNo;
                                }
                            })
                        }
                    }
                    break;
                case 'ISleadHasMeeting':
                    if (this.id) {
                        const hasMeeting = { ...this.state.leadDetail, ISleadHasMeeting: event }
                        this.setState({ leadDetail: hasMeeting })
                    }
                    if (event === true) {
                        formData[2].columns[0].rows[0][7].hide = false;
                        formData[2].columns[0].rows[0][8].hide = false;
                        formData[2].columns[0].rows[0][7].validators = [
                            {
                                "name": "required",
                                "value": true,
                                "validationMessage": ""
                            }
                        ]
                        formData[2].columns[0].rows[0][8].validators = [
                            {
                                "name": "required",
                                "value": true,
                                "validationMessage": ""
                            }
                        ]
                        this.setState({
                            ...this.state,
                            defaultFormVal: {
                                ...this.state.defaultFormVal, ISleadHasMeeting: true
                            }
                        })
                    } else {
                        this.setState({
                            ...this.state, startMeetingDate: null, endMeetingDate: null,
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: '', MeetingStartDateTime: '', ISleadHasMeeting: false
                            }
                        })
                        this.setState({ extErrors: {} })
                        formData[2].columns[0].rows[0][7].hide = true;
                        formData[2].columns[0].rows[0][8].hide = true;
                        formData[2].columns[0].rows[0][7].validators = ""
                        formData[2].columns[0].rows[0][8].validators = ""
                    }
                    break;

                case 'MeetingStartDateTime':
                    this.setState({ extErrors: { MeetingEndDateTime: null, MeetingStartDateTime: null } })
                    delete model.errors['MeetingEndDateTime']
                    var startDate = moment(event.target.value).format(_formatDate);
                    formData[2].columns[0].rows[0].map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            option.max = moment(startDate).endOf('day').format("YYYY-MM-DD kk:mm");
                            option.min = startDate;
                        }
                    })

                    const endMeetDate = moment(startDate).add(30, 'm').toString();
                    formData[2].columns[0].rows[0][8].min = startDate
                    if (this.id) {
                        this.setState({
                            ...this.state, extErrors: {},
                            leadDetail: { ...this.state.leadDetail, MeetingStartDateTime: startDate, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss") }, startMeetingDate: startDate.replace("T", ' '), endMeetingDate: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss").replace("T", ' '),
                        })
                    } else {
                        this.setState({
                            ...this.state, extErrors: {}, endMeetingDate: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss").replace("T", ' '),
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss"), MeetingStartDateTime: event.target.value
                            }
                        })
                        if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
                            this.setState({
                                ...this.state, extErrors: { ...this.state.extErrors, "MeetingStartDateTime": "please select future value" }, defaultFormVal: {
                                    ...this.state.defaultFormVal, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss"), MeetingStartDateTime: event.target.value
                                }
                            })
                        } else {
                            this.setState({
                                ...this.state, extErrors: {}, defaultFormVal: {
                                    ...this.state.defaultFormVal, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss"), MeetingStartDateTime: event.target.value
                                }
                            })
                        }
                    }
                    break;
                case 'MeetingEndDateTime':
                    const endDate = event.target.value;
                    this.setState({ endMeetingDate: endDate.replace("T", ' ') })
                    this.setState({
                        ...this.state,
                        defaultFormVal: {
                            ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                        }
                    })
                    if (!checkDateIsValid(endDate, this.state.defaultFormVal.MeetingStartDateTime)) {
                        this.setState({
                            ...this.state, extErrors: { ...this.state.extErrors, "MeetingEndDateTime": "meeting end date should not less than meeting starting date" },
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                            }
                        })
                    } else {
                        this.setState({
                            ...this.state, extErrors: {},
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                            }
                        })
                    }
                    break;
                case 'Location':
                    if (this.id) {
                        this.setState({
                            ...this.state, leadDetail: { ...this.state.leadDetail, Location: event }
                        })
                    }
                    break;
                case 'ActivityDoneWith':
                    formData[2].columns[0].rows[0][3].options = [];
                    // this.props.activateLoader();
                    if (this.id) {
                        this.setState({
                            leadDetail: {
                                ...this.state.leadDetail,
                                ZmaID: [],
                                ActivityDoneWith: event.target.value
                            }
                        })
                    }

                    if (event.target.value === "1") {
                        this.props.activateLoader()
                        const AgencyAdvisorMappingListData = await this.getAgencyAdvisorMapping("ZMA");
                        // const formDetails = [...this.state.formData]
                        // formData[2].columns[0].rows[0][3].options=[];
                        formData[2].columns[0].rows[0][3].hide = false;
                        formData[2].columns[0].rows[0][3].options = AgencyAdvisorMappingListData
                        this.props.deActivateLoader();
                        // const formDetails = [...this.state.formData]

                        // this.setState({ formData: formDetails })
                    }
                    else if (event.target.value === "2") {
                        this.props.activateLoader()
                        const AgencyAdvisorMappingListPremier = await this.getAgencyAdvisorMapping("Premier ZMA");
                        // const formDetail = [...this.state.formData]
                        // formData[2].columns[0].rows[0][3].options=[];
                        formData[2].columns[0].rows[0][3].hide = false;
                        formData[2].columns[0].rows[0][3].options = AgencyAdvisorMappingListPremier
                        this.props.deActivateLoader();
                        // this.setState({ formData: formDetail })
                    }

                    break;
                case 'ZmaID':
                    if (this.id) {
                        // this.setState({
                        //     leadDetail:{
                        //         ...this.state.leadDetail,
                        //         ZmaID: event.target.value.split(",")
                        //     }
                        // })
                    }
                    this.props.activateLoader()
                    formData[2].columns[0].rows[0][4].hide = false;
                    const AgencyAdvisorMappingList = await this.getAgencyAdvisorMapping("Advisor");
                    const form = [...this.state.formData]
                    formData[2].columns[0].rows[0][4].options = AgencyAdvisorMappingList
                    this.props.deActivateLoader();
                    this.setState({ formData: form })
                    break;
                default:
                    break;
            }

        }
        else {
            console.log('inside of non BL and RL');
            switch (key) {
                case 'ActivityID':
                    if (!isValidActivitiyLabel) {
                        const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }
                        this.setState({
                            ...this.state,
                            extErrors: extError
                        })
                    } else {
                        const activityIdLabelList = this.props.leadFormData.Activities.ActivityID.filter(ele => ele.id === event.target?.value)
                        this.setState({ extErrors: {} })
                        this.setState({
                            labelForId: {
                                ...this.state.labelForId,
                                ActivityIDLabel: activityIdLabelList[0]?.label
                            }
                        })
                    }
                    break;
                case 'ProductID':
                    if (isNaN(event.target.value)) {
                        const extError = { 'ProductID': 'Invalid ProductID.' }
                        this.setState({ extErrors: extError })
                    } else {
                        this.setState({ extErrors: {} })
                        delete model.errors.PriorityID;
                        delete model.errors.ProductIDlabel;
                    }
                    break;
                case 'MobileNo':
                    if (event.target.value.length === 10) {
                        const mobileNo = event.target.value;
                        if (navigator.onLine) {
                            const mobileStatus = await this.props.checkMobileNumberReducer({ mobileNumber: event.target.value, userId: this.userId, formData: formData })
                            if (!mobileStatus) {
                                const extError = { 'MobileNo': 'Mobile number already exits.' }
                                this.setState({ extErrors: extError })
                            }
                            else {
                                this.setState({ extErrors: {} })
                            }
                        } else {
                            isLeadValid(mobileNo).then((res) => {
                                if (!res) {
                                    const extError = { 'MobileNo': 'Mobile number already exits.' }
                                    this.setState({ extErrors: extError })
                                }
                                else {
                                    this.setState({ extErrors: {} })
                                }
                            })
                        }
                    }
                    break;
                case 'ISleadHasMeeting':
                    if (this.id) {
                        const ISleadHasMeeting = { ...this.state.leadDetail, ISleadHasMeeting: event }
                        this.setState({ leadDetail: ISleadHasMeeting })
                    }
                    if (event === true) {
                        formData[2].columns[0].rows[0][4].hide = false;
                        formData[2].columns[0].rows[0][5].hide = false;
                        formData[2].columns[0].rows[0][4].validators = [
                            {
                                "name": "required",
                                "value": true,
                                "validationMessage": ""
                            }
                        ]
                        formData[2].columns[0].rows[0][5].validators = [
                            {
                                "name": "required",
                                "value": true,
                                "validationMessage": ""
                            }
                        ]
                        this.setState({
                            ...this.state,
                            defaultFormVal: {
                                ...this.state.defaultFormVal, ISleadHasMeeting: true
                            }
                        })
                    } else {
                        this.setState({
                            ...this.state, startMeetingDate: null, endMeetingDate: null,
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: '', MeetingStartDateTime: '', ISleadHasMeeting: false
                            }
                        })
                        this.setState({ extErrors: {} })
                        formData[2].columns[0].rows[0][4].hide = true;
                        formData[2].columns[0].rows[0][5].hide = true;
                        formData[2].columns[0].rows[0][4].validators = ""
                        formData[2].columns[0].rows[0][5].validators = ""
                    }
                    break;

                case 'MeetingStartDateTime':
                    this.setState({ extErrors: { MeetingEndDateTime: null, MeetingStartDateTime: null } })
                    delete model.errors['MeetingEndDateTime']
                    var startDateTime = moment(event.target.value).format(_formatDate);
                    formData[2].columns[0].rows[0].map((option) => {
                        if (option.key === "MeetingEndDateTime") {
                            option.max = moment(startDateTime).endOf('day').format("YYYY-MM-DD kk:mm");
                            option.min = startDateTime;
                        }
                    })

                    const endMeetDate = moment(startDateTime).add(30, 'm').toString();
                    formData[2].columns[0].rows[0][5].min = startDateTime
                    if (this.id) {
                        this.setState({
                            ...this.state, extErrors: {},
                            leadDetail: { ...this.state.leadDetail, MeetingStartDateTime: startDateTime, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss") }, startMeetingDate: startDateTime.replace("T", ' '), endMeetingDate: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss").replace("T", ' '),
                        })
                    } else {
                        this.setState({
                            ...this.state, extErrors: {}, endMeetingDate: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss").replace("T", ' '),
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss"), MeetingStartDateTime: event.target.value
                            }
                        })
                        if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
                            this.setState({
                                ...this.state, extErrors: { ...this.state.extErrors, "MeetingStartDateTime": "please select future value" }, defaultFormVal: {
                                    ...this.state.defaultFormVal, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss"), MeetingStartDateTime: event.target.value
                                }
                            })
                        } else {
                            this.setState({
                                ...this.state, extErrors: {}, defaultFormVal: {
                                    ...this.state.defaultFormVal, MeetingEndDateTime: moment(endMeetDate).format("YYYY-MM-DDTHH:mm:ss"), MeetingStartDateTime: event.target.value
                                }
                            })
                        }
                    }
                    break;
                case 'MeetingEndDateTime':
                    const endDate = event.target.value;
                    this.setState({ endMeetingDate: endDate.replace("T", ' ') })
                    this.setState({
                        ...this.state,
                        defaultFormVal: {
                            ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                        }
                    })
                    if (!checkDateIsValid(endDate, this.state.defaultFormVal.MeetingStartDateTime)) {
                        this.setState({
                            ...this.state, extErrors: { ...this.state.extErrors, "MeetingEndDateTime": "meeting end date should not less than meeting starting date" },
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                            }
                        })
                    } else {
                        this.setState({
                            ...this.state, extErrors: {},
                            defaultFormVal: {
                                ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
                            }
                        })
                    }
                    break;
                case 'Location':
                    if (this.id) {
                        this.setState({
                            ...this.state, leadDetail: { ...this.state.leadDetail, Location: event }
                        })
                    }
                    break;
                case 'ActivityDoneWith':
                    // this.props.activateLoader();
                    if (event.target.value === "1") {
                        this.props.activateLoader()
                        const AgencyAdvisorMappingListData = await this.getAgencyAdvisorMapping("ZMA");
                        const formDetail = [...this.state.formData]
                        formData[2].columns[0].rows[0][7].hide = false;
                        formData[2].columns[0].rows[0][7].options = AgencyAdvisorMappingListData
                        this.props.deActivateLoader();
                        this.setState({ formData: formDetail })
                    }
                    else if (event.target.value === "2") {
                        this.props.activateLoader()
                        const AgencyAdvisorMappingListPremier = await this.getAgencyAdvisorMapping("Premier ZMA");
                        const forms = [...this.state.formData]
                        formData[2].columns[0].rows[0][7].hide = false;
                        formData[2].columns[0].rows[0][7].options = AgencyAdvisorMappingListPremier
                        this.props.deActivateLoader();
                        this.setState({ formData: forms })
                    }

                    break;
                case 'ZmaID':
                    this.props.activateLoader()
                    formData[2].columns[0].rows[0][8].hide = false;
                    const AgencyAdvisorMappingList = await this.getAgencyAdvisorMapping("Advisor");
                    const form = [...this.state.formData]
                    formData[2].columns[0].rows[0][8].options = AgencyAdvisorMappingList
                    this.props.deActivateLoader();
                    this.setState({ formData: form })
                    break;
                default:
                    break;
            }
        }
    }

    onCheckinHandler = (position, address) => {
        if (this.id) {
            this.setState({
                ...this.state.leadDetail, Location: address
            })
        }
    }



    initFormData = (formFields, actionType) => {
        const formData = [...formFields]
        const { ProductDetails, Salutation, LeadSource, PriorityStatus } = this.props.masterDataState;

        let arrPriorityStatus = PriorityStatus.slice(0, 4);
        formData[0].columns[0].rows[0][0].options = Salutation;
        switch (actionType) {
            case BANCA_LEAD:
                this.setState({ ...this.state, accordianFieldSet: { fieldset1: true, fieldset2: true, fieldset3: true } });
                formData[2].columns[0].rows[0][10].options = ProductDetails
                formData[2].columns[0].rows[0][0].options = arrPriorityStatus
                formData[2].columns[0].rows[0][3].options = LEAD_HAS_MEETING
                // formData[2].columns[0].rows[0][3].options = SPType
                formData[2].columns[0].rows[0][1].options = LeadSource
                formData[2].columns[0].rows[0][6].options = MEETING_TYPE_ZMA_PZMA
                /** Here data will come */

                if (Object.keys(this.state.leadDetail).length > 0) {
                    this.props.deActivateLoader()
                    const { ISleadHasMeeting } = this.state.leadDetail

                    if (ISleadHasMeeting) {
                        formData[2].columns[0].rows[0][4].hide = false;
                        formData[2].columns[0].rows[0][5].hide = false;
                    } else {
                        formData[2].columns[0].rows[0][4].hide = true;
                        formData[2].columns[0].rows[0][5].hide = true;
                    }
                } else {
                    formData[2].columns[0].rows[0][4].hide = true;
                    formData[2].columns[0].rows[0][5].hide = true;
                    // formData[2].columns[0].rows[0][6].hide = true;
                    // formData[2].columns[0].rows[0][5].hide = true;
                }
                break;
            case BUSSINESS_LEAD:
                // formData[5].options = ProductDetails
                // formData[14].options = LeadSource

                this.setState({ ...this.state, accordianFieldSet: { fieldset1: true, fieldset2: true, fieldset3: true } });
                formData[2].columns[0].rows[0][10].options = ProductDetails
                formData[1].columns[0].rows[0][0].options = arrPriorityStatus
                formData[2].columns[0].rows[0][3].options = LEAD_HAS_MEETING
                // formData[2].columns[0].rows[0][3].options = SPType
                // formData[2].columns[0].rows[0][0].options = LeadSource
                formData[2].columns[0].rows[0][2].options = MEETING_TYPE_ZMA_PZMA
                /** Here data will come */

                if (Object.keys(this.state.leadDetail).length > 0) {
                    this.props.deActivateLoader()
                    const { ISleadHasMeeting } = this.state.leadDetail

                    if (ISleadHasMeeting) {
                        formData[2].columns[0].rows[0][7].hide = false;
                        formData[2].columns[0].rows[0][8].hide = false;
                    } else {
                        formData[2].columns[0].rows[0][7].hide = true;
                        formData[2].columns[0].rows[0][8].hide = true;
                    }
                } else {
                    formData[2].columns[0].rows[0][7].hide = true;
                    formData[2].columns[0].rows[0][8].hide = true;
                    // formData[2].columns[0].rows[0][6].hide = true;
                    // formData[2].columns[0].rows[0][5].hide = true;
                }
                break;
            case RECRUITMENT_LEAD:
                this.setState({ ...this.state, accordianFieldSet: { fieldset1: true, fieldset2: true, fieldset3: true } });
                // formData[2].columns[0].rows[0][0].options = this.props.masterDataState.LeadType
                formData[0].columns[0].rows[0][4].options = GENDER
                break;
            default:

                break;
        }
        return formData
    }


    toggleFieldsetAccordian = (collapseId) => {
        this.dyFormRef.toggleCollapse(collapseId);
    }

    renderFieldsetAccordianLabel = (collapseId) => {
        const buttonStyle = {
            float: 'right',

            cursor: 'pointer',
            color: '#000000',
        }

        const accordianClass = this.dyFormRef?.state?.collapseStates[collapseId]
        return (
            <div className="accordHead">
                <span style={buttonStyle} onClick={(e) => this.toggleFieldsetAccordian(collapseId)}>{!accordianClass ? "+" : "-"}</span>
                <strong>
                    {this.formName === BUSSINESS_LEAD ? collapseId === 'fieldset1' ? 'Lead Personal Details' : collapseId === 'fieldset2' ? 'Business Details' : collapseId === 'fieldset3' ? 'Others' : '' : ""}
                    {this.formName === RECRUITMENT_LEAD ? collapseId === 'fieldset1' ? 'Lead Personal Details' : collapseId === 'fieldset2' ? 'Address' : collapseId === 'fieldset3' ? 'Others' : '' : ''}

                </strong>
            </div>
        );
    }


    onCancelHandler = () => {
        const isMobile = Math.min(window.screen.width, window.screen.height) < 480 || navigator.userAgent.indexOf("Mobi") > -1;
        if (isMobile) {
            this.setState({ isActiveMobileDraft: true })
        }
        else {
            this.props.history.push(LEADS_PATH)
        }
    }

    toggleDrawer = (flag) => {
        this.setState({ isActiveMobileDraft: flag });
    }

    onDontSaveDraftHandler = () => {
        this.props.history.push(LEADS_PATH)
    }

    onSaveDraftHandler = () => {
        this.onSaveDraft(new Event('test'))
    }
    getNameForId = (optionArr, inputId) => {
        const matchEl = optionArr.filter((option) => (option.key === inputId || option.value === inputId) ? option.label : '')
        if (matchEl && matchEl.length > 0) {
            return matchEl[0].label
        }
        return null
    }
    handleSaveLeadResponse = (result, mode) => {
        if (result.status === 200 && result.data.Responsestatus === true) {
            this.props.activateSuccessMessageBar(`Lead has been ${mode} successfully`);
            this.props.deActivateLoader();
            if (mode === 'Created') {
                this.props.history.push(`/app/lead/detail/${result.data.Transactionid}`)
            } else if (mode === 'Drafted') {
                if (this.formName === BUSSINESS_LEAD) { this.props.history.push('/app/businesslead/all') }
                if (this.formName === RECRUITMENT_LEAD) { this.props.history.push('/app/recruitmentlead/all') }

            }
        } else {
            this.props.deActivateLoader();
            this.props.activateSuccessMessageBar("Something went wrong! please try again later.");
        }
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * this.state.idleTimerTiming} //  new requirment changes for change idleTimerTiming BRD Activity new Lead flow v1.2
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <Snackbar open={this.state.timeOutMsg} autoHideDuration={3000} >
                    <Alert severity="error" >Your session has been expired! You will be redirected to homepage in 3 seconds.</Alert>
                </Snackbar>
                <div className="mainContainer createLead">
                    <MessageBar />
                    <Breadcrumb />
                    <span className="formInfo">{t('Please fill in the lead information for Agency')}</span>
                    <div className="formBox">
                        <DynamicForm
                            key={1}
                            onRef={ref => this.dyFormRef = ref}
                            className="dynamicForm"
                            buttonSubmit={t('Submit')}
                            defaultValues={this.id ? this.state.leadDetail : this.state.defaultFormVal}
                            model={this.state.formData}
                            onSubmit={(model) => this.onSubmitHandler(model)}
                            disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
                            design="advanceBootstrap"
                            materialOutline={false}
                            onChange={(event, key, model, newValue) => this.onChangeHandler(event, key, model, newValue)}
                            onAutocompleteSelect={this.onAutocompleteSelectHandler}
                            autoCompleteItems={this.state.autoCompleteItems}
                            customValidators={customValidators}
                            showAstersikOnRequiredFields={true}
                            extErrors={this.state.extErrors}
                            checkInConfig={checkInConfig}
                            onCheckinHandler={this.onCheckinHandler}
                            renderFieldsetAccordianLabel={this.renderFieldsetAccordianLabel}
                            defaultAccordianOpenStates={this.state.accordianFieldSet}
                            extaraActionFormButton={<>
                                <a className="savedraftLink" onClick={(e) => this.onSaveDraft(e)}>SAVE AS DRAFT</a>
                                <button color="indigo" className="btn cancelBtn" key="extra" id="extra" type="button" onClick={(e) => this.onCancelHandler(e)}>Cancel</button>

                            </>}
                        />
                    </div>
                    <Footer />
                    {this.props.masterDataState.isActiveLoader && <Loader />}
                    <SaveAsDraftDrawer
                        isActiveMobileDraft={this.state.isActiveMobileDraft}
                        toggleDrawer={this.toggleDrawer}
                        onDontSaveHandler={this.onDontSaveDraftHandler}
                        onSaveDraftHandler={this.onSaveDraftHandler}
                    ></SaveAsDraftDrawer>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    leadFormData: state.leadFormReducer,
    masterDataState: state.commonReducer
});
const mapDispatchToProps = dispatch => ({
    // getAutoCompleteActivities: (payload) => dispatch(getAutoCompleteActivities(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    validateMeetingDateStatusInCreate: (payload) => dispatch(validateMeetingDateStatusInCreate(payload)),
    validateMeetingDateForCreateLead: (payload) => dispatch(validateMeetingDateForCreateLead(payload)),
    saveLeadForm: (payload) => dispatch(saveLeadForm(payload)),
    getLGValueReducer: (payload) => dispatch(getLGValueReducer(payload)),
    activateLoader: (payload) => dispatch(activateLoader(payload)),
    deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
    activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
    checkMobileNumberReducer: (payload) => dispatch(checkMobileNumberReducer(payload)),
    getLeadDetails: (payload) => dispatch(getLeadDetails(payload)),
    UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
    createLeadDeDupeValidation: (payload) => dispatch(createLeadDeDupeValidation(payload)),
    getAdvisorZMAPremierZMAListing: (payload) => dispatch(getAdvisorZMAPremierZMAListing(payload)),
    setZMAList: (payload) => dispatch(setZMAList(payload)),
    setAdvisorList: (payload) => dispatch(setAdvisorList(payload)),
    setPremierZMAList: (payload) => dispatch(setPremierZMAList(payload)),
    getSelectedDraftLeadData: (payload) => dispatch(getSelectedDraftLeadData(payload)),
    getProfessionData: () => dispatch(getProfessionData()),
    getEngagementData: () => dispatch(getEngagementData()),
    getIncomeGroupDetails: () => dispatch(getIncomeGroupDetails()),
    getAgencyLeadSource: () => dispatch(getAgencyLeadSourceData()),
    getRecruitmentLeadType: () => dispatch(getRecruitmentLeadType())
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(AgencyCreateLead));
