import { createSlice } from '@reduxjs/toolkit';

const createLeadSlice = createSlice({
    name: "createLead",
    initialState: {
      createLead: {},
      isLoading: false,
      error: false,
    },
    reducers: {
      createLeadLoading: state => {
        state.isLoading = true;
      },
      createLeadSuccess: (state, action) => {
        state.createLead = action.payload;
        state.isLoading = false;
      },
      createLeadError: (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      },
    }
  });


  export const {createLeadLoading,createLeadError,createLeadSuccess} = createLeadSlice.actions
  export default createLeadSlice.reducer;

