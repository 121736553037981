import {
  BRANCH_TRAINING, BRANCH_VISIT, EXPENCE_CLAIM, FINISH_DAY, HNI_MEET, ICE_E_SAMPARK, KYB,
  PUBLIC_TRANSPORT, CLAIM_TRAVEL_EXPENCE_OPTIONS, CONFIGS, BDF,
  DROPDOWN_ACTIVITY_TYPE, DROPDOWN_EXPENCE_CLAIM, DROPDOWN_MODE_OF_TRANSPORT, ROLE_BDM, ALLOWED_FUTURE_DAYS, ICE_FOR_LOAN, HNI_LEAD_GENERATION
} from "../../../../utils/config";
import { format } from 'date-fns'
import moment from "moment";

import { getDateAfterDays } from "../../../../utils/utils";
const branchNameLabel = "Branch Name & code";
const branchNameRequired = "Branch name is required.";
const maxFourDigit = "Maximum 4 digits allowed";
const maxTwoDigit = "Maximum 2 digits allowed";
const dateFormate = 'yyyy-MM-dd';
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";
const meetingStartDateAndTimeRequired = "Meeting start time is required.";
const meetingEndDateAndTimeRequired = "Meeting end time is required."
const minDate = format(new Date(), dateFormate) + "T" + format(new Date(), 'kk:mm');
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");
const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
const datetimelocal = "datetime-local"
export const GCLActivityForm = [
  {
    "key": "ActivityID", "type": "select", "id": "ActivityID", "label": "Activity Type",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Activity type is required." }],
    "options": []
  },
]

export const GCLBranchVisitForm = [
  {
    "key": "BranchId", "type": "autocomplete", "id": "BranchId", "label": "Branch Name & code",
    "validators": [{ "name": "required", "value": true, "validationMessage": branchNameRequired }]
  },
  {
    "key": "ExpectedLead", "props": { "min": "0" }, "type": "number", "id": "ExpectedLead", "label": "# Expected Leads",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "ExpectedConversion", "props": { "min": "0" }, "type": "number", "disabled": false, "id": "ExpectedConversion", "label": "# Expected Conversion",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "TicketSize", "props": { "min": "0" }, "type": "number", "id": "TicketSize", "label": "Average Ticket Size",
    "validators": [{ "name": "maxLength", "value": 8, "validationMessage": "Maximum 8 digits allowed" }]
  },
  {
    "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
    "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
    "type": datetimelocal, "id": "MeetingStartDateTime",
    "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
  },
  {
    "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
    "max": endMaxDate,
    "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
  },
  {
    "key": "GCLActivitytypeID", "type": "select", "id": "GCLActivitytypeID", "label": "Type",
    "validators": [{ "name": "required", "value": true, "validationMessage": " Type is required." }],
    "options": []
  },
    { "key": "Remarks", "hide": false, "type": "textarea", "rows":"3", "disabled":false, "id":"Remarks", "label": "Remarks",
    "validators": [{ "name": "required", "value": true, "validationMessage": " Type is required." }]},
]

export const GCLIceESamparkForm = [
  {
    "key": "ActivitySubTypeId", "hide": false, "type": "select", "disabled": false, "id": "ActivitySubTypeId", "label": "Sub Activity Type",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Sub activity type is required." }], "options": []
  },
  {
    "key": "BranchId", "hide": false, "type": "autocomplete", "disabled": false, "id": "BranchId", "label": branchNameLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": branchNameRequired }]
  },
  {
    "key": "ExpectedLead", "props": { "min": "0" }, "hide": false, "type": "number", "disabled": false, "id": "ExpectedLead", "label": "# of Expected Leads",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "ExpectedConversion", "props": { "min": "0" }, "hide": false, "type": "number", "disabled": false, "id": "ExpectedConversion", "label": "# Expected Conversion",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "TicketSize", "props": { "min": "0" }, "hide": false, "type": "number", "disabled": false, "id": "TicketSize", "label": " Average Ticket Size",
    "validators": [{ "name": "maxLength", "value": 8, "validationMessage": "Maximum 8 digits allowed" }]
  },
  {
    "key": "MeetingStartDateTime", "noDate": null, "hide": false, "type": datetimelocal, "disabled": false, "id": "MeetingStartDateTime",
    "min": minDate,
    "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
    "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
  },
  {
    "key": "MeetingEndDateTime", "noDate": null, "hide": false, "type": datetimelocal, "disabled": false, "id": "MeetingEndDateTime",
    "min": minDate,
    "max": endMaxDate,
    "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
  },
  // { "key": "Remarks", "hide": false, "type": "textarea", "rows":"3", "disabled":false, "id":"Remarks", "label": "Remarks, if any",
  // "validators":[{"name":"maxLength", "value":250, "validationMessage": "Maximum 250 characters allowed."}]},
]

export const GCLBranchTrainingActivityForm = [
  {
    "key": "TrainingTopic", "hide": false, "type": "text", "disabled": false, "id": "trainingTopic", "label": "Training Topic",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Training topic is required." }]
  },
  {
    "key": "BranchId", "hide": false, "type": "autocomplete", "disabled": false, "id": "BranchId", "label": branchNameLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": branchNameRequired }]
  },
  {
    "key": "MeetingStartDateTime", "noDate": null, "hide": false, "type": datetimelocal, "disabled": false, "id": "MeetingStartDateTime",
    "min": minDate,
    "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
    "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
  },
  {
    "key": "MeetingEndDateTime", "noDate": null, "hide": false, "type": datetimelocal, "disabled": false, "id": "MeetingEndDateTime",
    "min": minDate,
    "max": endMaxDate,
    "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
  },
]

export const GCLExpenceClaimActivity = [
  {
    "key": "ExpenseTypeId", "hide": false, "type": "select", "disabled": false, "id": "utilityCategory", "label": "Select Utility Category",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Utility category is required." }], "options": []
  },
  {
    "key": "ExpenseAmount", "props": { "min": "0" }, "hide": false, "type": "number", "disabled": false, "id": "expenceAmount", "label": "Expense Amount",
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Expense amount is required." }, { "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }
    ]
  },
  {
    "key": "BillDate", "max": format(new Date(), dateFormate), "noDate": null, "hide": false, "type": "date", "disabled": false, "id": "billDate", "label": "Bill Date",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Bill date is required." }]
  },
  {
    "key": "BillNumber", "props": { "min": "0" }, "hide": false, "type": "number", "disabled": false, "id": "billNumber", "label": "Bill Number",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Bill number is required." }]
  },
  {
    "key": "UploadBill", "hide": false, "type": "file", "dropTitle": "Upload bill photo",
    "acceptFileTypes": ["image/jpeg", "image/jpg", "image/png", "application/pdf"],
    "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": true,
    "id": "billPhoto", "label": "Bill Photo",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Bill photo is required." }]
  },
]

export const GCLFinishDayForm = [
  {
    "key": "IsClaimRequired", "name": "IsClaimRequired", "type": "CustomSwitch", "id": "IsClaimRequired", "label": "Do you want to Claim Travel Expense?", handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: "1px solid #ddd",
    activeBoxShadow: "0px 0px 1px 2px #fffc35",
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>
  }]

export const GCLICELoanActivityForm = [
  {
    "key": "BranchId", "type": "autocomplete", "id": "BranchId", "label": branchNameLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": branchNameRequired }]
  },
  {
    "key": "ExpectedLead", "props": { "min": "0" }, "type": "number", "id": "ExpectedLead", "label": "# of Expected Leads",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "ExpectedConversion", "props": { "min": "0" }, "type": "number", "disabled": false, "id": "ExpectedConversion", "label": "# Expected Conversion",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "TicketSize", "props": { "min": "0" }, "type": "number", "id": "TicketSize", "label": "Average Ticket Size",
    "validators": [{ "name": "maxLength", "value": 8, "validationMessage": "Maximum 8 digits allowed" }]
  },
  {
    "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
    "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
    "type": datetimelocal, "id": "MeetingStartDateTime",
    "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
  },
  {
    "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
    "max": endMaxDate,
    "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
  },
]

export const GCLHNILeadGenerationForm = [
  {
    "key": "BranchId", "type": "autocomplete", "id": "BranchId", "label": branchNameLabel,
    "validators": [{ "name": "required", "value": true, "validationMessage": branchNameRequired }]
  },
  {
    "key": "ExpectedLead", "props": { "min": "0" }, "type": "number", "id": "ExpectedLead", "label": "# of Expected Leads",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "ExpectedConversion", "props": { "min": "0" }, "type": "number", "disabled": false, "id": "ExpectedConversion", "label": "# Expected Conversion",
    "validators": [{ "name": "maxLength", "value": 2, "validationMessage": maxTwoDigit }]
  },
  {
    "key": "TicketSize", "props": { "min": "0" }, "type": "number", "id": "TicketSize", "label": "Average Ticket Size",
    "validators": [{ "name": "maxLength", "value": 8, "validationMessage": "Maximum 8 digits allowed" }]
  },
  {
    "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
    "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
    "type": datetimelocal, "id": "MeetingStartDateTime",
    "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
  },
  {
    "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
    "max": endMaxDate,
    "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
  },
]


export const GCLExpenceClaimForm = [
  {
    "key": "TransportModeID", "type": "radio", "label": "Mode of Transport",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Mode of transport is required." }], "options": []
  },
]

export const GCLPublicTransportForm = [
  {
    "key": "TravelExpence", "props": { "min": "1" },"hide": false, "type": "number", "disabled": false, "id": "travelExpence", "label": "Travel Expense",
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Travel expense is required." },
      { "name": "maxLength", "value": 4, "validationMessage": maxFourDigit }]
  },
  {
    "key": "UploadBill", "hide": false, "type": "file", "dropTitle": "Upload bill photo", "acceptFileTypes": ["image/jpeg", "image/jpg", "image/png", "application/pdf"],
    "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": true,
    "disabled": false, "id": "billPhoto", "label": "Please upload bill copy", "validators": []
  },
]

export const GCLSelfOwnedTransportForm = [
  {
    "key": "VehicleTypeID", "type": "radio", "label": "Vehicle Type",
    "validators": [
      { "name": "required", "value": true, "validationMessage": "Vehicle type is required." }], "options": []
  },
]

export const initFormData = (formFields, masterDataState) => {
  const formData = [...formFields]
  formData[0].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY.TABLE_NAME]
  return formData
}

export const getAutoCompleteItems = (masterDataState, tableName) => {
  const options = []
  for (const option of masterDataState[tableName]) {
    options.push({ id: option.value ? option.value.toString() : '', label: option.label ? option.label : '', value: option.value ? option.value.toString() : '', })
  }
  return options
}
export const getAutoCompleteItemsBranch = (masterDataState, tableName) => {
  const options = []
  for (const option of masterDataState[tableName]) {
    options.push({ id: option.value ? option.value.toString() : '', label: option.label ? `${option.label}-${option.key}` : '' })
  }
  return options
}

export const getDropDownOptions = (data, dropDownConfig, channelID = '') => {
  let options = [
    { "key": "Select", "label": "Select", "value": "" }
  ]
  if (dropDownConfig.TABLE_NAME === 'ModeofTransport' || dropDownConfig.TABLE_NAME === 'VehicleType') {
    options = []
  }

  for (const option of data) {
    if (dropDownConfig.CHANNEL_ID !== '' && channelID !== option[dropDownConfig.CHANNEL_ID]) {
      continue
    }
    const optionItem = {
      "key": option[dropDownConfig.CODE],
      "label": option[dropDownConfig.LABEL],
      "value": option[dropDownConfig.KEY] ? option[dropDownConfig.KEY].toString() : ''
    }
    if (dropDownConfig.TABLE_NAME === 'ModeofTransport' || dropDownConfig.TABLE_NAME === 'VehicleType') {
      optionItem["name"] = dropDownConfig.FIELD_NAME
      optionItem["id"] = option[dropDownConfig.CODE]
    }
    if (dropDownConfig.TABLE_NAME === 'District') {
      optionItem["StateID"] = option[dropDownConfig?.StateID]
    }
    if (dropDownConfig.TABLE_NAME === 'AgencyAdvisorMapping') {
      optionItem["BranchCode"] = option[dropDownConfig?.BranchCode]
    }
    options.push(optionItem)
  }
  return options
}



export const getOptionById = (id, optionValues) => {
  return optionValues.find(item => item.value === id)
}

export const removeSubArrayFromArray = (subArray, keyForSearch, arrOfObjects) => {
  let newArray = [...arrOfObjects]
  for (const object of subArray) {
    newArray = newArray.filter((item) => item[keyForSearch] !== object[keyForSearch])
  }
  return newArray
}

export const handleActivitySelection = (event, key, formFields, masterDataState, role) => {
  let formData = [...formFields]
  switch (key) {
    case DROPDOWN_ACTIVITY_TYPE:
      formData = GCLActivityForm
      const selectedActivity = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY.TABLE_NAME])
      switch (selectedActivity.key) {
        case BRANCH_VISIT:
        case HNI_MEET:
        case BDF:
          formData = formData.concat(GCLBranchVisitForm);
          formData[7].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.GCLACTIVITYTYPE.TABLE_NAME]
          break;
        case ICE_E_SAMPARK:
          formData = formData.concat(GCLIceESamparkForm);
          formData[1].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.SUB_ACTIVITY.TABLE_NAME]
          break;
        case BRANCH_TRAINING:
          formData = formData.concat(GCLBranchTrainingActivityForm);
          break;
        case ICE_FOR_LOAN:
          formData = formData.concat(GCLICELoanActivityForm);
          break;
        case HNI_LEAD_GENERATION:
          formData = formData.concat(GCLHNILeadGenerationForm)
          break;
        case EXPENCE_CLAIM:
          formData = formData.concat(GCLExpenceClaimActivity);
          formData[1].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.EXPENSE_TYPE.TABLE_NAME]
          break;
        case FINISH_DAY:
        case KYB:
          formData = formData.concat(GCLFinishDayForm);
          formData[1].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
          break;
        default:
          break;
      }
      break;
    case DROPDOWN_EXPENCE_CLAIM:
      if (event) {
        formData = formData.concat(GCLExpenceClaimForm)
        formData[2].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
      } else {
        const arrayToRemove = GCLExpenceClaimForm.concat(GCLPublicTransportForm).concat(GCLSelfOwnedTransportForm)
        formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
      }
      break;
    case DROPDOWN_MODE_OF_TRANSPORT:
      const selectetTransport = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME])
      if (selectetTransport.key === PUBLIC_TRANSPORT) {
        formData = removeSubArrayFromArray(GCLSelfOwnedTransportForm, "key", formData);
        formData = formData.concat(GCLPublicTransportForm)
      } else {
        formData = removeSubArrayFromArray(GCLPublicTransportForm, "key", formData);
        if (role !== ROLE_BDM) {
          formData = formData.concat(GCLSelfOwnedTransportForm)
          formData[3].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
        }
      }
      break;
    default:
      break;
  }
  return formData
}

export const validateExtraValidations = (formData) => {
  // validate expected and converted leads
  let extraValidations = {}
  if (formData.hasOwnProperty('ExpectedLead') && formData.hasOwnProperty('ExpectedConversion') &&
    formData["ExpectedLead"] !== '' && formData["ExpectedConversion"] !== '') {
    if (parseInt(formData['ExpectedConversion']) > parseInt(formData['ExpectedLead'])) {
      extraValidations['ExpectedConversion'] = 'Expected conversion should be less than or equal to expected leads.'
    }else if(parseInt(formData['ExpectedConversion']) <= parseInt(formData['ExpectedLead'])){
      extraValidations = {}
    }
  }
  return extraValidations;
}

export const tiketsizeExtraValidations = (formData) => {
  // validate expected and converted leads
  const extraValidations = {}
  if (formData.hasOwnProperty('TicketSize') && formData.hasOwnProperty('ExpectedConversion') &&
    formData["TicketSize"] === '' && formData["ExpectedConversion"] !== '') {
    extraValidations['TicketSize'] = 'Ticket size should not be blank.'
  }
  return extraValidations;
}