import { isAlphabetOnly, isBranchValid, isEmailValid, numberStartWith6789Validator, isPositiveNumber ,isTwoDigitIntiger, checkMonthValidity} from "../../../../utils/validators";
import { format } from "date-fns";
import moment from "moment";
const dateFormate = 'yyyy-MM-dd';
const date= new Date()
const minDate = format(new Date(), dateFormate);
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD");
var today = new Date();
var priorDate = moment(new Date(new Date().setMonth(today.getMonth()-3))).format("YYYY-MM-DD");
const datetimelocal = "date"
const backThreeMonth=  moment(new Date(new Date().setMonth(today.getMonth()-3))).format("YYYY-MM-DD");
const FutureThreeMonth=  moment(new Date(new Date().setMonth(today.getMonth()+3))).format("YYYY-MM-DD");
const FutureFourMonth=  format(new Date(date.getFullYear(), date.getMonth()+4, date.getMonth()), dateFormate)
console.log("endMaxDate",priorDate,backThreeMonth)

export const customValidators = [
    {
        key: 'FirstName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'MiddleName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'LastName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'Email',
        validator: isEmailValid,
        message: 'invalid email id with missing domain name, @, .com, gmail id etc.'
    },
    {
        key: 'BranchID',
        validator: isBranchValid,
        message: "Branch code and activity id's branch code should be same."
    },
    {
        key: 'MobileNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
    },
    {
        key: "ActualPremiumAmount",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium amount should be greater than 0", // message to show
    },
    {
        key: "LoanAmount",
        validator: isPositiveNumber, // function to be called
        message: "Loan amount  should be greater than 0", // message to show
    },
    {
        key: "RateOfInterest",
        validator: isTwoDigitIntiger, // function to be called
        message: "Maximum 2 number and decimals upto 3 number allowed",
    },
    {
        key: 'LoanTerm',
        validator: checkMonthValidity,
        message: 'Maximum 360 months and minimum 12 months allowed'
    },
]


export const MSMELeadForm = [
    {
        "key": "SalutationID", "type": "select", "disabled": false, "id": "SalutationID", "label": "Salutation",
        "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required"    }],
        "options": []
    },
    {
        "key": "FirstName", "type": "text", "id": "FirstName", "label": "First Name",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 2, "validationMessage": "Please enter at least 2 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "key": "MiddleName", "type": "text", "id": "MiddleName", "label": "Middle Name",
        "validators": [
            { "name": "minLength", "value": 2, "validationMessage": "Please enter at least 2 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "key": "LastName", "type": "text", "id": "LastName", "label": "Last Name",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 2, "validationMessage": "Please enter at least 2 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "key": "MobileNo", "type": "number", "id": "MobileNo", "label": "Mobile No.",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required." },
            { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
            { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
    },
    {
        "key": "Email", "type": "email", "id": "Email", "label": "Customer Email ID", "hide": false,
        "validators": [{ "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }]
    },
    {
        "key": "LoanACReferenceNo", "type": "text", "id": "LoanACReferenceNo", "label": "Loan Ref Number/Retail ID", "hide": false,
        "validators": [
            { "name": "maxLength", "value": 40, "validationMessage": "Please enter no more than 40 alphanumeric characters." },
            { "name": "minLength", "value": 40, "validationMessage": "Please enter at least 40 alphanumeric characters." }
        ]
    },
    {
        "key": "ActivityID", "type": "select", "disabled": false, "id": "ActivityID", "label": "Activity Type","hide": false,
        "options": []
    },
    {
        "key": "BranchID", "type": "select", "disabled": false, "id": "BranchID", "label": "Branch Code", "hide": false,
        "validators": [{ "name": "required", "value": true,"validationMessage": "Please select branch code." }],
        "options": []
    },
    {
        "key": "MSMELoanTypeID", "type": "select", "id": "MSMELoanTypeID", "label": "Loan Type", "hide": false,
        "validators": [{ "name": "required", "value": true , "validationMessage": "Please select loan type" }],
        "options": []
    },
    {
        "key": "LoanACNo", "type": "number", "id": "LoanACNo", "label": "Loan Account Number", "hide": false,
        "validators": [{ "name": "minLength", "value": 14, "validationMessage": "Please enter at least 14 digit" },
                      { "name": "maxLength", "value": 15, "validationMessage": "Please enter no more than 15 digit." }
        ]
    },
    {
        "key": "MSMESource", "type": "select", "id": "MSMESource", "label": "Source", "hide": false, "disabled":false,
        "options": [
            { key: "Self", label: "Self", value: "Self" },
            { key: "LLPS", label: "LLPS", value: "LLPS" },]
    },
    {
        "key": "LoanAmount", "type": "number", "id": "LoanAmount", "label": "Loan Amount", "hide": false,
        "validators": [{ "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 digit" },
                     { "name": "maxLength", "value": 9, "validationMessage": "Please enter no more than 9 digit." }]
    },
    {
        "key": "LeadCreationDate", "noDate": null, "min": backThreeMonth,
        "max": moment(new Date()).endOf('day').format("YYYY-MM-DD"),
        "type": datetimelocal, "id": "LeadCreationDate","hide": false,
        "label": "Lead Creation Date", "validators": [{ "name": "required", "value": true, "validationMessage": "Lead creation date is required" }]
    },
    {
        "key": "LoanTerm", "type": "text", "disabled": false, "id": "LoanTerm", "label": "Loan Term","hide": false,
        "validators":[]
    },
    {
        "key": "ExpectedLeadClosureDate", "noDate": null, "min": minDate, "max": moment(new Date(new Date().setMonth(today.getMonth()+4))).format("YYYY-MM-DD"),
        "hide": false,"type": datetimelocal, "id": "ExpectedLeadClosureDate", "label": "Expected Lead Closure Date",
    },
    {
        "key": "CustomerType", "type": "select", "disabled": false, "id": "CustomerType", "label": "Customer Type","hide": false,
        "options": [{ key: "Select", label: "Select", value: "" },{ key: "new", label: "New", value: "new" },
        { key: "existing", label: "Existing", value: "existing" }]
    },
    {
        "key": "LoanSanctionDate", "noDate": null, "min": backThreeMonth, "max": FutureThreeMonth,"hide": true,
        "type": datetimelocal, "id": "LoanSanctionDate","label": "Loan Sanction Date", 
        "validators": [{ "name": "required", "value": true, "validationMessage": "Loan Sanction Date is required"  }]
    },
    {
        "key": "IsPremiumFunding", "type": "select", "disabled": false, "id": "IsPremiumFunding", "label": "Premium Funding","hide": true,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Please select Premium Funding" }],
        "options": [{ key: "select", label: "select", value: "" },{ "key": "Yes", "label": "Yes", "value": "Yes" },{ "key": "No", "label": "No", "value": "No" }],
    },
    {
        "key": "ActualPremiumAmount", "type": "text", "disabled": false, "id": "ActualPremiumAmount", "label": "Premium Amount","hide": true,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Please enter premium Amount" },
        { "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 digit" },
        { "name": "maxLength", "value": 7, "validationMessage": "Please enter no more than 7 digit." }]
    },
    {
        "key": "ExpectedDisbustmentDate", "noDate": null, "min": minDate, "type": datetimelocal, "id": "ExpectedDisbustmentDate",
        "max":moment(new Date(new Date().setMonth(today.getMonth()+6))).format("YYYY-MM-DD"),"hide": true,
        "label": "Expected Disbursement Date", "validators": [{ "name": "required", "value": true, "validationMessage": "Expected Disbursement Date is required" }]
    },
    {
        "key": "LoanDisbursementDate", "noDate": null, "type": datetimelocal, "id": "LoanDisbursementDate","hide": true,
        "label": "Disbursed Date", "validators": []
    },
    {
        "key": "IsPremiumCollected", "type": "select", "disabled": false, "id": "IsPremiumCollected", "label": "Premium Collection", "hide": false,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Please select meeting duration." }],
        "options": [{ key: "select", label: "select", value: "" },{ "key": "Yes", "label": "Yes", "value": "Yes" },{ "key": "No", "label": "No", "value": "No" }],
    },
    {
        "key": "CreatedByMsme", "type": "select", "disabled": false, "id": "CreatedByMsme", "label": "Created By","hide": false,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Please select Created By." }],
        "options": []
    },
]









