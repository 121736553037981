import React from "react";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import IdleTimer from 'react-idle-timer';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {
  GCLActivityForm,
  handleActivitySelection,
  initFormData,
  getAutoCompleteItemsBranch,
  validateExtraValidations,
  tiketsizeExtraValidations,
} from "./gcl/createActivityForm";
import {
  addDynamicData,
  getMasterData,
  uploadFileToBlob,
  activateSuccessMessageBar,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
  setBreadcrumbs,
  updateDynamicData,
  isfinishdayActivityDone
} from "../../../redux/actions/common";
import { getActivityById, getActivityStatusMasterData, finishDayActivityCreate } from "../../../redux/actions";
import { validateActivity, getUserCheckInCountforFinishDay } from "../../../redux/actions/createActivityActions";
import {
  CONFIGS,
  FOUR_WHEELER,
  PUBLIC_TRANSPORT,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  STATUS_ACTIVITY_DRAFTED,
  STATUS_ACTIVITY_PLANNED,
  ACTIVITY_COMPLETED,
  checkInConfig,
} from "../../../utils/config";
import "./createActivity.scss";
import { minusMinuteFromDate } from '../../../utils/utils'
import { checkDateIsValid, isPositiveNumber } from "../../../utils/validators";
import moment from "moment";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getActivityDataGCL, getBranchDataGCL, getOptionByCode, getSubTitles } from "./helper";
import SaveAsDraftDrawer from "./saveAsDraftDrawer";
import { getActivityNameList, getActivityStatusNameList, getGCLTypeActivity, saveActivityOffline } from "../../../offlineManager/activityOfflineManager";
import { v4 as uuidv4 } from "uuid";
import { getBranchList } from "../../../offlineManager/leadOfflineManager";
const _url = "/app/activity/all";
const _formatDate = "YYYY-MM-DDTHH:mm";
const customValidators = [
  {
    key: "ExpectedLead",
    validator: isPositiveNumber, // function to be called
    message: "Expected leads should be greater than 0", // message to show
  },
  {
    key: "ExpectedConversion",
    validator: isPositiveNumber, // function to be called
    message: "Expected conversion should be greater than 0", // message to show
  },
  {
    key: "TicketSize",
    validator: isPositiveNumber, // function to be called
    message: "Ticket size should be greater than 0", // message to show
  },
  {
    key: "ExpenseAmount",
    validator: isPositiveNumber, // function to be called
    message: "Expense amount should be greater than 0", // message to show
  },
  {
    key: "BillNumber",
    validator: isPositiveNumber, // function to be called
    message: "Bill number should be greater than 0", // message to show
  },
  {
    key: "TravelExpene",
    validator: isPositiveNumber, // function to be called
    message: "Travel expense should be greater than 0", // message to show
  },
];

const createActivityTitle = "Create Activity";

class CreateActivityGCL extends React.Component {
  constructor() {
    super();
    this.dyFormRef = React.createRef();
    this.idleTimer = null;
    this.state = {
      current: {
        id: 1,
        MeetingStartDateTime: moment(new Date()).format(_formatDate),
        MeetingEndDateTime: moment(new Date()).add(30, "m")
          .format(_formatDate),
      },
      formData: GCLActivityForm,
      autoCompleteItems: {
        BranchId: [],
      },
      uploadedFile: "",
      originalFileName: "",
      extErrors: {},
      ExpectedConversion: "",
      ExpectedLead: "",
      isActiveMobileDraft: false,
      isFormSUbmitDisabled: false,
      channelId: "",
      optionList: {
        branchType: [],
        activityNameType: [],
        activityStatusType: [],
        gclType: []
      },
      isFinishDayActivity: false,
      checkedinCount: null,
      responseMessage: ""

    };
  }
  loadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Activity", url: _url },
      { name: createActivityTitle, url: "/app/activity/create" },
    ];
    this.props.setBreadcrumbs(breadcrumbs);
  };

  isValidBusinessActivity = async (formData) => {
    if (formData["ActivityID"] !== '24') {
      if (
        formData.hasOwnProperty("MeetingStartDateTime") &&
        formData.hasOwnProperty("MeetingEndDateTime")
      ) {
        const checkActivity = await this.props.validateActivity({
          user_id: this.props.userState.profileInfo.userId,
          formData: formData,
        });
        if (
          checkActivity.status === 200 &&
          checkActivity.data.Responsestatus === true
        ) {
          return true;
        } else {
          this.setState({
            responseMessage: checkActivity.data.Responsemessage
          })
        }
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  getActivityStatusName = (inputId) => {
    const collection = this.state.optionList['activityStatusType'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name && name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  }
  getOptionData = () => {
    Promise.all([getBranchList(), getActivityStatusNameList(), getGCLTypeActivity(), getActivityNameList()]).then((res) => {
      if (res && res.length > 0) {
        const optionState = {
          branchType: res[0],
          activityStatusType: res[1],
          activityNameType: res[2],
          gclType: res[3]
        }
        this.setState({ optionList: optionState }, () => {
        });
      }
    })
  }
  reset = (model) => {
    for (const [key] of Object.entries(model)) {
      model[key] = "";
    }
    this.setState({ uploadedFile: "", originalFileName: "", extErrors: {} });
    this.dyFormRef.reset(model);
  };

  isfinishdayActivityDoneCheck = async () => {
    const res = await this.props.isfinishdayActivityDone(this.props.userState.profileInfo.userId);
    if (res.status === 200) {
      this.setState({ isFinishDayActivity: res.data })
    }
  }

  onSubmitHandler = async (model) => {
    if (
      !model.isValidForm ||
      Object.keys(this.state.extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = []
      const formD = this.state.formData
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1)
        }
      })
      const errorElement = document.getElementsByClassName(`${'control'}${errArr[0]}`)[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: 'center',
        });
      }
      if (Object.keys(model.errors).length == 1 && !model.BranchId && !model.errors.hasOwnProperty('BranchIdlabel')) {
        this.props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
      if ((!model.isValidForm && Object.keys(model.errors).length > 0) || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
        this.props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }
    const isBranchNameLabel = this.props.masterDataState.Branches.some(ele => ele.value === model.BranchId);
    if (model.BranchId !== '' && isBranchNameLabel || (model.ActivityID == 24)) {
      delete model.errors.BranchId;
    }
    else if (!isBranchNameLabel) {
      const extError = { ...this.state.extErrors, 'BranchId': 'Invalid BranchId' }

      this.setState({
        ...this.state,
        extErrors: extError
      })
    }
    if (
      !model.isValidForm ||
      (Object.keys(this.state.extErrors).length > 0 &&
        !this.state.extErrors.MobileNo) ||
      Object.keys(model.errors).length > 0 ||
      !isBranchNameLabel
    ) {
      const errList = Object.keys(this.state.extErrors)
      if (errList && errList.length > 0 && errList.includes('BranchId')) {
        const errorElement = document.getElementsByClassName(`control-BranchId`)[0];
        if (errorElement) {
          errorElement.scrollIntoView({
            block: 'center'
          });
        }
        return false;
      }
    }
    if (model["TicketSize"] === undefined && model['ExpectedConversion'] > 0) {
      // const extraValidations = {};
      // extraValidations['TicketSize'] = 'Ticket size should not be blank.';
      // this.setState({ extErrors: extraValidations });
      // return false;
    }
    const formData = { ...model };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(formData["MeetingEndDateTime"]).replace(
        "T",
        " "
      );
    }
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_PLANNED,
      this.props.masterDataState.ActivityStatus
    );
    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format(_format);
    formData["ModifiedDate"] = moment().format(_format);
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(_format);
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["ActivityStatus"] = this.getActivityStatusName(formData.ActivityStatusId);
    const matchedActivityTypeName = getActivityDataGCL(this.state.optionList, formData.ActivityID);
    let branchData = getBranchDataGCL(this.state.optionList, formData.BranchId);
    if (formData.ActivityID === '24') {
      formData["ActivityName"] = getSubTitles(matchedActivityTypeName, '', null, formData.CreatedDate)
      formData["TrnActivityID"] = null;
      formData["BranchId"] = null;
      // formData["MeetingEndDateTime"] = null;
      // formData["MeetingStartDateTime"] = null;
    } else {

formData["ActivityName"] = getSubTitles(matchedActivityTypeName, formData?.BranchId, branchData?.BranchName + '-' + branchData?.BranchCode, formData.MeetingStartDateTime,this.props.masterDataState)

    }
    formData["AssignedToName"] = `${this.props.userState.profileInfo.firstName}${' '}${this.props.userState.profileInfo.lastName}`;
    formData["IsBacklog"] = false;
    formData["CreatedBy"] = parseInt(this.props.userState.profileInfo.userId);

    if (model.ActivityID == 24) {
      formData['IsClaimRequired'] = false
    }


    if(model.ActivityID == 22){
      formData["ExpectedLead"] = null;
      formData["ExpectedConversion"] = null;
      formData['TicketSize']= null;
      formData['ActivitySubTypeId'] = null;
    }
    if (navigator.onLine) {
      this.props.activateLoader();
      let isValidActivity = await this.isValidBusinessActivity(formData);
      isValidActivity = model.ActivityID == 24 ? true : isValidActivity;
      if (isValidActivity) {
        if (model.ActivityID === "24" && this.state.isFinishDayActivity) {
          // if(!this.state.isFinishDayActivity && this.state.current?.ActivityID==6){
          this.props.deActivateLoader();
          this.props.activateErrorMessageBar("Finish Day Activity Already done!")
          // }else if(this.state.checkedinCount==0){
          //   this.props.activateErrorMessageBar(
          //     "No Checkin Found For Today.")
          // }
        } else if (model.ActivityID === "24" && this.state.checkedinCount == 0) {
          this.props.activateErrorMessageBar(
            "No Checkin Found For Today.")
          this.props.deActivateLoader();
        } else {
          if (model.ActivityID == 24) {
            formData['IsClaimRequired'] = false
          }
          if (model.ActivityID == 24) {
            try {
              const result1 = await this.props.finishDayActivityCreate(
                this.props.userState.profileInfo.userId
              );
              if (result1.status === 200) {
                //this.setState({ isFormSUbmitDisabled: false });
                const result = await this.props.addDynamicData({
                  data: { ...formData, "IsBacklog": 0 },
                  formName: CONFIGS.NEW_ACTIVITY_FORM,
                });
                if (result.status === 200 && result.data.Responsestatus === true) {
                  this.props.activateSuccessMessageBar(
                    "Activity has been created successfully."
                  );
                  if (model.ActivityID === "24") {
                    const getCurrentActivity = await this.props.getActivityById(result.data.Transactionid);
                    getCurrentActivity.data.ActivityStatus = ACTIVITY_COMPLETED;
                    const activityresult = await this.props.getActivityStatusMasterData();
                    if (activityresult.status === 200) {
                      // const statusId = activityresult.data.filter(f => f.ActivityStatus === 'Activity Completed');
                      getCurrentActivity.data.ActivityStatusId = 26;//statusId[0].ActivityStatusId;
                      getCurrentActivity.data['ModifiedBy'] = this.props.userState.profileInfo.userId;
                      getCurrentActivity.data['IsClaimRequired'] = model.IsClaimRequired;
                    }
                    const finshiday = {
                      "ExpectedPremium": null,
                      "NoOfLeads": null,
                      "Remarks": "Auto Completed",
                      "IsBacklog": 0,
                    }
                    const data = { ...getCurrentActivity.data, ...finshiday };
                    const updateresult = await this.props.updateDynamicData({
                      'data': [data], 'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
                      "formBuilder": ACTIVITY_COMPLETED, "userID": result.data.Transactionid
                    });
                    if (updateresult.status === 200) {
                      this.props.activateSuccessMessageBar(
                        "Activity is updated successfully."
                      );
                      // try {
                      //     const result1 = await this.props.finishDayActivityCreate(
                      //       this.props.userState.profileInfo.userId
                      //     );
                      //     if (result1.status === 200) {
                      //       //this.setState({ isFormSUbmitDisabled: false });
                      //     }
                      //   } catch (e) {
                      //     //this.setState({ isFormSUbmitDisabled: true });
                      //   }
                    }
                  }


                  this.props.deActivateLoader();
                  const saveFormData = { ...formData };
                  //  SAVE activity in indexDb online
                  saveFormData["TrnActivityID"] = result.data.Transactionid;
                  saveFormData["IsBacklog"] = false;
                  saveActivityOffline(saveFormData).then((res) => {
                    if (res) {
                      this.reset(formData);
                      this.props.history.push(
                        `/app/activity/detail/${result.data.Transactionid}`
                      );
                    }
                  });
                } else {
                  this.props.deActivateLoader();
                  this.props.activateSuccessMessageBar(
                    "Something went wrong! please try again later."
                  );
                }
              }
            } catch (e) {
              //this.setState({ isFormSUbmitDisabled: true });
            }
          } else {
            const result = await this.props.addDynamicData({
              data: { ...formData, "IsBacklog": 0 },
              formName: CONFIGS.NEW_ACTIVITY_FORM,
            });
            if (result.status === 200 && result.data.Responsestatus === true) {
              this.props.activateSuccessMessageBar(
                "Activity has been created successfully."
              );
              if (model.ActivityID === "24") {
                const getCurrentActivity = await this.props.getActivityById(result.data.Transactionid);
                getCurrentActivity.data.ActivityStatus = ACTIVITY_COMPLETED;
                const activityresult = await this.props.getActivityStatusMasterData();
                if (activityresult.status === 200) {
                  // const statusId = activityresult.data.filter(f => f.ActivityStatus === 'Activity Completed');
                  getCurrentActivity.data.ActivityStatusId = 26;// statusId[0].ActivityStatusId;
                  getCurrentActivity.data['ModifiedBy'] = this.props.userState.profileInfo.userId;
                  getCurrentActivity.data['IsClaimRequired'] = model.IsClaimRequired;
                }
                const finshiday = {
                  "ExpectedPremium": null,
                  "NoOfLeads": null,
                  "Remarks": "Auto Completed",
                  "IsBacklog": 0,
                }
                const data = { ...getCurrentActivity.data, ...finshiday };
                const updateresult = await this.props.updateDynamicData({
                  'data': [data], 'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
                  "formBuilder": ACTIVITY_COMPLETED, "userID": result.data.Transactionid
                });
                if (updateresult.status === 200) {
                  this.props.activateSuccessMessageBar(
                    "Activity is updated successfully."
                  );
                  // try {
                  //     const result1 = await this.props.finishDayActivityCreate(
                  //       this.props.userState.profileInfo.userId
                  //     );
                  //     if (result1.status === 200) {
                  //       //this.setState({ isFormSUbmitDisabled: false });
                  //     }
                  //   } catch (e) {
                  //     //this.setState({ isFormSUbmitDisabled: true });
                  //   }
                }
              }


              this.props.deActivateLoader();
              const saveFormData = { ...formData };
              //  SAVE activity in indexDb online
              saveFormData["TrnActivityID"] = result.data.Transactionid;
              saveFormData["IsBacklog"] = false;
              saveActivityOffline(saveFormData).then((res) => {
                if (res) {
                  this.reset(formData);
                  this.props.history.push(
                    `/app/activity/detail/${result.data.Transactionid}`
                  );
                }
              });
            } else {
              this.props.deActivateLoader();
              this.props.activateSuccessMessageBar(
                "Something went wrong! please try again later."
              );
            }
          }
        }
      } else {
        this.props.activateErrorMessageBar(this.state.responseMessage)
        this.props.deActivateLoader();
      }
    } else {
      saveActivityOffline(formData).then((res) => {
        if (res) {
          const {
            data: { key },
          } = res;
          this.props.history.push(`/app/activity/detail/${key}`);
        }
      });
    }
  };



  handleSaveAsDraft = async (e) => {
    e.preventDefault();
    this.props.activateLoader();
    const formData = { ...this.dyFormRef.state };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(formData["MeetingEndDateTime"]).replace(
        "T",
        " "
      );
    }
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }
    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_DRAFTED,
      this.props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = this.props.userState.profileInfo.userId;
    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["CreatedDate"] = moment(new Date()).format(_format)
    formData["ModifiedDate"] = moment(new Date()).format(_format)
    // formData["ModifiedDate"] = new Date()
    //   .toISOString()
    //   .replace("T", " ")
    //   .replace("Z", "");
    //formData["IsClaimRequired"] = formData["IsClaimRequired"] ? "Yes" : "No"
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format("YYYY-MM-DD HH:mm:ss");
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["ActivityStatus"] = this.getActivityStatusName(formData.ActivityStatusId);
    formData["AssignedToName"] = `${this.props.userState.profileInfo.firstName}${' '}${this.props.userState.profileInfo.lastName}`;
    formData["IsBacklog"] = false;
    if (formData.ActivityID == 24) {
      formData['IsClaimRequired'] = false
    }
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;
    if (navigator.onLine) {
      if (formData.ActivityID == 24) {
        formData['IsClaimRequired'] = false
      }
      const result = await this.props.addDynamicData({
        data: { ...formData, "IsBacklog": 0 },
        formName: CONFIGS.NEW_ACTIVITY_FORM,
        CreatedBy: parseInt(this.props.userState.profileInfo.userId),
      });
      if (result.status === 200 && result.data.Responsestatus === true) {
        this.props.activateSuccessMessageBar(
          "Activity has been drafted successfully."
        );
        this.reset(formData);
        this.props.deActivateLoader();
        saveActivityOffline(formData);
        this.props.history.push("/app/activity/all");
      } else {
        this.props.deActivateLoader();
        this.props.activateSuccessMessageBar(
          "Something went wrong! please try again later."
        );
      }
    } else {
      this.props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          this.props.history.push(`/app/activity/all`);
        }
      });
    }
  };

  userCheckInCountforFinishDay = async () => {
    let res = await this.props.getUserCheckInCountforFinishDay(this.props.userState.profileInfo.userId)
    this.setState({ checkedinCount: res.data })
  }

  finishDayActivityStatus = async () => {
    let res = await this.props.finishDayActivityCreate(this.props.userState.profileInfo.userId)
    this.setState({ isFinishDayActivity: res.data })
  }

  componentDidMount = () => {
    this.loadBreadCrumbs();

    const formData = this.props.masterDataState
      ? initFormData(this.state.formData, this.props.masterDataState)
      : this.state.formData;
    const autoCompleteItems = { ...this.state.autoCompleteItems };
    autoCompleteItems.BranchId = this.props.masterDataState
      ? getAutoCompleteItemsBranch(
        this.props.masterDataState,
        CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME
      )
      : [];
    this.setState({ formData: formData, autoCompleteItems: autoCompleteItems, channelId: this.props.userState.profileInfo.channelid }, () => {
    });
    this.getOptionData();
    //this.finishDayActivityStatus();
    this.isfinishdayActivityDoneCheck();
    this.userCheckInCountforFinishDay();
  };

  uploadFile = async (files) => {
    const file = files[0];
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const fileRes = await this.props.uploadFileToBlob({
        file: file,
        fileName: fileName,
      });
      if (fileRes.status === 200) {
        this.setState({ uploadedFile: fileName, originalFileName: file.name });
      }
    }
  };

  onChangeHandler = async (event, key,model) => {
    if (key === "IsClaimRequired" && this.state.checkedinCount == 0) {
      this.props.activateErrorMessageBar(
        "No Checkin Found For Today."
      );
      this.setState({
        current: { ...this.state.current, IsClaimRequired: false },
      });
      return false

    }

    if (key === 'UploadBill') {
      if (navigator.onLine) {
        this.uploadFile(event);
      } else {
        this.props.activateErrorMessageBar(
          "Cannot upload files in offline mode!"
        );
      }
    }
    if (key === "MeetingStartDateTime") {
      var StartDate = moment(event.target.value).format(_formatDate);
      const newDate = moment(event.target.value)
        .add(30, "m")
        .format(_formatDate);

      this.state.formData.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate).endOf('day').format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
      })

      if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, [key]: "please select future value" }
        })
      } else {
        delete this.state.extErrors?.MeetingStartDateTime
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors }
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingStartDateTime: StartDate, MeetingEndDateTime: newDate },
      });
    }
    if (key === "MeetingEndDateTime") {
      const newDate = moment(event.target.value).format(_formatDate);
      if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, "MeetingEndDateTime": "meeting end date should not less than meeting starting date" },
        })
      } else {
        delete this.state.extErrors?.MeetingEndDateTime;
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors },
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingEndDateTime: newDate }
      });
    }

    const formData = handleActivitySelection(
      event,
      key,
      this.state.formData,
      this.props.masterDataState,
      this.props.userState.profileInfo.roleId
    );
    this.setState({ formData: formData });
    if (key === "ExpectedLead" || key === "ExpectedConversion") {
      const extErrors = validateExtraValidations({ ...this.state, [key]: event.target.value });
      this.setState({ current: { ...this.state.current, [key]: event.target.value } })
      if (extErrors.hasOwnProperty('ExpectedConversion')) {
        this.setState({ [key]: event.target.value, current: { ...this.state.current }, extErrors: { ...this.state.extErrors, ...extErrors } })
      } else if (extErrors.hasOwnProperty('ExpectedLead')) {
        this.setState({ [key]: event.target.value, current: { ...this.state.current }, extErrors: { ...this.state.extErrors, ...extErrors } })
      } else {
        delete this.state.extErrors?.ExpectedConversion;
        // this.setState({ [key]: event.target.value,current:{...this.state.current,[key]: event.target.value,ExpectedConversion:event.target.value}, extErrors: {...this.state.extErrors}})
        this.setState({ [key]: event.target.value, current: { ...this.state.current }, extErrors: { ...this.state.extErrors } })

      }

    }


    if (key === "TicketSize") {


      if (event.target.value > 0) {
        delete this.state.extErrors?.TicketSize;
        this.setState({ [key]: event.target.value, extErrors: { ...this.state.extErrors } });
      } else {
        const extErrors = tiketsizeExtraValidations(this.state);
        this.setState({ [key]: event.target.value, extErrors: { ...this.state.extErrors, ...extErrors } });
      }

    }
    if (key === "ActivityID") {
      this.setState({ current: { ...this.state.current, ActivityID: event.target.value } })
    }

    if (key === "BranchId") {
      const isBranchNameLabel = this.props.masterDataState.Branches.some(ele => ele.value === event.target?.value);
      delete model?.errors["BranchIdlabel"]
      if (!isBranchNameLabel) {
        const extError = { ...this.state.extErrors, 'BranchId': 'Invalid BranchId' }
        this.setState({
          ...this.state,
          extErrors: extError
        })
      } else {

        // this.setState({ extErrors: {} })
        delete this.state.extErrors["BranchId"]
        delete model?.errors["BranchIdlabel"]

        this.setState({
          current: { ...this.state.current, BranchId: event.target.value },
          extErrors: { ...this.state.extErrors }
        });
      }
    }
  };

  onAutocompleteSelectHandler = (key, value, item) => {
    if (key === "BranchId") {
      this.setState({
        current: { ...this.state.current, BranchId: item.id, BranchIdlabel: item.label }
      });
    }
  };

  onCancelHandler = () => {
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      this.setState({ isActiveMobileDraft: true });
    } else {
      this.props.history.push(_url);
    }
  };

  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({ uploadedFile: "" });
  };

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => this.removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  renderRadioIcons = (radioOption) => {
    let icon = "";
    if (radioOption.key === PUBLIC_TRANSPORT) {
      icon = <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      icon = <DirectionsCarIcon></DirectionsCarIcon>;
    }
    return icon;
  };
  onCheckinHandler = (position, address) => {
    if (this.id) {
      this.setState({
        ...this.state.leadDetail, Location: address
      })
    }
  }

  toggleDrawer = (flag) => {
    this.setState({ isActiveMobileDraft: flag });
  };

  onDontSaveDraftHandler = () => {
    this.props.history.push(_url);
  };

  onSaveDraftHandler = () => {
    this.handleSaveAsDraft(new Event("test"));
  };
  handleOnAction = (event) => {
    if (this.state.timeOutMsg) {
      setTimeout(() => {
        this.props.history.push("/app/home")
      }, 3000)
    }
  }
  handleOnActive = (event) => {
    if (this.idleTimer.getRemainingTime() === 0) {
      this.setState({
        ...this.state, timeOutMsg: this.idleTimer.getRemainingTime() === 0
      })
    }
  }
  handleOnIdle = (event) => {
  }

  render() {
    const { t } = this.props;
    return (
      <div className="mainContainer createActivity">
        <Breadcrumb></Breadcrumb>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 10}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Snackbar open={this.state.timeOutMsg} autoHideDuration={3000} >
          <Alert severity="error" >Your session has been expired! You will be redirected to homepage in 3 seconds.</Alert>
        </Snackbar>
        <span className="formInfo">
          {t("Please fill in the activity information for GCL")}
        </span>
        <div className="formBox">
          <DynamicForm
            key={this.state.current.id}
            onRef={(ref) => (this.dyFormRef = ref)}
            title={t(createActivityTitle)}
            buttonSubmit={t("Submit")}
            defaultValues={this.state.current}
            model={this.state.formData}
            onSubmit={(model) => {
              this.onSubmitHandler(model);
            }}
            disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key,model) => this.onChangeHandler(event, key,model)}
            onAutocompleteSelect={this.onAutocompleteSelectHandler}
            autoCompleteItems={this.state.autoCompleteItems}
            showAstersikOnRequiredFields={true}
            extErrors={this.state.extErrors}
            checkInConfig={checkInConfig}
            onCheckinHandler={this.onCheckinHandler}
            className="activityTypeDiv"
            extaraActionFormButton={
              <>
                <a
                  className="savedraftLink"
                  onClick={(e) => this.handleSaveAsDraft(e)}
                  href="#"
                >
                  SAVE AS DRAFT
                </a>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={this.onCancelHandler}
                >
                  {t("Cancel")}
                </button>
              </>
            }
            fileUploaderFields={["UploadBill"]}
            renderUploadedFilesItem={this.renderUploadedFilesItem}
            customValidators={customValidators}
            renderRadioIcons={(option) => this.renderRadioIcons(option)}
          />
        </div>
        <SaveAsDraftDrawer
          isActiveMobileDraft={this.state.isActiveMobileDraft}
          toggleDrawer={this.toggleDrawer}
          onDontSaveHandler={this.onDontSaveDraftHandler}
          onSaveDraftHandler={this.onSaveDraftHandler}
        ></SaveAsDraftDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  // getUserBranches: (payload) => dispatch(getUserBranches(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  finishDayActivityCreate: (payload) =>
    dispatch(finishDayActivityCreate(payload)),
  getActivityById: (payload) => dispatch(getActivityById(payload)),
  getActivityStatusMasterData: (payload) => dispatch(getActivityStatusMasterData(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  isfinishdayActivityDone: (payload) => dispatch(isfinishdayActivityDone(payload)),


  getUserCheckInCountforFinishDay: (payload) => dispatch(getUserCheckInCountforFinishDay(payload))
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(CreateActivityGCL)
);
