import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import "./adminadasboardlisting.scss";
import { connect } from "react-redux";
import { downloadFilesAdmin } from "../utils/utils";
import { uuidv4 } from "../utils/utils";
import "./leadlist.scss";
import {
  activateLoader,
  deActivateLoader,
  campainLeadUploadedFileLists,
  campainLeadDownloadFile,
} from "../redux/actions/common";

const FileDetails = (props) => {
  const [isReady, setIsReady] = useState(false);
  const location = useLocation();
  const [isshow,setIsShow] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsShow(false)
      setIsReady(true);
    }, 100000)
  }, []);

  const getSource = async (filename) => {
    const name = filename.substring(0, filename.lastIndexOf("."));
    const filetype = location.filetype;
    const response = await props.campainLeadDownloadFile({
      filetype: filetype,
      fileName: filename,
    });
    if (location.status == "success") {
      downloadFilesAdmin(response, name);
    }
  };

  const getSummary = async () => {
    let data;
    let name;
    let today = new Date();
    var month;
    month = parseInt(today.getUTCMonth() + 1);
    if(month < 10){
      month = "0"+month;
    }
    let date =
    today.getDate() +
    "-" + month +
    "-" +
    today.getFullYear();
    const filetype = location.filetype;
    const result = await props.campainLeadUploadedFileLists(filetype);
    const Errorsummary = "Error";
    for (const key in result.data) {
      if (
        result.data[key].includes(Errorsummary) &&
        result.data[key].includes(date)
      ) {
        data = result.data[key];
        break;
      }
    }
    const response = await props.campainLeadDownloadFile({
      filetype: filetype,
      fileName: data,
    });

    if (data !== undefined) {
      name = data.substring(0, data.lastIndexOf("."));
    }

    if (location.status == "success") {
      downloadFilesAdmin(response, name);
    }
  };
  return (
    <>
      <div>
          <div className="jobDetailDiv">
           {
            location.status == "success" && !isReady && (
            <div className="unsuccessDiv">
              Please wait! Your download is getting ready....
            </div>
          )}
          {
          location.status == "success" && isReady && (
            <div className="successDiv">Your download is ready now</div>
          )}

          {
          location.status == "error" && (
            <div className="errorDiv">
              File could not be uploaded,please check your file
            </div>
          )}
          <div className="jobDetailInner">
            <table class="table">
              <thead>
                <th colSpan={2} align="center">
                  <h1>Job Details</h1>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>Job Type</td>
                  {location.filetype === undefined ? (
                    <td>Nothing Selected</td>
                  ) : location.filetype.includes("activities") ? (
                    <td>Actvities Uploads</td>
                  ) : (
                    <td>Lead Uploads</td>
                  )}
                </tr>
                <tr>
                  <td>Job Id</td>
                  <td>{uuidv4()}</td>
                </tr>
                <tr>
                  <td>Filename</td>
                  <td>{location.File}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  {location.status == "success" && !isReady && (
                    <td style={{ color: "yellow" }}>in progress...</td>
                  )}
                  {location.status == "success" && isReady && (
                    <td style={{ color: "green" }}>Completed</td>
                  )}
                  {location.status == "error" && (
                    <td style={{ color: "red" }}>Failed</td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              id="sourceButton"
              disabled={isshow}
              onClick={() => getSource(location.File)}
            >
              <span className="fa fa-download">&nbsp;</span> Source File
            </button>
            <button
              className="btn btn-primary"
              id="summaryButton"
              disabled={isshow}
              onClick={getSummary}
            >
              <span className="fa fa-download">&nbsp;</span> Summary File
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  leadFormData: state.leadFormReducer,
  masterDataState: state.commonReducer,
});
export default connect(mapStateToProps, {
  campainLeadUploadedFileLists,
  campainLeadDownloadFile,
  activateLoader,
  deActivateLoader,
})(FileDetails);
