import React from "react";
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

const SaveAsDraftDrawer = (props) =>{

    const onDontSaveDraftHandler = () =>{
        props.onDontSaveHandler()
    }

    const onSaveDraftHandler = () =>{
        props.onSaveDraftHandler()
    }

    return <div className="saveAsDraftDrawer">
        <React.Fragment key={"saveAsDraftDrawerBottom"}>
            <SwipeableDrawer
                anchor={'bottom'}
                open={props.isActiveMobileDraft}
                onClose={()=>props.toggleDrawer(false)}
                onOpen={()=>props.toggleDrawer(true)}
            >
               <Box
                    sx={{ width:'auto', height: '170px'}}
                    role="presentation"
                    onClick={()=>props.toggleDrawer(false)}
                    onKeyDown={()=>props.toggleDrawer(false)}
                >
                    <Grid className="saveAsDraftDiv" container spacing={0}>
                        <Grid container spacing={0}>
                            <Grid item xs={8} md={8} >
                                <h2 className="draftHead">Save As Draft?</h2>
                            </Grid>
                            <Grid item xs={4} md={4} >
                                <CloseIcon></CloseIcon>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} >
                                <span className="draftInfo">
                                    Drafts let you save your edits, so you <br/> can come back later.
                                </span>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} >
                                <button onClick={onDontSaveDraftHandler} className="btn cancelDraftBtn" key="cancel" id="cancel" type="button">DON'T SAVE</button>
                                <button onClick={onSaveDraftHandler} className="btn saveDraftBtn" key="cancel" id="cancel" type="button">SAVE</button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </SwipeableDrawer>
        </React.Fragment>
    </div>
}

export default SaveAsDraftDrawer
