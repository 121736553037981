const fieldSetLabel = () => {
    return (
        <div>
            <h1>Fieldset Lable 1</h1>
            <p>Sub label</p>
        </div>
    );
}
const acceptFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf", "application/bmp"];
const claimtravelExpenseLabel = "Do You Want to Claim Travel Expense?";
const optionSelectValidationMessage = "Please select one option.";
const cssBorder = "1px solid #ddd";
const cssactiveBoxShadow = "0px 0px 1px 2px #fffc35";
export const ProductTrainingForm = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12",
                            "key": "IsPartnerMet", "type": "select", "id": "IsPartnerMet", "label": "Partner Met",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Partner Met is required." }],
                            "options": [
                                { "key": "Yes", "label": "Yes", "value": "1" },
                                { "key": "No", "label": "No", "value": "0" },
                            ]
                        },
                        {
                            "colmd": "12", "key": "NoOfAttendees", "hide": false, "type": "number", "disabled": false, "id": "NoOfAttendees", "label": "No Of Attendees",
                            "validators": [//{ "name": "required", "value": true, "validationMessage": "No Of Attendees is required." },
                            { "name": "minLength", "value": 1, "validationMessage": "Min length for No Of Attendees is 1 digits" },
                            { "name": "maxLength", "value": 3, "validationMessage": "No Of Attendees field require 3 digits." }]
                        },
                        {
                            "colmd": "12",
                            "key": "TopicsCoverdID", "type": "select", "id": "TopicsCoverdID", "label": "Topics Covered",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Topics Covered is required." }],
                            "options": []
                        },
                    ]
                ]
            }
        ]
    },
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "6",
                "colxs": "6",
                "childType": "fieldset",
                "fieldsets": [
                    {
                        "fieldSetClassName": "chilld-fieldset",
                        "fieldSetLabelType": "string", // string/component
                        "fieldSetLabel": "",
                        "rows": [
                            [
                                {
                                    "colmd": "12",
                                    "key": "TrainingAttendee", "type": "checkbox", "id": "TrainingAttendee", "label": "Training Attendee",
                                    "validators": [{ "name": "required", "value": true, "validationMessage": "Training Attendee is required." }],
                                    "options": []
                                },
                            ]
                        ]
                    },
                ]
            },
            {
                "colmd": "6",
                "colxs": "6",
                "childType": "fieldset",
                "className" : "testBox",
                "fieldsets": [
                    {
                        "fieldSetClassName": "chilld-fieldset trainingAttendeeSec",
                        "fieldSetLabelType": "string", // string/component
                        "fieldSetLabel": "",
                        "rows": [
                            [
                                {
                                    "colmd": "12",
                                    "colxs": "12",
                                    "key": "PartnerCount", "hide": true, "type": "number", "disabled": false, "id": "PartnerCount", "label": "Partner",
                                    "validators": [
                                        { "name": "required", "value": true, "validationMessage": "Partner Count is required." },
                                    { "name": "minLength", "value": 1, "validationMessage": "Min length for Partner Count is 1 digits" },
                                    { "name": "maxLength", "value": 3, "validationMessage": "Partner Count field require 3 digits." }]

                                }
                            ],
                            [
                                {
                                    "colmd": "12",
                                    "colxs": "12",
                                    "key": "CustomerCount", "hide": true, "type": "number", "disabled": false, "id": "CustomerCount", "label": "Customer",
                                    "validators": [
                                        { "name": "required", "value": true, "validationMessage": "Customer Count is required." },
                                    { "name": "minLength", "value": 1, "validationMessage": "Min length for Customer Count is 1 digits" },
                                    { "name": "maxLength", "value": 3, "validationMessage": "Customer Count field require 3 digits." }]

                                }
                            ],
                            [
                                {
                                    "colmd": "12",
                                    "colxs": "12",
                                    "key": "StaffCount", "hide": true, "type": "number", "disabled": false, "id": "StaffCount", "label": "Staff",
                                    "validators": [
                                        { "name": "required", "value": true, "validationMessage": "Staff Count is required." },
                                    { "name": "minLength", "value": 1, "validationMessage": "Min length for Staff Count is 1 digits" },
                                    { "name": "maxLength", "value": 3, "validationMessage": "Staff Count field require 3 digits." }]

                                }
                            ],
                            [
                                {
                                    "colmd": "12",
                                    "colxs": "12",
                                    "key": "BMCount", "hide": true, "type": "number", "disabled": false, "id": "BMCount", "label": "BM",
                                    "validators": [
                                        { "name": "required", "value": true, "validationMessage": "BM Count is required." },
                                    { "name": "minLength", "value": 1, "validationMessage": "Min length for BM Count is 1 digits" },
                                    { "name": "maxLength", "value": 3, "validationMessage": "BM Count field require 3 digits." }]

                                }
                            ],
                            [
                                {
                                    "colmd": "12",
                                    "colxs": "12",
                                    "key": "FieldCount", "hide": true, "type": "number", "disabled": false, "id": "FieldCount", "label": "Field",
                                    "validators": [
                                        { "name": "required", "value": true, "validationMessage": "Field Count is required." },
                                    { "name": "minLength", "value": 1, "validationMessage": "Min length for Field Count is 1 digits" },
                                    { "name": "maxLength", "value": 3, "validationMessage": "Field Count field require 3 digits." }]

                                }
                            ]
                        ]
                    }
                ]

            }
        ]
    },
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],

                        },
                        {
                            "colmd": "12", "key": "UploadPhoto", "hide": false, "type": "file", "dropTitle": "Drag and drop files here to upload!", "acceptFileTypes": acceptFileType,
                            "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": false, "disabled": false,
                            "id": "UploadPhoto", "label": "Upload Attendance Sheet", "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
                            "name": "UploadPhoto",
                            "validators": [
                                // {"name": "required", "value": true, "validationMessage": "Photo is required."}
                            ]
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    },
]