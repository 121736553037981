import React from "react";
import { useSelector } from "react-redux";
import Matbreadcrumb from "f1-mat-breadcrumb-react";
import { useHistory } from "react-router-dom";

const Breadcrumb = (props) => {
  /**
   *  [{
   *       { name: 'Home', url: '/home' },
   *  }]
   */

  const commonState = useSelector((state) => state);
  const history = useHistory();

  const breadcrumbs = commonState.commonReducer.breadcrumbs;

  const handleClick = (event, breadcrumb) => {
    event.preventDefault();
    history.push(breadcrumb.url);
  };

  return (
    <div className="breadcrumWrapper">
      <div className="row">
        <Matbreadcrumb
          handleclick={handleClick}
          separator="››"
          maxItems={2}
          breadcrumb={breadcrumbs}
          color={"inherit"}
        />
      </div>
    </div>
  );
};

export default Breadcrumb;
