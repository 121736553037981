import { format } from 'date-fns';
import { ALLOWED_FUTURE_DAYS } from '../../../../utils/config';
import { dateFormat, getDateAfterDays } from '../../../../utils/utils';
import moment from "moment";

const stateLabel = "State";
const Location = "location is required";
const faMapMarker = "fa fa-map-marker";

const topicRequired = "Topic is required";
const _format = 'yyyy-MM-dd';

const _meetingStartEndTimeValidator = "Meeting Start Date and Time is required.";
const _meetingEndTimeValidator = "Meeting End Date and Time is required.";

const averageTicketValidation = "Average Ticket size is required.";
const requiredField = "This field  is required."
const max30char = "Max 30 characters allowed."
const min10char = "Min 10 characters allowed."
const topic = "Topic for Monthly Review";
const NoOfPoliciesPlanned ="Number of policies planned";
const max5digit ="Max 5 digits allowed.";
const min1digit ="Min 1 digits allowed";
const noOfRAPActivationPlanned = "No. of RAP activation planned is required."
const mindateFormat = format(new Date(), _format) + "T" + format(new Date(), 'kk:mm');
const _dateTimeLocal = "datetime-local";
const _meetingStartTime = "Meeting Start Date and Time";
const _meetingEndTime = "Meeting End Date and Time";

const averageTicketLabel = "Average Ticket Size";
const NoofRAPactivationplannedLabel = "No. of RAP activation planned";
const currentAddLabel = "Current Address:";
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");

export const CscMothlyReviewForm = [
	{
		"key": "ReviewWith", "type": "select", "disabled": false,
		"id": "ReviewWith", "label": "Review with",
		"validators": [{ "name": "required", "value": true, "validationMessage": requiredField}], "options": []
	},
]

export const CscMonthlyReviewStateHead = [
	{
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
	},
	{
		"key": "Address",
		"type": "text", "disabled": false, "hide": false,
		"id": "Address", "label": "Address",
		"validators": [{ "name": "required", "value": false, "validationMessage": requiredField }]
	},
	{
		"key": "TrainingTopic",
		"type": "text", "disabled": false, "hide": false,
		"id": "TrainingTopic", "label": topic,
		"validators": [{ "name": "required", "value": true, "validationMessage": topicRequired },
        { "name": "maxLength", "value": 30, "validationMessage": max30char },
        { "name": "minLength", "value": 10, "validationMessage": min10char }]
	},
	{
		"key": "MeetingStartDateTime",
		"min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false,
		"id": "MeetingStartDateTime", "label": _meetingStartTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "MeetingEndDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }]
	},
	{
		"key": "Location", "locationIcon": faMapMarker, "label": "Location",
		"type": "checkinField", "name": "address", "locationLabel": currentAddLabel,
		"okayButtonText": "Submit", "cancelButtonText": "Cancel", "id": "Location",
		"validators": [{ "name": "required", "value": true ,"validationMessage": Location}]
	},

	{
		"key": "NoOfPoliciesPlanned", "hide": false, "type": "number", "disabled": false,
		"id": "NoOfPoliciesPlanned", "label": NoOfPoliciesPlanned,
		"validators": [{ "name": "required", "value": true, "validationMessage":requiredField },
		{ "name": "maxLength", "value": 5, "validationMessage": max5digit }, { "name": "mimLength", "value": 1, "validationMessage": min1digit }]
	},

	{
		"key": "AverageTicketSize", "props": { "min": "0" }, "type": "number", "id": "AverageTicketSize", "label": averageTicketLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": averageTicketValidation },
		{ "name": "maxLength", "value": 5, "validationMessage": max5digit }]
	},
	{
		"key": "NoOfRAPActivationPlanned", "props": { "min": "1" }, "type": "number", "id": "NoOfRAPActivationPlanned", "label": NoofRAPactivationplannedLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": noOfRAPActivationPlanned },
        { "name": "maxLength", "value": 5, "validationMessage": max5digit },
        { "name": "minLength", "value": 1, "validationMessage": min1digit }]
	},

]

export const CscMonthlyReviewStateManager = [
	{
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": "State name is required." }], "options": []
	},
	{
		"key": "Address",
		"type": "text", "disabled": false, "hide": false,
		"id": "Address", "label": "Address",
		"validators": [{ "name": "required", "value": false, "validationMessage": "Valid address is required." }]
	},
	{
		"key": "TrainingTopic",
		"type": "text", "disabled": false, "hide": false,
		"id": "TrainingTopic", "label": topic,
		"validators": [{ "name": "required", "value": true, "validationMessage": topicRequired },
        { "name": "maxLength", "value": 30, "validationMessage": max30char },
        { "name": "minLength", "value": 10, "validationMessage": min10char }]
	},
	{
		"key": "MeetingStartDateTime",
		"min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false,
		"id": "MeetingStartDateTime", "label": _meetingStartTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "MeetingEndDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }]
	},
	{
		"key": "Location", "locationIcon": faMapMarker, "label": "Location",
		"type": "checkinField", "name": "address", "locationLabel": currentAddLabel,
		"okayButtonText": "Submit", "cancelButtonText": "Cancel", "id": "Location",
		"validators": [{ "name": "required", "value": true ,"validationMessage": Location}]
	},

	{
		"key": "NoOfPoliciesPlanned", "hide": false, "type": "number", "disabled": false,
		"id": "NoOfPoliciesPlanned", "label": NoOfPoliciesPlanned,
		"validators": [{ "name": "required", "value": true, "validationMessage": requiredField },
		{ "name": "maxLength", "value": 5, "validationMessage": max5digit }, { "name": "mimLength", "value": 1, "validationMessage": min1digit }]
	},

	{
		"key": "AverageTicketSize", "props": { "min": "0" }, "type": "number", "id": "AverageTicketSize", "label": averageTicketLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": averageTicketValidation },
		{ "name": "maxLength", "value": 5, "validationMessage": "Max 5 digits allowed" }]
	},

	{
		"key": "NoOfRAPActivationPlanned", "type": "number", "id": "NoOfRAPActivationPlanned", "label": NoofRAPactivationplannedLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": noOfRAPActivationPlanned },
        { "name": "maxLength", "value": 5, "validationMessage": max5digit },
        { "name": "minLength", "value": 1, "validationMessage": min1digit }]
	},

]

export const CscMonthlyReviewDistrictManager = [
	{
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": "State name is required." }], "options": []
	},
	{
		"key": "DistrictID", "type": "select",
		"disabled": false, "hide": true,
		"id": "DistrictID", "label": "District Name",
		"validators": [{ "name": "required", "value": true, "validationMessage": "District name is required." }], "options": []
	},
	{
		"key": "Address",
		"type": "text", "disabled": false, "hide": false,
		"id": "Address", "label": "Address",
		"validators": [{ "name": "required", "value": false, "validationMessage": "Valid address is required." }]
	},
	{
		"key": "TrainingTopic",
		"type": "text", "disabled": false, "hide": false,
		"id": "TrainingTopic", "label": topic,
		"validators": [{ "name": "required", "value": true, "validationMessage": topicRequired },
        { "name": "maxLength", "value": 30, "validationMessage": max30char },
        { "name": "minLength", "value": 10, "validationMessage": min10char }]
	},
	{
		"key": "MeetingStartDateTime",
		"min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false,
		"id": "MeetingStartDateTime", "label": _meetingStartTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "MeetingEndDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }]
	},
	{
		"key": "Location", "locationIcon": faMapMarker, "label": "Location",
		"type": "checkinField", "name": "address", "locationLabel": currentAddLabel,
		"okayButtonText": "Submit", "cancelButtonText": "Cancel", "id": "Location",
		"validators": [{ "name": "required", "value": true, "validationMessage": Location}]
	},

	{
		"key": "NoOfPoliciesPlanned", "hide": false, "type": "number", "disabled": false,
		"id": "NoOfPoliciesPlanned", "label": NoOfPoliciesPlanned,
		"validators": [{ "name": "required", "value": true, "validationMessage": requiredField },
		{ "name": "maxLength", "value": 5, "validationMessage": max5digit }, { "name": "mimLength", "value": 1, "validationMessage": min1digit }]
	},

	{
		"key": "AverageTicketSize", "props": { "min": "0" }, "type": "number", "id": "AverageTicketSize", "label": averageTicketLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": averageTicketValidation },
		{ "name": "maxLength", "value": 5, "validationMessage": max5digit }]
	},

	{
		"key": "NoOfRAPActivationPlanned", "type": "number", "id": "NoOfRAPActivationPlanned", "label": NoofRAPactivationplannedLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": noOfRAPActivationPlanned },
        { "name": "maxLength", "value": 5, "validationMessage": max5digit },
        { "name": "minLength", "value": 1, "validationMessage": min1digit }]
	},

]
