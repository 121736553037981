import React, { useState, useEffect, useRef } from "react";
import { format } from "date-fns";

import { useHistory } from "react-router-dom";

import DynamicForm from "f1-dynamic-form-react";
import {
  BankaSalesMangementActvityForm,
  handleSmaActivitySelection,
} from "./createSalesManagementActivityForm";
import "../activity/createActivity.scss";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import IdleTimer from "react-idle-timer";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  setBreadcrumbs,
  getTrainingType,
  getBDMBranchName,
  activateLoader,
  addDynamicData,
  deActivateLoader,
  activateSuccessMessageBar,
  activateErrorMessageBar,
  getBdmBankRegion,
  getsubordinatedetailsbylevel,
} from "../../../redux/actions/common";
import SaveAsDraftDrawer from "../activity/saveAsDraftDrawer";

import {
  saveActivityOffline,
} from "../../../offlineManager/activityOfflineManager";


import { ABH_SALES_ACTIVITY_TYPES_ID, RBH_SALES_ACTIVITY_TYPES_ID, STATUS_ACTIVITY_DRAFTED,CONFIGS } from "../../../utils/config";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getActivitySmaName, getOptionByCode } from "../activity/helper";
import { validateActivity } from "../../../redux/actions/createActivityActions";
import { getBDMforABHlist } from "../../../redux-reducer/reducer";


const customValidators = [];
const createSalesMangementActivityTitle = "Create Sales Management Activity";
const _formatDate = "YYYY-MM-DDTHH:mm";
const _format = "YYYY-MM-DD HH:mm:ss";
const _url = "/app/activity/smaActivities";

const CreateSalesManagementActivity = (props) => {
  const data = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  let dyFormRef = useRef();

  const selectOption = [{ "key": "Select", "label": "Select", "value": "" }]
  const BDMList = data.leadFormReducer.BDMList.UsersDetails;
  const activityStatusId = data.commonReducer.ActivityStatus
  const activityType = data.commonReducer.Activities;
  const trainingType = data.commonReducer.smaTrainingType;
  const bankRegion = data.commonReducer.smaBankRegion;
  const abhUserId = data.userReducer.profileInfo.userId;
  const roleId = data.userReducer.profileInfo.roleId
  const abhUserName = `${data.userReducer.profileInfo.firstName}${" "}${data.userReducer.profileInfo.lastName}`;
  const activityTypeId = roleId === "ABH" ? ABH_SALES_ACTIVITY_TYPES_ID : RBH_SALES_ACTIVITY_TYPES_ID
  const activityTypeList = activityType.filter((item) =>
    activityTypeId.includes(Number(item.value))
  );
  // const sortBankRegion = bankRegion.filter(
  //   (item) => item.ABHUserID == abhUserId
  // );
  const sortBankRegion = bankRegion
  console.log("bankRegion",data.commonReducer)
  const [current, setCurrent] = useState({
    id: 1,
    MeetingStartDateTime: moment(new Date()).format("DD-MM-YYYY"),
    MeetingEndDateTime: moment(new Date()).add(30, "m").format("DD-MM-YYYY"),
    ActivityDateTime: moment(new Date()).format(_formatDate),
    // dateVisitBranch: moment(new Date()).format(_formatDate),
    ABHDate : format(new Date(),"yyyy-MM-dd")
  });
  const [branchList, setBranchList] = useState([])

  const [formDataState, setFormDataState] = useState(
    BankaSalesMangementActvityForm
  );
  const [extErrors] = useState({});
  const [responseMessage, setresponseMessage] = useState("");
  const [isActiveMobileDraft, setisActiveMobileDraft] = useState(false)
  let idleTimer = useRef()
  const [timeOutMsg, setTimeOutMsg] = useState()
  const [abhName, setABHName] = useState([])
  const [bdmByRBHName, setBDMByRBHName] = useState([])

  const onChangeHandler = async (event, key, model) => {
    delete model.errors["BDMName"]
    delete model.errors["BranchName"]
    delete model.errors["Month_Year"]
    delete model.errors["bankRegion"]
    delete model.errors["typeOfTraning"]
    const formData = handleSmaActivitySelection(
      event,
      key,
      formDataState,
      data.commonReducer,
      getOptionData(BDMList),
      getOptionTrainingType(trainingType),
      getOptionBankRegion(sortBankRegion),
      data.userReducer.profileInfo.roleId === "RBH" ? getRBHOptionData(abhName) : null,
      data.userReducer.profileInfo.roleId === "RBH" ? getBDMBYRBHOptionData(bdmByRBHName) : null
    );
    setFormDataState(formData);
    if (key === "SalesMangementID") {
      if(data.userReducer.profileInfo.roleId === "ABH"){
        setCurrent({
          ...current,
          SalesMangementID: event.target.value,
          ABHID: abhUserName,
          MeetingStartDateTime: moment(new Date()).format(_formatDate),
          MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
        });
      } else if(data.userReducer.profileInfo.roleId === "RBH"){
        setCurrent({
          ...current,
          SalesMangementID: event.target.value,
          RBHName: abhUserName,
          MeetingStartDateTime: moment(new Date()).format(_formatDate),
          MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
        });
      }
    }
    if (current.SalesMangementID === "27") {
      if (key === "BDMName") {
        model.BranchName = "";
        if (event.target.value && event.target.value !== '') {
          const res = await props.getBDMBranchName(event.target.value)
           props.deActivateLoader()
          if (res.status === 200) {
            if (formDataState[0].options[2].value === "27") {
              const copyFormData = formDataState;
              copyFormData[2].options = getOptionBdmList(res.data);
              setBranchList(getOptionBdmList(res.data));
              setFormDataState(copyFormData);
            }
          }
        }
      }
    }
    if (current.SalesMangementID === "42") {
      if (key === "ABHName") {
        model.BranchName = "";
        if (event.target.value && event.target.value !== '') {
          props.activateLoader();
          const res = await props.getBDMforABHlist(event.target.value,data.userReducer.profileInfo.roleId)
          if (res.status === 200) {
            if (formDataState[0].options[5].value === "42") {
              const copyFormData = formDataState;
              copyFormData[2].options = getOptionData(res.data[0].UsersDetails);
              setFormDataState(copyFormData);
            }
          }
          props.deActivateLoader();
        }
      }
      if (key === "BDMName") {
        model.BranchName = "";
        if (event.target.value && event.target.value !== '') {
          const res = await props.getBDMBranchName(event.target.value)
          if (res.status === 200) {
            if (formDataState[0].options[5].value === "42") {
              const copyFormData = formDataState;
              copyFormData[3].options = getOptionBdmList(res.data);
              setFormDataState(copyFormData);
            }
          }
        }
      }
      if (key === "BranchName") {
        setCurrent({
          ...current,
          BranchCode: event.target.value
        })
      }
    }

    if (key === "MeetingStartDateTime") {
      let StartDate = moment(event.target.value).format(_formatDate);
      formDataState.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate).endOf("day").format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
        setCurrent({
          ...current,
          MeetingStartDateTime: event.target.value,
          MeetingEndDateTime: moment(new Date(event.target.value))
            .add(30, "m")
            .format(_formatDate),
        });
      });
    }
    if (key === "MeetingEndDateTime") {
      setCurrent({
        ...current,
        MeetingEndDateTime: moment(event.target.value).format(_formatDate),
      });
    }
    if (key === "ActivityDateTime") {
      setCurrent({
        ...current,
        ActivityDateTime: moment(event.target.value).format(_formatDate),
      });
    }
  };
  const isValidBusinessActivity = async (formData) => {
      if (
        formData.hasOwnProperty("MeetingStartDateTime") &&
        formData.hasOwnProperty("MeetingEndDateTime")
      ) {
        const checkActivity = await props.validateActivity({
          user_id: abhUserId,
          formData: formData,
        });

        if (
          checkActivity.status === 200 &&
          checkActivity.data.Responsestatus === true
        ) {
          return true;
        } else {
          setresponseMessage(checkActivity.data.Responsemessage);
        }
        return false;
      }
      return true;
  };

  const getOptionData = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["UserID"],
        label: item["UserName"],
        value: item["UserID"],
      };
    });
    return [...selectOption, ...options];
  };

  const getRBHOptionData = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["userID"],
        label: item["userName"],
        value: item["userID"],
      };
    });
    return [...selectOption, ...options];
  };

  const getBDMBYRBHOptionData = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["userName"],
        label: item["userName"],
        value: item["userID"],
      };
    });
    return [...selectOption, ...options];
  };

  const getOptionActivityType = (optionList) => {
    const options = optionList.map((item) => {
      return { key: item["key"], label: item["label"], value: item["value"] };
    });
    return [...selectOption, ...options];
  };
  const getOptionTrainingType = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["ID"],
        label: item["TrainingType"],
        value: item["ID"],
      };
    });
    return [...selectOption, ...options];
  };

  const getOptionBankRegion = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["ABHUserID"],
        label: `${item["RegionName"]} - ${item["RHName"]} `,
        value: `${item["RegionName"]} - ${item["RHName"]} `,
      };
    });
    return [...selectOption, ...options];
  };

  const getOptionBdmList = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["BranchID"],
        label: `${item["BranchName"]} - ${item["BranchCode"]} `,
        value: item["BranchID"],
      };
    });
    return [...selectOption, ...options];
  };

  const onSubmit = async (model) => {
    const formData = { ...model };
    if (!model.isValidForm || Object.keys(extErrors).length > 0 || Object.keys(model.errors).length > 0) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = []
      const formD = formDataState
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1)
        }
      })


      const errorElement = document.getElementsByClassName(`${'control'}${errArr[0]}`)[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: "center",
        });
      }
      
      if (Object.keys(model.errors).length == 1 && !model.BranchId && !model.errors.hasOwnProperty('BranchIdlabel')) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
      if ((!model.isValidForm && Object.keys(model.errors).length > 0) || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }

    const BDMName = BDMList.filter(val => val.UserID == formData['BDMName'])

    if (model?.MeetingEndDateTime && model?.MeetingStartDateTime) {
      if (model?.MeetingStartDateTime) {
        formData['MeetingStartDateTime'] = model?.MeetingStartDateTime.replace("T", ' ');
      } else {
        formData["MeetingStartDateTime"] = null;
      }
      if (model?.MeetingEndDateTime) {
        formData['MeetingEndDateTime'] = model?.MeetingEndDateTime.replace("T", ' ');
      } else {
        formData["MeetingEndDateTime"] = null;
      }
    } else {
      formData["MeetingStartDateTime"] = null;
      formData["MeetingEndDateTime"] = null;
    }
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    formData["isActiveCheckin"] = false;
    formData["ActivityID"] = model.SalesMangementID ? model.SalesMangementID : null;
    formData["BranchId"] = model.BranchName ? model.BranchName : null;
    formData["ActivityStatusId"] = activityStatusId[1].value;
    formData["UserId"] = abhUserId;
    formData["AssignedTo"] = formData['BDMName'] ? formData['BDMName'] : data.userReducer.profileInfo.userId;
    formData["CreatedDate"] = moment().format(_format)
    formData["ModifiedDate"] = moment().format(_format);
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(_format);
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["AssignedToName"] = BDMName[0]?.UserName ? BDMName[0].UserName :abhUserName
    formData["IsBacklog"] = false;
    formData["CreatedBy"] = abhUserId;
    formData["ModifiedBy"] = abhUserId;
    formData["BDMID"] = model.BDMName ? model.BDMName : null;
    formData["ActivityDateTime"] = model.ActivityDateTime ? model.ActivityDateTime.replace("T", ' ') : null;
    formData["BankRegionName"] = model?.bankRegion ? model.bankRegion : null;
    formData["ABHID"] = roleId === "ABH" ? abhUserId : model.ABHID ;
    formData["TrainingTypeID"] = model.typeOfTraning ? model.typeOfTraning : null;
    formData["ActionPlanasAgreedWithBDM"] = model.actionPlan ? model.actionPlan : null;
    formData["ActivityDurationID"] = model.activityDuration ? model.activityDuration : null;
    formData["MetBranchManager"] = model.metBranchManager ? model.metBranchManager : null;
    formData["Comments"] = model.Comments ? model.Comments : null;
    formData["DiscussCurrentCampaign"] = model.discussCurrentCampaign ? model.discussCurrentCampaign : null;
    formData["DiscussIfTheRegionisQualifiedforit"] = model.discussRegionIsQualified ? model.discussRegionIsQualified : null;
    formData["ActionablesDecided"] = model.actionablesDecided ? model.actionablesDecided : null;
    formData["ISCompleted"] = model.Completed ? model.Completed : null;
    formData["CustomerName"] = model.customerName ? model.customerName : null;
    formData["ISClosed"] = model.Closed ? model.Closed : null;
    formData["PolicyApplicationNumber"] = model.policyApplicationNumber ? model.policyApplicationNumber : null;
    formData["CircularTitle"] = model.circularTitle ? model.circularTitle : null;
    formData["UploadPdfFile"] = model.detailsOfCircular ? model.detailsOfCircular : null;

    formData["ActivityName"] = getActivitySmaName(activityType, formData.SalesMangementID, formData["MeetingStartDateTime"], formData["BranchId"], branchList);
    if (navigator.onLine) {
      props.activateLoader();
      let isValidActivity = await isValidBusinessActivity(formData);
      isValidActivity = parseInt(model.SalesMangementID) === 27 ? true : isValidActivity;
      if (isValidActivity) {
        const result = await props.addDynamicData({
          data: { ...formData, "IsBacklog": 0 },
          formName: CONFIGS.NEW_ACTIVITY_FORM,
        });
        if (result.status === 200 && result.data.Responsestatus === true) {
          props.activateSuccessMessageBar(
            "Activity has been created successfully."
          );

          props.deActivateLoader();
          const saveFormData = { ...formData };
          //  SAVE activity in indexDb online
          saveFormData["TrnActivityID"] = result.data.Transactionid;
          saveFormData["IsBacklog"] = false;
          saveActivityOffline(saveFormData).then((res) => {
            if (res) {
              reset(formData);
              history.push(
                `/app/salesmanagement/detail/${result.data.Transactionid}`
              );
            }
          });
        } else {
          props.deActivateLoader();
          props.activateSuccessMessageBar(
            "Something went wrong! please try again later."
          );
        }
        // }
      } else {
        props.activateErrorMessageBar(responseMessage);
        props.deActivateLoader();
      }

    }
  };

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    const formData = {...dyFormRef.current.state};
    // return false;
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace("T", " ");
    }

    const BDMName = BDMList.filter(val => val.UserID == formData['BDMName'])

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_DRAFTED,
      activityStatusId
    );
    formData["ActivityDateTime"] = formData["ActivityDateTime"].replace("T", ' ') ;

    formData["BDMID"] = formData['BDMName'] ? formData['BDMName'] : null;

    formData["ActivityID"] = formData['SalesMangementID'] ? formData['SalesMangementID'] : null;

    formData["ABHID"] = roleId === "ABH" ? abhUserId : formData["ABHID"] ;
    formData["BranchId"] = formData["BranchName"] ? formData["BranchName"] : null;
    formData["BranchName"] = formData["BranchName"] ? formData["BranchName"] : null;
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = data.userReducer.profileInfo.userId;
    formData["ModifiedBy"] = abhUserId;
    formData["CreatedBy"] = abhUserId;
    formData["AssignedTo"] = formData['BDMName'] ? formData['BDMName'] : data.userReducer.profileInfo.userId;
    formData["CreatedDate"] = moment(new Date()).format(_format);
    formData["ModifiedDate"] = moment(new Date()).format(_format);

    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(
      "YYYY-MM-DD HH:mm:ss"
    );
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["BankRegionName"] = formData?.bankRegion ? formData.bankRegion : null;
    formData["ActivityStatus"] = statusOption.label
    formData["AssignedToName"] = BDMName[0]?.UserName ? BDMName[0].UserName :abhUserName;
    formData["IsBacklog"] = false;
    formData["TrainingTypeID"] = formData.typeOfTraning ? formData.typeOfTraning : null;
    if (navigator.onLine) {
      const result = await props.addDynamicData({
        data: { ...formData, IsBacklog: 0 }, 
        formName: CONFIGS.NEW_ACTIVITY_FORM,
        CreatedBy: data.userReducer.profileInfo.userId,
      });
      if (result.status === 200 && result.data.Responsestatus === true) {
        props.activateSuccessMessageBar(
          "Activity has been drafted successfully."
        );
        reset(formData);
        props.deActivateLoader();
        saveActivityOffline(formData);
        history.push("/app/activity/smaActivities");
      } else {
        props.deActivateLoader();
        props.activateSuccessMessageBar(
          "Something went wrong! please try again later."
        );
      }
    } else {
      props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
         history.push(`/app/activity/smaActivities`);
        }
      });
    }

  };
  const reset = (model) => {
    for (let [key] of Object.entries(model)) {
      model[key] = "";
    }
    dyFormRef.current = model;
  };
  const LoadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Sales Management", url: "/app/activity/smaActivities" },
      {
        name: createSalesMangementActivityTitle,
        url: "/app/salesmanagement/create",
      },
    ];
    dispatch(setBreadcrumbs(breadcrumbs));
  };

  useEffect(() => {
    LoadBreadCrumbs();
    const newData = formDataState;
    newData[0].options = getOptionActivityType(activityTypeList);
    setFormDataState(newData);
    // const formData = handleSmaActivitySelection(
    //   { target: { value: "26" } },
    //   "SalesMangementID",
    //   newData,
    //   data.commonReducer,
    //   getOptionData(BDMList),
    //   getOptionTrainingType(trainingType),
    //   getOptionBankRegion(sortBankRegion)
    // );
    // setFormDataState(formData);
    // setCurrent({
    //   ...current,
    //   SalesMangementID: "26",
    //   ABHName: abhUserName,
    //   MeetingStartDateTime: moment(new Date()).format(_formatDate),
    //   MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
    //   min: moment(new Date()).format(_formatDate),
    //   max: moment(new Date()).add(30, "m").format(_formatDate),
    // });
    props.getTrainingType()
    props.getBdmBankRegion({userId : abhUserId, role:  data.userReducer.profileInfo.roleId})


    const getABHName = async () => {
      const res = await props.getsubordinatedetailsbylevel({userId: data.userReducer.profileInfo.userId, level: "ABH"})
      setABHName(res.data)
      const resBDM = await props.getsubordinatedetailsbylevel({userId: data.userReducer.profileInfo.userId, level: "BDM"})
      setBDMByRBHName(resBDM.data)
    }
    getABHName();
  }, []);

  useEffect(()=>{
    if(timeOutMsg){
      setTimeout(() => {
        history.push("/app/home")
      }, 3000)
    }
  }, [timeOutMsg])

  const onCancelHandler = () => {
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 468 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      setisActiveMobileDraft(true);
    } else {
      history.push(_url);
    }
  };

  const toggleDrawer = (flag) => {
    setisActiveMobileDraft(flag);
  };

  const onDontSaveDraftHandler = () => {
    history.push(_url);
  };
  const onSaveDraftHandler = () => {
    handleSaveAsDraft(new Event("test"));
  };

  const handleOnAction = (event) => {
    if (timeOutMsg) {
      setTimeout(() => {
        history.push("/app/home")
      }, 3000)
    }
  }
  const handleOnActive = (event) => {
    if (idleTimer.current.getRemainingTime() === 0) {
      setTimeOutMsg(idleTimer.current.getRemainingTime() === 0)
    }
  }
  const handleOnIdle = (event) => {
  }


  return (
    <div className="mainContainer createActivity">
      <IdleTimer
          ref={(ref) => {
            idleTimer.current = ref;
          }}
          timeout={1000 * 60 * 1}
          onActive={handleOnActive}
          onIdle={handleOnIdle}
          onAction={handleOnAction}
          debounce={250}
        />
        <Snackbar open={timeOutMsg} autoHideDuration={3000}>
          <Alert severity="error">
            Your session has been expired! You will be redirected to homepage in
            3 seconds.
          </Alert>
        </Snackbar>
      <Breadcrumb></Breadcrumb>
      <span className="formInfo">
        {"Please fill in the activity information"}
      </span>
      <div className="formBox">
        <DynamicForm
          key={current.id}
          className="form"
          onRef={(ref) => {
            dyFormRef.current = ref
            }}
          title={createSalesMangementActivityTitle}
          defaultValues={current}
          model={formDataState}
          onSubmit={(model) => {
            onSubmit(model);
          }}
          showAstersikOnRequiredFields={true}
          customValidators={customValidators}
          extErrors={extErrors}
          onChange={(event, key, model) => onChangeHandler(event, key, model)}
          extaraActionFormButton={
            <>
               <a
                  className="savedraftLink"
                  onClick={(e) => handleSaveAsDraft(e)}
                  href="#"
                >
                  SAVE AS DRAFT
                </a>

              <button
                className="btn cancelBtn"
                key="cancel"
                id="cancel"
                type="button"
                onClick={onCancelHandler}
              >
                {"Cancel"}
              </button>
            </>
          }
        />
      </div>
      <SaveAsDraftDrawer
        isActiveMobileDraft={isActiveMobileDraft}
        toggleDrawer={toggleDrawer}
        onDontSaveHandler={onDontSaveDraftHandler}
        onSaveDraftHandler={onSaveDraftHandler}
      ></SaveAsDraftDrawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
const mapDispatchToProps = (dispatch) => ({
  getTrainingType: (payload) => dispatch(getTrainingType(payload)),
  getsubordinatedetailsbylevel: (payload) => dispatch(getsubordinatedetailsbylevel(payload)),
  getBDMforABHlist: (userid, roleid) => dispatch(getBDMforABHlist(userid, roleid)),
  getBdmBankRegion: (payload) => dispatch(getBdmBankRegion(payload)),
  getBDMBranchName: (payload) => dispatch(getBDMBranchName(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
});

export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(CreateSalesManagementActivity)
);