import { get} from '../../api/api'
import { BASE_URL_ACTIVITY, GET_ACTIVITY_BY_ID, GET_ACTIVITY_HISTORY_BY_ID,GET_CAMPAIGN_ACTIVITY_HISTORY_BY_ID } from '../../api/baseURL'

export const getActivityDetails = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get( GET_ACTIVITY_BY_ID + payload.activityId, {})
            resolve(res)
        } catch (err) {
            console.log(err)
            dispatch({
                type: 'DEACTIVATE_LOADER',
                payload: false
            })
            dispatch({
                type: 'ACTIVATE_ERROR_MESSAGE_BAR',
                payload: err.data
            })
            reject(err)
        }
    })
}

export const getCampaignActivityDetails = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get(`${BASE_URL_ACTIVITY}getcampaignactivitybyid/`+ payload.activityId, {})
            resolve(res)
        } catch (err) {
            console.log(err)
            dispatch({
                type: 'DEACTIVATE_LOADER',
                payload: false
            })
            dispatch({
                type: 'ACTIVATE_ERROR_MESSAGE_BAR',
                payload: err.data
            })
            reject(err)
        }
    })
}

export const getActivityDetailsHistory = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get( GET_ACTIVITY_HISTORY_BY_ID + payload.activityId, {})
            resolve(res)
        } catch (err) {
            console.log(err)
            dispatch({
                type: 'DEACTIVATE_LOADER',
                payload: false
            })
            if(err){
                dispatch({
                    type: 'ACTIVATE_ERROR_MESSAGE_BAR',
                    payload: err.data
                })
            }
            else{
                dispatch({
                    type: 'ACTIVATE_ERROR_MESSAGE_BAR',
                    payload: "Something went wrong please try again later."
                })
            }

            reject(err)
        }
    })
}

export const getCampiagnActivityDetailsHistory = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await get( GET_CAMPAIGN_ACTIVITY_HISTORY_BY_ID + payload.activityId, {})
            resolve(res)
        } catch (err) {
            console.log(err)
            dispatch({
                type: 'DEACTIVATE_LOADER',
                payload: false
            })
            if(err){
                dispatch({
                    type: 'ACTIVATE_ERROR_MESSAGE_BAR',
                    payload: err.data
                })
            }
            else{
                dispatch({
                    type: 'ACTIVATE_ERROR_MESSAGE_BAR',
                    payload: "Something went wrong please try again later."
                })
            }
            reject(err)
        }
    })
}