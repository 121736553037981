import { createSlice } from '@reduxjs/toolkit'
const persistancyLeadListSlice = createSlice({
    name: "PersistancyLeadList",
    initialState: {
        persistancyLeadListLoading: false,
        persistancyLeadListError: false,
        allPersistancyLeadList: {},
        persistancyActivity:[],
    },
    reducers: {
      persistancyLeadListLoading:( state,action) => {
        state.persistancyLeadListLoading = action.payload;
      },

      persistancyLeadListSuccess: (state, action) => {
        state.allPersistancyLeadList = action.payload;
        state.persistancyLeadListLoading = false;
      },
      persistancyLeadListError: (state, action) => {
        state.persistancyLeadListError = action.payload;
        state.persistancyLeadListLoading = false;
      },
      getFilteredPersistanceLeads : (state,action) =>{
          const persistancyLead = {
            TotalnoofRows: action.payload.TotalnoofRows,
            Listofleads:action.payload.Persistencyleaddata,
            Noofpages:action.payload.Noofpages
          }
          state.allPersistancyLeadList = persistancyLead
      },
      persistancyActivity : ( state,action) => {
        state.persistancyActivity = action.payload;
      },
    }
  });

export const { persistancyLeadListLoading, persistancyLeadListSuccess, 
  persistancyLeadListError,persistancyLeadListLoadingEnd, getFilteredPersistanceLeads,persistancyActivity
    } = persistancyLeadListSlice.actions
export default persistancyLeadListSlice.reducer



