import { ALLOWED_FUTURE_DAYS } from "../../../../utils/config";
  import { getDateAfterDays } from "../../../../utils/utils";
import { format } from 'date-fns';
import moment from "moment";

const _format = 'yyyy-MM-dd';
const faMapMarker = "fa fa-map-marker";
const meetingLocation = "Meeting Location";
const currAddress = "Current Address: ";
const meetingLocRequired = "Meeting location is required.";
const requiredField = "This field  is required.";
const dateFormate = 'yyyy-MM-dd';
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";
const meetingStartDateAndTimeRequired = "Meeting start time is required.";
const meetingEndDateAndTimeRequired = "Meeting end time is required."
const minDate = format(new Date(), dateFormate) + "T" + format(new Date(), 'kk:mm');
const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
const datetimelocal = "datetime-local"
const mindateFormat = `${format(new Date(), _format)} T ${format(new Date(), 'kk:mm')}`;
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");


export const newBusinessActivityForm = [
    {
        "key": "ActivityDoneWith", "type": "select", "id": "ActivityDoneWith", "label": "Select Activity done with",
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    },
    {
        "key": "AdvisorID", "type": "select", "id": "AdvisorID", "label": "Advisor Name","hide": true,
        "validators": [],
         "options": []
    },
    {
        "key": "ZMAID", "type": "select", "id": "ZMAID", "label": "ZMA Name","hide":true,
        "validators": [],
        "options": []
    },
    {
        "key": "PremierZMAID", "type": "select", "id": "PremierZMAID", "label": "Premier ZMA ","hide":true,
       "validators": [],
         "options": []
    },
    {
        "key": "ExpectedLead", "props": { "min": "0" }, "type": "number", "id": "ExpectedLead", "label": "#No of expected leads generated",
        "validators": [{ "name": "maxLength", "value": 2, "validationMessage": "Max 2 digits allowed" },
        { "name": "required", "value": false, "validationMessage": requiredField }]
    },

    {
        "key": "ExpectedConversion", "props": { "min": "0" }, "type": "number", "disabled": false, "id": "ExpectedConversion", "label": "#No of expected leads converted",
        "validators": [{ "name": "required", "value": false, "validationMessage": requiredField },
        { "name": "maxLength", "value": 2, "validationMessage": "Max 2 digits allowed" }]
    },
    {
        "key": "TicketSize", "type": "number", "id": "TicketSize", "label": "Ticket size",
        "validators": [{ "name": "required", "value": false, "validationMessage": requiredField },
        { "name": "maxLength", "value": 6, "validationMessage": "Max 6 digits allowed" }]
    },
    {
        "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
        "type": datetimelocal, "id": "MeetingStartDateTime",
        "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
      },
      {
        "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
        "max":endMaxDate,
        "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
      },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
]
