import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';


const ApplicationCreatedStatusHistory = (props) => {
    const { history, ActivityIDlabel } = props;
    const historyDate = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('DD MMM YYYY') : ''
    const historyTime = history.MeetingEndDateTime ? moment(history.MeetingEndDateTime).format('h:mma') : "";
    return <>
        {history.LeadStatus === "Application created" ?
            <>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>{ActivityIDlabel}</label>
                </TimelineContent>
            </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Scheduled Time</label><br></br>
                        <label>
                            {historyDate}
                             , {historyTime}
                        </label>
                    </TimelineContent>
                </TimelineItem>
            </>
            : ''
        }
    </>
}

export default ApplicationCreatedStatusHistory
