import React, { useEffect, useState } from "react";
import ModalPopup from "f1-modal-react";
import CustomOTPComponent from "./OTP";
import { useDispatch, useSelector } from "react-redux";
import {
  activateErrorMessageBar,
  activateSuccessMessageBar,
  getOtpForResetDevice,
  validateOtpForResetDevice,
} from "../../../redux/actions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { checkIsMobile, getUniqueDeviceId } from "../../../utils/utils";
import { resetMasterDataSyncTime } from "../../../offlineManager/leadOfflineManager";
import { logOutAction } from "../../../redux/actions/userAction";
const ResetDevice = () => {
  const select = useSelector((state) => state);
  const dispatch = useDispatch();
  const isDeviceReset = select.commonReducer.isDeviceReset;
  const userId = select.commonReducer.userIdForDeviceReset;
  const [showModal, setShowModal] = useState(false);
  const [showModalForOTP, setShowModalForOTP] = useState(false);
  const [showModalForAlert, setShowModalForAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [otp,setOtp]=useState(null)
  const [location,setLocation]=useState({
    latitude:null,
    longitude:null
  })

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "0",
    boxShadow:"0 5px 11px 0 rgba(0,0,0,0.18) 0 4px 15px 0 rgba(0,0,0,0.15)",
    borderRadius:"10px",
    p: 4,
  };
  const sendOtpValidation = async () => {
    handleCancelReset();
    const response = await dispatch(getOtpForResetDevice(userId))
    if(response.data.success == true){
      dispatch(activateSuccessMessageBar(response.data.message))
      setShowModalForOTP(true)
    } else {
      dispatch(activateErrorMessageBar(response.data.message))
      setMessage(response.data.message)
      setShowModalForAlert(true)
    }
  };

  function successFunction(position) {
    var lat = position.coords.latitude;
    var long = position.coords.longitude;
    setLocation({
      latitude:lat,
      longitude:long
    })
   }
  function errorFunction(err) {
    console.log(err);
}

  const getLocation =()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
  } else {
      alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
  }
  }

  const logoutHandler = () => {
    resetMasterDataSyncTime().then((res) => {
      dispatch(logOutAction())
    });
    localStorage.removeItem("roleId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("expiration");
    localStorage.removeItem("token");
    sessionStorage.clear();
  };

  const clearCache = () => {
    if (caches) {
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
        logoutHandler();
        window.location.reload(true);
    } else {
      console.log("cache undefined");
    }
  };

  const handleValidateOtp =async () => {
    let userDeviceId = getUniqueDeviceId();
    setShowModalForOTP(false);
    const otpData = otp.join('')
    let payload={
        "userid": userId,
        "otp": Number(otpData),
        "deviceId": userDeviceId,
        "latitude": location.latitude.toString(),
        "longitude": location.longitude.toString()
      }
    const response = await dispatch(validateOtpForResetDevice(payload))
      if(response.status==200&& response.data.success==true){
        clearCache()
        setMessage(response.data.message)
        setShowModalForAlert(true)
        dispatch(activateSuccessMessageBar("reset device successfully"))
      }else{
        // dispatch(activateErrorMessageBar(response.data.message))
        setMessage(response.data.message)
        setShowModalForAlert(true)
      }
  };
  const handleCancel = () => {
    dispatch({
      type: "isDeviceReset",
      payload: false,
    });
  };
  const handleAlertCancel = () => {
    setShowModalForAlert(false)
  };

  const handleCancelReset = () => {
    dispatch({
      type: "isDeviceReset",
      payload: false,
    });
  };

  useEffect(() => {
    setShowModal(isDeviceReset);
  }, [isDeviceReset]);

useEffect(()=>{
  getLocation()
},[])

  return (
    <div>
      <ModalPopup
        show={showModal}
        onHide={() => handleCancel()}
        size={"sm"}
        centered={true}
        modelHeader={"reset device"}
        modelContent={
          <div className="footerButtons">
            <p>Are you want to reset device?</p>
            <div className="float-right">
              <Button
                className="cancelBtn btn Ripple-parent btn btn-primary"
                onClick={() => handleCancel()}>
                No
              </Button>
              &nbsp;
              <Button
                className="btn-black btn Ripple-parent btn btn-primary"
                onClick={sendOtpValidation}>
                Yes
              </Button>
            </div>
          </div>
        }
        backdrop={"static"}
      />

      <Modal
        open={showModalForOTP}
        onClose={handleValidateOtp}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <Box sx={{ ...style, width: 370 }}>
        <div class="modal-header">
          <div class="modal-title h4 " style={{
                'fontWeight': "bold",
                'fontSize': "1.2rem",
                'boxShadow': "none",
                "color":"#00427E"
          }}id="contained-modal-title-vcenter">
            OTP Section
            </div>
            </div>
          <div>
            <CustomOTPComponent setOtpData={setOtp}  />
            <div className="footerButtons mt-1">
              <div className="float-right">
                <Button
                  className="cancelBtn btn Ripple-parent btn btn-primary"
                  onClick={() => setShowModalForOTP(false)}>
                  No
                </Button>
                &nbsp;
                <Button
                  className="btn-black btn Ripple-parent btn btn-primary"
                  onClick={handleValidateOtp}>
                  Yes
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <ModalPopup
        show={showModalForAlert}
        onHide={() => handleAlertCancel()}
        size={"sm"}
        centered={true}
        modelHeader={"Alert"}
        modelContent={
          <div className="footerButtons">
            <p>{message}</p>
            <div className="float-right">
              &nbsp;
              <Button
                className="btn-black btn Ripple-parent btn btn-primary"
                onClick={handleAlertCancel}>
                Ok
              </Button>
            </div>
          </div>
        }
        backdrop={"static"}
      />

    </div>
  );
};

export default ResetDevice;
