import { VALIDATE_LEAD_MOBILE } from "../api/baseURL";
import moment from "moment"
import { get } from "../api/api";
const userData = JSON.parse(localStorage.getItem('persist:root'));
let userId;
if (userData) {
  userId = JSON.parse(userData.userReducer).profileInfo.userId;
}
const pureTextOnly=/^\S*$/;/* trim white spaces*/
const alphabetRegx = /^[A-Za-z]+$/;/*Alphabet Only*/
const numberOnly = /^[0-9]+$/;
const emailFormat = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
const mobileNumber = /^[0-9]{10}$/
const pinCode = /^[1-9][0-9]{5}$/
const alphanumeric =/^[ A-Za-z0-9_@./#&+-]*$/
const isTwoDigitOnly=/^(\d{1,2})?(?:\.\d{1,3})?$/
const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
const alphanumeric1 = /^[0-9 a-z A-Z]+$/;
const isTwocharactersOnly=/^[a-zA-Z]{2}$/
const isDigitOnly1=/^[0-9]{1,8}$/
const DigitnumberOnly = /^[0-9]+$/
const alphabetWithSpaceRegx = /^[A-Za-z ]+$/;/*Alphabet and Space Only*/
const alphanumericNospace = /^[a-zA-Z0-9]+$/;


const isEmailorMobileNo=/^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/
/*
^     #Match the beginning of the string
[789] #Match a 7, 8 or 9
\d    #Match a digit (0-9 and anything else that is a "digit" in the regex engine)
{9}   #Repeat the previous "\d" 9 times (9 digits)
$     #Match the end of the string
*/
const numberStartWith6789 = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/; //[6789]

/**
 * Chek if isAlphabetOnly is valid
 * @prop String
 * @returns Boolean
 */
export const isAlphabetOnly = (key, value) => {

  if(value!=="") {
    return alphabetRegx.test(value);
  }
  else{
    return true
  }
};

export const isAlphabetWithSpaceOnly = (key, value) => {

  if(value!=="") {
    return alphabetWithSpaceRegx.test(value);
  }
  else{
    return true
  }
};

export const isAlphanumeric1 = (key, value) => {

  if(value!=="") {
    return alphanumeric1.test(value);
  }
  else{
    return true
  }
};

export const isRegName = (key, value) => {

  if(value!=="") {
    return regName.test(value);
  }
  else{
    return true
  }
};

export const isValidPinCode = (key,value) =>{
  if(value!==""){
    return pinCode.test(value)
  }else{
    return true
  }
}

export const isTextOnly = (key, value) => {

  if(value!=="") {
    return pureTextOnly.test(value);
  }
  else{
    return true
  }
};


export const isAlphaNumericOnly = (key, value) => {
  if (value){
    return true
  }
  return false
}
export const isEmailValid = (key, value) => {
  return emailFormat.test(value)
}

export const isBranchValid = (selectActivityID, selectBrannchCode) => {
  if (selectActivityID !== selectBrannchCode) {
    return true
  }
}

export const isMeetindDate = (status) => {
  if (status !== 'Success') {
    return true
  }
}

export const isChekin = (status) => {
 return status
}
export const checkDateIsValid=(selectedDate,minDate)=> moment(selectedDate).isAfter(moment(minDate))

export const checkDateIsValidSameOrAfter=(selectedDate,minDate)=> moment(selectedDate).isSameOrAfter(moment(minDate))


export const isNewLeadMobileNumber = (key, mobileNumber) => {
  get(`${VALIDATE_LEAD_MOBILE}/${mobileNumber}/${userId}`).then(response => {
    const { Responsestatus } = response.data;
    return Responsestatus;
  })

}


export const numberStartWith6789Validator = (key, value) => {
  const val = value?.charAt(0)
  if (!value) {
    return true;
  }
  return val >= 6
}

export const isPositiveNumber = (key, value) => {
  if (!value) {
    return true;
  }
  return value >= 1
}

export const isValidMobileNumber = (key,value) =>{
  return value ? mobileNumber.test(value) : true;
}

export const isNumberOnly = (key,value) =>{
  return value? numberOnly.test(value) : true;
}

export const meetingDateValidations = (formData) => {
	const extraValidations = {}
	if (formData.current["MeetingStartDateTime"] !== '' &&
		formData.current["MeetingEndDateTime"] !== '') {
		const startDate = new Date(formData.current["MeetingStartDateTime"]);
		const endDate = new Date(formData.current["MeetingEndDateTime"]);
		if (startDate.toDateString() !== endDate.toDateString()) {
			extraValidations['MeetingEndDateTime'] = 'Meeting dates are not the same day.'
		}
		if (startDate.toDateString() === endDate.toDateString()) {
			if (startDate.getTime() > endDate.getTime()) {
				extraValidations['MeetingEndDateTime'] = 'Meeting End Time should not less than meeting time'
			}
		}


	}
	return extraValidations;
}

export const isTwoDigitIntiger = (key, value) => {
  return isTwoDigitOnly.test(value)
}

export const isTwocharacters = (key, value) => {
  if(value.length < 3) {
    return isTwocharactersOnly.test(value)
  }
  return isTwocharactersOnly.test(value.slice(0,2))
}

export const isDigitnumberOnly = (key,value) =>{
  if(value.length > 2 && value.length < 11) {
    return DigitnumberOnly.test(value.slice(2,11))
  }
  return true
}

export const isDigitOnly = (key,value) =>{
  if(value.length > 2) {
    return isDigitOnly1.test(value.slice(2,))
  }
  return true
}

export const  isAlphanumeric =(key, value)=>{
    return alphanumeric.test(value)
}

export const  isAlphanumericNoSpace =(key, value)=>{
  return alphanumericNospace.test(value)
}
export const  isEmailorMobileNoCheck =(key, value)=>{
    return isEmailorMobileNo.test(value)
}
export const checkMonthValidity = (key, value) => {
  if (value >= 12 && value <= 360) {
    return true;
  }
  return false;
};

export function onlyLettersAndSpaces(key, value) {
  return /^[A-Za-z\s\&]*$/.test( value);
}
export function onlyAlphanumericSpaces(key, value) {
  return /^[A-Za-z0-9/s ]+$/.test( value);
}


export const  onlyAlphanumericSpacesMinMax =(textarea)=>{
  // const reg = /^[a-zA-Z0-9 ]{3,50}$/
  const reg = /^[a-zA-Z0-9 .]{3,50}$/
  return reg.test(textarea)
 }