import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';


const GCLNotAttachedLoanPreSanctionStageHistory = (props) => {
    const { history } = props
    return <>
        {history.LeadStatusId === 16 ?
            <>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Reason </label>
                        <label>{history.OtherReason}</label>
                    </TimelineContent>
                </TimelineItem>
            </>
            : ''
        }
    </>
}

export default GCLNotAttachedLoanPreSanctionStageHistory
