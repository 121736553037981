import React, { useEffect, useState } from "react";
import { getCampaignActivityListing } from "../../../redux/actions/updateActivityAction";
import { useHistory, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {
  activateLoader,
  deActivateLoader,
  getSubordinateList,
  downloadBranchPartnerCategorywise,
  downloadBrocaReportMustdoActivity,
  downloadBrocaReportBusinessDevelopment,
  downloadBrocaReportBFDWiseActivity,
  downloadBrocaReportPartnerEngagementActivity,
  downloadBrocaReportLeadGenerationActivity,
  getFilterByLevel
} from "../../../redux/actions/common";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DynamicForm from "f1-dynamic-form-react";
import { filterFormData } from "./brocaCommomActivityFilterForm";
import { ADMIN_HIERARCHY_TYPE, ADMIN_HIERARCHY_TYPE_ONLY } from "../../../utils/config";
import "../../../components/scheduleActivityTable/tableNcard.scss";
import {
  activateErrorMessageBar,
  activateSuccessMessageBar,
  setFilterBy,
} from "../../../redux/actions";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { isBrowser, isMobile } from "react-device-detect";
import ModalPopup from "f1-modal-react";
import { downloadFilesAdmin } from "../../../utils/utils";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopFilter: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const BrocaActivityListing = (props) => {
  const commonState = useSelector((state) => state);
  let location = useLocation();
  const isBranchPartner = location.pathname.includes("branchpartner");
  const isMustDo = location.pathname.includes("mustdo");
  const isLeadGeneration = location.pathname.includes("leadgeneration");
  const isBFDWise = location.pathname.includes("bfdwise");
  const isPartnerEngagement = location.pathname.includes("partnerengagement");
  const isBusinessDevelopment = location.pathname.includes("businessdevelopment");
  const classes = useStyles();
  const [stateSelect, setStateSelect] = useState({ top: false });
  const [RBHUsers, setRBHUsers] = useState([]);
  const [UserID, setUserID] = useState();
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const dispatch = useDispatch();
  const userState = commonState.userReducer;
  const [UserId] = useState(null);
  const fulln = `${userState.profileInfo.firstName} ${userState.profileInfo.lastName}`;
  const [UserFullName] = useState(fulln);
  const [showModal] = useState(true);
  const { page1 } = commonState.updateActivityReducer.activityLandingPage;
  let activepage;
  if (page1 !== 0 && commonState.updateActivityReducer.filterby === "planned") {
    activepage = 2;
  } else {
    activepage = 0;
  }
  const [tabValue] = React.useState(activepage);
  const isOnline = useOnlineStatus();
  const chanelId = 21;
  const [title,setTitle]=useState("title");
  const [fileName,setFileName]=useState("");
  const [showModel,setShowModel] = useState(false);
  useEffect(() => {
    if (isBranchPartner) {
      setTitle("Branch Partner and Category-wise");
      setFileName("BrocaReportBranchPartnerByCategory");
    } else if (isMustDo) {
      setTitle("Must Do Activity Summary");
      setFileName("BrocaReportMustDoActivitySummary");
    } else if (isLeadGeneration) {
      setTitle("Lead Generation Activity Summary");
      setFileName("BrocaReportLeadGenerationActivitySummary");
    } else if (isBFDWise) {
      setTitle("Branch Foundation Day wise Activity Summary");
      setFileName("BrocaReportBranchFoundationDayWiseActivitySummary");
    } else if (isPartnerEngagement) {
      setTitle("Partner Engagement Activity Summary");
      setFileName("BrocaReportPartnerEngagementActivitySummery");
    } else if (isBusinessDevelopment) {
      setTitle("Business Development Activity");
      setFileName("BrocaReportBusinessDevelopmentActivitySummery");
    }
  }, []);

  async function fetchActivityTableData(payload) {
    if (isOnline) {
      props.activateLoader();
      let result;
      if(isBranchPartner){
         result = await  props.downloadBranchPartnerCategorywise(
          payload
        )
      }else if(isMustDo){
         result = await props.downloadBrocaReportMustdoActivity(
          payload,
        );
      }
      else if(isLeadGeneration){
         result = await props.downloadBrocaReportLeadGenerationActivity(
          payload,
        );
      }
      else if(isBFDWise){
         result = await props.downloadBrocaReportBFDWiseActivity(
          payload,
        );
      }
      else if(isPartnerEngagement){
         result = await props.downloadBrocaReportPartnerEngagementActivity(
            payload,
        );
      }
      else if(isBusinessDevelopment){
         result = await props.downloadBrocaReportBusinessDevelopment(
          payload,
        );
      }

      if (result.status === 200) {
     return result;
      } else {
        props.deActivateLoader();
      }
    }
  }

  const clearSetFilterBy = async () => {
    await props.setFilterBy("");
  };


  const [newUserList, setNewUserList] = useState([
    {
      key: UserId,
      label: UserFullName,
      value: UserId,
    },
  ]);

  const [filterState, setFilterState] = useState({
    meetingOn: "null",
    fromdate: "null",
    todate: "null",
    UserID: null,
    // EmployeeCode: null,
  });

  const [activityReq, setActivityReq] = useState({
    StartDate: null,
    EndDate: null,
    Userid: null,
    // EmployeeCode: null,
  });

  useEffect(() => {
    filterFormData[0].options =ADMIN_HIERARCHY_TYPE_ONLY
    RBHUsers.push({ key: "Select", label: "Select", value: "Select" });
    getRBHList();
    if(roleId=="BDM"){
      filterFormData[0].hide=true
    }
    filterFormData[1].hide = true;
    filterFormData[2].hide = true;
    filterFormData[3].hide = true;
    filterFormData[4].hide = true;
    filterFormData[5].hide = true;
    filterFormData[7].hide = true;
    filterFormData[8].hide = true;
    if (userState.profileInfo.roleId !== "ADM") {
        setActivityReq({
          ...activityReq,
          Userid: userState.profileInfo.userId,
        });
      }
  }, []);

  const getSub = async (key, getUser) => {
    const result = await props.getSubordinateList({
      userId: getUser,
    });

    const r = result.data.map((el) => {
      return {
        key: el.userID,
        label: el.userName,
        value: el.userID,
      };
    });
    setTimeout(() => {
      setNewUserList([newUserList, ...r]);
      props.deActivateLoader();
    });

    if (key === "User") {
      filterFormData[4].options = [newUserList, ...r];
    } else if (key === "UserOne") {
        filterFormData[5].options = [newUserList, ...r];
    }
  };
  const getRBHList = async () => {
    const result = await props.getFilterByLevel({userLevel:"RBH", channelId: chanelId });
    console.log("RBH users are", result.data);
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setRBHUsers([...RBHUsers, ...r]);
    filterFormData[3].options = [...RBHUsers, ...r];
  };

  const resetFilters = (anchor) => {
    props.activateLoader();
    filterFormData[1].hide = true;
    filterFormData[2].hide = true;
    filterFormData[3].hide = true;
    filterFormData[4].hide = true;
    filterFormData[5].hide = true;
    filterFormData[7].hide = true;
    filterFormData[8].hide = true;
    clearSetFilterBy();
    const payload = {
        StartDate: null,
        EndDate: null,
        Userid: null,
        userType:null
        // EmployeeCode: null,

    };
    if (isOnline) {
      if (userState.profileInfo.roleId !== "ADM") {
        payload.Userid = userState.profileInfo.userId;
      }
    }
    setFilterState({
        meetingOn: "null",
        fromdate: "null",
        todate: "null",
        UserID: null,
        RBHUsers: [],
        newUserList: [],
        teamMember: null,
        User: null,
        UserOne: null,
        UserTwo: null,
        // EmployeeCode: null,
        userType:null
    });
    props.activateSuccessMessageBar('reset filter successfully')
    setActivityReq({
        StartDate: null,
        EndDate: null,
        Userid: payload.Userid !== null ? payload.Userid : null,
        // EmployeeCode: null,
    });
    props.deActivateLoader();
  };

  const onAutocompleteSelectHandler = (key, value, item) => {};
  const onSubmitHandler = (model, anchor) => {
    clearSetFilterBy();
    const {
      meetingOn,
      UserID
    } = model;

    let meetingstart = null;
    let meetingend = null;
    if (
      meetingOn !== "null" &&
      meetingOn !== "duration" &&
      meetingOn !== undefined
    ) {
      const lastdatearr = model.meetingOn.split(",");
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (meetingOn === "duration") {
      if (model.fromdate === "null" || model.todate === "null") {
        dispatch(
          props.activateErrorMessageBar(
            "Please Select Value For Both Meeting Start Date and End Date"
          )
        );
        return false;
      }
      meetingstart = model.fromdate;
      meetingend = model.todate;
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;
    if (
      meetingOn !== "null" || UserID !==null
    ) {
      const payload = {
        Userid:
          UserID !== null
            ? UserID
            : userState.profileInfo.roleId !== "ADM"
            ? userState.profileInfo.userId
            : null,
        StartDate: model.fromdate !== "null" ? model.fromdate : null,
        EndDate: model.todate !== "null" ? model.todate : null,
        // EmployeeCode: EmployeeCode ? EmployeeCode : null,
      };
      setFilterState({
        Userid: UserID,
        // EmployeeCode: EmployeeCode,
        meetingOn: meetingOn,
        fromdate: model.fromdate,
        todate: model.todate,
      });
      if (userState.profileInfo.roleId !== "ADM") {
        payload.Userid = userState.profileInfo.userId;
      }
      setActivityReq({
        Userid:
          UserID !== null
            ? UserID
            : userState.profileInfo.roleId !== "ADM"
            ? userState.profileInfo.userId
            : null,
        StartDate: model.fromdate !== "null" ? model.fromdate : null,
        EndDate: model.todate !== "null" ? model.todate : null,
        // EmployeeCode: EmployeeCode ? EmployeeCode : null,
      });
      props.activateSuccessMessageBar('Filter applied successfully')
      if (anchor) {
        setStateSelect({ ...stateSelect, [anchor]: false });
      }
    } else {
      props.activateErrorMessageBar("Please apply atleast any one option");
    }
  };

  const onFilterChange = async (event, key, model) => {
    setFilterState({
      ...filterState,
      [key]:event.target.value
    });
    switch (key) {

      case "meetingOn":
        if (event.target.value === "duration") {
          filterFormData[7].hide = false;
          filterFormData[8].hide = false;
        } else {
          filterFormData[7].hide = true;
          filterFormData[8].hide = true;
        }
        break;
      case "fromdate":
        filterFormData[8].min = event.target.value;
        break;
      case "userType":
        if (userState.profileInfo.roleId !== "ADM") {
          manageUserHeirarcheyForNonAdminUsers(
            event.target.value,
            key
          );
        }
        else {
        const getUserType = event.target.value;
        if (getUserType === "1") {
          setFilterState({
            ...filterState,
            teamMember: null,
            User: null,
            UserOne: null,
            UserTwo: null,
            UserID:null,
          });
          setActivityReq({
            ...activityReq,
            Userid:null,
          });
          //filterFormData[1].hide = false;
          filterFormData[2].hide = true;
          filterFormData[3].hide = true;
          filterFormData[4].hide = true;
          filterFormData[5].hide = true;
        } else if (getUserType === "2") {
          setFilterState({
            ...filterState,
            EmployeeCode: null,
            UserID:null,
          });
          setActivityReq({
            ...activityReq,
            EmployeeCode: null,
          });
          filterFormData[2].hide = false;
          filterFormData[3].hide = true;
          filterFormData[1].hide = true;
          filterFormData[4].hide = true;
          filterFormData[5].hide = true;
        }
      }
        break;
      case "teamMember":
        const getUser = event.target.value;
        if (getUser === "Team") {
          filterFormData[3].hide = false;
        } else {
          filterFormData[4].hide = true;
           filterFormData[5].hide = true;
        }
        break;
      case "EmployeeCode":
        setFilterState({
          ...filterState,
          EmployeeCode: event.target.value,
          teamMember: 0,
          User: null,
          UserID:0,
        });
        break;
      case "User":
        props.activateLoader();
        setFilterState({
          ...filterState,
          UserID: event.target.value,
        });
        const getUserNH = event.target.value;
        setUserID(getUserNH);
        console.log("last user's user id is:", UserID);
        await getSub("User", getUserNH);
        if (getUserNH) {
          filterFormData[4].hide = false;
          filterFormData[5].hide = true;
        }
        break;
      case "UserOne":
        props.activateLoader();
        setFilterState({
          ...filterState,
          UserID: event.target.value,
        });
        const getUserZBH = event.target.value;
        setUserID(getUserZBH);
        await getSub("UserOne", getUserZBH);
        console.log("last user's user id is:", UserID);
        if (getUserZBH) {
            props.deActivateLoader();
            filterFormData[5].hide = false;
        }
        break;
      case "UserTwo":
        setFilterState({
            ...filterState,
            UserID: event.target.value,
          });
          const getUserADM = event.target.value;
        setUserID(getUserADM);
        console.log("last user's user id is:", UserID);
        break;
      default:
        break;
    }
  };
  const manageUserHeirarcheyForNonAdminUsers = async (
    userId,
    key
  ) => {
    props.activateLoader();
    console.log("filterFormData ", filterFormData);
    // if user is non admin, set filter level accordingly
    if (userState.profileInfo.roleId !== "ADM") {
      const userRole = userState.profileInfo.roleId;
      switch (userRole) {
        case "BDM":
          console.log("inside of ", userRole);
          break;

        case "ABH":
          console.log("inside of ", userRole);
          setUserID(userState.profileInfo.userId);
          await getSub("UserOne", userState.profileInfo.userId);
          if (userState.profileInfo.userId) {
            filterFormData[5].hide = false;
            filterFormData[4].hide = true;
            filterFormData[2].hide = true;
          }
          break;
        case "RBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("User", userState.profileInfo.userId);
          filterFormData[4].hide = false;
          filterFormData[5].hide = false;
           filterFormData[2].hide = true;
          break;
        default:
          break;
      }
    }
  };
  const csvToArray =(str, delimiter = ",")=> {
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });
    return arr;
  }

  const csvExp = async () => {
      const payload = {

        filterReq: { ...activityReq },
      };
      try {
        props.activateLoader();
      const downloadData= await fetchActivityTableData(payload.filterReq);
      if (downloadData.status === 200) {
        const data = csvToArray(downloadData.data);
        console.log("downloadData",downloadData.data)
        console.log(data.length)
        if(data.length<2)
        {
          setShowModel(true)
          props.deActivateLoader();
        }
        else {
          props.deActivateLoader();
          // setShowModel(false)
          downloadFilesAdmin(downloadData, fileName);
        } 
      } else {
          props.deActivateLoader();
        }
      } catch (err) {
        props.deActivateLoader();
      }
  };
  return (
    <>
      {isBrowser && (
        <div className="activityList">
          <TabPanel value={tabValue} index={0}>
            <Grid
              className="activiPad0"
              container
              spacing={2}
              columns={{ xs: 4, md: 12 }}
            >
              <Grid item xs={12} md={9} className="leftCol">
                <div className="admindashCompain">
                  <div className="float-right">
                    <button className="btn btn-primary" onClick={csvExp}>
                      <span className="fa fa-download">&nbsp;</span> Download
                      List
                    </button>
                    &nbsp;&nbsp;&nbsp;
                  </div>
                  <div className="clearfix"></div>
                  <div>
                    <span style={{ fontWeight: "bold" ,fontSize:"30px"}}>{title}</span>
                  </div>
                </div>
                <ModalPopup
          show={showModel}
          size={"sm"} // "lg","sm" or "xl"
          centered={true} // vertically center the Dialog in the window
          modelHeader={"No Records available for download"} // to show model header
          modelContent={
            <div className="footerButtons">
              {/* <p>Please view in Desktop/Laptop</p> */}
              <Button onClick={()=>setShowModel(false)}>OK</Button>
            </div>
          }
          backdrop={"static"}
        />
              </Grid>
              <Grid item xs={12} md={3} className="rightCol">
                <div className={classes.desktopFilter}>
                  <div className="filterBox">
                    <h4 className="filterSec">
                      {" "}
                      <FilterAltIcon className="icnFilter" /> Filter By
                    </h4>
                    <DynamicForm
                      key={"filterId"}
                      title={" "}
                      buttonSubmit={"APPLY FILTER"}
                      defaultValues={filterState}
                      model={filterFormData}
                      onSubmit={(model) => {
                        onSubmitHandler(model);
                      }}
                      disableSubmitOnInValid={false}
                      design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
                      materialOutline={false}
                      onChange={(event, key,model) => onFilterChange(event, key,model)}
                      onAutocompleteSelect={onAutocompleteSelectHandler}
                      showAstersikOnRequiredFields={true}
                      className="activityFilters"
                      extaraActionFormButton={
                        <span className="reset" onClick={resetFilters}>
                          <RestartAltIcon /> Reset
                        </span>
                      }
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
        </div>
      )}
      {isMobile && (
        <ModalPopup
          show={showModal}
          size={"sm"} // "lg","sm" or "xl"
          centered={true} // vertically center the Dialog in the window
          modelHeader={"Invalid Selection"} // to show model header
          modelContent={
            <div className="footerButtons">
              <p>Please view in Desktop/Laptop</p>
              {/* <Button onClick={()=>setShowModal(false)}>OK</Button> */}
            </div>
          }
          backdrop={"static"}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
    getCampaignActivityListing,
    activateLoader,
    deActivateLoader,
    getSubordinateList,
    activateErrorMessageBar,
    activateSuccessMessageBar,
    setFilterBy,
    downloadBranchPartnerCategorywise,
    downloadBrocaReportMustdoActivity,
    downloadBrocaReportBusinessDevelopment,
    downloadBrocaReportBFDWiseActivity,
    downloadBrocaReportPartnerEngagementActivity,
    downloadBrocaReportLeadGenerationActivity,
    getFilterByLevel
  })(BrocaActivityListing)
);
