
import {ACTIVITY_RESCHEDULED, ACTIVITY_CANCELLED, ACTIVITY_COMPLETED, CONFIGS, ROLE_BDM, ALLOWED_FUTURE_DAYS} from "../../../../utils/config"; //CLAIM_TRAVEL_EXPENCE_OPTIONS,CUSTOM_FIELDSET
import { getDateAfterDays } from "../../../../utils/utils";
import { format } from 'date-fns';
import { ProductTrainingForm } from "../broca/formSetConfig"
import {isRegName,isMeetindDate, numberStartWith6789Validator, isPositiveNumber, isAlphanumeric1} from "../../../../utils/validators";

const acceptFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf", "application/bmp"];
const dateFormat = 'yyyy-MM-dd';
const claimtravelExpenseLabel = "Do You Want to Claim Travel Expense?";
const optionSelectValidationMessage = "Please select one option.";
const cssBorder = "1px solid #ddd";
const cssactiveBoxShadow = "0px 0px 1px 2px #fffc35";

const modeOfTransportLabel = "Mode of Transport";
const travelExpenseLabel = "Travel Expense";
const travelExpenseValidationmessage = "Travel expense is required.";
const fourDigitAllowed = "Maximum 4 digits allowed";
const billPhoto = "Upload bill photo";
const uploadBill = "Please Upload Bill Copy";
const vehicleType = "Vehicle Type";
const modeOftransportRequired = "mode of Trasport is required.";

export const customValidators = [
    {
        key: 'Name',
        validator: isRegName,
        message: 'Please enter first and last only.'
    },
    {
        key: 'MobileNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
    },
    {
        key: 'OtherDesignation',
        validator: isAlphanumeric1,
        message: 'Please enter alphanumeric only'
    },
    {
        key: 'MeetingDate',
        validator: isMeetindDate,
        message: "Meeting already exits."
    },
    {
        key: "ExpectedPremium",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium should be greater than 0", // message to show
    },
    
    {
        key: "PartnerCount",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium should be greater than 0", // message to show
    },
    {
        key: "CustomerCount",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium should be greater than 0", // message to show
    },
    {
        key: "StaffCount",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium should be greater than 0", // message to show
    },
    {
        key: "BMCount",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium should be greater than 0", // message to show
    },
    {
        key: "FieldCount",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium should be greater than 0", // message to show
    },
    {
        key: "TravelExpence",
        validator: isPositiveNumber, // function to be called
        message: "Travel Expence should be greater than 0", // message to show
    },
]
export const PartnerVisitForm = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12",
                            "key": "IsPartnerMet", "type": "select", "id": "IsPartnerMet", "label": "Partner Met",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Partner Met is required." }],
                            "options": [
                                { "key": "Yes", "label": "Yes", "value": "1" },
                                { "key": "No", "label": "No", "value": "0" },
                            ]
                        },
                        {
                            "colmd": "12", "key": "Name", "hide": false, "type": "text", "disabled": false, "id": "Name", "label": "Name",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Name is required." }]

                        },
                        {
                            "colmd": "12", "key": "OtherDesignation", "hide": false, "type": "text", "disabled": false, "id": "OtherDesignation", "label": "Designation",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Designation is required." },
                            { "name": "minLength", "value": 3, "validationMessage": "Min length is 3 characters" },
                            { "name": "maxLength", "value": 200, "validationMessage": "Max length 200 characters." }]

                        },
                        {
                            "colmd": "12", "key": "MobileNo", "hide": false, "type": "number", "disabled": false, "id": "MobileNo", "label": "MobileNo",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required." },
                                { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
                                { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]

                        },
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],

                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]

export const BirthdayCelebration = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "NoOfAttendees", "hide": false, "type": "number", "disabled": false, "id": "NoOfAttendees", "label": "No Of Attendees",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "No Of Attendees is required." },
                            { "name": "minLength", "value": 1, "validationMessage": "Min length for No Of Attendees is 1 digits" },
                            { "name": "maxLength", "value": 3, "validationMessage": "No Of Attendees field require 3 digits." }]
                        },
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", "key": "UploadPhoto", "hide": false, "type": "file", "dropTitle": "Upload Photo", "acceptFileTypes": acceptFileType,
                            "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": false, "disabled": false,
                            "id": "UploadPhoto", "label": "Group Photo Of Birthday Celebration", "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
                            "name": "UploadPhoto",
                            "validators": [
                                // {"name": "required", "value": true, "validationMessage": "Photo is required."}
                            ]
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }
]


export const ProductLaunch = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "NoOfAttendees", "hide": false, "type": "number", "disabled": false, "id": "NoOfAttendees", "label": "No Of Attendees",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "No Of Attendees is required." },
                            { "name": "minLength", "value": 1, "validationMessage": "Min length for No Of Attendees is 1 digits" },
                            { "name": "maxLength", "value": 3, "validationMessage": "No Of Attendees field require 3 digits." }]
                        },
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", "key": "UploadPhoto", "hide": false, "type": "file", "dropTitle": "Upload Photo", "acceptFileTypes": acceptFileType,
                            "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": false, "disabled": false,
                            "id": "UploadPhoto", "label": "Group Photo Of Product Launch", "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
                            "name": "UploadPhoto",
                            "validators": [
                                // { "name": "required", "value": true, "validationMessage": "Photo is required." }
                            ]
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }
]

export const BranchHeadMeet = [

    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "IsPartnerMet", "type": "select", "id": "IsPartnerMet", "label": "Partner Met",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Partner Met is required." }],
                            "options": [
                                { "key": "Yes", "label": "Yes", "value": "1" },
                                { "key": "No", "label": "No", "value": "0" },
                            ]
                        },
                        {
                            "colmd": "12", "key": "Name", "hide": false, "type": "text", "disabled": false, "id": "Name", "label": "Name",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Name is required." }]
                        },
                        {
                            "colmd": "12", "key": "OtherDesignation", "hide": false, "type": "text", "disabled": false, "id": "OtherDesignation", "label": "Designation",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Designation is required." }]
                        },
                        {
                            "colmd": "12", "key": "MobileNo", "hide": false, "type": "number", "disabled": false, "id": "MobileNo", "label": "MobileNo",
                            "validators": [
                                { "name": "required", "value": true, "validationMessage": "This field is required." },
                                { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
                                { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
                        },
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]

export const BranchFoundationDay = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "BFDActivityID", "label": "Activity Done", "type": "checkbox",
                            "options": [], "validators": [{ "name": "required", "value": true }]
                        },
                        {
                            "colmd": "12", "key": "OtherBFDActivity", "hide": true, "type": "text", "disabled": false, "id": "OtherBFDActivity", "label": "Provide Details",
                            "validators": [
                                // { "name": "required", "value": true, "validationMessage": "This field is required." },
                                { "name": "minLength", "value": 3, "validationMessage": "Min is 3 characters allowed" }
                                , { "name": "maxLength", "value": 200, "validationMessage": "Max 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", "key": "NoOfAttendees", "hide": false, "type": "text", "disabled": false, "id": "NoOfAttendees", "label": "No Of Attendees",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "No Of Attendees is required." },
                            { "name": "minLength", "value": 1, "validationMessage": "Min length for No Of Attendees is 1 digits" },
                            { "name": "maxLength", "value": 3, "validationMessage": "No Of Attendees field require 3 digits." }]
                        },
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Max 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", "key": "UploadPhoto", "hide": false, "type": "file", "dropTitle": "Upload Photo", "acceptFileTypes": acceptFileType,
                            "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": false, "disabled": false,
                            "id": "UploadPhoto", "label": "Group Photo of Branch Foundation Day", "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
                            "name": "UploadPhoto",
                            "validators": [{
                                "name": "required", "value": true, "validationMessage": "Photo is required."
                            }]
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]


export const JoinCallWithPartner = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "NoOfCallsDone", "hide": false, "type": "number", "disabled": false, "id": "NoOfCallsDone", "label": "No Of Calls Done",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "No Of Attendees is required." },
                            { "name": "minLength", "value": 1, "validationMessage": "Min length for No Of Attendees is 1 digits" },
                            { "name": "maxLength", "value": 3, "validationMessage": "No Of Attendees field require 3 digits." }],
                        },
                        {
                            "colmd": "12", "key": "NoOfAttendees", "hide": true, "type": "number", "disabled": false, "id": "NoOfAttendees", "label": "No Of Attendees",
                            "validators": [
                                // { "name": "required", "value": true, "validationMessage": "No Of Attendees is required." },
                                { "name": "minLength", "value": 1, "validationMessage": "Min length for No Of Attendees is 1 digits" },
                                { "name": "maxLength", "value": 3, "validationMessage": "No Of Attendees field require 3 digits." }]
                        },
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]


export const BusinessDevelopmentActivity = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "IsPartnerMet", "type": "select", "id": "IsPartnerMet", "label": "Partner Met",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Partner Met is required." }],
                            "options": [
                                { "key": "Yes", "label": "Yes", "value": "1" },
                                { "key": "No", "label": "No", "value": "0" },
                            ]
                        },
                        {
                            "colmd": "12", "key": "Name", "hide": false, "type": "text", "disabled": false, "id": "Name", "label": "Name Of The Person",
                            "validators": [
                                // { "name": "required", "value": true, "validationMessage": "Name is required." }
                            ]
                        },
                        {
                            "colmd": "12", "key": "DesignationId", "type": "select", "id": "DesignationId", "label": "Designation",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Designation is required." }],
                            "options": []
                        },
                        {
                            "colmd": "12", "key": "Other", "hide": true, "type": "text", "disabled": false, "id": "Other", "label": "Provide Details",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", "key": "PlanOfAction", "hide": false, "type": "text", "disabled": false, "id": "PlanOfAction", "label": "Plan Of Action/Remark",
                            "validators": [{ "name": "minLength", "value": 1, "validationMessage": "Min length for Plan Of Action is 1 digits" },
                            { "name": "maxLength", "value": 3, "validationMessage": "Plan Of Action field require 3 digits." }],
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]


export const LiveLeadGenration = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "NoOfLeadsContacted", "hide": false, "type": "number", "disabled": false, "id": "NoOfLeadContacted", "label": "No Of Lead Contacted",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "No Of Lead Contacted is required." },
                            { "name": "minLength", "value": 1, "validationMessage": "Min length for No Of Lead Contacted is 1 digits" },
                            { "name": "maxLength", "value": 3, "validationMessage": "No Of Lead Contacted field require 3 digits." }],
                        },
                        {
                            "colmd": "12", "key": "Remarks", "hide": false, "type": "text", "disabled": false, "id": "Remarks", "label": "Remarks",
                            "validators": [{ "name": "maxLength", "value": 100, "validationMessage": "Maximum 100 characters allowed" }],
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]



export const RescheduleActivity = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "Reason", "hide": false, "type": "text", "disabled": false, "id": "Reason", "label": "Reason",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Reason is required." },
                            { "name": "maxLength", "value": 1000, "validationMessage": "Maximum 1000 characters allowed" }],
                        },
                        {
                            "colmd": "12", "key": "MeetingStartDateTime", "hide": false, "type": "datetime-local", "disabled": false, "id": "MeetingStartDateTime",
                            "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
                            "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
                            "label": "Reschedule Meeting Date & Time", "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting start time is required." }]
                        },
                        {
                            "key": "MeetingEndDateTime",
                            "hide": false,
                            "type": "datetime-local", "disabled": false, "id": "MeetingEndDateTime",
                            "min": `${format(new Date(), dateFormat)}T${format(new Date(), 'kk:mm')}`,
                            "max": `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
                            "label": "Reschedule Meeting End Date & Time", "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting end time is required." }]
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]




export const CancelledActivity = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "key": "Reason", "hide": false, "type": "text", "disabled": false, "id": "Reason", "label": "Reason",
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Reason is required." },
                            { "name": "maxLength", "value": 1000, "validationMessage": "Maximum 1000 characters allowed" }],
                        },
                        {
                            "colmd": "12", key: "IsClaimRequired",
                            name: "IsClaimRequired",
                            type: "CustomSwitch",
                            disabled: false,
                            id: "IsClaimRequired",
                            label: claimtravelExpenseLabel,
                            validators: [
                                {
                                    name: "required",
                                    value: false,
                                    validationMessage: optionSelectValidationMessage,
                                },
                            ],
                            handleDiameter: 45,
                            offColor: "#08f",
                            onColor: "#0ff",
                            offHandleColor: "#0ff",
                            onHandleColor: "#08f",
                            className: "radioBoxDiv",
                            height: 26,
                            width: 75,
                            borderRadius: 24,
                            border: cssBorder,
                            activeBoxShadow: cssactiveBoxShadow,
                            uncheckedIcon: <div className="switchUnChecked">Yes</div>,
                            checkedIcon: <div className="switchChecked">No</div>,
                        },
                    ]
                ]
            }
        ]
    }

]

export const ActivityClaimFormPart1 = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [

                        {
                            "colmd": "12", "key": "TransportModeID",
                            "type": "radio",
                            "label": modeOfTransportLabel,
                            "validators": [{ "name": "required", "value": true, "validationMessage": "Mode of transport is required." }], "options": []
                        },
                    ]
                ]
            }
        ]
    }

]


export const ActivityClaimFormPart2 = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "TravelExpence",
                            "hide": false,
                            "type": "number",
                            "props": { "min": "0" },
                            "disabled": false,
                            "id": "TravelExpence",
                            "label": travelExpenseLabel,
                            "validators": [{ "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage },
                            { "name": "maxLength", "value": 4, "validationMessage": fourDigitAllowed }]
                        },

                        {
                            "colmd": "12", "key": "UploadBill",
                            "hide": false,
                            "type": "file",
                            "dropTitle": billPhoto,
                            "acceptFileTypes": acceptFileType,
                            "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg,image/png, application/pdf",
                            "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": true, "disabled": false, "id": "UploadBill", "label": uploadBill, "validators": []
                        }
                    ]
                ]
            }
        ]
    }
]

export const ActivityClaimFormPart3 = [
    {
        "fieldSetClassName": "custom-fieldset",
        "fieldSetLabelType": "string", // string/component
        "fieldSetLabel": "",
        "columns": [
            {
                "colmd": "12",
                "rows": [
                    [
                        {
                            "colmd": "12", "key": "VehicleTypeID",
                            "hide": false,
                            "type": "radio",
                            "disabled": false,
                            "id": "VehicleType",
                            "label": vehicleType,
                            "validators": [{ "name": "required", "value": true, "validationMessage": modeOftransportRequired }], "options": []
                        },
                    ]
                ]
            }
        ]
    }
]


export class ActivityFormBuilder {
    constructor(actionType, activityTypeForupdate) {
        this.actionType = actionType;
        this.activityTypeForupdate = activityTypeForupdate;
    }

    getFormTitle = () => {
        let formTitle = ''
        switch (this.actionType) {
            case ACTIVITY_RESCHEDULED:
                formTitle = 'Activity Rescheduled';
                break;
            case ACTIVITY_CANCELLED:
                formTitle = 'Activity Cancelled';
                break;
            case ACTIVITY_COMPLETED:
                formTitle = 'Activity Completed';
                break;
            default:
                formTitle = 'Activity Completed';
                break;
        }
        return formTitle;
    }

    getForm = () => {
        let form = []
        switch (this.actionType) {
            case ACTIVITY_RESCHEDULED:
                form = RescheduleActivity;
                break;
            case ACTIVITY_CANCELLED:
                form = CancelledActivity;
                break;
            case ACTIVITY_COMPLETED:
                switch (this.activityTypeForupdate) {
                    case 45:
                        form = PartnerVisitForm;
                        break;
                    case 46:
                        form = ProductTrainingForm;
                        break;
                    case 47:
                        form = BirthdayCelebration;
                        break;
                    case 48:
                        form = ProductLaunch;
                        break;
                    case 49:
                        form = BranchHeadMeet;
                        break;
                    case 50:
                        form = BranchFoundationDay;
                        break;
                    case 51:
                        form = JoinCallWithPartner;
                        break;
                    case 52:
                        form = BusinessDevelopmentActivity;
                        break;
                    case 53:
                        form = LiveLeadGenration;
                        break;
                }
                break;
            default:
                form = PartnerVisitForm;
                break;
        }
        return form;
    }
}
export const getOptionById = (optionValues) => {
    return optionValues.filter(item => item.value !== "")
}

export const initFormData = (formFields, oldData, masterDataState, actionType, ActivityID) => {
    let formData = [...formFields]
    // console.log(formData[0].columns[0].rows[0][2])
    const { BrocaTrainingAttendee, BrocaTopicsCovered, BrocaBFDActivity, BrocaDesignation } = masterDataState;
    switch (actionType) {
        case ACTIVITY_RESCHEDULED:
            break;
        case ACTIVITY_CANCELLED:
            // formData[2].options = CLAIM_TRAVEL_EXPENCE_OPTIONS
            break;
        case ACTIVITY_COMPLETED:
            switch (ActivityID) {
                case 46:
                    formData[0].columns[0].rows[0][2].options = BrocaTopicsCovered;
                    formData[1].columns[0].fieldsets[0].rows[0][0].options = getOptionById(BrocaTrainingAttendee);
                    break;
                case 50:
                    formData[0].columns[0].rows[0][0].options = getOptionById(BrocaBFDActivity);
                    break;
                case 51:
                    if (oldData.JointCallActivityID == 11){
                    formData[0].columns[0].rows[0][0].hide = true;
                    formData[0].columns[0].rows[0][1].hide = false;
                     }
                    break;
                case 52:
                    formData[0].columns[0].rows[0][2].options = BrocaDesignation;
                    break;
            }
            break;
        default:
            // formData[3].options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
            break;
    }
    return formData
}


export const removeSubArrayFromArray = (subArray, keyForSearch, arrOfObjects) => {
    let newArray = [...arrOfObjects]
    for (const object of subArray) {
        newArray = newArray.filter((item) => item[keyForSearch] !== object[keyForSearch])
    }
    return newArray
}
export const handleDropdownSelection = (event, key, formFields, masterDataState, actionType, role, ActivityID, chekin) => {
    let formData = [...formFields]
    switch (actionType) {
        case ACTIVITY_RESCHEDULED:
        case ACTIVITY_CANCELLED:
            switch (key) {
                case "IsClaimRequired":
                    if (event && chekin) {
                        formData = formData.concat(ActivityClaimFormPart1);
                        // formData[3].options = masterDataState['ModeofTransport']
                        formData[1].columns[0].rows[0][0].options = masterDataState['ModeofTransport']

                    } else {
                        formData.splice(1);
                    }
                    break;
                case "TransportModeID":

                    if (event.target.value === '1') {
                        formData.splice(2);
                        formData = formData.concat(ActivityClaimFormPart2);
                    } else if (event.target.value === '2') {
                        formData.splice(2);
                        let roleValidation = [ROLE_BDM, "RBH", "ZBH", "NH"];
                        if (!roleValidation.includes(role)) {
                            formData = formData.concat(ActivityClaimFormPart3);
                            formData[2].columns[0].rows[0][0].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
                        } else {
                            formData.splice(2);
                        }
                    }
                    else {
                        formData.splice(2);
                    }
            }
            break;
        case ACTIVITY_COMPLETED:
            switch (key) {
                case "IsClaimRequired":
                    switch (ActivityID) {

                        case 46:
                            if (event && chekin) {
                                formData = formData.concat(ActivityClaimFormPart1);
                                formData[3].columns[0].rows[0][0].options = masterDataState['ModeofTransport']
                            } else {
                                formData.splice(3);
                            }
                            break;
                        case 45:
                        case 47:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 52:
                        case 53:
                            if (event && chekin) {
                                formData = formData.concat(ActivityClaimFormPart1);
                                formData[1].columns[0].rows[0][0].options = masterDataState['ModeofTransport']

                            } else {
                                formData.splice(1);
                            }
                            break;
                    }
                    break;
                case "TransportModeID":
                    switch (ActivityID) {
                        case 46:
                            if (event.target.value === '1') {
                                formData.splice(4);
                                formData = formData.concat(ActivityClaimFormPart2);
                            } else if (event.target.value === '2') {
                                formData.splice(4);
                                let roleValidation = ["BDM", "RBH", "ZBH", "NH"];
                                if (!roleValidation.includes(role)) {
                                    formData = formData.concat(ActivityClaimFormPart3);
                                    formData[4].columns[0].rows[0][0].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
                                } else {
                                    formData.splice(4);
                                }
                            }
                            else {
                                formData.splice(4);
                            }
                            break;
                        case 45:
                        case 47:
                        case 48:
                        case 49:
                        case 50:
                        case 51:
                        case 52:
                        case 53:
                            if (event.target.value === '1') {
                                formData.splice(2);
                                formData = formData.concat(ActivityClaimFormPart2);
                            } else if (event.target.value === '2') {
                                formData.splice(2);
                                let roleValidation = ["BDM", "RBH", "ZBH", "NH"];
                                if (!roleValidation.includes(role)) {
                                    formData = formData.concat(ActivityClaimFormPart3);
                                    formData[2].columns[0].rows[0][0].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
                                } else {
                                    formData.splice(2);
                                }
                            }
                            else {
                                formData.splice(2);
                            }
                            break;
                    }
            }
            break;
    }
    return formData
}