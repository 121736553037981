import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { getUserHome, isAuthenticated, isAuthorized } from '../auth';
import useOnlineStatus from "../hooks/useOnlineStatus";

function getDeviceType() {
    const width = window.innerWidth;
    if (width < 768) {
        return 'mobile';
    } else if (width >= 768 && width < 992) {
        return 'tablet';
    } else {
        return 'desktop';
    }
}

function getBrowserName() {
    const userAgent = navigator.userAgent;
    // Check for Chrome first, as Chrome's user agent contains 'Safari'
    if (/chrome|crios|crmo/i.test(userAgent)) {
        return 'Chrome';
    } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo/i.test(userAgent)) {
        return 'Safari';
    } else if (/firefox|fxios/i.test(userAgent)) {
        return 'Firefox';
    } else if (/msie|trident/i.test(userAgent)) {
        return 'Internet Explorer';
    } else if (/edg/i.test(userAgent)) {
        return 'Edge';
    } else if (/opr\//i.test(userAgent)) {
        return 'Opera';
    } else {
        return 'Unknown';
    }
}

export const PublicRoute = ({ component: Component, restricted, path, ...rest }) => {
    const isOnline = useOnlineStatus();

    return (
        <Route {...rest} render={props => {
            if (isAuthenticated() && path === '/auth') {
                // already logged in so redirect to home page
                return <Redirect to={{ pathname: getUserHome() }} />
            }
            const deviceType = getDeviceType();
            const browserName = getBrowserName();
            if ((deviceType === 'mobile' || deviceType === 'tablet') && browserName !== 'Chrome') {
                return <Redirect to="/auth/browser-not-supported" />;
            }else if(path === "/auth/browser-not-supported"){
                return <Redirect to={{ pathname: '/' }} />
            }
            return <Component {...props} />
        }} />
    );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            if (!isAuthenticated()) {
                // not logged in so redirect to login page
                return <Redirect to={{ pathname: '/' }} />
            }
            const deviceType = getDeviceType();
            const browserName = getBrowserName();
            console.log("private", deviceType, browserName);
            if ((deviceType === 'mobile' || deviceType === 'tablet') && browserName !== 'Chrome') {
                return <Redirect to="/app/browser-not-supported" />;
            }
            return <Component {...props} />
        }} />
    );
};

export const AuthorizedRoute = ({ component: Component, permission, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            if (!isAuthorized(permission)) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: getUserHome() }} />
            }
            const deviceType = getDeviceType();
            const browserName = getBrowserName();
            console.log("authorized", deviceType, browserName);
            if ((deviceType === 'mobile' || deviceType === 'tablet') && browserName !== 'Chrome') {
                return <Redirect to="/app/browser-not-supported" />;
            }
            return <Component {...props} />
        }} />
    );
};
