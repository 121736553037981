import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TravelHistory from '../common/travelHistory';
import { useState } from 'react';
import { getUploadedImage } from '../../../redux-reducer/reducer';

const DroppedStatusHistory = (props) => {
    const { history, styletimeline } = props
    const [fileData, setfileData] = useState(null)
    const [loader, setLoader] = useState(true)
    const remarks = history.Remarks ? history.Remarks : 'N/A';
    const ReasonName = ["Not Interested - NI","Product not Suitable","Loan Rejected","Not eligible"]
    const getBase64Data = async() => {
        if (history.UploadBillFileName) {
          setLoader(false)
          const res = await getUploadedImage(history.UploadBillFileName);
          if (res.status === 200 && res.data) {
            setfileData(`${"data:image/png;base64,"}${res.data}`);
            var image = new Image();
            image.src = "data:image/jpg;base64," + res.data;
            var w = window.open("");
            w.document.write(image.outerHTML);
            setLoader(true)
          } else {
            setLoader(true)
          }
          setLoader(true)
        }
        setLoader(true)
      };
    return <>
        {history.LeadStatus === "Dropped" ?
            <>
                {(history.ChannelID === 13 || history.ChannelID === 79) ? (
              <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason For Dropping</label>
                  <label>{history.ReasonForDropping}</label>
                </TimelineContent>
              </TimelineItem>
            ) : (
              <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason </label>
                  <label>{history.ReasonName}</label>
                </TimelineContent>
              </TimelineItem>
            )}
              {!ReasonName.includes(history.ReasonForDropping) && !history.ChannelID === 21 && <TimelineItem sx={styletimeline}>
              <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                  <label>Other Reason </label>
                  <label>{history.OtherReason}</label>
              </TimelineContent>
          </TimelineItem>}
                <TimelineItem sx={styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Remarks </label>
                        <label>{remarks ? remarks : 'N/A'}</label>
                    </TimelineContent>
                </TimelineItem>
                {history.IsClaimRequired &&
                    <TravelHistory history={history} styletimeline={styletimeline} />
                }
                {history.UploadBill &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            {loader == true ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
                                {
                                fileData &&
                                <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
            </>
            : ''}
    </>
}

export default DroppedStatusHistory
