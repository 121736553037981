import { ACTIVE_BOX_SHADOW, APPLICATION_PDF, BORDER_ONE_PIXEL_SOLID_GREY, IMAGE_JPEG, MODE_OF_TRANSPORT, PLEASE_UPLOAD_BILL_COPY, TRAVEL_EXPENSE, UPLOAD_BILL_PHOTO, VEHICLE_TYPE } from "../../../../utils/config";

  const max100Charmessage = "Maximum 100 charactors allowed.";
  export const CancelActivityFormBorca = [
    {
        key: "Reason",
        hide: false,
        type: "text",
        disabled: false,
        id: "Reason",
        label: "Reason",
        validators: [
          {
            name: "required",
            value: true,
            validationMessage: "Please enter remarks",
          },
          { name: "maxLength", value: 100, validationMessage: max100Charmessage },
        ],
      },
    {
      key: "IsClaimRequired",
      name: "IsClaimRequired",
      type: "CustomSwitch",
      id: "IsClaimRequired",
      label: "Do You Want to Claim Travel Expense",
      handleDiameter: 45,
      offColor: "#00427E",
      onColor: "#FFFFFF",
      offHandleColor: "#FFFFFF",
      onHandleColor: "#00427E",
      className: "radioBoxDiv",
      height: 26,
      width: 75,
      borderRadius: 24,
      border: BORDER_ONE_PIXEL_SOLID_GREY,
      activeBoxShadow: ACTIVE_BOX_SHADOW,
      uncheckedIcon: <div className="switchUnChecked">Yes</div>,
      checkedIcon: <div className="switchChecked">No</div>,
    },
    {
      key: "TransportModeID",
      hide: true,
      type: "radio",
      disabled: false,
      label: MODE_OF_TRANSPORT,
      validators: [],
      options: [],
    },
    {
      key: "VehicleTypeID",
      hide: true,
      type: "radio",
      disabled: false,
      id: "VehicleTypeID",
      label: VEHICLE_TYPE,
      validators: [],
      options: [],
    },
    {
      key: "TravelExpence",
      hide: true,
      type: "number",
      props: { min: "1" },
      disabled: false,
      id: "RescheduleTravelExpense",
      label: TRAVEL_EXPENSE,
      validators: [],
    },
    {
      key: "UploadBill",
      hide: true,
      type: "file",
      dropTitle: UPLOAD_BILL_PHOTO,
      acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
      componentType: "DRAG_DROP_BROWSE",
      layout: "1column",
      restrictMultipleFiles: true,
      disabled: false,
      id: "UploadBill",
      label: PLEASE_UPLOAD_BILL_COPY,
      validators: [],
    },
  ];

  // export const meetingScheduledForm = [
  //   {
  //     key: "MeetingStartDateTime",
  //     hide: false,
  //     type: DATETIME_LOCAL,
  //     min: restrictPastDateTime,
  //     disabled: false,
  //     id: "MeetingStartDateTime",
  //     label: "Meeting Start Date & Time",
  //     validators: [
  //       { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
  //     ],
  //   },
  //   {
  //     key: "MeetingEndDateTime",
  //     hide: false,
  //     type: DATETIME_LOCAL,
  //     min: restrictPastDateTime,
  //     max: endMaxDate,
  //     disabled: false,
  //     id: "MeetingEndDateTime",
  //     label: "Meeting End Date & Time",
  //     validators: [
  //       { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
  //     ],
  //   },
  //   {
  //     key: "GCLCallDurationId",
  //     hide: false,
  //     type: "select",
  //     disabled: false,
  //     id: "GCLCallDurationId",
  //     label: "Duration",
  //     validators: [
  //       { name: "required", value: true, validationMessage: "Please Select the Duration" },
  //     ],
  //     options: [],
  //   },
  //   {
  //     key: "Remarks",
  //     hide: false,
  //     type: "textarea",
  //     disabled: false,
  //     id: "Remarks",
  //     label: "Remarks",
  //     validators: remarkValidation
  //   },
  //   {
  //     key: "IsClaimRequired",
  //     name: "IsClaimRequired",
  //     type: "CustomSwitch",
  //     id: "IsClaimRequired",
  //     label: "Do You Want to Claim Travel Expense",
  //     handleDiameter: 45,
  //     offColor: "#00427E",
  //     onColor: "#FFFFFF",
  //     offHandleColor: "#FFFFFF",
  //     onHandleColor: "#00427E",
  //     className: "radioBoxDiv",
  //     height: 26,
  //     width: 75,
  //     borderRadius: 24,
  //     border: BORDER_ONE_PIXEL_SOLID_GREY,
  //     activeBoxShadow: ACTIVE_BOX_SHADOW,
  //     uncheckedIcon: <div className="switchUnChecked">Yes</div>,
  //     checkedIcon: <div className="switchChecked">No</div>,
  //   },
  //   {
  //     key: "TransportModeID",
  //     hide: true,
  //     type: "radio",
  //     disabled: false,
  //     label: MODE_OF_TRANSPORT,
  //     validators: [],
  //     options: [],
  //   },
  //   {
  //     key: "VehicleTypeID",
  //     hide: true,
  //     type: "radio",
  //     disabled: false,
  //     id: "VehicleTypeID",
  //     label: VEHICLE_TYPE,
  //     validators: [],
  //     options: [],
  //   },
  //   {
  //     key: "TravelExpense",
  //     hide: true,
  //     type: "number",
  //     props: { min: "1" },
  //     disabled: false,
  //     id: "RescheduleTravelExpense",
  //     label: TRAVEL_EXPENSE,
  //     validators: [],
  //   },
  //   {
  //     key: "UploadBill",
  //     hide: true,
  //     type: "file",
  //     dropTitle: UPLOAD_BILL_PHOTO,
  //     acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
  //     componentType: "DRAG_DROP_BROWSE",
  //     layout: "1column",
  //     restrictMultipleFiles: true,
  //     disabled: false,
  //     id: "UploadBill",
  //     label: PLEASE_UPLOAD_BILL_COPY,
  //     validators: [],
  //   },
  // ];
  export const CompletedActivityFormPartnerVisit = [
    {
      key: "IsPartnerMet",
      type: "select",
      id: "IsPartnerMet",
      label: "Partner Met",
      validators: [
        {
          name: "required",
          value: true,
          validationMessage: "Please select an option ",
        },
      ],

      options: [
        { key: "select", label: "select", value: "select" },
        {
          key: "Yes",
          label: "Yes",
          value: 1,
        },
        { key: "No", label: "No", value: 0 },
      ],
    },
    {
        key: "PartnerName",
        type: "select",
        id: "PartnerName",
        label: "Partner Name",
        hide:true,
        validators: [],
        options: [],
      },
      {
        key: "MobileNo",
        type: "text",
        id: "MobileNo",
        label: "Partner Mobile No.",
        hide:true,
        validators: [],
        options:[],
        disabled : true,
      },

    {
        key: "Remarks",
        hide: false,
        type: "text",
        disabled: false,
        id: "Remarks",
        label: "Remarks",
        validators: [
          { name: "maxLength", value: 100, validationMessage: max100Charmessage },
        ],
      },
      {
        key: "IsClaimRequired",
        name: "IsClaimRequired",
        type: "CustomSwitch",
        id: "IsClaimRequired",
        label: "Do You Want to Claim Travel Expense",
        handleDiameter: 45,
        offColor: "#00427E",
        onColor: "#FFFFFF",
        offHandleColor: "#FFFFFF",
        onHandleColor: "#00427E",
        className: "radioBoxDiv",
        height: 26,
        width: 75,
        borderRadius: 24,
        border: BORDER_ONE_PIXEL_SOLID_GREY,
        activeBoxShadow: ACTIVE_BOX_SHADOW,
        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
        checkedIcon: <div className="switchChecked">No</div>,
      },
      {
        key: "TransportModeID",
        hide: true,
        type: "radio",
        disabled: false,
        label: MODE_OF_TRANSPORT,
        validators: [],
        options: [],
      },
      {
        key: "VehicleTypeID",
        hide: true,
        type: "radio",
        id: "VehicleTypeID",
        label: VEHICLE_TYPE,
        validators: [],
        options: [],
      },
      {
        key: "TravelExpense",
        hide: true,
        type: "number",
        props: { min: "1" },
        id: "TravelExpense",
        label: TRAVEL_EXPENSE,
        validators: []
      },
      {
        key: "UploadBill",
        hide: true,
        type: "file",
        dropTitle: UPLOAD_BILL_PHOTO,
        acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
        componentType: "DRAG_DROP_BROWSE",
        layout: "1column",
        restrictMultipleFiles: true,
        disabled: false,
        id: "UploadBill",
        label: PLEASE_UPLOAD_BILL_COPY,
        validators: [],
      },
  ];
