/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-17 11:26:33
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */
import { addSingleLead, getAllOfTable, addAllLeads, removeAllLeads, addLastSyncTime, getLastSyncByKey, getSingleLead, updateLead, deleteDocFromCollection, addAllHistoricalLeads, leadHistory, removeAllActivities } from './localbase'

export const saveLeadOffline = (leadObject) => {
    return addSingleLead(leadObject)
}
export const updateLeadOffline = (leadObject) => {
    const key = leadObject.LeadId ? leadObject.LeadId.toString() : leadObject.LmsleadGuid
    return updateLead(key, leadObject)
}
export const getLeadOffline = (id) => {
    return getSingleLead(id).then((res) => {
        if(res) {
            return res
        } else {
            return null
        }
    }).catch(()=> {return null})
}
export const getAllLeadOffline = () => {
    return getAllOfTable('Leads')
}
export const isLeadValid = (mobileNumber) => {
    return getAllOfTable('Leads').then((leads) => {
        if (leads.length > 0) {
            const isFound = leads.filter((lead) => lead.MobileNo === mobileNumber)
            if (isFound.length > 0) {
                return false
            }
            return true
        }
        return true
    })
}
export const addLeadInDb = (leadList) => {
    return addAllLeads(leadList)
}
const removeAllGivenLeads = (leadList) => {
    return removeAllLeads(leadList)
}
const removeAllGivenActivity = (activityList) => {
    return removeAllActivities(activityList)
}
export const deleteLeads = (leadList) => {
    return removeAllGivenLeads(leadList);
}
export const deleteActivities = (activityList) => {
    return removeAllGivenActivity(activityList);
}
export const addHistoricalLeadsInDb = (list) => {
    return addAllHistoricalLeads(list)
}

export const addUpdateLastLeadSyncTime = (syncTimeMili) => {
    const syncTime = {
        syncTime: syncTimeMili
    }
    return addLastSyncTime(syncTime, 'leadSyncTime')
}
export const getLeadSyncTime = () => {
    return getLastSyncByKey('leadSyncTime').then((res) => {
        if(res) {
            return res
        } else {
            return null
        }
    }).catch(()=> {return null})
}
export const addUpdateLastMasterSyncTime = (syncTimeMili, version) => {
    const syncTime = {
        syncTime: syncTimeMili,
        versionNumber: version
    }
    return addLastSyncTime(syncTime, 'masterDataSyncTime')
}
export const getMasterSyncTime = () => {
    return getLastSyncByKey('masterDataSyncTime').then((res) => {
        if(res) {
            return res
        } else {
            return null
        }
    }).catch(()=> {return null})
}
export const getNameForId = (collectionName, id) => {
    return getAllOfTable(collectionName).then((res) => {
        if (res[0][collectionName]) {
            const name = res[0][collectionName].filter((el) => el.key === id || el.value === id )
            if (name) {
                return name[0].label
            }
            return null
        }
        return null
    })
    .catch(()=> {return null})
}
export const getBranchList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('BranchDetails').then((res) => {
            resolve(res[0].branchDetails)
        }).catch((err) => reject(false))
    })
}
export const getLeadSourceNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('LeadSource').then((res) => {
            resolve(res[0].LeadSource)
        }).catch((err) => reject(false))
    })
}
export const getLeadTypeNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('LeadType').then((res) => {
            resolve(res[0].LeadType)
        }).catch((err) => reject(false))
    })
}
export const getSPTypeNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('SPType').then((res) => {
            resolve(res[0].SPType)
        }).catch((err) => reject(false))
    })
}
export const getSalutionNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('Salutation').then((res) => {
            resolve(res[0].Salutation)
        }).catch((err) => reject(false))
    })
}
export const getLgSpList = (branchCode) => {
    return new Promise((resolve, reject) => {
        getAllOfTable('LgSpList').then((res) => {
            // this changes has been done for the absent of lgsp data
            if (res && res[0].LgSpList) {
                const allLgdetails = res[0].LgSpList.Lgdetails;
                const allSpdetails = res[0].LgSpList.Spdetails;
                const allSpWithoutBranchMapping = res[0].LgSpList.SpdetailList;
                if (allSpdetails.length > 0 || allLgdetails.length > 0) {
                    const matchedLg = allLgdetails.filter((lg) =>  lg.BranchCode === branchCode.toString());
                    const matchedSp = allSpdetails.filter((sp) =>  sp.BranchCode === branchCode.toString());
                    resolve({
                        lgList: matchedLg,
                        spList: matchedSp,
                        allSPWithoutFilter : allSpWithoutBranchMapping,
                    })
                }else{
                    resolve({
                        lgList: allLgdetails,
                        spList: allSpdetails,
                        allSPWithoutFilter : allSpWithoutBranchMapping,
                    })
                }
            }
        }).catch((err) => reject(false));
    });
}
export const getSRMList = (branchCode)=> {
    return new Promise((resolve, reject) => {
        getAllOfTable('SRMList').then((res) => {
            if (res && res[0].SRMList) {
                const SRMlist = res[0].SRMList;
                if (SRMlist.length > 0) {
                    const matchedSRM = SRMlist.filter((srm) =>  srm.BranchCode === branchCode.toString());
                    resolve({
                        SRMlist: matchedSRM,
                    })
                }
            }

        }).catch((err) => reject(false));
    });
}
export const getAllLgSp = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('LgSpList').then((res) => {
            if (res && res[0].LgSpList) {
                const allLgdetails = res[0].LgSpList.Lgdetails;
                const allSpdetails = res[0].LgSpList.Spdetails;
                resolve({
                    lgList: allLgdetails,
                    spList: allSpdetails,
                })
            }
        }).catch((err) => reject(false));
    });
}
export const getFilterLeads = (leadList, filterReq) => {
    let mLeadList = leadList;
    if (filterReq.ActivityID && filterReq.BranchID && filterReq.Createddate && filterReq.LeadSourceID && filterReq.LeadStatusId &&
        filterReq.Leadname && filterReq.LeadtypeID && filterReq.MeetingDate && filterReq.MeetingStartdate && filterReq.MobileNo &&
        filterReq.Modifieddate && filterReq.ProductID && filterReq.SPTypeID) {
        return mLeadList.filter((lead) =>
            lead.ActivityID === filterReq.ActivityID &&
            lead.BranchID === filterReq.BranchID &&
            lead.CreatedBy === filterReq.CreatedBy &&
            lead.Createddate === filterReq.Createddate &&
            lead.LeadSourceID === filterReq.LeadSourceID &&
            lead.LeadStatusId === filterReq.LeadStatusId &&
            lead.firstName.toLowerCase() === filterReq.Leadname.toLowerCase() &&
            lead.LeadtypeID === filterReq.LeadtypeID &&
            lead.MeetingDate === filterReq.MeetingDate &&
            lead.MeetingEnddate === filterReq.MeetingEnddate &&
            lead.MeetingStartdate === filterReq.MeetingStartdate &&
            lead.MobileNo === filterReq.MobileNo &&
            lead.Modifieddate === filterReq.Modifieddate &&
            lead.ProductID === filterReq.ProductID &&
            lead.SPTypeID === filterReq.SPTypeID
        );
    }
    if (filterReq.BranchID) {
        mLeadList = mLeadList.filter((lead) =>
            lead.BranchID === filterReq.BranchID &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.LeadSourceID) {
        mLeadList = mLeadList.filter((lead) =>
            lead.LeadSourceID === filterReq.LeadSourceID &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.LeadStatusId) {
        mLeadList = mLeadList.filter((lead) =>
            lead.LeadStatusId === filterReq.LeadStatusId &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.Leadname) {
        mLeadList = mLeadList.filter((lead) =>
            lead.FirstName.toLowerCase() === filterReq.Leadname.toLowerCase() &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.LeadtypeID) {
        mLeadList = mLeadList.filter((lead) =>
            lead.LeadtypeID === filterReq.LeadtypeID &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.MobileNo) {
        mLeadList = mLeadList.filter((lead) =>
            lead.MobileNo === filterReq.MobileNo &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.ProductID) {
        mLeadList = mLeadList.filter((lead) =>
            lead.ProductID === filterReq.ProductID &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.SPTypeID) {
        mLeadList = mLeadList.filter((lead) =>
            lead.SPTypeID === filterReq.SPTypeID &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.ActivityID) {
        mLeadList = mLeadList.filter((lead) =>
            lead.ActivityID === filterReq.ActivityID &&
            lead.CreatedBy === filterReq.CreatedBy
        );
    }
    return mLeadList;
}
export const resetMasterDataSyncTime = () => {
    return deleteDocFromCollection('LastSyncTimes', 'masterDataSyncTime');
}
export const getLeadOfflineHistory = (leadId) => {
    return leadHistory(leadId)
}

