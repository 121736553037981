import { createSlice } from '@reduxjs/toolkit'
import { LEAD_SP } from '../../../src/utils/config';


const leadFormSlice = createSlice({
  name: "leadSelectFormData",
  initialState: {
    loading: false,
    error: null,
    productDetails: [],
    salutations: [],
    SPType: [],
    leadType: [],
    branch_value: [],
    lg_value: [],
    sp_value: [],
    Activities: { ActivityID: [] },
    mobileNumberStatus: "",
    getLeadData: [],
    leadStatus: [],
    BDMList: [],
    secondLevelSubordinateList: [],
    thirdLevelSubordinateList: [],
    fourthLevelSubordinateList: [],
    getPersistancyLeadData: [],
    getCampaginLeadData: [],
    getCampaignNames: [],
    CampaignActivities: { ActivityID: [] },
    ABHListForZBH: [],
    TeamList:[],
    secondLevelTeamList:[],
    thirdLevelTeamList:[],
    fourthLevelTeamList:[],
    
  },
  reducers: {
    getBranchValue: (state, action) => {
      state.branch_value = action.payload;
    },
    getLGValue: (state, action) => {
      state.lg_value = action.payload['Lgdetails'];
      state.sp_value = action.payload['Spdetails']
    },
    getSelectSP: (state, action) => {
      state.sp_value = LEAD_SP;
    },
    loadingBegins: (state, action) => {
      state.loading = true;
    },
    LoadingEnd: (state, action) => {
      state.loading = false;
    },
    logErrors: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    getProducts: (state, action) => {
      state.loading = false;
      state.productDetails = action.payload
    },
    getSalutations: (state, action) => {
      state.loading = false;
      state.salutations = action.payload
    },
    getSPType: (state, action) => {
      state.loading = false;
      state.SPType = action.payload
    },

    getLeadType: (state, action) => {
      state.loading = false;
      state.leadType = action.payload
    },
    getActivities: (state, action) => {
      state.loading = false;
      state.Activities = action.payload
    },
    getCampaignActivities: (state, action) => {
      state.loading = false;
      state.CampaignActivities = action.payload
    },
    getLeadSource: (state, action) => {
      state.loading = false;
      state.leadSource = action.payload
    },
    getZMA: (state, action) => {
      state.loading = false;
      state.zmaList = action.payload
    },
    getLeadByIdData: (state, action) => {
      state.loading = false;
      state.getLeadData = action.payload
    },
    getMobileNumberStatus: (state, action) => {
      state.loading = false;
      state.mobileNumberStatus = action.payload
    },
    getLeadStatus: (state, action) => {
      state.leadStatus = action.payload;
    },
    getMeetingStatus: (state, action) => {
      state.loading = false;
      state.meetingStatus = action.payload
    },
    bdmListForABH: (state, action) => {
      state.loading = false;
      state.BDMList = action.payload
    },
    secondLevelSubordinateListForAll: (state, action) => {
      state.loading = false;
      state.secondLevelSubordinateList = action.payload
    },
    secondLevelTeamListForAll: (state, action) => {
      state.loading = false;
      state.secondLevelTeamList = action.payload
    },
    thirdLevelSubordinateListForAll: (state, action) => {
      state.loading = false;
      state.thirdLevelSubordinateList = action.payload
    },
    thirdLevelTeamListForAll: (state, action) => {
      state.loading = false;
      state.thirdLevelTeamList = action.payload
    },
    fourthLevelSubordinateListForAll: (state, action) => {
      state.loading = false;
      state.fourthLevelSubordinateList = action.payload
    },
    fourthLevelTeamListForAll: (state, action) => {
      state.loading = false;
      state.fourthLevelTeamList = action.payload
    },
    getPersistancyLeadByIdData: (state, action) => {
      state.loading = false;
      state.getPersistancyLeadData = action.payload
    },
    getCampaginLeadByIdData: (state, action) => {
      state.loading = false;
      state.getCampaginLeadData = action.payload
    },
    getCampaginNamesData: (state, action) => {
      state.loading = false;
      state.getCampaignNames = action.payload
    },
    ABHListForZBH: (state, action) => {
      state.loading = false;
      state.ABHListForZBH = action.payload
    },
    TeamList: (state, action) => {
      state.loading = false;
      state.TeamList = action.payload
    },
  }
});


export const {
  getBranchValue,
  getLGValue, getSelectSP, getProducts, getSalutations,
  getSPType, getActivities, getCampaignActivities, loadingBegins,
  LoadingEnd, getLeadType, getLeadSource,
  getZMA, getLeadByIdData, getMobileNumberStatus, getLeadStatus, getMeetingStatus,
  getPersistancyLeadByIdData, bdmListForABH, secondLevelSubordinateListForAll,secondLevelTeamListForAll, thirdLevelSubordinateListForAll,thirdLevelTeamListForAll,
  getCampaginLeadByIdData, getCampaginNamesData, ABHListForZBH, fourthLevelSubordinateListForAll,fourthLevelTeamListForAll,TeamList } = leadFormSlice.actions

export default leadFormSlice.reducer;
