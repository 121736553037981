import React from "react";
import Footer from '../../../../layouts/footer/footer';
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from 'react-i18next';
import {
  ActivityCancelledFormPart1, ActivityCancelledFormPart2,
  ActivityCancelledFormPart3, ActivityFormBuilder, handleDropdownSelection, initFormData
} from "./updateCSCActivityForm";
import {
  ACTIVITY_RESCHEDULED, ACTIVITY_COMPLETED,
  ACTIVITY_CANCELLED, CONFIGS, FOUR_WHEELER, PUBLIC_TRANSPORT, SELF_OWNED_TRANSPORT, TWO_WHEELER, ACTIVITY_UPDATE_CALLS
} from '../../../../utils/config';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import moment from "moment";
import {
  setActivityData, updateDynamicData,
  getMasterData, getActivityById, uploadFileToBlob,
  activateSuccessMessageBar, activateErrorMessageBar, activateLoader,
  deActivateLoader, getActivityStatusMasterData, setBreadcrumbs, getUpdatedStatusCallCountforCSC,getUpdatedStatusCallCountforCampaignCSC,
} from '../../../../redux/actions';
import { connect } from 'react-redux';
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import './updateCSCActivity.scss'
import { validateIsCheckin } from "../../../../redux-reducer/reducer";
import { removeSubArrayFromArray } from "./createActivityForm";
import { checkDateIsValid, isPositiveNumber, meetingDateValidations } from "../../../../utils/validators"
import { updateActivityOffline } from "../../../../offlineManager/activityOfflineManager";
import { addMinuteToDate, minusMinuteFromDate } from "../../../../utils/utils";
import { getActivityDetails ,getCampaignActivityDetails} from "../../../../redux/actions/activityDetailsAction";

const TDateFormat = 'YYYY-MM-DDTHH:mm'
class CSCUpdateActivity extends React.Component {
  constructor(props) {
    super(props);
    this.isCampaign = props.activityData.getSelectActivityData.CampaignActivityid !== undefined
    this.dyFormRef = React.createRef();
    this.formBuilder = new ActivityFormBuilder(props.activityData.activityComplete ? ACTIVITY_COMPLETED :
      props.activityData.activityUpdateCalls ? ACTIVITY_UPDATE_CALLS :
        props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED :
          ACTIVITY_CANCELLED, props.activityData.getSelectActivityData.ActivityIdName)
    this.state = {
      current: {
        id: 1,
        MeetingStartDateTime: props.activityData.getSelectActivityData.MeetingStartDateTime,
        MeetingEndDateTime: addMinuteToDate(props.activityData.getSelectActivityData.MeetingEndDateTime),
        IsClaimRequired: false
      },
      extErrors: {},
      activityStatus: {},
      selectedActivityOldData: {},
      formData: this.formBuilder.getForm(),
      uploadedFile: '',
      uploadedFilePhoto1: "",
      uploadedFileDocument1: "",
      callCount: 0, uploadedFileCallingToVLEUploadPhoto: "",
      UploadPhoto:'',
      activityStatusCheck:{}
    }
  }
  isMaxNumber = (key, value) => {
    if (!value) {
      return true;
    }
     return value == this.props.activityData.statusCallCOunt

  }

  phoneNumerVal = (key, value) => {
    const val = value?.charAt(0)
    if (!value) {
      return true;
    }
    return val >= 6
  }

  customValidators = [
    {
      key: "NumberOfCallsDone",
      // validator: this.isMaxNumber, 
      validator: this.isMaxNumber, // function to be called
      message: `No of calls done must be equal to updated calls ${this.props.activityData.statusCallCOunt}`, // message to show
    },
    {
      key: "VLERAPDMMobile",
      validator: this.phoneNumerVal, // function to be called
      message: `10 digit phone number starting with 6,7,8,9 only`, // message to show
    },
    {
      key: "PremiumAmount",
      validator: isPositiveNumber, // function to be called
      message: "Premium Amount should be greater than 0", // message to show
    },
    {
      key: "TicketSize",
      validator: isPositiveNumber, // function to be called
      message: "Ticket size should be greater than 0", // message to show
    },
    {
      key: "TravelExpence",
      validator: isPositiveNumber, // function to be called
      message: "Travel expense should be greater than 0", // message to show
    },
    
  ]

  onSubmitHandler = async (model) => {
    if (this.state.activityStatusCheck.ActivityStatusId == 10 || this.state.activityStatusCheck.ActivityStatusId == 12 ){
      this.props.activateErrorMessageBar("Activity already completed or cancelled")
      return false;
    }
    if (
      Object.keys(model.errors).length > 0 ||
      Object.keys(this.state.extErrors).length > 0
    ) {
      this.props.activateErrorMessageBar("Activity is not updated.");
      return false;
    }
    this.props.activateLoader();

    if (model.MeetingStartDateTime && model.MeetingEndDateTime) {
      model.MeetingStartDateTime = model.MeetingStartDateTime.replace('T', ' ').replace('Z', '')
      model.MeetingEndDateTime = minusMinuteFromDate(model.MeetingEndDateTime).replace('T', ' ').replace('Z', '')
    }
    if (model.MeetingEndDateTime === "Invalid date") {
      model.MeetingEndDateTime = null;
      model.MeetingStartDateTime = null;
    }
    const oldData = this.state.selectedActivityOldData;
    // oldData["Remarks"] = null;
    oldData.ActivityStatusId = this.props.activityData.activityComplete ? 10 : this.props.activityData.activityReshedule ? 11 : this.props.activityData.activityUpdateCalls ? 16 : 12
    oldData.ActivityStatus = this.props.activityData.activityComplete ? "Activity Completed" :
    this.props.activityData.activityReshedule ? "Activity Rescheduled" :
    this.props.activityData.activityUpdateCalls ? "Activity Update Calls" : "Activity Cancelled"
    oldData.ModifiedDate = (moment(new Date()).format(TDateFormat)).replace('T', ' ').replace('Z', '')
    const formData = { ...oldData, ...model };
    delete formData['advanceLayoutFields'];
    delete formData['collapseStates'];
    delete formData['errors'];
    delete formData['isValidForm'];
    delete formData['id'];
    formData['IsCheckin'] = false
    formData.IsBacklog = 0;

    if (model.IsClaimRequired) {
      formData['TransportModeID'] = parseInt(model.TransportModeID);
      formData['TransportMode'] = model.TransportModeID === "1" ? "Public Transport" : "Self Owned Transport";

    } else {
      formData['IsClaimRequired'] = false;
      formData['TransportMode'] = null;
      formData['TransportModeID'] = null;
      formData['TravelExpence'] = null;
      formData['VehicleTypeID'] = null;

    }

    formData["NumberOfProposals"] = model.NoOfProposals ? model.NoOfProposals : null;
    formData["CreatedBy"] = parseInt(formData["CreatedBy"]);
    formData["ModifiedBy"] = parseInt(this.props.userState.profileInfo.userId);

    if (formData.hasOwnProperty('UploadBill')) {
      formData["UploadBill"] = this.state.uploadedFile;
    }
    if (formData.hasOwnProperty('UploadPhoto')) {
      formData["UploadPhoto"] = this.state.UploadPhoto;
    }
    if (formData.hasOwnProperty("UploadTrainingAttendancePhoto1")) {
      formData["UploadTrainingAttendancePhoto1"] = this.state.uploadedFilePhoto1;
    }
    if (formData.hasOwnProperty("CallingToVLEUploadPhoto")) {
      formData["CallingToVLEUploadPhoto"] = this.state.uploadedFileCallingToVLEUploadPhoto;
    }
    if (formData.hasOwnProperty("UploadTrainingAttendanceDocument1")) {
      formData["UploadTrainingAttendanceDocument1"] = this.state.uploadedFileDocument1
    }
    if (navigator.onLine) {
      const result = await this.props.updateDynamicData({
        'data': [formData],
        'formName': this.isCampaign ? "existingcampaignactivity" : CONFIGS.UPDATE_ACTIVITY_FORM,
        formBuilder: this.formBuilder?.actionType,
        userID: this.props.userState?.profileInfo?.userId
      });
      if (result.status === 200) {
        this.props.activateSuccessMessageBar("Activity is updated successfully.")
        this.props.deActivateLoader();
        setTimeout(() => {
          if (this.isCampaign){
            this.props.history.push(`/app/activity/campaigndetails/${this.props.activityData.getSelectActivityData.CampaignActivityid}`)
          }else{
          this.props.history.push(`/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`)
          }
        }, 1000)
      } else {
        this.props.activateErrorMessageBar("Activity is not updated.")
        this.props.deActivateLoader();
      }
    } else {
      this.props.deActivateLoader();
      // update the activity in offline
      const activityObject = { ...this.props.activityData.getSelectActivityData };
      activityObject.IsBacklog = false
      activityObject.IsCheckin = false
      activityObject.IsClaimRequired = formData.IsClaimRequired
      activityObject.MeetingEndDateTime = formData.MeetingEndDateTime
      activityObject.MeetingStartDateTime = formData.MeetingStartDateTime
      activityObject.ModifiedDate = formData.ModifiedDate
      activityObject.Reason = formData.Reason
      activityObject.TransportModeID = formData.TransportModeID
      activityObject.TravelExpence = formData.TravelExpence
      activityObject.isActiveCheckin = false
      const key = this.isCampaign ? activityObject.CampaignActivityid : activityObject.TrnActivityID ? activityObject.TrnActivityID  : activityObject.LmsActivityGuid
      updateActivityOffline(key.toString(), activityObject).then((res) => {
        if (res) {
          if (this.isCampaign){
            this.props.history.push(`/app/activity/campaigndetails/${key}`)
          }else{
          this.props.history.push(`/app/activity/detail/${key}`)
          }
        }
      }).catch((err) => this.props.history.push(`/app/activity/all`));
    }
    return true;
  }

  onChangeHandler = async (event, key, model) => {
    if (key === 'UploadBill') {
      this.uploadFile(event, key);
    } else if (key === "UploadTrainingAttendancePhoto1") {
      this.uploadFile(event, key)
    } else if (key === "UploadPhoto") {
      this.uploadFile(event, key)
    } else if (key === "UploadTrainingAttendanceDocument1") {
      this.uploadFile(event, key)
    } else if (key === "CallingToVLEUploadPhoto") {
      this.uploadFile(event, key)
    } else if (key === 'MeetingStartDateTime') {
      const newDate = moment(event.target.value).add(30, 'm').format(TDateFormat)
      this.setState({ current: { ...this.state.current, MeetingStartDateTime: event.target.value, MeetingEndDateTime: newDate } })
    } else if (key === 'MeetingEndDateTime') {
      const newDate = moment(event.target.value).format(TDateFormat)
      this.setState({ current: { ...this.state.current, MeetingEndDateTime: newDate } })
    }
    const flag = this.props.activityData.activityComplete ? ACTIVITY_COMPLETED :
      this.props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED : this.props.activityData.activityUpdateCalls ? ACTIVITY_UPDATE_CALLS : ACTIVITY_CANCELLED;
    let formData = handleDropdownSelection(event, key, this.state.formData,
      this.props.masterDataState, flag,
      this.props.userState.profileInfo.roleId, this.props.activityData.getSelectActivityData.ActivityName)
    this.setState({ formData: formData })
    if (key === "IsClaimRequired") {
        delete model['IsClaimRequired']
        delete model['TravelExpence']
        delete model['TransportModeID']
        delete model['errors']['TravelExpence']
      if (event === true) {
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: true
          }
        })

        formData.find((data) => data.key === "TransportModeID").options = this.props.masterDataState['ModeofTransport']
        this.setState({ formData: formData })
        const checkIn = await this.props.validateIsCheckin({ checkinFor: this.isCampaign ? "CampaignActivity" : "activity", Id:  this.isCampaign ?  this.props.activityData.getSelectActivityData.CampaignActivityid : this.props.activityData.getSelectActivityData.TrnActivityID })
        const arrayToRemove = ActivityCancelledFormPart1
        formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

        const arrayToRemove1 = ActivityCancelledFormPart2
        formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

        const arrayToRemove2 = ActivityCancelledFormPart3
        formData = removeSubArrayFromArray(arrayToRemove2, "key", formData)
        const { data } = checkIn

        if (data) {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: data
            }
          })
          formData = formData.concat(ActivityCancelledFormPart1);
          formData.find((data) => data.key === "TransportModeID").options = this.props.masterDataState['ModeofTransport']
          this.setState({ formData: formData })
        } else {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: false
            }
          })
          const arrayToRemove = ActivityCancelledFormPart1
          formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

          const arrayToRemove1 = ActivityCancelledFormPart2
          formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

          const arrayToRemove2 = ActivityCancelledFormPart3
          formData = removeSubArrayFromArray(arrayToRemove2, "key", formData)
          delete model.errors['TravelExpense'];
          delete model.errors['TransportModeID'];
          this.setState({ formData: formData })
          this.props.activateErrorMessageBar("Please Check In")
        }
      } else {
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: false
          }
        })
        const arrayToRemove = ActivityCancelledFormPart1
        formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

        const arrayToRemove1 = ActivityCancelledFormPart2
        formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

        const arrayToRemove2 = ActivityCancelledFormPart3
        formData = removeSubArrayFromArray(arrayToRemove2, "key", formData)
        this.setState({ formData: formData })
        delete model.errors['TravelExpense'];
        delete model.errors['TransportModeID'];
      }
    }else if (event.target.value === "2") {
        delete model['TravelExpence']
        delete model.errors['TravelExpence']
    }
    if(this.props.activityData.activityComplete===true &&
      this.props.activityData.getSelectActivityData.ActivityIdName=="Meeting/Visit"){
    if (key === "IsClaimRequired") {
      const copyFormData=formData
      const uploadPhotoform1=copyFormData.slice(0,3)
      const uploadPhotoform2=copyFormData.slice(4)
      const uploadPhotoform3=copyFormData.slice(3,4)
    if (event === true) {
      this.setState({
        formData:[...uploadPhotoform1,...uploadPhotoform2,...uploadPhotoform3]
      })
    }else{
      this.setState({
        current: {
          ...this.state.current,
          IsClaimRequired: false
        }
      })
    }
  }
}
if(this.props.activityData.activityComplete===true &&
  this.props.activityData.getSelectActivityData.ActivityIdName==="Meeting/Visit"){
  if (key === "TransportModeID") {
    const copyFormData=formData
    const uploadPhotoForm1=copyFormData.slice(0,4)
    const uploadPhotoForm2=copyFormData.slice(5,7)
    const uploadPhotoForm3= copyFormData.slice(4,5)
    const finalUploadForm=[...uploadPhotoForm1,...uploadPhotoForm2,...uploadPhotoForm3]
  if (key === "TransportModeID" && this.props.userState.profileInfo.roleId=="BDM") {
    this.setState({
      current: {
        ...this.state.current,
        IsClaimRequired: true
      },
      formData:finalUploadForm
      })
    }else if (key === "TransportModeID" && this.props.userState.profileInfo.roleId=="ABH"){
      const copyform=finalUploadForm
      copyform[4].options=this.props.masterDataState.VehicleType
      this.setState({
        current: {
          ...this.state.current,
          IsClaimRequired: true
        },
        formData:copyform
        })
    }
  }
}
    if (key === "MeetingStartDateTime"){
      const StartDate = moment(event.target.value).format(TDateFormat);
      this.state.formData.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate).endOf('day').format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
      })

    }
    if (key === "MeetingEndDateTime") {
      const newDate = moment(event.target.value).format(TDateFormat);
      if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, "MeetingEndDateTime": "meeting end date should not less than meeting starting date" },
        })
      } else {
        this.setState({
          ...this.state, extErrors: {},
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingEndDateTime: newDate },
      }, () => {
        const extErrors = meetingDateValidations(this.state);
        this.setState({ extErrors: extErrors });
      });
    }
  }

  onCancelHandler = () => {
    if (this.isCampaign) {
      this.props.history.push(`/app/activity/campaigndetails/${this.props.activityData.getSelectActivityData.CampaignActivityid}`)
    } else {
      this.props.history.push(`/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`)
    }
  }

  uploadFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await this.props.uploadFileToBlob({ 'file': files[0] });
      const { downloadUrl } = fileRes.data
      if (fileRes.status === 200) {
        if (key === "UploadTrainingAttendancePhoto1") {
          this.setState({ uploadedFilePhoto1: files[0].name })
        } else if (key === "UploadTrainingAttendanceDocument1") {
          this.setState({ uploadedFileDocument1: files[0].name })
        } else if (key === "CallingToVLEUploadPhoto") {
          this.setState({ uploadedFileCallingToVLEUploadPhoto: files[0].name})
        } else if (key === "UploadPhoto") {
          this.setState({ UploadPhoto: downloadUrl })
        } else {
          this.setState({ uploadedFile: downloadUrl })
        }
      }
    } else {
      this.props.activateErrorMessageBar(
        "Cannot upload files in offline mode!"
      );
    }
  }

  statusFunction = async () => {
    const result = await this.props.getActivityStatusMasterData()
    if (result.status === 200) {
      let statusId = []
      if (this.props.activityData.activityComplete) {
        statusId = result.data.filter(f => f.ActivityStatus === 'Activity Completed')
      } else if (this.props.activityData.activityReshedule) {
        statusId = result.data.filter(f => f.ActivityStatus === "Activity Rescheduled")
      } else {
        statusId = result.data.filter(f => f.ActivityStatus === "Activity Cancelled")
      }
      this.setState({ activityStatus: statusId[0] })
    }
  }
  loadBreadCrumbs = () => {
    const { t } = this.props;
    const createActivityTitle = t(this.formBuilder.getFormTitle())
    if (this.isCampaign){
      const breadcrumbs = [
        { name: 'Campaign Activity', url: '/app/campaignactivity/all' },
        { name: createActivityTitle, url: '/app/csc/activity/update' },
      ]
      this.props.setBreadcrumbs(breadcrumbs);
    }else{
      const breadcrumbs = [
        { name: 'Activity', url: '/app/activity/all' },
        { name: createActivityTitle, url: '/app/csc/activity/update' },
      ]
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  getUpdatedStatusCallCount = async () => {

    const trn = parseInt(this.props.activityData?.getSelectActivityData?.TrnActivityID)
    const camId = parseInt(this.props.activityData?.getSelectActivityData?.CampaignActivityid)
    if(this.isCampaign){
      const res = await this.props.getUpdatedStatusCallCountforCampaignCSC( camId  )
      this.setState({ callCount: res?.data })
    }else{
    const res = await this.props.getUpdatedStatusCallCountforCSC(trn)
    this.setState({ callCount: res?.data })
    }
  }
  componentDidUpdate=()=>{
    if(this.props.activityData.activityComplete === true &&
    this.props.activityData.getSelectActivityData.ActivityID == 10){
      if(this.props.activityData.statusCallCOunt !== 0 &&
        this.props.activityData.getSelectActivityData.ActivityStatusId == 16 ){
        const copyFormData=this.state.formData
        copyFormData[0].disabled=false
      } else {
        const copyFormData=this.state.formData
        copyFormData[0].disabled=true
      }
    }
  }

getDetails=async()=>{
  this.props.activateLoader()
  const activityId = this.props.activityData.getSelectActivityData?.TrnActivityID
  const campaignActivityId = this.props.activityData.getSelectActivityData?.CampaignActivityid
  if (navigator.onLine) {
    if (this.isCampaign){
      const result = await this.props.getCampaignActivityDetails({ 'activityId': campaignActivityId })
       if (result.status === 200) {
      const data = result.data;
      this.setState({ activityStatusCheck: data ,selectedActivityOldData:data})
      this.props.deActivateLoader();
    }}else{
    const result = await this.props.getActivityDetails({ 'activityId': activityId })
    if (result.status === 200) {
      const data = result.data;
      this.setState({ activityStatusCheck: data ,selectedActivityOldData:data})
      this.props.deActivateLoader();
    }}
  } else {
    this.props.deActivateLoader();
  }
}



  componentDidMount() {
    this.getDetails()
    this.loadBreadCrumbs()
    this.statusFunction()
    const formData = this.props.masterDataState ? initFormData(this.state.formData, this.props.masterDataState, this.props.activityData.activityComplete ? ACTIVITY_COMPLETED :
      this.props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED :
      this.props.activityData.activityUpdateCalls ? ACTIVITY_UPDATE_CALLS : ACTIVITY_CANCELLED, this.props.activityData.getSelectActivityData.ActivityIdName) : this.state.formData;
    this.setState({ formData: formData })

    this.getUpdatedStatusCallCount()
  }
  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    if (key === "UploadTrainingAttendancePhoto1") {
      this.setState({ uploadedFilePhoto1: '' })
    } else if (key === "UploadTrainingAttendanceDocument1") {
      this.setState({ uploadedFileDocument1: '' })
    }
    else if (key === "CallingToVLEUploadPhoto") {
      this.setState({ uploadedFileCallingToVLEUploadPhoto: '' })
    }
    else {
      this.setState({ uploadedFile: '' })
    }
  }


  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: '#ccc' }} key={index}>
        <span className="file-name">{file.name}</span>
        <span className="action" onClick={(e) => this.removeFile(e, file, index, key)}>
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  }

  renderRadioIcons = (radioOption) => {
    if (radioOption.key === PUBLIC_TRANSPORT) {
      return <DirectionsBusIcon></DirectionsBusIcon>
    }
    else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      return <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if (radioOption.key === TWO_WHEELER) {
      return <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if (radioOption.key === FOUR_WHEELER) {
      return <DirectionsCarIcon></DirectionsCarIcon>
    } else {
      return ""
    }
  }

  render() {
    const { t } = this.props;
    return <div className="mainContainer createActivity updateActivity">
      <Breadcrumb></Breadcrumb>
      <div className="formBox">
        <DynamicForm
          onRef={ref => this.dyFormRef = ref}
          //className="dynamicForm"
          title={this.state.selectedActivityOldData ? this.state.selectedActivityOldData.ActivityName : ''}

          buttonSubmit={t('Update')}
          defaultValues={this.state.current}
          model={this.state.formData}
          onSubmit={(model) => {
            this.onSubmitHandler(model);
          }}
          disableSubmitOnInValid={false}
          design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
          materialOutline={false}
          onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
          buttonNormalClick={(id, key, obj) => {
          }
          }
          showAstersikOnRequiredFields={true}
          className="activityTypeDiv"
          extErrors={this.state.extErrors}
          extaraActionFormButton={<>
            <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{t('Cancel')}</button>
          </>}
          fileUploaderFields={['UploadBill', "UploadTrainingAttendancePhoto", "UploadTrainingAttendanceDocument"]}
          renderUploadedFilesItem={this.renderUploadedFilesItem}
          renderRadioIcons={(option) => this.renderRadioIcons(option)}
          customValidators={this.customValidators}
        />
      </div>
      <Footer></Footer>
    </div>
  }
}

const mapStateToProps = (state) => ({
  activityData: state.updateActivityReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer
});


const mapDispatchToProps = dispatch => ({
  getActivityStatusMasterData: (payload) => dispatch(getActivityStatusMasterData(payload)),
  setActivityData: (payload) => dispatch(setActivityData(payload)),
  getActivityById: (payload) => dispatch(getActivityById(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getUpdatedStatusCallCountforCSC: (payload) => dispatch(getUpdatedStatusCallCountforCSC(payload)),
  getUpdatedStatusCallCountforCampaignCSC: (payload) => dispatch(getUpdatedStatusCallCountforCampaignCSC(payload)),
  getCampaignActivityDetails: (payload) => dispatch(getCampaignActivityDetails(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  getActivityDetails: (payload) => dispatch(getActivityDetails(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(CSCUpdateActivity));
