


export const setSalesManagementActivity=(data,ele)=>({
    type:'EDIT_SALES_MANAGEMENT_ACTIVITY',payload:data,payloadEle:ele
})

export const setSalesManagementComplete=()=>({
    type:'EDIT_SALES_MANAGEMENT_ACTIVITY_COMPLETE',payload : true
})

export const setSalesManagementCancel=()=>({
    type:'EDIT_SALES_MANAGEMENT_ACTIVITY_CANCEL', payload: true
})

export const setSalesManagementUpdate=()=>({
    type:'EDIT_SALES_MANAGEMENT_ACTIVITY_UPDATE', payload: true
})

// export const setSalesManagementdata=(res)=>({
//     type:'activitydata', payload:res
// })

// export const getdropdowndata = (payload) => (dispatch, getState) => {
//   console.log("working------------------------------------");

//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await get(`${GET_DROPDOWN_DATA}`);
//       dispatch(setSalesManagementdata(res.data));
//       resolve(res);
//     } catch (err) {
//       reject(err);
//       dispatch(setSalesManagementdata(err?.data?.Responsemessage));
//     }
//   });
// };