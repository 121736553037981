
import { format } from 'date-fns';
import moment from "moment";

const _format = 'yyyy-MM-dd';
const _dateTimeLocal = "datetime-local";
const _meetingStartTime = "Meeting Start Date and Time";
const _meetingEndTime = "Meeting End Date and Time";
const _meetingStartEndTimeValidator = "Meeting Start Date and Time is required.";
const _requiredRemarks = "Remarks is required.";
const stateLabel = "State";
const meetingType = "Meeting Type";
const stateName = "State name is required.";
const faMapMarker = "fa fa-map-marker";
const meetingLocation = "Meeting Location";
const currAddress = "Current Address: ";
const meetingLocRequired = "Meeting location is required.";
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");


export const trainngActivityForm = [
    {
        "key": "ZMAID", "type": "autocompleteMultiselect", "id": "ZMAID", "label": "Training to ZMAs",
        "validators": [{ "name": "required", "value": false, "validationMessage": "This field  is required." }], "options": []
    },
    {
        "key": "AdvisorID", "type": "autocompleteMultiselect", "id": "AdvisorID", "label": "Training to Advisors",
        "validators": [{ "name": "required", "value": false, "validationMessage": "This field  is required." }], "options": []
    },
    {
        "key": "TrainingTopic",
        "type": "text", "disabled": false, "hide": false,
        "id": "TrainingTopic", "label": "Training Topic",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Topic is required." },
        { "name": "maxLength", "value": 30, "validationMessage": "Max 30 characters allowed." },
        { "name": "minLength", "value": 10, "validationMessage": "Min 10 characters required." }]
    },
    {
        "key": "MeetingStartDateTime",
        "min": format(new Date(), _format) + "T" + format(new Date(), 'kk:mm'),
        "hide": false, "type": _dateTimeLocal, "disabled": false,
        "id": "MeetingStartDateTime", "label": _meetingStartTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }]
    },
    {
        "key": "MeetingEndDateTime", "min": format(new Date(), _format) + "T" + format(new Date(), 'kk:mm'),"max": endMaxDate,
        "hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting End Date and Time is required." }]
    },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
]
