import React from 'react'
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { makeStyles } from "@material-ui/core/styles";
import "./teamList.scss";

const TeamListItem = ({ userID, userName, initials, loggedIn, lastSeen, isLogin, history }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(3),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    activityLogo: {
      borderRadius: 10,
      textAlign: "center",
    },
    heading: {
      padding: "10px 20px 20px 10px",
    },
    subHeading: {
      fontSize: 16,
      fontWeight: "400",
      padding: "5px 10px !important",
    },
    subHeadingBold: {
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();
  const handleBDMSelect = (id) => {
    if (id) {
      // history.push('/app/home');
    }
  }
  return (
    <div>
      <Grid container spacing={3} textalign="left">
        <Grid item xs={12} md={12}>
          <Grid className='teamListDiv' onClick={()=> handleBDMSelect(userID)}>
            <Paper className={classes.paper}>
              {/* <ArrowRightAltIcon className='arrowIcon' onClick={()=> handleBDMSelect(userID)}/> */}
              <Grid container spacing={3} className="teamDiv">
                <Grid item xs={4} md={2}>
                  <div className='profBG'>{initials}</div>
                </Grid>
                <Grid item xs={8} md={10}>
                  <Typography variant='h3'>{userName}</Typography>
                  {
                    isLogin === 1 ?
                    <Typography className='loggedDiv' variant='h6'><span className='onlineCircle'></span> Logged in at {loggedIn}</Typography>
                    :
                    <Typography className='loggedDiv' variant='h6'><span className='offlineCircle'></span> Not Logged in</Typography>
                  }
                  <Typography className='lastSeenDiv' variant='h6'> Last seen {lastSeen}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default TeamListItem;
