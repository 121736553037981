import { format } from "date-fns";
import { ALLOWED_FUTURE_DAYS, BROCA_COMMON_ACTIVITY_TYPES_ID, CONFIGS } from "../../../../utils/config";
import { getDateAfterDays } from "../../../../utils/utils";
import moment from "moment";


const partnerNameLabel = "Partner Name"
const branchNameLabel = "Branch Name & Code";
const partnerMobileNoLabel="Partner Mobile No"
const branchCategoryLabel = "Branch Category"
const activityPlannedLabel = "Activity Planned"
const provideDetailsLabel = "Provide Details"
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";


const partnerNameRequired = "Partner name is required."
const partnerMobileNoRequired = "Partner Mobile No is required"
const branchCategoryRequired= "Branch Category is required"
const activityPlannedRequired= "Activity Planned is required"
const provideDetailsRequired= "Provide Details is required"
const branchNameRequired = "Branch name is required.";

const meetingStartDateAndTimeRequired = "Meeting start time is required.";
const meetingEndDateAndTimeRequired = "Meeting end time is required."
const dateFormate = 'yyyy-MM-dd';

const minDate = format(new Date(), dateFormate) + "T" + format(new Date(), 'kk:mm');
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");
const date = new Date();
let pastTenYear = date.getFullYear() - 10;
let pastEleventYear = date.getFullYear() - 11;
let FutureThreeYear = date.getFullYear() + 3;
let lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

const backEeleventYear=moment(date.setFullYear(pastEleventYear)).format("YYYY-MM-DD");
const getPastTenYear=moment(date.setFullYear(pastTenYear)).format("YYYY-MM-DD");
const getFutureThreeYear=moment(date.setFullYear(FutureThreeYear)).format("YYYY-MM-DD");
const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
const datetimelocal = "datetime-local"

export const provideDetailsValidator=[{ "name": "required", "value": true, "validationMessage": provideDetailsRequired },
{ "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
{ "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
export const BrocaActivityForm = [
    {
      "key": "ActivityID", "type": "select", "id": "ActivityID", "label": "Activity Type",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Activity type is required." }],
      "options": []
    },
  ]

  export const BrocaPartnerVisitForm = [
    {
      "key": "BranchId", "type": "select", "id": "BranchId", "label": branchNameLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": branchNameRequired }],
      "options": []
    },
    {
      "key": "PartnerName", "type": "select", "id": "PartnerName", "label": partnerNameLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerNameRequired }],
      "options": []
    },
    {
      "key": "PartnerMobileNo", "type": "number","disabled": true, "id": "PartnerMobileNo", "label": partnerMobileNoLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerMobileNoRequired }]
    },
    {
        "key": "BranchCategory", "type": "select", "id": "BranchCategory", "label": branchCategoryLabel,
        "validators": [{ "name": "required", "value": true, "validationMessage": branchCategoryRequired }],
        "options": [{ "key": "Select", "label": "Select", "value": "" },
        { "key": "A", "label": "A", "value": "A" },
        { "key": "B", "label": "B", "value": "B" },
        { "key": "C", "label": "C", "value": "C" }]
    },
    {
        "key": "ActivityPlannedId", "type": "select", "id": "ActivityPlannedId", "label": activityPlannedLabel,
        "validators": [{ "name": "required", "value": true, "validationMessage": activityPlannedRequired }],
        "options": []
    },
    {
      "key": "OtherActivityPlanned", "type": "text", "disabled": false, "id": "OtherActivityPlanned", "label": provideDetailsLabel,"hide":true,
      "validators":[]
    },
    {
      "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
      "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
      "type": datetimelocal, "id": "MeetingStartDateTime",
      "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
      "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
      "max": endMaxDate,
      "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
  ]

  export const BrocaProductTraningForm = [
    {
      "key": "BranchId", "type": "select", "id": "BranchId", "label": branchNameLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": branchNameRequired }],
      "options": []
    },
    {
      "key": "PartnerName", "type": "select", "id": "PartnerName", "label": partnerNameLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerNameRequired }],
      "options": []
    },
    {
      "key": "PartnerMobileNo", "type": "number","disabled": true, "id": "PartnerMobileNo", "label": partnerMobileNoLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerMobileNoRequired }]
    },
    {
        "key": "TrainingTopic", "type": "select", "id": "TrainingTopic", "label": "Training Topic",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Training topic is required" }],
        "options": []
    },
    {
        "key": "OtherTrainingTopic", "type": "text", "disabled": false, "id": "OtherTrainingTopic", "label": provideDetailsLabel,"hide":true,
        "validators": []
      },
    {
        "key": "ProductId", "type": "select", "id": "ProductId", "label": "Product Name",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Product name is required" }],
        "options": []
    },
    {
      "key": "NoOfAttendees", "type": "number", "disabled": false, "id": "NoOfAttendees", "label": "No of Attendees",
      "validators": [{ "name": "required", "value": true, "validationMessage": "No of attendees is required "},
         { "name": "minLength", "value": 1, "validationMessage": "Mininum 1 digit required" },
         { "name": "maxLength", "value": 3, "validationMessage": "Maximum 3 digit allowed" }]
    },
    {
      "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
      "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
      "type": datetimelocal, "id": "MeetingStartDateTime",
      "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
      "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
      "max": endMaxDate,
      "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
  ]

  export const BrocaBirthdayCelebrationForm = [
    {
      "key": "BranchId", "type": "select", "id": "BranchId", "label": branchNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": branchNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerName", "type": "select", "id": "PartnerName", "label": partnerNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": partnerNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerMobileNo", "type": "number","disabled": true, "id": "PartnerMobileNo", "label": partnerMobileNoLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerMobileNoRequired }]
    },
    {
      "key": "OtherDesignation", "type": "text", "disabled": false, "id": "OtherDesignation", "label": "Designation",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Designation is required "},
      { "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]

    },
    {
      "key": "Name", "type": "text", "disabled": false, "id": "Name", "label": "Birthday Person Name",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Birthday person name is required "},
      { "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 letters" },
      { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }]
    },
    {
      "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
      "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
      "type": datetimelocal, "id": "MeetingStartDateTime",
      "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
      "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
      "max": endMaxDate,
      "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
  ]

  export const BrocaProductLunchForm = [
    {
      "key": "BranchId", "type": "select", "id": "BranchId", "label": branchNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": branchNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerName", "type": "select", "id": "PartnerName", "label": partnerNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": partnerNameRequired }
    ],
      "options": []
    },
    {
      "key": "PartnerMobileNo", "type": "number","disabled": true, "id": "PartnerMobileNo", "label": partnerMobileNoLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerMobileNoRequired }]
    },
    {
      "key": "LaunchProductName", "type": "text", "disabled": false, "id": "LaunchProductName", "label": "Launch Product name",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Launch product name is required "},
      { "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
      "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
      "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
      "type": datetimelocal, "id": "MeetingStartDateTime",
      "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
      "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
      "max": endMaxDate,
      "label": meetingEndDateAndTimeLabel, "validators": [
        { "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }
      ]
    },
  ]

  export const BrocaBranchHeadMeetForm = [
    {
      "key": "BranchId", "type": "select", "id": "BranchId", "label": branchNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": branchNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerName", "type": "select", "id": "PartnerName", "label": partnerNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": partnerNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerMobileNo", "type": "number","disabled": true, "id": "PartnerMobileNo", "label": partnerMobileNoLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerMobileNoRequired }]
    },
    {
      "key": "OtherDesignation", "type": "text", "disabled": false, "id": "OtherDesignation", "label": "Designation",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Designation is required "},
      { "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
      "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
      "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
      "type": datetimelocal, "id": "MeetingStartDateTime",
      "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
      "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
      "max": endMaxDate,
      "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
  ]

  export const BrocaBranchFoundationDayForm = [
    {
      "key": "BranchId", "type": "select", "id": "BranchId", "label": branchNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": branchNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerName", "type": "select", "id": "PartnerName", "label": partnerNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": partnerNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerMobileNo", "type": "number","disabled": true, "id": "PartnerMobileNo", "label": partnerMobileNoLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerMobileNoRequired }]
    },
    {
      "key": "FoundationDate", "type": "date", "disabled": false, "id": "FoundationDate", "label": "Foundation Date",
      "min":format(new Date(), dateFormate),"max": format(lastDayMonth, dateFormate),
      "validators": [{ "name": "required", "value": true, "validationMessage": "Foundation Date is required "}]
    },
    {
      "key": "MeetingStartDateTime", "noDate": null, "min": minDate,"hide": false,
      "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
      "type": datetimelocal, "id": "MeetingStartDateTime",
      "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
      "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
      "max": endMaxDate,
      "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
  ]

  export const BrocaJointCallWithPartnerForm = [
    {
      "key": "BranchId", "type": "select", "id": "BranchId", "label": branchNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": branchNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerName", "type": "select", "id": "PartnerName", "label": partnerNameLabel,
      "validators": [
        { "name": "required", "value": true, "validationMessage": partnerNameRequired }
      ],
      "options": []
    },
    {
      "key": "PartnerMobileNo", "type": "number","disabled": true,  "id": "PartnerMobileNo", "label": partnerMobileNoLabel,
      "validators": [{ "name": "required", "value": true, "validationMessage": partnerMobileNoRequired }]
    },
    {
        "key": "JointCallActivityID", "type": "select", "id": "JointCallActivityID", "label": "Activity",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Activity is required" }],
        "options": []
    },
    {
      "key": "OtherJointCallActivity", "type": "text", "disabled": false, "id": "OtherJointCallActivity", "label": "Activity Name","hide":true,
      "validators": [{ "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
        "key": "JointCAllACtivtytypeID", "type": "select", "id": "JointCAllACtivtytypeID", "label": "Type",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Type is required" }],
        "options": []
    },
    {
      "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
      "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
      "type": datetimelocal, "id": "MeetingStartDateTime",
      "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
      "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
      "max": endMaxDate,
      "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
  ]

  export const BrocaBusinessDevelopmentActivityForm = [
    {
        "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
        "type": datetimelocal, "id": "MeetingStartDateTime",
        "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
        "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
        "max": endMaxDate,
        "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
    {
      "key": "NameOfTheProspect", "type": "text", "id": "NameOfTheProspect", "label": "Name of the Prospect",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Name of the Prospect is required" },
      { "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 letters" },
      { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }]
    },
    {
      "key": "DesignationId", "type": "select", "id": "DesignationId", "label": "Designation",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Designation is required" }],
      "options": []
    },
    {
      "key": "CurrentPartner1", "type": "text", "id": "CurrentPartner1", "label": "Current Partner 1",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Current Partner 1 is required" },
      { "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
      "key": "CurrentPartner2", "type": "text", "id": "CurrentPartner2", "label": "Current Partner 2",
      "validators": [{ "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
      "key": "CurrentPartner3", "type": "text", "id": "CurrentPartner3", "label": "Current Partner 3",
      "validators": [{ "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
        "key": "MeetingPlannedWithId", "type": "select", "id": "MeetingPlannedWithId", "label": "Meeting planned with",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting planned with is required" }],
        "options": []
    },
    {
      "key": "RenewalYear", "type": "select", "disabled": false, "id": "RenewalYear", "label": "Renewal Year(FY)",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Renewal Year(FY) is required "}],
      "options": []
    },
    {
        "key": "LastAssignmentofId", "type": "select", "id": "LastAssignmentofId", "label": "Last Assignment of",
        "validators": [],
        "options": []
    },
    {
      "key": "LastAssignmentPlace", "type": "text", "disabled": false, "id": "LastAssignmentPlace", "label": "Last Assignment place",
      "validators": [{ "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
        "key": "SinceWhen", "noDate": null, "min": backEeleventYear,
        "max": format(new Date(), dateFormate),
        "type": "date", "id": "SinceWhen",
        "label": "Since when", "validators": []
    },
    {
        "key": "RetirementDate", "noDate": null, "min": getPastTenYear,
        "max": getFutureThreeYear,
        "type": "date", "id": "RetirementDate",
        "label": "Retirement Date", "validators": []
    },
    {
        "key": "EmailID", "type": "email", "disabled": false, "id": "EmailID", "label": "Email ID",
        "validators": []
    },
    {
        "key": "MobileNo", "type": "number", "disabled": false, "id": "MobileNo", "label": "Contact Details",
        "validators": [{ "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
        { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
    },
    {
        "key": "SecretaryName", "type": "text", "disabled": false, "id": "SecretaryName", "label": "Secretary Name",
        "validators": [{"name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
        { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
        "key": "SecretaryEmailID", "type": "email", "disabled": false, "id": "SecretaryEmailID", "label": "Email ID",
    },
    {
        "key": "SecretaryMobileNo", "type": "number", "disabled": false, "id": "SecretaryMobileNo", "label": "Contact Details",
        "validators": [{ "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
        { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
    },
  ]

  export const BrocaLiveLeadGenerationForm = [
    {
        "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
        "type": datetimelocal, "id": "MeetingStartDateTime",
        "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
    },
    {
        "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
        "max": endMaxDate,
        "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
    },
    {
      "key": "Location", "type": "text", "id": "Location", "label": "Location",
      "validators": [{ "name": "required", "value": true, "validationMessage": "Location is required" },
      { "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
    {
      "key": "NoOfLeads", "type": "text", "id": "NoOfLeads", "label": "No of Leads",
      "validators": [{ "name": "required", "value": true, "validationMessage": "No of Leads is required" },
      { "name": "minLength", "value": 1, "validationMessage": "Mininum 1 digit required" },
      { "name": "maxLength", "value": 3, "validationMessage": "Maximum 3 digit allowed" }]
    },
    {
        "key": "CampaigningDoneId", "type": "select", "id": "CampaigningDoneId", "label": "Campaigning Done",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Campaigning Done is required" }],
        "options": []
    },
    {
      "key": "OtherCampaigningDone", "type": "text", "disabled": false, "id": "OtherCampaigningDone", "label": provideDetailsLabel,"hide":true,
      "validators":[{ "name": "minLength", "value": 3, "validationMessage": "Mininum 3 charector required" },
      { "name": "maxLength", "value": 200, "validationMessage": "Maximum 200 charector allowed" }]
    },
  ]

  export const initFormData = (formFields, masterDataState, roleId, isSMARoute) => {

    const formData = [...formFields]
    const {Activities}=masterDataState
    const activity=Activities.filter((item)=>BROCA_COMMON_ACTIVITY_TYPES_ID.includes(item.value))
    formData[0].options=activity
    return formData
  }

export const getRenewalYear = () => {
  const dateRenewal = new Date();
  let getYear = [];
  let getPrevousYear = [];
  let year = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, -1, -2, -3];
  let prevousYear = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0, -1, -2];
  prevousYear.forEach((item) => {
    getYear.push(dateRenewal.getFullYear() - item);
  });
  year.forEach((item) => {
    getPrevousYear.push(dateRenewal.getFullYear() - item);
  });
  let arr = getYear.map((option, index) => {
    return {
      key: `${option}-${getPrevousYear[index]}`,
      label: `${option}-${getPrevousYear[index]}`,
      value: `${option}-${getPrevousYear[index]}`,
    };
  });
  arr.unshift({ key: "select", label: "Select", value: "" });
  return arr;
}

  export const getDropdownDataBranch = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
        arr = optionsArr?.map((option) => {
          if (option?.value ==''){
            return { key: option[key], label: ` ${option[label]}`, value: option[value] }
          }else{
            return { key: option[key], label: `${option[key]} - ${option[label]}`, value: option[value] }
          }
        })
    }
    return arr;
}

const getDropdownData = (optionsArr, key, label, value) => {
  let arr = [];
  if (optionsArr && optionsArr.length > 0) {
    arr = optionsArr?.map((option) => {
      return { key: option[key], label: option[label], value: option[value] };
    });
  }
  arr.unshift({ key: "select", label: "select", value: "" });
  return arr;
};

export const handleActivitySelection = (event, key, formFields, masterDataState, role) => {
  const { Branches, getPatnerDetails } = masterDataState
  let formData = [...formFields]
  if (key === "ActivityID") {
    formData = BrocaActivityForm
    switch (event.target.value) {
      case "45":
        formData = formData.concat(BrocaPartnerVisitForm);
        formData[5].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_ACTIVITY_PLANNED.TABLE_NAME];
        break;
      case "46":
        formData = formData.concat(BrocaProductTraningForm);
        formData[4].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_TRAINING_TOPIC.TABLE_NAME];
        formData[6].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.PRODUCTS.TABLE_NAME];
        break;
      case "47":
        formData = formData.concat(BrocaBirthdayCelebrationForm);
        break;
      case "48":
        formData = formData.concat(BrocaProductLunchForm);
        break;
      case "49":
        formData = formData.concat(BrocaBranchHeadMeetForm);
        break;
      case "50":
        formData = formData.concat(BrocaBranchFoundationDayForm);
        break;
      case "51":
        formData = formData.concat(BrocaJointCallWithPartnerForm);
        formData[4].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_JOINT_CALL_ACTIVITY.TABLE_NAME]
        formData[6].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_JOINT_CALL_ACTIVITY_TYPE.TABLE_NAME]
        break;
      case "52":
        formData = formData.concat(BrocaBusinessDevelopmentActivityForm);
        formData[4].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_DESIGNATION.TABLE_NAME].slice(0, 5)
        formData[8].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_MEETING_PLANNED_WITH.TABLE_NAME];
        formData[9].options = getRenewalYear()
        formData[10].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_LAST_ASSIGNMENTOF.TABLE_NAME];
        break;
      case "53":
        formData = formData.concat(BrocaLiveLeadGenerationForm);
        formData[5].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.BROCA_CAMPAIGNING_DONE.TABLE_NAME];
        break;
      default:
        break;
    }
  }
  formData.find((val, index) => {
    if (val.key === "BranchId") {
      formData[index].options = getDropdownDataBranch(Branches, 'key', "label", "value")
    }
    if (val.key === "PartnerName") {
      let activePatner = getPatnerDetails.filter(list => list.Status === "ACTIVE");
      formData[index].options = getDropdownData(
        activePatner,
        "Id",
        "PartnerName",
        "PartnerName"
      );
    }
  })
  return formData
}