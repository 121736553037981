import Grid from '@mui/material/Grid';
import { Fragment,  useState } from 'react';
import { Drawer } from '@mui/material';
import useOnlineStatus from '../../../hooks/useOnlineStatus';
// import { makeStyles } from '@material-ui/core/styles';
import MapRoute from './profileSettingAddressMap';
import { useHistory } from "react-router-dom"

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ModalPopup from 'f1-modal-react';
import { Button } from '@material-ui/core';


const  ProfileSettingAddress= (props) =>{
    const isOnline = useOnlineStatus();
    const history = useHistory();

    const [drawerList, setDrwerList] = useState({
        right: false
    })
    const [showModalSetup, setShowModalSetup] = useState(false);

    const handleClose = () => {
        setShowModalSetup(false)
        toggleDrawer('right', true)
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (isOnline) {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setDrwerList({ ...drawerList, [anchor]: open });
        }
    };
    const getUpdatedHistory = () => {
        props.onGetUpdatedHistory();
    }
    return <Grid className='checkInDiv' container spacing={4}>
                    <Grid item xs={1} md={1}>
                            {['right'].map((anchor) => (
                                <Fragment key={anchor}>
                                    <span style={{ cursor: isOnline ? 'pointer' : 'not-allowed'}} onClick={toggleDrawer(anchor, true)}><LocationOnIcon /></span>
                                    <Drawer
                                        anchor={anchor}
                                        open={drawerList[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                    >
                                        <h3>Loading...</h3>
                                        <MapRoute history={history} updateAddress={props.updateAddress} 
                                        setDrwerList={setDrwerList} UserId={props.UserId} 
                                        onCheckInComplete={getUpdatedHistory}/>
                                    </Drawer>
                                    <ModalPopup
                                        show={showModalSetup}
                                        onHide={() => setShowModalSetup(!showModalSetup)}
                                        size={"sm"}
                                        centered={true}
                                        modelHeader={"Please enable your location"}
                                        modelContent={
                                            <Button
                                                onClick={handleClose}
                                            >
                                                CLOSE
                                            </Button>
                                        }
                                        backdrop={"static"}
                                    />
                                </Fragment>
                            ))}
                    </Grid>
    </Grid>
}

export default ProfileSettingAddress
