import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';

const PlannedHistory = (props) => {
    const { history, activityTitle } = props
    return <>
        {history.ActivityStatus === "Activity Planned" ?
            <>
                <TimelineItem>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>{activityTitle}</label>
                    </TimelineContent>
                </TimelineItem>
            </>
            : ''
        }
    </>
}

export default PlannedHistory
