import { post,get } from '../../api/api'
import { GET_ALL_NOTIFICATION_BY_USER,GET_NOTIFICATION_BY_USER,GET_MASTER_DATA,POST_READALL_NOTIFICATION_BY_USER,POST_READ_SINGLE_NOTIFICATION_BY_USER, VERSION_INFO_URL } from '../../api/baseURL'
import { activateLoader, deActivateLoader } from "./common";

export const getallnotificationsbyuser = (userId)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        //dispatch(activateLoader());
        try{
            const res= await get(`${GET_ALL_NOTIFICATION_BY_USER}${userId}`)
            resolve(res)
           //dispatch(deActivateLoader());
        } catch (err){
            reject(err)
            //dispatch(deActivateLoader());
        }
    })
 }

 export const getnotificationsbyuser = (userId)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
       // dispatch(activateLoader());
        try{
            const res= await get(`${GET_NOTIFICATION_BY_USER}userId`)
            resolve(res)
          //  dispatch(deActivateLoader());
        } catch (err){
            reject(err)
           //    dispatch(deActivateLoader());
        }
    })
 }

 export const getMasterDataForNotification = (payload) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await get(`${GET_MASTER_DATA}${payload.tableName}${'?IsCachecleared=true'}`, {});
        if (res.status === 200) {
          resolve(res)
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  export const readallnotificationsbyuser = (userId)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        dispatch(activateLoader());
        try{
            const res= await post(`${POST_READALL_NOTIFICATION_BY_USER}${userId}`)
            resolve(res)
            dispatch(deActivateLoader());
        } catch (err){
            reject(err)
            dispatch(deActivateLoader());
        }
    })
 }

 export const readsinglenotificationsbyuser = (NotificationID)=>(dispatch,getState)=>{
    return new Promise(async (resolve,reject)=>{
        dispatch(activateLoader());
        try{
            const res= await post(`${POST_READ_SINGLE_NOTIFICATION_BY_USER}${NotificationID}`)
            resolve(res)
            dispatch(deActivateLoader());
        } catch (err){
            reject(err)
            dispatch(deActivateLoader());
        }
    })
 }
 export const getLatestVersionInfo = () => (dispatch,getState) => {
   return new Promise(async (resolve, reject) => {
     try {
       const res = await get(`${VERSION_INFO_URL}`);
       if (res.status === 200) {
         resolve(res.data[0]);
       } else {
        reject();
       }
     } catch (err) {
       reject(err);
     }
   });
 };