import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import "./planYourDay.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import GpsFixedOutlinedIcon from "@mui/icons-material/GpsFixedOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { InputLabel, TextField } from "@mui/material";
import { checkInConfig } from "../../../../utils/config";
import { makeStyles } from "@material-ui/core/styles";
import useOnlineStatus from "../../../../hooks/useOnlineStatus";
import { getUserPlan } from "../../../../redux/actions/calendarActions";
import { useSelector } from "react-redux";
import PlanCard from "./planCard";
import Geocode from "react-geocode";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import currentLocationMarker from "../../../../assets/img/markerCurrent.png";
import leadsLocationMarker from "../../../../assets/img/markerLeads.png";
import { activateErrorMessageBar } from "../../../../redux/actions";
import { connect } from "react-redux"
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
}));

const PlanYourDay = ({ toggleDrawer, anchor, google }) => {
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(new Date());
  const isOnline = useOnlineStatus();
  const commonState = useSelector((state) => state);
  const [userId] = useState(commonState.userReducer.profileInfo.userId);
  const [userRole] = useState(commonState.userReducer.profileInfo.roleId);
  const [channelid] = useState(commonState.userReducer.profileInfo.channelid)
  const [selectedCalendarDate, setselectedCalendarDate] = useState(commonState.calendarActivityReducer.selectedDate === '' ? new Date() : commonState.calendarActivityReducer.selectedDate)
  const [mapInitData, setMapInitData] = useState({
    lat: null,
    lng: null,
    locationName: "",
  });
  const [loading, setLoading] = useState(true);
  const [fetchLocationloading, setFetchLocationloading] = useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [planDataList, setPlanDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState(0);
  const onMarkerClick = () => { };
  const handleChange = () => { };
  const onInfoWindowClose = () => {
    setOpenInfoWindowMarkerId(0);
  };
  const onInfoWindowOpen = (id) => {
    setOpenInfoWindowMarkerId(id);
  };
  const classes = useStyles();
  useEffect(() => {
    // buffer time to set the config of map
    setTimeout(() => {
      getPlanForDay(selectedCalendarDate);
    }, 3000);
    getCoords();
  }, []);

  useEffect(() => {
    Geocode.setApiKey(checkInConfig.API_KEY);
    Geocode.setLanguage("en");
    Geocode.setRegion("in");
    // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE
    Geocode.setLocationType("ROOFTOP");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      const tempData = [...planDataList];
      setFilteredData(tempData.filter((item) => item.DataType === "Lead"));
    } else if (newValue === 2) {
      const tempData = [...planDataList];
      setFilteredData(tempData.filter((item) => item.DataType === "PersistenceLead"));
    } else if (newValue === 3) {
      const tempData = [...planDataList];
      setFilteredData(tempData.filter((item) => item.DataType === "CampaignLead"));
    }
    else {
      const tempData = [...planDataList];
      setFilteredData(tempData);
    }
  };
  const getPlanForDay = async (forDate) => {
    if (isOnline) {
      setLoading(true);
      const request = {
        userId: userId,
        roleName: userRole,
        viewFor: "SELF",
        subordinateUserIds: "ALL",
        date: forDate,
      };
      const plandata = await getUserPlan(request);
      if (plandata.status === 200) {
        getLatLngForAddress(plandata.data);
      } else {
        console.log("failed");
        setLoading(false);
      }
    }
  };
  // Get latitude & longitude from address.
  const getLatLngForAddress = (planList) => {
    const promises = planList.map(item => {
      if (item.CustomerAddress && item.CustomerAddress !== '' && item.CustomerAddress !== 'N/A') {
        return Geocode.fromAddress(item.CustomerAddress).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            item.location = {
              lat: lat,
              lng: lng
            }
            return item;
          },
          (error) => {
            console.error(error);
            item.location = {
              lat: null,
              lng: null
            }
            return item;
          });
      } else {
        item.location = {
          lat: null,
          lng: null
        }
        return item;
      }
    });

    Promise.all(promises).then(results => {
      setLoading(false);
      setPlanDataList(results);
      setFilteredData(results);
    })
  };
  const handleDateChange = event => {
    setCurrentDate(event.target.value);
    setselectedCalendarDate(event.target.value)
    if (event.target.value !== '') {
      getPlanForDay(event.target.value);
    }
    setTabValue(0);
    setFilteredData([]);
  };

  const getCoords = async () => {
    setFetchLocationloading(true);
    navigator.geolocation.getCurrentPosition(handleResponse, handleError);
  };

  const handleResponse = (position) => {
    const { coords } = position;
    const { latitude, longitude } = coords;
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        setFetchLocationloading(false);
        if (response.results.length > 0) {
          const address = response.results[0].formatted_address;
          setMapInitData({
            lat: latitude,
            lng: longitude,
            locationName: address,
          });
        } else {
          this.props.activateErrorMessageBar('Couldn\'t find the address for the selected location!')
        }
      },
    );
  }

  const handleError = (error) => {
    setFetchLocationloading(false);
    this.props.activateErrorMessageBar('Couldn\'t find the address for the selected location!')
    console.error(error);
  }

  useEffect(() => {
    return () => {
      const id = navigator.geolocation.getCurrentPosition(handleResponse, handleError)
      navigator.geolocation.clearWatch(id)
    }
  }, [])

  return (
    <div>
      <Box
        sx={{ width: anchor === "right" }}
        role="presentation"
      >
        <div className="mapDiv pydMapDiv">
          <span className="closeIcon"><CloseIcon
            className={classes.drawerMobile}
            style={{ cursor: "pointer" }}
            onClick={toggleDrawer(anchor, false)}
          /></span>
          <div className="checkInDiv">
            {!fetchLocationloading ?
              (
                <>
                  <InputLabel>Current Location</InputLabel>
                  <div className="srch">
                    <SearchIcon className="srchIcon" />
                    <TextField
                      fullWidth
                      id="standard-bare"
                      variant="outlined"
                      placeholder="Current Location"
                      disabled
                      value={mapInitData.locationName}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <span onClick={getCoords}>
                            <GpsFixedOutlinedIcon />
                          </span>
                        ),
                      }}
                    />
                  </div>
                </>
              )
              :
              <InputLabel>Fetching your current location...</InputLabel>
            }
          </div>
          <div className="mapGoogleHeight">
            {!loading ? (
              <Map
                google={google}
                initialCenter={{
                  lat: mapInitData.lat,
                  lng: mapInitData.lng,
                }}
                zoomControlOptions={
                  {
                    position: google.maps.ControlPosition.RIGHT_CENTER
                  }
                }
                zoom={12} //filteredData.length === 1 ? 18 : 13
                disableDefaultUI={false}
                containerStyle={{
                  position: "static",
                  width: "100%",
                  height: "85%",
                }}
                style={{
                  width: "100%",
                  height: "85%",
                }}
              >
                <Marker
                  onClick={onMarkerClick}
                  name={"Current location"}
                  position={{ lat: mapInitData.lat, lng: mapInitData.lng }}
                  options={{ icon: currentLocationMarker }}
                />
                <InfoWindow onClose={() => onInfoWindowClose()}></InfoWindow>
                {filteredData.length > 0 &&
                  filteredData.map((data) => (
                    data.location.lat && data.location.lng &&
                    <Marker options={{ icon: leadsLocationMarker }} key={data.Id} position={data.location} onClick={() => onInfoWindowOpen(data.Id)}>
                      {openInfoWindowMarkerId === data.Id ?
                        <InfoWindow marker={data.location} key={data.Id} onCloseClick={() => onInfoWindowClose()}>
                          <span>{data.Name}{'--'}{data.BranchCode}{'-'}{data.BranchName} </span>
                        </InfoWindow>
                        : null
                      }
                    </Marker>
                  ))
                }
              </Map>
            ) : (
              <h3>Loading...</h3>
            )}
          </div>
          <div className="planRouteContainer">
            <Typography variant="h6">Plan Your Route</Typography>
            <div className="dText"><CalendarMonthIcon />
              <form className={classes.container} noValidate>
                <TextField
                  id="date"
                  label=""
                  type="date"
                  onkeydown={(e) => e.preventDefault()}
                  //  value={selectedCalendarDate}
                  defaultValue={selectedCalendarDate ? moment(selectedCalendarDate).format("YYYY-MM-DD") : moment(currentDate).format("YYYY-MM-DD")}
                  onChange={handleDateChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </div>
            <div className="tags">
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs example">
                <Tab label="All" {...a11yProps(0)} />
                { channelid && channelid === 3 ?
                  <Tab label="Business Leads" {...a11yProps(1)} />
                  :
                  <Tab label="Leads" {...a11yProps(1)} />
                }
                <Tab label="Persistency Leads" {...a11yProps(2)} />
                <Tab label="Campaign Leads" {...a11yProps(3)} />
              </Tabs>
            </div>
            <div className="listingHeighScroll">
              {
                filteredData.length > 0 && filteredData.map((plan) => {
                  return (
                    <PlanCard
                      customerAddress={plan.CustomerAddress}
                      meetingEndDateTime={plan.MeetingEndDateTime}
                      meetingStartDateTime={plan.MeetingStartDateTime}
                      planName={plan.Name}
                      planStatus={plan.Status}
                      planType={plan.DataType}
                      planId={plan.Id}
                      history={history}
                      mobileNo={plan.MobileNo}
                      channelid={channelid}
                      activityTypeId={plan.ActiviTypeId}
                    />
                  )
                })
              }
              {
                !loading && filteredData.length === 0 &&
                <Typography variant="h6">No record found</Typography>
              }
              {
                loading &&
                <Typography variant="h6">loading...</Typography>
              }
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};


const mapDispatchToProps = dispatch => ({
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload))
})

export default GoogleApiWrapper({
  apiKey: (checkInConfig.API_KEY)
})(withTranslation('translation', { withRef: true })(connect(mapDispatchToProps)(PlanYourDay)))
