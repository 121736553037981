import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import { getDateDuration, addMinuteToDate } from "../../../../utils/utils";
import { getOptionById } from "../helper";
import { useSelector } from "react-redux";
import { BOB_QLIK, activityCorprteGroup } from "../../../../utils/config";
import {
  BDF,
  BRANCH_TRAINING,
  BRANCH_VISIT,
  HNI_MEET,
  ICE_E_SAMPARK,
  MONTHLY_REVIEW,
  TRAINING,
  MEETING_VISIT,
  CALLING_TO_VLE_RAP_DM,
  NEW_BUSINESS,
  NEW_RECRUITMENT_ACTIVITY,
  REVIEW_MEETING,
  JOINT_SALES_CALL_WITH_SRM,
  BUSINESS_DEVELOPMENT_ACTIVITY, PARTNER_VISIT, PRODUCT_TRAINING, BIRTHDAY_CELEBRATION, PRODUCT_LAUNCH,
  BRANCH_HEAD_MEET, BRANCH_FOUNDATION_DAY, JOINT_CALL_WITH_PARTNER, LIVE_LEAD_GENERATION,
  PARTNER_BRANCH_VISIT_PLAN, TEAM_REVIEW,
  ICE_FOR_LOAN_ACTIVITY,
  HNI_LEAD_GENERATION,
  SME_LF,
  SME_CELL,
  BRANCH,
  COLLATE_THE_UNQUALIFIED_LEADS_DATA,
  REGIONAL_OFFICE_CONNECT,
} from "../../../../utils/config";

const ActivityDetailsCard = (props) => {
  const commonState = useSelector((state) => state);
  const activityTypes = commonState.commonReducer.Activities;
  const SRM_LIST = commonState.commonReducer.SRMList;
  const ZMA_LIST = commonState.commonReducer.ZMAList;
  const advisorList = commonState.commonReducer.AdvisorList;
  const PremierZMAList = commonState.commonReducer.PremierZMA;
  const visitToList = commonState.commonReducer.VisitTo;
  const recruitmentForList = commonState.commonReducer.RecruitmentFor;
  const EAPListForDetailCard = commonState.commonReducer.EAPList;
  //const Branches_List = commonState.commonReducer.Branches;

  const { activityData, branchCode, classes, Item } = props;
  if (activityData.channelid !== 19) {
    console.log(activityCorprteGroup);
  } else {
    console.log(activityTypes);
  }
  const activityOption = getOptionById(
    activityData.ActivityID ? activityData.ActivityID.toString() : "", activityData.ChannelID !== 19 ? activityTypes : activityCorprteGroup
  );

  //ZMA List
  const zmaArr = activityData.ZMAID?.split(",");
  const ZMA_LIST_NAME = ZMA_LIST?.filter(ele => zmaArr?.includes(ele.UserID?.toString())).map((el1) => {
    return el1.Name + " (" + el1.Code + ")"
  })

  const SRMArr = activityData.SRMID
  const SRM_LIST_NAME = SRM_LIST?.filter(ele => ele.value == SRMArr).map((el2) => {
    return el2.label + " (" + el2.key + ")"
  })

  //const BranchId = activityData?.BranchId
  //   const BRANCH_LIST_NAME = Branches_List?.filter(ele => ele.value == BranchId).map((el3)=>{
  //     return el3.label+" ("+el3.key+")"
  //  })

  const EAPArr = activityData.EAPIDs?.split(",")
  const EAP_LIST_NAME = EAPListForDetailCard?.filter(ele => EAPArr?.includes(ele.UserID.toString())).map((el4) => {
    return el4.Name + " (" + el4.Code + ")"
  })
  //Advoiser List

  const AdvisorIDArr = activityData.AdvisorID?.split(",");
  const Advisor_LIST_NAME = advisorList?.filter(ele => AdvisorIDArr?.includes(ele.UserID.toString())).map((el5) => {
    return el5.Name + " (" + el5.Code + ")"
  })

  //PremierZMAList
  //  if (PremierZMAList && PremierZMAList.length > 0) {
  const PremierZMAListArr = activityData.PremierZMAID?.split(",");
  const PremierZMAList_NAME = PremierZMAList?.filter(ele => PremierZMAListArr?.includes(ele.UserID.toString())).map((el6) => {
    return el6.Name + " (" + el6.Code + ")"
  })
  //}

  let getRecruitmentFor = recruitmentForList && recruitmentForList.filter(el7 => el7.value == activityData.RecruitmentFor)
  const visitToName = visitToList && visitToList?.filter(el9 => el9.value == activityData?.VisitToID)

  return (
    <Paper className={classes.paper2} sx={{ mb: 2 }}>
      <Grid className="activityDetailsSec" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Item className="activityDetailsHead">
                <b>Activity Details</b>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="listItemTextDiv" item xs={12} md={12}>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Activity Type</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.ActivityIdName}
                </Grid>
              </Grid>
            </ListItemText>

            <Divider />
            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Activity Name</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.ActivityName}
                </Grid>
              </Grid>
            </ListItemText>
            <Divider />
            {commonState.userReducer.profileInfo.channelid === 21 &&
              activityOption &&
              activityOption?.key === TEAM_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>BDM Code</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.BDMID}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />

                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>BDM Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.BDMName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {/* {activityData?.BranchName && <><ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Branch Name</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                {BRANCH_LIST_NAME?.length>0?BRANCH_LIST_NAME.toString():activityData.BranchName}
                </Grid>
              </Grid>
            </ListItemText>

            <Divider />
            </>
            } */}
            {/* broca activity */}
            {activityOption && activityOption.key === PARTNER_VISIT && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Mobile No</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Branch Category</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.BranchCategory}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Activity Planned</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.ActivityPlannedName}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }
            {activityOption && activityOption.key === PRODUCT_TRAINING && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Mobile No</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training Topic</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.TrainingTopic}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Product Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.ProductName}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of Attendees</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.NoOfAttendees}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }

            {activityOption && activityOption.key === BIRTHDAY_CELEBRATION && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Mobile No</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Birthday Person Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.Name}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Designation</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.OtherDesignation}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }

            {activityOption && activityOption.key === PRODUCT_LAUNCH && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Mobile No</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Launch Product name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.LaunchProductName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }
            {activityOption && activityOption.key === BRANCH_HEAD_MEET && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Mobile No</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Designation</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.OtherDesignation}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }

            {activityOption && activityOption.key === BRANCH_FOUNDATION_DAY && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Mobile No</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Foundation Date</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <label className="date">
                        {activityData.FoundationDate
                          ? moment(activityData.FoundationDate).format(
                            "DD MMM YYYY"
                          )
                          : ""}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }
            {activityOption && activityOption.key === JOINT_CALL_WITH_PARTNER && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Partner Mobile No</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PartnerMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Activity</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.JointCallActivityName}
                      {activityData.JointCallActivityName === "Other" ? <><label>Activity Name</label>
                        <label>{activityData.OtherJointCallActivity}</label></> : ""}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.JointCallActivityTypeName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }

            {activityOption && activityOption.key === LIVE_LEAD_GENERATION && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of Leads</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NoOfLeads}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Campaigning Done</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.CampaigningDoneName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }

            {activityOption && activityOption.key === BUSINESS_DEVELOPMENT_ACTIVITY && (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Name of the Prospect</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NameOfTheProspect}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Designation</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.DesignationName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Current Partner</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      <span>{activityData.CurrentPartner1}</span><br></br>
                      <span>{activityData.CurrentPartner2}</span><br></br>
                      <span>{activityData.CurrentPartner3}</span>
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Meeting planned with</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.MeetingPlannedWithName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Renewal Year(FY)</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.RenewalYear}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Last Assignment of</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.LastAssignmentOfName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Last Assignment place</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.LastAssignmentPlace}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Since when </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {/* {activityData.SinceWhen} */}
                      <label className="date">
                        {activityData.SinceWhen
                          ? moment(activityData.SinceWhen).format(
                            "DD MMM YYYY"
                          )
                          : ""}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Retirement Date </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {/* {activityData.RetirementDate} */}
                      <label className="date">
                        {activityData.RetirementDate
                          ? moment(activityData.RetirementDate).format(
                            "DD MMM YYYY"
                          )
                          : ""}
                      </label>
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Email ID</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.EmailID}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Contact Details</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.MobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Secretary Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.SecretaryName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Secretary Email ID</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.SecretaryEmailID}
                    </Grid>
                  </Grid>
                </ListItemText>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Secretary Contact</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.SecretaryMobileNo}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            )
            }
            {activityData?.BankRegionName && <><ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Bank Region Name</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.BankRegionName}
                </Grid>
              </Grid>
            </ListItemText>

              <Divider />
            </>
            }

            {activityData?.TrainingType && <><ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Type Of Training</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.TrainingType}
                </Grid>
              </Grid>
            </ListItemText>

              <Divider />
            </>
            }

            {activityOption && activityOption.key === ICE_E_SAMPARK ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Sub Activity Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ActivitySubTypeName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}

            {activityOption &&
              activityOption.key === JOINT_SALES_CALL_WITH_SRM ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    {/* <Grid item xs={5} md={5}>
                      <label>Bank Branch</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {BRANCH_LIST_NAME?.length>0?BRANCH_LIST_NAME.toString():activityData.BranchName}
                    </Grid> */}
                    <Grid item xs={5} md={5}>
                      <label>Select  SRM</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {SRM_LIST_NAME?.length > 0
                        ? SRM_LIST_NAME.toString()
                        : SRM_LIST.filter(
                          (ele) => activityData.SRMID == ele.value
                        )[0].label}
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <label>Meeting Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.FpmMeetingTypeName}
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <label>Remark</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.Remarks}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}

            {activityOption &&
              activityOption.key === TRAINING &&
              activityOption.value === "7" ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training For</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.TrainingForName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Review with</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ReviewWithName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MEETING_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Meeting with</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.MeetingWithName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MEETING_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>RAP/VLE</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.RAP_VLE}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === NEW_BUSINESS ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Activity done with </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ActivityDoneWithName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                {activityData.AdvisorID !== " " &&
                  activityData.ActivityDoneWith == "1" && (
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={5} md={5}>
                          <label>Advisor Name </label>
                        </Grid>
                        <Grid item xs={7} md={7} className="lastitem">
                          {Advisor_LIST_NAME?.length > 0
                            ? Advisor_LIST_NAME.toString()
                            : activityData.AdvisorName}
                        </Grid>
                      </Grid>
                    </ListItemText>
                  )}

                <Divider />
                {activityData.ZMAID &&
                  !activityData.AdvisorID &&
                  activityData.ActivityDoneWith === "2" && (
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={5} md={5}>
                          <label>ZMA Name </label>
                        </Grid>
                        <Grid item xs={7} md={7} className="lastitem">
                          {ZMA_LIST_NAME?.length > 0
                            ? ZMA_LIST_NAME.toString()
                            : activityData.ZMAName}
                        </Grid>
                      </Grid>
                    </ListItemText>

                  )}
                <Divider />
                {activityData.ActivityDoneWith === "3" && (
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={5} md={5}>
                        <label>Premier ZMA</label>
                      </Grid>
                      <Grid item xs={7} md={7} className="lastitem">

                        {PremierZMAList_NAME?.length > 0
                          ? PremierZMAList_NAME.toString()
                          : activityData.PremierName}
                      </Grid>
                    </Grid>
                  </ListItemText>
                )}
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
              activityOption.key === NEW_RECRUITMENT_ACTIVITY ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Recruitment For</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {
                        recruitmentForList.length !== 0 ?
                          getRecruitmentFor[0].label : " "
                      }
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                {recruitmentForList.length !== 0 && getRecruitmentFor[0].label == "Advisor for ZMA" && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>ZMA Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {ZMA_LIST_NAME?.length > 0
                        ? ZMA_LIST_NAME.toString()
                        : activityData.ZMAName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
              (activityOption.key === NEW_BUSINESS ||
                activityOption.key === NEW_RECRUITMENT_ACTIVITY) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of expected leads generated</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ExpectedLead}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of expected leads converted</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ExpectedConversion}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                {(activityOption.key === NEW_BUSINESS) &&
                  <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                    <Grid container spacing={4}>
                      <Grid item xs={5} md={5}>
                        <label>Ticket Size</label>
                      </Grid>
                      <Grid item xs={7} md={7} className="lastitem">
                        {activityData.TicketSize}
                      </Grid>
                    </Grid>
                  </ListItemText>
                }

                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
              activityOption.key === TRAINING &&
              activityOption.value === "12" ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training to ZMAs </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {ZMA_LIST_NAME?.length > 0
                        ? ZMA_LIST_NAME.toString()
                        : activityData.ZMAName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training to Advisors</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {Advisor_LIST_NAME?.length > 0
                        ? Advisor_LIST_NAME.toString()
                        : activityData.AdvisorName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}

            {(activityOption && activityOption.key === BRANCH_TRAINING) ||
              (activityOption && activityOption.key === TRAINING) ||
              (activityOption && activityOption.key === MONTHLY_REVIEW) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training Topic</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.TrainingTopic}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === REVIEW_MEETING ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Visit To</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {visitToName.length > 0 ? visitToName[0].label : ""}
                    </Grid>
                  </Grid>
                </ListItemText>
                {activityData.PremierZMAID && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Premier ZMA</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {PremierZMAList_NAME?.length > 0
                        ? PremierZMAList_NAME.toString()
                        : activityData.PremierName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
                {activityData.ZMAID && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>ZMA </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {ZMA_LIST_NAME?.length > 0
                        ? ZMA_LIST_NAME.toString()
                        : activityData.ZMAName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
                {activityData.AdvisorID && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Advisor Name </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {Advisor_LIST_NAME?.length > 0
                        ? Advisor_LIST_NAME.toString()
                        : activityData.AdvisorName}
                    </Grid>
                  </Grid>
                </ListItemText>}

                <Divider />

                {activityData.Premierzmaid && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Premier ZMA</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {PremierZMAList_NAME?.length > 0
                        ? PremierZMAList_NAME.toString()
                        : activityData.PremierName}

                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
                {activityData.EAPIDs && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>EAP</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {EAP_LIST_NAME?.length > 0 ? EAP_LIST_NAME.toString() : activityData.EAPName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
              (activityOption.key === BRANCH_VISIT ||
                activityOption.key === HNI_MEET ||
                activityOption.key === ICE_E_SAMPARK ||
                activityOption.key === BDF ||
                activityOption.key === BRANCH_TRAINING ||
                activityOption.key === JOINT_CALL_WITH_PARTNER ||
                activityOption.key === BRANCH_FOUNDATION_DAY ||
                activityOption.key === BRANCH_HEAD_MEET ||
                activityOption.key === PRODUCT_LAUNCH ||
                activityOption.key === BIRTHDAY_CELEBRATION ||
                activityOption.key === PRODUCT_TRAINING ||
                activityOption.key === PARTNER_BRANCH_VISIT_PLAN ||
                activityOption.key === PARTNER_VISIT ||
                activityOption.key === ICE_FOR_LOAN_ACTIVITY ||
                activityOption.key === HNI_LEAD_GENERATION ||
                activityOption.key === SME_LF ||
                activityOption.key === SME_CELL ||
                activityOption.key === BRANCH ||
                activityOption.key === COLLATE_THE_UNQUALIFIED_LEADS_DATA ||
                activityOption.key === REGIONAL_OFFICE_CONNECT ||
                activityOption.key === BOB_QLIK ) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                    Branch Name & Code
                      {/* <label>{commonState.userReducer.profileInfo.channelid === 19 || activityData.ChannelID == 19 ? "MPH Name" : "Branch Name & Code"}</label> */}
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {/* - ${activityData.CBLatLongId} */}
                      {commonState.userReducer.profileInfo.channelid === 19 || activityData.ChannelID == 19? `${activityData.Mph_name} ` :
                        activityData.BranchName ? `${activityData.BranchName} - ${branchCode}` : "NA"
                      }
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === CALLING_TO_VLE_RAP_DM ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of Calls Planned</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NoOfCallsPlanned}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
{/* { activityOption.key === BRANCH_TRAINING} */}
            {activityOption &&
              (activityOption.key === BRANCH_VISIT ||
                activityOption.key === HNI_MEET ||
                activityOption.key === ICE_E_SAMPARK ||
                activityOption.key === BDF ||
                activityOption.key === ICE_FOR_LOAN_ACTIVITY ||
                activityOption.key === HNI_LEAD_GENERATION ||
                activityOption.key === SME_LF ||
                activityOption.key === SME_CELL ||
                activityOption.key === BRANCH ||
                activityOption.key === COLLATE_THE_UNQUALIFIED_LEADS_DATA ||
                activityOption.key === REGIONAL_OFFICE_CONNECT || 
                activityOption.key === BOB_QLIK ) ? ( // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label># of Expected Leads</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.ExpectedLead ? activityData.ExpectedLead : "0"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label># of Expected Conversion</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.ExpectedConversion ? activityData.ExpectedConversion : "0"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Average Ticket Size:</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.TicketSize ?
                        `₹ ${activityData.TicketSize}` : "0"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />

              </>
            ) : (
              ""
            )}

            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>{activityOption?.key === PRODUCT_TRAINING ? "Training Date and Time" : "Meeting Date & Time"}</label>
                </Grid>
                {activityData.MeetingStartDateTime && (
                  <Grid item xs={7} md={7} className="lastitem">
                    <label className="date">
                      {activityData.MeetingStartDateTime
                        ? moment(activityData.MeetingStartDateTime).format(
                          "DD MMM YYYY"
                        )
                        : ""}
                    </label>
                    <br />
                    <label className="time">
                      {activityData.MeetingStartDateTime
                        ? moment(activityData.MeetingStartDateTime).format(
                          "h:mma"
                        )
                        : ""}
                      -
                      {activityData.MeetingEndDateTime
                        ? moment(
                          addMinuteToDate(activityData.MeetingEndDateTime)
                        ).format("h:mma")
                        : ""}
                    </label>
                    <label className="duration">
                      (
                      {getDateDuration(
                        activityData.MeetingStartDateTime,
                        addMinuteToDate(activityData.MeetingEndDateTime),
                        "HOUR"
                      )}
                      {getDateDuration(
                        activityData.MeetingStartDateTime,
                        addMinuteToDate(activityData.MeetingEndDateTime),
                        "MINUTE"
                      )}
                      )
                    </label>
                  </Grid>
                )}
                {activityData.MeetingStartDateTime == null && (
                  <Grid item xs={7} md={7} className="lastitem">
                    <b>NA</b>
                  </Grid>
                )}
              </Grid>
            </ListItemText>
            <Divider />
            {commonState.userReducer.profileInfo.channelid === 13 &&
              activityOption &&
              activityOption?.key === BRANCH_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.GCLActivityName
                        ? activityData.GCLActivityName
                        : "Not available"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption && activityOption.key === TRAINING) ||
              (activityOption && activityOption.key === MONTHLY_REVIEW) ||
              (activityOption && activityOption.key === MEETING_VISIT) ||
              (activityOption && activityOption.key === NEW_BUSINESS) ||
              (activityOption && activityOption.key === REVIEW_MEETING) ||
              (activityOption.key === LIVE_LEAD_GENERATION) ||
              activityOption.key === NEW_RECRUITMENT_ACTIVITY ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Location</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.Location}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
              activityOption.key === TRAINING &&
              activityOption.value === "7" ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Planned for no. of people</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PlannedForNoOfPeople}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MEETING_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Meeting Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.MeetingTypeName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No. of Policies planned</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NoOfPoliciesPlanned}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Average Ticket Size</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.AverageTicketSize &&
                        `₹ ${activityData.AverageTicketSize}`}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No. of RAP activation planned</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NoOfRAPActivationPlanned}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption && activityOption.key === MEETING_VISIT) ||
              activityOption.key === REVIEW_MEETING ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Remarks</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.Remarks}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption &&
              activityOption.key === TRAINING &&
              activityOption.value === "7") ||
              (activityOption && activityOption.key === MONTHLY_REVIEW) ||
              (activityOption && activityOption.key === MEETING_VISIT) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>State Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.StateName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption && activityOption.key === MONTHLY_REVIEW) ||
              (activityOption && activityOption.key === MEETING_VISIT) ||
              (activityOption.key === TRAINING &&
                activityOption.value === "7") ? (
              <>
                {activityData.DistrictName !== null && (
                  <>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={5} md={5}>
                          <label>District Name</label>
                        </Grid>
                        <Grid item xs={7} md={7} className="lastitem">
                          {activityData.DistrictName}
                        </Grid>
                      </Grid>
                    </ListItemText>
                    <Divider />
                  </>
                )}
              </>
            ) : (
              ""
            )}
            {(activityOption &&
              activityOption.key === TRAINING &&
              activityOption.value === "7") ||
              (activityOption && activityOption.key === MONTHLY_REVIEW) ||
              (activityOption && activityOption.key === MEETING_VISIT) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Address</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.Address}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
          </List>
        </Grid>
      </Grid>
    </Paper >
  );
};

export default ActivityDetailsCard;
