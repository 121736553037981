
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const CheckInHistory = (props) => {
    const { history } = props;
    const { StartLocation, LeadStatus, EndLocation } = history;

    return (
        <>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Check In</label>
                    <div style={{ display: 'flex' }}>
                        <label> {EndLocation} </label>
                        <span style={{ float: 'right' }}><LocationOnIcon /></span>
                    </div>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Check-In For State</label>
                    <label>
                        {LeadStatus}
                    </label>
                </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Start Location</label>
                    <div style={{ display: 'flex' }}>
                        <label>
                            {StartLocation}
                        </label>
                        <span style={{ float: 'right' }}><LocationOnIcon /></span>
                    </div>
                </TimelineContent>
            </TimelineItem>
        </>
    )
}

export default CheckInHistory
