export const reAssignLeadForm = [
  {
    key: "BranchID",
    type: "select",
    disabled: false,
    id: "BranchID",
    label: "Branch Name & Code",
    options: [],
    "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }]
  },
  {
    key: "BDM",
    type: "autocomplete",
    disabled: false,
    id: "BDM",
    label: "BDM",
    validators: [],
    options: [],
    hide:true,
},
];
