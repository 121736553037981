// import { useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import moment from "moment";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TimelineDot } from "@material-ui/lab";


const HistoryDetails = (props) => {
    // const styletimeline = {
    //     ':before': { content: 'none' },
    // }
    const { classes, Item, data } = props;

    return <Paper className={classes.paper2} sx={{ mb: 2 }}>
        <Grid container className="historyDiv">
            <Grid item xs={12} md={12} >
                <Grid container >
                    <Grid item xs={12} md={12}>
                        <Item className="activityDetailsHead"><b>History</b></Item>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} >
                    <Grid item xs={12} md={12} >
                        <div className="accBox">
                            {data?.Status =="Completed" ? <>
                                <Accordion >
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <TimelineDot className="customDot"></TimelineDot>
                                        <div className="accordianTitle">
                                            <span className="status">Completed On</span><br />
                                            <span>
                                                {data?.CompletedOn
                                                    ? moment(data.CompletedOn).format(
                                                        "DD MMM YYYY"
                                                    )
                                                    : "N/A"}{" "}
                                                <span className="content">
                                                    {data?.CompletedOn
                                                        ? moment(data?.CompletedOn).format("h:mma")
                                                        : " "}
                                                </span>
                                            </span>
                                        </div>
                                    </AccordionSummary>
                                </Accordion>
                            </> : ''}                          
                            {data?.Status =="Not-Completed" ? <>
                                <Accordion >
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <TimelineDot className="customDot"></TimelineDot>
                                        <div className="accordianTitle">
                                            <span className="status">Not-Completed On</span><br />
                                            <span>
                                                {data?.CompletedOn
                                                    ? moment(data.CompletedOn).format(
                                                        "DD MMM YYYY"
                                                    )
                                                    : "N/A"}{" "}
                                                <span className="content">
                                                    {data?.CompletedOn
                                                        ? moment(data?.CompletedOn).format("h:mma")
                                                        : " "}
                                                </span>
                                            </span>
                                        </div>
                                    </AccordionSummary>
                                </Accordion>
                            </> : ''}

                            {data?.TriggerdOn ? <>
                                <Accordion >
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <TimelineDot className="customDot"></TimelineDot>
                                        <div className="accordianTitle">
                                            <span className="status">Triggered On</span><br />
                                            <span>{data?.TriggerdOn
                                                ? moment(data?.TriggerdOn).format(
                                                    "DD MMM YYYY"
                                                )
                                                : "N/A"}{" "}
                                                <span className="content">
                                                    {data?.TriggerdOn
                                                        ? moment(data?.TriggerdOn).format("h:mma")
                                                        : " "
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    </AccordionSummary>
                                </Accordion>
                            </> : ''}

                        </div>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
        <div className="clearfix"></div>
    </Paper>
}
export default HistoryDetails;
