import React, { useState ,useEffect} from "react";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../draftListing/draftActivityLead.scss";
import DraftCard from './draftCard';
import AlertDialogBox from "../alertdialogbox/alertDialogBox";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import ModalPopup from "f1-modal-react";


export default function ActivityDraftListing({
    ListOfDrafts,
    TotalNoOfRows,
    NoOfRecordperPage,
    deleteActivityListByIdForDraft,
    fetchActivityTableDraftData,
    setNoOfDraftRecordperPage
}) {
    const commonState = useSelector(state => state);
    const userState = commonState.userReducer;
    const userViewFor = commonState.commonReducer.viewForStatus;


    const [UserId] = useState(userState.profileInfo.userId)
    const [Noofdays] = useState(3650)
    const draftActivityTypeId = "1,2,3,4,5,7,8,9,10,13,19";
    const draftActivityStatusId = "6,14,22,30";

    const loadMoreRecord = () => {
        const drafpayload = {
            "UserId": UserId,
            "PageNo": 1,
            "NoOfRecordperPage": NoOfRecordperPage + 5,
            "Noofdays": Noofdays,
            "CreatedBy": null,
            "Activitytypeid": draftActivityTypeId,
            "BranchId": 0,
            "Createddate": null,
            "MeetingDate": null,
            "ActivityStatusId": draftActivityStatusId,
            "Modifieddate": null,
            "IsBacklog": false
        }
        fetchActivityTableDraftData(drafpayload);
        setNoOfDraftRecordperPage(NoOfRecordperPage + 5)
    }

    const [isChecked, setIsChecked] = useState([]);
    const [selectDraftDelete, setSelectDraftDelete] = useState({ TrnActivityID: '', index: '' })
    const [showModal, setShowModal] = useState(false)

    const handleCheckboxChange = (val) => {
        setIsChecked(isChecked.map((v, i) => (i === val ? !v : v)));
    };
    const handleSelectAll = (e) => {
        const selectAll = ListOfDrafts.slice().fill(e.target.checked);
        setIsChecked(selectAll);
    };
    const deleteDraft = () => {
        setOpen(false);
        const TrnActivityIDArr = [];
        isChecked.map((v, i) => {
            if (v === true) {
                TrnActivityIDArr.push(ListOfDrafts[i].TrnActivityID);
            }
        });
        const { TrnActivityID, index } = selectDraftDelete
        if (TrnActivityID) {
            const selctDraft = isChecked.filter((v, i) => i !== index)
            setIsChecked(selctDraft)
            deleteActivityListByIdForDraft('activity', TrnActivityID);

        } else {
            if (TrnActivityIDArr.length > 0) {
                deleteActivityListByIdForDraft('activity', TrnActivityIDArr.join(','));
                setIsChecked(ListOfDrafts.slice().fill(false));
            } else {
                setShowModal(true)
            }
        }

    }
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setIsChecked(ListOfDrafts.slice().fill(false))
    }, [ListOfDrafts])

    return (
        <>
            <div className="draftSec">
                <Grid container spacing={3} className="topDummy">
                    <Grid item xs={8} md={6}>
                        <div className="selectBox">
                            <FormControlLabel
                                label="Select All"
                                control={<Checkbox onChange={(event) => {
                                    handleSelectAll(event);
                                }} />}
                            />
                        </div>
                        <div className="smsBox">
                            {/* <select>
                <option>Send SMS</option>
              </select> */}
                            <button disabled={userViewFor === 'SELF' ? "" : "disabled"} onClick={() => { setSelectDraftDelete({}); handleClickOpen() }}>
                                Delete <DeleteIcon />
                            </button>
                            <AlertDialogBox open={open}
                                handleClose={handleClose}
                                deleteDraft={deleteDraft}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <div className="rightPanel" style={{ float: "right" }}>
                            <span>
                                {" "}
                                {NoOfRecordperPage < TotalNoOfRows
                                    ? NoOfRecordperPage
                                    : TotalNoOfRows}{" "}
                            </span>
                            /
                            <span>
                                {TotalNoOfRows > 1
                                    ? `${TotalNoOfRows}${" "}${"Results"}`
                                    : `${TotalNoOfRows}${" "}${"Result"}`}
                            </span>
                        </div>
                    </Grid>
                </Grid>

                <div className="listingCard">
                    <Grid container spacing={3} textAlign="left">
                        {ListOfDrafts &&
                            ListOfDrafts.length > 0 &&
                            ListOfDrafts.map((draft, index) => (
                                <Grid className="gridItemDiv" item xs={12} md={6} key={draft?.id}>
                                    <DraftCard
                                        draft={draft}
                                        index={index}
                                        isChecked={isChecked}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleClickOpen={handleClickOpen}
                                        setSelectDraftDelete={setSelectDraftDelete}
                                        UserId={UserId}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                    {NoOfRecordperPage <= TotalNoOfRows ? (
                        <button className="btn loadMore" onClick={loadMoreRecord}>
                            Load More
                        </button>
                    ) : (
                        <div className='noMoreDiv'> No more records to load</div>
                    )}
                </div>
            </div>
            <ModalPopup
                show={showModal}
                size={"sm"}
                centered={true}
                modelHeader={"Invalid Selection"}
                modelContent={
                    <div className="footerButtons">
                        <p>Please select atleast one draft to delete</p>
                        <Button onClick={() => setShowModal(false)}>OK</Button>
                    </div>}
                backdrop={'static'}
            />
        </>
    );
}
