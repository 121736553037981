import React from "react";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
    BrocaSmaActivityForm,
    handleSmaActivitySelection,
    initFormData,
     getAutoCompleteItemsBranch,
  } from "./createSmaActivityFormBroca";
import {
  addDynamicData,
  getMasterData,
  uploadFileToBlob,
  activateSuccessMessageBar,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
  setBreadcrumbs,
  updateDynamicData,
  isfinishdayActivityDone,
  ActivityType,
  getAcitvityDuration
} from "../../../../redux/actions/common";
import { getActivityById, getActivityStatusMasterData, finishDayActivityCreate } from "../../../../redux/actions";
import { validateActivity, getUserCheckInCountforFinishDay } from "../../../../redux/actions/createActivityActions";
import {
  CONFIGS,
  STATUS_ACTIVITY_DRAFTED,
  STATUS_ACTIVITY_PLANNED,
  checkInConfig,
} from "../../../../utils/config";
import "./../createActivity.scss";
import { minusMinuteFromDate } from '../../../../utils/utils'
import { checkDateIsValid, isPositiveNumber } from "../../../../utils/validators";
import moment from "moment";
import { getOptionByCode, getSubTitles, getSubTitlesBroca } from "./../helper";
import SaveAsDraftDrawer from "./../saveAsDraftDrawer";
import {
  saveActivityOffline,
} from "../../../../offlineManager/activityOfflineManager";
import { v4 as uuidv4 } from "uuid";
// import { getBranchList } from "../../../../offlineManager/leadOfflineManager";
const _url = "/app/activity/smaActivities";
const _formatDate = "YYYY-MM-DDTHH:mm";
const selectOption = [{ "key": "Select", "label": "Select", "value": "" }]
const customValidators = [
  {
    key: "TravelExpene",
    validator: isPositiveNumber, // function to be called
    message: "Travel expense should be greater than 0", // message to show
  },
];

const createSalesMangementActivityTitle = "Create Sales Management Activity";

class CreateSmaActivityBroca extends React.Component {
  constructor() {
    super();
    this.dyFormRef = React.createRef();
    this.idleTimer = null;

    this.state = {
      current: {
        id: 1,
        MeetingStartDateTime: moment(new Date()).format(_formatDate),
        MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
        ABHName:"",
        ActivityDateTime: moment(new Date()).format(_formatDate),
        ABHDate : moment(new Date()).format("yyyy-MM-dd"),
      },
      formData: BrocaSmaActivityForm,
      autoCompleteItems: {
        BranchId: [],
      },
      originalFileName: "",
      extErrors: {},
      ExpectedConversion: "",
      ExpectedLead: "",
      isActiveMobileDraft: false,
      isFormSUbmitDisabled: false,
      channelId: "",
      optionList: {
        branchType: [],
        activityNameType: [],
        activityStatusType: [],
      },
      responseMessage: "",
      bdmName:[]
    };
  }

  getOptionDataBdm = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["UserID"],
        label: item["UserName"],
        value: item["UserID"],
      };
    });
    return [...selectOption, ...options];
  };

  getOptionDataBdmCode = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["UserID"],
        label: item["UserID"],
        value: item["UserID"],
      };
    });
    return [...selectOption, ...options];
  };

  loadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Sales Management", url: _url },
      { name: createSalesMangementActivityTitle, url: "/app/salesmanagement/create" },
    ];
    this.props.setBreadcrumbs(breadcrumbs);
  };

  isValidBusinessActivity = async (formData) => {
    if (
        formData.hasOwnProperty("MeetingStartDateTime") &&
        formData.hasOwnProperty("MeetingEndDateTime")
      ) {
        const checkActivity = await this.props.validateActivity({
          user_id: this.props.userState.profileInfo.userId,
          formData: formData,
        });
        if (
          checkActivity.status === 200 &&
          checkActivity.data.Responsestatus === true
        ) {
          return true;
        } else {
          this.setState({
            responseMessage: checkActivity.data.Responsemessage,
          });
        }
        return false;
      }
      return true;
  };
  getBranchName = (inputId) => {
    const collection = this.state.optionList["branchType"];
    if (inputId && collection) {
      const name = collection.filter(
        (el) => el.BranchID === inputId.toString()
      );
      if (name && name.length > 0) {
        return name[0].BranchName;
      }
      return null;
    }
    return null;
  };
  getActivityName = (inputId) => {
    const collection = this.state.formData;
    if (inputId && collection) {
      const name = collection[0].options.filter((el) => el.value === inputId.toString());
      if (name && name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };
  getActivityStatusName = (inputId) => {
    const collection = this.state.optionList["activityStatusType"];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name && name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };
  reset = (model) => {
    for (const [key] of Object.entries(model)) {
      model[key] = "";
    }
    this.setState({  originalFileName: "", extErrors: {} });
    this.dyFormRef.reset(model);
  };

  onSubmitHandler = async (model) => {
    if (
      !model.isValidForm ||
      Object.keys(this.state.extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = []
      const formD = this.state.formData
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1)
        }
      })
      const errorElement = document.getElementsByClassName(`${'control'}${errArr[0]}`)[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: "center",
        });
      }
      if (Object.keys(model.errors).length == 1 && !model.BranchId && !model.errors.hasOwnProperty('BranchIdlabel')) {
        this.props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
      if ((!model.isValidForm && Object.keys(model.errors).length > 0) || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
        this.props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }
    const isBranchNameLabel = this.props.masterDataState.Branches.some(ele => ele.value === model.BranchId);
    const isBranchName = this.props.masterDataState.Branches.filter(ele => ele.value === model.BranchId);
    if (model.BranchId !== '' && isBranchNameLabel || (model.ActivityID == 6)) {
      delete model.errors.BranchId;
    }
    else if (!isBranchNameLabel) {
      const extError = { ...this.state.extErrors, 'BranchId': 'Invalid BranchId' }

      this.setState({
        ...this.state,
        extErrors: extError
      })
    }
    if (
      !model.isValidForm ||
      (Object.keys(this.state.extErrors).length > 0 &&
        !this.state.extErrors.MobileNo) ||
      Object.keys(model.errors).length > 0 ||
      !isBranchNameLabel
    ) {
      const errList = Object.keys(this.state.extErrors)
      if (errList && errList.length > 0 && errList.includes('BranchId')) {
        const errorElement = document.getElementsByClassName(`control-BranchId`)[0];
        if (errorElement) {
          errorElement.scrollIntoView({
            block: 'center'
          });
        }
        return false;
      }
    }
    const formData = { ...model };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_PLANNED,
      this.props.masterDataState.ActivityStatus
    );
    const _format = "YYYY-MM-DD HH:mm:ss";
     formData["ActivityID"] = model.ActivityID ? model.ActivityID : null;
    const BDMName = this.state.bdmName.filter(val => val.UserID == formData['BDMID'])
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] =formData['BDMID'] ? formData['BDMID'] :  this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format(_format);
    formData["ModifiedDate"] = moment().format(_format);
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(_format);
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["ActivityStatus"] = this.getActivityStatusName(
      formData.ActivityStatusId
    );
    const activityName = this.getActivityName(formData.ActivityID);
    // if (formData.ActivityID == 55 ){
    //   formData["ActivityName"] = getSubTitlesBroca(activityName, formData?.BranchId, isBranchName, formData.MeetingStartDateTime);
    // } else {
    //   formData["ActivityName"] = getSubTitles(activityName, formData?.BranchId, undefined , formData.MeetingStartDateTime);
    // }
    formData["ActivityName"] = isBranchName.length > 0 ? getSubTitlesBroca(activityName, formData?.BranchId, isBranchName, formData.MeetingStartDateTime) : getSubTitles(activityName, formData?.BranchId, undefined , formData.MeetingStartDateTime)
    formData["AssignedToName"] = BDMName[0]?.UserName ? BDMName[0].UserName : `${this.props.userState.profileInfo.firstName
      }${" "}${this.props.userState.profileInfo.lastName}`;
    formData["IsBacklog"] = false;
    formData["CreatedBy"] = parseInt(this.props.userState.profileInfo.userId);
    formData["ModifiedBy"] = this.props.userState.profileInfo.userId
    formData["BDMID"] = model.BDMID ? model.BDMID : null;
    formData["BDMName"] = formData['BDMID'] ? formData['BDMID'] : null;
    formData["ActivityDateTime"] = model.ActivityDateTime ? model.ActivityDateTime.replace("T", ' ') : null;
    formData["ABHID"] = this.props.userState.profileInfo.userId

    if (navigator.onLine) {
      this.props.activateLoader();
      let isValidActivity = await this.isValidBusinessActivity(formData);
      if (isValidActivity) {
        const result = await this.props.addDynamicData({
            data: { ...formData, "IsBacklog": 0 },
            formName: CONFIGS.NEW_ACTIVITY_FORM,
          });

          if (result.status === 200 && result.data.Responsestatus === true) {
            this.props.activateSuccessMessageBar(
              "Activity has been created successfully."
            );
            this.props.deActivateLoader();
            const saveFormData = { ...formData };
                //  SAVE activity in indexDb online
                saveFormData["TrnActivityID"] = result.data.Transactionid;
                saveFormData["IsBacklog"] = false;
                saveActivityOffline(saveFormData).then((res) => {
                  if (res) {
                    this.reset(formData);
                    this.props.history.push(
                      `/app/salesmanagement/detail/${result.data.Transactionid}`
                    );
                  }
                });
              } else {
                this.props.deActivateLoader();
                this.props.activateSuccessMessageBar(
                  "Something went wrong! please try again later."
                );
              }
            }

     else {
        {
          this.props.activateErrorMessageBar(this.state.responseMessage);
        }
        this.props.deActivateLoader();
      }
    } else {
      saveActivityOffline(formData).then((res) => {
        if (res) {
          const {
            data: { key },
          } = res;
          this.props.history.push(`/app/salesmanagement/detail/${key}`);
        }
      });
    }
  };

  handleSaveAsDraft = async (e) => {
    e.preventDefault();
    this.props.activateLoader();
    const formData = { ...this.dyFormRef.state };
    const BDMName = this.state.bdmName.filter(val => val.UserID === formData['BDMID'])
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }
    const statusOption = getOptionByCode(
        STATUS_ACTIVITY_DRAFTED,
        this.props.masterDataState.ActivityStatus
      );
    formData["ActivityDateTime"] = formData["ActivityDateTime"].replace("T", ' ') ;
    formData["BDMName"] = formData['BDMID'] ? formData['BDMID'] : null;
    formData["ActivityID"] = formData['ActivityID'] ? formData['ActivityID'] : null;
    formData["ABHID"] = this.props.userState.profileInfo.userId;
    formData["BDMID"] =formData['BDMID'] ? formData['BDMID'] : null;
    formData["ModifiedBy"] = this.props.userState.profileInfo.userId;
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = formData['BDMID'] ? formData['BDMID'] : this.props.userState.profileInfo.userId;
    formData["LmsActivitylastSyncTime"] = moment().format(
      "YYYY-MM-DD HH:mm:ss"
    );
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["BankRegionName"] = formData?.bankRegion ? formData.bankRegion : null;
    formData["AssignedToName"] = BDMName[0]?.UserName ? BDMName[0].UserName :`${this.props.userState.profileInfo.firstName
    }${" "}${this.props.userState.profileInfo.lastName}`;
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["CreatedDate"] = moment(new Date()).format(_format);
    formData["ModifiedDate"] = moment(new Date()).format(_format);
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(
      "YYYY-MM-DD HH:mm:ss"
    );
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["ActivityStatus"] = this.getActivityStatusName(
      formData.ActivityStatusId
    );
    formData["IsBacklog"] = false;
    if (formData.ActivityID == 6) {
      formData['IsClaimRequired'] = false
    }
    if (navigator.onLine) {
      const result = await this.props.addDynamicData({
        data: { ...formData, IsBacklog: 0 },
        formName: CONFIGS.NEW_ACTIVITY_FORM,
        CreatedBy: this.props.userState.profileInfo.userId,
      });
      if (result.status === 200 && result.data.Responsestatus === true) {
        this.props.activateSuccessMessageBar(
          "Activity has been drafted successfully."
        );
        this.reset(formData);
        this.props.deActivateLoader();
        saveActivityOffline(formData);
        this.props.history.push("/app/activity/smaActivities");
      } else {
        this.props.deActivateLoader();
        this.props.activateSuccessMessageBar(
          "Something went wrong! please try again later."
        );
      }
    } else {
      this.props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          this.props.history.push(`/app/activity/smaActivities`);
        }
      });
    }
  };

  componentDidMount = () => {
    this.loadBreadCrumbs();

    const BDMList = this.props.leadFormReducer.BDMList.UsersDetails;
    const abhUserName = `${this.props.userState.profileInfo.firstName}${" "}${this.props.userState.profileInfo.lastName}`;
    const formData = this.props.masterDataState
      ? initFormData(this.state.formData, this.props.masterDataState, this.props.userState.profileInfo.roleId,this.props.userState.profileInfo.channelid)
      : this.state.formData;
    const autoCompleteItems = { ...this.state.autoCompleteItems };
    autoCompleteItems.BranchId = this.props.masterDataState
      ? getAutoCompleteItemsBranch(
        this.props.masterDataState,
        CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME
      )
      : [];
      const curr ={...this.state.current}
      curr.ABHName=abhUserName
    this.setState(
      {
        formData: formData,
        autoCompleteItems: autoCompleteItems,
        channelId: this.props.userState.profileInfo.channelid,
        BDMList: this.getOptionDataBdm(BDMList),
        BDMCode:this.getOptionDataBdmCode(BDMList),
        bdmName:BDMList,
        current:curr
      },
      () => { }
    );
  };

  onChangeHandler = async (event, key, model) => {
    delete model.errors["BDMName"]
    if (key === "ActivityID") {
        model.errors ={}
        this.setState({ current: { ...this.state.current, ActivityID: event.target.value } })
      }
    if(key==="BDMID"){

        const bdmCode =parseInt(event.target.value)
        const BDMList = this.props.leadFormReducer.BDMList.UsersDetails;
        const bdmName=BDMList?.filter((item)=>item.UserID == bdmCode)
     this.setState({ current: { ...this.state.current, BDMName:  bdmName[0].UserName } })
     }
      if(key==="BranchId"){
        const isBranchNameLabel = this.props.masterDataState.Branches.some(ele => ele.value === event.target?.value);
        delete model.errors["BranchIdlabel"]
        if (!isBranchNameLabel) {
          const extError = { ...this.state.extErrors, 'BranchId': 'Invalid BranchId' }
          this.setState({
            ...this.state,
            extErrors: extError
          })
        }else{
          delete this.state.extErrors["BranchId"]
          // this.setState({ extErrors: {} })
        this.setState({
          current: { ...this.state.current, BranchId: event.target.value }
        });
      }
      }
    if (key === "MeetingStartDateTime") {
      //const copyFormData = this.state.formData
      var StartDate = moment(event.target.value).format(_formatDate);
      const newDate = moment(event.target.value).add(30, "m").format(_formatDate);
      this.state.formData.map((option) => {
          if (option.key === "MeetingEndDateTime") {
            option.max = moment(StartDate).endOf('day').format("YYYY-MM-DD kk:mm");
            option.min = StartDate;
          }
        })
      if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, [key]: "please select future value" }
        })
      } else {
        delete this.state.extErrors?.MeetingStartDateTime;
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors },
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingStartDateTime: StartDate, MeetingEndDateTime: newDate },
      });

    }
    if (key === "MeetingEndDateTime") {
      const newDate = moment(event.target.value).format(_formatDate);
      if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
        this.setState({
          ...this.state,
          extErrors: { ...this.state.extErrors,
            "MeetingEndDateTime": "meeting end date should not less than meeting starting date" },
        })
      } else {
        delete this.state.extErrors.MeetingEndDateTime;
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors },
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingEndDateTime: newDate },
      });
    }

    const formData = handleSmaActivitySelection(
      event,
      key,
      this.state.formData,
      this.props.masterDataState,
      this.state.BDMCode,
      this.props.userState.profileInfo.roleId,
    );
    this.setState({ formData: formData });
  };

  onAutocompleteSelectHandler = (key, value, item) => {
    if (key === "BranchId") {
      this.setState({
        current: { ...this.state.current, BranchId: item.id, BranchIdlabel: item.label }
      });
    }
  };

  onCancelHandler = () => {
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      this.setState({ isActiveMobileDraft: true });
    } else {
      this.props.history.push(_url);
    }
  };

  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
  };

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => this.removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  toggleDrawer = (flag) => {
    this.setState({ isActiveMobileDraft: flag });
  };

  onDontSaveDraftHandler = () => {
    this.props.history.push(_url);
  };

  onSaveDraftHandler = () => {
    this.handleSaveAsDraft(new Event("test"));
  };
  handleOnAction = (event) => {
    if (this.state.timeOutMsg) {
      setTimeout(() => {
        this.props.history.push("/app/home")
      }, 3000)
    }
  }
  handleOnActive = (event) => {
    if (this.idleTimer.getRemainingTime() === 0) {
      this.setState({
        ...this.state, timeOutMsg: this.idleTimer.getRemainingTime() === 0
      })
    }
  }
  handleOnIdle = (event) => {
  }

  render() {
    const { t } = this.props;
    return (
      <div className="mainContainer createActivity">
        <Breadcrumb></Breadcrumb>
        {/* <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 1}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Snackbar open={this.state.timeOutMsg} autoHideDuration={3000}>
          <Alert severity="error">
            Your session has been expired! You will be redirected to homepage in
            3 seconds.
          </Alert>
        </Snackbar> */}
        <span className="formInfo">
          {t("Please fill in the SMA activity information")}
        </span>
        <div className="formBox">
          <DynamicForm
            key={this.state.current.id}
            onRef={(ref) => (this.dyFormRef = ref)}
            title={t(createSalesMangementActivityTitle)}
            buttonSubmit={t("Submit")}
            defaultValues={this.state.current}
            model={this.state.formData}
            onSubmit={(model) => {
              this.onSubmitHandler(model);
            }}
            disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
            onAutocompleteSelect={this.onAutocompleteSelectHandler}
            autoCompleteItems={this.state.autoCompleteItems}
            showAstersikOnRequiredFields={true}
            extErrors={this.state.extErrors}
            checkInConfig={checkInConfig}
            className="activityTypeDiv"
            extaraActionFormButton={
              <>
                <a
                  className="savedraftLink"
                  onClick={(e) => this.handleSaveAsDraft(e)}
                  href="#"
                >
                  SAVE AS DRAFT
                </a>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={this.onCancelHandler}
                >
                  {t("Cancel")}
                </button>
              </>
            }
            customValidators={customValidators}
          />
        </div>
        <SaveAsDraftDrawer
          isActiveMobileDraft={this.state.isActiveMobileDraft}
          toggleDrawer={this.toggleDrawer}
          onDontSaveHandler={this.onDontSaveDraftHandler}
          onSaveDraftHandler={this.onSaveDraftHandler}
        ></SaveAsDraftDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  leadFormReducer:state.leadFormReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  finishDayActivityCreate: (payload) => dispatch(finishDayActivityCreate(payload)),
  getActivityById: (payload) => dispatch(getActivityById(payload)),
  getActivityStatusMasterData: (payload) => dispatch(getActivityStatusMasterData(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  isfinishdayActivityDone: (payload) => dispatch(isfinishdayActivityDone(payload)),
  getUserCheckInCountforFinishDay: (payload) => dispatch(getUserCheckInCountforFinishDay(payload)),
  ActivityType: (payload) => dispatch(ActivityType(payload)),
  getAcitvityDuration: (payload) => dispatch(getAcitvityDuration(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(CreateSmaActivityBroca)
);
