let MODE = 'MODE';

const initialState = {
    createActivity: true
}

function createActivityReducer(state=initialState, action){
    switch (action.type){
        case 'createActivity':  
            return{
                ...state, 
                createActivity: action.payload
            };
        default:
            return state;
    }
}

export default createActivityReducer;