import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PrimaryDetailsCard from "../activity/activityDetails/primaryDetailsCard";
import CheckInCard from "../activity/activityDetails/checkInCard";
import ActivityDetailsCard from "../activity/activityDetails/activityDetailsCard";
import HistoryCard from "../activity/activityDetails/historyCard";
import {
  ALLOWED_CHECK_IN_ACTIVITY,
  STATUS_ACTIVITY_CANCELLED,
  STATUS_ACTIVITY_COMPLETED,
  FINISH_DAY_ACTIVITY,
  CIRCULAR_FOR_SALES_ENABLEMENT_ABH_CHECK
} from "../../../utils/config";
import { getMobileOS, isUserSame } from "../../../utils/utils";
import { getOptionById } from "../activity/helper";
import {
  setSalesManagementActivity,
  setSalesManagementCancel,
  setSalesManagementComplete,
  setSalesManagementUpdate,
} from "../../../redux/actions";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DetailLayout = (props) => {
  const history = useHistory();
  const commonState = useSelector((select) => select);
  const ActivityDetails = commonState.commonReducer.ActivityStatus;
  const activityStatusTypes = commonState.commonReducer.ActivityStatus;
  const { activityData, activityTitle, branchCode, activityHistoryData } = props;
  const activityStatusOption = getOptionById(
    activityData.ActivityStatusId
      ? activityData.ActivityStatusId.toString()
      : "",
    activityStatusTypes
  );
  const [state, setState] = useState({});
  const isDisabled =
    activityStatusOption?.key === STATUS_ACTIVITY_CANCELLED ||
    activityStatusOption?.key === STATUS_ACTIVITY_COMPLETED;
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userId = JSON.parse(userData.userReducer).profileInfo.userId;
  const isMobileOS = getMobileOS()
  const userState = commonState.userReducer;
  const goToActivity = (type) => {
    dispatch(setSalesManagementActivity(type, activityData));
    if (type === "ACTIVITY_CANCELLED") {
      dispatch(setSalesManagementCancel());
    } else if (type === "ACTIVITY_RESCHEDULED") {
      dispatch(setSalesManagementUpdate());
    } else if (type === "ACTIVITY_COMPLETED") {
      dispatch(setSalesManagementComplete());
    }
    if (userState.profileInfo.channelid === 21) {
      history.push(`/app/broca/salesmanagement/update/${activityData.TrnActivityID}`);

    }
    else {
      history.push(`/app/salesmanagement/update/${activityData.TrnActivityID}`);
    }

  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="updatePopupDiv">
        <ListItem
          color="black"
          sx={{ fontWeight: "bold", fontSize: 18 }}
          style={{}}
        >
          {" "}
          Update Activity
          <CloseIcon />
        </ListItem>
        {ActivityDetails.map((item) => {
          return (
            <>
              {item.value != "" &&
                item.value != "1" &&
                item.value != "5" &&
                item.value != "6" &&
                item.value != "7" &&
                item.value != "33" &&
                item.value != "38" &&
                item.value != "39" && (
                  <ListItem
                    disabled={isDisabled}
                    className={isDisabled ? "disabled" : ""}
                    button
                    onClick={() => goToActivity(item.key)}
                  >
                    <ListItemIcon>
                      <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{ mr: 2 }}
                      >
                        <PendingActionsIcon />
                      </Fab>{" "}
                      {item.label}
                    </ListItemIcon>
                  </ListItem>
                )}
            </>
          );
        })}
      </List>
      <Divider />
    </Box>
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: "left",
      color: theme.palette.text.secondary,
      //height: '100%',
      padding: theme.spacing(2),
    },
    paper2: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
      // height: '300px',
    },
    activityLogo: {
      //  borderRadius: 10,
      textAlign: "center",
      backgroundColor: "rgb(0 66 126 / 27%)",
      height: "50px",
      padding: theme.spacing(2),
    },
    heading: {
      padding: "1px 2px 2px 1px",
    },
  }));
  const classes = useStyles();
  const getHistory = () => {
    props.getActivityHistory()
    props.loadData();
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <PrimaryDetailsCard
              activityData={activityData}
              activityTitle={activityTitle}
              classes={classes}
            ></PrimaryDetailsCard>
            {!ALLOWED_CHECK_IN_ACTIVITY.includes(activityData.ActivityStatus) &&
              !FINISH_DAY_ACTIVITY.includes(activityTitle) &&
              !CIRCULAR_FOR_SALES_ENABLEMENT_ABH_CHECK.includes(activityData.ActivityID) && isUserSame(activityData.CreatedBy, userId) &&
              isMobileOS !== "Other" && (
                <CheckInCard
                  classes={classes}
                  statusId={props.activityData.ActivityStatusId}
                  CheckinCount={props.activityData.CheckinCount}
                  onGetUpdatedHistory={getHistory}
                ></CheckInCard>
              )}
          </Grid>

          <Grid item xs={12} md={4}>
            <ActivityDetailsCard
              activityData={activityData}
              activityTitle={activityTitle}
              branchCode = {branchCode}
              classes={classes}
              Item={Item}
            ></ActivityDetailsCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <HistoryCard
              classes={classes}
              activityHistoryData={activityHistoryData}
              activityTitle={activityTitle}
              Item={Item}
            ></HistoryCard>
          </Grid>
        </Grid>
      </Box>
      <span>
        {["bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            {(!ALLOWED_CHECK_IN_ACTIVITY.includes(activityData.ActivityStatus) &&
              isUserSame(activityData.CreatedBy, userId) && !FINISH_DAY_ACTIVITY.includes(activityTitle)) &&
              <Box className="editBtn" sx={{ '& > :not(style)': { m: 1 } }} onClick={toggleDrawer(anchor, true)} >
                <Fab color="primary" aria-label="add" sx={{ float: 'right' }}>
                  <EditIcon />
                </Fab>
              </Box>
            }
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </span>
    </>
  );
};

export default DetailLayout;
