import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { t } from "i18next";
import { useSelector, useDispatch, connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
  activateErrorMessageBar,
  getSubordinateList,
  getMasterDataForAdmin,
  getCampaignName,
} from "../../../redux/actions";
import {
  campaignLeadFilterForm,
  customValidators,
} from "./campaignLeadGridFilterForm";
import { ROLE_ABH, ADMIN_HIERARCHY_TYPE } from "../../../utils/config";
import {
  NHList,
  activateLoader,
  deActivateLoader,
} from "../../../redux/actions/common";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopFilter: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

  const CampaignLeadFilter = (props) =>
  {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userState = useSelector((select) => select.userReducer);
  const UserId = userState?.profileInfo?.userId;
  const FullName = `${userState?.profileInfo?.firstName} ${userState?.profileInfo?.lastName}`;
  const [UserFullName] = useState(FullName);
  const [UserID, setUserID] = useState();
  const [NhUsers, setNhUsers] = useState([]);

  const roleId = userState?.profileInfo?.roleId;
  const userName = `${
    JSON.parse(userData.userReducer).profileInfo.firstName
  } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
  const { leadStatus } = useSelector((selectStatus) => selectStatus.leadFormReducer);

  const [newUserList, setNewUserList] = useState([
    {
      key: UserId,
      label: UserFullName,
      value: UserId,
    },
  ]);
  const [productDetails, setProductDetails] = useState([]);
  const [campaignName, setCampaignName] = useState([]);
  const [state, setState] = useState({
    current: { id: 1 },
    formData: campaignLeadFilterForm,
    autoCompleteItems: {
      product: [],
    },
  });
  const dynamicRef = React.useRef();
let flag;
  const getDropdownOFData = (optionsArr, id, label) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { id: `${option[id]}`, label: `${option[label]}` };
      });
    }
    arr.shift({ id: " ", label: "select" });
    arr.unshift({ id: " ", label: "select" });
    return arr;
  };
  useEffect(() => {
    const getProductIdChannelId = async () => {
      const response = await props.getMasterDataForAdmin({
        tableName: "ProductDetails",
        config: {
          TABLE_NAME: "ProductDetails",
          KEY: "ProductId",
          LABEL: "ProductName",
          CODE: "ProductId",
          CHANNEL_ID: "",
          FIELD_NAME: "ProductId",
        },
      });
      const data = response.data.data.ProductDetails;
      setProductDetails(data);
      console.log("products  response ", response);
      console.log("current Status ", productDetails);
    };
    if (!productDetails.length) {
      getProductIdChannelId();
    } else if (productDetails.length) {
      const autoCompleteItems = { ...state.autoCompleteItems };
      autoCompleteItems.product = getDropdownOFData(
        productDetails,
        "key",
        "label"
      );
      const formData = initFormData(state.formData);
      setState({ formData: formData, autoCompleteItems: autoCompleteItems });
      console.log("current Status is ", productDetails);
    }
  }, [productDetails]);

  const getcampaign = async () => {
    const result = await props.getCampaignName({
      ChannelId: props.chanelId,
      CampaignNameFor: "CampaignLead",
    });
    console.log("campaign name", result);
    const r = result.data.map((el) => {
      return {
        key: el.CampaignName,
        label: el.CampaignName,
        value: el.CampaignName,
      };
    });
    setCampaignName([...campaignName, ...r]);
    campaignLeadFilterForm[20].options = [...campaignName, ...r];
  };

  useEffect(() => {
    NhUsers.push({ key: "Select", label: "Select", value: "Select" });
    getNhList();
    campaignLeadFilterForm[1].hide = true;
    campaignLeadFilterForm[2].hide = true;
    campaignLeadFilterForm[3].hide = true;
    campaignLeadFilterForm[4].hide = true;
    campaignLeadFilterForm[5].hide = true;
    campaignLeadFilterForm[6].hide = true;
    campaignLeadFilterForm[7].hide = true;
    campaignLeadFilterForm[10].hide = true;
    campaignLeadFilterForm[11].hide = true;
    campaignLeadFilterForm[14].hide = true;
    campaignLeadFilterForm[15].hide = true;
    campaignName.push({ key: "Select", label: "Select", value: "Select" });
    getcampaign();
  }, []);
  useEffect(() => {
    if (roleId === ROLE_ABH) {
      state.formData[0].hide = false;
    }
  }, [roleId]);

  useEffect(() => {
    console.log(UserFullName);
    props.setFilterState({
      UserName: UserFullName,
    });
  }, [UserFullName]);

  const getSub = async (key, getUser) => {
    dispatch(getSubordinateList({ userId: getUser })).then((response) => {
      const r = response.data.map((el) => {
        return {
          key: el.userID,
          label: el.userName,
          value: el.userID,
        };
      });
      setNewUserList([newUserList, ...r]);

      if (key === "User") {
        campaignLeadFilterForm[4].options = [newUserList, ...r];
      } else if (key === "UserOne") {
        campaignLeadFilterForm[5].options = [newUserList, ...r];
      } else if (key === "UserTwo") {
        campaignLeadFilterForm[6].options = [newUserList, ...r];
      } else if (key === "UserThree") {
        campaignLeadFilterForm[7].options = [newUserList, ...r];
      }
      props.deActivateLoader();
    });
  };

  const getNhList = async () => {
    const result = await props.NHList({ channelId: props.chanelId });
    console.log("nh users are", result.data);
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setNhUsers([...NhUsers, ...r]);
    campaignLeadFilterForm[3].options = [...NhUsers, ...r];
  };

  const onSubmitHandler = (model) => {
    if (model.LeadName === "") {
      model.LeadName = null;
    }
    if (model.CampaignName === "") {
      model.CampaignName = null;
    }
    if (model.CreatedDateTime === 0) {
      model.CreatedDateTime = null;
    }
    if (model.MeetingDate === 0) {
      model.MeetingDate = null;
    }
    if (model.MobileNo === "") {
      model.MobileNo = null;
    }

    if (model.teamMember === 0 || model.teamMember === undefined) {
      model.teamMember = null;
    }
    if (model.MeetingDate === "null") {
      model.MeetingDate = null;
    }
    if (model.CreatedDateStartTIme === "null") {
      model.CreatedDateStartTIme = null;
    }
    if (model.CreatedDateEndTIme === "null") {
      model.CreatedDateEndTIme = null;
    }
    if (model.fromdate === "null") {
      model.fromdate = null;
    }
    if (model.todate === "null") {
      model.todate = null;
    }
    if (model.activityID === "") {
      model.activityID = null;
    }
    if (model.branchNameCode === "") {
      model.branchNameCode = null;
    }
    if (model.EmployeeCode === "") {
      model.EmployeeCode = null;
    }

    const status = model.campaigncurrentStatus?.map((val) => {
      return val.value;
    });
    model.currentStatus = status?.join(",");

    if (model.currentStatus === "") {
      model.currentStatus = null;
    }
    const {
      CreatedDateEndTIme,
      LeadName,
      CampaignName,
      CreatedDateTime,
      CreatedDateStartTIme,
      MeetingOn,
      currentStatus,
      product,
      activityID,
      MeetingDate,
      fromdate,
      todate,
      MobileNo,
      teamMember,
      branchNameCode,
      EmployeeCode
    } = model;

    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    if (
      MeetingDate !== null &&
      MeetingDate !== "duration" &&
      MeetingDate !== undefined
    ) {
      const lastdatearr = model.MeetingDate.split(",");
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (
      CreatedDateTime !== null &&
      CreatedDateTime !== "duration" &&
      CreatedDateTime !== undefined
    ) {
      const lastdatearr = model.CreatedDateTime.split(",");
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (MeetingDate === "duration") {
      if (fromdate === null || todate === null) {
        dispatch(
          activateErrorMessageBar(
            "Please Select Value For Both Meeting Start Date and End Date"
          )
        );
        return false;
      }
      meetingstart = model.fromdate;
      meetingend = model.todate;
    }
    if (CreatedDateTime === "duration") {
      if (CreatedDateStartTIme === null || CreatedDateEndTIme === null) {
        dispatch(
          activateErrorMessageBar(
            "Please Select Value For Both Created Start Date and End Date"
          )
        );
        return false;
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme;
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;

    model.CreatedDateStartTIme = createdstart;
    model.CreatedDateEndTIme = createdend;
    if (
      CreatedDateTime !== null ||
      LeadName !== null ||
      CampaignName !== null ||
      product !== 0 ||
      MeetingOn !== null ||
      currentStatus !== null ||
      activityID !== null ||
      MeetingDate !== null ||
      MobileNo !== null ||
      fromdate !== null ||
      todate !== null ||
      teamMember !== null ||
      branchNameCode !== null||
      UserID !== null ||
      EmployeeCode !== null
    ) {
      props.setFilterState({
        ...props.filterState,
        LeadName: LeadName,
        CampaignName: CampaignName,
        CreatedDateTime: CreatedDateTime,
        CreatedDateStartTIme: CreatedDateStartTIme,
        CreatedDateEndTIme: CreatedDateEndTIme,
        MeetingOn: null,
        LastUpdateDateTime: null,
        currentStatus: currentStatus,
        product: product,
        activityID: activityID,
        MeetingDate: MeetingDate,
        fromdate: fromdate,
        todate: todate,
        MobileNo: MobileNo,
        branchNameCode: branchNameCode,
        EmployeeCode: EmployeeCode,
        UserID: UserID,
        campaigncurrentStatus: model?.campaigncurrentStatus ? model.campaigncurrentStatus : []
      });
      props.getFilteredLeads(model);
      props.handleClose();
    } else {
      dispatch(activateErrorMessageBar("Please select anyone to apply filter"));
    }
  };
  const onResetHandler = () => {
    props.setFilterState({
      ...props.filterState,
      LeadName: "",
      CampaignName: "",
      CreatedDateTime: 0,
      CreatedDateStartTIme: "null",
      CreatedDateEndTIme: "null",
      MeetingOn: null,
      branchNameCode: "",
      LastUpdateDateTime: null,
      currentStatus: "",
      campaigncurrentStatus: [],
      product: 0,
      productlabel: "",
      activityID: "",
      MeetingDate: "null",
      fromdate: "null",
      todate: "null",
      MobileNo: "",
      UserID: null,
      NhUsers: [],
      newUserList: [],
      teamMember: null,
      User: null,
      UserOne: null,
      UserTwo: null,
      UserThree: null,
      UserFour: null,
      UserCode: null,
      EmployeeCode:null,
    });
    campaignLeadFilterForm[1].hide = true;
    campaignLeadFilterForm[2].hide = true;
    campaignLeadFilterForm[3].hide = true;
    campaignLeadFilterForm[4].hide = true;
    campaignLeadFilterForm[5].hide = true;
    campaignLeadFilterForm[6].hide = true;
    campaignLeadFilterForm[7].hide = true;
    campaignLeadFilterForm[10].hide = true;
    campaignLeadFilterForm[11].hide = true;
    campaignLeadFilterForm[14].hide = true;
    campaignLeadFilterForm[15].hide = true;
    props.getAllLeads();
  };
  const onChangeHandler = async (e, key,model) => {
    if (
      key === "User" ||
      key === "UserOne" ||
      key === "UserTwo" ||
      key === "UserThree" ||
      key === "UserFour" 
    ) {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "EmployeeCode") {
      props.setFilterState({
        ...props.filterState,
        EmployeeCode: e.target.value,
        UserID:0,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "LeadName") {
      props.setFilterState({
        ...props.filterState,
        LeadName: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }

    if (key === "branchNameCode") {
      props.setFilterState({
        ...props.filterState,
        branchNameCode: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "activityID") {
      props.setFilterState({
        ...props.filterState,
        activityID: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "CampaignName") {
      props.setFilterState({
        ...props.filterState,
        CampaignName: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "MobileNo") {
      props.setFilterState({
        ...props.filterState,
        MobileNo: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "CreatedDateTime") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateTime: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
      if (e.target.value === "duration") {
        campaignLeadFilterForm[10].hide = false;
        campaignLeadFilterForm[11].hide = false;
      } else {
        campaignLeadFilterForm[10].hide = true;
        campaignLeadFilterForm[11].hide = true;
      }
    }
    if (key === "CreatedDateStartTIme") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateStartTIme: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
      campaignLeadFilterForm[11].min = e.target.value;
    }
    if (key === "CreatedDateEndTIme") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateEndTIme: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "currentStatus") {
      props.setFilterState({
        ...props.filterState,
        currentStatus: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "campaigncurrentStatus") {
      let keys = Object.keys(e.currentTarget);
      let selectedValue = e.currentTarget[keys[1]].children;
      let selectedevent = e.currentTarget[keys[1]]["data-testid"];
      let clearevent = e.currentTarget[keys[1]]["aria-label"];


      if (selectedevent === "CancelIcon" || clearevent ==="Clear" ) {
        flag = 1;
        if(clearevent ==="Clear"){
          props.setFilterState({ ...props.filterState, campaigncurrentStatus: [] })
        }
      }else {
        if (typeof (selectedValue) != "object" && selectedValue !== "select" ) {
          let filter_Status = LEAD_STATUS.find((item) => item.label == selectedValue)
          if (model?.campaigncurrentStatus.length > 0) {
            let arr = model?.campaigncurrentStatus.find((item) => item.label == selectedValue)
            if (arr === undefined) {
              model?.campaigncurrentStatus.push(filter_Status)
            }
          } else {
            model?.campaigncurrentStatus.push(filter_Status)
          }
          props.setFilterState({ ...props.filterState, campaigncurrentStatus: model?.campaigncurrentStatus })
        }
      }
    }
    // if (key !== "campaigncurrentStatus" && flag == 1 ) {
    //   props.setFilterState({
    //     ...props.filterState,
    //     campaigncurrentStatus: model?.campaigncurrentStatus,
    //   });
    //       flag = 0;
    // }
  
    if (key === "MeetingDate") {
      props.setFilterState({
        ...props.filterState,
        MeetingOn: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
      if (e.target.value === "duration") {
        campaignLeadFilterForm[14].hide = false;
        campaignLeadFilterForm[15].hide = false;
      } else {
        campaignLeadFilterForm[14].hide = true;
        campaignLeadFilterForm[15].hide = true;
      }
    }
    if (key === "LastUpdateDateTime") {
      props.setFilterState({
        ...props.filterState,
        LastUpdateDateTime: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }

    if (key === "product") {
      const productNameValue = productDetails.filter((el) => el.key === parseInt(e.target.value))
      props.setFilterState({
        ...props.filterState,
        product: e.target.value,
        productlabel: productNameValue[0]?.label,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (key === "MeetingDate") {
      props.setFilterState({
        ...props.filterState,
        MeetingDate: e.target.value,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
      if (e.target.value === "duration") {
        campaignLeadFilterForm[14].hide = false;
        campaignLeadFilterForm[15].hide = false;
      } else {
        campaignLeadFilterForm[14].hide = true;
        campaignLeadFilterForm[15].hide = true;
      }
    }

    if (key === "fromdate") {
      const fromDate = e.target.value;
      props.setFilterState({
        ...props.filterState,
        fromdate: fromDate,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
      campaignLeadFilterForm[15].min = e.target.value;
    }
    if (key === "todate") {
      const todate = e.target.value;
      props.setFilterState({
        ...props.filterState,
        todate: todate,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
    }
    if (userState.profileInfo.roleId === "ADM" && key === "userType") {
      const getUserType = e.target.value;
      if (getUserType === "1") {
        props.setFilterState({
          ...props.filterState,
          teamMember: null,
          User: null,
          UserOne: null,
          UserTwo: null,
          UserThree: null,
          UserFour: null,
          UserID:null,
          campaigncurrentStatus: model?.campaigncurrentStatus,
        });
        props.setLeadReq({
          ...props.leadReq,
          UserId: null,
        })
        campaignLeadFilterForm[1].hide = false;
        campaignLeadFilterForm[2].hide = true;
        campaignLeadFilterForm[3].hide = true;
        campaignLeadFilterForm[4].hide = true;
        campaignLeadFilterForm[5].hide = true;
        campaignLeadFilterForm[6].hide = true;
        campaignLeadFilterForm[7].hide = true;
      } else if (getUserType === "2") {
        props.setFilterState({
          ...props.filterState,
          EmployeeCode: null,
          UserID:null,
          // UserCode: null,
          campaigncurrentStatus: model?.campaigncurrentStatus,
        });
        props.setLeadReq({
          ...props.leadReq,
          EmployeeCode: null,
        })
        campaignLeadFilterForm[2].hide = false;
        campaignLeadFilterForm[3].hide = true;
        campaignLeadFilterForm[1].hide = true;
        campaignLeadFilterForm[6].hide = true;
        campaignLeadFilterForm[4].hide = true;
        campaignLeadFilterForm[5].hide = true;
        campaignLeadFilterForm[7].hide = true;
      }
    }
    if (key === "teamMember") {
      const getUser = e.target.value;
      props.setFilterState({
        ...props.filterState,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
      if (getUser === "Team") {
        campaignLeadFilterForm[3].hide = false;
      } else {
        campaignLeadFilterForm[3].hide = true;
        campaignLeadFilterForm[4].hide = true;
        campaignLeadFilterForm[5].hide = true;
        campaignLeadFilterForm[6].hide = true;
        campaignLeadFilterForm[7].hide = true;
      }
    }
    if (key === "User") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);

      await getSub(key, getUser);
      if (getUser) {
        campaignLeadFilterForm[4].hide = false;
        campaignLeadFilterForm[5].hide = true;
        campaignLeadFilterForm[6].hide = true;
        campaignLeadFilterForm[7].hide = true;
      }
    }
    if (key === "UserOne") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        campaignLeadFilterForm[5].hide = false;
        campaignLeadFilterForm[6].hide = true;
        campaignLeadFilterForm[7].hide = true;
      }
    }
    if (key === "UserTwo") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        campaignLeadFilterForm[6].hide = false;
      }
    }
    if (key === "UserThree") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        campaignLeadFilterForm[7].hide = false;
      }
    }
    if (key === "UserFour") {
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
    }
    if (userState.profileInfo.roleId !== "ADM" && key === 'userType') {
      props.setFilterState({
        ...props.filterState,
        campaigncurrentStatus: model?.campaigncurrentStatus,
      });
      manageUserHeirarcheyForNonAdminUsers(e.target.value, key);
    }
  };
  const manageUserHeirarcheyForNonAdminUsers = async (
    userId,
    key
  ) => {
    props.activateLoader();
    console.log("campaignLeadFilterForm ", campaignLeadFilterForm);
    // if user is non admin, set filter level accordingly
    if (userState.profileInfo.roleId !== "ADM") {
      const userRole = userState.profileInfo.roleId;
      switch (userRole) {
        case "BDM":
          console.log("inside of ", userRole);
          break;

        case "ABH":
          console.log("inside of ", userRole);
          setUserID(userState.profileInfo.userId);
          await getSub('UserThree', userState.profileInfo.userId);
          if (userState.profileInfo.userId) {
            campaignLeadFilterForm[7].hide = false;
            campaignLeadFilterForm[6].hide = true;
            campaignLeadFilterForm[5].hide = true;
            campaignLeadFilterForm[4].hide = true;
            campaignLeadFilterForm[2].hide = true;
          }
          break;
        case "RBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub('UserTwo', userState.profileInfo.userId);
          campaignLeadFilterForm[7].hide = false;
          campaignLeadFilterForm[6].hide = false;
          campaignLeadFilterForm[5].hide = true;
          campaignLeadFilterForm[4].hide = true;
          campaignLeadFilterForm[2].hide = true;
          break;

        case "ZBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub('UserOne', userState.profileInfo.userId);
          campaignLeadFilterForm[7].hide = false;
          campaignLeadFilterForm[6].hide = false;
          campaignLeadFilterForm[5].hide = false;
          campaignLeadFilterForm[4].hide = true;
          campaignLeadFilterForm[2].hide = true;
          break;

        case "NH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub('User', userState.profileInfo.userId);
          campaignLeadFilterForm[7].hide = false;
          campaignLeadFilterForm[6].hide = false;
          campaignLeadFilterForm[5].hide = false;
          campaignLeadFilterForm[4].hide = false;
          campaignLeadFilterForm[2].hide = true;
          break;

        default:
          break;
      }
    }
  };
  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "Select", value: "" });
    return arr;
  };
  let LEAD_STATUS = getDropdownData(
    leadStatus,
    "LeadStatus",
    "LeadStatus",
    "LeadStatusId"
  );
  if (props.chanelId ===13) {
    LEAD_STATUS = LEAD_STATUS?.filter(
      (ele) =>
        ele.value === 1 ||
        ele.value === 2 ||
        ele.value === 3 ||
        ele.value === 4 ||
        ele.value === 5 ||
        ele.value === 6 ||
        ele.value === 7 ||
        ele.value === 8 ||
        ele.value === 10 ||
        ele.value === 15 ||
        ele.value === 16 ||
        ele.value === 17 ||
        ele.value === 18 ||
        ele.value === 19 ||
        ele.value === 20 ||
        ele.value === 21 ||
        ele.value === 22 ||
        ele.value === 23 ||
        ele.value === 24
    );
  }
  else{
  LEAD_STATUS = LEAD_STATUS?.filter(
    (ele) =>
      ele.value === 1 ||
      ele.value === 2 ||
      ele.value === 3 ||
      ele.value === 4 ||
      ele.value === 5 ||
      ele.value === 6 ||
      ele.value === 7 ||
      ele.value === 8 ||
      ele.value === 10
  );
}
  const onAutocompleteSelectHandler = (key, value, item) => {};
  const initFormData = (formFields) => {
    const formData = [...formFields];
    formData[0].options = ADMIN_HIERARCHY_TYPE;
    props.setFilterState({ ...props.filterState, UserName: userName });
    formData[18].options = productDetails;
    formData[12].options = LEAD_STATUS;
    return formData;
  };
  const classes = useStyles();

  return (
    <div className={props.anchor ? classes.drawerMobile : classes.desktopFilter}>
      <div className="filterBox">
        <h4 className="filterSec">
          {" "}
          <FilterAltIcon className="icnFilter" /> Filter By{" "}
          <CloseIcon
            className={classes.drawerMobile}
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          />
        </h4>
        <div className="fformBox">
          <DynamicForm
            key={1}
            ref={dynamicRef}
            className="dynamicForm leadFilters  leadFiltersMobile"
            title={" "}
            buttonSubmit={t("Apply Filter")}
            defaultValues={props.filterState}
            customValidators={customValidators}
            model={state.formData}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={false}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key, model) => onChangeHandler(event, key, model)}
            onAutocompleteSelect={onAutocompleteSelectHandler}
            autoCompleteItems={state.autoCompleteItems}
            showAstersikOnRequiredFields={false}
            extaraActionFormButton={
              <span className="reset" onClick={onResetHandler}>
                <RestartAltIcon /> Reset
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});

export default connect(mapStateToProps, {
  getMasterDataForAdmin,
  getCampaignName,
  NHList,
  activateLoader,
  deActivateLoader,
})(CampaignLeadFilter);
