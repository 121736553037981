import React from "react";
import { withTranslation } from "react-i18next";
import "../../../styles/app.css";
import "./login.scss";
import loginLogo from "../../../assets/img/ifli-logo-new.png";
import Grid from "@material-ui/core/Grid";
import compIcon from "../../../assets/img/computer-icon.png";
import { connect } from "react-redux";
import {
  loginAction,
  logOutAction,
  ssoAuthValidateUserData,
} from "../../../redux/actions/userAction";
import {
  activateLoader,
  deActivateLoader,
  loadMasterData,
  setIsUserFound,
} from "../../../redux/actions/common";
import Signin from "f1-signin-form-react";
import {
  getBranchDetailsForUser,
  getTeamListForUSer,
  getZMAList,
  pullLeadList,
  getLgSPForUSer,
  pullHistoriCalLeadsList,
  getSRMforUser,
} from "../../../offlineManager/leadMasterLoader";
import {
  addUpdateAppVersionInfo,
  addUpdateLastUserSyncTime,
  getCurrentInsatlledAppVersion,
  isOfflineSessionValid,
  saveUserInfo,
} from "../../../offlineManager/userAuthOfflineManager";
import {
  addUpdateLastLeadSyncTime,
  addLeadInDb,
  getLeadSyncTime,
  addHistoricalLeadsInDb,
  resetMasterDataSyncTime,
} from "../../../offlineManager/leadOfflineManager";
import {
  getCoords,
  setLoginCount,
  setMenuDisabled,
} from "../../../redux-reducer/reducer/leadListReducer";
import {
  addActivityInDb,
  addHistoricalActivityInDb,
  addUpdateLastActivitySyncTime,
  getActivitySyncTime,
} from "../../../offlineManager/activityOfflineManager";
import {
  pullActivityList,
  pullHistoricalActivityList,
} from "../../../offlineManager/activityMasterLoader";
import ModalPopup from "f1-modal-react";
import { Button } from "@mui/material";
import { Typography } from "@material-ui/core";
import { getUserInformationData } from "../../../redux/actions/dashboardAction";
//import { logoutUserReducer } from "../../../redux-reducer/reducer";
import { getEnvName } from "../../../utils/utils";
import packageJson from "../../../../package.json";
import { getLatestVersionInfo } from "../../../redux/actions/notificationAction";
import ResetDevice from "../resetDevice/resetDevie";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geoLocation: "",
      count: 1,
      showModalSetup: false,
      isSSOLogin: false,
      tempAppVersion: null,
      setShowAppVersionModal: false,
      isUserFound:this.props.commonState.isUserFound
    };
  }

  handleRegisterClick = (e) => {
    e.preventDefault();
    this.props.history.push("/auth/register");
  };
  getCurrentLocationCoordinates = () => {
    const geoOps = {
      enableHighAccuracy: true,
      timeout: 10000, //10 seconds
    };
    navigator?.geolocation.watchPosition(
      ({ coords: { latitude, longitude } }) => {
        this.setState({ geoLocation: JSON.stringify({ latitude, longitude }) });
        this.props.getCoords({ latitude: latitude, longitude: longitude });
      },
      null,
      geoOps
    );
  };

  componentDidMount = () => {
    this.props.deActivateLoader();
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const email = query.get("emailAddress");
    const token = query.get("token");
    setTimeout(() => {
      this.getCurrentLocationCoordinates();
    }, 0);
    if (token && email && token !== "" && email !== "") {
      this.setState({ ...this.state, isSSOLogin: true });
      this.submitForm(null, null, email, token, true);
    }
  };
  getCurrentLocation = () => {
    this.getCurrentLocationCoordinates();
  };
  submitForm = async (event, data, email, token, isSsoLogin = false) => {
    const allowed = await this.checkUsersAppVersion();
    console.log('allowed ', allowed);
    if (!allowed) {
      return false;
    }
    if (!isSsoLogin) {
      event.preventDefault();
      if (!data.isFormValid && data.formData.username === "") {
        return false;
      }
      this.props.activateLoader();
      data.formData["geoLocation"] = this.state.geoLocation;
    }
    if (navigator.onLine) {
      let res;
      if (!isSsoLogin) {
        res = await this.props.loginAction({ formData: data.formData });
      } else {
        this.props.activateLoader();
        res = await this.props.ssoAuthValidateUserData({
          authToken: token,
          email: email,
        });
        this.props.deActivateLoader();
      }
      const { userData, success } = res;
      if (success) {
        if (
          !userData.channelid ||
          !userData.roleMapping ||
          userData.roleID === "" ||
          userData.roleMapping.length === 0
        ) {
          this.setState({ ...this.state, showModalSetup: true });
          return;
        }
        const data = await this.props.getUserInformationData(userData.id);
        let isFirstLogin;
        if (
          data.data.Address !== null &&
          data.data.Latitude !== null &&
          data.data.Longitude !== null
        ){
          isFirstLogin = false;
        } else {
          if (!userData.isFirstLogin) {
            isFirstLogin = false;
          } else {
            isFirstLogin = true;
          }
        }
        this.props.setLoginCount({ isFirstLogin: isFirstLogin });
        this.props.setMenuDisabled({ isDisable: isFirstLogin });
        this.props.activateLoader();
        const numberOfMillisecondsSinceEpoch = Date.now();
        Promise.all([
          saveUserInfo(userData),
          getZMAList(),
          getLgSPForUSer(userData.id),
        ]).then((results) => {
          addUpdateLastUserSyncTime(numberOfMillisecondsSinceEpoch);
          this.getUserSpecificMasterData(
            userData.id,
            userData.roleID,
            isFirstLogin
          );
        });
      }
    } else {
      this.props.deActivateLoader();
      // check offline login
      isOfflineSessionValid().then((userData) => {
        const { id, userType } = userData[0];
        if (id && userType) {
          this.props.history.push("/app/home");
        }
      });
    }
  };

  getUserSpecificMasterData = (userId, roleName, isFirstLogin) => {
    this.props.activateLoader();
    Promise.all([
      getBranchDetailsForUser(userId),
      getSRMforUser(userId),
      getTeamListForUSer(userId),
    ]).then((results) => {
      this.props.deActivateLoader();
      if (roleName === "BDM") {
        this.getUserLeads(userId, isFirstLogin);
      } else {
        if (roleName === "ADM") {
          this.props.history.push("/app/home/admin");
        } else {
          if (isFirstLogin) {
            this.props.history.push("/app/profilesetting");
          } else {
            this.props.history.push("/app/home");
          }
        }
      }
    });
  };

  getUserLeads = async (userId, isFirstLogin) => {
    this.props.activateLoader();
    const leadLastSyncTime = await getLeadSyncTime();
    const syncTime = leadLastSyncTime ? leadLastSyncTime.syncTime : null;
    pullLeadList(userId, syncTime)
      .then((res) => {
        const leadCount = res.length;
        if (res) {
          addLeadInDb(res).then((result) => {
            if (result && result.length === leadCount) {
              // update the lead sync time
              const numberOfMillisecondsSinceEpoch = Date.now();
              addUpdateLastLeadSyncTime(numberOfMillisecondsSinceEpoch);
              this.getUserActivities(userId, isFirstLogin);
              this.getHistoricalActivities(userId, isFirstLogin);
              this.getHistoricalLeads(userId, isFirstLogin);
            }
          });
        }
      })
      .catch((err) => {
        this.props.deActivateLoader();
      });
  };

  getUserActivities = async (userId, isFirstLogin) => {
    this.props.activateLoader();
    const activityLastSyncTime = await getActivitySyncTime();
    const syncTime = activityLastSyncTime
      ? activityLastSyncTime.syncTime
      : null;
    pullActivityList(userId, syncTime)
      .then((res) => {
        const activityCount = res.length;
        if (res) {
          addActivityInDb(res).then((result) => {
            if (result && result.length === activityCount) {
              // update the lead sync time
              const numberOfMillisecondsSinceEpoch = Date.now();
              addUpdateLastActivitySyncTime(numberOfMillisecondsSinceEpoch);
              //this.props.history.push('/app/profilesetting')
              if (isFirstLogin) {
                this.props.history.push("/app/profilesetting");
              } else {
                this.props.history.push("/app/home");
              }
              this.props.deActivateLoader();
            }
          });
        }
      })
      .catch((err) => {
        this.props.deActivateLoader();
      });
  };

  getHistoricalActivities = async (userId, isFirstLogin) => {
    this.props.activateLoader();
    const activityLastSyncTime = await getActivitySyncTime();
    const syncTime = activityLastSyncTime ? activityLastSyncTime.syncTime : 0;
    pullHistoricalActivityList(userId, syncTime)
      .then((res) => {
        const activityCount = res.length;
        if (res) {
          addHistoricalActivityInDb(res).then((result) => {
            if (result && result.length === activityCount) {
              // update the lead sync time
              const numberOfMillisecondsSinceEpoch = Date.now();
              addUpdateLastActivitySyncTime(numberOfMillisecondsSinceEpoch);
              if (isFirstLogin) {
                this.props.history.push("/app/profilesetting");
              } else {
                this.props.history.push("/app/home");
              }
              this.props.deActivateLoader();
            }
          });
        }
      })
      .catch((err) => {
        this.props.deActivateLoader();
      });
  };

  getHistoricalLeads = async (userId, isFirstLogin) => {
    this.props.activateLoader();
    const leadLastSyncTime = await getLeadSyncTime();
    const syncTime = leadLastSyncTime ? leadLastSyncTime.syncTime : 0;
    pullHistoriCalLeadsList(1, userId, syncTime)
      .then((res) => {
        const leadCount = res.length;
        if (res) {
          addHistoricalLeadsInDb(res).then((result) => {
            if (result && result.length === leadCount) {
              // update the lead sync time
              const numberOfMillisecondsSinceEpoch = Date.now();
              addUpdateLastLeadSyncTime(numberOfMillisecondsSinceEpoch);
              // this.props.history.push('/app/home')
              if (isFirstLogin) {
                this.props.history.push("/app/profilesetting");
              } else {
                this.props.history.push("/app/home");
              }
              this.props.deActivateLoader();
            }
          });
        }
      })
      .catch((err) => {
        this.props.deActivateLoader();
      });
  };
  forgotPass = (e) => {
    e.preventDefault();
    let redirectUrl = "https://itam.indiafirstlife.com:9251/authorization.do";
    Object.assign(document.createElement("a"), {
      target: "_blank",
      rel: "noopener noreferrer",
      href: redirectUrl,
    }).click();


  };

  logintitle = () => {
    return (
      <div className="login-title">
        <p className="brand">
          <img className="ifli-logo" src={loginLogo} alt="loginLogo" />
        </p>
        <p className="tagLine">
          Welcome to <br /> ACTIFY
        </p>
      </div>
    );
  };

  handleCancel =()=>{
    this.setState({ ...this.state, isUserFound: false })
    this.props.setIsUserFound(false)
  }

  // logoutHandler = () => {
  //   localStorage.removeItem("roleId");
  //   localStorage.removeItem("refreshToken");
  //   localStorage.removeItem("permissions");
  //   localStorage.removeItem("expiration");
  //   localStorage.removeItem("token");
  //   sessionStorage.clear();
  // };
  checkUsersAppVersion = async () => {
    return new Promise(async (resolve, reject) => {
      this.props.activateLoader();
      const result = await this.props.getLatestVersionInfo();
      this.props.deActivateLoader();
      const { Appversion } = result;
      this.setState({ ...this.state, tempAppVersion: Appversion })
      getCurrentInsatlledAppVersion().then((res) => {
        if (res) {
          const { appVersionInfo } = res;
          if (parseInt(Appversion) > parseInt(appVersionInfo)) {
            this.setState({ ...this.state, showAppVersionModal: true })
            resolve(false);
          } else {
            this.setState({ ...this.state, showAppVersionModal: false })
            resolve(true);
          }
        } else {
          addUpdateAppVersionInfo(Appversion);
          resolve(true);
        }
      }).catch(() => {
        resolve(true)
      });
    })
  }
  clearCache = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
      addUpdateAppVersionInfo(this.state.tempAppVersion).then((success) => {
        this.setState({ ...this.state, setShowAppVersionModal: false });
        // delete browser cache and hard reload
        this.logoutHandler();
        window.location.reload(true);
      });
    } else {
      console.log("cache undefined");
    }
  };
  logoutHandler = () => {
    // this will reset the master data sync time, so on next login it will push all master data again
    resetMasterDataSyncTime().then((res) => {
      this.props.logOutAction();
    });
    localStorage.removeItem("roleId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("expiration");
    localStorage.removeItem("token");
    sessionStorage.clear();
  };
  render() {
    const { t } = this.props;
    return (
      <div className="main-container">
        <Grid container columns={{ xs: 4, md: 12 }}>
          <Grid item xs={12} md={7} className="leftColLogin">
            <div className="leadNurtDiv">
              <img src={compIcon} alt="comLog" height="200" />
              <h3>
                <span>Improve your</span> Lead Nurturing
              </h3>
              <p>
                Now manage your activities, leads in a new and improved way.
                Presenting the all new ACTIFY just for you.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className="loginPage">
              <div className="loginBox">
                {this.state.isSSOLogin ? (
                  <>
                    <Typography variant="h4">
                      Validating, Please wait....
                    </Typography>
                    <Typography variant="h6">
                      Do not press back button or refresh
                    </Typography>
                  </>
                ) : (
                  <>
                    <Signin
                      className="login-form"
                      Title=""
                      Email={false}
                      UserName={true}
                      titleAlign="left"
                      LableUsername={t("User Name")}
                      PlaceholderEmail={t("name@domain.com")}
                      LablePassword={t("Password")}
                      PlaceholderPassword={t("password")}
                      SubmitButtonText={t("LOG IN")}
                      design="bootstrap"
                      RememberMe={true}
                      extErrors={this.state.extErrors}
                      validationEvent="blur"
                      onSubmit={this.submitForm}
                      onClick={this.forgotPass}
                      showIcons={true}
                      ForgotPassword={true}
                      showPassword={true}
                      registerLink="#"
                      columns={6}
                      registerClick={this.handleRegisterClick}
                      registerLinkAlign="center"
                      disableSubmit={false}
                      onRenderTitle={this.logintitle}
                      linkPositionToLoginButton="bottom"
                      autocomplete={"off"}
                    />
                    <p style={{ textAlign: "center" }}>
                      For all queries, please mark a mail to:{" "}
                      <a href="mailto:actify.support@indiafirstlife.com">
                        actify.support@indiafirstlife.com
                      </a>
                    </p>
                    <p style={{ textAlign: "center" }}>
                      {getEnvName()}
                      {packageJson.version}
                    </p>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <ModalPopup
          className="modalDiv"
          show={this.state.showModalSetup}
          onHide={() => this.setState({ ...this.state, showModalSetup: false })}
          size={"sm"}
          centered={true}
          modelHeader={"Alert"}
          modelContent={
            <div className="footerButtons">
              <p>User data is missing, please contanct administrator</p>
              <span>
                <Button
                  onClick={() => {
                    this.logoutHandler();
                    this.setState({ ...this.state, showModalSetup: false });
                  }}
                >
                  OK
                </Button>
              </span>
            </div>
          }
          backdrop={"static"}
        />
        <ModalPopup
          show={this.state.showAppVersionModal}
          onHide={() => this.setState({ ...this.state, setShowAppVersionModal: false })}
          size={"sm"}
          centered={true}
          modelHeader={""}
          modelContent={
            <div className="footerButtons">
              <p>
                There is new version of the app available; please click on the
                button below to get the latest features of the app.
              </p>
              <p>You will have to Login again to use new app features</p>
              <Button
                className="btn-black btn Ripple-parent btn btn-primary"
                onClick={() => {
                  this.clearCache();
                }}
              >
                Update
              </Button>
            </div>
          }
          backdrop={"static"}
        />
        <ModalPopup
          show={this.props.commonState.isUserFound}
          onHide={this.handleCancel}
          size={"sm"}
          centered={true}
          modelHeader={""}
          modelContent={
            <div className="footerButtons">
              <div style={{fontSize:"1.1rem",fontWeight:"bold"}}>
              <b >User Not Found. Kindly write to</b>
              </div>
              <div>
                <a style={{fontSize:"1.2rem",fontWeight:"bold"}} href="mailto:Distribution.Services@indiafirstlife.com">Distribution.Services@indiafirstlife.com</a>
              </div>
              <div className="footerButtons mt-3">
            <span>
            <Button
                className=" btn Ripple-parent btn btn-primary float-right"
                onClick={this.handleCancel}
              >
                ok
              </Button>
            </span>
          </div>
            </div>
          }
          backdrop={"static"}
        />
        <ResetDevice/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  commonState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  loginAction: (payload) => navigator.onLine && dispatch(loginAction(payload)),
  activateLoader: (payload) =>
    navigator.onLine && dispatch(activateLoader(payload)),
  deActivateLoader: (payload) =>
    navigator.onLine && dispatch(deActivateLoader(payload)),
  loadMasterData: (payload) =>
    navigator.onLine && dispatch(loadMasterData(payload)),
  getCoords: (payload) => navigator.onLine && dispatch(getCoords(payload)),
  setLoginCount: (payload) => navigator.onLine && dispatch(setLoginCount(payload)),
  setMenuDisabled: (payload) => navigator.onLine && dispatch(setMenuDisabled(payload)),
  ssoAuthValidateUserData: (payload) => navigator.onLine && dispatch(ssoAuthValidateUserData(payload)),
  getUserInformationData: (payload) => navigator.onLine && dispatch(getUserInformationData(payload)),
  logOutAction: () => dispatch(logOutAction()),
  getLatestVersionInfo: () => dispatch(getLatestVersionInfo()),
  setIsUserFound: () => dispatch(setIsUserFound())
});

export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
