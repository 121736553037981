
import { format } from 'date-fns';
const _format = 'yyyy-MM-dd';
const _dateTimeLocal = "datetime-local";
const _meetingStartTime = "Meeting Start Date and Time";
const _meetingEndTime = "Meeting End Date and Time";
const _meetingStartEndTimeValidator = "Meeting Start Date and Time is required.";
const _requiredRemarks = "Remarks is required.";
const faMapMarker = "fa fa-map-marker";
const meetingLocation = "Meeting Location";
const currAddress = "Current Address: ";
const meetingLocRequired = "Meeting location is required.";
const requiredField = "This field  is required.";
const mindateFormat = `${format(new Date(), _format)} T ${format(new Date(), 'kk:mm')}`;


export const ReviewMeetingActivityForm = [
    {
        "key": "VisitToID", "type": "select", "id": "VisitToID", "label": "Visit To",
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    }
]

export const ReviewMeetingActivityForm1 = [
    {
        "key": "ZMAID", "type": "select", "id": "ZMAID", "label": "ZMA",
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    }
]
export const ReviewMeetingActivityForm2 = [
    {
        "key": "AdvisorID", "type": "select", "id": "AdvisorID", "label": "Advisor",
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    }
]
export const ReviewMeetingActivityForm3 = [
    {
        "key": "PremierZMAID", "type": "select", "id": "PremierZMAID", "label": "Premier ZMA ",
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    }
]
export const ReviewMeetingActivityForm4 = [
    {
        "key": "EAPIDs", "type": "select", "id": "EAPIDs", "label": "EAP",
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    }
]
export const ReviewMeetingActivityForm5 = [
    {
        "key": "MeetingStartDateTime",
        "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false,
        "id": "MeetingStartDateTime", "label": _meetingStartTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }]
    },
    {
        "key": "MeetingEndDateTime", "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting End Date and Time is required." }]
    },
    {
        "key": "Remarks", "type": "text", "id": "Remarks", "label": "Remarks",
        "validators": [{ "name": "required", "value": false, "validationMessage": _requiredRemarks },
        { "name": "maxLength", "value": 30, "validationMessage": "Max 30 characters allowed." }]
    },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
]
