import React, { useState } from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import ActivityTravelHistory from "./activityTravelHistory";
import {
    BDF,
    BRANCH_TRAINING,
    BRANCH_VISIT,
    HNI_MEET,
    ICE_E_SAMPARK,
    MONTHLY_REVIEW,
    TRAINING,
    MEETING_VISIT,
    CALLING_TO_VLE_RAP_DM,
    NEW_BUSINESS,
    JOINT_SALES_CALL_WITH_SRM,
    SCHEDULE_STRUCTURED_REVIEW_BDM,
    BRANCH_VISIT_PLAN_BDM,
    REGIONAL_OFFICE_MEETING_ABH,
    SALES_TRAINING_PLAN_BDM,
    JOINT_CALLS_BDM,
    CIRCULAR_FOR_SALES_ENABLEMENT_ABH,NEW_RECRUITMENT_ACTIVITY,REVIEW_MEETING, ICE_FOR_LOAN_ACTIVITY, HNI_LEAD_GENERATION, SME_LF, SME_CELL, BRANCH, COLLATE_THE_UNQUALIFIED_LEADS_DATA, REGIONAL_OFFICE_CONNECT,
    LIVE_LEAD_GENERATION, BUSINESS_DEVELOPMENT_ACTIVITY, PARTNER_VISIT, PRODUCT_TRAINING, BIRTHDAY_CELEBRATION, PRODUCT_LAUNCH, BRANCH_HEAD_MEET, BRANCH_FOUNDATION_DAY, JOINT_CALL_WITH_PARTNER, PARTNER_BRANCH_VISIT_PLAN, TEAM_REVIEW, BOB_QLIK
  } from "../../../../utils/config";
import { getUploadedImage } from "../../../../redux-reducer/reducer";

const CancelledHistory = (props) => {
  const { history, activityOption } = props;
  const remarks = history.Remarks ? history.Remarks : "N/A";
  const [fileData, setfileData] = useState(null)
  const [loader, setLoader] = useState(true)
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const channelId = JSON.parse(userData.userReducer).profileInfo.channelid;
  const getBase64Data = async() => {
    if (history.UploadBillFileName) {
      setLoader(false)
      const res = await getUploadedImage(history.UploadBillFileName);
      if (res.status === 200 && res.data) {
        setfileData(`${"data:image/png;base64,"}${res.data}`);
        var image = new Image();
        image.src = "data:image/jpg;base64," + res.data;
        var w = window.open("");
        w.document.write(image.outerHTML);
      }
      setLoader(true)
    }
  };

  return (
    <>
      {history.ActivityStatus === "Activity Cancelled" ? (
        <>
          {activityOption &&
            (activityOption.key == TEAM_REVIEW ||
              activityOption.key == PARTNER_BRANCH_VISIT_PLAN) && (
              <>
                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Reason</label>
                    <label>{history.Reason}</label>
                  </TimelineContent>
                </TimelineItem>
              </>
            )}
          {activityOption &&
            (activityOption.key === BRANCH_VISIT ||
              activityOption.key === BRANCH_TRAINING ||
              activityOption.key === HNI_MEET ||
              activityOption.key === ICE_E_SAMPARK ||
              activityOption.key === BDF ||
              activityOption.key === ICE_FOR_LOAN_ACTIVITY || 
              activityOption.key === HNI_LEAD_GENERATION ||
              activityOption.key === BOB_QLIK ) && ( // added new BoB Qlik Activity 24-02-2024 BRD  Actify new lead flow v1.2
              <>
                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Reason</label>
                    <label>{history.Reason}</label>
                  </TimelineContent>
                </TimelineItem>

                { channelId !== 13 && <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Remarks</label>
                    <label>{remarks}</label>
                  </TimelineContent>
                </TimelineItem>}
                {/* {history.IsClaimRequired &&
                    <ActivityTravelHistory history={history} styletimeline={props.styletimeline} />
                } */}
              </>
            )}
          {activityOption && (activityOption.key === TRAINING || activityOption.key === MONTHLY_REVIEW ||
              activityOption.key === SME_LF||
              activityOption.key === SME_CELL||
              activityOption.key === BRANCH||
              activityOption.key === COLLATE_THE_UNQUALIFIED_LEADS_DATA||
              activityOption.key === REGIONAL_OFFICE_CONNECT) && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason for cancellation</label>
                  <label> {activityOption.key === MONTHLY_REVIEW ? history.ReasonForCancellation : history.Reason}</label>
                </TimelineContent>
              </TimelineItem>

            </>
          )}

          {activityOption && activityOption.key === NEW_RECRUITMENT_ACTIVITY && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason for cancellation</label>
                  <label> {history.Reason}</label>
                </TimelineContent>
              </TimelineItem>
            </>
          )}
          {activityOption && activityOption.key === NEW_BUSINESS && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason</label>
                  <label> {history.Reason}</label>
                </TimelineContent>
              </TimelineItem>
            </>
          )}
          {activityOption && activityOption.key === JOINT_SALES_CALL_WITH_SRM && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason</label>
                  <label> {history.FpmActivitycancelreason}</label>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                </TimelineSeparator>
                <TimelineContent>
                  <label>Remarks</label>
                  <label>{remarks}</label>
                </TimelineContent>
              </TimelineItem>
            </>
          )}

          {activityOption && activityOption.key === REVIEW_MEETING && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason for cancellation</label>
                  <label> {history.Reason}</label>
                </TimelineContent>
              </TimelineItem>

            </>
          )}

          {activityOption && activityOption.key === CALLING_TO_VLE_RAP_DM && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason for cancellation</label>
                  <label> {history.ReasonForCancellation}</label>
                </TimelineContent>
              </TimelineItem>
            </>
          )}
          {/* {activityOption && activityOption.key === MONTHLY_REVIEW && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Premium Amount</label>
                  <label> {history.PremiumAmount}</label>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Number of Proposals</label>
                  <label> {history.NumberOfProposals}</label>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>RAP Activated</label>
                  <label> {history.RAPActivated}</label>
                </TimelineContent>
              </TimelineItem>
            </>
          )} */}

          {activityOption && activityOption.key === MEETING_VISIT && (
            <>
              <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason for cancellation</label>
                  <label> {history.Reason}</label>
                </TimelineContent>
              </TimelineItem>
            </>
          )}
          {activityOption &&
            (activityOption.key === SCHEDULE_STRUCTURED_REVIEW_BDM ||
              activityOption.key === JOINT_CALLS_BDM ||
              activityOption.key === BRANCH_VISIT_PLAN_BDM ||
              activityOption.key === REGIONAL_OFFICE_MEETING_ABH ||
              activityOption.key === SALES_TRAINING_PLAN_BDM ||
              activityOption.key === CIRCULAR_FOR_SALES_ENABLEMENT_ABH ||
              activityOption.value === "38" ||
              activityOption.value === "39" ||
              activityOption.value === "40" ||
              activityOption.value === "41" ||
              activityOption.value === "42" ||
              activityOption.value === "43" ||
              activityOption.value === "44"
            ) && (
              <>
                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Reason</label>
                    <label>{history.Reason}</label>
                  </TimelineContent>
                </TimelineItem>
                {history.OtherReason && <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Other Reason</label>
                    <label> {history.OtherReason}</label>
                  </TimelineContent>
                </TimelineItem>}

                <TimelineItem>
                  <TimelineSeparator>
                    <span className="separator">-</span>
                  </TimelineSeparator>
                  <TimelineContent>
                    <label>Remarks</label>
                    <label>{remarks}</label>
                  </TimelineContent>
                </TimelineItem>
                {/* {history.IsClaimRequired &&
                <ActivityTravelHistory history={history} styletimeline={props.styletimeline} />
            } */}
              </>
            )}
          {history.IsClaimRequired && (
            <ActivityTravelHistory
              history={history}
              styletimeline={props.styletimeline}
            />
          )}
          {history.UploadBill &&
            <TimelineItem>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {loader == true ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
                {
                  fileData &&
                  <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                }
              </TimelineContent>
            </TimelineItem>
          }
            {activityOption &&
            (activityOption.key === LIVE_LEAD_GENERATION ||
              activityOption.key === JOINT_CALL_WITH_PARTNER ||
              activityOption.key === BRANCH_FOUNDATION_DAY ||
              activityOption.key === BRANCH_HEAD_MEET ||
              activityOption.key === PRODUCT_LAUNCH ||
              activityOption.key === BIRTHDAY_CELEBRATION ||
              activityOption.key === PRODUCT_TRAINING ||
              activityOption.key == PARTNER_VISIT ||
              activityOption.key === BUSINESS_DEVELOPMENT_ACTIVITY
            ) && (
              <>
               <TimelineItem>
                <TimelineSeparator>
                  <span className="separator">-</span>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <label>Reason</label>
                  <label> {history.Reason}</label>
                </TimelineContent>
              </TimelineItem>
              </>
            )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CancelledHistory;
