import React, { useState } from "react";
import { useHistory } from "react-router-dom"
import { useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AccountOverViewCard from './accountOverViewCard';
import ActivityOverviewCard from './activityOverviewCard';
import CalenderDashboardCard from "./calenderDashboardCard";
import LeadOverviewCard from "./leadOverviewCard";
import BusinessLeadOverviewCard from "./businessLeadOverviewCard";
import RecruitmentLeadOverviewCard from "./recruitmentLeadOverviewCard";
import PersistencyLeadOverview from './persistencyLeadOverview';
import CampaignLeadOverviewCard from "./campaignLeadOverviewCard";
import SalesManagementOverviewCard from "../salesManagement/salesManagementOverviewCard";
import CampaignActivityOverviewCard from './campaignActivityOverviewCard';
import SalesManagementOverviewCardBroca from '../activity/brocaSMA/salesManagementOverviewCardBroca';
import PersonIcon from '@mui/icons-material/Person';
import MyTeamOverviewCard from "./myTeamOverviewCard";
import PehlaKadamOverViewCard from "./pehlaKadamOverViewCard";

// import SalesManagementOverviewCard from "../salesManagement/salesManagementOverviewCard";
import {ROLE_BDM,ROLE_NH,ROLE_RBH,ROLE_ZBH} from "../../../utils/config";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const DashboardNHRBHZBHMyTeamLayout = ({ selectedBDM,
    selectedView,
    selectedSecondLevelSubordinate,
    selectedThirdLevelSubordinate,
    selectedFourthLevelSubordinate, viewFor, rbhView }) => {
    const history = useHistory();
    const commonState = useSelector(state => state)
    const viewForStatus = commonState.commonReducer.viewForStatus
    const channelid = commonState.userReducer.profileInfo.channelid;
    const roleId = commonState.userReducer.profileInfo.roleId;
    const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelSubordinateList;

    const [togglestate, setToggleState] = useState({ 'bottom': false });
    const activityClick = () => history.push("/app/activity/create");
    const leadClick = () => history.push("/app/lead/create");
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setToggleState({ ...togglestate, [anchor]: open });
    };
    // console.log(commonState.userReducer.profileInfo.channelid)
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="updatePopupDiv">
                <ListItem color="black" sx={{ fontWeight: 'bold', fontSize: 18 }} >
                    Create
                    <CloseIcon />
                </ListItem>
                <ListItem
                    button
                    onClick={() => activityClick()}>
                    <ListItemIcon>
                        <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                            <PendingActionsIcon />
                        </Fab> + Create Activity
                    </ListItemIcon>
                    <ListItemText />
                </ListItem>
                {channelid !== 2 &&
                    <ListItem
                        button
                        onClick={() => leadClick()}
                    >
                        <ListItemIcon >
                            <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                                <PersonIcon />
                            </Fab> + Create Lead
                        </ListItemIcon>
                        <ListItemText />
                    </ListItem>
                }
            </List>
            <Divider />
        </Box>
    );
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    // let getSelectedViewFor = []
    const classes = useStyles();
    if (secondLevelSubordinateList && secondLevelSubordinateList.UsersDetails && secondLevelSubordinateList.UsersDetails.length > 0) {
        // getSelectedViewFor = secondLevelSubordinateList.UsersDetails?.filter(f => f.UserId == selectedSecondLevelSubordinate)
    }
    return <>
        <Box sx={{ flexGrow: 1 }}>

            <Grid container spacing={3}>
                <Grid item xs={12} md={4} >
                    <AccountOverViewCard
                        selectedBDM={selectedBDM}
                        //  selectedView={selectedView}
                        roleId={roleId} classes={classes}
                        Item={Item} history={history}
                        selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                        selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                        selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                        selectedView={selectedView}
                        rbhView={rbhView}
                    //getSelectedViewFor.length>0?getSelectedViewFor[0].Level:
                    ></AccountOverViewCard>

                    {(channelid !== 21 ||
                        (channelid === 21 &&
                            roleId === ROLE_RBH &&
                            viewForStatus !== 'SELF')) &&
                        <ActivityOverviewCard
                            classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                            selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                            selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                            //selectedView={getSelectedViewFor.length>0 && getSelectedViewFor[0].Level}
                            selectedView={selectedView}
                            roleId={roleId}
                            history={history}
                        >
                        </ActivityOverviewCard>
                    }
                    {/*(viewForStatus === "ABH" || viewForStatus === "BDM" || (viewForStatus === "RBH" && roleId === "ZBH")) &&
                        rbhView &&  */}
                    {channelid !== 3 &&
                        channelid !== 2 && channelid !== 19 &&
                        (channelid !== 21 || viewForStatus !== "SELF") &&
                        ((roleId === ROLE_NH && viewForStatus !== 'SELF') ||
                            (roleId === ROLE_RBH && viewForStatus !== 'SELF') ||
                            (roleId === ROLE_ZBH && viewForStatus !== 'SELF')) &&
                        <>
                            <LeadOverviewCard classes={classes} Item={Item}
                                selectedBDM={selectedBDM}
                                selectedView={selectedView}
                                roleId={roleId}
                                history={history}
                                selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                                selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                                selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}>
                            </LeadOverviewCard>
                        </>
                    }
                    {(channelid === 1) && (roleId !== ROLE_BDM) && viewForStatus !== ROLE_BDM &&
                        ((roleId === ROLE_NH && viewForStatus !== 'SELF') ||
                            (roleId === ROLE_RBH) ||
                            (roleId === ROLE_ZBH && viewForStatus !== 'SELF')) &&
                        <SalesManagementOverviewCard
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            Item={Item} history={history}
                        />}
                    {(channelid === 21 && viewForStatus !== ROLE_BDM) &&
                        <SalesManagementOverviewCardBroca
                            roleId={roleId} classes={classes}
                            Item={Item} history={history}
                        />}
                    {/* (viewForStatus === "ABH" || viewForStatus === "BDM" || (viewForStatus === "RBH" && roleId === "ZBH")) &&
                     rbhView && */}
                    {channelid === 3 &&
                        ((roleId === ROLE_NH && viewForStatus !== 'SELF') ||
                            (roleId === ROLE_RBH && viewForStatus !== 'SELF') ||
                            (roleId === ROLE_ZBH && viewForStatus !== 'SELF')) && <>
                            <BusinessLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history}
                                selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                                selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                                selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                            />
                            <RecruitmentLeadOverviewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history}
                                selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                                selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                                selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                            />
                        </>}
                </Grid>

                {selectedBDM !== 0 && selectedBDM !== undefined &&
                    <Grid item xs={12} md={4}>
                        <CalenderDashboardCard
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                            selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                            selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                            Item={Item} history={history}>
                        </CalenderDashboardCard>

                    </Grid>
                }
                {selectedView === "SELF" &&
                    <Grid item xs={12} md={4}>
                        <CalenderDashboardCard
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} classes={classes}
                            selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                            selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                            selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                            Item={Item} history={history}>
                        </CalenderDashboardCard>

                    </Grid>
                }
                <Grid item xs={12} md={4}>
                    {
                        <Grid item xs={12} md={12} >
                            <MyTeamOverviewCard classes={classes} Item={Item} history={history}
                                selectedBDM={selectedBDM}
                                selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                                selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                                selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                                roleId={roleId}>
                            </MyTeamOverviewCard>
                        </Grid>
                    }
                    {/* viewForStatus === "BDM" ||
                        (viewForStatus === "RBH" && roleId === "ZBH") ||
                        (viewForStatus === "ZBH" && roleId === "NH") || */}
                    {(viewForStatus !== "SELF") &&
                    channelid !== 21 && channelid !== 19 &&
                        <>
                            <PersistencyLeadOverview classes={classes}
                                Item={Item}
                                selectedBDM={selectedBDM}
                                selectedView={selectedView}
                                roleId={roleId}
                                history={history}
                                selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                                selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                                selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}>
                            </PersistencyLeadOverview>
                            {viewForStatus !== "SELF" && channelid === 2 &&
                                <>
                                    <CampaignActivityOverviewCard
                                        classes={classes}
                                        Item={Item}
                                        selectedBDM={selectedBDM}
                                        selectedView={selectedView}
                                        roleId={roleId} history={history}
                                        selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                                        selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                                        selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}>
                                    </CampaignActivityOverviewCard>
                                </>
                            }
                        </>
                    }
                    {roleId === 'RBH' && commonState.userReducer.profileInfo.channelid === 1 && (viewForStatus !== "SELF" && viewForStatus !== "BDM") &&
                        <PehlaKadamOverViewCard classes={classes} Item={Item} selectedBDM={selectedBDM} selectedView={selectedView} roleId={roleId} history={history} />

                    }
                    {(channelid === 1 || channelid === 3) &&
                        (viewForStatus !== "SELF") &&
                        <CampaignLeadOverviewCard
                            classes={classes}
                            Item={Item}
                            selectedBDM={selectedBDM}
                            selectedView={selectedView}
                            roleId={roleId} history={history}
                            selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
                            selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
                            selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
                        />
                    }

                </Grid>

            </Grid>
        </Box>
        <span>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <button className="addItem" onClick={() =>{toggleDrawer(anchor, true)}}>+</button> */}
                    {/* </Box> */}
                    <SwipeableDrawer
                        anchor={anchor}
                        open={togglestate[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </span>
    </>
}

export default DashboardNHRBHZBHMyTeamLayout;