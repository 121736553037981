import React from "react";
import "font-awesome/css/font-awesome.min.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PersonIcon from "@material-ui/icons/Person";
import { useSelector } from 'react-redux';

const MyTeamOverviewCard = ({ classes, history, selectedBDM, selectedSecondLevelSubordinate,
    selectedThirdLevelSubordinate, selectedFourthLevelSubordinate, roleId }) => {
    const commonState = useSelector(state => state);
    const { viewForStatus } = useSelector(state => state.commonReducer)

    const bdmFormState = commonState.leadFormReducer.TeamList;
    const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelTeamList;
    const thirdLevelSubordinateList = commonState.leadFormReducer?.thirdLevelTeamList;
    const fourthLevelSubordinateList = commonState.leadFormReducer?.fourthLevelTeamList;

    let myTeamDetails = {}
    if (selectedFourthLevelSubordinate) {
        myTeamDetails = {
            totalMembers: 0,
            loggedInMembers: 0,
            notLoggedInMembers: 0,
            loggedInMembersPercentage: 0,
            notLoggedInMembersPercentage: 0,
        }
    } else if (selectedThirdLevelSubordinate) {
        myTeamDetails = {
            totalMembers: fourthLevelSubordinateList.TotalUserCount ? fourthLevelSubordinateList.TotalUserCount : 0,
            loggedInMembers: fourthLevelSubordinateList.LoginUserCount ? fourthLevelSubordinateList.LoginUserCount : 0,
            notLoggedInMembers: fourthLevelSubordinateList.NonLoginUserCount ? fourthLevelSubordinateList.NonLoginUserCount : 0,
            loggedInMembersPercentage: fourthLevelSubordinateList.LoginUserCountPercentage ? fourthLevelSubordinateList.LoginUserCountPercentage : 0,
            notLoggedInMembersPercentage: fourthLevelSubordinateList.NonLoginUserCountPercentage ? fourthLevelSubordinateList.NonLoginUserCountPercentage : 0,
        }
    } else if (selectedSecondLevelSubordinate) {
        myTeamDetails = {
            totalMembers: thirdLevelSubordinateList.TotalUserCount ? thirdLevelSubordinateList.TotalUserCount : 0,
            loggedInMembers: thirdLevelSubordinateList.LoginUserCount ? thirdLevelSubordinateList.LoginUserCount : 0,
            notLoggedInMembers: thirdLevelSubordinateList.NonLoginUserCount ? thirdLevelSubordinateList.NonLoginUserCount : 0,
            loggedInMembersPercentage: thirdLevelSubordinateList.LoginUserCountPercentage ? thirdLevelSubordinateList.LoginUserCountPercentage : 0,
            notLoggedInMembersPercentage: thirdLevelSubordinateList.NonLoginUserCountPercentage ? thirdLevelSubordinateList.NonLoginUserCountPercentage : 0,
        }
    } else if (selectedBDM) {
        myTeamDetails = {
            totalMembers: secondLevelSubordinateList.TotalUserCount ? secondLevelSubordinateList.TotalUserCount : 0,
            loggedInMembers: secondLevelSubordinateList.LoginUserCount ? secondLevelSubordinateList.LoginUserCount : 0,
            notLoggedInMembers: secondLevelSubordinateList.NonLoginUserCount ? secondLevelSubordinateList.NonLoginUserCount : 0,
            loggedInMembersPercentage: secondLevelSubordinateList.LoginUserCountPercentage ? secondLevelSubordinateList.LoginUserCountPercentage : 0,
            notLoggedInMembersPercentage: secondLevelSubordinateList.NonLoginUserCountPercentage ? secondLevelSubordinateList.NonLoginUserCountPercentage : 0,
        }
    } else {
        myTeamDetails = {
            totalMembers: bdmFormState.TotalUserCount,
            loggedInMembers: bdmFormState.LoginUserCount,
            notLoggedInMembers: bdmFormState.NonLoginUserCount,
            loggedInMembersPercentage: bdmFormState.LoginUserCountPercentage,
            notLoggedInMembersPercentage: bdmFormState.NonLoginUserCountPercentage,
        }
    }
    const handleClick = (type = 'all') => {
        if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
            if (viewForStatus === "SELF") {
                if (type === 'loggedIn') {
                    history.push('/app/home/myTeam/active');
                } else if (type === 'not-LoggedIn') {
                    history.push('/app/home/myTeam/non-active');
                } else {
                    history.push('/app/home/myTeam/all');
                }
            } else {
                return false
            }
        } else {
            if (type === 'loggedIn') {
                history.push('/app/home/myTeam/active');
            } else if (type === 'not-LoggedIn') {
                history.push('/app/home/myTeam/non-active');
            } else {
                history.push('/app/home/myTeam/all');
            }
        }
    }
    return (
        <Paper className={classes.paper2} sx={{ mb: 2 }}>
            <Grid className="persistanceyOverviewSec" container>
                {/* <ZoomInIcon className="zoomIcon" /> */}
                <Grid item xs={12} md={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom>
                                My Team
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign="right">
                            <p className="viewAll" onClick={() => handleClick('all')}>View All</p>
                        </Grid>
                    </Grid>
                    <Grid container className="greyView greyViewSml">
                        <Grid item xs={6} md={6} sm={3}>
                            <div className="greyViewInner" onClick={() => handleClick('loggedIn')}>
                                <ArrowRightAltIcon className="arrowIcon" />
                                <PersonIcon className="navyBlueBG" />
                                <div className="clearfix"></div>
                                <div className="titleActivity">Logged In Members</div>
                                <div className="numDiv">
                                    <span>{myTeamDetails.loggedInMembers}</span> / {myTeamDetails.totalMembers}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3} md={6}>
                            <div className="greyViewInner" onClick={() => handleClick('not-LoggedIn')}>
                                <ArrowRightAltIcon className="arrowIcon" />
                                <PersonIcon className="blueBG" />
                                <div className="clearfix"></div>
                                <div className="titleActivity">Not Logged In Members</div>
                                <div className="numDiv">
                                    <span>{myTeamDetails.notLoggedInMembers}</span> / {myTeamDetails.totalMembers}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MyTeamOverviewCard;
