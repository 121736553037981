import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';


const ReassignStatusHistory = (props) => {
    const { history, ActivityIDlabel } = props;
    const remarks = history.Remarks ? history.Remarks : 'N/A';

    const historyDate = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('DD MMM YYYY') : ''
    const historyTime = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('h:mma') : "";
    return <>
        {history.LeadStatus === "Reassign" || history.LeadStatus === "Assign" ?
            <> {
                history.LeadStatus === "Reassign" && <>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Reason </label><br></br>
                        <label>{history.ReasonName?history.ReasonName:"N/A"}</label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Scheduled Time </label><br></br>
                        <label>
                            {historyDate}
                            {' '} {historyTime}
                        </label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Remarks </label><br></br>
                        <label>{remarks?remarks:'N/A'}</label>
                    </TimelineContent>
                </TimelineItem>
                </>
            }
            {
                history.LeadStatus === "Assign" && <>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Assigned To </label><br></br>
                        <label>{history.AssignedToName}</label>
                    </TimelineContent>
                </TimelineItem>
                </>
            }
                
            </>
            : ''
        }
    </>
}

export default ReassignStatusHistory
