import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';

const ActivityPlannedHistory = (props) => {
    const { history, ActivityIDlabel } = props
    return <>
        {history.LeadStatus === "New" ?
            <>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>{ActivityIDlabel}</label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Expected Premium  </label><br></br>
                        <label>{parseInt(history.ExpectedPremium).toLocaleString()}</label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Lead Source Name  </label><br></br>
                        <label>{history.LeadSourceName}</label>
                    </TimelineContent>
                </TimelineItem>
            </>
            : ''
        }
    </>
}

export default ActivityPlannedHistory
