import moment from "moment";

const formatDate = "dd/MM/yyyy";
function datedata(option) {
  const dateFormat = 'YYYY-MM-DD';
  switch (option) {
    case "Today":   
      return `${moment(new Date()).format(dateFormat)},${moment(new Date()).format(dateFormat)}`
    case "Yesterday":
      return `${moment().subtract(1, 'days').format(dateFormat)},${moment().subtract(1, 'days').format(dateFormat)}`
    case "thismonth":
      const startOfMonth = moment().startOf("month").format(dateFormat);
      const endOfMonth = moment().endOf("month").format(dateFormat);
      return `${startOfMonth},${endOfMonth}`;
    case 'thisweek':
      const startDate = moment().startOf('week').format(dateFormat);
      const endDate = moment().endOf('week').format(dateFormat);
      return `${startDate},${endDate}`;
    case "7days":
      const daybefor = moment().subtract(7, "days").format(dateFormat);
      const lastday = moment().subtract(1, 'days').format(dateFormat);
      return `${daybefor},${lastday}`;
    case "15days":
      const last15day = moment().subtract(15, "days").format(dateFormat);
      const last1day = moment().subtract(1, 'days').format(dateFormat);
      return `${last15day},${last1day}`;
    case "30days":
      const befornoofday = moment(new Date()).format(dateFormat);
      const yesterday = moment().subtract(29, 'days').format(dateFormat);
      return `${yesterday},${befornoofday}`;
    case "lastmonth":
      const lastmonthfirstday = moment()
        .subtract(1, "month")
        .startOf("month")
        .format(dateFormat);
      const lastmonthlastday = moment()
        .subtract(1, "month")
        .endOf("month")
        .format(dateFormat);
      return `${lastmonthfirstday},${lastmonthlastday}`;
    default:
      return `${null},${null}`
  }
}
export const LEAD_DATE = [
  { key: "Select", label: "Select", value: "" },
  { "key": "today", "label": "Today", "value": datedata('Today') },
  { "key": "yesterday", "label": "Yesterday", "value": datedata('Yesterday') },

  { "key": "thisweek", "label": "This Week", "value": datedata('thisweek') },
  { key: "7days", label: "Last 7 Days", value: datedata("7days") },
  // { key: "15days", label: "Last 15 Days", value: datedata("15days") },
  { key: "thismonth", label: "This Month", value: datedata("thismonth") },
  { key: "30", label: "30 Days", value: datedata("30days") },
  // { key: "lastmonth", label: "Last Month", value: datedata("lastmonth") },
  { key: "duration", label: "custom", value: "duration" }
];
export const filterFormData = [{
  "key": "userType",
  "label": "User Type",
  "hide": true,
  "type": "select",
  "id": "userType",
  "name": "userType",
  "options": [
  ],
  "validators": [{ "name": "required", "value": false }]
},
{
  "key": "teamMember",
  "label": "Team Member",
  "hide": true,
  "type": "select",
  "id": "teamMember",
  "name": "teamMember",
  "options": [
  ],
  "validators": [{ "name": "required", "value": false }]
},
{
  "key": "userName",
  "label": "User Name / Employee Code",
  "id": "userName",
  "name": "userName",
  "options": [
  ],
  "validators": [{ "name": "required", "value": false }]
},
{
  "key": "activityType",
  "label": "Activity Type",
  "type": "select",
  "id": "activityType",
  "name": "activityType",
  "options": [
  ],
  "validators": [{ "name": "required", "value": false }]
},
{
  "key": "branchNameAndCode",
  "label": "Branch Name & Code",
  "type": "autocomplete",
  "id": "branchNameAndCode",
  "name": "branchNameAndCode",
  "hide":false,
  "options": [
  ],
  "validators": [{ "name": "required", "value": false }]
},
{
  "key": "createdOn",
  "label": "Created Date",
  "type": "select",
  "id": "createdOn",
  "name": "createdOn",
  "validators": [],
  options: [...LEAD_DATE]
},
{
  key: "CreatedDateStartTIme",
  label: "From Date",
  type: "date",
  id: "CreatedDateStartTIme",
  hide: true,
  defaultValue: "",
  format: "dd/MM/yyyy",
  validators: []
},
{
  key: "CreatedDateEndTIme",
  label: "To Date",
  type: "date",
  id: "CreatedDateEndTIme",
  hide: true,
  defaultValue: "",
  format: "dd/MM/yyyy",
  validators: []
},
{
  "key": "meetingOn",
  "label": "Meeting Date",
  "id": "meetingOn",
  "name": "meetingOn",
  type: "select",
  disabled: false,
  options: [...LEAD_DATE],
  validators: [],
},
{
  key: "fromdate",
  label: "From Date",
  type: "date",
  id: "fromdate",
  hide: true,
  defaultValue: "",
  format: "dd/MM/yyyy",
  validators: []
},
{
  key: "todate",
  label: "To Date",
  type: "date",
  id: "todate",
  hide: true,
  defaultValue: "",
  format: "dd/MM/yyyy",
  validators: []
},
{
  "key": "LeadTypeID",
  "label": "Activity Status",
  "type": "autocompleteMultiselect",
  "id": "activityStatus",
  "name": "activityStatus",
  "options": [
  ],
  "validators": [{ "name": "required", "value": false }]
},
]


export const BacklogfilterFormData = [
  {
    "key": "userType",
    "label": "User Type",
    "hide": true,
    "type": "select",
    "id": "userType",
    "name": "userType",
    "options": [
    ],
    "validators": [{ "name": "required", "value": false }]
  },
  {
    "key": "teamMember",
    "label": "Team Member",
    "hide": true,
    "type": "select",
    "id": "teamMember",
    "name": "teamMember",
    "options": [
    ],
    "validators": [{ "name": "required", "value": false }]
  },
  {
    "key": "userName",
    "label": "User Name / Employee Code",
    "id": "userName",
    "name": "userName",
    "options": [
    ],
    "validators": [{ "name": "required", "value": false }]
  },
  {
    "key": "activityType",
    "label": "Activity Type",
    "type": "select",
    "id": "activityType",
    "name": "activityType",
    "options": [
    ],
    "validators": [{ "name": "required", "value": false }]
  },
  {
    "key": "branchNameAndCode",
    "label": "Select Branch",
    "type": "autocomplete",
    "id": "branchNameAndCode",
    "name": "branchNameAndCode",
    "options": [
    ],
    "hide": false,
    "validators": [{ "name": "required", "value": false }]
  },
  {
    "key": "lastdate",
    "label": "Date",
    "type": "select",
    "id": "lastdate",
    "name": "lastdate",
    "options": [
    ],
    "validators": [{ "name": "required", "value": false }]
  },
  {
    "key": "formdate",
    "label": "From Date",
    "type": "date",
    "id": "formdate",
    "name": "formdate",
    "hide": true,
    defaultValue: "",
    format: formatDate,
    "validators": [{ "name": "required", "value": false }]
  },
  {
    "key": "todate",
    "label": "To Date",
    "type": "date",
    "id": "todate",
    "name": "todate",
    "hide": true,
    defaultValue: "",
    format: formatDate,
    "validators": [{ "name": "required", "value": false }]
  },
]

