import React , { useState } from "react";
import LeadListingCampaignDatagrid from "./campaignLeadDataGridListing";
import { connect ,useSelector } from "react-redux";
import {
  activateLoader,
} from "../../../redux/actions/common";

export const CampaignLeadAgencyListing = () => {
  const ChannelId = 3;
  const CAMPAIGN_LEAD_AGENCY = "Campaign Agency Lead Listing";
  const filetype = "campaignleadagency";
  const campaignid = "5";
  const fileName = "CampaignLeadsAgency_";

  return (
    <>
      <LeadListingCampaignDatagrid
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_AGENCY}
        filetype={filetype}
        campaignid={campaignid}
        fileName={fileName}
      />
    </>
  );
};

export const CampaignLeadBancaListing = () => {
  const filetype = "campaignlead";
  const ChannelId = 1;
  const CAMPAIGN_LEAD_BANCA = "Campaign Banca Lead Listing";
  const campaignid = "4";
  const fileName = "CampaignLeadsBanca_";

  return (
    <>
      <LeadListingCampaignDatagrid
        ChannelId={ChannelId}
        Listingtitle={CAMPAIGN_LEAD_BANCA}
        filetype={filetype}
        campaignid={campaignid}
        fileName={fileName}
      />
    </>
  );
};

const CampaignLeadListingAll  = (props) => {
  const commonState = useSelector((state) => state);
  const userState = commonState.userReducer;
  const [channelId] = useState(userState.profileInfo.channelid)
  const LEAD_TITLE = "Campaign Lead Listing";
  const fileName = "CampaignLeads_";
return (
  <>
      <LeadListingCampaignDatagrid
      ChannelId={channelId}
      Listingtitle={LEAD_TITLE}
      fileName={fileName}
    />
  </>
);
}

const mapStateToProps = (state) => ({
masterDataState: state.commonReducer,
});
export default (
connect(mapStateToProps, {
  activateLoader,
})(CampaignLeadListingAll)
);