import {
  DROPPED,
  MEETINGSCHEDULE,
  FOLLOWUP,
  CALLBACK,
  restrictPastDateTime,
  ROLE_BDM,
  TRAVEL_EXPENSE_IS_REQUIRED,
  DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
  THIS_IS_REQUIRED,
  MODE_OF_TRANSPORT,
  VEHICLE_TYPE,
  TRAVEL_EXPENSE,
  PLEASE_UPLOAD_BILL_COPY,
  MAXIMUM_FOUR_DIGITS_ALLOWED,
  IMAGE_JPEG,
  APPLICATION_PDF,
  UPLOAD_BILL_PHOTO,
  DATETIME_LOCAL,
  ACTIVE_BOX_SHADOW,
  BORDER_ONE_PIXEL_SOLID_GREY,
  LOAN_PRE_SANCTION_STAGE,
  ASSIGN,
  LOAN_DISBURSEMENT_STAGE,
  MEMBER_FORM_COLLECTED,
  MEDICALSCHEDULED,
  MEDICAL_COMPLETED,
  COI_ISSUED,
  LOAN_NOT_PRE_SANCTION_STAGE,
  LOAN_NOT_SANCTION_STAGE,
  LOAN_SANCTION_STAGE,
} from "../../../../utils/config";
import { isAlphanumeric, isNumberOnly, isPositiveNumber, isTwocharacters, isDigitOnly, isDigitnumberOnly, isAlphanumeric1, isAlphabetWithSpaceOnly } from "../../../../utils/validators";

import moment from "moment";
const date = new Date();
const backThreeMonth=date.setMonth(date.getMonth() - 3);
const getBackThreeMonth=moment(backThreeMonth).endOf("day").format("YYYY-MM-DD")
const endMaxDate = moment(new Date()).endOf("day").format("YYYY-MM-DD kk:mm");
console.log("getBackThreeMonth",getBackThreeMonth)
const remarkValidation= [
  {
    name: "minLength",
    value: 3,
    validationMessage: "Please enter at least 3 Character",
  },
  {
    name: "maxLength",
    value: 50,
    validationMessage: "Please enter less than 50 Character.",
  },
]
export const customValidators = [
  {
    key: "ChequeNumber",
    validator: isNumberOnly,
    message: "Please enter numbers only.",
  },
  {
    key: "COINumber",
    validator: isTwocharacters,
    message: "Please enter first 2 characters",
  },
  {
    key: "COINumber",
    validator: isDigitnumberOnly,
    message: "Please enter digits",
  },
  {
    key: "COINumber",
    validator: isDigitOnly,
    message: "Maximum 8 digits allowed",
  },
  {
    key: "ActualLoanAmountDisbursed",
    validator: isPositiveNumber,
    message: "Actual Loan amount disbursed should be greater than 0.",
  },
  {
    key: "ActualPremiumAmount",
    validator: isPositiveNumber,
    message: "Actual Premium Amount should be greater than 0.",
  },
  {
    key: "OtherReason",
    validator: isAlphanumeric1,
    message: "Please enter alpha numeric only.",
  },
  {
    key: "OtherReason1",
    validator: isAlphanumeric1,
    message: "Please enter alpha numeric only.",
  },
  {
    key: "TravelExpense",
    validator: isPositiveNumber, // function to be called
    message: "Travel Expense should be greater than 0", // message to show
  },
  {
    key: "GCLReasonsforfollowup",
    validator: isAlphanumeric, // function to be called
    message: "Please enter alphanumeric only.", // message to show
  },
  {
    key: "LoanACReferenceNo",
    validator: isAlphanumeric, // function to be called
    message: "Please enter alphanumeric only.", // message to show
  },
  {
    key: "ExpectedPremiumAmount",
    validator: isPositiveNumber, // function to be called
    message: "Expected Premium Amount should be greater than 0",  // message to show
  },
  {
    key: "Remarks",
    validator: isAlphabetWithSpaceOnly, // function to be called
    message: "Please enter alphabets only",  // message to show
  },
];

export const meetingScheduledForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    min: restrictPastDateTime,
    disabled: false,
    id: "MeetingStartDateTime",
    label: "Meeting Start Date & Time",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    min: restrictPastDateTime,
    max: endMaxDate,
    disabled: false,
    id: "MeetingEndDateTime",
    label: "Meeting End Date & Time",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "GCLCallDurationId",
    hide: false,
    type: "select",
    disabled: false,
    id: "GCLCallDurationId",
    label: "Duration",
    validators: [
      { name: "required", value: true, validationMessage: "Please Select the Duration" },
    ],
    options: [],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: "Do You Want to Claim Travel Expense",
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    hide: true,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "RescheduleTravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const callBackForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingStartDateTime",
    label: "Meeting Start Date & Time",
    min: restrictPastDateTime,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage:
        THIS_IS_REQUIRED,
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    min: restrictPastDateTime,
    max: endMaxDate,
    disabled: false,
    id: "MeetingEndDateTime",
    label: "Meeting End Date & Time",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "GCLReasonsforfollowup",
    hide: false,
    type: "text",
    disabled: false,
    id: "GCLReasonsforfollowup",
    label: "Reasons for follow up",
    validators: [
      { name: "required", value: true, validationMessage: "Please Enter Reasons for follow up" },
      {
        name: "minLength",
        value: 3,
        validationMessage: "Please enter at least 3 Character",
      },
      {
        name: "maxLength",
        value: 50,
        validationMessage: "Please enter less than 50 Character.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: "Do You Want to Claim Travel Expense",
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    hide: true,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "RescheduleTravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const followupForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingStartDateTime",
    label: "Meeting Start Date & Time",
    min: restrictPastDateTime,
    noDate: null,
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: DATETIME_LOCAL,
    disabled: false,
    id: "MeetingEndDateTime",
    label: "Meeting End Date & Time",
    min: restrictPastDateTime,
    max: endMaxDate,
    noDate: null,
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "GCLCallDurationId",
    hide: false,
    type: "select",
    disabled: false,
    id: "GCLCallDurationId",
    label: "Duration",
    validators: [
      { name: "required", value: true, validationMessage: "Please select the Duration" },
    ],
    options: [],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: "Do You Want to Claim Travel Expense",
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    hide: true,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "RescheduleTravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const GCLAttachedAtLoanPresanctionStageForm = [
  {
    key: "LoanACReferenceNo",
    hide: false,
    type: "text",
    disabled: false,
    id: "LoanACReferenceNo",
    label: "Loan a/c Reference no.",
    validators: [
      { name: "required", value: true, validationMessage: "Loan a/c Reference no. is required" },
      {
        name: "minLength",
        value: 40,
        validationMessage: "Please enter at least 40 Character",
      },
      {
        name: "maxLength",
        value: 40,
        validationMessage: "Please enter less than 40 Character.",
      },
    ],
  },
  {
    key: "ExpectedPremiumAmount",
    hide: false,
    type: "number",
    disabled: false,
    id: "ExpectedPremiumAmount",
    label: "Expected Premium Amount",
    validators: [
      { name: "required", value: true, validationMessage: "Expected Premium Amount is required" },
      {
        name: "minLength",
        value: 1,
        validationMessage: "Please enter at least 1 digit",
      },
      {
        name: "maxLength",
        value: 7,
        validationMessage: "Please enter less than 7 digit.",
      },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: "Do You Want to Claim Travel Expense",
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    hide: true,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "RescheduleTravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const GCLAttachedAtLoanSanctionStageForm = [
  {
    key: "ActualPremiumAmount",
    hide: false,
    type: "number",
    disabled: false,
    id: "ActualPremiumAmount",
    label: "Actual premium amount",
    validators: [
      { name: "required", value: true, validationMessage: "Actual Premium Amount is required" },
        {
          name: "minLength",
          value: 1,
          validationMessage: "Please enter at least 1 digit",
        },
        {
          name: "maxLength",
          value: 7,
          validationMessage: "Please enter less than 7 digit.",
        },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
];

export const GCLAttachedAtLoanDisbursementStageForm = [
  {
    key: "LoanACNo",
    hide: false,
    type: "number",
    disabled: false,
    id: "LoanACNo",
    label: "Loan A/c number",
    validators: [
      { name: "required", value: true, validationMessage: "Loan A/c number is required" },
      {
        name: "minLength",
        value: 14,
        validationMessage: "Please enter minimum 14 digit.",
      },
      {
        name: "maxLength",
        value: 15,
        validationMessage: "Please enter less than 15 digit.",
      },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
];

export const MemberFormCollected = [
  {
    key: "LoanACNo",
    hide: false,
    type: "number",
    disabled: false,
    id: "LoanACNo",
    label: "Loan A/C No",
    validators: [
      { name: "required", value: true, validationMessage: "Loan A/c number is required" },
      {
        name: "minLength",
        value: 14,
        validationMessage: "Please enter minimum 14 digit.",
      },
      {
        name: "maxLength",
        value: 15,
        validationMessage: "Please enter less than 15 digit.",
      },
    ],
  },
  {
    key: "LoanDisbursementDate",
    hide: false,
    type: "date",
    disabled: false,
    min:getBackThreeMonth,
    max: moment(new Date()).endOf('day').format("YYYY-MM-DD"),
    id: "LoanDisbursementDate.",
    label: "Loan Disbursement date",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "ActualLoanAmountDisbursed",
    hide: false,
    type: "number",
    disabled: false,
    id: "ActualLoanAmountDisbursed",
    label: "Actual Loan amount disbursed",
    validators: [
      { name: "required", value: true, validationMessage: "Actual Loan amount disbursed is required" },
      { name: "minLength", value: 1, validationMessage: "Please enter at least 1 digit" },
      { name: "maxLength", value: 7, validationMessage: "Please enter no more than 7 digit." }
    ],
  },
  {
    key: "ActualPremiumAmount",
    hide: false,
    type: "number",
    disabled: false,
    id: "ActualPremiumAmount",
    label: "Actual Premium amount",
    validators: [
      { name: "required", value: true, validationMessage: "Actual Premium Amount is required" },
      { name: "minLength", value: 1, validationMessage: "Please enter at least 1 digit" },
      { name: "maxLength", value: 7, validationMessage: "Please enter no more than 7 digit." }
    ],
  },
  {
    key: "UploadMemberForm",
    hide: false,
    type: "file",
    dropTitle: "Upload member form",
    acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", "image/bmp", APPLICATION_PDF],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: false,
    disabled: false,
    id: "UploadMemberForm",
    name: "UploadMemberForm",
    label: "Upload member form",
    "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
    validators: [
  { name: "required", value: true, validationMessage: "Please Upload member form" },
],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: "Do You Want to Claim Travel Expense",
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    hide: true,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "RescheduleTravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const gCLNotAttachedLoanSanctionStage = [
  {
    key: "OtherReason",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "ReasonID",
    label: "Reason",
    validators: remarkValidation,
    options: [],
  },
];
export const gCLNotAttachedLoanPreSanctionStage = [
  {
    key: "OtherReason",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "ReasonID",
    label: "Reason",
    validators: remarkValidation,
    options: [],
  },
];

export const COIIssued = [
  {
    key: "COINumber",
    hide: false,
    type: "text",
    disabled: false,
    id: "COINumber",
    label: "COI number",
    validators: [
      { name: "required", value: true, validationMessage: "COI number is required" },
    ],
  },
  {
    key: "GCLDateOfIssue",
    hide: false,
    type: "date",
    disabled: false,
    id: "Dateofissue",
    label: "Date of issue",
    min: getBackThreeMonth,
    max: moment(new Date()).endOf('day').format("YYYY-MM-DD"),
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
];

export const medicalCompleted = [
  {
    key: "MedicalcompletedDate",
    hide: false,
    type: "date",
    disabled: false,
    min:getBackThreeMonth,
    max:moment(new Date()).endOf('day').format("YYYY-MM-DD"),
    id: "medicalCompletedDate",
    label: "Medical completed date",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
];

export const medicalSchedule = [
  {
    key: "LTRFNo",
    hide: false,
    type: "number",
    disabled: false,
    id: "LTRFNo",
    label: "  LTRF no",
    validators: [
      { name: "required", value: true, validationMessage: "LTRF no is required" },
      {
        name: "minLength",
        value: 5,
        validationMessage: "Please enter at least 5 digit.",
      },
      {
        name: "maxLength",
        value: 15,
        validationMessage: "Please enter less than 15 digit.",
      },
    ],
  },
  {
    key: "MedicalScheduledDate",
    hide: false,
    type: "date",
    disabled: false,
    id: "MedicalScheduledDate",
    min:getBackThreeMonth,
    max:moment(new Date()).endOf('day').format("YYYY-MM-DD"),
    label: "Medical scheduled date",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
];

export const droppedForm = [
  {
    key: "ReasonForDropping",
    hide: false,
    type: "select",
    disabled: false,
    id: "ReasonForDropping",
    label: "Reason For Dropped",
    validators: [
      { name: "required", value: true, validationMessage: "Please Select Reason For Drop" },
    ],
    options: [
      {key: "select", label: "select", value: ""},
      {key: "Not_Interested_NI", label: "Not Interested - NI", value: "Not Interested - NI"},
      {key: "Product_not_Suitable", label: "Product not Suitable", value: "Product not Suitable"},
      {key: "Loan_Rejected", label: "Loan Rejected", value: "Loan Rejected"},
      {key: "Not_eligible", label: "Not eligible", value: "Not eligible"},
      {key: "Other", label: "Other", value: "Other"},
//       Not Interested - NI, Product not Suitable, Loan Rejected, Not eligible,
// Other
    ]
  },
  {
    key: "OtherReason1",
    hide: true,
    type: "textarea",
    disabled: false,
    id: "OtherReason",
    label: "Other reason",
    validators: [
      { name: "required", value: true, validationMessage: THIS_IS_REQUIRED },
      {
        name: "minLength",
        value: 3,
        validationMessage: "Please enter at least 3 Character",
      },
      {
        name: "maxLength",
        value: 50,
        validationMessage: "Please enter less than 50 Character.",
      },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "textarea",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: remarkValidation
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    id: "IsClaimRequired",
    label: DO_YOU_WANT_TO_CLAIM_TRAVEL_EXPENSE,
    handleDiameter: 45,
    offColor: "#00427E",
    onColor: "#FFFFFF",
    offHandleColor: "#FFFFFF",
    onHandleColor: "#00427E",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: BORDER_ONE_PIXEL_SOLID_GREY,
    activeBoxShadow: ACTIVE_BOX_SHADOW,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
  {
    key: "TransportModeID",
    hide: true,
    type: "radio",
    disabled: false,
    label: MODE_OF_TRANSPORT,
    validators: [],
    options: [],
  },
  {
    key: "VehicleTypeID",
    hide: true,
    type: "radio",
    disabled: false,
    id: "VehicleTypeID",
    label: VEHICLE_TYPE,
    validators: [],
    options: [],
  },
  {
    key: "TravelExpense",
    props: { min: "1" },
    hide: true,
    type: "number",
    disabled: false,
    id: "TravelExpense",
    label: TRAVEL_EXPENSE,
    validators: [],
  },
  {
    key: "UploadBill",
    hide: true,
    type: "file",
    dropTitle: UPLOAD_BILL_PHOTO,
    acceptFileTypes: [IMAGE_JPEG, "image/jpg", "image/png", APPLICATION_PDF],
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: PLEASE_UPLOAD_BILL_COPY,
    validators: [],
  },
];

export const assign = [
  {
    hide: false,
    key: "AssignedTo",
    type: "autocomplete",
    id: "AssignedTo",
    label: "Select Assignee",
    validators: [],
    options: [],
  },
];



export const getForm = (actionType) => {
  let form = [];
  switch (actionType) {
    case CALLBACK:
      form = callBackForm;
      break;
    case FOLLOWUP:
      form = followupForm;
      break;
    case MEETINGSCHEDULE:
      form = meetingScheduledForm;
      break;
    case LOAN_PRE_SANCTION_STAGE:
      form = GCLAttachedAtLoanPresanctionStageForm;
      break;
    case LOAN_SANCTION_STAGE:
      form = GCLAttachedAtLoanSanctionStageForm;
      break;
    case LOAN_DISBURSEMENT_STAGE:
      form = GCLAttachedAtLoanDisbursementStageForm;
      break;
    case ASSIGN:
      form = assign;
      break;
    case MEMBER_FORM_COLLECTED:
      form = MemberFormCollected;
      break;
    case MEDICALSCHEDULED:
      form = medicalSchedule;
      break;
    case MEDICAL_COMPLETED:
      form = medicalCompleted;
      break;
    case DROPPED:
      form = droppedForm;
      break;
    case COI_ISSUED:
      form = COIIssued;
      break;
    case LOAN_NOT_SANCTION_STAGE:
      form = gCLNotAttachedLoanSanctionStage;
      break;
    case LOAN_NOT_PRE_SANCTION_STAGE:
      form = gCLNotAttachedLoanPreSanctionStage;
      break;
    default:
      form = callBackForm;
      break;
  }
  return form;
};

const formDataModifications = (formFields, fieldIndexes, property, value) => {
  console.log(formFields);
  // const formData = [...formFields];
  for (const index of fieldIndexes) {
    formFields[index][property] = value;
  }
};

export const getOptionById = (id, optionValues) => {
  return optionValues.find((item) => item.ID === parseInt(id));
};

export const getDropdownData = (optionsArr, key, label, value) => {
  let arr = [];
  if (optionsArr && optionsArr.length > 0) {
    arr = optionsArr?.map((option) => {
      return { key: option[key], label: option[label], value: option[value] };
    });
  }
  arr.unshift({ key: "select", label: "select", value: "" });
  return arr;
};

export const removeExtraConcat = (data) => {
  return data.splice(0, 1);
};
export const handleDropdownSelection = (
  event,
  key,
  formFields,
  actionType,
  masterDataState,
  updateLeadData,
  role
) => {
  let formData = [...formFields];
    if (key == "TransportModeID"){
    if (event.target.value === "1") {
      formData.find((val, index) => {
        if (val.key === "VehicleTypeID") {
          formData[index].hide = true;
          formData[index].validators = [];
          } else if(event.target.value === "1"){
          if (val.key === "TransportModeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
          formData[index].hide = false;
          if (val.key === "TravelExpense") {
            formData[index].validators = [
              {
                name: "required",
                value: true,
                validationMessage: TRAVEL_EXPENSE_IS_REQUIRED,
              },
              {
                name: "maxLength",
                value: 4,
                validationMessage: MAXIMUM_FOUR_DIGITS_ALLOWED,
              },
            ];
          }
        }
      }
      })
    } else if (event.target.value === "2") {
        formData.find((val, index) => {
          if (val.key === "VehicleTypeID" || val.key === "TravelExpense" || val.key === "UploadBill") {
            if (role === ROLE_BDM) {
              formData[index].hide = true;
              formData[index].validators = [];
            } else {
              formData[index].hide = false;
              if (val.key === "UploadBill" || val.key === "TravelExpense") {
                formData[index].hide = true;
                formData[index].validators = [];
              }
              if (val.key==="VehicleTypeID"){
                formData[index].validators =  [{name: "required",value: true,validationMessage: "This field is required" }]
              }
            }
          }
        })
    }
  }
  return formData
}
