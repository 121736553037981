import React,{ useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks
} from "date-fns";
import "./commonWeeklyCalender.scss";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const CommonWeeklyCalendar = ({ showDetailsHandle,getCurrentWeekStartAndEndDate }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [ setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());

  const changeWeekHandle = (btnType) => {
    let startDate = null
    let endDate = null
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      startDate=startOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 });
      endDate=lastDayOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 });
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      startDate=startOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 });
      endDate=lastDayOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 });}
    console.log(startDate,endDate)
    getCurrentWeekStartAndEndDate(startDate,endDate)
  };

  const onDateClickHandle = (e,day, dayStr) => {
    setSelectedDate(day);
    showDetailsHandle(e,dayStr,day);
  };

  const renderCellFunction = (e,cloneDay) => {
    const dayStr = format(cloneDay, "ccc dd MMM yy");
   onDateClickHandle(e,cloneDay, dayStr);
  }
const onClickMonthAndYear=(e,str)=>{
    if(str==='prev'){
        setCurrentMonth(subMonths(currentMonth, 1));
    }else{
    setCurrentMonth(addMonths(currentMonth, 1));
    }
}
  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    return (
      <div className="header row flex-middle">
        <div className="col col-start" onClick={(e)=>onClickMonthAndYear(e,'prev')}>
        <ArrowBackIosIcon/>
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={(e)=>onClickMonthAndYear(e,'next')}>
        <ArrowForwardIosIcon/>
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
            isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
            onClick={(e)=>renderCellFunction(e,cloneDay)}
          >
            <span className="number">{formattedDate}</span>
            {/* <span className="bg">{formattedDate}</span> */}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <div className="row calendarMob">
        <div className="col-sm-2 col-1 text-center">
          <span className="arrowBackIcon" style={{cursor:'pointer'}} onClick={() => changeWeekHandle("prev")}>
           <ArrowBackIosIcon/>
          </span>
        </div>
        {/* <div>{currentWeek}</div> */}
        <div className="col-sm-8 col-10"> {renderDays()}
      {renderCells()}</div>
        <div className="col-sm-2 col-1 text-center" onClick={() => changeWeekHandle("next")}>
          <div className="arrowNextIcon" style={{cursor:'pointer'}}><ArrowForwardIosIcon/></div>
        </div>
      </div>
    );
  };
  return (
    <div className="calendar">
      {renderHeader()}
      {renderFooter()}
    </div>
  );
};

export default CommonWeeklyCalendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
