import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';
import { useState } from 'react';
import { getUploadedImage } from '../../../../../redux-reducer/reducer';
import TravelHistory from '../../../common/travelHistory';


const MemberFormCollectedHistory = (props) => {
    const { history, styletimeline } = props
    const [fileData, setfileData] = useState(null)
    const [uploadMemberfileData1, setuploadMemberfileData1] = useState(null)
    const [uploadMemberfileData2, setuploadMemberfileData2] = useState(null)
    const [uploadMemberfileData3, setuploadMemberfileData3] = useState(null)
    const [loader, setLoader] = useState(true)
    const [uploadMemberloader1, setUploadMemberloader1] = useState(true)
    const [uploadMemberloader2, setUploadMemberloader2] = useState(true)
    const [uploadMemberloader3, setUploadMemberloader3] = useState(true)
    const historyDate = history.LoanDisbursementDate ? moment(history.LoanDisbursementDate).format('DD MMM YYYY') : ''

    const getBase64Data = async(key) => {
        if (history.UploadBillFileName === key) {
          setLoader(false)
          const res = await getUploadedImage(history.UploadBillFileName);
          if (res.status === 200 && res.data) {
            setfileData(`${"data:image/png;base64,"}${res.data}`);
            let image = new Image();
            image.src = "data:image/jpg;base64," + res.data;
            let w = window.open("");
            w.document.write(image.outerHTML);
        }
        setLoader(true)
        } else if (history.UploadMemberForm1 === key) {
            setUploadMemberloader1(false)
            const res = await getUploadedImage(history.UploadMemberForm1);
            if (res.status === 200 && res.data) {
                setuploadMemberfileData1(`${"data:image/png;base64,"}${res.data}`);
                let image = new Image();
                image.src = "data:image/jpg;base64," + res.data;
                let w = window.open("");
                w.document.write(image.outerHTML);
            }
          setUploadMemberloader1(true)
        } else if (history.UploadMemberForm2 === key) {
            setUploadMemberloader2(false)
            const res = await getUploadedImage(history.UploadMemberForm2);
            if (res.status === 200 && res.data) {
              setuploadMemberfileData2(`${"data:image/png;base64,"}${res.data}`);
              let image = new Image();
                image.src = "data:image/jpg;base64," + res.data;
                let w = window.open("");
                w.document.write(image.outerHTML);
            }
          setUploadMemberloader2(true)
        } else if (history.UploadMemberForm3 === key) {
            setUploadMemberloader3(false)
            const res = await getUploadedImage(history.UploadMemberForm3);
            if (res.status === 200 && res.data) {
              setuploadMemberfileData3(`${"data:image/png;base64,"}${res.data}`);
              let image = new Image();
                image.src = "data:image/jpg;base64," + res.data;
                let w = window.open("");
                w.document.write(image.outerHTML);
            }
          setUploadMemberloader3(true)
        }
      };

    return <>
        {history.LeadStatusId === 20 ?
            <>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Loan A/C No</label>
                        <label>
                            {history.LoanACNo}
                        </label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Loan Disbursement Date</label>
                        <label>
                            {historyDate}
                        </label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Actual LoanAmount Disbursed</label>
                        <label>
                            {history.ActualLoanAmountDisbursed}
                        </label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Actual Premium amount </label>
                        <label>{history.ActualPremiumAmount}</label>
                    </TimelineContent>
                </TimelineItem>
                {history.UploadMemberForm1 &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        {uploadMemberloader1 == true ? <label className="link-label" onClick={() => getBase64Data(history.UploadMemberForm1)}>Upload Member Form 1</label> : <>Loading</>}
                                {
                                uploadMemberfileData1 &&
                                <img className='bill-copy' alt="uploaded bill" src={uploadMemberfileData1}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
                {history.UploadMemberForm2 &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        {uploadMemberloader2 == true ? <label className="link-label" onClick={() => getBase64Data(history.UploadMemberForm2)}>Upload Member Form 2</label> : <>Loading</>}
                                {
                                uploadMemberfileData2 &&
                                <img className='bill-copy' alt="uploaded bill" src={uploadMemberfileData2}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
                {history.UploadMemberForm3 &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        {uploadMemberloader3 == true ? <label className="link-label" onClick={() => getBase64Data(history.UploadMemberForm3)}>Upload Member Form 3</label> : <>Loading</>}
                                {
                                uploadMemberfileData3 &&
                                <img className='bill-copy' alt="uploaded bill" src={uploadMemberfileData3}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Remark</label>
                        <label><p>{history.Remarks ? history.Remarks : "N/A"}</p></label>
                    </TimelineContent>
                </TimelineItem>
                {history.IsClaimRequired &&
                    <TravelHistory history={history} styletimeline={styletimeline} />
                }
                {history.UploadBill &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        {loader == true ? <label className="link-label" onClick={() => getBase64Data(history.UploadBill)}>View uploaded Bill</label> : <>Loading</>}
                                {
                                fileData &&
                                <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
            </>
            : ''
        }
    </>
}

export default MemberFormCollectedHistory
