import {
  ALLOWED_FUTURE_DAYS,
  BROCA_SMA_ACTIVITY_TYPES_ID,
} from "../../../../utils/config";
import { format } from "date-fns";
import { getDateAfterDays } from "../../../../utils/utils";
import moment from "moment";
const BDMNameErrorMessege = "Please select BDM";
const branchNameLabel = "Branch Name & Code";
const branchNameRequired = "Branch name is required.";
const dateFormate = "yyyy-MM-dd";
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";
const meetingStartDateAndTimeRequired = "Meeting start time is required.";
const meetingEndDateAndTimeRequired = "Meeting end time is required.";
const minDate =
  format(new Date(), dateFormate) + "T" + format(new Date(), "kk:mm");
const endMaxDate = moment(new Date()).endOf("day").format("YYYY-MM-DD kk:mm");

const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
const datetimelocal = "datetime-local";
export const BrocaSmaActivityForm = [
  {
    key: "ActivityID",
    type: "select",
    id: "ActivityID",
    label: "Activity Type",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Activity type is required.",
      },
    ],
    options: [],
  },
];

export const TeamReview = [
  {
    key: "BDMID",
    type: "select",
    id: "BDMID",
    label: "ABH Code",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: BDMNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "BDMName",
    type: "text",
    id: "BDMName",
    label: "ABH Name",
    validators: [
      //   {
      //     name: "required",
      //     value: true,
      //     validationMessage: BDMNameErrorMessege,
      //   },
    ],
    disabled: true,
  },

  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: meetingStartDateAndTimeRequired,
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    max: endMaxDate,
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: meetingEndDateAndTimeRequired,
      },
    ],
  },
];
export const TeamReviewABH = [
  {
    key: "BDMID",
    type: "select",
    id: "BDMID",
    label: "BDM Code",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: BDMNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "BDMName",
    type: "text",
    id: "BDMName",
    label: "BDM Name",
    validators: [
      //   {
      //     name: "required",
      //     value: true,
      //     validationMessage: BDMNameErrorMessege,
      //   },
    ],
    disabled: true,
  },

  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: meetingStartDateAndTimeRequired,
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    max: endMaxDate,
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: meetingEndDateAndTimeRequired,
      },
    ],
  },
];

export const PartnerBranchVisitPlan = [
  {
    key: "BranchId",
    hide: false,
    type: "autocomplete",
    disabled: false,
    id: "BranchId",
    label: branchNameLabel,
    validators: [
      { name: "required", value: true, validationMessage: branchNameRequired },
    ],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    hide: false,
    type: datetimelocal,
    disabled: false,
    id: "MeetingStartDateTime",
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: meetingStartDateAndTimeRequired,
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    hide: false,
    type: datetimelocal,
    disabled: false,
    id: "MeetingEndDateTime",
    min: minDate,
    max: endMaxDate,
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: meetingEndDateAndTimeRequired,
      },
    ],
  },
];

export const initFormData = (
  formFields,
  masterDataState,
  roleId,
  isSMARoute
) => {
  const formData = [...formFields];
  const { Activities } = masterDataState;
  const activity = Activities.filter((item) =>
    BROCA_SMA_ACTIVITY_TYPES_ID.includes(item.value)
  );
  formData[0].options = activity;
  return formData;
};

export const getAutoCompleteItems = (masterDataState, tableName) => {
  const options = [];
  for (const option of masterDataState[tableName]) {
    options.push({
      id: option.value ? option.value.toString() : "",
      label: option.label ? option.label : "",
    });
  }
  return options;
};
export const getAutoCompleteItemsBranch = (masterDataState, tableName) => {
  const options = [];
  for (const option of masterDataState[tableName]) {
    options.push({
      id: option.value ? option.value.toString() : "",
      label: option.label ? `${option.label}-${option.key}` : "",
    });
  }
  return options;
};

export const getOptionById = (id, optionValues) => {
  return optionValues.find((item) => item.value === id);
};

export const removeSubArrayFromArray = (
  subArray,
  keyForSearch,
  arrOfObjects
) => {
  let newArray = [...arrOfObjects];
  for (const object of subArray) {
    newArray = newArray.filter(
      (item) => item[keyForSearch] !== object[keyForSearch]
    );
  }
  return newArray;
};
export const handleSmaActivitySelection = (
  event,
  key,
  formFields,
  masterDataState,
  BDMCode,
  roleId
) => {
  let formData = [...formFields];
  if (key === 'ActivityID'){
      formData = BrocaSmaActivityForm;
      switch (event.target.value) {
        case "54":
          if(roleId==="RBH")
          {
          formData = formData.concat(TeamReview);
          formData[1].options = BDMCode;
          }
          else{
            formData = formData.concat(TeamReviewABH);
            formData[1].options = BDMCode;
          }
          //  formData[2].options =  BDMList;
          break;
        case "55":
          formData = formData.concat(PartnerBranchVisitPlan);
          break;
        default:
          break;
      }
  }
  return formData;
};
