/*  --------------------------------------------------------
            Cancel Activity start
    --------------------------------------------------------  */

export const BankaSalesMangementActivityCancel = [
  {
    key: "SalesManagementIDCancel",
    type: "select",
    id: "SalesMangementID",
    label: "Sales Management Activity Type",
    validators: [
      {
        name: "required",

        value: true,

        validationMessage: "Please select sales management activity type",
      },
    ],

    options: [],
  },
];

//------------------- Cancel Activity 1 ( Schedule BDM ) ---------------------------------

export const CancelledActivitySchedule_BDM = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
];

//------------------- Cancel Activity 2 ( Branch visit plan ) ---------------------------------

export const CancelledActivityBranchvisitplan = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    required: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks,if any",

    validators: [],
  },
  {
    key: "CBIsClaimRequired",
    name: "CBIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CBIsClaimRequired",
    label: "Do you want to claim travel expense?",
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Cancel Activity 3 ( Regional Office meeting ) ---------------------------------

export const CancelledActivityRegionalOfficemeeting = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks,if any",

    validators: [],
  },
  {
    key: "CROIsClaimRequired",
    name: "CROIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CROIsClaimRequired",
    label: "Do you want to claim travel expense?",
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Cancel Activity 4 ( Sales Training plan ) ---------------------------------

export const CancelledActivitySalesTrainingplan = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks,if any",

    validators: [],
  },
];

//------------------- Cancel Activity 5 ( joint call bdm ) ---------------------------------

export const CancelledActivityjointcallbdm = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks,if any",

    validators: [],
  },
  {
    key: "CJIsClaimRequired",
    name: "CJIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CJIsClaimRequired",
    label: "Do you want to claim travel expense?",
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Cancel Activity 6 ( Circular for Sales Enablement ) ---------------------------------

export const CancelledActivityCircularforSalesEnablement = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks,if any",

    validators: [],
  },
];




export const Cancelled_SCHEDULE_STRUCTURED_REVIEW_ABH = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Other_Reason",
    type: "text",
    id: "Other_Reason",
    label: "Other reason",
    hide: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
]

export const Cancelled_BRANCH_VISIT_WITH_BDM_AND_ABH = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Other_Reason",
    type: "text",
    id: "Other_Reason",
    label: "Other reason",
    hide: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
]

export const Cancelled_STRUCTURED_ZONAL_HEAD_MEETING = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Other_Reason",
    type: "text",
    id: "Other_Reason",
    label: "Other reason",
    hide: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
]

export const Cancelled_JOINT_CALLS_WITH_CUSTOMER = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Other_Reason",
    type: "text",
    id: "Other_Reason",
    label: "Other reason",
    hide: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
]

export const Cancelled_REGION_VISIT_WITH_ABH = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Other_Reason",
    type: "text",
    id: "Other_Reason",
    label: "Other reason",
    hide: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
]

export const Cancelled_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM = [
  {
    key: "Reason",
    type: "select",
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Other_Reason",
    type: "text",
    id: "Other_Reason",
    label: "Other reason",
    hide: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
]