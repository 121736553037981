import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';
import TravelHistory from '../common/travelHistory';
import { useState } from 'react';
import { getUploadedImage } from '../../../redux-reducer/reducer';

const FollowUpStatusHistory = (props) => {
    const { history, styletimeline } = props
    const remarks = history.Remarks ? history.Remarks : 'N/A'
    const historyDate = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('DD MMM YYYY') : ''
    const historyTime = history.MeetingStartDateTime ? moment(history.MeetingStartDateTime).format('h:mma') : "";
    // const ExpectedLeadClosureDate = history.ExpectedLeadClosureDate ? history.ExpectedLeadClosureDate : null;

    const [fileData, setfileData] = useState(null)
    const [loader, setLoader] = useState(true)

    const getBase64Data = async() => {
        if (history.UploadBillFileName) {
          setLoader(false)
          const res = await getUploadedImage(history.UploadBillFileName);
          if (res.status === 200 && res.data) {
            setfileData(`${"data:image/png;base64,"}${res.data}`);
            var image = new Image();
            image.src = "data:image/jpg;base64," + res.data;
            var w = window.open("");
            w.document.write(image.outerHTML);
        }
        setLoader(true)
        }
      };

    return <>
        {history.LeadStatus === "Follow-Up Meeting" ?
            <>
                <TimelineItem sx={styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Follow-up Meeting Date</label>
                        <label>{historyDate} {' '} {historyTime}</label>
                    </TimelineContent>
                </TimelineItem>
                {/* {
                    (history.ChannelID === 1 && ExpectedLeadClosureDate != null) &&
                    <TimelineItem sx={props.styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Expected lead closure date</label>
                            <label>
                                {moment(history.ExpectedLeadClosureDate).format('DD MMM YYYY')}
                            </label>
                        </TimelineContent>
                    </TimelineItem>
                } */}
                { (history.ChannelID === 13 || history.ChannelID === 79) ? 
                <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label> Call Duration </label>
                    <label>{history.GCLCallDuration}</label>
                </TimelineContent>
            </TimelineItem>:
                ""}
                <TimelineItem sx={styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Remarks </label>
                        <label>{remarks}</label>
                    </TimelineContent>
                </TimelineItem>
                {history.IsClaimRequired &&
                    <TravelHistory history={history} styletimeline={styletimeline} />
                }
                {history.UploadBill &&
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                        {loader == true ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
                                {
                                fileData &&
                                <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                                }
                        </TimelineContent>
                    </TimelineItem>
                }
            </>
            : ''}
    </>
}

export default FollowUpStatusHistory
