import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "./leadfilter.scss";
import DynamicForm from "f1-dynamic-form-react";
import { leadFilterForm, customValidators } from "./leadGridFilterForm";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { t } from "i18next";
import { useSelector, useDispatch, connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import {
  NHList,
  getFilterByLevel,
  activateErrorMessageBar,
  getSubordinateList,
  activateLoader,
  deActivateLoader,
  getMasterDataForAdminByChannelId,
  getMasterDataForAdmin,
} from "../../../redux/actions";
import {
  ADMIN_HIERARCHY_TYPE,
  LEAD_STATUS as LEAD_OPTION,
} from "../../../utils/config";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopFilter: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function LeadFilter(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((select) => select.userReducer);
  const UserId = userState?.profileInfo?.userId;
  const UserFullName = `${userState?.profileInfo?.firstName} ${userState?.profileInfo?.lastName}`;
  const roleId = userState?.profileInfo?.roleId;
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userName = `${JSON.parse(userData.userReducer).profileInfo.firstName
    } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
  const { leadStatus } = useSelector((statusState) => statusState.leadFormReducer);

  const { SPType } = useSelector((spTypeState) => spTypeState.commonReducer);
  const [state, setState] = useState({
    current: { id: 1 },
    formData: leadFilterForm,
    autoCompleteItems: {
      product: [],
    },
    selectproduct: "",
  });
  const [newUserList, setNewUserList] = useState([
    {
      key: UserId,
      label: UserFullName,
      value: UserId,
    },
  ]);
  const [NhUsers, setNhUsers] = useState([]);
  const [ABHUsers, setABHUsers] = useState([]);

  const [UserID, setUserID] = useState();
  const [productDetails, setProductDetails] = useState([]);
  const [spType, setSpType] = useState([]);
  const [count, setCount] = useState(0);
  const dynamicRef = React.useRef();
  const getDropdownOFData = (optionsArr, id, label) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { id: `${option[id]}`, label: `${option[label]}` };
      });
    }
    arr.shift({ id: " ", label: "select" });
    arr.unshift({ id: " ", label: "select" });
    return arr;
  };
  let flag;

  useEffect(() => {
    NhUsers.push({ key: "Select", label: "Select", value: "Select" });
    getNhList();
    leadFilterForm[1].hide = true;
    leadFilterForm[2].hide = true;
    leadFilterForm[3].hide = true;
    leadFilterForm[4].hide = true;
    leadFilterForm[5].hide = true;
    leadFilterForm[6].hide = true;
    leadFilterForm[7].hide = true;
    leadFilterForm[11].hide = true;
    leadFilterForm[12].hide = true;
    leadFilterForm[15].hide = true;
    leadFilterForm[16].hide = true;
    if (
      history?.location?.pathname === "/app/admin/businesslead/agency/all" ||
      (props.chanelId === 3 &&
        history?.location?.pathname === "/app/report/businesslead/all")
    ) {
      leadFilterForm.find((item) => item.key === "typeOfSP").hide = true;
      leadFilterForm.find((item) => item.key === "branchNameCode").hide = true;
      leadFilterForm.find((item) => item.key === "LeadTypeID").hide = false;
      leadFilterForm.find((item) => item.key === "product").hide = false;
      leadFilterForm.find((item) => item.key === "campaign").hide = false;
      leadFilterForm.find((item) => item.key === "activityID").hide = false;
      leadFilterForm.find((item) => item.key === "MobileNumber").hide = false;
    }
    if (
      history?.location?.pathname === "/app/admin/recruitmentlead/agency/all" ||
      (props.chanelId === 3 &&
        history?.location?.pathname === "/app/report/recruitmentlead/all")
    ) {
      leadFilterForm.find((item) => item.key === "typeOfSP").hide = true;
      leadFilterForm.find((item) => item.key === "branchNameCode").hide = true;
      leadFilterForm.find((item) => item.key === "LeadTypeID").hide = true;
      leadFilterForm.find((item) => item.key === "product").hide = true;
      leadFilterForm.find((item) => item.key === "campaign").hide = false;
      leadFilterForm.find((item) => item.key === "activityID").hide = false;
      leadFilterForm.find((item) => item.key === "MobileNumber").hide = false;
    }
    if (
      history?.location?.pathname === "/app/admin/businesslead/banca/all" ||
      ((props.chanelId === 1) &&
        history?.location?.pathname === "/app/report/businesslead/all")
    ) {
      leadFilterForm.find((item) => item.key === "typeOfSP").hide = false;
      leadFilterForm.find((item) => item.key === "branchNameCode").hide = false;
      leadFilterForm.find((item) => item.key === "LeadTypeID").hide = false;
      leadFilterForm.find((item) => item.key === "product").hide = false;
      leadFilterForm.find((item) => item.key === "campaign").hide = false;
      leadFilterForm.find((item) => item.key === "activityID").hide = false;
      leadFilterForm.find((item) => item.key === "MobileNumber").hide = false;
    }
    if (history?.location?.pathname === "/app/admin/businesslead/broca/all" ||
      (props.chanelId === 21 &&
        history?.location?.pathname === "/app/report/businesslead/all")) {
      leadFilterForm.find((item) => item.key === "typeOfSP").hide = true;
      leadFilterForm.find((item) => item.key === "branchNameCode").hide = true;
      leadFilterForm.find((item) => item.key === "campaign").hide = true;
      leadFilterForm.find((item) => item.key === "product").hide = true;
      leadFilterForm.find((item) => item.key === "LeadTypeID").hide = true;
      leadFilterForm.find((item) => item.key === "activityID").hide = false;
      leadFilterForm.find((item) => item.key === "MobileNumber").hide = false;
    }
    if (props.chanelId === 79 && history?.location?.pathname === "/app/report/businesslead/all") {
      leadFilterForm.find((item) => item.key === "typeOfSP").hide = true;
      leadFilterForm.find((item) => item.key === "branchNameCode").hide = true;
      leadFilterForm.find((item) => item.key === "LeadTypeID").hide = true;
      leadFilterForm.find((item) => item.key === "product").hide = true;
      leadFilterForm.find((item) => item.key === "campaign").hide = true;
      leadFilterForm.find((item) => item.key === "activityID").hide = false;
      leadFilterForm.find((item) => item.key === "MobileNumber").hide = false;
    }
    if (props.chanelId === 13 &&
      (history?.location?.pathname === "/app/report/businesslead/all" ||
        history?.location?.pathname === "/app/admin/businesslead/gcl/all")) {
          ABHUsers.push({ key: "Select", label: "Select", value: "Select" });
          getABHList();
      leadFilterForm.find((item) => item.key === "typeOfSP").hide = true;
      leadFilterForm.find((item) => item.key === "LeadTypeID").hide = false;
      leadFilterForm.find((item) => item.key === "product").hide = true;
      leadFilterForm.find((item) => item.key === "campaign").hide = true;
    }
  }, []);

  useEffect(() => {
    const getProductIdChannelId = async () => {
      const response = await props.getMasterDataForAdmin({
        tableName: "ProductDetails",
        config: {
          TABLE_NAME: "ProductDetails",
          KEY: "ProductId",
          LABEL: "ProductName",
          CODE: "ProductId",
          CHANNEL_ID: "",
          FIELD_NAME: "ProductId",
        },
      });
      const data = response.data.data.ProductDetails;
      setProductDetails(data);
    };
    if (!productDetails.length) {
      getProductIdChannelId();
    } else if (productDetails.length) {
      const autoCompleteItems = { ...state.autoCompleteItems };
      autoCompleteItems.product = getDropdownOFData(
        productDetails,
        "key",
        "label"
      );
      const formData = initFormData(state.formData);
      setState({ formData: formData, autoCompleteItems: autoCompleteItems });
    }
  }, [productDetails]);

  useEffect(() => {
    const getSPTypeIdChannelId = async () => {
      const response = await props.getMasterDataForAdmin({
        tableName: "SPType",
        config: {
          TABLE_NAME: "SPType",
          KEY: "SPTypeID",
          LABEL: "SPTypeName",
          CODE: "SPTypeCode",
          CHANNEL_ID: "",
          FIELD_NAME: "SPTypeID",
        },
      });
      const data = response.data.data.SPType;
      setSpType(data);
    };
    if (!spType.length) {
      getSPTypeIdChannelId();
    } else if (spType.length) {
      initFormData(state.formData);
    }
  }, [spType]);

  const getSub = async (key, getUser) => {
    dispatch(getSubordinateList({ userId: getUser })).then((response) => {
      const r = response.data.map((el) => {
        return {
          key: el.userID,
          label: el.userName,
          value: el.userID,
        };
      });
      setNewUserList([newUserList, ...r]);
      if (key === "User") {
        leadFilterForm[4].options = [newUserList, ...r];
      } else if (key === "UserOne") {
        leadFilterForm[5].options = [newUserList, ...r];
      } else if (key === "UserTwo") {
        leadFilterForm[6].options = [newUserList, ...r];
      } else if (key === "UserThree") {
        leadFilterForm[7].options = [newUserList, ...r];
      }
      props.deActivateLoader();
    });
  };

  const getNhList = async () => {
    const result = await props.NHList({ channelId: props.chanelId });
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setNhUsers([...NhUsers, ...r]);
    leadFilterForm[3].options = [...NhUsers, ...r];
  };

  const getABHList = async () => {
    const result = await props.getFilterByLevel({ userLevel: "ABH",channelId: props.chanelId });
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setABHUsers([...ABHUsers, ...r]);
    leadFilterForm[6].options = [...ABHUsers, ...r];
  };

  const onSubmitHandler = (model) => {
    let {
      CreatedDateTime,
      LeadName,
      fromdate,
      todate,
      MeetingDate,
      MobileNo,
      teamMember,
      activityID,
      CreatedDateStartTIme,
      CreatedDateEndTIme,
      currentstatus,
      branchNameCode,
      EmployeeCode,
    } = model;
    if (LeadName === "" || LeadName === "null" || LeadName === 0) {
      LeadName = null;
    }
    if (MobileNo === "" || MobileNo === "null" || MobileNo === 0) {
      MobileNo = null;
    }
    if (
      branchNameCode === "" ||
      branchNameCode === "null" ||
      branchNameCode === 0
    ) {
      branchNameCode = null;
    }
    if (activityID === "" || activityID === "null" || activityID === 0) {
      activityID = null;
    }
    if (teamMember === "" || teamMember === undefined) {
      teamMember = null;
    }
    if (EmployeeCode === "" || EmployeeCode === "null" || EmployeeCode === 0) {
      EmployeeCode = null;
    }
    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    if (
      MeetingDate !== "null" &&
      MeetingDate !== "duration" &&
      MeetingDate !== undefined
    ) {
      const lastdatearr = model.MeetingDate.split(",");
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (
      CreatedDateTime !== "null" &&
      CreatedDateTime !== "duration" &&
      CreatedDateTime !== undefined
    ) {
      const lastdatearr = model.CreatedDateTime.split(",");
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }

    if (MeetingDate === "duration") {
      if (fromdate === "null" || todate === "null") {
        dispatch(
          activateErrorMessageBar(
            "Please Select Value For Both Meeting Start Date and End Date"
          )
        );
        return false;
      }
      meetingstart = model.fromdate;
      meetingend = model.todate;
    }
    if (CreatedDateTime === "duration") {
      if (CreatedDateStartTIme === "null" || CreatedDateEndTIme === "null") {
        dispatch(
          activateErrorMessageBar(
            "Please Select Value For Both Created Start Date and End Date"
          )
        );
        return false;
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme;
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;

    model.CreatedDateStartTIme = createdstart;
    model.CreatedDateEndTIme = createdend;
    for (const item in model) {
      if (
        model[item] === "" &&
        item !== LeadName &&
        item !== MobileNo &&
        item !== branchNameCode &&
        item !== activityID &&
        item !== EmployeeCode
      ) {
        model[item] = 0;
      }
    }
    const status = currentstatus?.map((val) => {
      return val.value;
    });
    model.currentStatus = status?.join(",");
    if (model.currentStatus === "") {
      model.currentStatus = null;
    }

    if (
      CreatedDateTime !== "null" ||
      LeadName !== null ||
      model.LeadTypeID !== 0 ||
      MeetingDate !== "null" ||
      MobileNo !== null ||
      activityID !== 0 ||
      branchNameCode !== 0 ||
      model.currentStatus !== 0 ||
      model.leadSource !== 0 ||
      model.product !== 0 ||
      model.typeOfSP !== 0 ||
      teamMember !== null ||
      EmployeeCode !== null
    ) {
      props.handleClose();
      props.getFilteredLeads(model, teamMember);
    } else {
      dispatch(activateErrorMessageBar("Please select anyone to apply filter"));
    }
  };
  const onResetHandler = () => {
    props.setFilterState({
      ...props.filterState,
      CreatedDateTime: "null",
      MeetingOn: null,
      currentStatus: 0,
      currentstatus: [],
      branchNameCode: "",
      typeOfSP: 0,
      product: 0,
      leadSource: 0,
      LeadTypeID: 0,
      activityID: "",
      LeadName: "",
      MeetingDate: "null",
      fromdate: "null",
      todate: "null",
      MobileNumber: "",
      CreatedDateStartTIme: "null",
      CreatedDateEndTIme: "null",
      Modifiedstartdate: "null",
      Modifiedenddate: "null",
      MobileNo: "",
      productlabel: "",
      UserID: null,
      NhUsers: [],
      newUserList: [],
      teamMember: null,
      User: null,
      UserOne: null,
      UserTwo: null,
      UserThree: null,
      UserFour: null,
      EmployeeCode: null,
    });
    leadFilterForm[1].hide = true;
    leadFilterForm[2].hide = true;
    leadFilterForm[3].hide = true;
    leadFilterForm[4].hide = true;
    leadFilterForm[5].hide = true;
    leadFilterForm[6].hide = true;
    leadFilterForm[7].hide = true;
    leadFilterForm[11].hide = true;
    leadFilterForm[12].hide = true;
    leadFilterForm[15].hide = true;
    leadFilterForm[16].hide = true;
    leadFilterForm[17].hide = true;
    leadFilterForm[18].hide = true;
    props.getAllLeads();
  };

  const increment = () => {
    setCount(count + 1);
  };

  const onChangeHandler = async (e, key, model) => {
    console.log("key is", key);
    if (key === "userType") {
      const radioValue = e.target.value;
      if (radioValue === "2") {
        increment();
        console.log("count is", count);
      }
      if (count >= 1) {
        props.setFilterState({
          ...props.filterState,
          teamMember: 0,
          User: 0,
          EmployeeCode: null,
          currentstatus: model?.currentstatus
        });
      }
    }
    if (key === "User") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "UserOne") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "UserTwo") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "UserThree") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "UserFour") {
      props.setFilterState({
        ...props.filterState,
        UserID: e.target.value,
        currentstatus: model?.currentstatus
      });
    }

    if (key === "EmployeeCode") {
      props.setFilterState({
        ...props.filterState,
        EmployeeCode: e.target.value,
        UserID: 0,
        currentstatus: model?.currentstatus
      });
    }

    if (key === "LeadName") {
      props.setFilterState({
        ...props.filterState,
        LeadName: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "MobileNumber") {
      props.setFilterState({
        ...props.filterState,
        MobileNo: e.target.value,
        MobileNumber: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "activityID") {
      props.setFilterState({
        ...props.filterState,
        activityID: e.target.value,
        currentstatus: model?.currentstatus
      });
    }

    if (key === "branchNameCode") {
      props.setFilterState({
        ...props.filterState,
        branchNameCode: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "LeadTypeID") {
      props.setFilterState({
        ...props.filterState,
        LeadTypeID: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "CreatedDateTime") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateTime: e.target.value,
        currentstatus: model?.currentstatus
      });
      if (e.target.value === "duration") {
        leadFilterForm[11].hide = false;
        leadFilterForm[12].hide = false;
      } else {
        leadFilterForm[11].hide = true;
        leadFilterForm[12].hide = true;
      }
    }
    if (key === "currentStatus") {
      props.setFilterState({
        ...props.filterState,
        currentStatus: e.target.value,
        // currentstatus: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "currentstatus") {
      const restrictedArr = ["DRAFTED", "BACKLOG", "AUTO_CLOSED", "PREMIUM_COLLECTED"]
      const currenstatusoption = LEAD_STATUS.filter((item) => !restrictedArr.includes(item.key))
      let keys = Object.keys(e.currentTarget);
      let selectedValue = e.currentTarget[keys[1]].children;
      let selectedevent = e.currentTarget[keys[1]]["data-testid"];
      let clearevent = e.currentTarget[keys[1]]["aria-label"];
      let arr_status = LEAD_STATUS
      if (props.chanelId == 3) {
        arr_status = processCurrentStatusOption([...currenstatusoption, { key: 'OPENLEADS', label: 'Open Leads', value: '2,3,4,7' }]);
      } else {
        arr_status = LEAD_STATUS
      }
      if (selectedevent === "CancelIcon" || clearevent === "Clear") {
        flag = 1;
        if (clearevent === "Clear") {
          props.setFilterState({ ...props.filterState, currentstatus: [] });
        }
      } else {
        if (typeof selectedValue != "object" && selectedValue !== "select" && flag != 1) {
          let filter_Status;
          if (props.chanelId == 3) {
            filter_Status = arr_status.find(
              (item) => item.key == selectedValue,
            );
          } else {
            filter_Status = arr_status.find(
              (item) => item.label == selectedValue,
            );
          }
          if (model?.currentstatus?.length > 0) {
            if (props.chanelId == 3) {
              let arr = model?.currentstatus.find(
                (item) => item.key == selectedValue,
              );
              if (arr === undefined) {
                model?.currentstatus.push(filter_Status);
              }
            } else {
              let arr = model?.currentstatus.find(
                (item) => item.label == selectedValue,
              );
              if (arr === undefined) {
                model?.currentstatus.push(filter_Status);
              }
            }
          } else {
            model?.currentstatus?.push(filter_Status);
          }
          props.setFilterState({
            ...props.filterState,
            currentstatus: model?.currentstatus,
          });
        }
      }
    }

    if (key === "MeetingOn") {
      props.setFilterState({
        ...props.filterState,
        MeetingOn: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "leadSource") {
      props.setFilterState({
        ...props.filterState,
        leadSource: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "product") {
      const productNameValue = productDetails.filter((el) => el.key === parseInt(e.target.value))
      props.setFilterState({
        ...props.filterState,
        product: e.target.value,
        productlabel: productNameValue[0]?.label,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "typeOfSP") {
      props.setFilterState({
        ...props.filterState,
        typeOfSP: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "MeetingDate") {
      props.setFilterState({
        ...props.filterState,
        MeetingDate: e.target.value,
        currentstatus: model?.currentstatus
      });
      if (e.target.value === "duration") {
        leadFilterForm[15].hide = false;
        leadFilterForm[16].hide = false;
      } else {
        leadFilterForm[15].hide = true;
        leadFilterForm[16].hide = true;
      }
    }
    if (key === "CreatedDateStartTIme") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateStartTIme: e.target.value,
        currentstatus: model?.currentstatus
      });
      leadFilterForm[12].min = e.target.value;
    }
    if (key === "CreatedDateEndTIme") {
      props.setFilterState({
        ...props.filterState,
        CreatedDateEndTIme: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "Modifiedstartdate") {
      props.setFilterState({
        ...props.filterState,
        Modifiedstartdate: e.target.value,
        currentstatus: model?.currentstatus
      });
      leadFilterForm[18].min = e.target.value;
    }
    if (key === "Modifiedenddate") {
      props.setFilterState({
        ...props.filterState,
        Modifiedenddate: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (key === "fromdate") {
      props.setFilterState({
        ...props.filterState,
        fromdate: e.target.value,
        currentstatus: model?.currentstatus
      });
      leadFilterForm[16].min = e.target.value;
    }
    if (key === "todate") {
      props.setFilterState({
        ...props.filterState,
        todate: e.target.value,
        currentstatus: model?.currentstatus
      });
    }
    if (userState.profileInfo.roleId === "ADM" && key === "userType") {
      const getUserType = e.target.value;
      if (getUserType === "1") {
        props.setFilterState({
          ...props.filterState,
          teamMember: null,
          User: null,
          UserOne: null,
          UserTwo: null,
          UserThree: null,
          UserFour: null,
          UserID: null,
          currentstatus: model?.currentstatus
        });
        props.setLeadReq({
          ...props.leadReq,
          UserId: null,
        })

        leadFilterForm[1].hide = false;
        leadFilterForm[2].hide = true;
        leadFilterForm[3].hide = true;
        leadFilterForm[4].hide = true;
        leadFilterForm[5].hide = true;
        leadFilterForm[6].hide = true;
        leadFilterForm[7].hide = true;
      } else if (getUserType === "2") {
        props.setFilterState({
          ...props.filterState,
          EmployeeCode: null,
          UserID: null,
          // UserCode: null,
          currentstatus: model?.currentstatus
        });
        props.setLeadReq({
          ...props.leadReq,
          EmployeeCode: null,
        })

        leadFilterForm[2].hide = false;
        leadFilterForm[3].hide = true;
        leadFilterForm[1].hide = true;
        leadFilterForm[6].hide = true;
        leadFilterForm[4].hide = true;
        leadFilterForm[5].hide = true;
        leadFilterForm[7].hide = true;
      }
    }
    if (key === "teamMember") {
      const getUser = e.target.value;
      if (getUser === "Team") {
        if (props.chanelId === 13) {
          leadFilterForm[6].hide = false;
        } else {
          leadFilterForm[3].hide = false;
        }
      } else {
        leadFilterForm[3].hide = true;
        leadFilterForm[4].hide = true;
        leadFilterForm[5].hide = true;
        leadFilterForm[6].hide = true;
        leadFilterForm[7].hide = true;
      }
    }
    if (key === "User") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        leadFilterForm[4].hide = false;
        leadFilterForm[5].hide = true;
        leadFilterForm[6].hide = true;
        leadFilterForm[7].hide = true;
      }
    }
    if (key === "UserOne") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        leadFilterForm[5].hide = false;
        leadFilterForm[6].hide = true;
        leadFilterForm[7].hide = true;
      }
    }
    if (key === "UserTwo") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        leadFilterForm[6].hide = false;
      }
    }
    if (key === "UserThree") {
      props.activateLoader();
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
      await getSub(key, getUser);
      if (getUser) {
        leadFilterForm[7].hide = false;
      }
    }
    if (key === "UserFour") {
      const getUser = e.target.value;
      setUserID(getUser);
      console.log("last user's user id is:", UserID);
    }
    // if (userState.profileInfo.roleId === "ADM" && key === "userType" && props.chanelId == 13) {
    //   // console.log("inside of ", userRole);
    //   setUserID(userState.profileInfo.userId);
    //   await getSub("UserThree", userState.profileInfo.userId);
    //   if (userState.profileInfo.userId) {
    //     leadFilterForm[7].hide = false;
    //     leadFilterForm[6].hide = true;
    //     leadFilterForm[5].hide = true;
    //     leadFilterForm[4].hide = true;
    //     leadFilterForm[2].hide = true;
    //   }
    // }
    if ((userState.profileInfo.roleId !== "ADM" && key === "userType")) {
      props.setFilterState({
        ...props.filterState,
        EmployeeCode: null,
        currentstatus: model?.currentstatus
      });
      manageUserHeirarcheyForNonAdminUsers(e.target.value, key);
    }
  };
  const manageUserHeirarcheyForNonAdminUsers = async (
    userId,
    key
  ) => {
    props.activateLoader();
    console.log("leadFilterForm ", leadFilterForm);
    // if user is non admin, set filter level accordingly
    if (userState.profileInfo.roleId !== "ADM") {
      const userRole = userState.profileInfo.roleId;
      switch (userRole) {
        case "BDM":
          console.log("inside of ", userRole);
          break;

        case "ABH":
          console.log("inside of ", userRole);
          setUserID(userState.profileInfo.userId);
          await getSub("UserThree", userState.profileInfo.userId);
          if (userState.profileInfo.userId) {
            leadFilterForm[7].hide = false;
            leadFilterForm[6].hide = true;
            leadFilterForm[5].hide = true;
            leadFilterForm[4].hide = true;
            leadFilterForm[2].hide = true;
          }
          break;
        case "RBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("UserTwo", userState.profileInfo.userId);
          leadFilterForm[7].hide = false;
          leadFilterForm[6].hide = false;
          leadFilterForm[5].hide = true;
          leadFilterForm[4].hide = true;
          leadFilterForm[2].hide = true;
          break;

        case "ZBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("UserOne", userState.profileInfo.userId);
          leadFilterForm[7].hide = false;
          leadFilterForm[6].hide = false;
          leadFilterForm[5].hide = false;
          leadFilterForm[4].hide = true;
          leadFilterForm[2].hide = true;
          break;

        case "NH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("User", userState.profileInfo.userId);
          leadFilterForm[7].hide = false;
          leadFilterForm[6].hide = false;
          leadFilterForm[5].hide = false;
          leadFilterForm[4].hide = false;
          leadFilterForm[2].hide = true;
          break;

        default:
          break;
      }
    }
  };
  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "Select", value: "" });
    return arr;
  };
  let LEAD_STATUS = getDropdownData(
    leadStatus,
    "LeadStatus",
    "LeadStatus",
    "LeadStatusId"
  );

  if (
    history?.location?.pathname === "/app/admin/businesslead/banca/all" ||
    ((props.chanelId === 1 || props.chanelId === 21) &&
      history?.location?.pathname === "/app/report/businesslead/all")
  ) {
    LEAD_STATUS = LEAD_STATUS?.filter(
      (ele) =>
        ele.value === 1 ||
        ele.value === 2 ||
        ele.value === 3 ||
        ele.value === 4 ||
        ele.value === 5 ||
        ele.value === 6 ||
        ele.value === 7 ||
        ele.value === 8 ||
        ele.value === 10
    );
  }

  if (
    history?.location?.pathname === "/app/admin/recruitmentlead/agency/all" ||
    (props.chanelId === 3 &&
      history?.location?.pathname === "/app/report/recruitmentlead/all")
  ) {
    LEAD_STATUS = LEAD_STATUS?.filter(
      (ele) =>
        ele.value === 2 ||
        ele.value === 3 ||
        ele.value === 4 ||
        ele.value === 5 ||
        ele.value === 6 ||
        ele.value === 7
    );
  }
  if (
    history?.location?.pathname === "/app/admin/businesslead/agency/all" ||
    (props.chanelId === 3 &&
      history?.location?.pathname === "/app/report/businesslead/all")
  ) {
    LEAD_STATUS = LEAD_STATUS?.filter(
      (ele) =>
        ele.value === 1 ||
        ele.value === 2 ||
        ele.value === 3 ||
        ele.value === 4 ||
        ele.value === 5 ||
        ele.value === 6 ||
        ele.value === 7 ||
        ele.value === 8 ||
        ele.value === 10
    );
  }
  if (props.chanelId === 13 || props.chanelId === 79) {
    LEAD_STATUS = LEAD_STATUS?.filter(
      (ele) =>
        ele.value === 1 ||
        ele.value === 2 ||
        ele.value === 3 ||
        ele.value === 4 ||
        ele.value === 5 ||
        ele.value === 6 ||
        ele.value === 7 ||
        ele.value === 8 ||
        ele.value === 10 ||
        ele.value === 15 ||
        ele.value === 16 ||
        ele.value === 17 ||
        ele.value === 18 ||
        ele.value === 19 ||
        ele.value === 20 ||
        ele.value === 21 ||
        ele.value === 22 ||
        ele.value === 23 ||
        ele.value === 24
    );
  }
  const onAutocompleteSelectHandler = (key, value, item) => {
    // if (key === "branchNameCode") {
    //   setFilterState({
    //     branchNameCode: item.value
    //   })
    // }
  };
  const processCurrentStatusOption = (csOption) => {
    const processedOption = [];
    if (
      history?.location?.pathname === "/app/admin/businesslead/banca/all" ||
      ((props.chanelId === 1 || props.chanelId === 21) &&
        history?.location?.pathname === "/app/report/businesslead/all")
    ) {
      csOption.map((option) => {
        processedOption.push({
          key: option.key !== "select" ? `${option.key}` : option.key,
          label: option.label,
          value: option.value,
        });
      });
      return processedOption;
    } else if (
      history?.location?.pathname === "/app/admin/businesslead/agency/all" ||
      (props.chanelId === 3 &&
        history?.location?.pathname === "/app/report/businesslead/all")
    ) {
      csOption.map((option) => {
        processedOption.push({
          key:
            option.key !== "select"
              ? `Businesslead - ${option.key}`
              : option.key,
          label: option.label,
          value: option.value,
        });
      });
      return processedOption;
    } else if (
      history?.location?.pathname === "/app/admin/recruitmentlead/agency/all" ||
      (props.chanelId === 3 &&
        history?.location?.pathname === "/app/report/recruitmentlead/all")
    ) {
      csOption.map((option) => {
        processedOption.push({
          key:
            option.key !== "select"
              ? `Recruitmentlead - ${option.key}`
              : option.key,
          label: option.label,
          value: option.value,
        });
      });
      return processedOption;
    } else if (props.chanelId === 1) {
      let csOptionfilter = csOption.filter(
        (opt) =>
          opt.value != 11 &&
          opt.value != 12 &&
          opt.value != 13 &&
          opt.value != 14 &&
          opt.value != "2,3,4,7"
      );
      return csOptionfilter;
    } else {
      return csOption;
    }
  };
  const initFormData = (formFields) => {
    const restrictedArr = [
      "DRAFTED",
      "BACKLOG",
      "AUTO_CLOSED",
      "PREMIUM_COLLECTED",
    ];
    const currenstatusoption = LEAD_STATUS.filter(
      (item) => !restrictedArr.includes(item.key)
    );
    const formData = [...formFields];
    formData[0].options = ADMIN_HIERARCHY_TYPE;
    props.setFilterState({ ...props.filterState, UserName: userName });
    formData[18].options = productDetails;
    formData[9].options = LEAD_OPTION;

    if (roleId === "BDM" || roleId === "ABH") {
      let banca_sp_type = ["SRM"];
      let banca_sp_arr = [];
      if (SPType && SPType.length > 0) {
        banca_sp_arr = SPType.filter((option) => {
          if (!banca_sp_type.includes(option.key)) {
            return option;
          }
        });
      }
      formData[22].options = banca_sp_arr;
    } else if (roleId === "FPM") {
      const sp_type = ["STAFF"];
      let filter_arr = [];
      if (SPType && SPType.length > 0) {
        filter_arr = SPType.filter((option) => {
          if (!sp_type.includes(option.key)) {
            return option;
          }
        });
      }
      formData[22].options = filter_arr;
    } else {
      formData[22].options = spType;
    }
    formData[13].options = processCurrentStatusOption([...currenstatusoption]);

    return formData;
  };
  const classes = useStyles();

  return (
    <div className={props.anchor ? classes.drawerMobile : classes.desktopFilter}>
      <div className="filterBox">
        <h4 className="filterSec">
          {" "}
          <FilterAltIcon className="icnFilter" /> Filter By{" "}
          <CloseIcon
            className={classes.drawerMobile}
            style={{ float: "right", cursor: "pointer" }}
            onClick={props.handleClose}
          />
        </h4>
        <div className="fformBox">
          <DynamicForm
            key={1}
            ref={dynamicRef}
            className="dynamicForm leadFilters  leadFiltersMobile"
            title={" "}
            buttonSubmit={t("Apply Filter")}
            defaultValues={props.filterState}
            customValidators={customValidators}
            model={state.formData}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={false}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key, model) => onChangeHandler(event, key, model)}
            onAutocompleteSelect={onAutocompleteSelectHandler}
            autoCompleteItems={state.autoCompleteItems}
            showAstersikOnRequiredFields={false}
            extaraActionFormButton={
              <span className="reset" onClick={onResetHandler}>
                <RestartAltIcon /> Reset
              </span>
            }
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  leadFormData: state.leadFormReducer,
  masterDataState: state.commonReducer,
});
export default connect(mapStateToProps, {
  NHList,
  getFilterByLevel,
  activateLoader,
  deActivateLoader,
  getMasterDataForAdminByChannelId,
  getMasterDataForAdmin,
})(LeadFilter);
