import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute, PublicRoute } from '../utils/routeValidator';
import AgentLayout from './agentLayout';
import AuthLayout from './authLayout';
import BrowserNotSupport from "../browserNotSupport";
function Layouts() {
    return (
      <Switch>
        <PublicRoute restricted={false} path="/auth" component={AuthLayout}/>
        <PrivateRoute restricted={false} path="/app" component={AgentLayout}/>
        <Redirect from="/" to="/auth" exact />
        <Route />
      </Switch>
    );
}

export default Layouts
