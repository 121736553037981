import React, { useState, useEffect } from 'react'
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import { calenderFilterForm } from './calenderFilterForm';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Dynamicform from 'f1-dynamic-form-react';
import { t } from "i18next";
import {
    BDM_USER_TYPE, ROLE_BDM, USER_TYPE, ROLE_NH, ROLE_RBH, ROLE_ZBH,
    ZBH_RBH_ABH_BDM, ZBH_ABH_BDM, RBH_ABH_BDM, ABH_BDM
} from '../../../utils/config';
import { getSubordinateList } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getABHByZBH } from "../../../redux-reducer/reducer";
//import { getCalendarListData } from '../../../redux/reducers/calendarActivityReducer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawerMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block"
        }
    },
    desktopFilter: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    }
}));

export default function CalenderFilter({ handleClose, onSubmitHandler, filterClose, setFilterClose }) {
    
    const dispatch = useDispatch()
    // const { selectedDate } = useSelector(state=>state.calendarActivityReducer)
    // const userState = useSelector(state => state.userReducer);
    // const roleId = userState?.profileInfo?.roleId
    // const UserId = userState?.profileInfo?.userId
    // const channelid = userState?.profileInfo?.channelid
    const commonState = useSelector(select => select);
    const {roleId,userId,channelid,firstName,lastName} = commonState.userReducer.profileInfo;
    // console.log(roleId,userId,channelid,firstName,lastName)
    const ABHListForZBH = commonState.leadFormReducer.ABHListForZBH;
    const secondLevelSubordinateList = commonState.leadFormReducer.secondLevelSubordinateList;
    // console.log(ABHListForZBH,secondLevelSubordinateList);
    const UserFullName = `${firstName} ${lastName}`;
    const [newUserList, setNewUserList] = useState([
        { "key": "Select", "label": "Select", "value": "" },
        {
            key: userId,
            label: UserFullName,
            value: userId
        }])
    const [UserOption] = useState([
        { "key": "Select", "label": "Select", "value": "" },
        // {
        //     key: UserId,
        //     label: "ALL",
        //     value: UserId
        // }
    ])
    const [initialFIlterState, setInitalFilterState] = useState({
        userType: "self",
        teamMember: ""
    })
    const [state, setState] = useState({
        current: { id: 1 },
        formData: calenderFilterForm,
    });
    const dynamicRef = React.useRef();
    const classes = useStyles();

    const onResetHandler = () => {
        // const formData = [...state.formData]
        setInitalFilterState({
            userType: "Self",
            teamMember: "all"
        })
        state.formData[1].hide = true
    }

    const onChangeHandler = (event, key) => {
        // let formData = [...state.formData]
        switch (key) {
            case "userType":
                const getUserType = event.target.value
                setInitalFilterState({
                    userType: getUserType
                })
                if (getUserType === "team") {
                    setFilterClose(false)
                    state.formData[1].hide = false
                } else {
                    setFilterClose(true)
                    state.formData[1].hide = true
                }
                break;
            case "teamMember":
                const getTeamMember = event.target.value
                setInitalFilterState({
                    teamMember: getTeamMember
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        getSub()
        const formData = initFormData(state.formData);
        formData[1].hide = true
        if (formData[0]?.options[0]?.key === "all") {
            formData[1].hide = false
        }
        setState({ formData: formData });
    }, []);
    useEffect(() => {
        const formData = initFormData(state.formData);

        // if (filterClose) {
        //     formData[1].hide = true
        //     setInitalFilterState({
        //         userType: "self",
        //         teamMember: ""
        //     })
        // }
        setState({ formData: formData });
    }, [filterClose]);
    const getSub = async () => {
        if (roleId !== ROLE_NH && roleId !== "ZBH" && roleId !== "RBH") {
            // const formData = [...state.formData]
            dispatch(getSubordinateList({ userId: userId })).then((response) => {
                const r = response.data.map(el => {
                    return {
                        key: el.userID,
                        label: el.userName,
                        value: el.userID
                    }
                });
                setNewUserList([...newUserList, ...r])
                state.formData[1].options = [...newUserList, ...r];
            })
        }
        let level;
        let roles = [ROLE_RBH, ROLE_ZBH, ROLE_NH]
        if (roles.includes(roleId)) {
            if (roleId === ROLE_RBH) {
                level = ABH_BDM;
            } else if (roleId === ROLE_ZBH) {
                level = (channelid === 2 ? ABH_BDM : RBH_ABH_BDM)
            } else if (roleId == ROLE_NH) {
                level = (channelid === 2 ? ZBH_ABH_BDM : ZBH_RBH_ABH_BDM)
            }
            dispatch(getABHByZBH(userId, level))
        }
    }
    useEffect(() => {
        // const formData = [...state.formData];
        let arr = [];
        // let arr1 = [];
        if (ABHListForZBH && ABHListForZBH.length > 0) {
            arr = ABHListForZBH?.map((option) => {
                return { key: option["userID"], label: `${option["userName"]}`, value: option["userID"] }
            })
        }
        setNewUserList([...UserOption, ...arr])
        state.formData[1].options = [...newUserList];
    }, [ABHListForZBH, secondLevelSubordinateList]);

    const initFormData = (formFields) => {
        const formData = [...formFields]
        // const roleId = userState?.profileInfo?.roleId
        if (roleId === ROLE_BDM) {
            formData[0].options = BDM_USER_TYPE
        } else {
            formData[0].options = USER_TYPE
        }
        return formData
    }

    return (
        <div>
            <div className="filterBox">
                <h4 className="filterSec">
                    {" "}
                    <FilterAltIcon className="icnFilter" />
                    Filter By
                    <CloseIcon className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
                </h4>
                <div className="fformBox">
                    <Dynamicform
                        key={1}
                        ref={dynamicRef}
                        className="dynamicForm leadFilters  leadFiltersMobile"
                        title={" "}
                        buttonSubmit={t("Apply Filter")}
                        defaultValues={initialFIlterState}
                        model={state.formData}
                        onSubmit={(model) => onSubmitHandler(model)}
                        disableSubmitOnInValid={false}
                        design="bootrstap"
                        materialOutline={false}
                        onChange={(event, key) => onChangeHandler(event, key)}
                        showAstersikOnRequiredFields={false}
                        extaraActionFormButton={
                            <span className="reset" onClick={onResetHandler} ><RestartAltIcon /> Reset</span>
                        }
                    />
                </div>
            </div>
        </div>
    )
}
