import React, { useEffect, useState } from 'react';
import Switch from '@mui/material/Switch';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Grid from '@mui/material/Grid';
import ProfileSettingAddress from './profileSettingAddress';
import { getUserInformationData, putUserAddress } from '../../../redux/actions/dashboardAction'
import { withTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';
import { setLoginCount } from "../../../redux-reducer/reducer/leadListReducer";

import packageJson from "../../../../package.json";
import { useHistory, useLocation } from "react-router-dom";
import Geocode from "react-geocode";
import ModalPopup from "f1-modal-react";
import { Button } from "@material-ui/core";
import { getEnvName } from "../../../utils/utils";
import { activateErrorMessageBar } from "../../../redux/actions";
import { checkInConfig } from '../../../utils/config';
import { loggInfo } from '../../../redux/actions/userAction';
const label = { inputProps: { "aria-label": "Switch demo" } };

const ADDRESS_NOT_FOUND = "Couldn't find the address for the selected location!"

const ProfileSetting = () => {
  const dispatch = useDispatch()
  const locationData = useLocation();
  const commonState = useSelector((state) => state);
  const userState = commonState.userReducer;
  const [UserId] = useState(userState.profileInfo.userId);
  const [UserEmail] = useState(userState.profileInfo.email);
  const history = useHistory();
  const [userData, setUserData] = useState({});
  const [location, setLocation] = useState("");
  const [msg, setMasg] = useState("");
  const [RoleId] = useState(userState.profileInfo.roleId);
  const [showModalSetup, setShowModalSetup] = useState(false);

  const getProfileInfo = async () => {
    const res = await dispatch(getUserInformationData(UserId));
    if (res.status === 200) {
      setUserData(res.data);
      if (res.data.Address !== '') {
        dispatch(setLoginCount({ isFirstLogin: false }));
        setLocation(res.data.Address)
      } else if (res.data.Address === '' && (res.data.Latitude != "" || res.data.Longitude != "")) {
        getAddressForLatLng(res.data.Latitude, res.data.Longitude);
      }
    }
  };
  useEffect(() => {
    Geocode.setApiKey(checkInConfig.API_KEY);
    Geocode.setRegion("IN");
    navigator.geolocation.getCurrentPosition((success) => {
      console.log('location permission is given...');
      setShowModalSetup(false);
    }, (error) => {
      console.log('location permission is not given...');
      setShowModalSetup(true);
    });
    const timeout = setTimeout(() => {
      setMasg("Please update the locaton First.");
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const handleClose = () => {
    setShowModalSetup(false);
  };

  const getAddressForLatLng = (latitude, longitude) => {
    console.log('getAddressForLatLng');
    Geocode.fromLatLng(latitude, longitude)
    .then((response) => {
      console.log(response);
      if (response.results.length > 0) {
        const address = response.results[0].formatted_address;
        setLocation(address);
      } else {
        dispatch(activateErrorMessageBar(
          ADDRESS_NOT_FOUND
        ));
        setLocation("");
      }
    }, (error) => {
      console.log(error, ADDRESS_NOT_FOUND);
      dispatch(activateErrorMessageBar(
        ADDRESS_NOT_FOUND
      ));
      setLocation("");
    })
    const logInfoRequest = {
      processname: "Profile Setting",
      employeeCode: userState.profileInfo.employeeCode,
      ipAddress: "",
      requestFor: "Profile Setting",
      leadActivityId: "",
      deviceId: userState.profileInfo.deviceId,
      routUrl: locationData.pathname
    }
    loggInfo(logInfoRequest);
  }
  const [closeDrawer, setCloseDrawer] = useState(false);
  const updateAddress = async (payload) => {
    const res = await dispatch(putUserAddress(payload));
    if (res.data && res.status === 200) {
      getProfileInfo();
      setCloseDrawer(true);
      dispatch(setLoginCount({ isFirstLogin: false }));
    }
  };
  useEffect(() => {
    getProfileInfo();
  }, [closeDrawer]);
  const goToPrivacy = () => {
    history.push("/app/privacyPolicy");
  };
  const goToTerms = () => {
    history.push("/app/terms");
  };
  return (
    <div>
      <div className="profileSettingDiv">
        <h3 className="title">Settings</h3>
        <div className="profileInner">
          <Grid container>
            <Grid item xs={12} md={8}>
              <h5>User Information</h5>
              <div className="fieldsection">
                <Grid container>
                  <Grid item xs={5} md={4}>
                    <div className="labels">Name</div>
                  </Grid>
                  <Grid item xs={7} md={8}>
                    <div className="textValues">
                      {userData.Firstname} {userData.Lastname}
                    </div>
                  </Grid>

                  <Grid item xs={5} md={4}>
                    <div className="labels">Login ID</div>
                  </Grid>
                  <Grid item xs={7} md={8}>
                    <div className="textValues emailID">{UserEmail}</div>
                  </Grid>

                  <Grid item xs={5} md={4}>
                    <div className="labels">Phone</div>
                  </Grid>
                  <Grid item xs={7} md={8}>
                    <div className="textValues">{userData.Mobilenumber}</div>
                  </Grid>

                  <Grid item xs={5} md={4}>
                    <div className="labels">Home Branch Location</div>
                  </Grid>
                  <Grid item xs={7} md={8}>
                    {location ? (
                      <span className="textValues addText">{location}</span>
                    ) : (
                      <span className="textValues" style={{ color: "red" }}>
                        {msg}
                      </span>
                    )}
                    <span className="mapIcon">
                      {location ? (
                        ""
                      ) : (
                        <ProfileSettingAddress
                          updateAddress={updateAddress}
                          closeDrawer={closeDrawer}
                          UserId={UserId}
                        />
                      )}
                    </span>
                  </Grid>
                </Grid>
              </div>

              <hr></hr>

              <h5>Account Settings</h5>
              <div className="toggleButtons">
                <Grid container>
                  <Grid item xs={6} md={3}>
                    <span className="labels">Touch ID Login</span>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Switch disabled {...label} />
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <span className="labels">Light Theme</span>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Switch {...label} disabled />
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <span className="labels">App Notifications</span>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <Switch
                      {...label}
                      disabled={RoleId === "BDM"}
                      defaultChecked={RoleId === "BDM"}
                    />
                  </Grid>
                </Grid>
              </div>

              <hr></hr>

              <h5>Legal Terms</h5>

              <div className="fieldsection">
                <div className="privacyDiv" onClick={goToPrivacy}>
                  Privacy Policy
                </div>
              </div>
              <div className="fieldsection">
                <div className="privacyDiv" onClick={goToTerms}>
                  Term of Use
                </div>
              </div>
              <hr></hr>
              <h5>General Settings</h5>
              <div className="fieldsection">
                <Grid container>
                  <Grid item xs={6} md={4}>
                    <div className="labels">Version</div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <div className="labels">{userData.version}</div>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={4}>
                    <div className="labels">Build Version</div>
                  </Grid>
                  <Grid item xs={6} md={8}>
                    <div className="labels">
                      {getEnvName()}
                      {packageJson.version}
                    </div>
                  </Grid>
                </Grid>
                {(getEnvName() === '(D)' || getEnvName() === '(Q)') &&
                  <Grid container>
                    <Grid item xs={6} md={4}>
                      <div className="labels">User Role</div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <div className="labels">
                        {userState.profileInfo.roleId}
                      </div>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <div className="labels">Channel ID</div>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <div className="labels">
                        {userState.profileInfo.channelid}
                      </div>
                    </Grid>
                  </Grid>
                }
              </div>
              <hr></hr>
              <div className="rateDiv">
                <span>
                  <ThumbUpIcon />
                </span>
                <span>Rate our Application</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <ModalPopup
        show={showModalSetup}
        onHide={() => setShowModalSetup(!showModalSetup)}
        size={"sm"}
        centered={true}
        modelHeader={"Please enable your location"}
        modelContent={<Button onClick={handleClose}>CLOSE</Button>}
        backdrop={"static"}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});

const mapDispatchToProps = (dispatch) => ({
});

export default withTranslation("translation", { withRef: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfileSetting)
);
