import React from "react";
import Footer from '../../../../layouts/footer/footer';
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from 'react-i18next';
import {
  ActivityCancelledFormPart1, ActivityCancelledFormPart2,
  ActivityCancelledFormPart3, ActivityFormBuilder, customValidators, handleDropdownSelection, initFormData
} from "./updateActivityForm";
import { ACTIVITY_RESCHEDULED, ACTIVITY_COMPLETED, ACTIVITY_CANCELLED, CONFIGS, FOUR_WHEELER, PUBLIC_TRANSPORT, SELF_OWNED_TRANSPORT, TWO_WHEELER } from '../../../../utils/config';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import moment from "moment";
import {
  setActivityData, updateDynamicData,
  getMasterData, getActivityById, uploadFileToBlob,
  activateSuccessMessageBar, activateErrorMessageBar,
  activateLoader, deActivateLoader,
  getActivityStatusMasterData, setBreadcrumbs
} from '../../../../redux/actions';
import { connect } from 'react-redux';

import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import './updateGCLActivity.scss'
import { getAutoCompleteActivities, validateIsCheckin } from "../../../../redux-reducer/reducer";
import { removeSubArrayFromArray } from "./createActivityForm";
import { checkDateIsValid } from "../../../../utils/validators";
import { updateActivityOffline } from "../../../../offlineManager/activityOfflineManager";
import { validateActivity } from "../../../../redux/actions/createActivityActions";
import { minusMinuteFromDate, addMinuteToDate } from '../../../../utils/utils'
const DateFormatString = 'YYYY-MM-DDTHH:mm'
class GCLUpdateActivity extends React.Component {
  constructor(props) {
    super(props);
    this.dyFormRef = React.createRef();
    this.formBuilder = new ActivityFormBuilder(props.activityData.activityComplete ?
      ACTIVITY_COMPLETED : props.activityData.activityReshedule ?
        ACTIVITY_RESCHEDULED : ACTIVITY_CANCELLED, props.activityData.getSelectActivityData.ActivityID)
    this.state = {
      current: {
        id: 1,
        MeetingStartDateTime: props.activityData.getSelectActivityData.MeetingStartDateTime,
        MeetingEndDateTime: addMinuteToDate(props.activityData.getSelectActivityData.MeetingEndDateTime),
        IsClaimRequired: false
      },
      activityStatus: {},
      selectedActivityOldData: {},
      formData: this.formBuilder.getForm(),
      uploadedFile: '',
      UploadTrainingAttendancePhoto1: '',
      extErrors: {},
      responseMessage: ""
    }
  }

  isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await this.props.validateActivity({
        user_id: this.props.userState.profileInfo.userId,
        formData: formData,
      });
      if (checkActivity.data.Responsestatus === false) {
        this.setState({
          responseMessage: checkActivity.data.Responsemessage
        })
      }
      return checkActivity.status === 200 && checkActivity.data.Responsestatus === true;
    }
    return true;
  };

  onSubmitHandler = async (model) => {
    if (Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
      this.props.activateErrorMessageBar("Activity is not updated.")
      return false;
    }
    this.props.activateLoader();
    if (model.MeetingStartDateTime && model.MeetingEndDateTime) {
      model.MeetingStartDateTime = model.MeetingStartDateTime.replace('T', ' ').replace('Z', '')
      model.MeetingEndDateTime = minusMinuteFromDate(model.MeetingEndDateTime).replace('T', ' ').replace('Z', '')
    }

    if (model.DateOfTraining) {
      model.DateOfTraining = model.DateOfTraining.replace('T', ' ').replace('Z', '')
    }

    if (model.MeetingEndDateTime === "Invalid date") {
      model.MeetingEndDateTime = null;
      model.MeetingStartDateTime = null;
    }
    const oldData = this.state.selectedActivityOldData;
    oldData.ActivityStatusId = this.props.activityData.activityComplete ? 26 : this.props.activityData.activityReshedule ? 27 : 28
    oldData.ActivityStatus = this.state.activityStatus?.ActivityStatus
    oldData.ModifiedDate = (moment(new Date()).format(DateFormatString)).replace('T', ' ').replace('Z', '')
    const formData = { ...oldData, ...model };
    delete formData['advanceLayoutFields'];
    delete formData['collapseStates'];
    delete formData['errors'];
    delete formData['isValidForm'];
    delete formData['id'];

    if (model.IsClaimRequired) {
      formData['TransportModeID'] = parseInt(model.TransportModeID);
      formData['TransportMode'] = model.TransportModeID === "1" ? "Public Transport" : "Self Owned Transport";

    } else {
      formData['IsClaimRequired'] = false;
      formData['TransportMode'] = null;
      formData['TransportModeID'] = null;
      formData['TravelExpence'] = null;
      formData['VehicleTypeID'] = null;

    }

    formData['IsCheckin'] = false;
    formData["CreatedBy"] = parseInt(formData["CreatedBy"]);
    formData["ModifiedBy"] = parseInt(this.props.userState.profileInfo.userId);

    formData.IsBacklog = 0;
    if (formData.hasOwnProperty('UploadBill')) {
      formData["UploadBill"] = this.state.uploadedFile;
    }
    if (formData.hasOwnProperty('UploadTrainingAttendancePhoto1')) {
      formData["UploadTrainingAttendancePhoto1"] = this.state.UploadTrainingAttendancePhoto1;
    }
  
    const validateMeetingStatusForm = ["Activity Cancelled", "Activity Completed"];
    if (navigator.onLine) {
      this.props.activateLoader();
      if (validateMeetingStatusForm.includes(formData["ActivityStatus"])) {
        const result = await this.props.updateDynamicData({
          'data': [formData],
          'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: this.formBuilder?.actionType,
          userID: this.props.userState?.profileInfo?.userId
        });
        if (result.status === 200) {
          this.props.activateSuccessMessageBar("Activity is updated successfully.")
          // this method is called to fetch the latest completed activity to create the lead
          this.props.getAutoCompleteActivities({ userId: this.props.userState?.profileInfo?.userId });
          this.props.deActivateLoader();
          setTimeout(() => {
            const str = `/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`
            this.props.history.push(str)
          }, 1000)
        } else {
          this.props.activateErrorMessageBar("Activity is not updated.")
          this.props.deActivateLoader();
        }
      } else {
        const isValidActivity = await this.isValidBusinessActivity(formData);
        if (isValidActivity) {
          const result = await this.props.updateDynamicData({
            'data': [formData],
            'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
            formBuilder: this.formBuilder?.actionType,
            userID: this.props.userState?.profileInfo?.userId
          });
          if (result.status === 200) {
            this.props.activateSuccessMessageBar("Activity is updated successfully.")
            this.props.deActivateLoader();
            // this method is called to fetch the latest completed activity to create the lead
            this.props.getAutoCompleteActivities({ userId: this.props.userState?.profileInfo?.userId });
            setTimeout(() => {
              const str = `/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`
              this.props.history.push(str)
            }, 1000)
          } else {
            this.props.activateErrorMessageBar("Activity is not updated.")
            this.props.deActivateLoader();
          }
        } else {
          this.props.activateErrorMessageBar(
            this.state.responseMessage
          );
          this.props.deActivateLoader();
        }
      }
    } else {
      this.props.deActivateLoader();
      // update the activity in offline
      const activityObject = { ...this.props.activityData.getSelectActivityData };
      activityObject.IsBacklog = false
      activityObject.IsCheckin = false
      activityObject.IsClaimRequired = formData.IsClaimRequired
      activityObject.MeetingEndDateTime = formData.MeetingEndDateTime
      activityObject.MeetingStartDateTime = formData.MeetingStartDateTime
      activityObject.ModifiedDate = formData.ModifiedDate
      activityObject.Reason = formData.Reason
      activityObject.TransportModeID = formData.TransportModeID
      activityObject.TravelExpence = formData.TravelExpence
      activityObject.isActiveCheckin = false
      const key = activityObject.TrnActivityID ? activityObject.TrnActivityID : activityObject.LmsActivityGuid
      updateActivityOffline(key.toString(), activityObject).then((res) => {
        if (res) {
          this.props.history.push(`/app/activity/detail/${key}`);
        }
      }).catch((err) => this.props.history.push(`/app/activity/all`));
    }
    return true;
  }
  onChangeHandler = async (event, key, model) => {
    if (key === 'UploadBill' || key === "UploadTrainingAttendanceDocument1" || key === "UploadTrainingAttendancePhoto1") {
      delete model.errors["UploadTrainingAttendancePhoto1"];
      this.uploadFile(event, key);
    }
    if (key === 'MeetingStartDateTime') {
      const newDate1 = moment(event.target.value).add(30, 'm').format(DateFormatString)
      this.setState({ current: { ...this.state.current, MeetingStartDateTime: event.target.value, MeetingEndDateTime: newDate1 } })
      if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
        this.setState({
          current: { ...this.state.current, MeetingStartDateTime: event.target.value }, extErrors: { ...this.state.extErrors, MeetingStartDateTime: "please select future value" }
        })
      } else {
        this.setState({
          ...this.state, current: { ...this.state.current, MeetingStartDateTime: event.target.value, MeetingEndDateTime: newDate1 }, extErrors: {}
        })
      }
    }
    if (key === 'MeetingEndDateTime') {
      var newDate = moment(event.target.value).format(DateFormatString)
      this.setState({ current: { ...this.state.current, MeetingEndDateTime: newDate } })
      if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
        this.setState({
          current: {
            ...this.state.current, MeetingEndDateTime: event.target.value
          }, extErrors: { ...this.state.extErrors, MeetingEndDateTime: "meeting end date should not less than meeting starting date" }
        })
      } else {
        this.setState({
          current: { ...this.state.current, MeetingEndDateTime: event.target.value }, extErrors: {}
        })
      }
    }

    const flag = this.props.activityData.activityComplete ? ACTIVITY_COMPLETED : this.props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED : ACTIVITY_CANCELLED;

    let formData = handleDropdownSelection(event, key, this.state.formData, this.props.masterDataState, flag, this.props.userState.profileInfo.roleId)
    this.setState({ formData: formData })
    if (key === "IsClaimRequired") {

      if (event === true) {
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: true
          }
        })
        //formData = formData.concat(ActivityCancelledFormPart1);
        formData.find((data1) => data1.key === "TransportModeID").options = this.props.masterDataState['ModeofTransport']
        this.setState({ formData: formData })
        const checkIn = await this.props.validateIsCheckin({ checkinFor: "activity", Id: this.props.activityData.getSelectActivityData.TrnActivityID })
        const arrayToRemove = ActivityCancelledFormPart1
        formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

        const arrayToRemove1 = ActivityCancelledFormPart2
        formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

        const arrayToRemove2 = ActivityCancelledFormPart3
        formData = removeSubArrayFromArray(arrayToRemove2, "key", formData)
        const { data } = checkIn

        if (data) {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: data
            }
          })
          formData = formData.concat(ActivityCancelledFormPart1);
          formData.find((data2) => data2.key === "TransportModeID").options = this.props.masterDataState['ModeofTransport']
          this.setState({ formData: formData })
        } else {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: false
            }
          })
          const arrayToRemove4 = ActivityCancelledFormPart1
          formData = removeSubArrayFromArray(arrayToRemove4, "key", formData);

          const arrayToRemove5 = ActivityCancelledFormPart2
          formData = removeSubArrayFromArray(arrayToRemove5, "key", formData);

          const arrayToRemove6 = ActivityCancelledFormPart3
          formData = removeSubArrayFromArray(arrayToRemove6, "key", formData)
          delete model.errors['TravelExpense'];
          delete model.errors['TransportModeID'];
          this.setState({ formData: formData })
          this.props.activateErrorMessageBar("Please Check In")
        }
      } else {
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: false
          }
        })
        const arrayToRemove7 = ActivityCancelledFormPart1
        formData = removeSubArrayFromArray(arrayToRemove7, "key", formData);

        const arrayToRemove8 = ActivityCancelledFormPart2
        formData = removeSubArrayFromArray(arrayToRemove8, "key", formData);

        const arrayToRemove9 = ActivityCancelledFormPart3
        formData = removeSubArrayFromArray(arrayToRemove9, "key", formData)
        this.setState({ formData: formData })
        delete model.errors['TravelExpense'];
        delete model.errors['TransportModeID'];
      }
    }
  }

  onCancelHandler = () => {
    this.props.history.push(`/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`)
  }

  uploadFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await this.props.uploadFileToBlob({ 'file': files[0] });
      const { downloadUrl } = fileRes.data
      if (fileRes.status === 200) {
        if (key === "UploadBill") {
          this.setState({ uploadedFile: downloadUrl });
        } else if (key === "UploadTrainingAttendancePhoto1") {
          this.setState({ UploadTrainingAttendancePhoto1: files[0].name });
        } else if (key === "UploadTrainingAttendancePhoto3") {
          this.setState({ uploadedPhotoActivity: files[0].name });
        }
      }
    } else {
      this.props.activateErrorMessageBar(
        "Cannot upload files in offline mode!"
      );
    }
  }
  initMasterData = () => {

  }

  statusFunction = async () => {
    const result = await this.props.getActivityStatusMasterData()
    if (result.status === 200) {
      let statusId = []
      if (this.props.activityData.activityComplete) {
        statusId = result.data.filter(f => f.ActivityStatus === 'Activity Completed')
      } else if (this.props.activityData.activityReshedule) {
        statusId = result.data.filter(f => f.ActivityStatus === "Activity Rescheduled")
      } else {
        statusId = result.data.filter(f => f.ActivityStatus === "Activity Cancelled")
      }
      this.setState({ activityStatus: statusId[0] })
    }
  }
  loadBreadCrumbs = () => {
    const { t } = this.props;
    const createActivityTitle = t(this.formBuilder.getFormTitle())
    const breadcrumbs = [
      { name: 'Activity', url: '/app/activity/all' },
      { name: createActivityTitle, url: '/app/gcl/activity/update' },
    ]
    this.props.setBreadcrumbs(breadcrumbs);
  }
  componentDidMount() {
    this.loadBreadCrumbs()
    this.statusFunction()
    const formData = this.props.masterDataState ? initFormData(this.state.formData, this.props.masterDataState, this.props.activityData.activityComplete ? ACTIVITY_COMPLETED :
      this.props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED : ACTIVITY_CANCELLED, this.props.activityData.getSelectActivityData.ActivityName) : this.state.formData;
    this.setState({ formData: formData })

    const fetchData = async () => {
      this.props.activateLoader();
      const getCurrentActivity = await this.props.getActivityById(this.props.activityData.getSelectActivityData.TrnActivityID);
      if (getCurrentActivity.status === 200) {
        this.setState({ selectedActivityOldData: getCurrentActivity.data })
        this.props.deActivateLoader();
      } else {
        this.props.deActivateLoader();
      }
    }
    if (navigator.onLine) {
      fetchData()
    }
  }
  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({ uploadedFile: '' })
  }

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: '#ccc' }} key={index}>
        <span className="file-name">{file.name}</span>
        <span className="action" onClick={(e) => this.removeFile(e, file, index, key)}>
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  }

  renderRadioIcons = (radioOption) => {
    if (radioOption.key === PUBLIC_TRANSPORT) {
      return <DirectionsBusIcon></DirectionsBusIcon>
    }
    else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      return <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if (radioOption.key === TWO_WHEELER) {
      return <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if (radioOption.key === FOUR_WHEELER) {
      return <DirectionsCarIcon></DirectionsCarIcon>
    } else {
      return ""
    }
  }

  render() {
    const { t } = this.props;
    return <div className="mainContainer createActivity updateActivity">
      {/* <MessageBar ></MessageBar>
      <Loader></Loader> */}
      {/* <Header history={this.props.history} /> */}
      <Breadcrumb></Breadcrumb>
      {/* <h3 className="pageHead">{t(this.formBuilder.getFormTitle())}</h3> */}
      <div className="formBox">
        <DynamicForm
          key={this.state.current.id}
          onRef={ref => this.dyFormRef = ref}
          //className="dynamicForm"
          title={this.state.selectedActivityOldData ? this.state.selectedActivityOldData.ActivityName : ''}

          buttonSubmit={t('Update')}
          defaultValues={this.state.current}
          model={this.state.formData}
          onSubmit={(model) => {
            this.onSubmitHandler(model);
          }}
          disableSubmitOnInValid={false}
          design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
          materialOutline={false}
          onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
          buttonNormalClick={(id, key, obj) => {
          }}
          showAstersikOnRequiredFields={true}
          className="activityTypeDiv"
          extaraActionFormButton={<>
            <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{t('Cancel')}</button>
          </>}
          customValidators={customValidators}
          fileUploaderFields={['UploadBill']}
          renderUploadedFilesItem={this.renderUploadedFilesItem}
          renderRadioIcons={(option) => this.renderRadioIcons(option)}
          extErrors={this.state.extErrors}
        />
      </div>
      <Footer></Footer>
    </div>
  }
}

const mapStateToProps = (state) => ({
  activityData: state.updateActivityReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer
});


const mapDispatchToProps = dispatch => ({
  getActivityStatusMasterData: (payload) => dispatch(getActivityStatusMasterData(payload)),
  setActivityData: (payload) => dispatch(setActivityData(payload)),
  getActivityById: (payload) => dispatch(getActivityById(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  getAutoCompleteActivities: (payload) => dispatch(getAutoCompleteActivities(payload)),
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(GCLUpdateActivity));
