import React from 'react';
import './loader.scss';
import LoaderImg from '../../assets/img/loader.gif'
import { useSelector } from 'react-redux';

const Loader = (props) =>{
    const commonState = useSelector(state => state);
    return <div className={commonState.commonReducer.isActiveLoader ? 'loader-overlay' : 'loader-overlay-none'}
	style={{display:commonState.commonReducer.isActiveLoader ?"":"none"}}>
             <img className="loader" src={LoaderImg} alt="Loader"></img>
            </div>
}

export default Loader;
