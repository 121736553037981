import { format } from 'date-fns';
import { ALLOWED_FUTURE_DAYS } from '../../../../utils/config';
import { getDateAfterDays } from "../../../../utils/utils";
import moment from "moment";

const stateLabel = "State";
const meetingType = "Meeting Type";
const stateName = "State name is required.";
const validatorAddressRequired = "Valid address is required.";
const faMapMarker = "fa fa-map-marker";
const meetingLocation = "Meeting Location";
const currAddress = "Current Address: ";
const meetingLocRequired = "Meeting location is required.";
const meetingTypeRequired = "Meeting Type is required.";
const _format = 'yyyy-MM-dd';
const _dateTimeLocal = "datetime-local";
const _meetingStartTime = "Meeting Start Date and Time";
const _meetingEndTime = "Meeting End Date and Time";
const _meetingStartEndTimeValidator = "Meeting Start Date and Time is required.";
const _requiredRemarks = "Remarks is required.";
const mindateFormat = format(new Date(), _format) + "T" + format(new Date(), 'kk:mm');
const _meetingEndTimeValidator = "Meeting End Date and Time is required.";

const dateFormat = 'yyyy-MM-dd';
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");

export const CscMeetingVisitForm = [
    {
        "key": "MeetingWith", "type": "select", "disabled": false,
        "id": "MeetingWith", "label": "Meeting with",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Field is required." }], "options": []
    },
]

export const CscMeetingVisitStateHead = [
    {
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": stateName }], "options": []
	},
    {
        "key": "Address",
        "type": "text", "disabled": false, "hide": false,
        "id": "Address", "label": "Address",
        "validators": [{ "name": "required", "value": false, "validationMessage": validatorAddressRequired }]
    },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
    {
		"key": "MeetingType", "type": "select", "id": "MeetingType", "label":meetingType,
		"validators": [{ "name": "required", "value": true, "validationMessage": meetingTypeRequired }], "options": []
	},
    {
        "key": "MeetingStartDateTime",
        "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false,
        "id": "MeetingStartDateTime", "label": _meetingStartTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59",

    },
    {
        "key": "MeetingEndDateTime", "min": mindateFormat,"max": endMaxDate,
        "hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }],

    },
    {
        "key": "Remarks", "hide": false, "type": "text", "disabled": false,
        "id": "Remarks", "label": "Remarks",
        "validators": [{ "name": "required", "value": true, "validationMessage": _requiredRemarks }]
    },

]

export const CscMeetingVisitStateManager = [
    {
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": stateName }], "options": []
	},
    {
        "key": "Address",
        "type": "text", "disabled": false, "hide": false,
        "id": "Address", "label": "Address",
        "validators": [{ "name": "required", "value": false, "validationMessage": validatorAddressRequired }]
    },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
    {
		"key": "MeetingType", "type": "select", "id": "MeetingType", "label":meetingType,
		"validators": [{ "name": "required", "value": true, "validationMessage": meetingTypeRequired }], "options": []
	},
    {
        "key": "MeetingStartDateTime",
        "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false,
        "id": "MeetingStartDateTime", "label": _meetingStartTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }]
        ,        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59",

    },
    {
        "key": "MeetingEndDateTime", "min": mindateFormat,"max": endMaxDate,
        "hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }],
        
    },
    {
        "key": "Remarks", "hide": false, "type": "text", "disabled": false,
        "id": "Remarks", "label": "Remarks",
        "validators": [{ "name": "required", "value": true, "validationMessage": _requiredRemarks }]
    },

]

export const CscMeetingVisitDistrictManager = [
    {
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": stateName }], "options": []
	},
    {
		"key": "DistrictID", "type": "select",
		"disabled": false, "hide": true,
		"id": "DistrictID", "label": "District Name",
		"validators": [{ "name": "required", "value": true, "validationMessage": "District name is required." }], "options": []
	},
    {
        "key": "Address",
        "type": "text", "disabled": false, "hide": false,
        "id": "Address", "label": "Address",
        "validators": [{ "name": "required", "value": false, "validationMessage": validatorAddressRequired }]
    },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
    {
		"key": "MeetingType", "type": "select", "id": "MeetingType", "label":meetingType,
		"validators": [{ "name": "required", "value": true, "validationMessage": meetingTypeRequired }], "options": []
	},
    {
        "key": "MeetingStartDateTime",
        "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false,
        "id": "MeetingStartDateTime", "label": _meetingStartTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
    },
    {
        "key": "MeetingEndDateTime", "min": mindateFormat,"max": endMaxDate,
        "hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }],
        
    },
    {
        "key": "Remarks", "hide": false, "type": "text", "disabled": false,
        "id": "Remarks", "label": "Remarks",
        "validators": [{ "name": "required", "value": true, "validationMessage": _requiredRemarks }]
    },

]

export const CscMeetingVisitRAPVLE = [
    {
        "key": "RAP_VLE",
        "type": "text", "disabled": false, "hide": false,
        "id": "RAP_VLE", "label": "RAP/VLE",
        "validators": [{ "name": "required", "value": true, "validationMessage": "RAP/VLE is required." },
        { "name": "maxLength", "value": 50, "validationMessage": "Max 50 characters allowed." },
        { "name": "minLength", "value": 1, "validationMessage": "Min 1 characters allowed." }]
    },
    {
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": stateName }], "options": []
	},
    {
        "key": "Address",
        "type": "text", "disabled": false, "hide": false,
        "id": "Address", "label": "Address",
        "validators": [{ "name": "required", "value": false, "validationMessage": validatorAddressRequired }]
    },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
    {
		"key": "MeetingType", "type": "select", "id": "MeetingType", "label":meetingType,
		"validators": [{ "name": "required", "value": true, "validationMessage": meetingTypeRequired }], "options": []
	},
    {
        "key": "MeetingStartDateTime",
        "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false,
        "id": "MeetingStartDateTime", "label": _meetingStartTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
    },
    {
        "key": "MeetingEndDateTime", "min": mindateFormat,"max": endMaxDate,
        "hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }],
    },
    {
        "key": "Remarks", "hide": false, "type": "text", "disabled": false,
        "id": "Remarks", "label": "Remarks",
        "validators": [{ "name": "required", "value": true, "validationMessage": _requiredRemarks }]
    },

]
