
import { format } from 'date-fns';
const _format = 'yyyy-MM-dd';
const _dateTimeLocal = "datetime-local";
const _meetingStartTime = "Meeting Start Date and Time";
const _meetingEndTime = "Meeting End Date and Time";
const _meetingStartEndTimeValidator = "Meeting Start Date and Time is required.";
const _requiredRemarks = "Remarks is required.";
const faMapMarker = "fa fa-map-marker";
const meetingLocation = "Meeting Location";
const currAddress = "Current Address: ";
const meetingLocRequired = "Meeting location is required.";
const requiredField = "This field  is required.";
const mindateFormat = `${format(new Date(), _format)} T ${format(new Date(), 'kk:mm')}`;


export const newRecruitmentActivityForm = [
    {
        "key": "RecruitmentFor", "type": "select", "id": "RecruitmentFor", "label": "Recruitment For ",
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    },
    {
        "key": "ZMAID", "type": "select", "id": "ZMAID", "label": "ZMA List","hide":true,
        "validators": [{ "name": "required", "value": true, "validationMessage": requiredField }], "options": []
    },
    {
        "key": "ExpectedLead", "type": "number", "id": "ExpectedLead", "label": "No of expected leads generated",
        "validators": [{ "name": "maxLength", "value": 2, "validationMessage": "Max 2 digits allowed" },
        { "name": "required", "value": false, "validationMessage": requiredField }]
    },

    {
        "key": "ExpectedConversion", "type": "number", "id": "ExpectedConversion", "label": "No of expected leads converted",
        "validators": [{ "name": "required", "value": false, "validationMessage": requiredField },
        { "name": "maxLength", "value": 2, "validationMessage": "Max 2 digits allowed" }]
    },
    {
        "key": "MeetingStartDateTime",
        "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false,
        "id": "MeetingStartDateTime", "label": _meetingStartTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }]
    },
    {
        "key": "MeetingEndDateTime", "min": mindateFormat,
        "hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting End Date and Time is required." }]
    },
    {
        "key": "Location", "locationIcon": faMapMarker, "label": meetingLocation, "hide": false,
        "type": "checkinField", "name": "address", "locationLabel": currAddress, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
        "id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": meetingLocRequired }]
    },
]
