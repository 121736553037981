import React, { useState, useEffect } from 'react';
import './index.css';

const PercentageBar = () => {
    const target = 15;
    const [style, setStyle] = useState({});
    const [done, setDone] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const check = (progress / target) * 100;
        const newStyle = {
            opacity: 1,
            width: `${check}%`
        };
        setStyle(newStyle);
        setDone(check);
    }, [progress, target]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= target) {
                    return target;
                }
                return prevProgress + 1;
            });
        }, 1000); // Update every second
        return () => clearInterval(interval);
    }, [target]);

    return (
        <div className="progress">
            <div className="progress-done" style={style}></div>
            <span className="progress-res">{Math.round(done)}%</span>
        </div>
    );
};

export default PercentageBar;
