import { format } from "date-fns";
import { ALLOWED_FUTURE_DAYS } from "../../../../utils/config";
import { getDateAfterDays } from "../../../../utils/utils";
import moment from "moment";

const max100Charmessage = "Maximum 100 charactors allowed.";
const claimtravelExpenseLabel = "Do You Want to Claim Travel Expense?";
const cssactiveBoxShadow = "0px 0px 1px 2px #fffc35";
const modeOfTransportLabel = "Mode of Transport";
const modeOftransportRequired = "mode of Trasport is required.";
const optionSelectValidationMessage = "Please select one option.";
const travelExpenseLabel = "Travel Expense";
const cssBorder = "1px solid #ddd";
const travelExpenseValidationmessage = "Travel expense is required.";
const billPhoto = "Upload bill photo";
const uploadBill = "Please Upload Bill Copy";
const acceptFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const fourDigitAllowed = "Maximum 4 digits allowed";
const vehicleType = "Vehicle Type";
const dateFormate = 'yyyy-MM-dd';
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";
const meetingStartDateAndTimeRequired = "Meeting start time is required.";
const meetingEndDateAndTimeRequired = "Meeting end time is required."
const minDate = format(new Date(), dateFormate) + "T" + format(new Date(), 'kk:mm');
const datetimelocal = "datetime-local"
const meetingEndDateAndTimeLabel = "Meeting End Date & Time";


const futureMonth = moment(new Date()).add(3, 'M');
const futureMonthEnd = moment(futureMonth).endOf('month').format("YYYY-MM-DD kk:mm");

export const JOINT_CALL_LEAD_FORM = [
    {
        "colmd": "12", "key": "SalutationID", "type": "select", "disabled": false, "id": "SalutationID", "label": "Salutation",  
        "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }],
        "options": []
    },
    {
        "colmd": "12", "key": "FirstName", "type": "text", "id": "FirstName", "label": "First Name",  
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "colmd": "12", "key": "MiddleName", "type": "text", "id": "MiddleName", "label": "Middle Name",  
        "validators": [
            // { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "colmd": "12", "key": "LastName", "type": "text", "id": "LastName", "label": "Last Name",  
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "colmd": "12", "key": "MobileNo", "type": "number", "id": "MobileNo", "label": "Mobile No.",  
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required." },
            { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
            { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
    },
    {
        "colmd": "12", "key": "AlternateNo", "id": "AlternateNo", "type": "number", "label": "Alternate No.",  
        "validators": [
            { "name": "minLength", "value": 10, "validationMessage": "Min length for Alternate Mobile No is 10 digits" },
            { "name": "maxLength", "value": 10, "validationMessage": "Alternate number field require 10 digits." }
        ]
    },
    {
        "colmd": "12", "key": "Email", "type": "email", "id": "Email id", "label": "Email", 
        "validators": [
            { "name": "required", "value": true }, { "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }
        ]
    },
    {
        "colmd": "12", "key": "Location", "locationIcon": "fa fa-map-marker", "label": "Address",  "type": "checkinField",
        "name": "address", "locationLabel": "Current Address: ", "okayButtonText": "Submit",
        "cancelButtonText": "Cancel", "id": "Location",
        "validators": [{ "name": "required", "value": false }]
    },
    {
        "colmd": "12", "key": "ProductID", "type": "select", "disabled": false, "id": "ProductID", "label": "Select Product",  "validators": [{ "name": "required", "value": true, "validationMessage": "Please select product" }],
        "options": []
    },
    { "colmd": "12", "key": "ExpectedPremium", "id": "ExpectedPremium", "type": "number", "label": "Expected Premium",  "validators": [{ "name": "required", "value": true, "validationMessage": "Please enter expected premium" }, { "name": "maxLength", "value": 8, "validationMessage": "Only 8 digits are allowed." }] },
    {
        "key": "PriorityID", "type": "select", "disabled": false, "id": "PriorityID", "label": "Lead Status",  "validators": [{ "name": "required", "value": true, "validationMessage": "Please select lead status." }],
        "options": []
    },
    {
        "colmd": "12", "key": "LeadSourceID", "type": "select", "disabled": false, "id": "LeadSourceID", "label": "Lead Source",  "validators": [{ "name": "required", "value": true }],
        "options": []
    },
    {
        "colmd": "12", "key": "SPTypeID", "type": "select", "disabled": false, "id": "SPTypeID", "label": "Type Of SP",  "validators": [{ "name": "required", "value": true, "validationMessage": "Please select type of SP value" }],
        "options": []
    },
    {
        "colmd": "12",  "key": "BranchID", "type": "select", "id": "BranchID", "label": "Branch Name & Code","hide":true, "validators": [{ "name": "required", "value": true }],
        "options": []
    },
    {
        "colmd": "12",  "key": "SRMID", "type": "select", "id": "SRMID", "label": "SRM","hide":true, "validators": [{ "name": "required", "value": true, }],
        "options": []
    },
    {
        "colmd": "12", "key": "ISleadHasMeeting", "name": "ISleadHasMeeting", "type": "CustomSwitch","disabled": true, "id": "ISleadHasMeeting", "label": "Meeting with Lead?",  handleDiameter: 45,
        offColor: "#00427E",
        onColor: "#FFFFFF",
        offHandleColor: "#FFFFFF",
        onHandleColor: "#00427E",
        className: "radioBoxDiv",
        height: 30,
        width: 75,
        borderRadius: 24,
        border: "1px solid #ddd",
        activeBoxShadow: "0px 0px 1px 2px #fffc35",
        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
        checkedIcon: <div className="switchChecked">No</div>
    },
    {
        "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
        "type": datetimelocal, "id": "MeetingStartDateTime",
        "label": meetingStartDateAndTimeLabel,"hide":false, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
      },
      {
        "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
        "label": "Expected lead closure date","hide":false, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
      },
    { "colmd": "12", "key": "LeadRemarks",  "type": "textarea", "id": "Remarks", "label": "Remarks", "validators": [{ "name": "required", "value": false }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 300, "validationMessage": "Only 300 characters allowed." }] }
]

export const JOINTCALL_COMPLETED_FORM = [
    {
        colmd: "12",
        key: "FpmCallDurationID",
        label: "Duration of joint call",
        // hide: true,
        type: "select",
        id: "FpmCallDurationID",
        name: "jointcallduration",
        options: [
        ],
        validators: [{ "name": "required", "value": true }]
    },
    {
        "key": "Remarks",
        "hide": false,
        "type": "text",
        "disabled": false,
        "id": "Remarks",
        "label": "Remarks",
        "validators": [{ "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
    },
    
    {
        "key": "leadgenerated", "name": "leadgenerated", "type": "CustomSwitch", "id": "leadgenerated", "label": "Is Lead generated?", handleDiameter: 45,
        offColor: "#00427E",
        onColor: "#FFFFFF",
        offHandleColor: "#FFFFFF",
        onHandleColor: "#00427E",
        className: "radioBoxDiv",
        height: 26,
        width: 75,
        borderRadius: 24,
        border: "1px solid #ddd",
        activeBoxShadow: "0px 0px 1px 2px #fffc35",
        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
        checkedIcon: <div className="switchChecked">No</div>
    },
    {
        "key": "IsClaimRequired",
        "name": "IsClaimRequired",
        "type": "CustomSwitch",
        "disabled": false,
        "id": "IsClaimRequired",
        "label": claimtravelExpenseLabel,
        "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
        offColor: "#08f",
        onColor: "#0ff",
        offHandleColor: "#0ff",
        onHandleColor: "#08f",
        className: "radioBoxDiv",
        height: 26,
        width: 75,
        borderRadius: 24,
        border: cssBorder,
        activeBoxShadow: cssactiveBoxShadow,
        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
        checkedIcon: <div className="switchChecked">No</div>
    },
]
export const JOINTCALL_REMARKS = [{
    "key": "Remarks",
    "hide": false,
    "type": "text",
    "disabled": false,
    "id": "Remarks",
    "label": "Remarks",
    "validators": [{ "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }]
}]

export const JOINTCALL_ISCLAIMREQUIREDFORM = [

    {
        "key": "IsClaimRequired",
        "name": "IsClaimRequired",
        "type": "CustomSwitch",
        "disabled": false,
        "id": "IsClaimRequired",
        "label": claimtravelExpenseLabel,
        "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
        offColor: "#08f",
        onColor: "#0ff",
        offHandleColor: "#0ff",
        onHandleColor: "#08f",
        className: "radioBoxDiv",
        height: 26,
        width: 75,
        borderRadius: 24,
        border: cssBorder,
        activeBoxShadow: cssactiveBoxShadow,
        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
        checkedIcon: <div className="switchChecked">No</div>
    }
]

export const JOINTCALL_COMPLETED_TRANSPORT_FORM = [
    {
        "key": "TransportModeID",
        "type": "radio",
        "label": modeOfTransportLabel,
        "validators": [{ "name": "required", "value": true, "validationMessage": "Mode of transport is required." }], "options": []
    },

]
export const JOINTCALL_COMPLETED_TRAVEL_EXPENSE_FORM = [
    {
        "key": "TravelExpence",
        "hide": false,
        "type": "number",
        "props": { "min": "1" },
        "disabled": false,
        "id": "TravelExpence",
        "label": travelExpenseLabel,
        "validators": [
            { "name": "required", "value": true, "validationMessage": travelExpenseValidationmessage },
            { "name": "maxLength", "value": 4, "validationMessage": fourDigitAllowed }]
    },
    {
        "key": "UploadBill",
        "hide": false,
        "type": "file",
        "dropTitle": billPhoto,
        "acceptFileTypes": acceptFileType,
        "componentType": "DRAG_DROP_BROWSE",
        "layout": "1column",
        "restrictMultipleFiles": true,
        "disabled": false,
        "id": "UploadBill",
        "label": uploadBill,
        "validators": []
    }
]
export const JOINTCALL_COMPLETED_TYPE_OF_TRANSPORT = [
    {
        "key": "VehicleTypeID",
        "hide": false,
        "type": "radio",
        "disabled": false,
        "id": "VehicleType",
        "label": vehicleType,
        "validators": [{
            "name": "required",
            "value": true,
            "validationMessage": modeOftransportRequired
        }],
        "options": []
    },
]

export const JOINTCALL_LEAD_FORM = [
    // {
    //     "fieldSetClassName": CUSTOM_FIELDSET,
    //     "fieldSetLabel": "Lead Personal Details",
    //     "fieldSetLabelType": "accordian",
    //     "fielsetId": "fieldset1",
    // "columns": [
    // {

    // "rows": [

    //     [
    {
        "colmd": "12", "key": "SalutationID", "type": "select", "disabled": false, "id": "SalutationID", "label": "Salutation",
        "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required." }],
        "options": []
    },
    {
        "colmd": "12", "key": "FirstName", "type": "text", "id": "FirstName", "label": "First Name",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "colmd": "12", "key": "LastName", "type": "text", "id": "LastName", "label": "Last Name",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "colmd": "12", "key": "MobileNo", "type": "number", "id": "MobileNo", "label": "Mobile No.",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required." },
            { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
            { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
    },
    {
        "colmd": "12", "key": "AlternateNo", "id": "AlternateNo", "type": "number", "label": "Alternate No.",
        "validators": [
            { "name": "minLength", "value": 10, "validationMessage": "Min length for Alternate Mobile No is 10 digits" },
            { "name": "maxLength", "value": 10, "validationMessage": "Alternate number field require 10 digits." }
        ]
    },
    {
        "colmd": "12", "key": "Email", "type": "email", "id": "Email id", "label": "Email", "hide": false,
        "validators": [
            { "name": "required", "value": true }, { "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }
        ]
    },



    //             ],]
    //     },
    // ]

    // },
    // {
    //     "fieldSetClassName": CUSTOM_FIELDSET,
    //     "fieldSetLabelType": "accordian",
    //     "fieldSetLabel": "Product Details",
    //     "fielsetId": "fieldset2",
    // "columns": [
    //     {

    //         "rows": [

    // [

    { "colmd": "12", "key": "ExpectedPremium", "id": "ExpectedPremium", "type": "number", "label": "Expected Premium", "validators": [{ "name": "required", "value": true, "validationMessage": "Please enter expected premium" }, { "name": "maxLength", "value": 8, "validationMessage": "Only 8 digits are allowed." }] },
    // ],
    //         ]

    //     },
    // ]
    // },
    // {
    // "fieldSetClassName": CUSTOM_FIELDSET,
    // "fieldSetLabelType": "accordian",
    // "fieldSetLabel": "Business Details",
    // "fielsetId": "fieldset3",
    // "columns": [
    //     {

    // "rows": [
    //     [
    {
        "key": "PriorityID", "type": "select", "disabled": false, "id": "PriorityID", "label": "Lead Status", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select lead status." }],
        "options": []
    },
    {
        "colmd": "12", "key": "LeadSourceID", "type": "select", "disabled": false, "id": "LeadSourceID", "label": "Lead Source", "validators": [{ "name": "required", "value": true }],
        "options": []
    },
    // {
    //     "colmd": "12", "key": "ActivityID", "hide": false, "type": "autocomplete", "disabled": false, "id": "ActivityID", "label": "Activity ID", "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required" }],
    //     "options": []
    // },
    {
        "colmd": "12", "key": "SPTypeID", "type": "select", "disabled": false, "id": "SPTypeID", "label": "Type Of SP", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select type of SP value" }],
        "options": []
    },
    {
        "colmd": "12", "hide": true, "key": "BranchID", "type": "select", "id": "BranchID", "label": "Select Branch", "validators": [{ "name": "required", "value": true }],
        "options": []
    },
    // {
    //     "colmd": "12", "hide": true, "key": "LGID", "type": "select", "id": "LGID", "label": "LG Code", "validators": [{ "name": "required", "value": true }],
    //     "options": []
    // },
    // {
    //     "colmd": "12", "hide": true, "key": "SPID", "type": "select", "id": "SPID", "label": "SP Code", "validators": [{ "name": "required", "value": true, }],
    //     "options": []
    // },
    {
        "colmd": "12", "key": "ProductID", "type": "select", "disabled": false, "id": "ProductID", "label": "Select Product", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select product" }],
        "options": []
    },
    {
        "colmd": "12", "key": "ISleadHasMeeting", "name": "ISleadHasMeeting", "type": "CustomSwitch", "id": "ISleadHasMeeting", "label": "Follow up date", handleDiameter: 45,
        offColor: "#00427E",
        onColor: "#FFFFFF",
        offHandleColor: "#FFFFFF",
        onHandleColor: "#00427E",
        className: "radioBoxDiv",
        height: 30,
        width: 75,
        borderRadius: 24,
        border: "1px solid #ddd",
        activeBoxShadow: "0px 0px 1px 2px #fffc35",
        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
        checkedIcon: <div className="switchChecked">No</div>
    },
    { "key": "MeetingStartDateTime", "noDate": null, "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'), "hide": true, "type": "datetime-local", "disabled": false, "id": "MeetingStartDateTime", "label": "Meeting Start Date & Time", "validators": [] },

    { "key": "MeetingEndDateTime", "noDate": null, "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'), "hide": true, "type": "datetime-local", "disabled": false, "id": "MeetingEndDateTime", "label": "Meeting End Date & Time", "validators": [] },
    {
        "colmd": "12", "key": "Location", "locationIcon": "fa fa-map-marker", "label": "Address", "hide": false, "type": "checkinField",
        "name": "address", "locationLabel": "Current Address: ", "okayButtonText": "Submit",
        "cancelButtonText": "Cancel", "id": "Location",
        "validators": [{ "name": "required", "value": false }]
    },
    // {
    //     "colmd": "12", "key": "Location", "locationIcon": "fa fa-map-marker", "label": "Lead Location", "hide": false, "type": "checkinField",
    //     "name": "address", "locationLabel": "Current Address: ", "okayButtonText": "Submit",
    //     "cancelButtonText": "Cancel", "id": "Location",
    //     "validators": [{ "name": "required", "value": false }]
    // },
    { "colmd": "12", "key": "Remarks", "hide": false, "type": "textarea", "id": "Remarks", "label": "Remarks", "validators": [{ "name": "required", "value": false }, { "name": "minLength", "value": 2 }, { "name": "maxLength", "value": 300, "validationMessage": "Only 300 characters allowed." }] },
    {
        "key": "IsClaimRequired",
        "name": "IsClaimRequired",
        "type": "CustomSwitch",
        "disabled": false,
        "id": "IsClaimRequired",
        "label": claimtravelExpenseLabel,
        "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
        offColor: "#08f",
        onColor: "#0ff",
        offHandleColor: "#0ff",
        onHandleColor: "#08f",
        className: "radioBoxDiv",
        height: 26,
        width: 75,
        borderRadius: 24,
        border: cssBorder,
        activeBoxShadow: cssactiveBoxShadow,
        uncheckedIcon: <div className="switchUnChecked">Yes</div>,
        checkedIcon: <div className="switchChecked">No</div>
    },

    //     ],
    // ]

    //     },
    // ]
    // },

];
