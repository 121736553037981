import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { checkInConfig } from '../../../utils/config';
import { InputLabel, TextField, Button } from "@mui/material";
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { activateErrorMessageBar, checkInMap } from '../../../redux/actions';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux"
import { LOCATION_BASE_URL } from '../../../api/baseURL';
import Geocode from "react-geocode";
import { setLoginCount } from "../../../redux-reducer/reducer/leadListReducer";
const ADDRESS_NOT_FOUND = 'Couldn\'t find the address for the selected location!'
export class MapRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coords: {},
            location: '',
            countryName:""
        }
        Geocode.setApiKey(checkInConfig.API_KEY);
        Geocode.setRegion("IN");
        this.getCoords()
    }

    handleCoordsResponse = async (pos) => {
        const { latitude, longitude } = pos.coords;
        this.setState({ coords: { lat: latitude, lng: longitude } })
        axios
            .get(
                `${LOCATION_BASE_URL}${latitude}+${longitude}&key=${this.API_KEY}`
            )
            .then((response) => {
                const { data } = response;
                this.setState({ location: data?.results[0]?.formatted });
            })
    }

    handleCoordsError(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    getCoords = async () => {        
        console.log('inside of profileSettingAddressMap -> mapRoute getCurrentPosition');
        navigator.geolocation.getCurrentPosition((res) => {
            console.log('inside of profileSettingAddressMap -> mapRoute getCurrentPosition res', res);
            const { coords } = res
            const { latitude, longitude } = coords
            this.setState({ coords: { lat: latitude, lng: longitude } })
            Geocode.fromLatLng(latitude, longitude).then(
                (response) => {
                    console.log('inside of profileSettingAddressMap -> mapRoute getCurrentPosition fromLatLng res', response)
                    if (response.results.length > 0) {
                        const address = response.results[0].formatted_address;
                        const getAddress = address?.split(', ')
                        const country = getAddress[getAddress.length - 1]
                        this.setState({ location: address, countryName: country });
                    } else {
                        this.props.activateErrorMessageBar(ADDRESS_NOT_FOUND)
                        this.setState({ location: '' });
                    }
                },
                (error) => {
                    console.log('inside of profileSettingAddressMap -> mapRoute getCurrentPosition fromLatLng err', error)
                    this.props.activateErrorMessageBar(ADDRESS_NOT_FOUND)
                    this.setState({ location: '' });
                    console.error(error);
                })
            })
    }

    handleChangeLocation = () => {
        // navigator.geolocation.getCurrentPosition(this.handleCoordsResponse, this.handleCoordsError, this.options);
        this.getCoords();
    }

    handleCheckIn = () => {
        if (this.state.countryName === "India") {
        const { lat, lng } = this.state.coords;
        this.props.updateAddress({
            "userID": this.props.UserId,
            "isAppNotification": true,
            "latitude": lat.toString(),
            "longitude": lng.toString(),
            "address": this.state.location
        })
        this.props.setDrwerList(false);
        this.props.setLoginCount({ isFirstLogin: false });
        this.props.history.push('/app/home');
        } else {
            this.props.setDrwerList(false)
            this.props.activateErrorMessageBar("Check-In cannot be done outside of India")
        }
    }
    handleChange = (e) => {
        this.setState({ location: e.target.value })
    }

    handleCancel = () => {
        this.props.setDrwerList(false)
    }

    render() {
        return (
            <>
                { this.state.coords.lat && this.state.coords.lng &&
                    <div className="mapDiv">
                        <div className='mapGoogleHeight'>
                            <Map google={this.props.google} initialCenter={{
                                lat: this.state.coords.lat,
                                lng: this.state.coords.lng
                            }} zoom={10}>

                                <Marker onClick={this.onMarkerClick}
                                    name={'Current location'} />

                                <InfoWindow onClose={this.onInfoWindowClose}>
                                </InfoWindow>
                            </Map>
                        </div>
                        <div className="checkInDiv">
                            <p>Check In</p>
                            <InputLabel>Current Location</InputLabel>
                            <div className='srch'>
                                <SearchIcon className='srchIcon' />
                                <TextField
                                    fullWidth
                                    id="standard-bare"
                                    variant="outlined"
                                    disabled
                                    value={this.state.location}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <span onClick={this.handleChangeLocation}><GpsFixedOutlinedIcon /></span>
                                        ),
                                    }}
                                />
                            </div>
                            <div className="btnBx">
                                <Button onClick={this.handleCancel}>Cancel</Button>
                                <Button onClick={this.handleCheckIn}>Submit</Button>
                            </div>
                        </div>
                    </div>
                }
                {
                    (!this.state.coords.lat || !this.state.coords.lng) &&
                    <div className="mapDiv">
                        <div className='mapGoogleHeight'>
                            <h4>fetching your location...</h4>
                        </div>
                    </div>
                }
            </>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    checkInMap: (payload) => dispatch(checkInMap(payload)),
    setLoginCount:(payload) => dispatch(setLoginCount(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
})


const mapStateToProps = state => ({
});

export default GoogleApiWrapper({
    apiKey: (checkInConfig.API_KEY)
})(withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(MapRoute)))