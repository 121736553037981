import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';


const GCLAttachedAtLoanSanctionStageFormHistory = (props) => {
    const { history } = props
    return <>
        {history.LeadStatusId === 17 ?
            <>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Actual Premium Amount </label>
                        <label>{history.ActualPremiumAmount}</label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Remark</label>
                        <label><p>{history.Remarks ? history.Remarks : "N/A"}</p></label>
                    </TimelineContent>
                </TimelineItem>
            </>
            : ''
        }
    </>
}

export default GCLAttachedAtLoanSanctionStageFormHistory
