import { getLocalStorage } from "./utils/utils"

export const PERMISSIONS = {
    'DASHBOARD': 'DASHBOARD',
    'CREATE_ACTIVITY': 'CREATEACT',
    'COMPLETE_ACTIVITY': 'COMPLETEACT',
    'RESCHEDULE_ACTIVITY': 'RESCHDULEACT',
    'CANCEL_ACTIVITY': 'CANCELACT',
    'CREATE_LEAD': 'CREATELEAD',
    'CREATELEAD': 'CREATELEAD',
    'CALLBACK_LEAD': 'CALLBACKLEAD',
    'RESCHEDULE_MEETING_LEAD': 'RESCHMEETLEAD',
    'FOLLOW_UP_MEETING_LEAD': 'FOLLOWMEETSCHLEAD',
    'CONVERTED_LEAD': 'CONVERTEDLEAD',
    'DROPPED_LEAD': 'DROPPEDLEAD',
    'UPDATE_LEAD_CALLBACK': 'UPDATELEADCALLBACK',
    'CHANGE_PASSWORD': 'CHANGEPASSWORD',
    'REASSIGN_LEAD':'REASSIGNLEAD',
    'DOWNLOAD_LEAD':'DOWNLOADLEAD',
    'SALESMANAGEMENTACTIVITY':'CREATEACT',
    'ADMDASHBOARD':'ADMDASHBOARD'
}


export const ROLE_WISE_HOME_PAGE = {
    'BDM': '/app/home',
    'ABH': '/app/home',
    'LG': '/app/home',
    'FPM': '/app/home',
}

export const isAuthenticated = () =>{
    return getLocalStorage('token') ? true : false;
}

export const isAuthorized = (permission) =>{
    if(!permission){
        return true
    }
    const permissionsStr = getLocalStorage('permissions')
    let permissions = []
    if(permissionsStr && permissionsStr != null && permissionsStr !== 'null'){
        permissions = JSON.parse(permissionsStr)
    }
    const uesrPermission = permissions.find( permissionItem => permissionItem.permissionCode === permission)
    if(uesrPermission){
        return true
    }
    return false
}

export const getUserHome = () =>{
    const roleName = getLocalStorage('roleId');
    return ROLE_WISE_HOME_PAGE[roleName]
}
export const getUserLoginOffline = () =>{
    return '/auth/login';
}
