import { useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import NewStatusHistory from "./ActivityStatusHistory";
import CallBackStatusHistory from "./callBackStatusHistory";
import RescheduleStatusHistory from "./rescheduleStatusHistory";
import FollowUpStatusHistory from "./followUpStatusHistory";
import DroppedStatusHistory from "./droppedStatusHistory";
import ConvertedStatusHistory from "./convertedStatusHistory";
import MeetingScheduledStatusHistory from './meetingScheduledStatusHistory';
import ApplicationCreatedStatusHistory from './applicationCreatedStatusHistory';
import ReassignStatusHistory from './reassignStatusHistory';
import IntrestedStatusHistory from './intrestedStatusHistory';
import DraftedStatusHistory from './draftedStatusHistory';
import PremiumCollectedStatusHistory from './premiumCollectedStatusHistory';
import { TimelineDot } from "@material-ui/lab";
import CheckInHistory from "../common/checkInHistory";
import GCLAttachedAtLoanPresanctionStageFormHistory from "./gcl/history/gCLAttachedAtLoanPresanctionStageFormHistory";
import GCLAttachedAtLoanDisbursementStageFormHistory from "./gcl/history/gCLAttachedAtLoanDisbursementStageFormHistory";
import MemberFormCollectedHistory from "./gcl/history/memberFormCollectedHistory";
import MedicalScheduleHistory from "./gcl/history/medicalScheduleHistory";
import MedicalCompletedHistory from "./gcl/history/medicalCompletedHistory";
import COIIssuedHistory from "./gcl/history/cOIIssuedHistory";
import GCLNotAttachedLoanPreSanctionStageHistory from "./gcl/history/gCLNotAttachedLoanPreSanctionStageHistory";
import GCLNotAttachedLoanSanctionStageHistory from "./gcl/history/gCLNotAttachedLoanSanctionStageHistory";
import GCLAttachedAtLoanSanctionStageFormHistory from "./gcl/history/gCLAttachedAtLoanSanctionStageFormHistory";

const HistoryCard = (props) => {
    const styletimeline = {
        ':before': { content: 'none' },
    }
    useEffect(() => {
    }, [props.leadHistoryData]);
    const { classes, leadHistoryData, Item, ActivityIDlabelAgency, ActivityIDlabel, IsSpotLeadClosure } = props;

    return <Paper className={classes.paper2} sx={{ mb: 2 }}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Item className="activityDetailsHead"><b>History</b></Item>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="historyDiv" item xs={12} md={12} >
                <Grid item xs={12} md={12} >
                    <div className="accBox">
                        {leadHistoryData.length > 0 && leadHistoryData.map((history, index) => {
                            return <> {history.LeadStatus ?
                                <Accordion key={`historyAcc ${index}`}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <TimelineDot className="customDot"></TimelineDot>
                                        <div className="accordianTitle">
                                            <span className="status">{history.IsCheckindata ? 'Check in' : history?.LeadStatus}</span><br />
                                            <span className="date">{history.ModifiedDate ? moment(history.ModifiedDate).format('DD MMM YYYY') : ""}</span>
                                            <span className="time">{history.ModifiedDate ? moment(history.ModifiedDate).format('h:mma') : ""}</span>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Timeline>
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                            </TimelineItem>
                                            {
                                                history && history.IsCheckindata ?
                                                    <CheckInHistory history={history} styletimeline={styletimeline} />
                                                    :
                                                    <>
                                                        <NewStatusHistory history={history} ActivityIDlabel={ActivityIDlabel} styletimeline={styletimeline} ></NewStatusHistory>
                                                        <CallBackStatusHistory history={history} styletimeline={styletimeline}></CallBackStatusHistory>
                                                        <RescheduleStatusHistory history={history} styletimeline={styletimeline} ></RescheduleStatusHistory>
                                                        <FollowUpStatusHistory history={history} styletimeline={styletimeline} ></FollowUpStatusHistory>
                                                        <DroppedStatusHistory history={history} styletimeline={styletimeline} />
                                                        <ConvertedStatusHistory history={history} styletimeline={styletimeline} />
                                                        <GCLAttachedAtLoanPresanctionStageFormHistory history={history} styletimeline={styletimeline} />
                                                        <GCLAttachedAtLoanSanctionStageFormHistory history={history} styletimeline={styletimeline} />
                                                        <GCLAttachedAtLoanDisbursementStageFormHistory history={history} styletimeline={styletimeline} />
                                                        <GCLNotAttachedLoanPreSanctionStageHistory history={history} styletimeline={styletimeline} />
                                                        <GCLNotAttachedLoanSanctionStageHistory history={history} styletimeline={styletimeline} />
                                                        <MemberFormCollectedHistory history={history} styletimeline={styletimeline} />
                                                        <MedicalScheduleHistory history={history} styletimeline={styletimeline} />
                                                        <MedicalCompletedHistory history={history} styletimeline={styletimeline} />
                                                        <COIIssuedHistory history={history} styletimeline={styletimeline} />
                                                        <MeetingScheduledStatusHistory history={history} ActivityIDlabelAgency={ActivityIDlabelAgency} ActivityIDlabel={ActivityIDlabel} styletimeline={styletimeline} />
                                                        <ApplicationCreatedStatusHistory history={history} ActivityIDlabel={ActivityIDlabel} styletimeline={styletimeline} />
                                                        <ReassignStatusHistory history={history} ActivityIDlabel={ActivityIDlabel} styletimeline={styletimeline} />
                                                        <IntrestedStatusHistory history={history} ActivityIDlabel={ActivityIDlabel} styletimeline={styletimeline} IsSpotLeadClosure={IsSpotLeadClosure} />
                                                        <DraftedStatusHistory history={history} ActivityIDlabel={ActivityIDlabel} styletimeline={styletimeline} />
                                                        <PremiumCollectedStatusHistory history={history} ActivityIDlabel={ActivityIDlabel} styletimeline={styletimeline} />
                                                    </>
                                            }
                                        </Timeline>
                                    </AccordionDetails>
                                </Accordion> : ' '}

                            </>
                        })}

                        {props.leadHistoryData.length > 0 ? ''
                            : <div className="noData">No history is available.</div>
                        }
                    </div>
                </Grid>
            </Grid>
        </Grid>
    </Paper>
}
export default HistoryCard;
