import React, { useState, useEffect } from 'react'
import useOnlineStatus from '../../../hooks/useOnlineStatus';
import "font-awesome/css/font-awesome.min.css";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import moment from "moment";
import { useSelector } from 'react-redux';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import VideocamIcon from '@mui/icons-material/Videocam';
import TextsmsIcon from '@mui/icons-material/Textsms';
import EmailIcon from '@mui/icons-material/Email';
import PerformanceStatsCard from './performanceStatsCard';
import { activateLoader, deActivateLoader } from "../../../redux/actions/common";
import { getAccountOverviewById } from '../../../redux/actions/dashboardAction';
import { levelbyUser } from "../../../utils/config";

const AccountOverViewCard = (props) => {
    const isOnline = useOnlineStatus();
    const { classes, history, selectedView, roleId, selectedBDM,
        selectedSecondLevelSubordinate, selectedThirdLevelSubordinate, selectedFourthLevelSubordinate, rbhView } = props;
    const commonState = useSelector(state => state)
    const { viewForStatus, GetBDMByABH } = useSelector(state => state.commonReducer)

    const bdmList = commonState.leadFormReducer.BDMList.UsersDetails;
    const ABHListForZBH = commonState.leadFormReducer.ABHListForZBH;

    const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelSubordinateList?.UsersDetails;
    const thirdLevelSubordinateList = commonState.leadFormReducer?.thirdLevelSubordinateList?.UsersDetails;
    const fourthLevelSubordinateList = commonState.leadFormReducer?.fourthLevelSubordinateList?.UsersDetails;

    const userState = commonState.userReducer

    const { lastSeen, channelid } = userState.profileInfo
    const loggedTime = moment(userState.profileInfo.lastLoginDatetime).format(
        "h:mma"
    );
    const lastSeenTime = lastSeen ? moment(lastSeen).format(
        "h:mma"
    ) : "";
    const [userId] = useState(commonState.userReducer.profileInfo.userId);
    const [accountOverview, setAccountOverview] = useState({
        activities: 0,
        campaignLeads: 0,
        campaignActivities: 0,
        date: new Date(),
        expectedRevenue: 0,
        leads: 0,
        persistencyLeads: 0,
        gclLeadCount: 0,
        gclLeadExpectedPremium: 0
    })
    const [userInfo, setUserInfo] = useState({
        loggedTime: loggedTime,
        firstName: userState.profileInfo.firstName,
        lastName: userState.profileInfo.lastName,
        initials: `${userState.profileInfo.firstName?.charAt(0).toUpperCase()}${userState.profileInfo.lastName?.charAt(0).toUpperCase()}`,
        lastSeen: lastSeen,
        reportingMgrfirstname: userState?.profileInfo?.reportingMgrfirstname,
        reportingMgrlastname: userState?.profileInfo?.reportingMgrlastname,
        MobileNo: userState?.profileInfo?.MobileNo,
        Email: userState?.profileInfo?.Email
    });
    const [showPerformStat, setShowPerformStat] = useState(false);
    const All = "all"
    useEffect(() => {
        getAccountData();
        getProfileInfo();
    }, [selectedBDM, selectedView, selectedSecondLevelSubordinate, selectedThirdLevelSubordinate, selectedFourthLevelSubordinate])
    const getAccountData = async () => {
        if (isOnline) {
            activateLoader();
            setShowPerformStat(false);
            let request = {};
            if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
                if (selectedBDM === 0) {
                    request = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: selectedView,
                        subordinateUserIds: selectedBDM === 0 ? "all" : selectedBDM,
                        viewByDate: "monthly",
                    };
                } else if (selectedFourthLevelSubordinate) {
                    const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedFourthLevelSubordinate);

                    request = {
                        userId: selectedBDMDetails[0]?.UserID,
                        roleName: selectedBDMDetails[0]?.Level,
                        viewFor: levelbyUser(channelid, roleId, "Five"),
                        subordinateUserIds: All,
                        viewByDate: "monthly",
                    };
                } else if (selectedThirdLevelSubordinate) {
                    const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === selectedThirdLevelSubordinate);
                    request = {
                        userId: selectedABHDetails[0]?.UserID,
                        roleName: selectedABHDetails[0]?.Level,
                        viewFor: levelbyUser(channelid, roleId, "Four"),
                        subordinateUserIds: All,
                        viewByDate: "monthly",
                    };
                } else if (selectedSecondLevelSubordinate && selectedSecondLevelSubordinate !== "ALL") {
                    if (channelid === 2 && roleId === "NH") {
                        const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === selectedSecondLevelSubordinate);
                        request = {
                            userId: selectedBDMDetails[0]?.userID,
                            roleName: selectedBDMDetails[0]?.level,
                            viewFor: levelbyUser(channelid, roleId, "Three"),
                            subordinateUserIds: All,
                            viewByDate: "monthly",
                        };
                    } else {
                        const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedSecondLevelSubordinate);

                        request = {
                            userId: selectedBDMDetails[0]?.UserID,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: levelbyUser(channelid, roleId, "Three"),
                            subordinateUserIds: All,
                            viewByDate: "monthly",
                        };
                    }

                } else if (selectedSecondLevelSubordinate === "ALL") {
                    if (channelid === 2 && roleId === "ZBH") {
                        const selectedBDMDetail = ABHListForZBH?.filter((bdm) => bdm.userID === selectedBDM);
                        request = {
                            userId: selectedBDM,
                            roleName: selectedBDMDetail[0].level,
                            viewFor: viewForStatus,
                            subordinateUserIds: "ALL",
                            viewByDate: "monthly",
                        };
                    } else {
                        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);

                        request = {
                            userId: selectedBDM,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: viewForStatus,
                            subordinateUserIds: "ALL",
                            viewByDate: "monthly",
                        };
                    }

                } else {
                    if (channelid === 2 && roleId === "ZBH") {
                        const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === selectedBDM);
                        request = {
                            userId: selectedBDMDetails[0]?.userID,
                            roleName: selectedBDMDetails[0].level,
                            viewFor: levelbyUser(channelid, roleId, "Second"),
                            subordinateUserIds: All,
                            viewByDate: "monthly",
                        };

                    } else {
                        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
                        request = {
                            userId: selectedBDM,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: levelbyUser(channelid, roleId, "Second"),
                            subordinateUserIds: All,
                            viewByDate: "monthly"
                        };
                    }

                }
            } else {
                if (selectedBDM === 0) {
                    request = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: selectedView === 'ALL' ? 'BDM' : selectedView,
                        subordinateUserIds: GetBDMByABH,
                        viewByDate: "monthly",
                    };
                } else {
                    request = {
                        userId: selectedBDM,
                        roleName: roleId,
                        viewFor: 'BDM',
                        subordinateUserIds: selectedBDM?.toString(),
                        viewByDate: "monthly",
                    };
                }
            }
            const res = await getAccountOverviewById(request)
            if (res.status === 200) {
                deActivateLoader();
                const { data } = res;
                setAccountOverview({
                    activities: data.Activities ? data.Activities : 0,
                    campaignLeads: data.CampaignLeads ? data.CampaignLeads : 0,
                    campaignActivities: data.CampaignActivities ? data.CampaignActivities : 0,
                    date: new Date(),
                    expectedRevenue: data.ExpectedRevenue ? data.ExpectedRevenue : 0,
                    leads: data.Leads ? data.Leads : 0,
                    persistencyLeads: data.PersistencyLeads ? data.PersistencyLeads : 0,
                    gclLeadCount: data.GCLLeadCount ? data.GCLLeadCount : 0,
                    gclLeadExpectedPremium: data.GCLLeadExpectedPremium ? data.GCLLeadExpectedPremium : 0
                });
            } else {
                deActivateLoader();
            }
        }
    }

    const getIntials = (name) => {
        if (name != null) {
            const words = name.split(" ");
            return `${words[0].slice(0, 1).toUpperCase()}${' '}${words[1].slice(0, 1).toUpperCase()}`;
        } else {
            return "";
        }
    };

    const activityCardClick = () => {
        if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
            if (viewForStatus === "SELF") {
                history.push('/app/activity/all')
            } else {
                return false
            }
        } else {
            history.push('/app/activity/all')
        }
    }

    const campaignActivityCardClick = () => {
        history.push('/app/campaignactivity/all')
    }

    const leadCardClick = () => {
        if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
            return false
        } else {
            history.push('/app/lead/all')
        }
    }
    const PersistancyleadCardClick = () => {
        if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
            return false
        } else {
            history.push('/app/persistencylead/all')
        }
    }
    const CampaignleadCardClick = () => {
        if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
            return false
        } else {
            history.push('/app/campaignlead/all')
        }
    }
    const BusinessleadCardClick = () => {
        if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
            return false
        } else {
            history.push('/app/businesslead/all')
        }
    }
    const togglePerformStat = () => {
        setShowPerformStat(!showPerformStat);
    }
    const getProfileInfo = () => {
        const userDataState = commonState.userReducer;
        const loggedInTime = moment(userDataState.profileInfo.lastLoginDatetime).format(
            "h:mma"
        );
        if (selectedBDM === 0) {
            setUserInfo({
                ...userInfo,
                loggedTime: loggedInTime,
                firstName: userDataState.profileInfo.firstName,
                lastName: userDataState.profileInfo.lastName,
                lastSeen: lastSeenTime,
                initials: `${userDataState.profileInfo.firstName?.charAt(0).toUpperCase()}${userDataState.profileInfo.lastName?.charAt(0).toUpperCase()}`
            })
        } else if (selectedFourthLevelSubordinate) {
            const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedFourthLevelSubordinate);
            if (selectedBDMDetails?.length > 0) {
                setUserInfo({
                    ...userInfo,
                    loggedTime: moment(selectedBDMDetails[0].LastLoginDateTime).format(
                        "h:mma"
                    ),
                    lastSeen: moment(selectedBDMDetails[0].lastSeen).format(
                        "h:mma"
                    ),
                    firstName: selectedBDMDetails[0].UserName.split(" ")[0],
                    lastName: selectedBDMDetails[0].UserName.split(" ")[1],
                    initials: getIntials(selectedBDMDetails[0].UserName),
                    MobileNo: selectedBDMDetails[0].Mobilenumber,
                    Email: selectedBDMDetails[0].Email,
                    ManagerName: selectedBDMDetails[0].ManagerName,
                    reportingMgrfirstname: selectedBDMDetails[0].ManagerName.split(" ")[0],
                    reportingMgrlastname: selectedBDMDetails[0].ManagerName.split(" ")[1]
                })
            }
        } else if (selectedThirdLevelSubordinate) {
            const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === selectedThirdLevelSubordinate);
            if (selectedABHDetails?.length > 0) {
                setUserInfo({
                    ...userInfo,
                    loggedTime: moment(selectedABHDetails[0].LastLoginDateTime).format(
                        "h:mma"
                    ),
                    lastSeen: moment(selectedABHDetails[0].lastSeen).format(
                        "h:mma"
                    ),
                    firstName: selectedABHDetails[0].UserName.split(" ")[0],
                    lastName: selectedABHDetails[0].UserName.split(" ")[1],
                    initials: getIntials(selectedABHDetails[0].UserName),
                    MobileNo: selectedABHDetails[0].Mobilenumber,
                    Email: selectedABHDetails[0].Email,
                    ManagerName: selectedABHDetails[0].ManagerName,
                    reportingMgrfirstname: selectedABHDetails[0].ManagerName.split(" ")[0],
                    reportingMgrlastname: selectedABHDetails[0].ManagerName.split(" ")[1]
                })
            }
        } else if (selectedSecondLevelSubordinate) {
            const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedSecondLevelSubordinate);
            if (selectedBDMDetails?.length > 0) {
                setUserInfo({
                    ...userInfo,
                    loggedTime: moment(selectedBDMDetails[0].LastLoginDateTime).format(
                        "h:mma"
                    ),
                    lastSeen: moment(selectedBDMDetails[0].lastSeen).format(
                        "h:mma"
                    ),
                    firstName: selectedBDMDetails[0].UserName.split(" ")[0],
                    lastName: selectedBDMDetails[0].UserName.split(" ")[1],
                    initials: getIntials(selectedBDMDetails[0].UserName),
                    MobileNo: selectedBDMDetails[0].Mobilenumber,
                    Email: selectedBDMDetails[0].Email,
                    ManagerName: selectedBDMDetails[0].ManagerName,
                    reportingMgrfirstname: selectedBDMDetails[0].ManagerName.split(" ")[0],
                    reportingMgrlastname: selectedBDMDetails[0].ManagerName.split(" ")[1]
                })
            }
        } else {

            // console.log(selectedSecondLevelSubordinate)
            const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
            if (selectedBDMDetails?.length > 0) {
                setUserInfo({
                    ...userInfo,
                    loggedTime: moment(selectedBDMDetails[0].LastLoginDateTime).format(
                        "h:mma"
                    ),
                    lastSeen: moment(selectedBDMDetails[0].lastSeen).format(
                        "h:mma"
                    ),
                    firstName: selectedBDMDetails[0].UserName.split(" ")[0],
                    lastName: selectedBDMDetails[0].UserName.split(" ")[1],
                    initials: getIntials(selectedBDMDetails[0].UserName),
                    MobileNo: selectedBDMDetails[0].Mobilenumber,
                    Email: selectedBDMDetails[0].Email,
                    ManagerName: selectedBDMDetails[0].ManagerName,
                    reportingMgrfirstname: selectedBDMDetails[0].ManagerName.split(" ")[0],
                    reportingMgrlastname: selectedBDMDetails[0].ManagerName.split(" ")[1]
                })
            }
        }
    }

    const handleAudioCall = (e, MobileNo) => {
        e.stopPropagation()
        window.open(`tel:${MobileNo}`)
    }

    const handleVideoCall = (e) => {
        console.log(e);
    }

    const handleSMS = (e, MobileNo) => {
        e.stopPropagation()
        window.open(`sms:${MobileNo}`)
        return true;
    }
    const handleEmail = (e, Email) => {
        e.stopPropagation()
        window.open(`mailto:${Email}`)
    }

    return <Paper className={classes.paper2} sx={{ mb: 2 }}>
        <Grid className="accountOverviewSec" container>
            <div className="profileDiv">
                <div className="dpDiv">
                    <span className="circle"></span>
                    {userInfo.initials}
                </div>
                <div className="welDiv">
                    <div className="welText"> Welcome!</div>
                    <Typography variant="h6" gutterBottom>
                        {userInfo.firstName} {userInfo.lastName}
                    </Typography>
                    <span className="loggDiv">Last Logged in at {userInfo.loggedTime}</span>
                    {roleId === 'ABH' && <div className="profSetting" onClick={() => history.push('/app/profilesetting')}><SettingsIcon /> Profile Settings</div>}
                    {(roleId === 'ABH' && selectedView !== "SELF" && selectedBDM !== 0) &&
                        <>
                            <div className='statusDiv'> Manager : {userInfo.reportingMgrfirstname}{" "}{userInfo.reportingMgrlastname} </div>
                            <div className="socialIconABH">
                                <Grid container>
                                    <Grid item xs={3}>
                                        <span onClick={(e) => handleAudioCall(e, userInfo.MobileNo)}><WifiCalling3Icon /> <em>Audio Call</em></span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span onClick={() => handleVideoCall()}><VideocamIcon /> <em>Video Call</em></span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span onClick={(e) => handleSMS(e, userInfo.MobileNo)}><TextsmsIcon /> <em>SMS</em></span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span onClick={(e) => handleEmail(e, userInfo.Email)}><EmailIcon /> <em>Email</em></span>
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Grid item xs={12} md={12}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>
                            Account Overview
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            <div className="dtDiv">{moment(new Date()).format("dddd Do, MMMM YYYY")}</div>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        {/* {channelid !== 2 && */}
                        <>
                            {/* (roleId === "NH" && channelid == 2 && viewForStatus === "ZBH") && */}
                            {/* {((((roleId === "ABH" || roleId == "BDM") && (viewForStatus === "RBH" && roleId === "ZBH")) 
                            && viewForStatus == "SELF") || (viewForStatus == "ABH" || viewForStatus == "BDM")) && */}
                            {(((roleId !== "RBH" || roleId !== "NH" || roleId !== "ZBH") && viewForStatus !== "SELF") ||
                                (roleId === "ABH" || roleId === "BDM" || roleId === "FPM")) && rbhView && channelid !== 21 &&

                                <>
                                    {(commonState.userReducer.profileInfo.channelid !== 13 && commonState.userReducer.profileInfo.channelid !== 79) &&
                                        <div className="expectDiv">Expected Revenue (MTD)</div>
                                    }
                                    {(commonState.userReducer.profileInfo.channelid === 13 || commonState.userReducer.profileInfo.channelid === 79) &&
                                        <div className="expectDiv">Expected Premium(MTD)</div>
                                    }
                                    <div className="payDiv">
                                        <CurrencyRupeeIcon fontSize="small" />{(commonState.userReducer.profileInfo.channelid === 13 || commonState.userReducer.profileInfo.channelid === 79) ? accountOverview.gclLeadExpectedPremium : accountOverview.expectedRevenue?.toLocaleString()}
                                    </div>
                                </>
                            }
                        </>
                        {/* } */}
                    </Grid>
                </Grid>
                <Grid container className="statsDiv" textAlign={"center"}>
                    {(commonState.userReducer.profileInfo.channelid !== 21 ||
                        (commonState.userReducer.profileInfo.channelid === 21 &&
                            ((commonState.userReducer.profileInfo.roleId === "RBH" && viewForStatus !== 'SELF')
                                || commonState.userReducer.profileInfo.roleId === "ABH" ||
                                commonState.userReducer.profileInfo.roleId === "BDM"))) &&
                        <Grid item xs={6} onClick={activityCardClick}>
                            <Paper className="navyBlueBG">
                                <ArrowRightAltIcon className="arrowIcon" />
                                <div className="num">{accountOverview.activities}</div>
                                <div className="numText"><BarChartIcon /> Activities</div>
                            </Paper>
                        </Grid>
                    }


                    {commonState.userReducer.profileInfo.channelid !== 2 && commonState.userReducer.profileInfo.channelid !== 3 &&
                        commonState.userReducer.profileInfo.roleId !== "NH" &&
                        commonState.userReducer.profileInfo.roleId !== "RBH" &&
                        commonState.userReducer.profileInfo.roleId !== "ZBH" && (
                            <Grid item xs={6} onClick={leadCardClick}>
                                <Paper className="redBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{(commonState.userReducer.profileInfo.channelid === 13 || commonState.userReducer.profileInfo.channelid === 79)? accountOverview.gclLeadCount : accountOverview.leads}</div>
                                    <div className="numText"><BarChartIcon /> Leads</div>
                                </Paper>
                            </Grid>
                        )}
                    {/* || 
                        ((commonState.userReducer.profileInfo.roleId === "RBH" && (viewForStatus === "ABH" || viewForStatus === "BDM")) ||
                            (commonState.userReducer.profileInfo.roleId === "ZBH" && (viewForStatus === "ABH" || viewForStatus === "RBH"))) && rbhView  */}
                    {commonState.userReducer.profileInfo.channelid !== 2 && commonState.userReducer.profileInfo.channelid !== 3 && commonState.userReducer.profileInfo.channelid !== 79 &&
                        ((commonState.userReducer.profileInfo.roleId === "NH" && viewForStatus !== 'SELF') ||
                            (commonState.userReducer.profileInfo.roleId === "RBH" && viewForStatus !== 'SELF') ||
                            (commonState.userReducer.profileInfo.roleId === "ZBH" && viewForStatus !== 'SELF')) &&
                        (<>
                            <Grid item xs={6} onClick={leadCardClick}>
                                <Paper className="redBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{(commonState.userReducer.profileInfo.channelid === 13 || commonState.userReducer.profileInfo.channelid === 79) ? accountOverview.gclLeadCount : accountOverview.leads}</div>
                                    <div className="numText"><BarChartIcon /> Leads</div>
                                </Paper>
                            </Grid>
                            {commonState.userReducer.profileInfo.channelid !== 21 && <>
                                <Grid item xs={6} onClick={CampaignleadCardClick}>
                                    <Paper className="orangeBG">
                                        <ArrowRightAltIcon className="arrowIcon" />
                                        <div className="num">{accountOverview.campaignLeads}</div>
                                        <div className="numText"><BarChartIcon /> Campaign Leads</div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} onClick={PersistancyleadCardClick}>
                                    <Paper className="blueBG">
                                        <ArrowRightAltIcon className="arrowIcon" />
                                        <div className="num">{accountOverview.persistencyLeads}</div>
                                        <div className="numText"> <BarChartIcon /> Persistency Leads</div>
                                    </Paper>
                                </Grid>
                            </>}
                        </>
                        )}

                    {commonState.userReducer.profileInfo.channelid === 3 &&
                        ((commonState.userReducer.profileInfo.roleId === "NH" && viewForStatus !== 'SELF') ||
                            (commonState.userReducer.profileInfo.roleId === "RBH" && viewForStatus !== 'SELF') ||
                            (commonState.userReducer.profileInfo.roleId === "ZBH" && viewForStatus !== 'SELF')) &&
                        (<>
                            <Grid item xs={6} onClick={BusinessleadCardClick}>
                                <Paper className="redBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{accountOverview.leads}</div>
                                    <div className="numText"><BarChartIcon />Business Leads</div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} onClick={CampaignleadCardClick}>
                                <Paper className="orangeBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{accountOverview.campaignLeads}</div>
                                    <div className="numText"><BarChartIcon /> Campaign Leads</div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} onClick={PersistancyleadCardClick}>
                                <Paper className="blueBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{accountOverview.persistencyLeads}</div>
                                    <div className="numText"> <BarChartIcon /> Persistency Leads</div>
                                </Paper>
                            </Grid>
                        </>
                        )}
                    {/* commonState.userReducer.profileInfo.roleId === "ABH" ||
                            commonState.userReducer.profileInfo.roleId === "BDM" */}
                    {commonState.userReducer.profileInfo.channelid === 2 &&
                        ((commonState.userReducer.profileInfo.roleId === "NH" && viewForStatus !== 'SELF') ||
                            (commonState.userReducer.profileInfo.roleId === "RBH" && viewForStatus !== 'SELF') ||
                            (commonState.userReducer.profileInfo.roleId === "ZBH" && viewForStatus !== 'SELF')
                        ) &&
                        (<>
                            <Grid item xs={6} onClick={PersistancyleadCardClick}>
                                <Paper className="blueBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{accountOverview.persistencyLeads}</div>
                                    <div className="numText"> <BarChartIcon /> Persistency Leads</div>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className="redBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{accountOverview.campaignActivities}</div>
                                    <div className="numText"> <BarChartIcon /> Campaign Activity</div>
                                </Paper>
                            </Grid>

                        </>
                        )}

                    {commonState.userReducer.profileInfo.channelid === 3 &&
                        commonState.userReducer.profileInfo.roleId !== "NH" &&
                        commonState.userReducer.profileInfo.roleId !== "RBH" &&
                        commonState.userReducer.profileInfo.roleId !== "ZBH" && (
                            <Grid item xs={6} onClick={BusinessleadCardClick}>
                                <Paper className="redBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{accountOverview.leads}</div>
                                    <div className="numText"><BarChartIcon />Business Leads</div>
                                </Paper>
                            </Grid>
                        )}
                    {commonState.userReducer.profileInfo.channelid !== 2 && commonState.userReducer.profileInfo.channelid !== 79 && channelid !== 21 && roleId !== 'FPM' &&
                        commonState.userReducer.profileInfo.roleId !== "NH" &&
                        commonState.userReducer.profileInfo.roleId !== "RBH" &&
                        commonState.userReducer.profileInfo.roleId !== "ZBH" && (
                            <Grid item xs={6} onClick={CampaignleadCardClick}>
                                <Paper className="orangeBG">
                                    <ArrowRightAltIcon className="arrowIcon" />
                                    <div className="num">{accountOverview.campaignLeads}</div>
                                    <div className="numText"><BarChartIcon /> Campaign Leads</div>
                                </Paper>
                            </Grid>
                        )}
                    {commonState.userReducer.profileInfo.channelid !== 13 && channelid !== 21 && commonState.userReducer.profileInfo.channelid !== 79 &&
                        commonState.userReducer.profileInfo.roleId !== "NH" &&
                        commonState.userReducer.profileInfo.roleId !== "RBH" &&
                        commonState.userReducer.profileInfo.roleId !== "ZBH" && (
                            <>
                                {
                                    commonState.userReducer.profileInfo.channelid === 2 &&
                                    (commonState.userReducer.profileInfo.roleId === "BDM" || viewForStatus == "BDM") && <>
                                        <Grid item xs={6}
                                            onClick={campaignActivityCardClick}
                                        >
                                            <Paper className="redBG">
                                                <ArrowRightAltIcon className="arrowIcon" />
                                                <div className="num">{accountOverview.campaignActivities}</div>
                                                <div className="numText"> <BarChartIcon /> Campaign Activity</div>
                                            </Paper>
                                        </Grid>
                                    </>
                                }

                                < Grid item xs={6} onClick={PersistancyleadCardClick}>
                                    <Paper className="blueBG">
                                        <ArrowRightAltIcon className="arrowIcon" />
                                        <div className="num">{accountOverview.persistencyLeads}</div>
                                        <div className="numText"> <BarChartIcon /> Persistency Leads</div>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                </Grid >
            </Grid >
        </Grid >
        {
            (((roleId !== "RBH" || roleId !== "NH" || roleId !== "ZBH") && viewForStatus != "SELF") ||
                (roleId === "ABH" || roleId === "BDM" || roleId === "FPM")) && rbhView && channelid !== 21 &&
            <Grid className="accountOverviewSec" container>
                <Typography style={{ cursor: 'pointer' }} variant="h5" gutterBottom onClick={() => togglePerformStat()}>
                    {`${'Performance Stats '}${showPerformStat ? '-' : '+'}`}
                </Typography>
                {showPerformStat &&
                    <PerformanceStatsCard selectedView={selectedView} roleId={roleId} classes={classes} history={history} />
                }
            </Grid>
        }
    </Paper >
}

export default AccountOverViewCard;
