import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

const MessageBar = (props) => {
    /**
     * props : {
     * autoHideDuration : 3000,
     * severity : 'success'/'error',
     * message : ''
     * }
     */
    const commonState = useSelector(state => state);

    const messageState = commonState.commonReducer.messageBar;
    const autoHideDuration = props.autoHideDuration ? props.autoHideDuration : 3000
    const severity = messageState ? messageState.severity : 'success'
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch({
            type:'DEACTIVATE_MESSAGE_BAR',payload:false
        })
    };

    return <>
            { messageState && messageState.isActive ? 
                <Snackbar
                    open={ messageState.isActive }
                    autoHideDuration={autoHideDuration}
                    onClose={() => handleClose({ vertical: 'bottom', horizontal: 'right' })}
                >
                    <Alert severity={ severity }>
                        {messageState?.message}
                    </Alert>
                </Snackbar>
            : ''}
            </>
}

export default MessageBar
