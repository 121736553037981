import { createSlice } from '@reduxjs/toolkit';

const pehlaKadamSlice = createSlice({
  name: "pehlaKadam",
  initialState: {
    getPehlaKadamCheckListQus: [],
    getCheckListSaveResponse: {},
    getPehlaKdamOverview: 'all',
    getBDMChecklist: {},
    selectedBDMId: [],
    getBDMData: [],
    getPehlaKdamDashbordOverview: [],
    getExportDataFile: [],
    getBDMView: [],
    getBDMViewProfile: [],
    getABHByRBH: 0,
    getPehlaKadamGridQus:[],
  },
  reducers: {
    getPehlaKadamCheckListQus: (state, action) => {
      state.getPehlaKadamCheckListQus = action.payload;
    },
    getCheckListSaveResponse: (state, action) => {
      state.getCheckListSaveResponse = action.payload;
    },
    setPehlaKdamOverview: (state, action) => {
      state.getPehlaKdamOverview = action.payload
    },
    setBDMChecklist: (state, action) => {
      state.getBDMChecklist = action.payload
    },
    setSelectedBDMId: (state, action) => {
      state.selectedBDMId = action.payload
    },
    setBDMData: (state, action) => {
      state.getBDMData = action.payload
    },
    getPehlaKdamDashbordOverview: (state, action) => {
      state.getPehlaKdamDashbordOverview = action.payload
    },
    setExportDataFile: (state, action) => {
      state.getExportDataFile = action.payload
    },
    setBDMView: (state, action) => {
      state.getBDMView = action.payload
    },
    setBDMViewProfile: (state, action) => {
      state.getBDMViewProfile = action.payload
    },
    setABHByRBH: (state, action) => {
      state.getABHByRBH = action.payload
    },
    getPehlaKadamGridQus:(state, action)=>{
      state.getPehlaKadamGridQus = action.payload
    }
  }
});


export const { getPehlaKadamCheckListQus, getCheckListSaveResponse, setPehlaKdamOverview, setBDMChecklist
  , setSelectedBDMId, setBDMData, getPehlaKdamDashbordOverview, setExportDataFile, setBDMView,
   setBDMViewProfile, setABHByRBH,getPehlaKadamGridQus } = pehlaKadamSlice.actions
export default pehlaKadamSlice.reducer;

