import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../../../layouts/footer/footer';
import { getForm } from "../createLeadForm";
import {  getNextLeadByStatus, getLeadHistory, getCompaignLeadByID, getCampaignLeadListById } from "../../../../redux-reducer/reducer";
import { BANCA_LEAD, LEAD_ACTION_MAPPING,CAMPAIGNLEAD, LEAD} from "../../../../utils/config";
import { useParams } from "react-router";
import Loader from "../../../../components/loader/loader";
import '../createLead.scss';
import { CAMPAIGN_LEAD_PATH } from "../../../../route/routePath";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import LeadLayout from "./leadLayout";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import { setBreadcrumbs } from "../../../../redux/actions/common";
import { PERMISSIONS, isAuthorized } from "../../../../auth";
import { getBranchList, getLeadOffline, getLeadSourceNameList, getLeadTypeNameList, 
    getLgSpList, getSalutionNameList, getSPTypeNameList } from "../../../../offlineManager/leadOfflineManager";
import useOnlineStatus from "../../../../hooks/useOnlineStatus";
import { getLeadAction } from "../../../../redux-reducer/reducer/updateLeadReducer";
import {isUserSame} from "../../../../../src/utils/utils";

const CampaignLeadDetail = () => {
    const { id } = useParams();
    const history = useHistory();
    const isOnline = useOnlineStatus()
    const formName = BANCA_LEAD;
    const { lead_action_list, lead_history } = useSelector(leadState => leadState.updateLeadReducer);
    const { getCampaginLeadData } = useSelector(campaignState => campaignState.leadFormReducer);
    const { LeadStatusId } = getCampaginLeadData;
    const { isActiveLoader } = useSelector(loaderState => loaderState.commonReducer);
    const [state, setState] = useState({
        current: { id: 1 },
        formData: getForm(formName)
    })
    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const userId = JSON.parse(userData.userReducer).profileInfo.userId;
    const UserName = `${JSON.parse(userData.userReducer).profileInfo.firstName}${' '}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
    const dispatch = useDispatch();
    const [leadDetails, setLeadDetails] = useState();
    const [leadMsg, setLeadMsg] = useState();
    const [leadActionList, setLeadActionList] = useState();
    const [optionList, setOptionList] = useState({
        leadType: [],
        SPType: [],
        leadSource: [],
        branchType: [],
        salutationName: [],
        lgCodeType: [],
        spCodeType: []
    })
    const [campaignLeadList, setComapaignLeadLIst] = useState({})
    const goTo = (action, ids) => {
        history.push(`/app/lead/update/${CAMPAIGNLEAD}/${action}/${ids}`);
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    }
    const breadcrumbs = [
        { name: 'Campaign Leads', url: CAMPAIGN_LEAD_PATH },
        { name: 'Lead Details' }
    ]
    const getCompaignList = async () => {

        dispatch(getCompaignLeadByID({ id: id })).then((response) => {

            setComapaignLeadLIst(response?.data)

        })

    }
    useEffect(() => {
        setTimeout(() => {
            getCompaignList()
        }, 3000);
    }, [])
    const list = (anchor, leadId) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="updatePopupDiv">
                <ListItem color="black" sx={{ fontWeight: 'bold', fontSize: 20 }} > Update Lead
                    <CloseIcon />
                </ListItem>
                {leadActionList  && leadActionList.length > 0 && leadActionList.map((actn) => (
                    <ListItem className={`editBtn ${actn.ISActionable ? "" : "disabled"}`} button onClick={() => actn.ISActionable ? goTo(actn.LeadActionCode, leadId) : ""}>
                        <ListItemIcon >
                            <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                                <EditIcon />
                            </Fab> {actn.LeadActionName}
                        </ListItemIcon>
                        <ListItemText />
                    </ListItem>
                ))}
                  { isAuthorized(PERMISSIONS.REASSIGN_LEAD) ?
                <ListItem className={`editBtn`} button onClick={() =>goTo("REASSIGN", leadId) }>
                <ListItemIcon >
                    <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                        <EditIcon />
                    </Fab> Reassign

                </ListItemIcon>
                <ListItemText />
            </ListItem>: ''
              }

            </List>
            <Divider />

        </Box>
    )
    const getNameForIds = (collectionName, inputId) => {
        const collection = optionList[collectionName];
        if (inputId && collection) {
            const name = collection.filter((el) => el.key === inputId.toString() || el.value === inputId.toString() );
            if (name.length > 0) {
                return name[0].label;
            }
            return null;
        }
    }
    const getSPCodeForIds = (inputId) => {
        const collection = optionList['spCodeType'];
        if (inputId && collection) {
            const name = collection.filter((el) => el.SPID === parseInt(inputId));
            if (name.length > 0) {
                return name[0].SPCode;
            }
            return null;
        }
    }
    const getLGCodeForIds = (inputId) => {
        const collection = optionList['lgCodeType'];
        if (inputId && collection) {
            const name = collection.filter((el) => el.LGID === parseInt(inputId));
            if (name.length > 0) {
                return name[0].LGCode;
            }
            return null;
        }
    }
    const getBranchName = (inputId) => {
        const collection = optionList['branchType'];
        if (inputId && collection) {
            const name = collection.filter((el) => el.BranchID === inputId.toString());
            if (name.length > 0) {
                return name[0].BranchName;
            }
            return null;
        }
    }
    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch({
            type: "ACTIVATE_LOADER",
            payload: false
        })
    }, [])
    useEffect(() => {
        if (isOnline) {
            dispatch(getCampaignLeadListById(id));
            dispatch(getLeadHistory(id, 'campaignlead'));
        }
    }, [id])
    useEffect(() => {
        if (isOnline && getCampaginLeadData) {
            setLeadDetails(getCampaginLeadData)
        }else{
            setLeadMsg("Lead details not found");
        }
    }, [getCampaginLeadData])

    useEffect(() => {
        if (isOnline) {
         LeadStatusId && dispatch(getNextLeadByStatus(LeadStatusId, CAMPAIGNLEAD));//LeadStatusId
        } else {
            // this will get a actionlist object from config file for offline leads
            switch (LeadStatusId) {
                case 1:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_1']['actions']));
                    break;
                case 2:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_2']['actions']));
                    break;
                case 3:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_3']['actions']));
                break;
                case 4:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_4']['actions']));
                break;
                default:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_5']['actions']));
                    break;
            }
        }
    }, [LeadStatusId])
    useEffect(() => {
        if (!isOnline) {
            Promise.all([getBranchList(), getLeadSourceNameList(), getLeadTypeNameList(), getSPTypeNameList(), getSalutionNameList(), getLgSpList() ]).then((res) => {
                if (res && res.length > 0) {
                    setOptionList({
                        ...optionList,
                        branchType: res[0],
                        leadSource: res[1],
                        leadType: res[2],
                        SPType: res[3],
                        salutationName: res[4],
                        lgCodeType: res[5].Lgdetails,
                        spCodeType: res[5].Spdetails
                    })
                }
            })
        }
    }, []);
    useEffect(() => {
        if (!isOnline) {
            if (optionList.leadType.length > 0 && optionList.SPType.length > 0 && optionList.leadSource.length > 0 && optionList.branchType.length > 0) {
                getLeadOffline(id).then(res => {
                    if (res) {
                        // this object is prepared to match the signature of lead api response
                        const leadObject = {
                            ...res,
                            ApplicationRemarks: null,
                            AssignedTo: userId,
                            AssignedToName: UserName,
                            BillFileName: null,
                            BranchName: getBranchName(res.BranchID),
                            Campaign: null,
                            ChannelID: null,
                            ChannelName: null,
                            IsActive: true,
                            IsClaimRequired: null,
                            IsDeleted: false,
                            LeadCode: null,
                            LeadSourceName: getNameForIds('leadSource', res.LeadSourceID),
                            LeadState: null,
                            LeadTypeName: getNameForIds('leadType', res.LeadTypeID),
                            MeetingDuration: null,
                            OtherReason: null,
                            PremiumCollected: null,
                            PriorityID: res?.PriorityID,
                            PriorityName: res?.PriorityName,
                            ReasonID: null,
                            ReasonName: "",
                            Remarks: res?.Remarks,
                            SPTypeName: getNameForIds('SPType', res.SPTypeID),
                            SimplifyLeadID: null,
                            SourceName: null,
                            // SalutationName: getNameForIds('salutationName', res.SalutationID),
                            Salutation: getNameForIds('salutationName', res.SalutationID),
                            TransportID: null,
                            TransportMode: null,
                            TravelExpense: null,
                            UploadBill: null,
                            UserName: UserName,
                            VehicleTypeID: null,
                            VehicleTypeName: null,
                            CheckinCount: res?.CheckinCount,
                            SPCode: getSPCodeForIds(res.SPID),
                            LGCode: getLGCodeForIds(res.LGID),
                        }
                        setLeadDetails(leadObject)
                    }
                })
            }
        }
    }, [optionList])

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    const classes = useStyles();
    useEffect(() => {
        if (lead_action_list) {
            setLeadActionList(lead_action_list);
        }
    }, [lead_action_list])
    const getHistory = () => {
        dispatch(getLeadHistory(id,'campaignlead'));
    }
    return (
        <div className="mainContainer leadDetails">
            <Breadcrumb />
            {
                leadDetails ?
                    <LeadLayout hitory={lead_history} leadDetails={leadDetails} classes={classes} getCampaignLeadData={campaignLeadList} layoutFor={LEAD} onGetUpdatedHistory={getHistory}/>
                :
                <h4>{leadMsg}</h4>
            }
            <span>
                {leadActionList &&  isUserSame(leadDetails.AssignedTo,userId) && leadActionList.length > 0 && ['bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>  */}
                        <Box className="editBtn" sx={{ '& > :not(style)': { m: 1 } }} onClick={toggleDrawer(anchor, true)} >
                            <Fab color="primary" aria-label="add" sx={{ float: 'right' }}>
                                <EditIcon />
                            </Fab>
                        </Box>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor, id)} 
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </span>
            <Footer />
            {isActiveLoader && <Loader />}
        </div >
    )
}
export default CampaignLeadDetail;
