import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../../layouts/footer/footer';
import { getForm } from "./createLeadForm";
import { getLeadListById, getNextLeadByStatus, getLeadHistory } from "../../../redux-reducer/reducer";
import { BANCA_LEAD, LEAD_ACTION_MAPPING, LEAD, RECRUITMENTLEAD, GCLLEAD } from "../../../utils/config";
import { useParams } from "react-router";
import Loader from "../../../components/loader/loader";
import './createLead.scss';
import { CREATE_LEAD_PATH, LEADS_PATH, RECRUITMENT_LEADS_PATH, BUSINESS_LEAD_PATH } from "../../../route/routePath";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import LeadLayout from "./leadLayout";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { setBreadcrumbs } from "../../../redux/actions/common";
import { PERMISSIONS, isAuthorized } from "../../../auth";
import { getBranchList, getLeadOffline, getLeadSourceNameList, getLeadTypeNameList, getAllLgSp, getSalutionNameList, getSPTypeNameList, getLeadOfflineHistory } from "../../../offlineManager/leadOfflineManager";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { getLeadAction } from "../../../redux-reducer/reducer/updateLeadReducer";
import { setSelectedLeadId } from "../../../redux-reducer/reducer/leadListReducer";
import { isUserSame } from "../../../../src/utils/utils";
import { getAdvisorZMAPremierZMAListing } from '../../../redux/actions/common'

const LeadDetail = () => {
    const { id } = useParams();
    const history = useHistory();
    const isOnline = useOnlineStatus()
    const formName = BANCA_LEAD;
    const { lead_action_list, lead_history } = useSelector(state => state.updateLeadReducer);
    const { getLeadData } = useSelector(state => state.leadFormReducer);
    const { LeadStatusId, IsRecruitmentLead } = getLeadData;
    const { isActiveLoader } = useSelector(state => state.commonReducer);
    const [state, setState] = useState({
        current: { id: 1 },
        formData: getForm(formName)
    })
    const commonState = useSelector(mainState => mainState)
    const userState = commonState.userReducer;
    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const userId = JSON.parse(userData.userReducer).profileInfo.userId;
    const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
    const channelid = JSON.parse(userData.userReducer).profileInfo.channelid;

    const UserName = `${JSON.parse(userData.userReducer).profileInfo.firstName}${' '}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
    const dispatch = useDispatch();
    const [leadDetails, setLeadDetails] = useState();

    const [leadMsg, setLeadMsg] = useState();
    const [leadActionList, setLeadActionList] = useState();
    const [leadHistoryState, setLeadHistory] = useState();

    const [optionList, setOptionList] = useState({
        leadType: [],
        SPType: [],
        leadSource: [],
        branchType: [],
        salutationName: [],
        lgCodeType: [],
        spCodeType: []
    })
    const getListData = async () => {
        if (userState.profileInfo.channelid == 3) {
            dispatch(getAdvisorZMAPremierZMAListing({
                userId: userId,
                status: "ZMA"
            }))
            dispatch(getAdvisorZMAPremierZMAListing({
                userId: userId,
                status: "Advisor"
            }))
            dispatch(getAdvisorZMAPremierZMAListing({
                userId: userId,
                status: "Premier ZMA"
            })
            )
        }
    }
    const goTo = (action, id) => {
        if (userState.profileInfo.channelid === 13) {
            if(action === "ASSIGN"){
                handleReassign(id);
            } else {
            history.push(`/app/lead/gcl/update/${LEAD}/${action}/${id}`);
            }
        } else if (userState.profileInfo.channelid === 79) {
            if(action === "ASSIGN"){
                handleReassign(id);
            } else {
            history.push(`/app/lead/msme/update/${LEAD}/${action}/${id}`);
            }
        }
        else if (userState.profileInfo.channelid === 3) {
            history.push(`/app/lead/agency/update/${LEAD}/${action}/${id}`);
        }
        else {
            history.push(`/app/lead/update/${LEAD}/${action}/${id}`);
        }
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    }
    useEffect(() => {
        let breadcrumbs = [];
        if (channelid !== 3) {
            breadcrumbs = [
                { name: 'Leads', url: LEADS_PATH },
                { name: 'Lead Details', url: CREATE_LEAD_PATH }
            ]
        } else {
            if (getLeadData.IsRecruitmentLead === 0) {
                breadcrumbs = [
                    { name: 'Business Leads', url: BUSINESS_LEAD_PATH },
                    { name: 'Business Lead Details', url: CREATE_LEAD_PATH }
                ]
            } else {
                breadcrumbs = [
                    { name: 'Recruitment Leads', url: RECRUITMENT_LEADS_PATH },
                    { name: 'Recruitment Lead Details', url: CREATE_LEAD_PATH }
                ]
            }
        }
        dispatch(setBreadcrumbs(breadcrumbs));
        LeadStatusId && dispatch(getNextLeadByStatus(LeadStatusId, (channelid == 13 || channelid == 79) ? GCLLEAD : getLeadData.IsRecruitmentLead === 0 ? LEAD : RECRUITMENTLEAD ));
        // LeadStatusId && dispatch(getNextLeadByStatus(LeadStatusId, channelid == 13 ? GCLLEAD : getLeadData.IsRecruitmentLead === 0 ? LEAD : RECRUITMENTLEAD ));
        //Comment bcoz facing pencil icon issue to except GCL
        // LeadStatusId && dispatch(getNextLeadByStatus(LeadStatusId, roleId == 13 ? getLeadData.IsRecruitmentLead === 0 ? LEAD : RECRUITMENTLEAD : GCLLEAD));

    }, [getLeadData]);
    const handleReassign = (ids) => {
        dispatch(setSelectedLeadId([ids]))
        history.push('/app/lead/reassignLead')
    }
    const list = (anchor, leadId) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            
            <List className="updatePopupDiv">

                <ListItem color="black" sx={{ fontWeight: 'bold', fontSize: 20 }} >
                    {channelid === 3 ? ((getLeadData.IsRecruitmentLead === 1) ? "Update Recruitment Leads" : "Update Business Leads") : "Update Lead"}
                    <CloseIcon />
                </ListItem>
                {leadActionList && leadActionList.length > 0 && leadActionList.map((actn) => (
                 actn.ISActionable && <>
                        <ListItem className={`editBtn ${actn.ISActionable ? "" : "disabled"}`} button onClick={() => actn.ISActionable ? goTo(actn.LeadActionCode, leadId) : ""}>
                            <ListItemIcon >
                                <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                                    <EditIcon />
                                </Fab> {
                                    userState.profileInfo.channelid === 3 && IsRecruitmentLead === 0 ? `Business ${actn.LeadActionName}` :
                                        userState.profileInfo.channelid === 3 && IsRecruitmentLead === 1 ? `Recruitment ${actn.LeadActionName}`
                                            : userState.profileInfo.channelid === 13 && actn.LeadActionName === "Assign" ? 
                                            isAuthorized(PERMISSIONS.REASSIGN_LEAD) ?
                                                actn.LeadActionName : ''
                                            : actn.LeadActionName
                                }
                            </ListItemIcon>
                            <ListItemText />
                        </ListItem>
                      </>
                ))}
                {isAuthorized(PERMISSIONS.REASSIGN_LEAD) && userState.profileInfo.channelid !== 13 && userState.profileInfo.channelid !== 79 ?
                    <ListItem className={`editBtn`} button onClick={() => handleReassign(id)}>
                        <ListItemIcon >
                            <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                                <EditIcon />
                            </Fab> Reassign
                        </ListItemIcon>
                        <ListItemText />
                    </ListItem> : ''
                }

            </List>
            <Divider />
        </Box >
    )
    const getNameForIds = (collectionName, inputId) => {
        const collection = optionList[collectionName];
        if (inputId && collection) {
            const name = collection.filter((el) => el.key === inputId.toString() || el.value === inputId.toString());
            if (name.length > 0) {
                return name[0].label;
            }
            return null;
        }
    }
    const getSPCodeForIds = (inputId) => {
        const collection = optionList['spCodeType'];
        if (inputId && collection) {
            const name = collection.filter((el) => el.SPID === parseInt(inputId));
            if (name.length > 0) {
                return name[0].SPCode;
            }
            return null;
        }
    }
    const getLGCodeForIds = (inputId) => {
        const collection = optionList['lgCodeType'];
        if (inputId && collection) {
            const name = collection.filter((el) => el.LGID === parseInt(inputId));
            if (name.length > 0) {
                return name[0].LGCode;
            }
            return null;
        }
    }
    const getBranchName = (inputId) => {
        const collection = optionList['branchType'];
        if (inputId && collection) {
            const name = collection.filter((el) => el.BranchID === inputId.toString());
            if (name.length > 0) {
                return name[0].BranchName;
            }
            return null;
        }
    }
    useEffect(() => {
        dispatch({
            type: "ACTIVATE_LOADER",
            payload: false
        })
        getListData()
        // getLeadHistoryOffline();
    }, [])
    useEffect(() => {
        if (isOnline) {
            dispatch(getLeadListById(id));
            dispatch(getLeadHistory(id, LEAD));
        }
    }, [id])
    useEffect(() => {
        if (isOnline && getLeadData) {
            setLeadDetails(getLeadData);
        } else {
            setLeadMsg("Lead details not found");
        }
    }, [getLeadData])
    useEffect(() => {
        if (isOnline) {
            LeadStatusId && dispatch(getNextLeadByStatus(LeadStatusId, (channelid == 13 || channelid == 79) ? GCLLEAD : getLeadData.IsRecruitmentLead === 0 ? LEAD : RECRUITMENTLEAD));
        } else {
            // this will get a actionlist object from config file for offline leads
            switch (LeadStatusId) {
                case 1:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_1']['actions']));
                    break;
                case 2:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_2']['actions']));
                    break;
                case 3:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_3']['actions']));
                    break;
                case 4:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_4']['actions']));
                    break;
                default:
                    dispatch(getLeadAction(LEAD_ACTION_MAPPING['LEAD_STATUS_ID_5']['actions']));
                    break;
            }
        }
    }, [getLeadData])
    useEffect(() => {
        if (!isOnline) {
            Promise.all([getBranchList(), getLeadSourceNameList(), getLeadTypeNameList(), getSPTypeNameList(), getSalutionNameList(), getAllLgSp()]).then((res) => {
                if (res && res.length > 0) {
                    setOptionList({
                        ...optionList,
                        branchType: res[0],
                        leadSource: res[1],
                        leadType: res[2],
                        SPType: res[3],
                        salutationName: res[4],
                        lgCodeType: res[5].lgList,
                        spCodeType: res[5].spList
                    })
                }
            })
        }
    }, []);
    useEffect(() => {
        if (!isOnline) {
            if (optionList.leadType.length > 0 && optionList.SPType.length > 0 && optionList.leadSource.length > 0 && optionList.branchType.length > 0) {
                getLeadOffline(id).then(res => {
                    if (res) {
                        // this object is prepared to match the signature of lead api response
                        const leadObject = {
                            ...res,
                            ApplicationRemarks: null,
                            AssignedTo: userId,
                            AssignedToName: UserName,
                            BillFileName: null,
                            BranchName: getBranchName(res.BranchID),
                            Campaign: null,
                            ChannelID: null,
                            ChannelName: null,
                            IsActive: true,
                            IsClaimRequired: null,
                            IsDeleted: false,
                            LeadCode: null,
                            LeadSourceName: getNameForIds('leadSource', res.LeadSourceID),
                            LeadState: null,
                            LeadTypeName: getNameForIds('leadType', res.LeadTypeID),
                            MeetingDuration: null,
                            OtherReason: null,
                            PremiumCollected: null,
                            PriorityID: res?.PriorityID,
                            PriorityName: res?.PriorityName,
                            ReasonID: null,
                            ReasonName: "",
                            Remarks: res?.Remarks,
                            SPTypeName: getNameForIds('SPType', res.SPTypeID),
                            SimplifyLeadID: null,
                            SourceName: null,
                            // SalutationName: getNameForIds('salutationName', res.SalutationID),
                            Salutation: getNameForIds('salutationName', res.SalutationID),
                            TransportID: null,
                            TransportMode: null,
                            TravelExpense: null,
                            UploadBill: null,
                            UserName: UserName,
                            VehicleTypeID: null,
                            VehicleTypeName: null,
                            CheckinCount: res?.CheckinCount,
                            SPCode: getSPCodeForIds(res.SPID),
                            LGCode: getLGCodeForIds(res.LGID),
                        }
                        setLeadDetails(leadObject)
                    }
                })
                getLeadHistoryOffline();
            }
        }
    }, [optionList])

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    const classes = useStyles();
    useEffect(() => {
        if (lead_action_list) {
            if(roleId === "BDM" && (channelid === 13 || channelid === 79) ){
                const data = lead_action_list.filter((ele) => {
                    return ele.LeadActionName !== "Assign"
                })
                setLeadActionList(data);
            } else {
                setLeadActionList(lead_action_list);
            }
            
        }
    }, [lead_action_list])
    useEffect(() => {
        if (lead_history) {
            setLeadHistory(lead_history)
        }
    }, [lead_history])
    const getHistory = () => {
        dispatch(getLeadHistory(id, LEAD));
    }
    const getLeadHistoryOffline = () => {
        getLeadOfflineHistory(id).then((result) => {
            if (result && result.HistoryOutput) {
                setLeadHistory(result.HistoryOutput)
            }
        })
    }
    return (
        <div className="mainContainer leadDetails">
            <Breadcrumb />
            {
                (leadDetails && leadHistoryState) ?
                    <LeadLayout hitory={leadHistoryState} leadDetails={leadDetails} classes={classes} layoutFor={LEAD} onGetUpdatedHistory={getHistory} roleId={roleId} />
                    :
                    <h4>{leadMsg}</h4>
            }
            <span>
                {leadActionList && isUserSame(leadDetails?.AssignedTo, userId) && leadActionList.length > 0 && ['bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>  */}
                        <Box className="editBtn" sx={{ '& > :not(style)': { m: 1 } }} onClick={toggleDrawer(anchor, true)} >
                            <Fab color="primary" aria-label="add" sx={{ float: 'right' }}>
                                <EditIcon />
                            </Fab>
                        </Box>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor, id)}
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </span>
            <Footer />
            {isActiveLoader && <Loader />}
        </div >
    )
}
export default LeadDetail;
