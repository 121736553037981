import React from "react";
import Footer from "../../../layouts/footer/footer";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from "react-i18next";
import {
  ActivityCancelledFormPart1,
  ActivityCancelledFormPart2,
  ActivityCancelledFormPart3,
  ActivityFormBuilder,
  customValidators,
  handleDropdownSelection,
  initFormData,
} from "./banka/updateActivityForm";
import {
  ACTIVITY_RESCHEDULED,
  ACTIVITY_COMPLETED,
  ACTIVITY_CANCELLED,
  CONFIGS,
  FOUR_WHEELER,
  PUBLIC_TRANSPORT,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  JOINTCALL_COMPLETED,
  checkInConfig,
  JOINTCALL_CANCELLED,
  JOINTCALL_RESCHEDULED,
} from "../../../utils/config";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import moment from "moment";
import {
  setActivityData,
  updateDynamicData,
  getMasterData,
  getActivityById,
  uploadFileToBlob,
  activateSuccessMessageBar,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
  getActivityStatusMasterData,
  setBreadcrumbs,
  getJointCallActivityCancelReason,
  getJointCallActivityRescheduleReason,
} from "../../../redux/actions";
import { connect } from "react-redux";

import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import "./createActivity.scss";
import {
  checkMobileNumberReducer,
  createLeadDeDupeValidation,
  getAutoCompleteActivities,
  validateMeetingDateForCreateLead,
  saveLeadForm,
  validateIsCheckin,
} from "../../../redux-reducer/reducer";
import { removeSubArrayFromArray } from "./banka/createActivityForm";
import { checkDateIsValid, isBranchValid } from "../../../utils/validators";
import { updateActivityOffline } from "../../../offlineManager/activityOfflineManager";
import { validateActivity } from "../../../redux/actions/createActivityActions";
import {
  minusMinuteFromDate,
  addMinuteToDate,
  getDropdownData,
  uuidv4,
} from "../../../utils/utils";
import { getActivityDetailsHistory } from "../../../redux/actions/activityDetailsAction";
import {
  getSRMList,
  isLeadValid,
  saveLeadOffline,
} from "../../../offlineManager/leadOfflineManager";
import { getOptionByCode } from "./helper";

import { format } from "date-fns";
const DateFormatString = "YYYY-MM-DDTHH:mm";
const _formatDate = "YYYY-MM-DDTHH:mm";
class UpdateActivity extends React.Component {
  constructor(props) {
    super(props);
    this.dyFormRef = React.createRef();
    this.userData = JSON.parse(localStorage.getItem("persist:root"));
    this.roleId = JSON.parse(this.userData.userReducer).profileInfo.roleId;
    this.channelid = JSON.parse(this.userData.userReducer).profileInfo.channelid;
    this.userId = JSON.parse(this.userData.userReducer).profileInfo.userId;
    this.employeeCode = JSON.parse(this.userData.userReducer).profileInfo.employeeCode;
    this.formBuilder = new ActivityFormBuilder(
      props.activityData.activityComplete
        ? ACTIVITY_COMPLETED
        : props.activityData.fpmActivityCancel ?
          JOINTCALL_CANCELLED :
          props.activityData.fpmActivityReschedule ?
            JOINTCALL_RESCHEDULED
            : props.activityData.activityReshedule
              ? ACTIVITY_RESCHEDULED
              : props.activityData.fpmActivityComplete
                ? JOINTCALL_COMPLETED
                : ACTIVITY_CANCELLED,
      props.activityData.getSelectActivityData.ActivityIdName
    );

    this.state = {
      current: {
        id: 1,
        MeetingStartDateTime:
          props.activityData.getSelectActivityData.MeetingStartDateTime,
        MeetingEndDateTime: addMinuteToDate(
          props.activityData.getSelectActivityData.MeetingEndDateTime
        ),
        IsClaimRequired: false,
        ISleadHasMeeting: true,
        leadgenerated: false,
      },
      autoCompleteItems: {
        ActivityID: [],
      },
      labelForId: {
        ActivityIDLabel: "",
        BranchIDLabel: "",
        LGIDLabel: "",
        SPIDLabel: "",
        SRMIDLabel: "",
      },
      activityStatus: {},
      selectedActivityOldData: {},
      formData: (this.props && this.props.activityData &&
        this.props.activityData.getSelectActivityData &&
        this.props.activityData.getSelectActivityData &&
        this.props.activityData.getSelectActivityData.FpmMeetingTypeName !== "Face to Face" &&
        this.props && this.props.activityData && this.props.activityData.getSelectActivityData &&
        this.props.activityData.getSelectActivityData &&
        this.props.activityData.getSelectActivityData.ActivityID === 25) ? this.formBuilder.getForm().filter(ele => ele.key !== "IsClaimRequired") : this.formBuilder.getForm(),
      uploadedFile: "",
      uploadedPhotoActivity: '',
      uploadTrainingDoucment: '',
      extErrors: {},
      selectActivityID: "",
      responseMessage: "",

    };
    const getFpmCancelReasonDropdownOption = async () => {
      const result = await this.props.getFpmActivityCancelReason()
      if (result.status === 200) {
        const fpmCancelReason = this.getDropdownDataWithoutCombination(result.data, "ShortCode", "Name", "ID")
        this.state.formData.find((item) => item.key === "Reason").options = fpmCancelReason
      }
    }
    if (this.props.activityData.fpmActivityCancel) {
      getFpmCancelReasonDropdownOption()
    }
    const getFpmRescheduleReasonDropdown = async () => {
      const result = await this.props.getFpmActivityRescheduleReason()
      if (result.status === 200) {
        const fpmCancelReason = this.getDropdownDataWithoutCombination(result.data, "ShortCode", "Name", "ID")
        this.state.formData.find((item) => item.key === "Reason").options = fpmCancelReason
      }
    }

    if (this.props.activityData.fpmActivityReschedule) {
      getFpmRescheduleReasonDropdown()
    }
  }

  isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await this.props.validateActivity({
        user_id: this.props.userState.profileInfo.userId,
        formData: formData,
      });
      if (checkActivity.data.Responsestatus === false) {
        this.setState({
          responseMessage: checkActivity.data.Responsemessage,
        });
      }
      return (
        checkActivity.status === 200 &&
        checkActivity.data.Responsestatus === true
      );
    }
    return true;
  };
  getNameForId = (optionArr, inputId) => {
    const matchEl = optionArr.filter((option) =>
      option.key === inputId || option.value === inputId ? option.label : ""
    );
    if (matchEl && matchEl.length > 0) {
      return matchEl[0].label;
    }
    return null;
  };
  handleSaveLeadResponse = (result, modeofAct) => {
    if (result.status === 200 && result.data.Responsestatus === true) {
      this.props.activateSuccessMessageBar(
        `Lead has been ${modeofAct} successfully`
      );
      this.props.deActivateLoader();
      if (modeofAct === "Created") {
        this.props.history.push(
          `/app/lead/detail/${result.data.Transactionid}`
        );
      } else if (modeofAct === "Drafted") {
        this.props.history.push("/app/lead/all");
      }
    } else {
      this.props.deActivateLoader();
      this.props.activateSuccessMessageBar(
        "Something went wrong! please try again later."
      );
    }
  };

  createLeadFPMUser = async (model, trnActivityID) => {
    if (
      !model.isValidForm ||
      Object.values(model.errors).filter((item) => item !== undefined).length >
      0 ||
      Object.keys(this.state.extErrors) > 0
    ) {
      const errorListClasess = Object.keys(model.errors);
      const errorElement = document.getElementsByClassName(
        `${"control-"}${errorListClasess[0]}`
      )[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: "center",
        });
        this.props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }
    const data = { ...model };
    delete data["advanceLayoutFields"];
    delete data["collapseStates"];
    delete data["errors"];
    delete data["isValidForm"];
    delete data["id"];
    delete data["ActivityIDlabel"];
    data["LeadStatusId"] = 1; //'NEW';
    data["UserId"] = this.userId;
    data["EmployeeCode"] = this.employeeCode;
    data["CreatedBy"] = parseInt(this.userId);
    data["ModifiedBy"] = parseInt(this.userId);

    // not requured anymore for FPM user
    if (this.roleId === "FPM") {
      data["LGID"] = null;
      data["SPID"] = null;
    }

    if (this.id) {
      data["IsBacklog"] = 0;
      data["LeadId"] = this.id;
    }
    if (data.ISleadHasMeeting) {
      data["MeetingStartDateTime"] = data["MeetingStartDateTime"].replace(
        "T",
        " "
      );
    } else {
      data["MeetingStartDateTime"] = null;
    }
    if (data.ISleadHasMeeting) {
      data["MeetingEndDateTime"] = minusMinuteFromDate(
        data["MeetingEndDateTime"]
      ).replace("T", " ");
    } else {
      data["MeetingEndDateTime"] = null;
    }
    data["ActivityID"] = trnActivityID;
    const { ProductDetails, Salutation } = this.props.masterDataState;
    if (navigator.onLine && data.ISleadHasMeeting) {
      const res = await this.props.createLeadDeDupeValidation(data);
      if (res) {
        const validationResponse =
          await this.props.validateMeetingDateForCreateLead({
            startDate:
              this.state.startMeetingDate || data["MeetingStartDateTime"],
            endDate: this.state.endMeetingDate || data["MeetingEndDateTime"],
            id: this.userId,
            formData: data,
            formBuilder: this.formName,
            history: this.props.history,
            leadId: this?.id,
            leadTypeId: 1,
          });
        const { Responsestatus, Responsemessage } = validationResponse.data;
        if (!Responsestatus) {
          const extError = {
            ...this.state.extErrors,
            MeetingStartDateTime: "Meeting already exits.",
            MeetingEndDateTime: "Meeting already exits.",
          };
          this.setState({ extErrors: extError });
          this.props.activateErrorMessageBar(Responsemessage);
          this.props.deActivateLoader();
        } else {
          const leadScoreRequest = {
            productName: this.getNameForId(ProductDetails, data.ProductID),
            salutationName: `${this.getNameForId(
              Salutation,
              data.SalutationID
            )}${"."}`,
            priorityName: data.PriorityName,
            lgCode: this.state.labelForId.LGIDLabel.split(" ")[0],
            branchCode: this.state.labelForId.BranchIDLabel,
            branchCodeActivity:
              this.state.labelForId.BranchIDLabel.split(" ")[0],
            activity: this.state.labelForId.ActivityIDLabel,
          };
          const result = await this.props.saveLeadForm({
            data: data,
            formName: this.formName,
            leadScoreRequest: leadScoreRequest,
          });
          this.handleSaveLeadResponse(result, "Created");
        }
      }
    } else {
      const mmData = {
        ...data,
        CreatedDate:
          format(new Date(), "yyyy-MM-dd") +
          " " +
          format(new Date(), "kk:mm:ss"),
        CreatedBy: parseInt(this.userId),
        ModifiedDate:
          format(new Date(), "yyyy-MM-dd") +
          " " +
          format(new Date(), "kk:mm:ss"),
        ModifiedBy: parseInt(this.userId),
        LmsleadGuid: uuidv4(),
        LmsleadlastSyncTime:
          format(new Date(), "yyyy-MM-dd") +
          " " +
          format(new Date(), "kk:mm:ss"), //Date.parse(new Date().toUTCString()),
        IsLmsSync: navigator.onLine,
        ActivityID: model.ActivityID,
        LeadStatus: "New Customer",
        ProductName: this.getNameForId(ProductDetails, data.ProductID),
        SalutationName: this.getNameForId(Salutation, data.SalutationID),
        Remarks: model.Remarks,
        PriorityID: model?.PriorityID,
        PriorityName: model.PriorityName ? model.PriorityName : "",
        IsBacklog: false,
      };
      if (navigator.onLine) {
        const res = await this.props.createLeadDeDupeValidation(data);
        const leadScoreRequest = {
          productName: mmData.ProductName,
          salutationName: `${mmData.SalutationName}${"."}`,
          priorityName: mmData.PriorityName,
          lgCode: this.state.labelForId.LGIDLabel.split(" ")[0],
          branchCode: this.state.labelForId.BranchIDLabel,
          branchCodeActivity:
            this.state.labelForId.BranchIDLabel.split(" ")[0],
          activity: this.state.labelForId.ActivityIDLabel,
        };
        if (res) {
          this.props.activateLoader();
          const result = await this.props.saveLeadForm({
            data: data,
            formName: this.formName,
            leadScoreRequest: leadScoreRequest,
          });
          this.handleSaveLeadResponse(result, "Created");
        }
      }
      // }
      if (!navigator.onLine) {
        saveLeadOffline(mmData).then((res) => {
          if (res) {
            // const { data } = res;
            const { LeadId, LmsleadGuid } = res?.data?.data;
            if (LeadId) {
              this.props.history.push(`/app/lead/detail/${LeadId}`);
            } else {
              this.props.history.push(`/app/lead/detail/${LmsleadGuid}`);
            }
          }
        });
      }
    }
  };
  //

  onSubmitHandler = async (model) => {
    const oldData = this.state.selectedActivityOldData;
    if (oldData.ActivityStatusId === 2) {
      this.props.activateErrorMessageBar("Activity already completed.");
      return false;
    } else if (oldData.ActivityStatusId === 4) {
      this.props.activateErrorMessageBar("Activity already cancelled.");
      return false;
    }
    let activityModel = {};
    let leadModel = {};
    if (
      Object.keys(model.errors).length > 0 ||
      Object.keys(this.state.extErrors).length > 0
    ) {
      this.props.activateErrorMessageBar("Please fill all required fields.");
      return false;
    }
    if (!model.leadgenerated) {
      delete model.errors["BranchID"];
      delete model.errors["Email"];
      delete model.errors["FirstName"];
      delete model.errors["LastName"];
      delete model.errors["LeadSourceID"];
      delete model.errors["MobileNo"];
      delete model.errors["PriorityID"];
      delete model.errors["ProductID"];
      delete model.errors["SPTypeID"];
      delete model.errors["SRMID"];
      delete model.errors["SalutationID"];
      delete model.errors["ExpectedPremium"];
      activityModel = model;
    } else {
      activityModel = {
        MeetingEndDateTime: model?.MeetingEndDateTime,
        MeetingStartDateTime: model?.MeetingStartDateTime,
        Remarks: model?.Remarks,
        TransportModeID: model.TransportModeID,
        TravelExpence: model?.TravelExpence,
        UploadBill: model?.UploadBill,
        UploadTrainingAttendanceDocument1: model?.UploadTrainingAttendanceDocument1,
        UploadTrainingAttendancePhoto3: model?.UploadTrainingAttendancePhoto3,
        advanceLayoutFields: model?.advanceLayoutFields,
        collapseStates: model?.collapseStates,
        errors: {},
        id: model?.id,
        isActiveCheckin: model?.isActiveCheckin,
        isValidForm: model?.isValidForm,
        FpmCallDurationID: model?.FpmCallDurationID,
        leadgenerated: model?.leadgenerated,
      };
      leadModel = {
        ActivityIDlabel: model?.ActivityIDlabel,
        AlternateNo: model?.AlternateNo,
        BranchID: model?.BranchID,
        Email: model?.Email,
        ExpectedPremium: model?.ExpectedPremium,
        FirstName: model?.FirstName,
        ISleadHasMeeting: model?.ISleadHasMeeting,
        IsClaimRequired: model?.IsClaimRequired,
        LGID: model?.LGID,
        LastName: model?.LastName,
        LeadSourceID: model?.LeadSourceID,
        Location: model?.Location,
        MeetingEndDateTime: model?.MeetingEndDateTime,
        MeetingStartDateTime: model?.MeetingStartDateTime,
        ExpectedLeadClosureDate: model?.MeetingEndDateTime.replace("T", " "),
        MobileNo: model?.MobileNo,
        PriorityID: model?.PriorityID,
        ProductID: model?.ProductID,
        Remarks: model?.LeadRemarks,
        SPID: model?.SPID,
        SPTypeID: model?.SPTypeID,
        SRMID: model?.SRMID,
        SalutationID: model?.SalutationID,
        errors: {
          BranchID: model?.errors?.BranchID,
          Email: model?.errors?.Email,
          ExpectedPremium: model?.errors?.ExpectedPremium,
          FirstName: model?.errors?.FirstName,
          LastName: model?.errors?.LastName,
          LeadSourceID: model?.errors?.LeadSourceID,
          MobileNo: model?.errors?.MobileNo,
          PriorityID: model?.errors?.PriorityID,
          ProductID: model?.errors?.ProductID,
          SPTypeID: model?.errors?.SPTypeID,
          SRMID: model?.errors?.SRMID,
          SalutationID: model?.errors?.SalutationID,
          FpmCallDurationID: model?.errors?.FpmCallDurationID,
        },
      };
    }

    this.props.activateLoader();
    if (
      activityModel.MeetingStartDateTime &&
      activityModel.MeetingEndDateTime
    ) {
      activityModel.MeetingStartDateTime =
        activityModel.MeetingStartDateTime.replace("T", " ").replace("Z", "");
      activityModel.MeetingEndDateTime = minusMinuteFromDate(
        activityModel.MeetingEndDateTime
      )
        .replace("T", " ")
        .replace("Z", "");
    }

    if (activityModel.DateOfTraining) {
      activityModel.DateOfTraining = activityModel.DateOfTraining.replace(
        "T",
        " "
      ).replace("Z", "");
    }
    if (model.MeetingEndDateTime === "Invalid date") {
      model.MeetingEndDateTime = null;
      model.MeetingStartDateTime = null;
    }

    const _format = "YYYY-MM-DD HH:mm:ss";
    oldData.ActivityStatusId = this.state.activityStatus?.ActivityStatusId;
    oldData.ActivityStatus = this.state.activityStatus?.ActivityStatus;
    oldData.ModifiedDate = moment(new Date())
      .format(DateFormatString)
      .replace("T", " ")
      .replace("Z", "");
    if (this.props.activityData.fpmActivityCancel) {
      oldData.FpmActivityCancelReasonID = model.Reason
      delete activityModel.Reason
    }
    if (this.props.activityData.fpmActivityReschedule) {
      oldData.FpmActivityRescheduleReasonID = model.Reason
      delete activityModel.Reason
    }
    const formData = { ...oldData, ...activityModel };

    if (model.IsClaimRequired) {
      formData['TransportModeID'] = parseInt(model.TransportModeID);
      formData['TransportMode'] = model.TransportModeID === "1" ? "Public Transport" : "Self Owned Transport";
      formData['TravelExpence'] = model.TravelExpence;
      formData['VehicleTypeID'] = model.VehicleTypeID ? model.VehicleTypeID : null;
      formData['IsClaimRequired'] = model.IsClaimRequired;
    } else {
      formData['IsClaimRequired'] = false;
      formData['TransportMode'] = null;
      formData['TransportModeID'] = null;
      formData['TravelExpence'] = null;
      formData['VehicleTypeID'] = null;

    }
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    formData["IsCheckin"] = false;
    formData["CreatedBy"] = parseInt(formData["CreatedBy"]);
    formData["ModifiedBy"] = parseInt(this.props.userState.profileInfo.userId);
    formData["CreatedDate"] = moment(formData["CreatedDate"]).format(_format);

    if (!model.Remarks) {
      delete formData["Remarks"]
    }

    formData.IsBacklog = 0;
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
    }
    if (formData.hasOwnProperty("UploadTrainingAttendancePhoto3")) {
      formData["UploadTrainingAttendancePhoto3"] = this.state.uploadedPhotoActivity;
    }
    if (formData.hasOwnProperty("UploadTrainingAttendanceDocument1")) {
      formData["UploadTrainingAttendanceDocument1"] = this.state.uploadTrainingDoucment;
    }


    const validateMeetingStatusForm = [
      "Activity Cancelled",
      "Activity Completed",
    ];
    if (navigator.onLine) {
      this.props.activateLoader();
      if (validateMeetingStatusForm.includes(formData["ActivityStatus"])) {
        console.log('update activity formData', formData);
        const result = await this.props.updateDynamicData({
          data: formData,
          formName: CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: this.formBuilder?.actionType,
          userID: this.props.userState?.profileInfo?.userId,
        });
        if (result.status === 200) {
          this.props.activateSuccessMessageBar(
            "Activity is updated successfully."
          );

          // this method is called to fetch the latest completed activity to create the lead
          this.props.getAutoCompleteActivities({
            userId: this.props.userState?.profileInfo?.userId,
          });
          this.props.deActivateLoader();
          if (model.leadgenerated) {
            this.createLeadFPMUser(leadModel, formData.TrnActivityID);
          }
          setTimeout(() => {
            const str = `/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`
            this.props.history.push(str)
          }, 1000)
        } else {
          this.props.activateErrorMessageBar("Activity is not updated.");
          this.props.deActivateLoader();
        }
      } else {
        const isValidActivity = await this.isValidBusinessActivity(formData);
        if (isValidActivity) {
          const result = await this.props.updateDynamicData({
            data: formData,
            formName: CONFIGS.UPDATE_ACTIVITY_FORM,
            formBuilder: this.formBuilder?.actionType,
            userID: this.props.userState?.profileInfo?.userId,
          });
          if (result.status === 200) {
            this.props.activateSuccessMessageBar(
              "Activity is updated successfully."
            );
            this.props.deActivateLoader();
            // this method is called to fetch the latest completed activity to create the lead
            this.props.getAutoCompleteActivities({
              userId: this.props.userState?.profileInfo?.userId,
            });

            if (model.leadgenerated) {
              this.createLeadFPMUser(leadModel, formData.TrnActivityID);
            }
            setTimeout(() => {
              const str = `/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`
              this.props.history.push(str)
            }, 1000)
          } else {
            this.props.activateErrorMessageBar("Activity is not updated.");
            this.props.deActivateLoader();
          }
        } else {
          this.props.activateErrorMessageBar(this.state.responseMessage);
          this.props.deActivateLoader();
        }
      }
    } else {
      this.props.deActivateLoader();
      // update the activity in offline
      const activityObject = {
        ...this.props.activityData.getSelectActivityData,
      };
      activityObject.IsBacklog = false;
      activityObject.IsCheckin = false;
      activityObject.IsClaimRequired = formData.IsClaimRequired;
      activityObject.MeetingEndDateTime = formData.MeetingEndDateTime;
      activityObject.MeetingStartDateTime = formData.MeetingStartDateTime;
      activityObject.ModifiedDate = formData.ModifiedDate;
      activityObject.Reason = formData.Reason;
      activityObject.TransportModeID = formData.TransportModeID;
      activityObject.TravelExpence = formData.TravelExpence;
      activityObject.isActiveCheckin = false;
      const key = activityObject.TrnActivityID
        ? activityObject.TrnActivityID
        : activityObject.LmsActivityGuid;
      updateActivityOffline(key.toString(), activityObject)
        .then((res) => {
          if (res) {
            this.props.history.push(`/app/activity/detail/${key}`);
          }
        })
        .catch((err) => this.props.history.push(`/app/activity/all`));
    }
    return true;
  };

  onChangeHandler = async (event, key, model) => {

    if (key === 'UploadBill' || key === "UploadTrainingAttendanceDocument1" || key === "UploadTrainingAttendancePhoto1" || key === "UploadTrainingAttendancePhoto3") {
      this.uploadFile(event, key);
    }
    if (model?.TransportModeID === "2" || model.IsClaimRequired == false) {
      delete model.errors?.TravelExpence;
    }
    if (key === 'MeetingStartDateTime') {
      const newDate2 = moment(event.target.value).add(30, 'm').format(DateFormatString)

      // const copyformData=this.state.formData

      var StartDate = moment(event.target.value).format(_formatDate);
      const endMeetDate = moment(StartDate).add(3, 'M').toString();

      this.state.formData.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(endMeetDate).endOf('day').format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
        return option
      })
      this.setState({
        current: {
          ...this.state.current, MeetingStartDateTime: event.target.value,
          // MeetingEndDateTime: newDate2
        }
      })
      if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
        this.setState({
          current: { ...this.state.current, MeetingStartDateTime: event.target.value }, extErrors: { ...this.state.extErrors, MeetingStartDateTime: "please select future value" }
        })
      } else {
        if (model.leadgenerated) {
          this.setState({
            ...this.state, current: {
              ...this.state.current, MeetingStartDateTime: event.target.value,
              //  MeetingEndDateTime: newDate2
            }, extErrors: {}
          })
        } else {
          this.setState({
            ...this.state, current: {
              ...this.state.current, MeetingStartDateTime: event.target.value,
              MeetingEndDateTime: newDate2
            }, extErrors: {}
          })
        }
      }
    }
    if (key === 'MeetingEndDateTime') {
      var newDate = moment(event.target.value).format(DateFormatString)

      this.setState({ current: { ...this.state.current, MeetingEndDateTime: newDate } })
      if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
        this.setState({
          current: {
            ...this.state.current,
            MeetingStartDateTime: event.target.value,
          },
          extErrors: {
            ...this.state.extErrors,
            MeetingStartDateTime: "please select future value",
          },
        });
      } else {
        this.setState({
          ...this.state,
          current: {
            ...this.state.current,
            MeetingStartDateTime: event.target.value,
            MeetingEndDateTime: newDate,
          },
          extErrors: {},
        });
      }
      this.setState({

        current: { ...this.state.current, MeetingStartDateTime: StartDate, MeetingEndDateTime: newDate },

      });
    }
    if (key === "MeetingEndDateTime") {
      const newDate1 = moment(event.target.value).format(DateFormatString);
      if (
        !checkDateIsValid(
          event.target.value,
          this.state.current.MeetingStartDateTime
        )
      ) {
        this.setState({
          current: {
            ...this.state.current,
            MeetingEndDateTime: event.target.value,
          },
          extErrors: {
            ...this.state.extErrors,
            MeetingEndDateTime:
              "Date should not less than meeting starting date",
          },
        });
      } else {
        this.setState({
          current: {
            ...this.state.current,
            MeetingEndDateTime: event.target.value,
          },
          extErrors: {},
        });
      }
      this.setState({

        current: { ...this.state.current, MeetingEndDateTime: newDate1 }

      });
    }
    if (key === "NoOfLeads") {
      if (event.target.value <= 0) {
        this.setState({
          extErrors: {
            ...this.state.extErrors,
            NoOfLeads: "No Of Lead Should not be 0.",
          },
        });
      } else {
        this.setState({
          extErrors: {},
        });
      }
    }

    const flag = this.props.activityData.activityComplete
      ? ACTIVITY_COMPLETED
      : this.props.activityData.activityReshedule
        ? ACTIVITY_RESCHEDULED
        : this.props.activityData.fpmActivityComplete
          ? JOINTCALL_COMPLETED :
          this.props.activityData.fpmActivityReschedule ?
            JOINTCALL_RESCHEDULED
            : ACTIVITY_CANCELLED;

    let formData = handleDropdownSelection(
      event,
      key,
      this.state.formData,
      this.props.masterDataState,
      flag,
      this.props.userState.profileInfo.roleId
    );
    this.setState({ formData: formData });
    if (key === "IsClaimRequired") {
      model.TransportModeID = ''
      model.TravelExpence = ''
      model.errors = {}
      if (event === true) {
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: true,
          },
        });
        formData.find((data2) => data2.key === "TransportModeID").options =
          this.props.masterDataState["ModeofTransport"];
        this.setState({ formData: formData });
        const checkIn = await this.props.validateIsCheckin({
          checkinFor: "activity",
          Id: this.props.activityData.getSelectActivityData.TrnActivityID,
        });
        const arrayToRemove = ActivityCancelledFormPart1;
        formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

        const arrayToRemove1 = ActivityCancelledFormPart2;
        formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

        const arrayToRemove2 = ActivityCancelledFormPart3;
        formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
        const { data } = checkIn;

        if (data) {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: data,
            },
          });
          if (flag === JOINTCALL_COMPLETED) {
            // formData.splice(4, 0, ...ActivityCancelledFormPart1);
            formData = formData.concat(ActivityCancelledFormPart1)
          } else {
            formData = formData.concat(ActivityCancelledFormPart1);
          }
          formData.find((data1) => data1.key === "TransportModeID").options =
            this.props.masterDataState["ModeofTransport"];
          this.setState({ formData: formData });
        } else {
          this.setState({
            current: {
              ...this.state.current,
              IsClaimRequired: false,
            },
          });
          const arrayToRemove3 = ActivityCancelledFormPart1;
          formData = removeSubArrayFromArray(arrayToRemove3, "key", formData);

          const arrayToRemove4 = ActivityCancelledFormPart2;
          formData = removeSubArrayFromArray(arrayToRemove4, "key", formData);

          const arrayToRemove5 = ActivityCancelledFormPart3;
          formData = removeSubArrayFromArray(arrayToRemove5, "key", formData);
          delete model.errors['TravelExpense'];
          delete model.errors['TravelExpence'];
          delete model.errors['TransportModeID'];
          this.setState({ formData: formData });
          this.props.activateErrorMessageBar("Please Check-In to claim the expense");
        }
      } else {
        this.setState({
          current: {
            ...this.state.current,
            IsClaimRequired: false,
          },
        });
        const arrayToRemove = ActivityCancelledFormPart1;
        formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

        const arrayToRemove1 = ActivityCancelledFormPart2;
        formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

        const arrayToRemove2 = ActivityCancelledFormPart3;
        formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
        this.setState({ formData: formData });
        delete model.errors['TravelExpense'];
        delete model.errors['TravelExpence'];
        delete model.errors['TransportModeID'];

      }
    }
    if (flag === JOINTCALL_COMPLETED) {
      if (key === "SPTypeID") {
        formData.find((data) => data.key === "BranchID").hide = false;
        this.setState({
          current: {
            ...this.state.current,
            BranchID: "",
            LGID: "",
            SPID: "",
            SRMID: "",
          },
        });
      }
      if (key === "BranchID") {
        const { Branches } = this.props.masterDataState;
        const selectBrannchCode = Branches.find(
          (b) => b.value === event.target.value
        )?.key;
        isBranchValid(this.state.selectActivityID, selectBrannchCode);

        if (this.roleId === "FPM") {
          this.bindSRMForSelectedBranch(selectBrannchCode);
        }
        formData.find((data) => data.key === "SRMID").hide = false;
        this.setState({
          current: {
            ...this.state.current,
            BranchID: event.target.value,
          },
        });
      }
      if (key === "SRMID") {
        this.setState({
          current: {
            ...this.state.current,
            SRMID: event.target.value,
          },
        });
      }
      if (key === "MobileNo") {
        if (event.target.value.length === 10) {
          const mobileNo = event.target.value;
          if (navigator.onLine) {
            const mobileStatus = await this.props.checkMobileNumberReducer({
              mobileNumber: event.target.value,
              userId: this.props.userState?.profileInfo?.userId,
            });
            if (!mobileStatus) {
              const extError = { MobileNo: "Mobile number already exits." };
              this.setState({ extErrors: extError });
            } else {
              delete this.state.extErrors["MobileNo"]
              // this.setState({ extErrors: {} });
            }
          } else {
            isLeadValid(mobileNo).then((res) => {
              if (!res) {
                const extError = { MobileNo: "Mobile number already exits." };
                this.setState({ extErrors: extError });
              } else {
                delete this.state.extErrors["MobileNo"]
                // this.setState({ extErrors: {} });
              }
            });
          }
        }
      }
      if (key == "leadgenerated") {
        this.setState({
          current: {
            ...this.state.current,
            leadgenerated: event,
          },
        });
      }
      if (key === "ISleadHasMeeting") {
        const MeetingStartDateTimeOption = formData.find(
          (data) => data?.key === "MeetingStartDateTime"
        );
        const MeetingEndDateTimeOption = formData.find(
          (data) => data?.key === "MeetingEndDateTime"
        );
        if (event === true) {
          MeetingStartDateTimeOption.hide = false;
          MeetingEndDateTimeOption.hide = false;
          MeetingStartDateTimeOption.validators = [
            {
              name: "required",
              value: true,
              validationMessage: "",
            },
          ];
          MeetingEndDateTimeOption.validators = [
            {
              name: "required",
              value: true,
              validationMessage: "",
            },
          ];
          this.setState({
            ...this.state,
            defaultFormVal: {
              ...this.state.defaultFormVal,
              ISleadHasMeeting: true,
              MeetingStartDateTime: moment(new Date()).format(DateFormatString),
              MeetingEndDateTime: moment(new Date())
                .add(30, "m")
                .format(DateFormatString),
            },
          });
        } else {
          this.setState({
            ...this.state,
            startMeetingDate: null,
            endMeetingDate: null,
            defaultFormVal: {
              ...this.state.defaultFormVal,
              MeetingEndDateTime: "",
              MeetingStartDateTime: "",
              ISleadHasMeeting: false,
            },
          });
          this.setState({ extErrors: {} });
          MeetingStartDateTimeOption.hide = true;
          MeetingEndDateTimeOption.hide = true;
          MeetingStartDateTimeOption.validators = "";
          MeetingEndDateTimeOption.validators = "";
        }
      }
    }

    if (key === "TransportModeID") {
      if (event.target.value === "1") {

        const data = formData.filter((val) => {
          return val.key !== "TravelExpence";
        });
        const data1 = data.filter((val) => {
          return val.key !== "UploadBill";
        });
        const privateData = data1.concat(ActivityCancelledFormPart2);
        const privatefields = privateData.filter((val) => {
          return val.key !== "VehicleTypeID";
        });
        this.setState({ formData: privatefields });
      } else if (event.target.value === "2") {
        const data = formData.filter((val) => {
          return val.key !== "TravelExpence";
        });
        const data1 = data.filter((val) => {
          return val.key !== "UploadBill";
        });
        this.setState({ formData: data1 });

        // if(this.props.userState.profileInfo.roleId !== "FPM"){
        //   const selfdata = data1.concat(ActivityCancelledFormPart3);
        //   selfdata.map((val) => {
        //     if (val.key === "VehicleTypeID") {
        //       val.options = this.props.masterDataState.VehicleType;
        //     }
        //   });
        //   this.setState({ formData: selfdata });
        // }
        delete model.errors['TravelExpence'];
        delete model.errors['TravelExpense'];
      }
    }
  };

  onCancelHandler = () => {
    this.props.history.push(
      `/app/activity/detail/${this.props.activityData.getSelectActivityData.TrnActivityID}`
    );
  };

  uploadFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await this.props.uploadFileToBlob({ file: files[0] });
      const { downloadUrl } = fileRes.data
      if (fileRes.status === 200) {
        if (key === "UploadBill") {
          this.setState({ uploadedFile: downloadUrl });
        } else if (key === "UploadTrainingAttendanceDocument1") {
          this.setState({ uploadTrainingDoucment: files[0].name });
        } else if (key === "UploadTrainingAttendancePhoto3") {
          this.setState({ uploadedPhotoActivity: files[0].name });
        }
      }
    } else {
      this.props.activateErrorMessageBar(
        "Cannot upload files in offline mode!"
      );
    }
  };
  bindSRMForSelectedBranch = (branchCode) => {
    getSRMList(branchCode).then((res) => {
      const { SRMlist } = res;
      const formData = [...this.state.formData];
      if (SRMlist) {
        const Srmlist = getDropdownData(
          SRMlist,
          "SRMCode",
          "FullName",
          "SRMID"
        );
        formData.find((data) => data.key === "SRMID").options = Srmlist;
      }
      this.setState({ formData: formData });
    });
  };
  initMasterData = () => { };
  onAutocompleteSelectHandler = (key, value, item) => {
    this.setState({ selectActivityID: item.id });
  };

  statusFunction = async () => {
    let statusId = [];
    if (this.channelid == 19) {
      const ActivityStatus = this.props.activityData.activityComplete ? ACTIVITY_COMPLETED: this.props.activityData.activityReshedule ? ACTIVITY_RESCHEDULED : ACTIVITY_CANCELLED;
      // statusId = this.props.masterDataState.ActivityStatus.data.filter(
      //   (f) => f.ActivityStatus === ActivityStatus
      // );
      
      const statusId = getOptionByCode(
        ActivityStatus,
        this.props.masterDataState.ActivityStatus
      );
      this.setState({ activityStatus:{ "ActivityStatusId" : statusId.value,"ActivityStatus":statusId.label}});
      
    } else {
      const result = await this.props.getActivityStatusMasterData();
      if (result.status === 200) {

        if (
          this.props.activityData.activityComplete ||
          this.props.activityData.fpmActivityComplete
        ) {
          statusId = result.data.filter(
            (f) => f.ActivityStatus === "Activity Completed"
          );
        } else if (this.props.activityData.activityReshedule || this.props.activityData.fpmActivityReschedule) {
          statusId = result.data.filter(
            (f) => f.ActivityStatus === "Activity Rescheduled"
          );
        } else {
          statusId = result.data.filter(
            (f) => f.ActivityStatus === "Activity Cancelled"
          );
        }
        this.setState({ activityStatus: statusId[0] });
      }
    }
    
  };
  loadBreadCrumbs = () => {
    const { t } = this.props;
    const createActivityTitle = t(this.formBuilder.getFormTitle());
    const breadcrumbs = [
      { name: "Activity", url: "/app/activity/all" },
      { name: createActivityTitle, url: "/app/activity/update" },
    ];
    this.props.setBreadcrumbs(breadcrumbs);
  };

  getHistory = async (activityId) => {
    const resultHistory = await this.props.getActivityDetailsHistory({
      activityId: activityId,
    });
    if (resultHistory.status === 200) {
      let checkCompleteCancelStatus = resultHistory?.data.filter((el) => el.ActivityStatusID == 2 || el.ActivityStatusID == 4);
      if (checkCompleteCancelStatus.length > 0) {
        this.props.history.push(`/app/activity/detail/${activityId}`);
      }
    }
  };
  getDropdownDataWithoutCombination = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr?.map((option) => {
        return { key: option[key], label: `${option[label]}`, value: option[value] }
      })
    }
    arr.unshift({ "key": "select", "label": "select", "value": "" })
    return arr;
  }

  //this.props.activityData.getSelectActivityData.TrnActivityID
  componentDidMount() {
    let activityId =
      this.props.activityData.getSelectActivityData.TrnActivityID;

    this.getHistory(activityId);
    this.loadBreadCrumbs();
    this.statusFunction();
    this.setState({
      autoCompleteItems: this.props.leadFormData.Activities,
    });
    const formData = this.props.masterDataState
      ? initFormData(
        this.state.formData,
        this.props.masterDataState,
        this.props.activityData.activityComplete
          ? ACTIVITY_COMPLETED
          : this.props.activityData.activityReshedule
            ? ACTIVITY_RESCHEDULED
            : this.props.activityData.fpmActivityCancel ?
              JOINTCALL_CANCELLED :
              this.props.activityData.fpmActivityReschedule ?
                JOINTCALL_RESCHEDULED :
                this.props.activityData.fpmActivityComplete
                  ? JOINTCALL_COMPLETED
                  : ACTIVITY_CANCELLED,
        this.roleId
      )
      : this.state.formData;
    this.setState({ formData: formData });

    const fetchData = async () => {
      this.props.activateLoader();
      const getCurrentActivity = await this.props.getActivityById(
        this.props.activityData.getSelectActivityData.TrnActivityID
      );
      if (getCurrentActivity.status === 200) {
        this.setState({ selectedActivityOldData: getCurrentActivity.data });
        this.props.deActivateLoader();
      } else {
        this.props.deActivateLoader();
      }
    };
    if (navigator.onLine) {
      fetchData();
    }
  }
  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    if (key === 'UploadBill') {
      this.setState({
        uploadedFile: ""
      });
    } else if (key === "UploadTrainingAttendancePhoto3") {
      this.setState({
        uploadedPhotoActivity: ""
      })
    } else if (key === "UploadTrainingAttendanceDocument1") {
      this.setState({
        uploadTrainingDoucment: ""
      })
    }
  };

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => this.removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  renderRadioIcons = (radioOption) => {
    if (radioOption.key === PUBLIC_TRANSPORT) {
      return <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      return <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      return <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      return <DirectionsCarIcon></DirectionsCarIcon>;
    } else {
      return "";
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="mainContainer createActivity updateActivity">
        {/* <MessageBar ></MessageBar>
      <Loader></Loader> */}
        {/* <Header history={this.props.history} /> */}
        <Breadcrumb></Breadcrumb>
        {/* <h3 className="pageHead">{t(this.formBuilder.getFormTitle())}</h3> */}
        <div className="formBox">
          <DynamicForm
            key={this.state.current.id}
            onRef={(ref) => (this.dyFormRef = ref)}
            //className="dynamicForm"
            title={this.state.selectedActivityOldData ? this.state.selectedActivityOldData?.ActivityName : ''}
            buttonSubmit={t("Update")}
            defaultValues={this.state.current}
            model={this.state.formData}
            onSubmit={(model) => {
              this.onSubmitHandler(model);
            }}
            disableSubmitOnInValid={false}
            design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
            materialOutline={false}
            onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
            buttonNormalClick={(id, key, obj) => { }}
            showAstersikOnRequiredFields={true}
            className="activityTypeDiv"
            checkInConfig={checkInConfig}
            extaraActionFormButton={
              <>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={this.onCancelHandler}
                >
                  {t("Cancel")}
                </button>
              </>
            }
            onAutocompleteSelect={this.onAutocompleteSelectHandler}
            autoCompleteItems={this.state.autoCompleteItems}
            customValidators={customValidators}
            fileUploaderFields={["UploadBill"]}
            renderUploadedFilesItem={this.renderUploadedFilesItem}
            renderRadioIcons={(option) => this.renderRadioIcons(option)}
            extErrors={this.state.extErrors}
          />
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activityData: state.updateActivityReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer,
  leadFormData: state.leadFormReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getActivityStatusMasterData: (payload) =>
    dispatch(getActivityStatusMasterData(payload)),
  getFpmActivityCancelReason: () => dispatch(getJointCallActivityCancelReason()),
  getFpmActivityRescheduleReason: () => dispatch(getJointCallActivityRescheduleReason()),
  setActivityData: (payload) => dispatch(setActivityData(payload)),
  getActivityById: (payload) => dispatch(getActivityById(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  getAutoCompleteActivities: (payload) =>
    dispatch(getAutoCompleteActivities(payload)),
  getActivityDetailsHistory: (payload) =>
    dispatch(getActivityDetailsHistory(payload)),
  checkMobileNumberReducer: (payload) =>
    dispatch(checkMobileNumberReducer(payload)),
  createLeadDeDupeValidation: (payload) =>
    dispatch(createLeadDeDupeValidation(payload)),
  validateMeetingDateForCreateLead: (payload) =>
    dispatch(validateMeetingDateForCreateLead(payload)),
  saveLeadForm: (payload) => dispatch(saveLeadForm(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(UpdateActivity)
);
