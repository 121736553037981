import React from "react";
import Breadcrumb from "./../../../../components/breadcrumb/breadcrumb";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  BrocaSmaActivityForm,
  handleSmaActivitySelection,
  initFormData,
  getAutoCompleteItems,
} from "./createSmaActivityFormBroca";
import {
  addDynamicData,
  updateDynamicData,
  getMasterData,
  uploadFileToBlob,
  activateSuccessMessageBar,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
  getUserBranches,
  setBreadcrumbs,
  getBDMBranchName,
} from "../../../../redux/actions/common";
import { validateActivity } from "../../../../redux/actions/createActivityActions";
import {
  CONFIGS,
  FOUR_WHEELER,
  PUBLIC_TRANSPORT,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  STATUS_ACTIVITY_DRAFTED,
  STATUS_ACTIVITY_PLANNED,
} from "../../../../utils/config";
import "./../createActivity.scss";
import { isPositiveNumber } from "../../../../utils/validators";
import moment from "moment";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getOptionByCode, getSubTitles, getSubTitlesBroca } from "./../helper";
import SaveAsDraftDrawer from "./../saveAsDraftDrawer";
import { saveActivityOffline } from "../../../../offlineManager/activityOfflineManager";
import { v4 as uuidv4 } from "uuid";
import { minusMinuteFromDate } from "../../../../utils/utils";

import { finishDayActivityCreate } from "../../../../redux/actions";
import { getActivityDetails } from "../../../../redux/actions/activityDetailsAction";
// import { BrocaSmaActivityForm, handleSmaActivitySelection } from "../salesManagement/createSalesManagementActivityForm";

const customValidators = [
  {
    key: "ExpectedLead",
    validator: isPositiveNumber, // function to be called
    message: "Expected leads should be greater than 0", // message to show
  },
  {
    key: "ExpectedConversion",
    validator: isPositiveNumber, // function to be called
    message: "Expected conversion should be greater than 0", // message to show
  },
  {
    key: "TicketSize",
    validator: isPositiveNumber, // function to be called
    message: "Ticket size should be greater than 0", // message to show
  },
  {
    key: "ExpenseAmount",
    validator: isPositiveNumber, // function to be called
    message: "Expense amount should be greater than 0", // message to show
  },
  {
    key: "BillNumber",
    validator: isPositiveNumber, // function to be called
    message: "Bill number should be greater than 0", // message to show
  },
  {
    key: "TravelExpene",
    validator: isPositiveNumber, // function to be called
    message: "Travel expense should be greater than 0", // message to show
  },
];

const createActivityTitle = "Create Activity";
const createSmaActivityTitle = "Create Sales Management Activity";
const activityListingSma = "/app/activity/smaActivities";
const _formatDate = "YYYY-MM-DDTHH:mm";
const selectOption = [{ key: "Select", label: "Select", value: "" }];
const _format = "YYYY-MM-DD HH:mm:ss";
class ResumeDraftedActivityBroca extends React.Component {
  constructor(props) {
    super(props);
    this.activityId = this.props.match.params.id;
    this.dyFormRef = React.createRef();
    this.state = {
      isSMARoute: props.location.pathname.includes("smaActivities"),
      current: {
        id: 1,
        MeetingStartDateTime: moment(new Date()).format(_formatDate),
        MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
        ABHName: "",
        ActivityDateTime: moment(new Date()).format(_formatDate),
        ABHDate: moment(new Date()).format("yyyy-MM-dd"),
      },
      formData: BrocaSmaActivityForm,
      autoCompleteItems: {
        BranchId: [],
      },
      uploadedFile: "",
      originalFileName: "",
      extErrors: {},
      ExpectedConversion: "",
      ExpectedLead: "",
      isActiveMobileDraft: false,
      optionList: {
        branchType: [],
        activityNameType: [],
        activityStatusType: [],
      },
      isFormSUbmitDisabled: false,
      responseMessage: "",
      bdmName: [],
      BdmName: "",
    };
  }

  loadBreadCrumbs = () => {
    const breadcrumbs = [
      {
        name:"Sales Management" ,
        url: activityListingSma ,
      },
      {
        name:  createSmaActivityTitle,
        url:  "/app/salesmanagement/create",
      },
    ];
    this.props.setBreadcrumbs(breadcrumbs);
  };

  isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await this.props.validateActivity({
        user_id: this.props.userState.profileInfo.userId,
        formData: formData,
      });
      if (
        checkActivity.status === 200 &&
        checkActivity.data.Responsestatus === true
      ) {
        return true;
      } else {
        this.setState({
          responseMessage: checkActivity.data.Responsemessage,
        });
      }
      return false;
    }
    return true;
  };

  getActivityName = (inputId) => {
    const collection = this.state.formData;
    if (inputId && collection) {
      const name = collection[0].options.filter((el) => el.value === inputId.toString());
      if (name && name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };
  reset = (model) => {
    for (const [key] of Object.entries(model)) {
      model[key] = "";
    }
    this.setState({ uploadedFile: "", originalFileName: "", extErrors: {} });
    this.dyFormRef.reset(model);
  };

  onSubmitHandler = async (model) => {
    if (
      !model.isValidForm ||
      Object.keys(this.state.extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      return false;
    }
    const formData = { ...model };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    formData["IsBacklog"] = false;
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_PLANNED,
      this.props.masterDataState.ActivityStatus
    );

    const BDMName = this.state.bdmName.filter(
      (val) => val.UserID == formData["BDMID"]
    );
    const isBranchName = this.props.masterDataState.Branches.filter(ele => ele.value === model.BranchId);
    const activityName = this.getActivityName(formData.ActivityID);
       // if (formData.ActivityID == 55 ){
    //   formData["ActivityName"] = getSubTitlesBroca(activityName, formData?.BranchId, isBranchName, formData.MeetingStartDateTime);
    // } else {
    //   formData["ActivityName"] = getSubTitles(activityName, formData?.BranchId, undefined , formData.MeetingStartDateTime);
    // }
    formData["ActivityName"] = isBranchName.length > 0 ? getSubTitlesBroca(activityName, formData?.BranchId, isBranchName, formData.MeetingStartDateTime) : getSubTitles(activityName, formData?.BranchId, undefined , formData.MeetingStartDateTime)
    formData["ActivityStatusId"] = statusOption.value;
    formData["ActivityStatus"] = statusOption.label;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = formData["BDMID"]
      ? formData["BDMID"]
      : this.props.userState.profileInfo.userId;
    // formData["AssignedTo"] = formData['BDMCode'] ? formData['BDMCode'] : this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format("YYYY-MM-DD HH:mm:ss");
    formData["ModifiedDate"] = moment().format("YYYY-MM-DD HH:mm:ss");
    formData["LmsleadGuid"] = uuidv4();
    formData["BankRegionName"] = formData?.bankRegion
      ? formData.bankRegion
      : null;
    formData["LmsleadlastSyncTime"] = new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(_format);
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = this.activityId;
    formData["AssignedToName"] = BDMName[0]?.UserName
      ? BDMName[0].UserName
      : `${this.props.userState.profileInfo.firstName}${" "}${
          this.props.userState.profileInfo.lastName
        }`;

    formData["ModifiedBy"] = this.props.userState.profileInfo.userId;
    formData["BDMID"] = model.BDMID ? model.BDMID : null;
    formData["BDMName"] = formData["BDMID"] ? formData["BDMID"] : null;
    formData["ActivityDateTime"] = model.ActivityDateTime
      ? model.ActivityDateTime.replace("T", " ")
      : null;
    formData["ABHID"] = this.props.userState.profileInfo.userId;
    formData["IsLmsSync"] = navigator.onLine;
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;
    formData["TrainingTypeID"] = formData.typeOfTraning
      ? formData.typeOfTraning
      : null;
    // validate the activity from server side
    if (navigator.onLine) {
      this.props.activateLoader();
      const isValidActivity = await this.isValidBusinessActivity(formData);
      if (isValidActivity) {
        const result = await this.props.updateDynamicData({
          data: [formData],
          formName: CONFIGS.UPDATE_ACTIVITY_FORM,
          formBuilder: "",
          userID: this.props.userState.profileInfo.userId,
        });
        if (result.status === 200 && result.data.Responsestatus === true) {
          this.props.activateSuccessMessageBar(
            "Activity has been created successfully."
          );
          this.props.deActivateLoader();
          const saveFormData = { ...formData };
          //  SAVE activity in indexDb online
          saveFormData["TrnActivityID"] = result.data.Transactionid;
          saveActivityOffline(saveFormData).then((res) => {
            if (res) {
              this.reset(formData);
                this.props.history.push(
                  `/app/salesmanagement/detail/${result.data.Transactionid}`
                );
            }
          });
        } else {
          this.props.deActivateLoader();
          this.props.activateSuccessMessageBar(
            "Something went wrong! please try again later."
          );
        }
      } else {
        this.props.activateErrorMessageBar(this.state.responseMessage);
        this.props.deActivateLoader();
      }
    } else {
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          const {
            data: { key },
          } = res;
          this.props.history.push(`/app/salesmanagement/detail/${key}`);
        }
      });
    }
  };

  handleSaveAsDraft = async (e) => {
    e.preventDefault();
    this.props.activateLoader();
    const formData = { ...this.dyFormRef.state };
    const BDMName = this.state.bdmName.filter(
      (val) => val.UserID === formData["BDMID"]
    );
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    formData["IsBacklog"] = false;
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }
    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_DRAFTED,
      this.props.masterDataState.ActivityStatus
    );
    formData["ActivityDateTime"] = formData["ActivityDateTime"].replace(
      "T",
      " "
    );
    formData["BDMName"] = formData["BDMID"] ? formData["BDMID"] : null;
    formData["ActivityID"] = formData["ActivityID"]
      ? formData["ActivityID"]
      : null;
    formData["ABHID"] = this.props.userState.profileInfo.userId;
    formData["BDMID"] = formData["BDMID"] ? formData["BDMID"] : null;
    formData["ModifiedBy"] = this.props.userState.profileInfo.userId;
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = formData["BDMID"]
      ? formData["BDMID"]
      : this.props.userState.profileInfo.userId;

    formData["AssignedToName"] = BDMName[0]?.UserName
      ? BDMName[0].UserName
      : `${this.props.userState.profileInfo.firstName}${" "}${
          this.props.userState.profileInfo.lastName
        }`;
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(
      "YYYY-MM-DD HH:mm:ss"
    );
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["ActivityStatus"] = statusOption.label;

    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    formData["ModifiedDate"] = new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    const result = await this.props.updateDynamicData({
      data: [formData],
      formName: CONFIGS.UPDATE_ACTIVITY_FORM,
      formBuilder: "",
      userID: this.props.userState.profileInfo.userId,
    });
    if (result.status === 200 && result.data.Responsestatus === true) {
      this.props.activateSuccessMessageBar(
        "Activity has been drafted successfully."
      );
      this.reset(formData);
      this.props.deActivateLoader();
      this.props.history.push(
       activityListingSma
      );
    } else {
      this.props.deActivateLoader();
      this.props.activateSuccessMessageBar(
        "Something went wrong! please try again later."
      );
    }
  };

  getActivityDraftData = async () => {
    this.props.activateLoader();
    const activityId = this.props.match.params.id;
    const result = await this.props.getActivityDetails({
      activityId: activityId,
    });
    if (result.status === 200) {
      const data = result.data;
      let defaultState = data;
      let selectedFormData = this.state.formData;
      if (data?.ActivityID) {
        const formData = handleSmaActivitySelection(
          { target: { value: data.ActivityID.toString() } },
          "ActivityID",
          selectedFormData,
          this.props.masterDataState,
          this.state.BDMCode,
          this.props.userState.profileInfo.roleId
        );
        selectedFormData = [...formData];
        defaultState.ActivityID = data.ActivityID.toString();
      }

      const { ActivityID, BranchCategory, MeetingEndDateTime, ActivityPlannedId, BranchId, PartnerName, PartnerMobileNo,
        Location,MeetingStartDateTime, Name, EmailID, MobileNo, BDMID, BranchName,
        BDMName,} = data;
      defaultState = {
        ActivityID: ActivityID ? ActivityID?.toString() : null,
        BranchCategory: BranchCategory ? BranchCategory?.toString() : null,
        MeetingEndDateTime: MeetingEndDateTime
          ? MeetingEndDateTime?.toString()
          : null,
        MeetingStartDateTime: MeetingStartDateTime
          ? MeetingStartDateTime?.toString()
          : null,
        ActivityPlannedId: ActivityPlannedId
          ? ActivityPlannedId?.toString()
          : null,
        BranchId: BranchId ? BranchId.toString() : null,
        Location: Location ? Location : null,
        Name: Name ? Name : null,
        EmailID: EmailID ? EmailID : null,
        MobileNo: MobileNo ? MobileNo : null,
        PartnerName: PartnerName ? PartnerName : null,
        PartnerMobileNo: PartnerMobileNo ? PartnerMobileNo : null,
        BDMID: BDMID ? BDMID.toString() : null,
        BDMName: BDMName ? BDMName : null,
      };
      if(BranchId){
        defaultState.BranchName= BranchName ? BranchName: null
        defaultState.BranchIdlabel= BranchName? BranchName : null
      }
      this.setState({ formData: selectedFormData, current: defaultState });
      this.props.deActivateLoader();
    }
  };

  componentDidMount = () => {
    const BDMList = this.props.leadFormReducer.BDMList.UsersDetails;
    this.loadBreadCrumbs();
    const formData = this.props.masterDataState
      ? initFormData(
          this.state.formData,
          this.props.masterDataState,
          this.props.userState.profileInfo.roleId,
          this.state.isSMARoute
        )
      : this.state.formData;
    const autoCompleteItems = { ...this.state.autoCompleteItems };
    autoCompleteItems.BranchId = this.props.masterDataState
      ? getAutoCompleteItems(
          this.props.masterDataState,
          CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME
        )
      : [];
    this.setState({
      formData: formData,
      autoCompleteItems: autoCompleteItems,
      BDMCode: this.getOptionDataBdmCode(BDMList),
      bdmName: BDMList,
      current: {
        ...this.state.current,
      },
    });
    this.getActivityDraftData();
  };

  getOptionData = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["UserID"],
        label: item["UserName"],
        value: item["UserID"],
      };
    });
    return [...selectOption, ...options];
  };

  getOptionDataBdmCode = (optionList) => {
    const options = optionList?.map((item) => {
      return {
        key: item["UserID"],
        label: item["UserID"],
        value: item["UserID"],
      };
    });
    return [...selectOption, ...options];
  };

  onChangeHandler = async (event, key, model) => {
    if (key === "BDMID") {
      const bdmCode = parseInt(event.target.value);
      const BDMList = this.props.leadFormReducer.BDMList.UsersDetails;
      const bdmName = BDMList?.filter((item) => item.UserID == bdmCode);
      this.state.current["BDMName"] = bdmName[0]?.UserName;
    }
    if (key === "MeetingStartDateTime") {
      const form = this.state.formData.map((ele) => {
        if (ele.key == "MeetingEndDateTime") {
          ele.min = moment(event.target.value)
            .add(1, "m")
            .format("YYYY-MM-DDTHH:mm");
          ele.max = moment(event.target.value)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm");
          return ele;
        } else {
          return ele;
        }
      });
      const newDate = moment(event.target.value)
        .add(30, "m")
        .format("YYYY-MM-DDTHH:mm");
      this.setState({
        current: {
          ...this.state.current,
          MeetingStartDateTime: event.target.value,
          MeetingEndDateTime: newDate,
        },
        formData: form,
      });
    } else if (key === "MeetingEndDateTime") {
      const newDate = moment(event.target.value).format("YYYY-MM-DDTHH:mm");
      this.setState({
        current: { ...this.state.current, MeetingEndDateTime: newDate },
      });
    } else if (key === "IsClaimRequired") {
      this.setState({
        current: { ...this.state.current, IsClaimRequired: event },
      });
    } else if (key === "ActivityID") {
      this.setState({
        current: {
          ...this.state.current,
          ActivityID: event.target.value,
          ActivityName: "",
        },
      });
    } else if(key==="BranchId"){
      const data = this.state.autoCompleteItems.BranchId.filter((val) => {
        if(val.id === event.target.value && val.id !== ""){
        return val.id === event.target.value
        }
      })
      this.setState({ current: { ...this.state.current, BranchId: event.target.value, BranchName: event.target.value, BranchIdlabel: data.length > 0 ? data[0]?.label : event.target.value } })
      // Alert -if this empty else if removed automcomplete will not work
      delete model.errors["BranchId"]
      delete model.errors["BranchIdlabel"]
  } else {
      this.setState({
        current: { ...this.state.current, [key]: event.target.value },
      });
    }

    if (key === "ActivityID") {
      const formData = handleSmaActivitySelection(
        event,
        key,
        this.state.formData,
        this.props.masterDataState,
        this.state.BDMCode,
        this.props.userState.profileInfo.roleId
      );
      this.setState({ formData: formData });
    }
  };

  onAutocompleteSelectHandler = (key, value, item) => {
    //this.setState({current:{...this.state.current,[key]:value},[key]:value})
  };

  onCancelHandler = () => {
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 768 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      this.setState({ isActiveMobileDraft: true });
    } else {
      this.props.history.push(
       activityListingSma
      );
    }
  };

  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({ uploadedFile: "" });
  };

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => this.removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  renderRadioIcons = (radioOption) => {
    let icon = "";
    if (radioOption.key === PUBLIC_TRANSPORT) {
      icon = <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      icon = <DirectionsCarIcon></DirectionsCarIcon>;
    }
    return icon;
  };

  toggleDrawer = (flag) => {
    this.setState({ isActiveMobileDraft: flag });
  };

  onDontSaveDraftHandler = () => {
    this.props.history.push(activityListingSma);
  };

  onSaveDraftHandler = () => {
    this.handleSaveAsDraft(new Event("test"));
  };

  render() {
    const { t } = this.props;
    return (
      <div className="mainContainer createActivity">
        <Breadcrumb></Breadcrumb>
        <span className="formInfo">
          {t("Please fill in the activity information")}
        </span>
        <div className="formBox">
          <DynamicForm
            key={this.state.current.id}
            onRef={(ref) => (this.dyFormRef = ref)}
            title={t(createActivityTitle)}
            buttonSubmit={t("Submit")}
            defaultValues={this.state.current}
            model={this.state.formData}
            onSubmit={(model) => {
              this.onSubmitHandler(model);
            }}
            disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key, model) =>
              this.onChangeHandler(event, key, model)
            }
            buttonNormalClick={(id, key, obj) => {}}
            onAutocompleteSelect={this.onAutocompleteSelectHandler}
            autoCompleteItems={this.state.autoCompleteItems}
            showAstersikOnRequiredFields={true}
            extErrors={this.state.extErrors}
            className="activityTypeDiv"
            extaraActionFormButton={
              <>
                <a
                  className="savedraftLink"
                  onClick={(e) => this.handleSaveAsDraft(e)}
                  href="#"
                >
                  SAVE AS DRAFT
                </a>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={this.onCancelHandler}
                >
                  {t("Cancel")}
                </button>
              </>
            }
            fileUploaderFields={["UploadBill"]}
            renderUploadedFilesItem={this.renderUploadedFilesItem}
            customValidators={customValidators}
            renderRadioIcons={(option) => this.renderRadioIcons(option)}
          />
        </div>
        <SaveAsDraftDrawer
          isActiveMobileDraft={this.state.isActiveMobileDraft}
          toggleDrawer={this.toggleDrawer}
          onDontSaveHandler={this.onDontSaveDraftHandler}
          onSaveDraftHandler={this.onSaveDraftHandler}
        ></SaveAsDraftDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  leadFormReducer: state.leadFormReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getUserBranches: (payload) => dispatch(getUserBranches(payload)),
  getActivityDetails: (payload) => dispatch(getActivityDetails(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  getBDMBranchName: (payload) => dispatch(getBDMBranchName(payload)),
  finishDayActivityCreate: (payload) =>
    dispatch(finishDayActivityCreate(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(ResumeDraftedActivityBroca)
);
