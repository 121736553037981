import React, { useEffect } from 'react';
import './styles/app.css';
import Layouts from './containers/layouts';
import useOnlineStatus from './hooks/useOnlineStatus';
import { getSyncConfigData, initHistoricalLeadPull, initPushLead } from './offlineManager/leadMasterLoader';
import { getLeadSyncTime } from './offlineManager/leadOfflineManager';
import { getActivitySyncTime } from './offlineManager/activityOfflineManager';
import { initHistoricalActivityPull, initPushActivity } from './offlineManager/activityMasterLoader';
import { getUniqueDeviceId } from "./utils/utils";
import { isAuthenticated } from './auth';

function App() {
  console.log(':::Unique device ID::: ', getUniqueDeviceId());
  const isOnline = useOnlineStatus();
  

  useEffect(() => {
    setTimeout(async () => {
      const leadLastSyncTime = await getLeadSyncTime()
      const activityLastSyncTime = await getActivitySyncTime()
      const userData = JSON.parse(localStorage.getItem('persist:root'));
      let userId;
      let roleName = 'BDM';
      if (userData) {
        userId = JSON.parse(userData.userReducer).profileInfo.userId;
        roleName = JSON.parse(userData.userReducer).profileInfo.roleId;
      }
      if (roleName === 'BDM' && isAuthenticated()) {
        if (isOnline && leadLastSyncTime && leadLastSyncTime.syncTime) {
          getSyncConfigData();
          initPushLead(userId, leadLastSyncTime.syncTime)
        }
        if (isOnline && leadLastSyncTime && leadLastSyncTime.syncTime) {
          initHistoricalLeadPull(userId, leadLastSyncTime.syncTime)
        }
        if (isOnline && activityLastSyncTime && activityLastSyncTime.syncTime) {
          initPushActivity(userId, activityLastSyncTime?.syncTime)
        }
        if (isOnline && activityLastSyncTime && activityLastSyncTime.syncTime) {
          initHistoricalActivityPull(userId, activityLastSyncTime?.syncTime)
        }
      }
    }, 3000);
  }, [isOnline])

  return (
    <div className="App"> 
     <Layouts />
    </div>
  );
}

export default App;
