import {
  ALLOWED_FUTURE_DAYS,
} from "../../../../utils/config";
import { getDateAfterDays } from "../../../../utils/utils";
import { format } from "date-fns";
import moment from "moment";

const _formatDate = "YYYY-MM-DDTHH:mm";

const dateFormate = "yyyy-MM-dd";
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";

const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
const minDate =  format(new Date(), dateFormate) + "T" + format(new Date(), "kk:mm");
const maxDate=moment(new Date()).endOf("day").format(_formatDate)
const datetimelocal = "datetime-local";

/*  --------------------------------------------------------
            Rschedule Activity start
    --------------------------------------------------------  */

export const BankaSalesMangementRescheduledActivity = [
  {
    key: "SalesManagementIDReschedule",

    type: "select",

    id: "SalesMangementID",

    label: "Sales Management Activity Type",

    validators: [
      {
        name: "required",

        value: true,

        validationMessage: "Please select the activity.",
      },
    ],

    options: []
  },
];

//------------------- Rechedule Activity 1 ( Schedule BDM ) ---------------------------------

export const RescheduledActivitySchedule_BDM = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  { 
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
];

//------------------- Rechedule Activity 2 ( Branch visit plan ) ---------------------------------

export const RescheduledActivityBranchvisitplan = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "CBIsClaimRequired",
    name: "CBIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CBIsClaimRequired",
    label: "Do you want to claim travel expense?",
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: "Do you want to claim travel expense?",
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Rechedule Activity 3 ( Regional Office meeting ) ---------------------------------

export const RescheduledActivityRegionalOfficemeeting = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "CROIsClaimRequired",
    name: "CROIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CROIsClaimRequired",
    label: "Do you want to claim travel expense?",
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: "Do you want to claim travel expense?",
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Rechedule Activity 4 ( Sales Training plan ) ---------------------------------

export const RescheduledActivitySalesTrainingplan = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select Reschedule",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
];

//------------------- Rechedule Activity 5 ( joint call bdm ) ---------------------------------

export const RescheduledActivityjointcallbdm = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "CJIsClaimRequired",
    name: "CJIsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "CJIsClaimRequired",
    label: "Do you want to claim travel expense?",
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

//------------------- Rechedule Activity 6 ( Circular for Sales Enablement ) ---------------------------------

export const RescheduledActivityCircularforSalesEnablement = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
];

/*  --------------------------------------------------------
                  Rschedule Activity end
          --------------------------------------------------------  */


export const Rescheduled_SCHEDULE_STRUCTURED_REVIEW_ABH = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  { 
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Rescheduled_BRANCH_VISIT_WITH_BDM_AND_ABH = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  { 
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Rescheduled_STRUCTURED_ZONAL_HEAD_MEETING = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  { 
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Rescheduled_JOINT_CALLS_WITH_CUSTOMER = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  { 
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Rescheduled_REGION_VISIT_WITH_ABH = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  { 
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Rescheduled_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM = [
  {
    key: "SmaReasonID",
    type: "select",
    id: "SmaReasonID",
    label: "Reschedule",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason.",
      },
    ],
    options: [],
  },
  {
    key: "Remarks",
    type: "text",
    id: "Remarks",
    label: "Remarks, if any",

    validators: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    max:
      format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) +
      "T" +
      "23:59",
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  { 
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]