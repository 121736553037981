/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-24 14:20:48
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */

import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import { leadFilterForm, customValidators } from "./leadFilterForm";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { activateErrorMessageBar, getSubordinateList } from "../../../redux/actions";
import { ROLE_ABH, ROLE_BDM, ROLE_FPM, USER_TYPE, LEAD_STATUS as LEAD_OPTION, GCL_LEAD_STATUS_ID } from "../../../utils/config";
import { getDropdownDataBranch } from '../../../utils/utils';
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { useHistory } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down('xs')]: {
      display: "block"
    },
    [theme.breakpoints.down('sm')]: {
      display: "block"
    }
  },
  desktopFilter: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  }
}));


const LeadFilter = ({ getFilteredLeads, getAllLeads, parent, handleClose, filterState, setFilterState, setSelectedFilter, anchor, IsRecruitmentlead }) => {
  // const commonState = useSelector(state => state); 
  const history = useHistory()
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  // const locationParams = location.pathname.split('/')[2]
  const userState = useSelector(mainState => mainState.userReducer);
  const UserId = userState?.profileInfo?.userId
  const FullName = `${userState?.profileInfo?.firstName} ${userState?.profileInfo?.lastName}`;
  const [UserFullName, setUserFullName] = useState(FullName);
  const roleId = userState?.profileInfo?.roleId
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userName = `${JSON.parse(userData.userReducer).profileInfo.firstName
    } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
  const channelId = userState.profileInfo.channelid;
  const { GetBDMByABH } = useSelector(nextState => nextState.commonReducer)
  const {
    leadStatus,
    Activities,
    getCampaignNames
  } = useSelector((reducer) => reducer.leadFormReducer);

  const { ProductDetails, SPType, LeadSource, Branches } = useSelector((state) => state.commonReducer)
  const [state, setState] = useState({
    current: { id: 1 },
    formData: leadFilterForm,
    autoCompleteItems: {
      product: [],
      activityID: [],
      branchNameCode: []
    },
    selectproduct: '',
  });
  const [newUserList, setNewUserList] = useState([{
    key: UserId,
    label: UserFullName,
    value: UserId
  }])
  const meetingDateFormate = "YYYY-MM-DD";

  const dynamicRef = React.useRef();
  const getDropdownOFData = (optionsArr, id, label) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { "id": `${option[id]}`, "label": `${option[label]}` }
      })
    }
    arr.shift({ "id": " ", "label": "select" })
    arr.unshift({ "id": " ", "label": "select" })
    return arr;
  }

  const getDropdownActivtiy = (optionsArr, id, label) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { "id": `${option[id]}`, "label": `${option[label]}` }
      })
    }
    arr.shift({ "id": " ", "label": "select" })
    arr.unshift({ "id": "0", "label": "Spot Lead Closure" })
    arr.unshift({ "id": " ", "label": "select" })
    return arr;
  }

  useEffect(() => {
    if (isOnline) {
      if (roleId !== ROLE_BDM && roleId !== ROLE_FPM) {
        getSub()
      }
    }

    const autoCompleteItems = { ...state.autoCompleteItems };
    autoCompleteItems.product = getDropdownOFData(ProductDetails, "key", "label");
    let copyData;
    if (channelId === 1) {
      copyData = getDropdownActivtiy(Activities?.ActivityID, 'id', 'label');
    } else {
      copyData = Activities?.ActivityID;
    }
    autoCompleteItems.activityID = getActivityTypeOptions(copyData)
    const BranchesCopy = [...Branches];
    autoCompleteItems.branchNameCode = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
    const formData = initFormData(state.formData);
    setState({ formData: formData, autoCompleteItems: autoCompleteItems });

    if (!dynamicRef.current.state?.userType) {
      state.formData[1].hide = true
      state.formData[2].hide = false
    }

    if (channelId === 13) {

      const businessfilterHide = ["activityID", "campaign", "product", "typeOfSP", "MobileNumber"]
      const businessfilterShow = ["leadSource", "LeadTypeID"]

      leadFilterForm.map((item, index) => {
        if (businessfilterHide.includes(item.key)) {
          leadFilterForm[index].hide = true
        } else if (businessfilterShow.includes(item.key)) {
          leadFilterForm[index].hide = false
        }
        return item;
      })

      // leadFilterForm.find(item=>item.key==="leadSource").hide = false
      // leadFilterForm.find(item=>item.key==="userType").hide = true
      // leadFilterForm.find(item=>item.key==="LeadTypeID").hide = false
      // leadFilterForm.find(item=>item.key==="LastUpdateDateTime").hide = true
      // leadFilterForm.find(item=>item.key==="product").hide = true
      // leadFilterForm.find(item=>item.key==="typeOfSP").hide = true
      // leadFilterForm.find(item=>item.key==="teamMember").hide = true

    }

    if (history?.location?.pathname === "/app/businesslead/all") {

      const businessfilterHide = ["userType", "LastUpdateDateTime", "product", "typeOfSP", "teamMember"]
      const businessfilterShow = ["leadSource", "LeadTypeID"]

      leadFilterForm.map((item, index) => {
        if (businessfilterHide.includes(item.key)) {
          leadFilterForm[index].hide = true
        } else if (businessfilterShow.includes(item.key)) {
          leadFilterForm[index].hide = false
        }
        return item;
      })
    }
    if (history?.location?.pathname === "/app/recruitmentlead/all") {
      const recruitmentfilterHide = ["userType", "LeadTypeID", "product", "typeOfSP", "teamMember"] //"leadSource",
      leadFilterForm.map((item, index) => {
        if (recruitmentfilterHide.includes(item.key)) {
          leadFilterForm[index].hide = true
        }
        return item
      })
    }
    if (channelId === 21) {
      const brocaLeadfilterHide = ["MeetingDate", "branchNameCode", "userType", "LeadTypeID", "product", "typeOfSP", "teamMember"]
      leadFilterForm.map((item, index) => {
        if (brocaLeadfilterHide.includes(item.key)) {
          leadFilterForm[index].hide = true
        }
        return item
      })
    }

    if (channelId == 79) {
      const msmefilterHide = ["campaign", "product", "typeOfSP", "typeOfSP", "branchNameCode", "LeadTypeID"]
      leadFilterForm.map((item, index) => {
        if (msmefilterHide.includes(item.key)) {
          leadFilterForm[index].hide = true
        }
        if (item.key === "activityID") {
          leadFilterForm[index].label = "Activity Type"
        }
        return item;
      })
    }


  }, []);

  useEffect(() => {
    if (roleId === ROLE_ABH && channelId !== 3) {
      state.formData[0].hide = false
    }

  }, [roleId])

  useEffect(() => {
    // console.log(UserFullName)
    setFilterState({
      UserName: UserFullName,
      ExpectedLeadClosureDate: "null"
    })
  }, [UserFullName]);

  const getSub = async () => {
    const formData = [...state.formData]
    dispatch(getSubordinateList({ userId: userState.profileInfo.userId })).then((response) => {
      const r = response.data.map(el => {
        return {
          key: el.userID,
          label: el.userName,
          value: el.userID
        }
      });
      setNewUserList([...newUserList, ...r])
      formData[1].options = [...newUserList, ...r];
      if (GetBDMByABH !== 'all') {
        const res = response.data.filter((el) => {
          if (GetBDMByABH == el.userID)
            return true;
        });
        setUserFullName(res[0].userName)
        formData[0].hide = true;
        formData[1].hide = true;
        formData[2].hide = false;
      }
      // else {
      //   formData[0].hide = false;
      //   formData[1].hide = true;
      //   formData[2].hide = false;
      // }
    })
  }

  const onSubmitHandler = (model) => {
    let { CreatedDateTime, LastUpdateDateTime, LeadName, fromdate, todate, MeetingDate, MobileNo, teamMember,
      CreatedDateStartTIme, CreatedDateEndTIme, Modifiedstartdate, Modifiedenddate, currentstatus, branchNameCode, ExpectedLeadClosureDate } = model;
    const branchNameValue = [...Branches].some((el) => el.value === branchNameCode)
    if (LeadName === "" || LeadName === "null" || LeadName === 0) {
      LeadName = null
    }
    if (MobileNo === "" || MobileNo === "null" || MobileNo === 0) {
      MobileNo = null
    }
    if (teamMember === "" || teamMember === undefined) {
      teamMember = null
    }
    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    let modifiedstart = null;
    let modifiedend = null;

    if (MeetingDate !== "null" && MeetingDate !== 'duration' && MeetingDate !== undefined) {
      const lastdatearr = model.MeetingDate.split(',');
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (CreatedDateTime !== "null" && CreatedDateTime !== 'duration' && CreatedDateTime !== undefined) {
      const lastdatearr = model.CreatedDateTime.split(',');
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (LastUpdateDateTime !== "null" && LastUpdateDateTime !== 'duration' && LastUpdateDateTime !== undefined) {
      const lastdatearr = model.LastUpdateDateTime.split(',');
      modifiedstart = lastdatearr[0] ? lastdatearr[0] : null;
      modifiedend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (ExpectedLeadClosureDate == "null" && ExpectedLeadClosureDate == undefined) {
      model.ExpectedLeadClosureDate = ExpectedLeadClosureDate ? ExpectedLeadClosureDate : "null";
    }


    if (MeetingDate === 'duration') {
      if (fromdate === 'null' || todate === 'null') {
        dispatch(activateErrorMessageBar('Please Select Value For Both Meeting Start Date and End Date'))
        return false
      }
      meetingstart = model.fromdate;
      meetingend = model.todate
    }
    if (LastUpdateDateTime === 'duration') {
      if (Modifiedstartdate === 'null' || Modifiedenddate === 'null') {
        dispatch(activateErrorMessageBar('Please Select Value For Both Lastupdated Start Date and End Date'))
        return false
      }
      modifiedstart = model.Modifiedstartdate;
      modifiedend = model.Modifiedenddate
    }
    if (CreatedDateTime === 'duration') {
      if (CreatedDateStartTIme === 'null' || CreatedDateEndTIme === 'null') {
        dispatch(activateErrorMessageBar('Please Select Value For Both Created Start Date and End Date'))
        return false
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;

    model.CreatedDateStartTIme = createdstart;
    model.CreatedDateEndTIme = createdend;

    model.Modifiedstartdate = modifiedstart;
    model.Modifiedenddate = modifiedend;

    for (const item in model) {
      if (model[item] === '' && item !== LeadName && item !== MobileNo) {
        model[item] = 0
      }
    }
    const status = currentstatus?.map((val) => {
      return val.value;
    })
    model.currentStatus = status?.join(',');
    if (model.currentStatus === "") {
      model.currentStatus = null
    }

    if (model.activityIDlabel !== 0) {
      let activitykey = Activities?.ActivityID.filter((activity) => activity.label === model.activityIDlabel)
      model.activityID = activitykey[0]?.id
    } else {
      model.activityID = null;
    }

    if (model.activityIDlabel == "Spot Lead Closure") {
      model.IsSpotLeadClosure = 1;
    } else {
      model.IsSpotLeadClosure = 0;
    }


    if (CreatedDateTime !== "null" || LastUpdateDateTime !== "null" || LeadName !== null || model.LeadTypeID !== 0 ||
      MeetingDate !== "null" || MobileNo !== null || model.activityID !== 0 || model.branchNameCode !== 0 || model.currentStatus !== 0 ||
      model.leadSource !== 0 || model.product !== 0 || model.typeOfSP !== 0 || teamMember !== null) {

      handleClose();
      getFilteredLeads(model, teamMember, branchNameValue);
    } else {
      dispatch(activateErrorMessageBar('Please select anyone to apply filter'))
    }

  };
  const onResetHandler = () => {
    setFilterState({
      ...filterState,
      ExpectedLeadClosureDate: "null",
      CreatedDateTime: "null",
      MeetingOn: "null",
      LastUpdateDateTime: "null",
      branchNameCode: 0,
      currentStatus: 0,
      currentstatus: [],
      branchNameCodelabel: "",
      typeOfSP: 0,
      productlabel: "",
      leadSource: 0,
      LeadTypeID: 0,
      activityIDlabel: "",
      LeadName: "",
      MeetingDate: "null",
      fromdate: "null",
      todate: "null",
      MobileNumber: "",
      CreatedDateStartTIme: "null",
      CreatedDateEndTIme: "null",
      Modifiedstartdate: "null",
      Modifiedenddate: "null",
      userType: "",
      MobileNo: "",
      campaign: "null",
      IsRecruitmentlead: IsRecruitmentlead ? 1 : 0,
    })


    leadFilterForm[1].hide = true;
    leadFilterForm[2].hide = false;
    leadFilterForm[6].hide = true;
    leadFilterForm[7].hide = true;
    leadFilterForm[10].hide = true;
    leadFilterForm[11].hide = true;
    leadFilterForm[12].hide = true;
    leadFilterForm[13].hide = true;
    getAllLeads()
  };
  const onChangeHandler = (e, key) => {
    if (key === 'LeadName') {
      setFilterState({
        ...filterState,
        LeadName: e.target.value
      })
    }
    if (key === 'activityID') {

      let copyData;
      if (channelId === 1) {
        copyData = getDropdownActivtiy(Activities?.ActivityID, 'id', 'label');
      } else {
        copyData = Activities?.ActivityID;
      }
      const activityNameValue = copyData.filter((el) => el.id === e.target.value)
      setFilterState({
        ...filterState,
        activityID: e.target.value,
        activityIDlabel: activityNameValue[0]?.label
      })
    }
    if (key === 'MobileNumber') {
      setFilterState({
        ...filterState,
        MobileNo: e.target.value,
        MobileNumber: e.target.value
      })
    }
    if (key === 'LeadTypeID') {
      setFilterState({
        ...filterState,
        LeadTypeID: e.target.value
      })
    }
    if (key === 'userType') {
      setFilterState({
        ...filterState,
        userType: e.target.value
      })
    }
    if (key === 'CreatedDateTime') {
      setFilterState({
        ...filterState,
        CreatedDateTime: e.target.value
      })
      if (e.target.value === "duration") {
        leadFilterForm[6].hide = false;
        leadFilterForm[7].hide = false;
        setFilterState({
          ...filterState,
          CreatedDateTime: e.target.value,
          CreatedDateStartTIme: moment(new Date()).format(meetingDateFormate),
          CreatedDateEndTIme: moment(new Date()).format(meetingDateFormate),
        });
      } else {
        leadFilterForm[6].hide = true;
        leadFilterForm[7].hide = true;
        setFilterState({
          ...filterState,
          CreatedDateTime: e.target.value,
          CreatedDateStartTIme: "null",
          CreatedDateEndTIme: "null",
        });
      }
    }
    if (key === 'currentStatus') {
      setFilterState({
        ...filterState,
        currentStatus: e.target.value,
        currentstatus: e.target.value
      })
    }
    if (key === 'MeetingOn') {
      setFilterState({
        ...filterState,
        MeetingOn: e.target.value
      })
    }
    if (key === 'LastUpdateDateTime') {
      setFilterState({
        ...filterState,
        LastUpdateDateTime: e.target.value
      })
      if (e.target.value === "duration") {
        leadFilterForm[12].hide = false;
        leadFilterForm[13].hide = false;
      } else {
        leadFilterForm[12].hide = true;
        leadFilterForm[13].hide = true;
      }
    }
    if (key === 'leadSource') {
      setFilterState({
        ...filterState,
        leadSource: e.target.value
      })
    }
    if (key === 'product') {
      const branchNameValue = ProductDetails.filter((el) => el.key === parseInt(e.target.value))

      setFilterState({
        ...filterState,
        product: e.target.value,
        productlabel: branchNameValue[0]?.label
      })
    }
    if (key === 'typeOfSP') {
      setFilterState({
        ...filterState,
        typeOfSP: e.target.value
      })
    }
    if (key === 'MeetingDate') {
      setFilterState({
        ...filterState,
        MeetingDate: e.target.value
      })
      if (e.target.value === "duration") {
        leadFilterForm[10].hide = false;
        leadFilterForm[11].hide = false;
        setFilterState({
          ...filterState,
          MeetingDate: e.target.value,
          fromdate: moment(new Date()).format(meetingDateFormate),
          todate: moment(new Date()).format(meetingDateFormate),
        });
      } else {
        leadFilterForm[10].hide = true;
        leadFilterForm[11].hide = true;
        setFilterState({
          ...filterState,
          MeetingDate: e.target.value,
          fromdate: "null",
          todate: "null",
        });
      }
    }
    if (key === 'CreatedDateStartTIme') {
      setFilterState({
        ...filterState,
        CreatedDateStartTIme: e.target.value
      })
      leadFilterForm[7].min = e.target.value

    }
    if (key === 'CreatedDateEndTIme') {
      setFilterState({
        ...filterState,
        CreatedDateEndTIme: e.target.value
      })
    }
    if (key === 'Modifiedstartdate') {
      setFilterState({
        ...filterState,
        Modifiedstartdate: e.target.value
      })
      leadFilterForm[13].min = e.target.value

    }
    if (key === 'Modifiedenddate') {
      setFilterState({
        ...filterState,
        Modifiedenddate: e.target.value
      })
    }
    if (key === 'fromdate') {
      setFilterState({
        ...filterState,
        fromdate: e.target.value
      })
      leadFilterForm[11].min = e.target.value
    }
    if (key === 'todate') {
      setFilterState({
        ...filterState,
        todate: e.target.value
      })
    }
    if (key === "userType") {
      const getUserType = e.target.value
      if (getUserType === "team") {
        state.formData[1].hide = false
        state.formData[2].hide = true
        // dispatch({
        //   type: "viewFor",
        //   payload: ""
        // })
        setSelectedFilter({
          roleId: "ABH",
          viewFor: "BDM"
        })
      } else {
        state.formData[1].hide = true
        state.formData[2].hide = false
        setSelectedFilter({
          roleId: "ABH",
          viewFor: "SELF"
        })
      }
    }
    if (key === 'ExpectedLeadClosureDate') {
      setFilterState({
        ...filterState,
        ExpectedLeadClosureDate: e.target.value
      })
    }

  };
  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "Select", value: "" });
    return arr;
  };
  let LEAD_STATUS = getDropdownData(
    leadStatus,
    "LeadStatus",
    "LeadStatus",
    "LeadStatusId"
  );
  const CAMPAIGN_NAMES = getDropdownData(
    getCampaignNames,
    "CampaignNameID",
    "CampaignName",
    "CampaignNameID"
  )

  if (channelId == 1 && roleId == "BDM") {
    LEAD_STATUS = LEAD_STATUS?.filter(ele => ele.value == 1 || ele.value == 2 || ele.value == 3 || ele.value == 4 || ele.value == 5 || ele.value == 6 || ele.value == 7 || ele.value == 8 || ele.value == 10)
  }

  if (channelId == 1 && roleId == "ABH") {
    LEAD_STATUS = LEAD_STATUS?.filter(ele => ele.value == 1 || ele.value == 2 || ele.value == 3 || ele.value == 4 || ele.value == 5 || ele.value == 6 || ele.value == 7 || ele.value == 8 || ele.value == 10)
  }
  if (channelId == 1 && roleId == ROLE_FPM) {
    LEAD_STATUS = LEAD_STATUS?.filter(ele => ele.value == 1 || ele.value == 2 || ele.value == 3 || ele.value == 4 || ele.value == 5 || ele.value == 6 || ele.value == 7 || ele.value == 8 || ele.value == 10)
  }

  const onAutocompleteSelectHandler = (key, value, item) => {
    if (key === "branchNameCode") {
      setFilterState({
        branchNameCode: item.value
      })
    }
  }
  const processCurrentStatusOption = csOption => {
    const processedOption = [];
    if (history?.location?.pathname === "/app/businesslead/all" && channelId === 3) {
      csOption.map(option => {
        if (channelId != 3) {
          processedOption.push({
            "key": option.key !== "select" ? `Businesslead - ${option.key}` : option.key,
            "label": option.label,
            "value": option.value
          })
        } else {
          if (!GCL_LEAD_STATUS_ID.includes(option.value)) {
            processedOption.push({
              "key": option.key !== "select" ? `Businesslead - ${option.key}` : option.key,
              "label": option.label,
              "value": option.value
            })
          }
        }
      })
      return processedOption
    } else if (history?.location?.pathname === "/app/recruitmentlead/all" && channelId === 3) {
      csOption.map(option => {
        if (channelId != 3) {
          processedOption.push({
            "key": option.key !== "select" ? `Recruitmentlead - ${option.key}` : option.key,
            "label": option.label,
            "value": option.value
          })
        } else {
          if (!GCL_LEAD_STATUS_ID.includes(option.value)) {
            processedOption.push({
              "key": option.key !== "select" ? `Recruitmentlead - ${option.key}` : option.key,
              "label": option.label,
              "value": option.value
            })
          }
        }
      })
      return processedOption
    } else if (channelId === 1) {
      let csOptionfilter = csOption.filter(opt => opt.value != 11 && opt.value != 12 && opt.value != 13 && opt.value != 14 && opt.value != '2,3,4,7')
      return csOptionfilter
    } else if (channelId === 21) {
      let csOptionfilter = csOption.filter(opt => opt.value === '' || opt.value === 1 || opt.value === 2 || opt.value === 3
        || opt.value === 4 || opt.value === 5 || opt.value === 6 || opt.value === 7 || opt.value === 10)
      return csOptionfilter
    } else {
      return csOption
    }
  }
  const getActivityTypeOptions = (allActivityOptions) => {
    let processedOption;
    if (history?.location?.pathname === "/app/businesslead/all" && channelId === 3) {
      processedOption = allActivityOptions?.filter((opt) => opt.activitytypeid === 13)
      // allActivityOptions.map(option => {
      //   if (option.activitytypeid === 13) {
      //     processedOption.push({
      //       key: option.id,
      //       label: option.label,
      //       value: option.id
      //     })
      //   }
      // })
      return processedOption
    } else if (history?.location?.pathname === "/app/recruitmentlead/all" && channelId === 3) {
      processedOption = allActivityOptions?.filter((opt) => opt.activitytypeid === 14)
      // allActivityOptions.map(option => {
      //   if (option.activitytypeid === 14) {
      //     processedOption.push({
      //       "key": option.id,
      //       "label": option.label,
      //       "value": option.id
      //     })
      //   }
      // })
      return processedOption
    } else if (channelId === 21) {
      processedOption = allActivityOptions?.filter((opt) => opt.activitytypeid === 53)
      // allActivityOptions.map(option => {
      //   if (option.activitytypeid === 53) {
      //     processedOption.push({
      //       "key": option.id,
      //       "label": option.label,
      //       "value": option.id
      //     })
      //   }
      // })
      return processedOption
    } else {
      return allActivityOptions
    }
  }
  const initFormData = (formFields) => {
    const restrictedArr = ["DRAFTED", "BACKLOG", "AUTO_CLOSED", "PREMIUM_COLLECTED"]
    const currenstatusoption = LEAD_STATUS.filter((item) => !restrictedArr.includes(item.key))
    const formData = [...formFields];
    formData[0].options = USER_TYPE
    formData[2].disabled = true;
    setFilterState({ ...filterState, UserName: userName });
    formData[18].options = CAMPAIGN_NAMES;
    formData[4].options = LEAD_OPTION;

    if (roleId === "BDM" || roleId === "ABH") {
      let banca_sp_type = ['SRM'];
      let banca_sp_arr = [];
      if (SPType && SPType.length > 0) {
        banca_sp_arr = SPType.filter((option) => {
          if (!banca_sp_type.includes(option.key)) {
            return option;
          }
        });
      }
      formData[19].options = banca_sp_arr;
    } else if (roleId === "FPM") {
      const sp_type = ['STAFF'];
      let filter_arr = [];
      if (SPType && SPType.length > 0) {
        filter_arr = SPType.filter((option) => {
          if (!sp_type.includes(option.key)) {
            return option;
          }
        });
      }
      formData[19].options = filter_arr;
    } else {
      formData[19].options = SPType;
    }
    if (userState.profileInfo.channelid === 3) {
      formData[19].hide = false;
    }
    if (userState.profileInfo.channelid === 1) {
      formData[20].hide = false;
    }
    formData[17].options = LeadSource;
    formData[8].options = processCurrentStatusOption([...currenstatusoption, { key: 'OPENLEADS', label: 'Open Leads', value: '2,3,4,7' }]);
    if (userState.profileInfo.channelid === 3) {
      formData[16].hide = true
    }
    return formData;

  };
  const classes = useStyles();

  return (
    <div className={anchor ? classes.drawerMobile : classes.desktopFilter}>
      <div className="filterBox">
        <h4 className="filterSec">
          {" "}
          <FilterAltIcon className="icnFilter" /> Filter By <CloseIcon className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
        </h4>
        <div className="fformBox">
          <DynamicForm
            key={1}
            onRef={(ref) => {
              dynamicRef.current = ref;
            }}
            className="dynamicForm leadFilters  leadFiltersMobile"
            title={" "}
            buttonSubmit={t("Apply Filter")}
            defaultValues={filterState}
            customValidators={customValidators}
            model={state.formData}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={false}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key) => onChangeHandler(event, key)}
            onAutocompleteSelect={onAutocompleteSelectHandler}
            autoCompleteItems={state.autoCompleteItems}
            showAstersikOnRequiredFields={false}

            extaraActionFormButton={
              <span className="reset" onClick={onResetHandler} ><RestartAltIcon /> Reset</span>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(LeadFilter);
