import React, { Component } from 'react'

export default class Terms extends Component {
    render() {
        return (
            <div>
                These Terms and Conditions govern your use of our application. By using our application, you accept the Terms and Conditions in full. If you disagree with any of these Terms and Conditions or any part of these Terms and Conditions, you must not use our application. Please read all the Terms and Conditions carefully before using this website.
            </div>
    )
  }
}
