import "font-awesome/css/font-awesome.min.css";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import MonthlyCalendar from '../commonCalendar/commonCalendar';
import './calenderDashboardCard.scss';
import { useHistory } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const CalenderDashboardCard = (props) => {
  const { classes, selectedBDM, selectedView, roleId, Item, selectedSecondLevelSubordinate,
    selectedThirdLevelSubordinate, selectedFourthLevelSubordinate } = props;
  const history = useHistory()

  const redirectToCalendar = () => {
    history.push('/app/calendar')
  }

  return <Paper className={classes.paper2} sx={{ mb: 2 }}>
    <Grid container className="calenderOverviewSec"> <Grid container>
      <Grid item xs={6}>
        <Typography variant="h5" gutterBottom>
          Calendar
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="right">
        <p className="viewAll" onClick={redirectToCalendar}>View All</p>
      </Grid>
    </Grid>
      <MonthlyCalendar
        selectedBDM={selectedBDM}
        selectedView={selectedView}
        roleId={roleId} classes={classes}
        Item={Item} history={history}
        renderFrom="dashboard"
        selectedSecondLevelSubordinate={selectedSecondLevelSubordinate}
        selectedThirdLevelSubordinate={selectedThirdLevelSubordinate}
        selectedFourthLevelSubordinate={selectedFourthLevelSubordinate}
      />
    </Grid>
  </Paper>
}
export default CalenderDashboardCard;
