import {
    ACTIVITY_CANCELLED,
    ACTIVITY_COMPLETED,
    ACTIVITY_RESCHEDULED,
  } from "../../../../utils/config";
import { CancelActivityFormBorca } from "./activityCancelForm";
  import {
    CompletedActivityFormBorca,
    CompletedActivityFormPartnerVisit
  } from "./activityCompleteForm";
import { ReschedeuleActivityFormBorca } from "./activityRescheduleForm";

  const travelExpenseLabel = "Travel Expense";
  const travelExpenseValidationmessage = "Travel expense is required.";
  const fourDigitAllowed = "Maximum 4 digits allowed";
  const billPhoto = "Upload bill photo";
  const uploadBill = "Please Upload Bill Copy";
  const modeOftransportRequired = "mode of Trasport is required.";
  const vehicleType = "Vehicle Type";
  const acceptFileType = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ];
  export const OtherReason = [{
    key: "Other_Reason",
    type: "text",
    id: "Other_Reason",
    label: "Other reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select reason",
      },
    ],
  }];
  // export const ActivityCompletedFormPart2 = [
  //   {
  //     key: "TravelExpence",
  //     hide: false,
  //     type: "number",
  //     props: { min: "1" },
  //     disabled: false,
  //     id: "TravelExpence",
  //     label: travelExpenseLabel,
  //     validators: [
  //       {
  //         name: "required",
  //         value: true,
  //         validationMessage: travelExpenseValidationmessage,
  //       },
  //       { name: "maxLength", value: 4, validationMessage: fourDigitAllowed },
  //     ],
  //   },
  //   {
  //     key: "UploadBill",
  //     hide: false,
  //     type: "file",
  //     dropTitle: billPhoto,
  //     acceptFileTypes: acceptFileType,
  //     componentType: "DRAG_DROP_BROWSE",
  //     layout: "1column",
  //     restrictMultipleFiles: true,
  //     disabled: false,
  //     id: "UploadBill",
  //     label: uploadBill,
  //     validators: [],
  //   },
  // ];
  // export const ActivityRescheduledFormPart3 = [
  //   {
  //     key: "VehicleTypeID",
  //     hide: false,
  //     type: "radio",
  //     disabled: false,
  //     id: "VehicleType",
  //     label: vehicleType,
  //     validators: [
  //       {
  //         name: "required",
  //         value: true,
  //         validationMessage: modeOftransportRequired,
  //       },
  //     ],
  //     options: [],
  //   },
  // ];


  // export const salesManagementRadio = [
  //   {
  //     key: "TransportModeID",
  //     type: "radio",
  //     label: "Mode Of transport",
  //     validators: [
  //       {
  //         name: "required",
  //         value: true,
  //         validationMessage: "please select transport mode",
  //       },
  //     ],
  //     options: [],
  //   },
  // ];
  export const getOptionById = (id, optionValues) => {
    return optionValues.find((item) => item.value === id);
  };
  export class SalesActivityFormBuilder {
    constructor(actionType, activityTypeForupdate,data,activityData,SMAActivityDuration,SmaReason,Reason) {
      this.actionType = actionType;
      this.activityTypeForupdate = activityTypeForupdate;
      this.data = data;
      this.activityData = activityData;
      this.SMAActivityDuration = SMAActivityDuration;
      this.SmaReason = SmaReason;
      this.Reason = Reason;
    }
    getFormTitle = () => {
      let formTitle = "";
      switch (this.actionType) {
        case ACTIVITY_RESCHEDULED:
          formTitle = "Activity Rescheduled";
          break;
        case ACTIVITY_CANCELLED:
          formTitle = "Activity Cancelled";
          break;
        case ACTIVITY_COMPLETED:
          formTitle = "Activity Completed";
          break;
        default:
          formTitle = "Activity Completed";
          break;
      }
      return formTitle;
    };
    getForm = () => {
      let form = [];
      switch (this.actionType) {
        case ACTIVITY_RESCHEDULED:
          switch (this.activityData) {
            case 54:
              form = ReschedeuleActivityFormBorca
              break;
            case 55:
              form = ReschedeuleActivityFormBorca
              break;
            default:
              break;
          }
          break;
          case ACTIVITY_CANCELLED:
            switch (this.activityData) {
              case 54:
                form = CancelActivityFormBorca
                break;
              case 55:
                form = CancelActivityFormBorca
                break;
              default:
                break;
            }
            break;
        case ACTIVITY_COMPLETED:
          switch (this.activityData) {
            case 54:
                form =CompletedActivityFormBorca
                form[0].options=this.SMAActivityDuration
              break;
            case 55:
              form = CompletedActivityFormPartnerVisit
              break;
            default:
              break;
          }
          break;
        default:
          form =CompletedActivityFormBorca
          break;
      }
      return form;
    };
  }
