import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto'


const BarChart = ({ chartData }) => {
  return (
    <div>
      <Bar
        data={chartData}
        options={{
          scales: {
            y: {
              ticks: {
                stepSize: 1,
                beginAtZero: true,
              },
            },
          },
          plugins: {
            // title: {
            //   display: true,
            //   text: "Cryptocurrency prices",
            //   alignment:'left'
            // },
            legend: {
              display: false,
              // position: "bottom"
            }
          }
        }}
      />
    </div>
  );
};

export default BarChart


