import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb'
import MessageBar from '../../../components/messageBar/messageBar'
import DynamicForm from "f1-dynamic-form-react"
import { BANCA_LEAD, BUSSINESS_LEAD, checkInConfig, LEAD_HAS_MEETING, RECRUITMENT_LEAD, FPM_LEAD } from '../../../utils/config';
import { getDropdownDataBranch, getDropdownData, minusMinuteFromDate, userData } from '../../../utils/utils';
import { customValidators, getForm } from './createLeadForm';
import { connect } from "react-redux"
import moment from "moment"
import { t } from "i18next";
import { checkDateIsValid, isBranchValid } from "../../../utils/validators";
import Loader from "../../../components/loader/loader";
import './createLead.scss';
import { saveLeadOffline, isLeadValid, getLgSpList, getSRMList } from "../../../offlineManager/leadOfflineManager";
import { v4 as uuidv4 } from 'uuid';
import Footer from '../../../layouts/footer/footer';
import {
    checkMobileNumberReducer, getLGValueReducer, saveLeadForm,
    validateMeetingDateStatusInCreate, getLeadDetails, UpdateLeadForm, createLeadDeDupeValidation,
    validateMeetingDateForCreateLead, getSelectedDraftLeadData
} from '../../../redux-reducer/reducer';
import { setBreadcrumbs, activateLoader, deActivateLoader, activateSuccessMessageBar, activateErrorMessageBar } from '../../../redux/actions/common';
import { LEADS_PATH } from '../../../route/routePath';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './updateLead.scss';
import SaveAsDraftDrawer from '../activity/saveAsDraftDrawer';
// import { format, getTime } from "date-fns";
import IdleTimer from 'react-idle-timer';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
const _formatDate = "YYYY-MM-DDTHH:mm";
const createformat = "YYYY-MM-DD HH:mm:ss";

class CreateLead extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.match.params.id;
        this.dyFormRef = React.createRef();
        this.LeadTitle = this.id ? 'Update Lead' : 'Create Lead'
        this.userData = JSON.parse(localStorage.getItem('persist:root'));
        this.userId = userData();
        this.employeeCode = JSON.parse(this.userData.userReducer).profileInfo.employeeCode;
        this.roleId = JSON.parse(this.userData.userReducer).profileInfo.roleId;
        this.channelID = JSON.parse(this.userData.userReducer).profileInfo.channelid;
        this.idleTimer = null
        this.formName = this.roleId === "FPM" ? FPM_LEAD : BANCA_LEAD;
        this.state = {
            current: {
                id: 1,
                // MeetingEndDateTime: '',
            },
            formData: getForm(this.formName),
            AssignedToName: '',
            AssignedTo: '',
            uploadedFile: '',
            originalFileName: '',
            extErrors: {},
            selectActivityID: "",
            selectProductID: "",
            startMeetingDate: "",
            endMeetingDate: "",
            defaultFormVal: {
                id: 1,
                MeetingStartDateTime: moment(new Date()).format(_formatDate),
                MeetingEndDateTime: moment(new Date()).format(_formatDate),
                ISleadHasMeeting: true
            },
            autoCompleteItems: {
                ActivityID: [],
                ProductID: [],

            },
            collapseStates: {},
            LEAD_BRANCH: [],
            LEAD_LG: [],
            LEAD_SP: [],
            leadDetail: {},
            isActiveMobileDraft: false,
            isFormSUbmitDisabled: false,
            timeOutMsg: false,
            labelForId: {
                ActivityIDLabel: '',
                ProductIDLabel: '',
                BranchIDLabel: '',
                LGIDLabel: '',
                SPIDLabel: '',
                SRMIDLabel: '',
            },
            accordianFieldSet: { fieldset1: false, fieldset2: false, fieldset3: false },
        }
        if (this.id) {
            this.getLeadDetail()
        }
    }

    loadBreadCrumbs = () => {
        const breadcrumbs = [
            { name: 'Leads', url: '/app/lead/all' },
            { name: this.LeadTitle, url: '/createLead' }
        ]
        this.props.setBreadcrumbs(breadcrumbs);
    }



    getLeadDetail = async () => {
        const leadDetails = await this.props.getLeadDetails({ id: this.id })
        const { SalutationID, AlternateNo, Salutation, FirstName, MiddleName, LastName, MobileNo, Email, Location, ProductID, ProductName, ExpectedPremium,
            LeadTypeID, LeadTypeName, ActivityID, BranchID, BranchName, MeetingStartDateTime, MeetingEndDateTime, SPTypeID,
            SPTypeName, LeadSourceName, LeadSourceID, SPID, LGID, Remarks, CreatedDate, CreatedBy, ActivityName } = leadDetails.data; //ISleadHasMeeting
        const ActiveLabel = this.state.autoCompleteItems.ActivityID.filter((Activate) => Activate.id === ActivityID)
        if (BranchID) {
            // const { Branches } = this.props.masterDataState
            // const selectBrannchCode = Branches.find(b => b.value === BranchID)?.key
            this.props.getLGValueReducer(BranchID)
        }
        // ActivityIDlabel: `${ActivityName}-${BranchName}-${moment(MeetingStartDateTime).format('DD/MM/YYYY')}`
        this.setState({
            leadDetail: {
                SalutationID: SalutationID?.toString(), Salutation: Salutation, FirstName: FirstName, MiddleName: MiddleName, LastName: LastName, MobileNo: MobileNo,
                Email: Email, Location: Location,
                ProductID: ProductID ? ProductID.toString() : null,
                ProductName: ProductName,
                ExpectedPremium: ExpectedPremium, LeadTypeID: LeadTypeID?.toString(), LeadTypeName: LeadTypeName,
                ActivityName: ActivityName,
                ActivityID: ActivityID?.toString(), ActivityIDlabel: ActiveLabel[0]?.label,
                // ProductID: ProductID ? ProductID?.toString() : null,
                ProductIDlabel: ProductName ? ProductName : '',
                SPTypeID: SPTypeID?.toString(), SPTypeName: SPTypeName, ISleadHasMeeting: true, LeadSourceID: LeadSourceID?.toString(),
                LeadSourceName: LeadSourceName, BranchID: BranchID?.toString(), BranchName: BranchName, SPID: SPID?.toString(),
                LGID: LGID?.toString(),
                AlternateNo: AlternateNo,
                Remarks: Remarks,
                MeetingStartDateTime: MeetingStartDateTime,
                MeetingEndDateTime: MeetingEndDateTime,
                CreatedDate: CreatedDate, CreatedBy: CreatedBy
            }
        }, () => {
            const formData = this.initFormData(this.state.formData, this.formName)
            this.setState({ formData: formData })
        })
    }

    handleOnAction = (event) => {
        if (this.state.timeOutMsg) {
            setTimeout(() => {
                this.props.history.push("/app/home")
            }, 3000)
        }
    }
    handleOnActive = (event) => {
        if (this.idleTimer.getRemainingTime() === 0) {
            this.setState({
                ...this.state, timeOutMsg: this.idleTimer.getRemainingTime() === 0
            })
        }
    }
    handleOnIdle = (event) => {

    }
    getDropdownData = (optionsArr, id, label) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { "id": `${option[id]}`, "label": `${option[label]}` }
            })
        }
        arr.shift({ "id": " ", "label": "select" })
        arr.unshift({ "id": " ", "label": "select" })
        return arr;
    }

    componentDidMount = async () => {
        this.initMasterData();
        this.loadBreadCrumbs();
        if (this.id) {
            const res = await this.props.getSelectedDraftLeadData(this.id)
            const { AssignedTo, AssignedToName } = res
            this.setState({
                AssignedTo: AssignedTo,
                AssignedToName: AssignedToName
            })
        }

        // this.setState({
        //     autoCompleteItems: this.props.leadFormData.Activities,
        // }, () => {
        //     if (!this.id) {
        //         const formData = this.initFormData(this.state.formData, this.formName)
        //         this.setState({ formData: formData })
        //     }
        // })
        const autoCompleteItems = { ...this.state.autoCompleteItems };
        autoCompleteItems.ProductID = this.getDropdownData(this.props.masterDataState.ProductDetails, "key", "label");
        const FPMActivties = this.props.leadFormData.Activities.ActivityID.filter((item) => item.activitytypeid != 25)
        if (this.roleId === "FPM") {
            autoCompleteItems.ActivityID = FPMActivties
        } else {
            autoCompleteItems.ActivityID = this.props.leadFormData.Activities.ActivityID;
        }
        this.setState({
            autoCompleteItems: autoCompleteItems,
        }, () => {
            if (!this.id) {
                const formData = this.initFormData(this.state.formData, this.formName)
                this.setState({ formData: formData })
            }
        })
    }
    bindLgSpForSelectedBranch = (branchCode) => {
        // it will fetch the LG and SP for given user's branch code
        getLgSpList(branchCode).then((res) => {
            const { lgList, spList, allSPWithoutFilter } = res;
            const formData = [...this.state.formData]
            let LEAD_LG;
            let LIST_SP;
            if (lgList) {
                LEAD_LG = getDropdownData(lgList, 'LGCode', 'FullName', 'LGID');
            }
            if (spList) {
                const LEAD_SP = getDropdownData(spList, 'SPCode', 'FullName', 'SPID');
                // formData[2].columns[0].rows[0][6].options = LEAD_SP;
                LIST_SP = LEAD_SP.slice(1);

                const ALL_SP = getDropdownData(allSPWithoutFilter, 'SPCode', 'FullName', 'SPID');
                formData[2].columns[0].rows[0][6].options = ALL_SP;

            }
            // this changes has been done for the absent of lgsp data

            const mergeLGSP = LEAD_LG.concat(LIST_SP)
            if (mergeLGSP.length <= 1) {
                mergeLGSP.shift();
                mergeLGSP.unshift({ "key": "select", "label": "Data Not Found", "value": "" })
            }
            formData[2].columns[0].rows[0][5].options = mergeLGSP;
            this.setState({ formData: formData });
        });
    }


    bindSRMForSelectedBranch = (branchCode) => {
        getSRMList(branchCode).then((res) => {
            const { SRMlist } = res;
            const formData = [...this.state.formData]
            if (SRMlist) {
                const Srmlist = getDropdownData(SRMlist, 'SRMCode', 'FullName', 'SRMID');
                formData[2].columns[0].rows[0][5].options = Srmlist;
            }
            this.setState({ formData: formData });
        });
    }

    initMasterData = () => {
        if (!this.id) {
            this.props.deActivateLoader()
        }
    }
    onAutocompleteSelectHandler = (key, value, item) => {
        if (key === "ProductID") {
            this.setState({ selectProductID: item.id });
        } else {
            this.setState({ selectActivityID: item.id });
        }
    }
    onSubmitHandler = async (model) => {
        const isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.some(ele => ele.label === model.ActivityIDlabel);
        const isValidProductLabel = this.props.masterDataState.ProductDetails.some(ele => ele.value === model.ProductID);
        let MeetingStartDateTime = moment(model.MeetingStartDateTime).format("YYYY-MM-DD HH:mm:ss");
        let MeetingEndDateTime = moment(model.MeetingEndDateTime).format("YYYY-MM-DD HH:mm:ss")
        if (!checkDateIsValid(MeetingEndDateTime, MeetingStartDateTime)) {
            const extError = { ...this.state.extErrors, "MeetingEndDateTime": "Expected lead closure date should be greater than Meeting start date" }
            this.setState({
                ...this.state,
                extErrors: extError
            })
            return false;
        }
        // if (MeetingStartDateTime.getTime() > MeetingEndDateTime.getTime()) {
        //     extraValidations['MeetingEndDateTime'] = 'Meeting End Time should not less than meeting time'
        // }
        if (!model.isValidForm || Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors) > 0) {

            const errorListClasess = Object.keys(model.errors);
            const selectedErrorClass = errorListClasess[0] === "extError" ? errorListClasess[1] : errorListClasess[0];
            const errorElement = document.getElementsByClassName(`${'control-'}${selectedErrorClass}`)[0];
            if (errorElement) {
                errorElement.scrollIntoView({
                    block: 'center'
                });
                this.props.activateErrorMessageBar("Please fill all required fields");
            }
            if (model.ActivityIDlabel !== '' && isValidActivitiyLabel) {
                delete model.errors.ActivityIDlabel;
            }
            else if (!isValidActivitiyLabel) {
                const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }
                this.setState({
                    ...this.state,
                    extErrors: extError
                })
                return false;
            }

            if (model.ProductIDlabel !== '' && isValidProductLabel) {
                delete model.errors.ProductIDlabel;
            }
            else if (!isValidProductLabel) {
                const extError = { ...this.state.extErrors, 'ProductID': 'Invalid ProductID' }
                this.setState({
                    ...this.state,
                    extErrors: extError
                })
                this.props.activateErrorMessageBar("Please fill all required fields");
                return false;
            }

            if (!model.isValidForm && Object.keys(model.errors).length > 0) {
                return false;
            }
        }
        if (model.ActivityIDlabel !== '' && isValidActivitiyLabel) {
            delete model.errors.ActivityIDlabel;
        }
        else if (!isValidActivitiyLabel) {
            const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }

            this.setState({
                ...this.state,
                extErrors: extError
            })
            return false;
        }

        if (model.ProductIDlabel !== '' && isValidProductLabel) {
            delete model.errors.ProductIDlabel;
        }
        else if (!isValidProductLabel) {
            const extError = { ...this.state.extErrors, 'ProductID': 'Invalid ProductID' }
            this.setState({
                ...this.state,
                extErrors: extError
            })
            return false;
        }
        if (!model.isValidForm && Object.keys(model.errors).length > 0) {
            return false;
        }
        if (
            !model.isValidForm ||
            (Object.keys(this.state.extErrors).length > 0 &&
                this.state.extErrors.MobileNo !== "") ||
            Object.keys(model.errors).length > 0 ||
            !isValidActivitiyLabel
        ) {
            this.props.activateErrorMessageBar(this.state.extErrors.MobileNo ? this.state.extErrors.MobileNo : "Please fill all required fields");
            return false;
        }
        const data = { ...model };
        delete data['advanceLayoutFields'];
        delete data['collapseStates'];
        delete data['errors'];
        delete data['isValidForm'];
        delete data['id'];
        delete data['ActivityIDlabel'];
        data['LeadStatusId'] = 1; //'NEW';
        data['UserId'] = this.userId;
        data['EmployeeCode'] = this.employeeCode;
        data["CreatedBy"] = this.userId;
        data["ModifiedBy"] = this.userId;
        data["AssignedTo"] = this.userId;
        data["ChannelID"] = parseInt(this.channelID);
        if (this.roleId === "FPM") {
            data['LGID'] = null;
            data["SPID"] = null;
        }

        if (this.id) {
            data['IsBacklog'] = 0;
            data['LeadId'] = this.id;

        }

        if (data.ISleadHasMeeting) {
            data["MeetingStartDateTime"] = data["MeetingStartDateTime"].replace("T", ' ')
        } else {
            data["MeetingStartDateTime"] = null;
        }
        if (data.ISleadHasMeeting) {
            data["MeetingEndDateTime"] = minusMinuteFromDate(data["MeetingEndDateTime"]).replace("T", ' ')
        } else {
            data["MeetingEndDateTime"] = null;
        }
        data["ExpectedLeadClosureDate"] = data["MeetingEndDateTime"]

        const { ProductDetails, Salutation } = this.props.masterDataState;
        if (navigator.onLine && data.ISleadHasMeeting) { //(this.state.startMeetingDate || data['MeetingStartDateTime']) && (this.state.endMeetingDate || data['MeetingEndDateTime'])
            const res = await this.props.createLeadDeDupeValidation(data)
            if (res) {
                const validationResponse = await this.props.validateMeetingDateForCreateLead({
                    startDate:
                        this.state.startMeetingDate || data["MeetingStartDateTime"],
                    endDate:
                        this.state.endMeetingDate || data["MeetingEndDateTime"],
                    id: this.userId,
                    formData: data,
                    formBuilder: this.formName,
                    history: this.props.history,
                    leadId: this?.id,
                    leadTypeId: 1
                });
                const { Responsestatus, Responsemessage } = validationResponse.data;
                if (!Responsestatus) {
                    const extError = { ...this.state.extErrors, 'MeetingStartDateTime': 'Meeting already exits.', 'MeetingEndDateTime': 'Meeting already exits.' }
                    this.setState({ extErrors: extError })
                    this.props.activateErrorMessageBar(Responsemessage);
                    this.props.deActivateLoader();
                } else {
                    // lead id is present, so call Update lead
                    if (this.id) {
                        data['AssignedTo'] = this.state.AssignedTo;
                        data['AssignedToName'] = this.state.AssignedToName;
                        this.props.UpdateLeadForm({ model: data, formBuilder: this.formBuilder, history: this.props.history })
                    } else {
                        const leadScoreRequest = {
                            productName: this.getNameForId(ProductDetails, data.ProductID),
                            salutationName: `${this.getNameForId(Salutation, data.SalutationID)}${'.'}`,
                            priorityName: data.PriorityName,
                            lgCode: this.state.labelForId.LGIDLabel.split(' ')[0],
                            branchCode: this.state.labelForId.BranchIDLabel,
                            branchCodeActivity: this.state.labelForId.BranchIDLabel.split(' ')[0],
                            activity: this.state.labelForId.ActivityIDLabel
                        };
                        const result = await this.props.saveLeadForm({ 'data': data, 'formName': this.formName, 'leadScoreRequest': leadScoreRequest })
                        this.handleSaveLeadResponse(result, 'Created');
                    }
                }
            }
        } else {
            const mmData = {
                ...data,
                "CreatedDate": moment(new Date()).format(createformat),
                "CreatedBy": this.userId,
                "ModifiedDate": moment(new Date()).format(createformat),
                "ModifiedBy": this.userId,
                "LmsleadGuid": uuidv4(),
                "LmsleadlastSyncTime": moment(new Date()).format(createformat),//Date.parse(new Date().toUTCString()),
                "IsLmsSync": navigator.onLine,
                "ActivityID": model.ActivityID,
                "LeadStatus": 'New Customer',
                "ProductName": this.getNameForId(ProductDetails, data.ProductID),
                "SalutationName": this.getNameForId(Salutation, data.SalutationID),
                "Remarks": model.Remarks,
                "PriorityID": model?.PriorityID,
                "PriorityName": model.PriorityName ? model.PriorityName : '',
                "IsBacklog": false
            }
            if (this.id) {  // need to add it for save draft
                if (navigator.onLine) {
                    data['IsBacklog'] = 0;
                    data['LeadId'] = this.id;
                    const res = await this.props.createLeadDeDupeValidation(data);
                    if (res) {
                        data['AssignedTo'] = this.state.AssignedTo;
                        data['AssignedToName'] = this.state.AssignedToName;
                        this.props.UpdateLeadForm({ model: data, formBuilder: this.formBuilder, history: this.props.history })
                    }
                }
            } else {
                if (navigator.onLine) {
                    const res = await this.props.createLeadDeDupeValidation(data)
                    const leadScoreRequest = {
                        productName: mmData.ProductName,
                        salutationName: `${mmData.SalutationName}${'.'}`,
                        priorityName: mmData.PriorityName,
                        lgCode: this.state.labelForId.LGIDLabel.split(' ')[0],
                        branchCode: this.state.labelForId.BranchIDLabel,
                        branchCodeActivity: this.state.labelForId.BranchIDLabel.split(' ')[0],
                        activity: this.state.labelForId.ActivityIDLabel
                    };
                    if (res) {
                        this.props.activateLoader();
                        const result = await this.props.saveLeadForm({ 'data': data, 'formName': this.formName, 'leadScoreRequest': leadScoreRequest })
                        this.handleSaveLeadResponse(result, 'Created');
                    }
                }
            }
            if (!navigator.onLine) {
                saveLeadOffline(mmData).then((res) => {
                    if (res) {
                        // const { data } = res

                        const { LeadId, LmsleadGuid } = res.data?.data;
                        if (LeadId) {
                            this.props.history.push(`/app/lead/detail/${LeadId}`);
                        } else {
                            this.props.history.push(`/app/lead/detail/${LmsleadGuid}`);
                        }
                    }
                })
            }
        }
    }

    onSaveDraft = async (e) => {
        const formData = { ...this.dyFormRef.state };
        e.stopPropagation();
        e.preventDefault();
        delete formData['advanceLayoutFields'];
        delete formData['collapseStates'];
        delete formData['errors'];
        delete formData['isValidForm'];
        delete formData['id'];
        delete formData['ActivityIDlabel'];
        if (this.id) {
            formData['LeadId'] = this.id
        }
        // not requured anymore for FPM user
        if (this.roleId === "FPM") {
            formData["LGID"] = null;
            formData["SPID"] = null;
        }
        formData['LeadStatusId'] = 11;
        formData['ChannelID'] = parseInt(this.channelID);
        formData['UserId'] = this.userId;
        formData['AssignedTo'] = this.userId;
        formData['EmployeeCode'] = this.employeeCode;
        if (formData['SalutationID'] === undefined) {
            formData['SalutationID'] = null;
        }
        if (formData['ISleadHasMeeting']) {
            if (formData['MeetingStartDateTime']) {
                formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"].replace("T", ' ')
            } else {
                formData["MeetingStartDateTime"] = null;
            }
            if (formData["MeetingEndDateTime"]) {
                formData["MeetingEndDateTime"] = minusMinuteFromDate(formData["MeetingEndDateTime"]).replace("T", ' ')
            } else {
                formData["MeetingEndDateTime"] = null;
            }
        } else {
            formData["MeetingStartDateTime"] = null;
            formData["MeetingEndDateTime"] = null;
        }
        if (this.roleId === "FPM") {
            formData['LGID'] = null;
            formData["SPID"] = null;
        }
        const { ProductDetails, Salutation } = this.props.masterDataState
        const mmData = {
            ...formData,
            "CreatedDate": moment(new Date()).format(createformat),
            "CreatedBy": this.userId,
            "ModifiedDate": moment(new Date()).format(createformat),
            "ModifiedBy": this.userId,
            "LmsleadGuid": uuidv4(),
            "LmsleadlastSyncTime": moment(new Date()).format(createformat),// Date.parse(new Date().toUTCString()),
            "IsLmsSync": navigator.onLine,
            "ActivityID": formData.ActivityID,
            "LeadStatus": 'Drafted',
            "ProductName": this.getNameForId(ProductDetails, formData.ProductID),
            "SalutationName": this.getNameForId(Salutation, formData.SalutationID),
            "IsBacklog": false,

        }
        if (navigator.onLine) {
            const leadScoreRequest = {
                productName: mmData.ProductName,
                salutationName: `${mmData.SalutationName}${'.'}`,
                priorityName: mmData.PriorityName,
                lgCode: this.state.labelForId.LGIDLabel.split(' ')[0],
                branchCode: this.state.labelForId.BranchIDLabel,
                branchCodeActivity: this.state.labelForId.BranchIDLabel.split(' ')[0],
                activity: this.state.labelForId.ActivityIDLabel
            };
            if (this.id) {
                this.props.UpdateLeadForm({ model: mmData, formBuilder: this.formBuilder, history: this.props.history, Drafted: 'Draft' })
            } else {
                const result = await this.props.saveLeadForm({ 'data': mmData, 'formName': this.formName, 'leadScoreRequest': leadScoreRequest })
                this.handleSaveLeadResponse(result, 'Drafted');
            }
        } else {
            saveLeadOffline(mmData).then((res) => {
                if (res) {
                    this.props.history.push(`/app/lead/all`);
                }
            })
        }
    }

    onChangeHandler = async (event, key, model) => {
        const { formData } = this.state;
        const isValidActivitiyLabel = this.props.leadFormData.Activities.ActivityID.some(ele => ele.id === event.target?.value);
        if (this.id) {
            const details = { ...this.LeadDetailsData, [key]: event?.target?.value }
            this.setState({ leadDetail: details })
        }
        switch (key) {
            case 'ActivityID':
                if (!isValidActivitiyLabel) {
                    const extError = { ...this.state.extErrors, 'ActivityID': 'Invalid ActivityID' }
                    this.setState({
                        ...this.state,
                        extErrors: extError
                    })
                } else {
                    const activityIdLabelList = this.props.leadFormData.Activities.ActivityID.filter(ele => ele.id === event.target?.value)
                    // this.setState({ extErrors: {} })
                    delete this.state.extErrors.ActivityID;
                    this.setState({
                        labelForId: {
                            ...this.state.labelForId,
                            ActivityIDLabel: activityIdLabelList[0]?.label
                        }
                    })
                }
                break
            case 'ProductID':
                if (isNaN(event.target.value)) {
                    const extError = { ...this.state.extErrors, 'ProductID': 'Invalid ProductID.case' }
                    this.setState({ extErrors: extError })
                } else {
                    // this.setState({ extErrors: {} })
                    delete this.state.extErrors.ProductID;
                    delete model.errors.PriorityID;
                    delete model.errors.ProductIDlabel;
                }
                break;
            case 'SPTypeID':
                formData[2].columns[0].rows[0][4].hide = false
                this.setState({
                    defaultFormVal: {
                        ...this.state.defaultFormVal,
                        BranchID: '',
                        LGID: '',
                        SPID: '',
                        SRMID: '',
                    },
                    labelForId: {
                        ...this.state.labelForId,
                        BranchIDLabel: '',
                        LGIDLabel: '',
                        SPIDLabel: '',
                        SRMIDLabel: '',
                    }
                })
                break;
            case 'BranchID':
                const { Branches } = this.props.masterDataState
                const selectBrannchCode = Branches.find(b => b.value === event.target.value)?.key
                isBranchValid(this.state.selectActivityID, selectBrannchCode)

                if (this.roleId === "FPM") {
                    this.bindSRMForSelectedBranch(selectBrannchCode);
                } else {
                    this.bindLgSpForSelectedBranch(selectBrannchCode);
                }

                const branchindex = event.nativeEvent.target.selectedIndex;
                formData[2].columns[0].rows[0][5].hide = false
                formData[2].columns[0].rows[0][6].hide = false
                this.setState({
                    defaultFormVal: {
                        ...this.state.defaultFormVal,
                        BranchID: event.target.value,
                    },
                    labelForId: {
                        ...this.state.labelForId,
                        BranchIDLabel: event.nativeEvent.target[branchindex].text
                    }
                })
                break;
            case 'LGID':
                const lgindex = event.nativeEvent.target.selectedIndex;
                this.setState({
                    defaultFormVal: {
                        ...this.state.defaultFormVal,
                        LGID: event.target.value
                    },
                    labelForId: {
                        ...this.state.labelForId,
                        LGIDLabel: event.nativeEvent.target[lgindex].text
                    }
                })
                break
            case 'SPID':
                const spindex = event.nativeEvent.target.selectedIndex;
                this.setState({
                    defaultFormVal: {
                        ...this.state.defaultFormVal,
                        SPID: event.target.value
                    },
                    labelForId: {
                        ...this.state.labelForId,
                        SPIDLabel: event.nativeEvent.target[spindex].text
                    }
                })
                break
            case 'SRMID':
                const srmindex = event.nativeEvent.target.selectedIndex;
                this.setState({
                    defaultFormVal: {
                        ...this.state.defaultFormVal,
                        SRMID: event.target.value
                    },
                    labelForId: {
                        ...this.state.labelForId,
                        SRMIDLabel: event.nativeEvent.target[srmindex].text
                    }
                })
                break
            case 'MobileNo':
                if (event.target.value.length === 10) {
                    const mobileNo = event.target.value;
                    if (navigator.onLine) {
                        const mobileStatus = await this.props.checkMobileNumberReducer({ mobileNumber: event.target.value, userId: this.userId, formData: formData })
                        if (!mobileStatus) {
                            const extError = { ...this.state.extErrors, 'MobileNo': 'Mobile number already exits.' }
                            this.setState({ extErrors: extError })
                        }
                        else {
                            // this.setState({ extErrors: {} })
                            delete this.state.extErrors.MobileNo;
                        }
                    } else {
                        isLeadValid(mobileNo).then((res) => {
                            if (!res) {
                                const extError = { ...this.state.extErrors, 'MobileNo': 'Mobile number already exits.' }
                                this.setState({ extErrors: extError })
                            }
                            else {
                                // this.setState({ extErrors: {} })
                                delete this.state.extErrors.MobileNo;
                            }
                        })
                    }
                }
                break;
            case 'ISleadHasMeeting':
                if (this.id) {
                    const ISleadHasMeeting = { ...this.state.leadDetail, ISleadHasMeeting: event }
                    this.setState({ leadDetail: ISleadHasMeeting })
                }
                switch (this.roleId) {
                    case "FPM":
                        if (event === true) {
                            formData[2].columns[0].rows[0][7].hide = false;
                            formData[2].columns[0].rows[0][8].hide = false;
                            formData[2].columns[0].rows[0][7].validators = [
                                {
                                    "name": "required",
                                    "value": true,
                                    "validationMessage": ""
                                }
                            ]
                            formData[2].columns[0].rows[0][8].validators = [
                                {
                                    "name": "required",
                                    "value": true,
                                    "validationMessage": ""
                                }
                            ]
                            this.setState({
                                ...this.state,
                                defaultFormVal: {
                                    ...this.state.defaultFormVal, ISleadHasMeeting: true,
                                    MeetingStartDateTime: moment(new Date()).format(_formatDate), MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
                                }
                            })
                        } else {
                            this.setState({
                                ...this.state, startMeetingDate: null, endMeetingDate: null,
                                defaultFormVal: {
                                    ...this.state.defaultFormVal, MeetingEndDateTime: '', MeetingStartDateTime: '', ISleadHasMeeting: false
                                }
                            })
                            this.setState({ extErrors: {} })
                            formData[2].columns[0].rows[0][7].hide = true;
                            formData[2].columns[0].rows[0][8].hide = true;
                            formData[2].columns[0].rows[0][7].validators = ""
                            formData[2].columns[0].rows[0][8].validators = ""
                        }
                        break;
                    case "abc":
                        break;
                    default:
                        if (event === true) {
                            formData[2].columns[0].rows[0][8].hide = false;
                            formData[2].columns[0].rows[0][9].hide = false;
                            formData[2].columns[0].rows[0][8].validators = [
                                {
                                    "name": "required",
                                    "value": true,
                                    "validationMessage": ""
                                }
                            ]
                            formData[2].columns[0].rows[0][9].validators = [
                                {
                                    "name": "required",
                                    "value": true,
                                    "validationMessage": ""
                                }
                            ]
                            this.setState({
                                ...this.state,
                                defaultFormVal: {
                                    ...this.state.defaultFormVal, ISleadHasMeeting: true,
                                    MeetingStartDateTime: moment(new Date()).format(_formatDate), MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
                                }
                            })
                        }
                        // else {
                        //     this.setState({
                        //         ...this.state, startMeetingDate: null, endMeetingDate: null,
                        //         defaultFormVal: {
                        //             ...this.state.defaultFormVal, MeetingEndDateTime: '', MeetingStartDateTime: '', ISleadHasMeeting: false
                        //         }
                        //     })
                        //     this.setState({ extErrors: {} })
                        //     formData[2].columns[0].rows[0][8].hide = true;
                        //     formData[2].columns[0].rows[0][9].hide = true;
                        //     formData[2].columns[0].rows[0][8].validators = ""
                        //     formData[2].columns[0].rows[0][9].validators = ""
                        // }
                        break;
                }
                break;

            case 'MeetingStartDateTime':
                this.setState({ extErrors: { MeetingEndDateTime: null, MeetingStartDateTime: null } })
                delete model.errors['MeetingEndDateTime']

                var startDate = moment(event.target.value).format(_formatDate);
                const endMeetDate = moment(startDate).add(3, 'M').toString();

                formData[2].columns[0].rows[0].map((option) => {
                    if (option.key === "MeetingEndDateTime") {
                        option.max = moment(endMeetDate).format("YYYY-MM-DDTHH:mm");
                        option.min = startDate;
                    }
                })

                if (this.roleId === "FPM") {
                    formData[0].columns[0].rows[0][18].max = moment(endMeetDate).endOf('day').format("YYYY-MM-DDTHH:mm");
                    formData[0].columns[0].rows[0][18].min = startDate;
                }

                if (this.id) {
                    this.setState({
                        ...this.state, extErrors: {},
                        leadDetail: {
                            ...this.state.leadDetail, MeetingStartDateTime: startDate,
                            // MeetingEndDateTime: moment(startDate).format("YYYY-MM-DDTHH:mm:ss") 
                        },
                        startMeetingDate: startDate.replace("T", ' '),
                        //  endMeetingDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss").replace("T", ' '),
                    })
                } else {
                    this.setState({
                        ...this.state, extErrors: {},
                        //  endMeetingDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss").replace("T", ' '),
                        defaultFormVal: {
                            ...this.state.defaultFormVal,
                            // MeetingEndDateTime: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
                            MeetingStartDateTime: event.target.value
                        }
                    })
                    if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
                        this.setState({
                            ...this.state, extErrors: { ...this.state.extErrors, "MeetingStartDateTime": "please select future value" }, defaultFormVal: {
                                ...this.state.defaultFormVal,
                                //  MeetingEndDateTime: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"), 
                                MeetingStartDateTime: event.target.value
                            }
                        })
                    } else {
                        this.setState({
                            ...this.state, extErrors: {}, defaultFormVal: {
                                ...this.state.defaultFormVal,
                                // MeetingEndDateTime: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"), 
                                MeetingStartDateTime: event.target.value
                            }
                        })
                    }
                }
                break;
            case 'MeetingEndDateTime':

                const endDate = event.target.value;
                // if (this.roleId === "FPM") {
                //     formData[2].columns[0].rows[0][8].max = endDate;
                // }

                // this.setState({ endMeetingDate: endDate.replace("T", ' ') })
                this.setState({
                    ...this.state,
                    defaultFormVal: {
                        ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm")
                    }
                })
                if (this.id) {
                    this.setState({
                        ...this.state,
                        leadDetail: {
                            ...this.state.leadDetail, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm")
                        }
                    })
                }
                if (!checkDateIsValid(endDate, this.state.defaultFormVal.MeetingStartDateTime)) {
                    this.setState({
                        ...this.state, extErrors: { ...this.state.extErrors, "MeetingEndDateTime": "Expected lead closure date should be greater than Meeting start date" },
                        defaultFormVal: {
                            ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm")
                        }
                    })
                } else {
                    this.setState({
                        ...this.state, extErrors: {},
                        defaultFormVal: {
                            ...this.state.defaultFormVal, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm")
                        },
                        endMeetingDate: endDate.replace("T", ' ')
                    })
                    if (this.id) {
                        this.setState({
                            ...this.state,
                            leadDetail: {
                                ...this.state.leadDetail, MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm")
                            }
                        })
                    }
                }
                break;
            case 'Location':
                if (this.id) {
                    this.setState({
                        ...this.state, leadDetail: { ...this.state.leadDetail, Location: event }
                    })
                }
                break;
            default:
                break;
        }
    }
    onCheckinHandler = (position, address) => {
        if (this.id) {
            this.setState({
                ...this.state.leadDetail, Location: address
            })
        }
    }



    initFormData = (formFields, actionType) => {
        const formData = [...formFields]
        const { ProductDetails, Salutation, SPType, LeadSource, Branches, PriorityStatus } = this.props.masterDataState;
        const BranchesCopy = [...Branches];
        let arrPriorityStatus = PriorityStatus.slice(0, 4);
        formData[0].columns[0].rows[0][0].options = Salutation;
        const LeadSourceBanca = ["Select", "SELF", "LG", "SRM", "BM", "OTHER", "QRCODE"];

        switch (actionType) {
            case BANCA_LEAD:
                this.setState({ ...this.state, accordianFieldSet: { fieldset1: true, fieldset2: true, fieldset3: true } });
                // formData[1].columns[0].rows[0][0].options = ProductDetails
                formData[2].columns[0].rows[0][0].options = arrPriorityStatus
                formData[2].columns[0].rows[0][7].options = LEAD_HAS_MEETING
                let banca_sp_type = ['SRM'];
                let banca_sp_arr = [];
                if (SPType && SPType.length > 0) {
                    banca_sp_arr = SPType.filter((option) => {
                        if (!banca_sp_type.includes(option.key)) {
                            return option;
                        }
                    });
                }

                formData[2].columns[0].rows[0][3].options = (this.roleId === "BDM" || this.roleId === "ABH") ? banca_sp_arr : SPType;

                let bancaLeadSource = [];

                if (LeadSource && LeadSource.length > 0) {
                    bancaLeadSource = LeadSource.filter((option) => {
                        if (LeadSourceBanca.includes(option.key)) {
                            return option;
                        }
                    });
                }
                // console.log(bancaLeadSource);

                // console.log(LeadSource);
                formData[2].columns[0].rows[0][1].options = bancaLeadSource
                formData[2].columns[0].rows[0][4].options = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
                if (Object.keys(this.state.leadDetail).length > 0) {
                    this.props.deActivateLoader()
                    const { SPTypeID, BranchID, LGID, SPID } = this.state.leadDetail; //ISleadHasMeeting,
                    if (SPTypeID && BranchID) {
                        formData[2].columns[0].rows[0][4].hide = false;
                    }
                    if (BranchID && LGID) {
                        formData[2].columns[0].rows[0][5].hide = false;
                    }
                    if (BranchID && SPID) {
                        formData[2].columns[0].rows[0][6].hide = false;
                    }
                    // if (ISleadHasMeeting) {
                    //     formData[2].columns[0].rows[0][8].hide = false;
                    //     formData[2].columns[0].rows[0][9].hide = false;
                    // } else {
                    //     formData[2].columns[0].rows[0][8].hide = true;
                    //     formData[2].columns[0].rows[0][9].hide = true;
                    // }
                } else {
                    formData[2].columns[0].rows[0][4].hide = true;
                    formData[2].columns[0].rows[0][5].hide = true;
                    formData[2].columns[0].rows[0][6].hide = true;
                    // formData[2].columns[0].rows[0][8].hide = true;
                    // formData[2].columns[0].rows[0][9].hide = true;
                }
                break;
            case BUSSINESS_LEAD:
                formData[5].options = ProductDetails
                formData[14].options = LeadSource
                break;
            case RECRUITMENT_LEAD:
                formData[6].options = ProductDetails
                break;
            case FPM_LEAD:
                this.setState({ ...this.state, accordianFieldSet: { fieldset1: true, fieldset2: true, fieldset3: true } });
                const sp_type = ['STAFF'];
                let filter_arr = [];
                if (SPType && SPType.length > 0) {
                    filter_arr = SPType.filter((option) => {
                        if (!sp_type.includes(option.key)) {
                            return option;
                        }
                    });
                }
                formData[1].columns[0].rows[0][0].options = ProductDetails
                formData[2].columns[0].rows[0][0].options = arrPriorityStatus
                formData[2].columns[0].rows[0][7].options = LEAD_HAS_MEETING
                formData[2].columns[0].rows[0][3].options = filter_arr
                let FPMLeadSource = [];

                if (LeadSource && LeadSource.length > 0) {
                    FPMLeadSource = LeadSource.filter((option) => {
                        if (LeadSourceBanca.includes(option.key)) {
                            return option;
                        }
                    });
                }
                formData[2].columns[0].rows[0][1].options = FPMLeadSource
                formData[2].columns[0].rows[0][4].options = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
                if (Object.keys(this.state.leadDetail).length > 0) {
                    this.props.deActivateLoader()
                    const { SPTypeID, BranchID, SRMID } = this.state.leadDetail; //ISleadHasMeeting
                    if (SPTypeID && BranchID) {
                        formData[2].columns[0].rows[0][4].hide = false;
                    }
                    if (BranchID && SRMID) {
                        formData[2].columns[0].rows[0][5].hide = false;
                    }
                    // if (ISleadHasMeeting) {
                    //     formData[2].columns[0].rows[0][7].hide = false;
                    //     formData[2].columns[0].rows[0][8].hide = false;
                    // } else {
                    //     formData[2].columns[0].rows[0][7].hide = true;
                    //     formData[2].columns[0].rows[0][8].hide = true;
                    // }
                } else {
                    formData[2].columns[0].rows[0][4].hide = true;
                    formData[2].columns[0].rows[0][5].hide = true;
                    // formData[2].columns[0].rows[0][6].hide = true;
                    // formData[2].columns[0].rows[0][7].hide = true;
                    // formData[2].columns[0].rows[0][8].hide = true;
                }
                break;
            default:

                break;
        }
        return formData
    }


    toggleFieldsetAccordian = (collapseId) => {
        this.dyFormRef.toggleCollapse(collapseId);
    }

    renderFieldsetAccordianLabel = (collapseId) => {
        const buttonStyle = {
            float: 'right',

            cursor: 'pointer',
            color: '#000000',
        }

        const accordianClass = this.dyFormRef?.state?.collapseStates[collapseId]
        return (
            <div className="accordHead">
                <span style={buttonStyle} onClick={(e) => this.toggleFieldsetAccordian(collapseId)}>{!accordianClass ? "+" : "-"}</span>
                <strong>
                    {collapseId === 'fieldset1' ? 'Lead Personal Details' : collapseId === 'fieldset2' ? 'Product Details' : collapseId === 'fieldset3' ? 'Business Details' : ''}
                </strong>
            </div>
        );
    }


    onCancelHandler = () => {
        const isMobile = Math.min(window.screen.width, window.screen.height) < 480 || navigator.userAgent.indexOf("Mobi") > -1;
        if (isMobile) {
            this.setState({ isActiveMobileDraft: true })
        }
        else {
            this.props.history.push(LEADS_PATH)
        }
    }

    toggleDrawer = (flag) => {
        this.setState({ isActiveMobileDraft: flag });
    }

    onDontSaveDraftHandler = () => {
        this.props.history.push(LEADS_PATH)
    }

    onSaveDraftHandler = () => {
        this.onSaveDraft(new Event('test'))
    }
    getNameForId = (optionArr, inputId) => {
        const matchEl = optionArr.filter((option) => (option.key === inputId || option.value === inputId) ? option.label : '')
        if (matchEl && matchEl.length > 0) {
            return matchEl[0].label
        }
        return null
    }
    handleSaveLeadResponse = (result, mode) => {
        if (result.status === 200 && result.data.Responsestatus === true) {
            this.props.activateSuccessMessageBar(`Lead has been ${mode} successfully`);
            this.props.deActivateLoader();
            if (mode === 'Created') {
                this.props.history.push(`/app/lead/detail/${result.data.Transactionid}`)
            } else if (mode === 'Drafted') {
                this.props.history.push('/app/lead/all')
            }
        } else {
            this.props.deActivateLoader();
            this.props.activateSuccessMessageBar("Something went wrong! please try again later.");
        }
    }
    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 1}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <Snackbar open={this.state.timeOutMsg} autoHideDuration={3000} >
                    <Alert severity="error" >Your session has been expired! You will be redirected to homepage in 3 seconds.</Alert>
                </Snackbar>
                <div className="mainContainer createLead">
                    <MessageBar />
                    <Breadcrumb />
                    <span className="formInfo">{t('Please fill in the lead information')}</span>
                    <div className="formBox">
                        <DynamicForm
                            key={1}
                            onRef={ref => this.dyFormRef = ref}
                            className="dynamicForm"
                            buttonSubmit={t('Submit')}
                            defaultValues={this.id ? this.state.leadDetail : this.state.defaultFormVal}
                            model={this.state.formData}
                            onSubmit={(model) => this.onSubmitHandler(model)}
                            disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
                            design="advanceBootstrap"
                            materialOutline={false}
                            onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
                            onAutocompleteSelect={this.onAutocompleteSelectHandler}
                            autoCompleteItems={this.state.autoCompleteItems}
                            customValidators={customValidators}
                            showAstersikOnRequiredFields={true}
                            extErrors={this.state.extErrors}
                            checkInConfig={checkInConfig}
                            onCheckinHandler={this.onCheckinHandler}
                            renderFieldsetAccordianLabel={this.renderFieldsetAccordianLabel}
                            defaultAccordianOpenStates={this.state.accordianFieldSet}
                            extaraActionFormButton={<>
                                <a className="savedraftLink" onClick={(e) => this.onSaveDraft(e)}>SAVE AS DRAFT</a>
                                <button color="indigo" className="btn cancelBtn" key="extra" id="extra" type="button" onClick={(e) => this.onCancelHandler(e)}>Cancel</button>

                            </>}
                        />
                    </div>
                    <Footer />
                    {this.props.masterDataState.isActiveLoader && <Loader />}
                    <SaveAsDraftDrawer
                        isActiveMobileDraft={this.state.isActiveMobileDraft}
                        toggleDrawer={this.toggleDrawer}
                        onDontSaveHandler={this.onDontSaveDraftHandler}
                        onSaveDraftHandler={this.onSaveDraftHandler}
                    ></SaveAsDraftDrawer>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    leadFormData: state.leadFormReducer,
    masterDataState: state.commonReducer
});
const mapDispatchToProps = dispatch => ({
    // getAutoCompleteActivities: (payload) => dispatch(getAutoCompleteActivities(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    validateMeetingDateStatusInCreate: (payload) => dispatch(validateMeetingDateStatusInCreate(payload)),
    validateMeetingDateForCreateLead: (payload) => dispatch(validateMeetingDateForCreateLead(payload)),
    saveLeadForm: (payload) => dispatch(saveLeadForm(payload)),
    getLGValueReducer: (payload) => dispatch(getLGValueReducer(payload)),
    activateLoader: (payload) => dispatch(activateLoader(payload)),
    deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
    activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
    checkMobileNumberReducer: (payload) => dispatch(checkMobileNumberReducer(payload)),
    getLeadDetails: (payload) => dispatch(getLeadDetails(payload)),
    UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
    createLeadDeDupeValidation: (payload) => dispatch(createLeadDeDupeValidation(payload)),
    getSelectedDraftLeadData: (payload) => dispatch(getSelectedDraftLeadData(payload))
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(CreateLead));
