import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import useQuery from "../../../hooks/useQuery";
import { ssoAuthValidateUserData } from "../../../redux/actions/dashboardAction";
import { useHistory } from "react-router-dom";

const SsoAuth = () => {
  const params = useQuery();
  const token = params.get("token");
  const email = params.get("emailAddress");
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token && email) {
      setIsLoading(true);
      validateSSOdata(token, email);
    } else {
      setIsLoading(false);
      console.log("required params not found");
    }
  }, [token, email]);
  const validateSSOdata = async (token, email) => {
    const request = {
      authToken: token,
      email: email,
    };
    const result = await ssoAuthValidateUserData(request);
    if (result.status === 200) {
      const { data } = result;
      setIsLoading(false);
      if (data.channelid) {
        history.push("/app/home");
      } else {
        setIsSuccess(false);
        console.log("ssoAuthValidateUserData failed ", result.data);
      }
    }
  };
  if (isLoading) {
    return (
      <>
        <Typography variant="h4">Validating, Please wait....</Typography>
        <Typography variant="h6">
          Do not press back button or refresh
        </Typography>
      </>
    );
  }
  return (
    <div>
      {!isLoading && !isSuccess && (
        <>
          <Typography variant="h4">Authentication failed....</Typography>
        </>
      )}
    </div>
  );
};

export default SsoAuth;
