import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { activateErrorMessageBar, getSubordinateList } from "../../../redux/actions";
import { customValidators, checkListFilterForm } from "./checkListFilterForm";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawerMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block"
        },
        [theme.breakpoints.down('sm')]: {
            display: "block"
        }
    },
    desktopFilter: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
}));

const ChecklistFilter = ({ getFilteredList, getAllLeads, parent, handleClose, filterState, setFilterState, setFilterDisabled, setSelectedFilter, anchor,
    setfilterApplied }) => {
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const userName = `${JSON.parse(userData.userReducer).profileInfo.firstName
        } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
    const userState = useSelector(userprofile => userprofile.userReducer);
    const roleId = userState?.profileInfo?.roleId
    const { CheckListStatus, CheckListType } = useSelector((ChecklistData) => ChecklistData.commonReducer)

    const [state, setState] = useState({
        current: { id: 1 },
        formData: checkListFilterForm,
    });

    const dynamicRef = React.useRef();




    useEffect(() => {
        // console.log(setfilterApplied)
        getSub()
        const formData = initFormData(state.formData);
        setState({ formData: formData });
    }, []);

    const getSub = async () => {
        const formData = [...checkListFilterForm]
        dispatch(getSubordinateList({ userId: userState.profileInfo.userId })).then((response) => {
            const r = response.data.map(el => {
                return {
                    key: el.userID,
                    label: el.userName,
                    value: el.userID
                }
            });
            r.unshift({ "id": "", "label": "select", "value": "" })
            formData[0].options = [...r];
            formData[1].options = CheckListType;
            formData[2].options = CheckListStatus;
        })
    }
    const onSubmitHandler = (model) => {
        if (model.BDMUserId === null || model.BDMUserId === 0 || model.BDMUserId === '') {
            model.BDMUserId = ''
        }
        if (model.Status === null || model.Status === '' || model.Status === '') {
            model.Status = ""
        }
        if (model.CheckListType === null || model.CheckListType === 0 || model.CheckListType === '') {
            model.CheckListType = 0
        }
        const { BDMUserId, Status} = model
        console.log(model);
        if (BDMUserId !== 0 || Status !== "" || model.CheckListType !== 0) {
            setFilterState({
                ...filterState,
                BDMUserId: BDMUserId ? BDMUserId : '',
                CheckListType: model.CheckListType ? model.CheckListType : 0,
                Status: Status ? Status : '',
            })
            getFilteredList(model)
            handleClose();
        } else {
            dispatch(activateErrorMessageBar('Please select anyone to apply filter'))
        }
    };

    const onResetHandler = () => {
        let payload = {}
        if (roleId === "ABH") {
            payload = {
                BDMUserId: '',
                Status: '',
                CheckListType: 0,
            }
        }
        if (roleId === "RBH") {
            payload = {
                BDMUserId: '',
                Status: '',
                CheckListType: 0,
            }
        }

        setFilterState({
            BDMUserId: '',
                Status: '',
                CheckListType: 0,
        })
        getFilteredList(payload)
        handleClose();
    };
    const onChangeHandler = (e, key) => {
        setfilterApplied(false)
        if (key === 'BDMUserId') {
            setFilterState({
                ...filterState,
                BDMUserId: e.target.value
            })
        }
        if (key === 'CheckListType') {
            setFilterState({
                ...filterState,
                CheckListType: e.target.value
            })
        }
        if (key === 'Status') {
            setFilterState({
                ...filterState,
                Status: e.target.value
            })
        }
    };

    const onAutocompleteSelectHandler = (key, value, item) => {

    }

    const initFormData = (formFields) => {
        const formData = [...formFields];
        //  formData[0].options = USER_TYPE
        setFilterState({ ...filterState, UserName: userName });
        return formData;
    };
    const classes = useStyles();
    return (
        <div className={anchor ? classes.drawerMobile : classes.desktopFilter}>
            <div className="filterBox">
                <h4 className="filterSec">
                    {" "}
                    <FilterAltIcon className="icnFilter" /> Filter By <CloseIcon className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
                </h4>
                <div className="fformBox">
                    <DynamicForm
                        key={1}
                        ref={dynamicRef}
                        className="dynamicForm leadFilters  leadFiltersMobile"
                        title={" "}
                        buttonSubmit={t("Apply Filter")}
                        defaultValues={filterState}
                        customValidators={customValidators}
                        model={state.formData}
                        onSubmit={(model) => onSubmitHandler(model)}
                        disableSubmitOnInValid={false}
                        design="bootrstap"
                        materialOutline={false}
                        onChange={(event, key) => onChangeHandler(event, key)}
                        onAutocompleteSelect={onAutocompleteSelectHandler}
                        //  autoCompleteItems={state.autoCompleteItems}
                        showAstersikOnRequiredFields={false}

                        extaraActionFormButton={
                            <span className="reset" onClick={onResetHandler} ><RestartAltIcon /> Reset</span>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(ChecklistFilter);
