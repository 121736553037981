import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
  key: "root",
  storage,
};

const configureStore = () => {
  const middlewares = [thunk];
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  return createStore(persistedReducer, enhancer);
};

export const persistor = persistStore(configureStore());

export default configureStore;
