export const CSCFinishDayForm = [
    { "key": "IsClaimRequired", "name": "IsClaimRequired", "type": "CustomSwitch", "id":"IsClaimRequired", "label": "Do you want to Claim Travel Expense?", handleDiameter:45,
      offColor:"#00427E",
      onColor:"#FFFFFF",
      offHandleColor:"#FFFFFF",
      onHandleColor:"#00427E",
      className: "radioBoxDiv",
      height:26,
      width:75,
      borderRadius:24,
      border:"1px solid #ddd",
      activeBoxShadow:"0px 0px 1px 2px #fffc35",
      uncheckedIcon:<div className="switchUnChecked">Yes</div> ,
      checkedIcon:<div className="switchChecked">No</div>
    }]


    export const CSCExpenceClaimForm = [
        { "key": "TransportModeID", "type": "radio", "label": "Mode of Transport",
          "validators":[{"name":"required", "value":true, "validationMessage": "Mode of transport is required."}], "options": []
        },
      ]
      export const CSCPublicTransportForm = [
        { "key": "TravelExpence", "hide": false, "type": "number", "disabled":false, "id":"TravelExpence", "label": "Travel Expense",
         "validators":[
           {"name":"required", "value":true, "validationMessage": "Travel expense is required."},
           {"name":"maxLength", "value":4, "validationMessage": "Max 4 digits allowed"}]
        },
        {
          "key": "UploadBill", "hide": false, "type": "file", "dropTitle": "Upload bill photo", "acceptFileTypes": ["image/jpeg", "image/jpg", "image/png", "application/pdf"],
         "componentType": "DRAG_DROP_BROWSE", "layout": "1column", "restrictMultipleFiles": true,
         "disabled":false, "id":"billPhoto", "label": "Please upload bill copy", "validators":[]},
      ]
      export const CSCSelfOwnedTransportForm = [
        { "key": "VehicleTypeID", "type": "radio", "label": "Vehicle Type",
          "validators":[
            {"name":"required", "value":true, "validationMessage": "Vehicle type is required."}], "options": []
          }
]
