import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
//import { getOptionById} from "../../pages/postlogin/activity/helper";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import { useHistory } from "react-router-dom";

import React, { useEffect} from "react"
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import { isUserSame } from "../../utils/utils";


export default function DraftCard({ draft, isChecked, index, handleCheckboxChange, handleClickOpen, setSelectDraftDelete, UserId }) {
    const history = useHistory();
    let location = useLocation();
    const isSMARoute = location.pathname.includes("smaActivities");
    const { ActivityName, ActivityIdName, CreatedDate, TrnActivityID, CreatedBy } = draft;
    const takeWordFirstLetter = (name) => {
        const str = []
        if (name) {
            const strings = name.split(" ")
            strings.map(el => {
                if (el.charAt(0) !== '(' && el.charAt(0) === el.charAt(0).toUpperCase()) {
                    str.push(el.charAt(0))
                }
            })
        }
        return str.toString().replaceAll(',', '')
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(3),
            textAlign: "left",
            color: theme.palette.text.secondary,
        },
        activityLogo: {
            borderRadius: 10,
            textAlign: "center",
        },
        heading: {
            padding: "10px 20px 20px 10px",
            fontWeight: "bold",
        },
        subHeading: {
            fontSize: 14,
            fontWeight: "400",
            padding: "0 5px 5px 0!important",
        },
        subHeadingBold: {
            fontWeight: "bold",
        },
    }));
    const classes = useStyles();

    //const commonState = useSelector(state => state);

    // const [branchNameData, setbranchNameData] = useState("");
    // const [branchCodeData, setbranchCodeData] = useState("");
    useEffect(() => {
        //const branchName = getOptionById(BranchId?.toString(), commonState.commonReducer?.Branches)
        // if (branchName) {
        //     setbranchNameData(branchName.label)
        //     setbranchCodeData(branchName.key)
        // }
    }, []);

    const draftRedirect = () => {
        if (isUserSame(CreatedBy, UserId)) {
            isSMARoute ? history.push(`/app/activity/smaActivities/drafted/${TrnActivityID}`) : history.push(`/app/activity/drafted/${TrnActivityID}`)
        }
    }
    return (<>
        <Paper className={classes.paper}>
            <Grid
                container
                style={{ position: "relative" }}
            >
                <Grid
                    item
                    xs={1}
                    md={1}
                    className="checkBoxCol"
                    alignItems="center"
                >
                    <Checkbox checked={isChecked[index] || false} onChange={() => {
                        handleCheckboxChange(index);
                    }} />
                </Grid>
                <Grid item xs={2} md={2} className={`gridLogo`} onClick={draftRedirect} >
                    <Typography
                        variant="h5"
                        component="h2"
                        className="boxName"
                    >
                        {takeWordFirstLetter(ActivityIdName)}{" "}
                    </Typography>
                </Grid>
                <Grid item xs={9} md={9}>
                    <Grid container className="cardTop" onClick={draftRedirect}>
                        <Grid className="createDiv" item xs={12}>
                            <Typography variant="h5" component="h2" className={classes.heading}>
                                <b className="mobHeader">{ActivityIdName}</b>
                                <span className="branchName">
                                    {ActivityName}
                                </span>
                            </Typography>
                            <span className="icn rightIcn" onClick={draftRedirect}>{isUserSame(CreatedBy, UserId) && <><ArrowRightAltIcon /></>}</span>
                            <div className={classes.subHeading}>
                                <Grid container className="labelGroup">
                                    <Grid item xs={12} md={11} className={classes.subHeading}>
                                    {isUserSame(CreatedBy, UserId) && <> <span className="delBG" onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleClickOpen(); setSelectDraftDelete({ TrnActivityID, index }) }}><DeleteIcon /></span></>}
                                       
                                        <label>Created Date</label><br />
                                        <span
                                            className={classes.subHeadingBold}>
                                            {CreatedDate ? moment(CreatedDate).format('DD MMM YYYY') : ""}
                                        </span>
                                        <span className="timeStamp">
                                            {CreatedDate ? moment(CreatedDate).format('h:mma') : ""}
                                        </span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    </>)
}
