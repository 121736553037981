/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-24 14:20:48
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */

import React, { useState, useEffect } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DynamicForm from "f1-dynamic-form-react";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { t } from "i18next";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { activateErrorMessageBar, getSubordinateList } from "../../../../redux/actions";
import { campaignLeadBacklogFilterForm, customValidators } from "./campaignLeadBacklogFilterForm"
import { ROLE_ABH, ROLE_BDM, ROLE_FPM, USER_TYPE } from "../../../../utils/config";
import { getDropdownDataBranch } from "../../../../utils/utils";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    drawerMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block"
        },
        [theme.breakpoints.down('sm')]: {
            display: "block"
        }
    },
    desktopFilter: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    }
}));

const CampaignLeadsBacklogFilter = ({ getFilteredBacklogList, getAllBacklogList, parent, handleClose, filterState, setFilterState, setSelectedFilter, anchor, setbacklogLeadReq }) => {
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const userState = useSelector(mainState => mainState.userReducer);
    const UserId = userState?.profileInfo?.userId
    const channelId = userState?.profileInfo?.channelid
    const FullName = `${userState?.profileInfo?.firstName} ${userState?.profileInfo?.lastName}`;
    const [UserFullName, setUserFullName] = useState(FullName);
    const meetingDateFormate = "YYYY-MM-DD";
    const roleId = userState?.profileInfo?.roleId
    const userName = `${JSON.parse(userData.userReducer).profileInfo.firstName
        } ${" "}${JSON.parse(userData.userReducer).profileInfo.lastName}`;
    const {
        leadStatus,
        CampaignActivities,
        getCampaignNames
    } = useSelector((nextState) => nextState.leadFormReducer);
    const commonState = useSelector(select => select);
    const { GetBDMByABH } = commonState.commonReducer
    const [newUserList, setNewUserList] = useState([{
        key: UserId,
        label: UserFullName,
        value: UserId
    }])

    const { ProductDetails, Branches } = useSelector((branchState) => branchState.commonReducer)
    const [state, setState] = useState({
        current: { id: 1 },
        formData: campaignLeadBacklogFilterForm,
        autoCompleteItems: {
            product: [],
            activityID: []
        },
    });

    const getDropdownOFData = (optionsArr, id, label) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { "id": `${option[id]}`, "label": `${option[label]}` }
            })
        }
        arr.shift({ "id": " ", "label": "select" })
        arr.unshift({ "id": " ", "label": "select" })
        return arr;
    }

    const dynamicRef = React.useRef();
    useEffect(() => {
        const autoCompleteItems = { ...state.autoCompleteItems };
        autoCompleteItems.product = getDropdownOFData(ProductDetails, "key", "label");
        const campaignActivityData = getDropdownData(
            CampaignActivities.ActivityID,
            "id",
            "label",
            "id"
        );
        const BranchesCopy = [...Branches];
        autoCompleteItems.branchNameCode = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
        autoCompleteItems.activityID = campaignActivityData.slice(1)
        const formData = initFormData(state.formData);
        setState({ formData: formData, autoCompleteItems: autoCompleteItems });

    }, []);

    useEffect(() => {
        if (roleId === ROLE_ABH) {
            state.formData[0].hide = false
        }
    }, [roleId])

    useEffect(() => {
        // console.log(UserFullName)
        if (roleId !== ROLE_BDM && roleId !== ROLE_FPM) {
            getSub()
        }
        setFilterState({
            ...filterState,
            UserName: UserFullName,
            ExpectedLeadClosureDate: "null"
        })
    }, [UserFullName]);

    const getSub = async () => {
        const formData = [...state.formData]
        dispatch(getSubordinateList({ userId: userState.profileInfo.userId })).then((response) => {
            const r = response.data.map(el => {
                return {
                    key: el.userID,
                    label: el.userName,
                    value: el.userID
                }
            });
            setNewUserList([...newUserList, ...r])
            formData[1].options = [...newUserList, ...r];

            if (GetBDMByABH !== 'all') {
                const res = response.data.filter((el) => {
                    if (GetBDMByABH == el.userID)
                        return true;
                });
                setUserFullName(res[0].userName)
                formData[0].hide = true;
                formData[1].hide = true;
                formData[2].hide = false;
            }
        })
    }
    const onSubmitHandler = (model) => {
        const branchNameValue = [...Branches].some((el) => el.value === model.branchNameCode)
        if (model.LeadName === "") {
            model.LeadName = null
        }
        if (model.CreatedDateTime === 0) {
            model.CreatedDateTime = null
        }
        if (model.MeetingDate === 0) {
            model.MeetingDate = null
        }
        if (model.MobileNo === "") {
            model.MobileNo = null
        }


        if (model.teamMember === 0 || model.teamMember === undefined) {
            model.teamMember = null
        }
        if (model.CreatedDateStartTIme === "null") {
            model.CreatedDateStartTIme = null
        }
        if (model.CreatedDateEndTIme === "null") {
            model.CreatedDateEndTIme = null
        }
        if (model.fromdate === "null") {
            model.fromdate = null
        }
        if (model.todate === "null") {
            model.todate = null
        }

        if (model.Createenddate != 'null' && model.Createenddate !== undefined) {
            model.CreatedDateEndTIme = model.Createenddate
        }

        if (model.Createstartdate != 'null' && model.Createstartdate !== undefined) {
            model.CreatedDateStartTIme = model.Createstartdate
        }
        if (model.CampaignName === 'null' || model.CampaignName === "") {
            model.CampaignName = null
        }
        if (model.ExpectedLeadClosureDate == "null" && model.ExpectedLeadClosureDate == undefined) {
            model.ExpectedLeadClosureDate = model.ExpectedLeadClosureDate ? model.ExpectedLeadClosureDate : "null";
        }
        const status = model.CampgnBackCurrentStatus?.map((val) => {
            return val.value;
        })
        model.CurrentStatus = status?.join(',');
        if (model.CurrentStatus === "" && model.CurrentStatus !== undefined) {
            model.CurrentStatus = null
        }
        const { CreatedDateEndTIme, LeadName, CreatedDateTime, CreatedDateStartTIme,
            MeetingOn, CurrentStatus, product, activityID, MeetingDate, fromdate,
            todate, MobileNo, teamMember, branchNameCode, CampaignName,ExpectedLeadClosureDate } = model

        let meetingstart = null;
        let meetingend = null;
        let createdstart = null;
        let createdend = null;
        if (MeetingDate && MeetingDate !== null && MeetingDate !== 'duration' && MeetingDate !== undefined) {
            const lastdatearr = model.MeetingDate.split(',');
            meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
            meetingend = lastdatearr[1] ? lastdatearr[1] : null;
        }
        if (CreatedDateTime && CreatedDateTime !== null && CreatedDateTime !== 'duration' && CreatedDateTime !== undefined) {
            const lastdatearr = model.CreatedDateTime.split(',');
            createdstart = lastdatearr[0] ? lastdatearr[0] : null;
            createdend = lastdatearr[1] ? lastdatearr[1] : null;
        }
        if (MeetingDate && MeetingDate === 'duration') {
            if (fromdate === null || todate === null) {
                dispatch(activateErrorMessageBar('Please Select Value For Both Meeting Start Date and End Date'))
                return false
            }
            meetingstart = model.fromdate;
            meetingend = model.todate
        }
        if (CreatedDateTime && CreatedDateTime === 'duration') {
            if (CreatedDateStartTIme === null || CreatedDateEndTIme === null) {
                dispatch(activateErrorMessageBar('Please Select Value For Both Created Start Date and End Date'))
                return false
            }
            createdstart = model.CreatedDateStartTIme;
            createdend = model.CreatedDateEndTIme
        }
        model.fromdate = meetingstart;
        model.todate = meetingend;

        model.CreatedDateStartTIme = createdstart;
        model.CreatedDateEndTIme = createdend;
        if (CreatedDateTime !== null || LeadName !== null || product !== 0 || MeetingOn !== null || CurrentStatus !== null || activityID !== 0 || MeetingDate !== null || MobileNo !== null || fromdate !== null || todate !== null || teamMember !== null || branchNameCode !== null) {
            const payload = {
                LeadName: LeadName,
                CreatedDateTime: CreatedDateTime,
                CreatedDateStartTIme: CreatedDateStartTIme,
                CreatedDateEndTIme: CreatedDateEndTIme,
                MeetingOn: null,
                LastUpdateDateTime: null,
                CurrentStatus: CurrentStatus,
                product: product,
                activityID: activityID,
                MeetingDate: MeetingDate,
                fromdate: fromdate,
                todate: todate,
                MobileNo: MobileNo,
                CampaignName: CampaignName,
                ExpectedLeadClosureDate:ExpectedLeadClosureDate,
            }
            setFilterState({ ...filterState, payload })
            setbacklogLeadReq(payload)
            getFilteredBacklogList(model, teamMember, branchNameValue)
            handleClose();
        } else {
            dispatch(activateErrorMessageBar('Please select anyone to apply filter'))
        }
    };
    const onResetHandler = () => {
        let teamMember = '';
        let payload = {
            LeadID: null,
            Leadname: "",
            CreatedDateTime: "null",
            activityIDlabel: "",
            Createddate: null,
            CurrentStatus: "1,2,3,4,5,6,7,8,9,10",
            Createstartdate: null,
            Createenddate: null,
            CampaignName: "",
            productlabel: "",
            MeetingDate: "null",
            MeetingEnddate: null,
            IsBacklog: true,
            MobileNo: "",
            branchNameCodelabel: "",
            branchNameCode: null,
            product: "",
            branchNameValue: null,
            ProductID: null,
            activityID: null,
            ExpectedLeadClosureDate: "null"
        };
        const reqPayload = {
            Noofdays: 0,
            LeadtypeID: null,
            Leadname: null,
            ActivityID: null,
            CreatedBy: UserId.toString(),
            Createddate: null,
            CurrentStatus: "1,2,3,4,5,6,7,8,9,10",
            MeetingDate: null,
            Modifieddate: null,
            LeadSourceID: null,
            ProductID: null,
            SPTypeID: null,
            BranchID: null,
            MeetingStartdate: null,
            MeetingEnddate: null,
            IsBacklog: true,
            ExpectedLeadClosureDate: null
        };
        setFilterState(payload)
        setbacklogLeadReq(reqPayload)
        campaignLeadBacklogFilterForm[1].hide = true
        campaignLeadBacklogFilterForm[2].hide = false
        getAllBacklogList(payload, teamMember, reqPayload)
    };
    const onChangeHandler = (e, key) => {
        if (key === 'LeadName') {
            setFilterState({
                ...filterState,
                LeadName: e.target.value
            })
        }
        if (key === 'MobileNo') {
            setFilterState({
                ...filterState,
                MobileNo: e.target.value
            })
        }
        if (key === 'CreatedDateTime') {
            setFilterState({
                ...filterState,
                CreatedDateTime: e.target.value
            })
            if (e.target.value === 'duration') {
                campaignLeadBacklogFilterForm[5].hide = false;
                campaignLeadBacklogFilterForm[6].hide = false;
                // console.log(moment(new Date()).format(meetingDateFormate))
                setFilterState({
                    ...filterState,
                    CreatedDateTime: e.target.value,
                    CreatedDateStartTIme: moment(new Date()).format(meetingDateFormate),
                    CreatedDateEndTIme: moment(new Date()).format(meetingDateFormate),
                });
            } else {
                campaignLeadBacklogFilterForm[5].hide = true;
                campaignLeadBacklogFilterForm[6].hide = true;
                setFilterState({
                    ...filterState,
                    CreatedDateTime: e.target.value,
                    CreatedDateStartTIme: "null",
                    CreatedDateEndTIme: "null",
                });
            }
        }
        if (key === 'CreatedDateStartTIme') {
            setFilterState({
                ...filterState,
                CreatedDateStartTIme: e.target.value
            })
            campaignLeadBacklogFilterForm[6].min = e.target.value

        }
        if (key === 'CreatedDateEndTIme') {
            setFilterState({
                ...filterState,
                CreatedDateEndTIme: e.target.value
            })
        }
        if (key === 'CurrentStatus') {
            setFilterState({
                ...filterState,
                CurrentStatus: e.target.value
            })
        }
        if (key === 'MeetingDate') {
            setFilterState({
                ...filterState,
                MeetingDate: e.target.value
            })
            if (e.target.value === 'duration') {
                campaignLeadBacklogFilterForm[9].hide = false;
                campaignLeadBacklogFilterForm[10].hide = false;
                setFilterState({
                    ...filterState,
                    MeetingDate: e.target.value,
                    fromdate: moment(new Date()).format(meetingDateFormate),
                    todate: moment(new Date()).format(meetingDateFormate),
                });
            } else {
                campaignLeadBacklogFilterForm[9].hide = true;
                campaignLeadBacklogFilterForm[10].hide = true;
                setFilterState({
                    ...filterState,
                    MeetingDate: e.target.value,
                    fromdate: "null",
                    todate: "null",
                });
            }
        }
        if (key === 'LastUpdateDateTime') {
            setFilterState({
                ...filterState,
                LastUpdateDateTime: e.target.value
            })
        }
        if (key === 'product') {
            const branchNameValue = ProductDetails.filter((el) => el.key === parseInt(e.target.value))
            setFilterState({
                ...filterState,
                product: e.target.value,
                productlabel: branchNameValue[0]?.label
            })
        }
        // if (key === 'MeetingDate') {
        //     setFilterState({
        //         ...filterState,
        //         MeetingDate: e.target.value
        //     })
        //     if (e.target.value === 'duration') {
        //         campaignLeadBacklogFilterForm[9].hide = false;
        //         campaignLeadBacklogFilterForm[10].hide = false;
        //     } else {
        //         campaignLeadBacklogFilterForm[9].hide = true;
        //         campaignLeadBacklogFilterForm[10].hide = true;
        //     }
        // }
        if (key === 'fromdate') {
            const fromDate = e.target.value
            setFilterState({
                ...filterState,
                fromdate: fromDate
            })
            campaignLeadBacklogFilterForm[10].min = e.target.value
        }
        if (key === 'todate') {
            const todate = e.target.value
            setFilterState({
                ...filterState,
                todate: todate
            })
        }
        if (key === "userType") {
            const getUserType = e.target.value
            if (getUserType === "team") {
                campaignLeadBacklogFilterForm[1].hide = false
                campaignLeadBacklogFilterForm[2].hide = true
                // dispatch({
                //     type: "viewFor",
                //     payload: ""
                // })
                setSelectedFilter({
                    roleId: "ABH",
                    viewFor: "BDM"
                })
            } else {
                campaignLeadBacklogFilterForm[1].hide = true
                campaignLeadBacklogFilterForm[2].hide = false
                setSelectedFilter({
                    roleId: "ABH",
                    viewFor: "SELF"
                })
            }
        }
        if (key === 'ExpectedLeadClosureDate') {
            setFilterState({
                ...filterState,
                ExpectedLeadClosureDate: e.target.value
            })
        }
    };
    const getDropdownData = (optionsArr, key, label, value) => {
        let arr = [];
        if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
                return { key: option[key], label: option[label], value: option[value] };
            });
        }
        arr.unshift({ key: "select", label: "Select", value: "" });
        return arr;
    };

    const campaign_status = [14, 13, 12, 11, 9];

    let filter_arr = [];
    if (leadStatus && leadStatus.length > 0) {
        filter_arr = leadStatus.filter((option) => {
            if (!campaign_status.includes(option.LeadStatusId)) {
                return option;
            }
            return []
        });
    }
    let LEAD_STATUS = getDropdownData(
        filter_arr,
        "LeadStatus",
        "LeadStatus",
        "LeadStatusId"
    );

    if (channelId == 1) {
        LEAD_STATUS = LEAD_STATUS.filter(el => el.value != 9 && el.value != 11 && el.value != 12 && el.value != 13 && el.value != 14)
    }

    const CAMPAIGN_NAMES = getDropdownData(
        getCampaignNames,
        "CampaignNameID",
        "CampaignName",
        "CampaignNameID"
    )

    const onAutocompleteSelectHandler = (key, value, item) => {
        if (key === "activityID") {
            setFilterState({
                activityID: item.value
            })
        }
        if (key === "branchNameCode") {
            setFilterState({
                branchNameCode: item.value
            })
        }
    }

    const initFormData = (formFields) => {
        const formData = [...formFields];
        // console.log("formdata", formData);
        formData[0].options = USER_TYPE
        formData[15].hide = false;
        formData[2].disabled = true;
        setFilterState({ ...filterState, UserName: userName });
        formData[12].options = ProductDetails;
        formData[7].options = LEAD_STATUS;
        formData[15].options = CAMPAIGN_NAMES;
        if (channelId == 1) {
            formData[16].hide = false
        }
        return formData;
    };
    const classes = useStyles();

    return (
        <div className={anchor ? classes.drawerMobile : classes.desktopFilter}>
            <div className="filterBox">
                <h4 className="filterSec">
                    {" "}
                    <FilterAltIcon className="icnFilter" /> Filter By <CloseIcon className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
                </h4>
                <div className="fformBox">
                    <DynamicForm
                        key={1}
                        ref={dynamicRef}
                        className="dynamicForm leadFilters  leadFiltersMobile"
                        title={" "}
                        buttonSubmit={t("Apply Filter")}
                        defaultValues={filterState}
                        customValidators={customValidators}
                        model={state.formData}
                        onSubmit={(model) => onSubmitHandler(model)}
                        disableSubmitOnInValid={false}
                        design="bootrstap"
                        materialOutline={false}
                        onChange={(event, key) => onChangeHandler(event, key)}
                        onAutocompleteSelect={onAutocompleteSelectHandler}
                        autoCompleteItems={state.autoCompleteItems}
                        showAstersikOnRequiredFields={false}

                        extaraActionFormButton={
                            <span className="reset" onClick={onResetHandler} ><RestartAltIcon /> Reset</span>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(CampaignLeadsBacklogFilter);
