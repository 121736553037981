import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUploadedImage } from "../../../redux-reducer/reducer";
import { useState } from "react";

const ConvertedStatusHistory = (props) => {
  const history = props.history;
  const remarks = history.Remarks ? history.Remarks : "N/A";
  const { TravelExpense, TransportMode, TravelDistance, StartLocation, EndLocation, IsClaimRequired } = history
  const historyDate = history.MeetingStartDateTime
    ? moment(history.MeetingStartDateTime).format("DD MMM YYYY")
    : "";
  const historyTime = history.MeetingEndDateTime
    ? moment(history.MeetingEndDateTime).format("h:mma")
    : "";
  const userState = useSelector((state) => state.userReducer);
  const ChannelID = userState.profileInfo.channelid;
  const [fileData, setfileData] = useState(null)
  const [loader, setLoader] = useState(true)
  const getBase64Data = async() => {
    if (history.UploadBillFileName) {
      setLoader(false)
      const res = await getUploadedImage(history.UploadBillFileName);
      if (res.status === 200 && res.data) {
        setfileData(`${"data:image/png;base64,"}${res.data}`);
        var image = new Image();
        image.src = "data:image/jpg;base64," + res.data;
        var w = window.open("");
        w.document.write(image.outerHTML);
      }
      setLoader(true)
    }
  };

  return (
    <>
      {history.LeadStatus === "Converted" && ChannelID !== 3 ? (
        <>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Reason</label>
              <br></br>
              <label>{history.ReasonName}</label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Reschedule Date</label>
              <br></br>
              <label>
                {historyDate}, {historyTime}
              </label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
               <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Remarks </label>
              <br></br>
              <label>{remarks}</label>
            </TimelineContent>
          </TimelineItem>
        </>
      ) : (
        ""
      )}
      {history.LeadStatus === "Converted" && ChannelID === 3 ? (
        <>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Date Of Joining</label>
              <br></br>
              <label>{moment(history.DateofJoining).format("DD MMM YYYY")}</label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Remarks </label>
              <br></br>
              <label>{remarks}</label>
            </TimelineContent>
          </TimelineItem>
          {IsClaimRequired ?
          <>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Do you want claim expense </label>
              <br></br>
              <label>{IsClaimRequired ? 'Yes' : 'No'}</label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Mode Of Transport </label>
              <br></br>
              <label>{TransportMode}</label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Travel Expense(In Rupees)</label>
              <br></br>
              <label>{TravelExpense}</label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label> Travel Distance(Kms)</label>
              <br></br>
              <label>{TravelDistance}</label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>Start Location</label>
              <br></br>
              <label>{StartLocation}</label>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem sx={props.styletimeline}>
            <TimelineSeparator>
              <span className="separator">-</span>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <label>End Location</label>
              <br></br>
              <label>{EndLocation}</label>
            </TimelineContent>
          </TimelineItem> </> : <></>}
          {history.UploadBill &&
            <TimelineItem sx={props.styletimeline}>
              <TimelineSeparator>
                <span className="separator">-</span>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {loader ? <label className="link-label" onClick={() => getBase64Data()}>View uploaded Bill</label> : <>Loading</>}
                {
                  fileData &&
                  <img className='bill-copy' alt="uploaded bill" src={fileData}/>
                }
              </TimelineContent>
            </TimelineItem>
          }
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ConvertedStatusHistory;
