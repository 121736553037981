import { React, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import TimelineIcon from "@mui/icons-material/Timeline";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const SalesManagementOverviewCardBroca = ({ classes, roleId }) => {
  const history = useHistory()
  const select = useSelector((state) => state);
  const data = select.commonReducer.activityTypeDetailCount;
  const user = select.userReducer.profileInfo;
  const viewForStatus = select.commonReducer.viewForStatus;
  const activityName = data?.filter(ele => ele.ActivityName === "Partner/Branch Visit Plan" || ele.ActivityName === "Team Review")

  useEffect(() => {

  }, [])

  const smaList = () => {
    history.push("/app/activity/smaActivities")
  }

  return (
    <>
      {user.roleId === "ABH" || user.roleId === "RBH" ? (
        <Paper className={classes.paper2} sx={{ mb: 2 }}>
          <Grid className="activityOverviewSec" container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h5" gutterBottom>
                    {user.roleId === "RBH" && viewForStatus === "SELF" ? "RBH" : "ABH"} Sales Management Activities
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right" onClick={smaList}>
                  <p className="viewAll">View All</p>
                </Grid>
              </Grid>
              {activityName && activityName.map((val) => {
                return (
                  <Grid className="salesManagementDiv"
                    container>
                    <Grid item xs={2} textAlign="center">
                      <TimelineIcon color="primary" />
                    </Grid>
                    <Grid item xs={7}>
                      <h5 style={{ color: "black" }}>{val.ActivityName}</h5>
                    </Grid>

                    <Grid item xs={3} className="totalDiv" textAlign="right">
                      <h6>{`${val.ActivityPlanned === null ? 0 : val.ActivityPlanned
                        }/${val.TotalActivity}`}</h6>
                    </Grid>
                    <Grid item xs={6} textAlign="center">
                      <h6 className="daysDiv">{val.DayLeft} days left</h6>
                    </Grid>

                    <Grid item xs={6} textAlign="right">
                      <h6 className="daysDiv">{val.ActivityPercentage}%</h6>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        ""
      )}
    </>
  );
};

export default SalesManagementOverviewCardBroca;
