/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-04-01 11:26:33
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */
import { addSingleActivity, getAllOfTable, getSingleActivity, addAllActivity, addLastSyncTime, getLastSyncByKey, updateActivityByKey, addAllHistoricalActivity, activityHistory } from './localbase'

export const saveActivityOffline = (activityObject) => {
    return addSingleActivity(activityObject)
}
export const getActivityOffline = (id) => {
    return getSingleActivity(id).then((res) => {
        if(res) {
            return res
        } else {
            return null
        }
    }).catch(()=> {return null})
}
export const getAllActivityOffline = () => {
    return getAllOfTable('Activity')
}
export const addActivityInDb = (activityList) => {
    return addAllActivity(activityList)
}

export const addHistoricalActivityInDb = (historicalList) => {
    return addAllHistoricalActivity(historicalList)
}

export const addUpdateLastActivitySyncTime = (syncTimeMili) => {
    const syncTime = {
        syncTime: syncTimeMili
    }
    return addLastSyncTime(syncTime, 'activitySyncTime')
}
export const getActivitySyncTime = () => {
    return getLastSyncByKey('activitySyncTime').then((res) => {
        if(res) {
            return res
        } else {
            return null
        }
    }).catch(() => {return null})
}
export const getActivityStatusNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('ActivityStatus').then((res) => {
            resolve(res[0].ActivityStatus)
        }).catch((err) => reject(false))
    })
}
export const getActivityNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('Activities').then((res) => {
            resolve(res[0].Activities)
        }).catch((err) => reject(false))
    })
}
export const getStateNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('State').then((res) => {
            resolve(res[0].State)
        }).catch((err) => reject(false))
    })
}
export const getDistrictNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('District').then((res) => {
            resolve(res[0].District)
        }).catch((err) => reject(false))
    })
}
export const getGCLTypeActivity =() =>{
    return new Promise((resolve, reject) => {
        getAllOfTable('GclActivitytype').then((res) => {
            resolve(res[0].GclActivitytype)
        }).catch((err) => reject(false))
    })
}
export const getTrainingNameList = () => {
    return new Promise((resolve, reject) => {
        getAllOfTable('TrainingFor').then((res) => {
            resolve(res[0].TrainingFor)
        }).catch((err) => reject(false))
    })
}
export const getFilterActivities = (activityList, filterReq) => {
    let mActivityList = activityList;
    if (filterReq.BranchId && filterReq.ActivityStatusId && filterReq.Activitytypeid && filterReq.UserId) {
        return activityList.filter((activity) =>
        activity.BranchId === parseInt(filterReq.BranchId) &&
        activity.ActivityStatusId === parseInt(filterReq.ActivityStatusId) &&
        activity.ActivityID === parseInt(filterReq.Activitytypeid) &&
        activity.UserId === filterReq.UserId
    );
    }
    if (filterReq.BranchId && filterReq.BranchId !== 0) {
        mActivityList = mActivityList.filter((activity) =>
            activity.BranchId === parseInt(filterReq.BranchId)
        );
    }
    if (filterReq.ActivityStatusId) {
        mActivityList = mActivityList.filter((activity) =>
            activity.ActivityStatusId === parseInt(filterReq.ActivityStatusId)
        );
    }
    if (filterReq.Activitytypeid) {
        mActivityList = mActivityList.filter((activity) =>
            activity.ActivityID === parseInt(filterReq.Activitytypeid)
        );
    }
    if (filterReq.CreatedBy) {
        mActivityList = mActivityList.filter((activity) =>
            activity.CreatedBy === filterReq.CreatedBy
        );
    }
    if (filterReq.UserId) {
        mActivityList = mActivityList.filter((activity) =>
            activity.UserId === filterReq.UserId
        );
    }
    return mActivityList;
}
export const updateActivityOffline = (key, obj) => {
    return updateActivityByKey(key, obj);
}

export const getActivityOfflineHistory = (activityId) => {
    return activityHistory(activityId)
}
