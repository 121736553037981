import React from "react";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import IdleTimer from 'react-idle-timer';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// Channel CSC below form shd get render
import {
  CscActivityForm,
  handleActivitySelection, initFormData, getAutoCompleteItems, validateExtraValidations
} from "./csc/createActivityForm";
import {
  addDynamicData,
  getMasterData,
  uploadFileToBlob,
  activateSuccessMessageBar,
  activateErrorMessageBar,
  activateLoader,
  deActivateLoader,
  getUserBranches,
  setBreadcrumbs,
  updateDynamicData,
  isfinishdayActivityDone
} from "../../../redux/actions/common";
import { getActivityById, getActivityStatusMasterData, finishDayActivityCreate } from "../../../redux/actions";
import { validateActivity, getUserCheckInCountforFinishDay } from "../../../redux/actions/createActivityActions";
import {
  CONFIGS,
  FOUR_WHEELER,
  PUBLIC_TRANSPORT,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  STATUS_ACTIVITY_DRAFTED,
  STATUS_ACTIVITY_PLANNED,
  ACTIVITY_COMPLETED,
  checkInConfig,
} from "../../../utils/config";
import "./createActivity.scss";
import { minusMinuteFromDate } from '../../../utils/utils'
import { isPositiveNumber, meetingDateValidations, checkDateIsValid } from "../../../utils/validators";
import moment from "moment";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { getOptionByCode } from "./helper";
import SaveAsDraftDrawer from "./saveAsDraftDrawer";

import { v4 as uuidv4 } from "uuid";
import { getActivityNameList, getActivityStatusNameList, saveActivityOffline } from "../../../offlineManager/activityOfflineManager";
const _url = "/app/activity/all";
const _formatDate = "YYYY-MM-DDTHH:mm"

const customValidators = [
  {
    key: "ExpectedLead",
    validator: isPositiveNumber, // function to be called
    message: "Expected leads should be greater than 0", // message to show
  },
  {
    key: "ExpectedConversion",
    validator: isPositiveNumber, // function to be called
    message: "Expected conversion should be greater than 0", // message to show
  },
  {
    key: "TicketSize",
    validator: isPositiveNumber, // function to be called
    message: "Ticket size should be greater than 0", // message to show
  },
  {
    key: "ExpenseAmount",
    validator: isPositiveNumber, // function to be called
    message: "Expense amount should be greater than 0", // message to show
  },
  {
    key: "BillNumber",
    validator: isPositiveNumber, // function to be called
    message: "Bill number should be greater than 0", // message to show
  },
  {
    key: "TravelExpence",
    validator: isPositiveNumber, // function to be called
    message: "Travel expense should be greater than 0", // message to show
  },
  {
    key: "PlannedForNoOfPeople",
    validator: isPositiveNumber, // function to be called
    message: "Planned for no of people should be greater than 0", // message to show
  },
  {
    key: "AverageTicketSize",
    validator: isPositiveNumber, // function to be called
    message: "Average ticketsize should be greater than 0", // message to show
  },
  {
    key: "NoOfRAPActivationPlanned",
    validator: isPositiveNumber, // function to be called
    message: "No of RAP activation planned should be greater than 0", // message to show
  },
  {
    key: "NoOfCallsPlanned",
    validator: isPositiveNumber, // function to be called
    message: "No of Calls planned should be greater than 0", // message to show
  },
];

const createActivityTitle = "Create Activity";

class CreateActivityCSC extends React.Component {
  constructor() {
    super();
    this.dyFormRef = React.createRef();
    this.idleTimer = null;
    this.state = {
      current: {
        id: 1,
        MeetingStartDateTime: moment(new Date()).format(_formatDate),
        MeetingEndDateTime: moment(new Date()).add(30, "m")
          .format(_formatDate),
      },

      formData: CscActivityForm, // BankaActivityForm CSC FormName will come here as per the import
      autoCompleteItems: {
        BranchId: [],
        StateID: [],
        DistrictID: [],
        MeetingType: [],
      },
      selectMeetingType: "",
      uploadedFile: "",
      originalFileName: "",
      extErrors: {},
      ExpectedConversion: "",
      ExpectedLead: "",
      isActiveMobileDraft: false,
      isFormSUbmitDisabled: false,
      channelId: "",
      optionList: {
        activityNameType: [],
        activityStatusType: []
      },
      isFinishDayActivity: false,
      checkedinCount: null,
      responseMessage: ""
    };
  }
  

  loadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Activity", url: _url },
      { name: createActivityTitle, url: "/app/activity/create" },
    ];
    this.props.setBreadcrumbs(breadcrumbs);
  };

  isValidBusinessActivity = async (formData) => {
    if (formData["ActivityID"] !== '11') {
      if (
        formData.hasOwnProperty("MeetingStartDateTime") &&
        formData.hasOwnProperty("MeetingEndDateTime")
      ) {
        const checkActivity = await this.props.validateActivity({
          user_id: this.props.userState.profileInfo.userId,
          formData: formData,
        });

        if (
          checkActivity.status === 200 &&
          checkActivity.data.Responsestatus === true
        ) {
          return true;
        } else {
          this.setState({
            responseMessage: checkActivity.data.Responsemessage
          })
        }
        return false;
      }
      return true;
    } else {
      return true;
    }

  };

  reset = (model) => {
    for (const [key] of Object.entries(model)) {
      model[key] = "";
    }
    this.setState({ uploadedFile: "", originalFileName: "", extErrors: {} });
    this.dyFormRef.reset(model);
  };
  isfinishdayActivityDoneCheck = async () => {
    const res = await this.props.isfinishdayActivityDone(this.props.userState.profileInfo.userId);
    console.log("checkin", res)
    if (res.status === 200) {
      this.setState({ isFinishDayActivity: res.data })
    }
  }

  onSubmitHandler = async (model) => {
    //const responseMsg = ["0000",0,"0"]
    if (
      !model.isValidForm ||
      Object.keys(this.state.extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = []
      const formD = this.state.formData
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1)
        }
      })
      const errorElement = document.getElementsByClassName(`${'control'}${errArr[0]}`)[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: 'center'
        });
      }
      if (Object.keys(model.errors).length === 1 && model.BranchId && model.errors.hasOwnProperty('BranchIdlabel')) {
        console.log()
      } else {
        this.props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }
    const _format = "YYYY-MM-DD HH:mm:ss";

    const formData = { ...model };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(formData["MeetingEndDateTime"]).replace(
        "T",
        " "
      );
    }
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_PLANNED,
      this.props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format(_format);
    formData["ModifiedDate"] = moment().format(_format);
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(_format);
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;

    if (formData["MeetingStartDateTime"]) {
      formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"].replace("T", ' ')
    } else {
      formData["MeetingStartDateTime"] = null;
    }
    if (formData["MeetingEndDateTime"]) {
      formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace("T", ' ')
    } else {
      formData["MeetingEndDateTime"] = null;
    }
    formData["AssignedToName"] = `${this.props.userState.profileInfo.firstName}${' '}${this.props.userState.profileInfo.lastName}`;
    formData["ActivityStatus"] = this.getActivityStatusName(formData.ActivityStatusId);
    formData["ActivityName"] = this.getActivityName(formData.ActivityID, formData["MeetingStartDateTime"]);
    formData["AssignedToName"] = `${this.props.userState.profileInfo.firstName}${' '}${this.props.userState.profileInfo.lastName}`;
    formData["IsBacklog"] = false;
    formData["CreatedBy"] = parseInt(this.props.userState.profileInfo.userId);

    // validate the activity from server side
    if (navigator.onLine) {
      this.props.activateLoader();
      let isValidActivity = await this.isValidBusinessActivity(formData);
      isValidActivity = model.ActivityID === '11' ? true : isValidActivity;

      if (isValidActivity) {
        if (model.ActivityID === "11" && this.state.isFinishDayActivity) {
          this.props.deActivateLoader();
          this.props.activateErrorMessageBar("Finish Day Activity Already done!")
        } else if (model.ActivityID == 11 && this.state.checkedinCount == 0) {
          this.props.activateErrorMessageBar(
            "No Checkin Found For Today.")
          this.props.deActivateLoader();
        } else {
          if (formData.ActivityID == 11) {
            formData['IsClaimRequired'] = false
          }
          try {
            if (formData.ActivityID == 11) {
              const result1 = await this.props.finishDayActivityCreate(
                this.props.userState.profileInfo.userId
              );
              if (result1.status === 200) {
                //this.setState({ isFormSUbmitDisabled: false });
                const result = await this.props.addDynamicData({
                  data: { ...formData, "IsBacklog": 0 },
                  formName: CONFIGS.NEW_ACTIVITY_FORM,
                  CreatedBy: this.props.userState.profileInfo.userId,
                });
                if (result.status === 200 && result.data.Responsestatus === true) {
                  this.props.activateSuccessMessageBar(
                    "Activity has been created successfully."
                  );
                  if (model.ActivityID === "11") {
                    const getCurrentActivity = await this.props.getActivityById(result.data.Transactionid);
                    getCurrentActivity.data.ActivityStatus = ACTIVITY_COMPLETED;
                    const activityresult = await this.props.getActivityStatusMasterData();
                    if (activityresult.status === 200) {
                      const statusId = activityresult.data.filter(f => f.ActivityStatus === 'Activity Completed');
                      getCurrentActivity.data.ActivityStatusId = statusId[0].ActivityStatusId;
                      getCurrentActivity.data['ModifiedBy'] = parseInt(this.props.userState.profileInfo.userId);
                      getCurrentActivity.data['IsClaimRequired'] = model.IsClaimRequired;
                    }
                    const finshiday = {
                      "ExpectedPremium": null,
                      "NoOfLeads": null,
                      "Remarks": "Auto Completed",
                      "IsBacklog": 0,
                    }
                    const data = { ...getCurrentActivity.data, ...finshiday };
                    const updateresult = await this.props.updateDynamicData({
                      'data': [data], 'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
                      "formBuilder": ACTIVITY_COMPLETED, "userID": result.data.Transactionid
                    });
                    if (updateresult.status === 200) {
                      this.props.activateSuccessMessageBar(
                        "Activity is updated successfully."
                      );
                    
                    }
                  }
      
      
                  this.props.deActivateLoader();
                  const saveFormData = { ...formData };
                  //  SAVE activity in indexDb online
                  saveFormData["TrnActivityID"] = result.data.Transactionid;
                  saveFormData["IsBacklog"] = false;
                  saveActivityOffline(saveFormData).then((res) => {
                    if (res) {
                      this.reset(formData);
                      this.props.history.push(
                        `/app/activity/detail/${result.data.Transactionid}`
                      );
                    }
                  });
                } else {
                  this.props.deActivateLoader();
                  this.props.activateSuccessMessageBar(
                    "Something went wrong! please try again later."
                  );
                }
              }
            }else{
             
                //this.setState({ isFormSUbmitDisabled: false });
                const result = await this.props.addDynamicData({
                  data: { ...formData, "IsBacklog": 0 },
                  formName: CONFIGS.NEW_ACTIVITY_FORM,
                  CreatedBy: this.props.userState.profileInfo.userId,
                });
                if (result.status === 200 && result.data.Responsestatus === true) {
                  this.props.activateSuccessMessageBar(
                    "Activity has been created successfully."
                  );
                  if (model.ActivityID === "11") {
                    const getCurrentActivity = await this.props.getActivityById(result.data.Transactionid);
                    getCurrentActivity.data.ActivityStatus = ACTIVITY_COMPLETED;
                    const activityresult = await this.props.getActivityStatusMasterData();
                    if (activityresult.status === 200) {
                      const statusId = activityresult.data.filter(f => f.ActivityStatus === 'Activity Completed');
                      getCurrentActivity.data.ActivityStatusId = statusId[0].ActivityStatusId;
                      getCurrentActivity.data['ModifiedBy'] = parseInt(this.props.userState.profileInfo.userId);
                      getCurrentActivity.data['IsClaimRequired'] = model.IsClaimRequired;
                    }
                    const finshiday = {
                      "ExpectedPremium": null,
                      "NoOfLeads": null,
                      "Remarks": "Auto Completed",
                      "IsBacklog": 0,
                    }
                    const data = { ...getCurrentActivity.data, ...finshiday };
                    const updateresult = await this.props.updateDynamicData({
                      'data': [data], 'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
                      "formBuilder": ACTIVITY_COMPLETED, "userID": result.data.Transactionid
                    });
                    if (updateresult.status === 200) {
                      this.props.activateSuccessMessageBar(
                        "Activity is updated successfully."
                      );
                    
                    }
                  }
      
      
                  this.props.deActivateLoader();
                  const saveFormData = { ...formData };
                  //  SAVE activity in indexDb online
                  saveFormData["TrnActivityID"] = result.data.Transactionid;
                  saveFormData["IsBacklog"] = false;
                  saveActivityOffline(saveFormData).then((res) => {
                    if (res) {
                      this.reset(formData);
                      this.props.history.push(
                        `/app/activity/detail/${result.data.Transactionid}`
                      );
                    }
                  });
                } else {
                  this.props.deActivateLoader();
                  this.props.activateSuccessMessageBar(
                    "Something went wrong! please try again later."
                  );
                }
              
            }
          } catch (e) {
            //this.setState({ isFormSUbmitDisabled: true });
          }

        }
      } else {
        {
          this.props.activateErrorMessageBar(this.state.responseMessage)
        }

        this.props.deActivateLoader();
      }
    } else {
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          const {
            data: { key },
          } = res;
          this.props.history.push(`/app/activity/detail/${key}`);
        }
      });
    }
  };

  handleSaveAsDraft = async (e) => {
    e.preventDefault();
    this.props.activateLoader();
    const formData = { ...this.dyFormRef.state };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(formData["MeetingEndDateTime"]).replace(
        "T",
        " "
      );
    }
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }
    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_DRAFTED,
      this.props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = this.props.userState.profileInfo.userId;
    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["CreatedDate"] = moment(new Date()).format(_format)
    formData["ModifiedDate"] = new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    //formData["IsClaimRequired"] = formData["IsClaimRequired"] ? "Yes" : "No"
    formData["TrnActivityID"] = null;
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format("YYYY-MM-DD HH:mm:ss");
    formData["IsLmsSync"] = navigator.onLine;

    formData["AssignedToName"] = `${this.props.userState.profileInfo.firstName}${' '}${this.props.userState.profileInfo.lastName}`;
    formData["ActivityStatus"] = this.getActivityStatusName(formData.ActivityStatusId);
    formData["ActivityName"] = this.getActivityName(formData.ActivityID, formData['MeetingStartDateTime']);
    formData["IsBacklog"] = false;
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;
    if (formData.ActivityID == 11) {
      formData['IsClaimRequired'] = false
    }
    if (navigator.onLine) {
      const result = await this.props.addDynamicData({
        data: { ...formData, "IsBacklog": 0 },
        formName: CONFIGS.NEW_ACTIVITY_FORM,
        CreatedBy: this.props.userState.profileInfo.userId,
      });
      if (result.status === 200 && result.data.Responsestatus === true) {
        this.props.activateSuccessMessageBar(
          "Activity has been drafted successfully."
        );
        this.reset(formData);
        this.props.deActivateLoader();
        saveActivityOffline(formData);
        this.props.history.push("/app/activity/all");
      } else {
        this.props.deActivateLoader();
        this.props.activateSuccessMessageBar(
          "Something went wrong! please try again later."
        );
      }
    }
    else {
      this.props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          this.props.history.push(`/app/activity/all`);
        }
      });
    }
  };
  userCheckInCountforFinishDay = async () => {
    let res = await this.props.getUserCheckInCountforFinishDay(this.props.userState.profileInfo.userId)
    this.setState({ checkedinCount: res.data })
  }

  finishDayActivityStatus = async () => {
    let res = await this.props.finishDayActivityCreate(this.props.userState.profileInfo.userId)
    this.setState({ isFinishDayActivity: res.data })
  }
  componentDidUpdate() {
    const autoCompleteItems = { ...this.state.autoCompleteItems };
    if (this.props.masterDataState.District) {
      autoCompleteItems.DistrictID = this.props.masterDataState.District;
    }
  }
  componentDidMount = () => {
    this.loadBreadCrumbs();
    const formData = this.props.masterDataState
      ? initFormData(this.state.formData, this.props.masterDataState)
      : this.state.formData;
    const autoCompleteItems = { ...this.state.autoCompleteItems };
    autoCompleteItems.BranchId = this.props.masterDataState
      ? getAutoCompleteItems(
        this.props.masterDataState,
        CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME
      )
      : [];
    autoCompleteItems.StateID = this.props.masterDataState
      ? getAutoCompleteItems(
        this.props.masterDataState,
        CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
      )
      : [];
    autoCompleteItems.MeetingType = this.props.masterDataState
      ? getAutoCompleteItems(
        this.props.masterDataState,
        CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE.TABLE_NAME
      )
      : [];
    this.setState({ formData: formData, autoCompleteItems: autoCompleteItems, channelId: this.props.userState.profileInfo.channelid }, () => {
    });
    this.getOptionData();
    this.isfinishdayActivityDoneCheck();
    this.userCheckInCountforFinishDay();
  };
  getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "Select", value: "" });
    return arr;
  };
  uploadFile = async (files) => {
    const file = files[0];
    if (file) {
      const fileName = new Date().getTime() + file.name;
      const fileRes = await this.props.uploadFileToBlob({
        file: file,
        fileName: fileName,
      });
      if (fileRes.status === 200) {
        this.setState({ uploadedFile: fileName, originalFileName: file.name });
      }
    }
  };
  getDistrictForState = (stateId) => {
    const allDistricts = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.DISRICT.TABLE_NAME];
    const filteredDistrict = [
      { key: "select", label: "Select", value: "" }
    ];
    if (allDistricts && allDistricts.length > 0) {
      allDistricts.filter((district) => {
        if (district.StateID === parseInt(stateId)) {
          filteredDistrict.push(district);
        }
      });
    }
    return filteredDistrict;
  };
  getActivityName = (inputId, MeetingStartDateTime) => {
    const collection = this.state.optionList['activityNameType'];
    let str = '';
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name && name.length > 0) {
        str = name[0].label;
      }
    }
    if (MeetingStartDateTime !== null && MeetingStartDateTime !== undefined) {
      const MeetingDate = moment(MeetingStartDateTime).format('DD-MMM');
      str += `-${MeetingDate}`
    }
    return str;
  };
  getOptionData = () => {
    Promise.all([getActivityStatusNameList(), getActivityNameList()]).then((res) => {
      if (res && res.length > 0) {
        const optionState = {
          activityStatusType: res[0],
          activityNameType: res[1]
        }
        this.setState({ optionList: optionState }, () => {
        });
      }
    })
  }
  getActivityStatusName = (inputId) => {
    const collection = this.state.optionList['activityStatusType'];
    if (inputId && collection) {
      const name = collection.filter((el) => el.value === inputId.toString());
      if (name && name.length > 0) {
        return name[0].label;
      }
      return null;
    }
    return null;
  };
  onChangeHandler = async (event, key, model) => {
    delete model.errors['MeetingWith']
    delete model.errors['ReviewWith']
    delete model.errors['NoOfCallsPlanned']
    delete model.errors['TrainingFor']
    delete model.errors['StateID']
    delete model.errors['DistrictID']
    delete model.errors['MeetingType']
    delete model.errors['Remarks']
    delete model.errors['TrainingTopic']
    delete model.errors['PlannedForNoOfPeople']
    delete model.errors['AverageTicketSize']
    delete model.errors['Location']
    delete model.errors['NoOfRAPActivationPlanned']
    delete model.errors['RAP_VLE']
    delete model.errors['NoOfPoliciesPlanned']
    if (key === "IsClaimRequired" && this.state.checkedinCount == 0) {
      this.props.activateErrorMessageBar(
        "No Checkin Found For Today."
      );
      this.setState({
        current: { ...this.state.current, IsClaimRequired: false },
      });
      return false
    }
    if (key === "IsClaimRequired") {
      delete model['IsClaimRequired']
      delete model['TravelExpence']
      delete model['TransportModeID']
      delete model['errors']['TravelExpence']
    }
    if (key == "TransportModeID") {
      delete model['TravelExpence']
      delete model.errors['TravelExpence']
    }
    if (key === 'StateID' && this.state.formData[3].key === "DistrictID") {
      // removed api call to get the district, instead using redux store
      const districtOptions = this.getDistrictForState(event.target.value);
      const form = [...this.state.formData]
      form[3].options = districtOptions;
      form[3].hide = false;
      this.setState({ formData: form })
    }

    if (key === 'TrainingFor') {
      this.setState({ districtValue: event.target.value });
    }
    if (key === 'UploadBill') {
      if (navigator.onLine) {
        this.uploadFile(event);
      } else {
        this.props.activateErrorMessageBar(
          "Cannot upload files in offline mode!"
        );
      }
    }
    if (key === "MeetingStartDateTime") {
      var StartDate = moment(event.target.value).format(_formatDate);
      const newDate = moment(event.target.value)
        .add(30, "m")
        .format(_formatDate);

      this.state.formData.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate).endOf('day').format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
      })

      if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, [key]: "please select future value" }
        })
      } else {
        delete this.state.extErrors?.MeetingStartDateTime;
        this.setState({
          ...this.state, extErrors: {}
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingStartDateTime: StartDate, MeetingEndDateTime: newDate },
      });
    }
    if (key === "MeetingEndDateTime") {
      const newDate = moment(event.target.value).format(_formatDate);
      if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, "MeetingEndDateTime": "meeting end date should not less than meeting starting date" },
        })
      } else {
        this.setState({
          ...this.state, extErrors: {},
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingEndDateTime: newDate },
      }, () => {
        const extErrors = meetingDateValidations(this.state);
        this.setState({ extErrors: extErrors });
      });
    }
    const formData = handleActivitySelection(
      event,
      key,
      this.state.formData,
      this.props.masterDataState,
      this.props.userState.profileInfo.roleId
    );

    this.setState({ formData: formData }, () => {
      const form = [...this.state.formData];
      if (key === "MeetingWith") {
        const meetingType = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE.TABLE_NAME];
        form.map((val) => {
          if (val.key === "MeetingType") {
            val.options = meetingType;
          }
          return true;
        })
      }
      if (key === "TrainingFor" || key === "MeetingWith" || key === "ReviewWith") {
        const allState = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME];
        form.map((val) => {
          if (val.key === "StateID") {
            val.options = allState;
          }
          return true;
        })
      }
      this.setState({ formData: form })
    });
    if (key === "ExpectedLead" || key === "ExpectedConversion") {
      this.setState({ [key]: event.target.value }, () => {
        const extErrors = validateExtraValidations(this.state);
        this.setState({ extErrors: extErrors });
      });
    }


    if (key === "ActivityID") {
      this.setState({ current: { ...this.state.current, ActivityID: event.target.value } })
    }

  };
  onAutocompleteSelectHandler = (key, value, item) => {
    this.setState({ selectMeetingType: item.id })
  };
  onCancelHandler = () => {
    const isMobile = Math.min(window.screen.width, window.screen.height) < 480 || navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      this.setState({ isActiveMobileDraft: true });
    } else {
      this.props.history.push(_url);
    }
  };
  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({ uploadedFile: "" });
  };
  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => this.removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };
  renderRadioIcons = (radioOption) => {
    let icon = "";
    if (radioOption.key === PUBLIC_TRANSPORT) {
      icon = <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      icon = <DirectionsCarIcon></DirectionsCarIcon>;
    }
    return icon;
  };
  onCheckinHandler = (position, address) => {
    if (this.id) {
      this.setState({
        ...this.state.leadDetail, Location: address
      })
    }
  }
  toggleDrawer = (flag) => {
    this.setState({ isActiveMobileDraft: flag });
  };
  onDontSaveDraftHandler = () => {
    this.props.history.push(_url);
  };
  onSaveDraftHandler = () => {
    this.handleSaveAsDraft(new Event("test"));
  };
  handleOnAction = (event) => {
    if (this.state.timeOutMsg) {
      setTimeout(() => {
        this.props.history.push("/app/home")
      }, 3000)
    }
  }
  handleOnActive = (event) => {
    if (this.idleTimer.getRemainingTime() === 0) {
      this.setState({
        ...this.state, timeOutMsg: this.idleTimer.getRemainingTime() === 0
      })
    }
  }
  handleOnIdle = (event) => {
  }

  render() {
    const { t } = this.props;
    return (
      <div className="mainContainer createActivity">
        <Breadcrumb></Breadcrumb>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 1}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Snackbar open={this.state.timeOutMsg} autoHideDuration={3000} >
          <Alert severity="error" >Your session has been expired! You will be redirected to homepage in 3 seconds.</Alert>
        </Snackbar>
        <span className="formInfo">
          {t("Please fill in the activity information for CSC")}
        </span>
        <div className="formBox">
          <DynamicForm
            key={this.state.current.id}
            onRef={(ref) => (this.dyFormRef = ref)}
            title={t(createActivityTitle)}
            buttonSubmit={t("Submit")}
            defaultValues={this.state.current}
            model={this.state.formData}
            onSubmit={(model) => {
              this.onSubmitHandler(model);
            }}
            disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key, model) => this.onChangeHandler(event, key, model)}
            onAutocompleteSelect={this.onAutocompleteSelectHandler}
            autoCompleteItems={this.state.autoCompleteItems}
            showAstersikOnRequiredFields={true}
            extErrors={this.state.extErrors}
            checkInConfig={checkInConfig}
            onCheckinHandler={this.onCheckinHandler}
            className="activityTypeDiv"
            extaraActionFormButton={
              <>
                <a
                  className="savedraftLink"
                  onClick={(e) => this.handleSaveAsDraft(e)}
                  href="#"
                >
                  SAVE AS DRAFT
                </a>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={this.onCancelHandler}
                >
                  {t("Cancel")}
                </button>
              </>
            }
            fileUploaderFields={["UploadBill"]}
            renderUploadedFilesItem={this.renderUploadedFilesItem}
            customValidators={customValidators}
            renderRadioIcons={(option) => this.renderRadioIcons(option)}
          />
        </div>
        <SaveAsDraftDrawer
          isActiveMobileDraft={this.state.isActiveMobileDraft}
          toggleDrawer={this.toggleDrawer}
          onDontSaveHandler={this.onDontSaveDraftHandler}
          onSaveDraftHandler={this.onSaveDraftHandler}
        ></SaveAsDraftDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  activateSuccessMessageBar: (payload) =>
    dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getUserBranches: (payload) => dispatch(getUserBranches(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  finishDayActivityCreate: (payload) =>
    dispatch(finishDayActivityCreate(payload)),
  getActivityById: (payload) => dispatch(getActivityById(payload)),
  getActivityStatusMasterData: (payload) => dispatch(getActivityStatusMasterData(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  isfinishdayActivityDone: (payload) => dispatch(isfinishdayActivityDone(payload)),


  getUserCheckInCountforFinishDay: (payload) => dispatch(getUserCheckInCountforFinishDay(payload))
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(CreateActivityCSC)
);
