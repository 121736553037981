const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
const GET_FORM ='GET_FORM';
const EDIT_ACTIVITY_RESCHEDULE_FORM = "EDIT_ACTIVITY_RESCHEDULE_FORM";
const EDIT_ACTIVITY_COMPLETE_FORM = "EDIT_ACTIVITY_COMPLETE_FORM";
const EDIT_ACTIVITY_CANCEL_FORM = "EDIT_ACTIVITY_CANCEL_FORM";
const EDIT_ACTIVITY_CALLING_VLE_FORM ="EDIT_ACTIVITY_CALLING_VLE_FORM";
const EDIT_ACTIVITY_UPDATE_CALLS="EDIT_ACTIVITY_UPDATE_CALLS";
const GET_UPDATED_STATUS_CALL_COUNT="GET_UPDATED_STATUS_CALL_COUNT";
const FILTER_BY="FILTER_BY";
const ACTIVITY_LISTING_LANDING = "ACTIVITY_LISTING_LANDING";
const JOINT_CALL_CANCELFORM = "JOINT_CALL_CANCELFORM";

const initialState = {
    fpmActivityReschedule:false,
    fpmActivityCancel:false,
    activityComplete:false,
    activityCancelled:false,
    activityReshedule:false,
    activityCalltoVLE:false,
    activityUpdateCalls:false,
    fpmActivityComplete:false,
    getSelectActivityData: {
    },
    statusCallCOunt:0,
    filterby:"",
    activityLandingPage : { },
}

function updateActivityReducer(state = initialState, action) {
    switch (action.type) {
        case EDIT_ACTIVITY_RESCHEDULE_FORM:
            return { ...state, activityReshedule: action.payload, activityComplete: false, activityCancelled : false,activityCalltoVLE:false,
                activityUpdateCalls:false,fpmActivityComplete:false,fpmActivityCancel:false,fpmActivityReschedule:false};
        case EDIT_ACTIVITY_COMPLETE_FORM:
            return { ...state, activityReshedule: false, activityComplete: action.payload, activityCancelled : false,activityCalltoVLE:false,
                activityUpdateCalls:false,fpmActivityComplete:false,fpmActivityCancel:false,fpmActivityReschedule:false};
        case EDIT_ACTIVITY_CANCEL_FORM:
            return { ...state, activityReshedule: false, activityComplete: false, activityCancelled : action.payload,activityCalltoVLE:false,
                activityUpdateCalls:false,fpmActivityComplete:false,fpmActivityCancel:false,fpmActivityReschedule:false};
        case EDIT_ACTIVITY_UPDATE_CALLS:
            return { ...state, activityReshedule: false, activityComplete: false, activityCancelled : false,activityCalltoVLE:false,
                activityUpdateCalls:action.payload,fpmActivityComplete:false,fpmActivityCancel:false,fpmActivityReschedule:false};
        case EDIT_ACTIVITY_CALLING_VLE_FORM:
            return { ...state, activityReshedule: false, activityComplete: false, activityCancelled : false,activityCalltoVLE:action.payload,
                        activityUpdateCalls:false,fpmActivityComplete:false,fpmActivityCancel:false,fpmActivityReschedule:false};
        case 'EDIT_FPM_JOINT_CALL_COMPLETE_FORM':
            return {
                ...state, activityReshedule: false, activityComplete: false, activityCancelled : false,activityCalltoVLE:false,
                activityUpdateCalls:false,fpmActivityComplete:action.payload,fpmActivityCancel:false,fpmActivityReschedule:false
            }
        case 'JOINT_CALL_CANCELFORM':
            return {
                ...state, activityReshedule: false, activityComplete: false, activityCancelled : false,activityCalltoVLE:false,
                activityUpdateCalls:false,fpmActivityComplete:false,
                fpmActivityCancel:action.payload,fpmActivityReschedule:false
            }
        case 'JOINT_CALL_RESCHEDULEFORM':
            return {
                ...state, activityReshedule: false, activityComplete: false, activityCancelled : false,activityCalltoVLE:false,
                activityUpdateCalls:false,fpmActivityComplete:false,fpmActivityCancel:false,fpmActivityReschedule:action.payload
            }
        case EDIT_ACTIVITY:
            return { ...state, getSelectActivityData: action.payload};
        case GET_FORM:
            return {...action.payload};
        case GET_UPDATED_STATUS_CALL_COUNT:
            return {...state, statusCallCOunt: action.payload};
        case FILTER_BY:
            return{...state,filterby:action.payload}
        case ACTIVITY_LISTING_LANDING:{
            return{...state,activityLandingPage:action.payload}
        }
        default:
            return state;
    }
}

export default updateActivityReducer;