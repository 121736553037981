import { format } from 'date-fns';
import { ALLOWED_FUTURE_DAYS } from '../../../../utils/config';
import { dateFormat, getDateAfterDays } from '../../../../utils/utils';
export const CscCallingToVLE_RAP_DMForm = [
    {
        "key": "NoOfCallsPlanned", "type": "number", "disabled": false,
        "id": "NoOfCallsPlanned", "label": "No of calls Planned",
        "validators": [{ "name": "required", "value": true, "validationMessage": "This field  is required." },
        { "name": "maxLength", "value": 3, "validationMessage": "Max 3 digits allowed." },
        { "name": "minLength", "value": 1, "validationMessage": "Min 1 digits allowed." }]
    },
    {
        "key": "MeetingStartDateTime",
        "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'),
        "hide": false, "type": "datetime-local", "disabled": false,
        "id": "MeetingStartDateTime", "label": "Meeting Start Date and Time",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting Start Date and Time is required." }],
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
    },
    {
        "key": "MeetingEndDateTime", "min": format(new Date(), 'yyyy-MM-dd') + "T" + format(new Date(), 'kk:mm'),
        "hide": false, "type": "datetime-local", "disabled": false, "id": "MeetingEndDateTime", "label": "Meeting End Date and Time",
        "validators": [{ "name": "required", "value": true, "validationMessage": "Meeting End Date and Time is required." }],
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
    },
]

