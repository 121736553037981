import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import "./../../leadlist.scss";
import { useDispatch, useSelector, connect } from "react-redux";
import { getLeadStatusList } from "../../../redux-reducer/reducer";
import LeadFilter from "./leadDataGridFilter";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import {
  setLeadsOverview,
  setRecruitmentLeadsOverview,
} from "../../../redux-reducer/reducer/leadListReducer";
import {
  downloadBusinessLeads,
  downloadBrocaLead,
  getFilteredBusinessDataGridLeadList,
  downloadBusinessLeadsWithIds,
  deActivateLoader,
  activateLoader,
  downloadGCLBusinessLeads,
  downloadGCLBusinessLeadsWithIds
  
} from "../../../redux/actions/common";
import { isBrowser, isMobile } from "react-device-detect";
import ModalPopup from "f1-modal-react";
import MatDatagrid from "f1-mat-data-grid-react";
import { downloadFilesAdmin } from "../../../utils/utils";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Paginator } from "./../../paginator";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
const currentStatusId = "1,2,3,4,5,6,7,8,9,10,15,16,17,18,19,20,21,22,23,24";
  function LeadListing(props){
  const commonState = useSelector((select) => select);
  const userState = commonState.userReducer;
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const formRef = useRef();
  const { getLeadsOverView, getRecruitmentLeadsOverView } = useSelector(
    (state) => state.leadListReducer
  );
  const chanelId = props.ChannelId;
  const history = useHistory();
  const [stateSelect, setStateSelect] = React.useState({
    bottom: false,
    right: false,
  });

  const [value] = React.useState(0);
  const IsRecruitmentlead = props.Isrecruitmentlead;
  const [showModal] = useState(true);
  const [NoOfRecordperPage, setNoOfRecordperPage] = useState(100);
  const [selectedRows, setSelectedRows] = useState([]);
  const [businessLeadForGrid, setBusinessLeadForGrid] = useState([]);

  let [rowPerPage, setRowPerPage] = useState(20);
  let [rowChangeEvent, setRowChangeEvent] = useState(0);
  let [totalCount, setTotalCount] = useState(0);
  let [initialPageNo, setInitialPageNo] = useState(0);
  const [leadFilterState, setLeadFilterState] = useState({
    UserID: null,
    CreatedDateTime: "null",
    CreatedDateStartTIme: "null",
    CreatedDateEndTIme: "null",
    MeetingOn: null,
    currentStatus: 0,
    currentstatus: [],
    branchNameCode: null,
    typeOfSP: 0,
    product: 0,
    product: [],
    LeadName: null,
    LeadTypeID: 0,
    activityID: null,
    MeetingDate: "null",
    fromdate: "null",
    todate: "null",
    MobileNo: null,
    MobileNumber: null,
    NhUsers: [],
    newUserList: [],
    Campaign: null,
    EmployeeCode:null,
  });
  const columns = [
    {
      title: "Created Date ",
      field: "CreatedDate",
      type: "date",
      sorting: true,
      render: (rowData) => moment(rowData.CreatedDate).format("DD/MM/YYYY"),
    },
    {
      title: "Meeting Date ",
      field: "MeetingDate",
      type: "date",
      sorting: true,
    },
    { title: "Lead Name", field: "CustomerName", sorting: true },
    { title: "Current Status", field: "CurrenStatus", sorting: true },
    { title: "Assigned To", field: "Assignedto", sorting: true },
    {
      title: "Last Updated date ",
      field: "LastUpdatedDate",
      type: "date",
      sorting: true,
    },
    { title: "Activity Name", field: "ActivityName", sorting: true },
  ];

  const csvExp = async () => {
    const IDs = [];
    if (selectedRows.length > 0) {
      const rows = selectedRows;
      for (const key in rows) {
        console.log("rows data is", rows[key].LeadId);
        IDs.push(rows[key].LeadId);
      }
      const filterReq = { IsRecruitmentLead: IsRecruitmentlead ? 1 : 0 };
      let ids = IDs.map((id) => id).join(",");
      console.log("idss", ids);
      let storedIds = ids.toString();
      try
      {
        props.activateLoader();
        if(chanelId==21){
          let filterReqcopy={
            ...leadReq,
            ids: storedIds
          }
          const downloadRes = await props.downloadBrocaLead(
            { ChannelId: props.ChannelId, ids: storedIds },
            filterReqcopy
          );
          console.log("ids apis res is", downloadRes);
          downloadFilesAdmin(downloadRes, props.fileName);
          props.deActivateLoader();

        }else if(chanelId==13){
          const downloadRes = await props.downloadGCLBusinessLeadsWithIds(
            { ChannelId: props.ChannelId, ids: storedIds },
            filterReq
          );
          console.log("ids apis res is", downloadRes);
          downloadFilesAdmin(downloadRes, props.fileName);
          props.deActivateLoader();

        } else {
        const downloadRes = await props.downloadBusinessLeadsWithIds(
          { ChannelId: props.ChannelId, ids: storedIds },
          filterReq
        );
        console.log("ids apis res is", downloadRes);
        downloadFilesAdmin(downloadRes, props.fileName);
        props.deActivateLoader();
        }
      }
      catch(err)
      {
        props.deActivateLoader();
      }
    } else {
      const payload = {
        ChannelId: props.ChannelId,
        filterReq: {
          ...leadReq,
        },
      };
      try {
        props.activateLoader();
        let downloadData;
        if(chanelId==21){
          downloadData = await props.downloadBrocaLead(
            { ChannelId: payload.ChannelId },
            payload.filterReq
          )
        }else if(chanelId==13){
          downloadData = await props.downloadGCLBusinessLeads(
            { ChannelId: payload.ChannelId },
            payload.filterReq
          )
        }else{
         downloadData = await props.downloadBusinessLeads(
          { ChannelId: payload.ChannelId },
          payload.filterReq
        )
      }
        if (downloadData.status === 200) {
          props.deActivateLoader();
          downloadFilesAdmin(downloadData, props.fileName);
        } else {
          props.deActivateLoader();
        }
      } catch (err) {
        props.deActivateLoader();
      }
    }
  };

  const rowOnRowclick = (e, row) => {
    const leadId = row.LeadId;
    console.log("row clicked ", row);
    if(userState.profileInfo.roleId ==='ADM')
    {
    history.push({
      pathname: `/app/lead/details/${leadId}`,
      chanelId: props.ChannelId,
      IsRecruitmentlead: IsRecruitmentlead,
    });
  }
  else
  {
    history.push({
      pathname: `/app/lead/detail/${leadId}`,
    });
  }
  };

  const fetchBusinessLead = async (ChannelId, Page, rowPerPageCount, filterReq) => {
    if (isOnline) {
      props.activateLoader();
      const result = await props.getFilteredBusinessDataGridLeadList(
        ChannelId,
        Page,
        rowPerPageCount,
        filterReq
      );
      if (result.status === 200) {
        const responseData = [];
        for (const element of result.data.Listofleads) {
          if (element.MeetingDate !== null) {
            element.MeetingDate = moment(
              element.MeetingDate
            ).format("DD/MM/YYYY");
          }
          if (element.LastUpdatedDate !== null) {
            element.LastUpdatedDate = moment(
              element.LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          responseData.push(element);
        }
        props.deActivateLoader();
        setBusinessLeadForGrid(responseData);
        setTotalCount(result.data.TotalnoofRows);
        console.log(
          "result.data.Getallcampaignactivitieslistbychannel ",
          result.data.Listofleads
        );
      } else {
        props.deActivateLoader();
      }
    }
  };

  const [leadReq, setLeadReq] = useState({
    CreateStartDate: null,
    CreateEndDate: null,
    MeetingStartDate: null,
    MeetingEndDate: null,
    CustomerName: null,
    CurrentStatus: "1,2,3,4,5,6,7,8,9,10,15,16,17,18,19,20,21,22,23,24",
    ActivityId: null,
    BranchId: null,
    UserId: null,
    Campaign: null,
    MobileNumber: null,
    LeadStatus: null,
    ProductId: 0,
    SPTypeID: 0,
    IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
    EmployeeCode:null,
  });

  const getLeadByOverviewSelection = (type) => {
    switch (type) {
      case "open":
        return {
          ChannelId: chanelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: {
            ...leadReq,
            CurrentStatus: "2,3,4,7,15,17",
            IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
          },
        };
      case "un-actioned":
        return {
          ChannelId: chanelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: {
            ...leadReq,
            CurrentStatus: "1,18",
            IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
          },
        };
      case "converted":
        return {
          ChannelId: chanelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: {
            ...leadReq,
            CurrentStatus: "6,19,20,24,22,21",
            IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
          },
        };
      case "dropped":
        return {
          ChannelId: chanelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: {
            ...leadReq,
            CurrentStatus: "5,16",
            IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
          },
        };
      case "all":
        return {
          ChannelId: chanelId,
          Page: 1,
          rowPerPage: rowPerPage,
          filterReq: {
            ...leadReq,
            IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
          },
        };
      default:
        break;
    }
  };

  useEffect(() => {
    if (isOnline) {
      const payload = getLeadByOverviewSelection(
        IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView
      );
      setLeadReq({
        ...leadReq,
        CurrentStatus: payload.filterReq.CurrentStatus,
      });
      // if user is non admin, set logged in userid in request for defualt
      if (userState.profileInfo.roleId !=='ADM') {
        payload.filterReq.UserId = userState.profileInfo.userId
        setLeadReq({
          ...leadReq,
          UserId : userState.profileInfo.userId
        });
      }
      fetchBusinessLead(
        payload.ChannelId,
        payload.Page,
        payload.rowPerPage,
        payload.filterReq
      );
    }
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(setLeadsOverview("all"));
      dispatch(setRecruitmentLeadsOverview("all"));
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      dispatch(getLeadStatusList());
    }
  }, []);
  const handleChangeRowPer = async (e) => {
    const payload = {
      ChannelId: props.ChannelId,
      Page: 1,
      filterReq: {
        ...leadReq,
      },
    };
    setRowPerPage(e.target.value);
    if (isOnline) {
      props.activateLoader();

      const result = await props.getFilteredBusinessDataGridLeadList(
        payload.ChannelId,
        payload.Page,
        e.target.value,
        payload.filterReq
      );
      if (result.status === 200) {
        var responseData = [];
        for (let i = 0; i < result.data.Listofleads.length; i++) {
          if (result.data.Listofleads[i].MeetingDate !== null) {
            result.data.Listofleads[i].MeetingDate = moment(
              result.data.Listofleads[i].MeetingDate
            ).format("DD/MM/YYYY");
          }
          if (result.data.Listofleads[i].LastUpdatedDate !== null) {
            result.data.Listofleads[i].LastUpdatedDate = moment(
              result.data.Listofleads[i].LastUpdatedDate
            ).format("DD/MM/YYYY");
          }

          responseData.push(result.data.Listofleads[i]);
        }
        props.deActivateLoader();
        setBusinessLeadForGrid(responseData);
        setRowChangeEvent(e.target.value);
        setTotalCount(result.data.TotalnoofRows);
        setNoOfRecordperPage(NoOfRecordperPage + 10);
        setSelectedRows([]);
        console.log(
          "result.data.Getallcampaignactivitieslistbychannel ",
          result.data.Listofleads
        );
      } else {
        props.deActivateLoader();
      }
    }
  };

  const onChildMount = React.useCallback((dataFromChild) => {
    setInitialPageNo(dataFromChild);
  });
  const onChangePage = async (e, page) => {
    const payload = {
      ChannelId: props.ChannelId,
      filterReq: {
        ...leadReq,
      },
    };
    if (isOnline) {
      props.activateLoader();

      const result = await props.getFilteredBusinessDataGridLeadList(
        payload.ChannelId,
        page + 1,
        rowPerPage,
        payload.filterReq
      );
      if (result.status === 200) {
        var responseData = [];
        for (let i = 0; i < result.data.Listofleads.length; i++) {
          if (result.data.Listofleads[i].MeetingDate !== null) {
            result.data.Listofleads[i].MeetingDate = moment(
              result.data.Listofleads[i].MeetingDate
            ).format("DD/MM/YYYY");
          }
          if (result.data.Listofleads[i].LastUpdatedDate !== null) {
            result.data.Listofleads[i].LastUpdatedDate = moment(
              result.data.Listofleads[i].LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          responseData.push(result.data.Listofleads[i]);
        }
        props.deActivateLoader();
        setBusinessLeadForGrid(responseData);
        console.log(
          "result.data.Getallcampaignactivitieslistbychannel ",
          result.data.Listofleads
        );
        setTotalCount(result.data.TotalnoofRows);
        setNoOfRecordperPage(NoOfRecordperPage + 10);
        setSelectedRows([]);
      } else {
        props.deActivateLoader();
      }
    }
  };

  useEffect(() => {}, [leadReq]);

  const getFilteredLeads = async (filterRequest, teamMemberID) => {
    scrollToTop();
    const req = {
      UserId: (filterRequest.UserID !== null)  ? filterRequest.UserID : (userState.profileInfo.roleId !== 'ADM' ? userState.profileInfo.userId: null),
      //(filterState.activityType !== "null" && filterState.activityType !== undefined) ? filterState.activityType : ( isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload)
      LeadStatus:
        filterRequest.LeadTypeID === 0
          ? null
          : parseInt(filterRequest.LeadTypeID),
      CustomerName:
        filterRequest?.LeadName === 0 ? null : filterRequest.LeadName,
      ActivityId:
        filterRequest?.activityID === 0 ? null : filterRequest.activityID,
      CurrentStatus:
        filterRequest.currentStatus === 0 ||
        filterRequest.currentStatus === null
          ? currentStatusId
          : filterRequest.currentStatus,

      ProductId:
        filterRequest.product === 0 ? null : parseInt(filterRequest.product),
      SPTypeID:
        filterRequest.typeOfSP === 0 ? null : parseInt(filterRequest.typeOfSP),
      BranchId:
        filterRequest?.branchNameCode === 0
          ? null
          : filterRequest.branchNameCode,
      IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
      MeetingStartDate:
        filterRequest.fromdate === null ? null : filterRequest.fromdate,
        MeetingEndDate:
        filterRequest.todate === null ? null : filterRequest.todate,
      MobileNumber:
        filterRequest.MobileNo === 0 ? null : filterRequest.MobileNo,
      CreateStartDate:
        filterRequest.CreatedDateStartTIme === null
          ? null
          : filterRequest.CreatedDateStartTIme,
      CreateEndDate:
        filterRequest.CreatedDateEndTIme === null
          ? null
          : filterRequest.CreatedDateEndTIme,
          EmployeeCode:
          filterRequest?.EmployeeCode === 0 || filterRequest.EmployeeCode === null? null : filterRequest.EmployeeCode,
      //   Campaign :filterRequest.Campaign
    };
    setLeadFilterState({
      ...leadFilterState,
      currentstatus:filterRequest.currentstatus ? filterRequest.currentstatus :[],
      currentStatus:filterRequest?.currentStatus ? filterRequest?.currentStatus :'',
    })
    setLeadReq({
      ...leadReq,
      UserId: req.UserId,
      LeadStatus: req.LeadStatus,
      CustomerName: req.CustomerName,
      ActivityId: req.ActivityId,
      CurrentStatus: req.CurrentStatus,
      ProductId: req.ProductId,
      SPTypeID: req.SPTypeID,
      BranchId: req.BranchId,
      MeetingStartDate: req.MeetingStartDate,
      MeetingEndDate: req.MeetingEndDate,
      IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
      CreateStartDate: req.CreateStartDate,
      CreateEndDate: req.CreateEndDate,
      EmployeeCode: req.EmployeeCode,
      //   Campaign :req.Campaign
    });
    if (isOnline) {
      fetchBusinessLead(props.ChannelId, 1, 20, req);
      setRowPerPage(20);
      setSelectedRows([]);
    }
  };
  const getLeadStatusId = (type) => {
    switch (type) {
      case "open":
        return "2,3,4,7"
      case "un-actioned":
        return "1"
      case "converted":
        return "6"
      case 'dropped':
        return "5"
      case 'all':
        return "1,2,3,4,5,6,7,8,9,10,15,16,17,18,19,20,21,22,23,24"
    }
  };
  const pageReset = () => {
    setInitialPageNo(0);
  };
  const getAllLeadsOfUser = () => {
    scrollToTop();
    const payload = {
      ChannelId: props.ChannelId,
      Page: 1,
      rowPerPage: 20,
      filterReq: {
        CreateStartDate: null,
        CreateEndDate: null,
        MeetingStartDate: null,
        MeetingEndDate: null,
        CustomerName: null,
        CurrentStatus: getLeadStatusId(
          IsRecruitmentlead ? getRecruitmentLeadsOverView : getLeadsOverView
        ),
        ActivityId: null,
        IsRecruitmentLead: IsRecruitmentlead ? 1 : 0,
        BranchId: null,
        UserId: null,
        // Campaign: null,
        MobileNumber: null,
        LeadStatus: null,
        ProductId: 0,
        SPTypeID: 0,
        EmployeeCode:null,
      },
    };
    // if user is non admin, set logged in userid in request for defualt
    if (userState.profileInfo.roleId !=='ADM') {
      payload.filterReq.UserId = userState.profileInfo.userId
      setLeadReq({
        ...leadReq,
        UserId : userState.profileInfo.userId
      });
    }
    setLeadReq({ ...payload.filterReq });
    setRowPerPage(20);
    pageReset();
    setSelectedRows([]);
    if (isOnline) {
      fetchBusinessLead(
        payload.ChannelId,
        payload.Page,
        payload.rowPerPage,
        payload.filterReq
      );
    }
  };

  const scrollToTop = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateSelect({ ...stateSelect, [anchor]: open });
  };
  const handleLeadFilterState = (item) => {
    setLeadFilterState({
      ...item,
    });
  };

  return (
    <>
      {isBrowser && (
        <TabPanel value={value} index={0}>
          <div ref={formRef}>
            <Grid
              className="activiPad0"
              container
              spacing={2}
              columns={{ xs: 4, md: 12 }}
            >
              <Grid item xs={12} md={9} className="leftCol">
                <div className="admindashCompain">
                  <div className="float-right">
                    <button className="btn btn-primary" onClick={csvExp}>
                      <span className="fa fa-download">&nbsp;</span> Download
                      List
                    </button>
                    &nbsp;&nbsp;&nbsp;
                  </div>
                  <div className="clearfix"></div>
                  <MatDatagrid
                    rows={businessLeadForGrid ? businessLeadForGrid : []}
                    columns={columns}
                    props={{ labelRowsSelect: "records" }}
                    search={false}
                    grouping={false} //to show or hide grouping
                    addRowPosition={"first"} //"last"
                    onSelectionChange={(rows) => setSelectedRows(rows)}
                    treeView={false}
                    selection={true}
                    filtering={false}
                    title={props.Listingtitle}
                    pageSize={rowPerPage}
                    paging={false}
                    pageSizeOptions={[15, 20, 25, 50]}
                    showFirstLastPageButtons={false}
                    paginationType={"normal"} //"normal" or "stepped"
                    exportButton={false}
                    exportAllData={true}
                    onRowClick={rowOnRowclick}
                    customPagination={{
                      isDisplay: true,
                      totalCount: totalCount,
                    }}
                    editable={{
                      showDelete: false,
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: " ",
                      },
                    }}
                  />
                </div>
                <div className="float-right">
                  {totalCount && totalCount > 9 ? (
                    <>
                      <label for="selectRecordsPerPage">
                        Records per Page: &nbsp;{" "}
                      </label>
                      <Select
                        value={rowPerPage}
                        onChange={(e) => handleChangeRowPer(e)}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="clearfix">&nbsp;</div>
                {totalCount != 0 ? (
                  <Paginator
                    onMount={onChildMount}
                    rowCount={rowChangeEvent}
                    className="pagination"
                    totalRecoards={totalCount}
                    recordsPerPage={rowPerPage}
                    onPageChange={onChangePage}
                    maxPageLimit={10}
                    initialPage={initialPageNo}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={3} className="rightCol">
                <LeadFilter
                  setLeadReq={setLeadReq}
                  leadReq={leadReq}
                  filterState={leadFilterState}
                  setFilterState={handleLeadFilterState}
                  getFilteredLeads={getFilteredLeads}
                  getAllLeads={getAllLeadsOfUser}
                  parent={"sidebar"}
                  handleClose={toggleDrawer(stateSelect.bottom, false)}
                  chanelId={chanelId}
                />
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      )}
      {isMobile && (
        <ModalPopup
          show={showModal}
          size={"sm"} // "lg","sm" or "xl"
          centered={true} // vertically center the Dialog in the window
          modelHeader={"Invalid Selection"} // to show model header
          modelContent={
            <div className="footerButtons">
              <p>Please view in Desktop/Laptop</p>
              {/* <Button onClick={()=>setShowModal(false)}>OK</Button> */}
            </div>
          }
          backdrop={"static"}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  leadFormData: state.leadFormReducer,
  masterDataState: state.commonReducer,
});
export default connect(mapStateToProps, {
  downloadBusinessLeads,
  downloadBrocaLead,
  downloadBusinessLeadsWithIds,
  getFilteredBusinessDataGridLeadList,
  deActivateLoader,
  activateLoader,
  downloadGCLBusinessLeads,
  downloadGCLBusinessLeadsWithIds
})(LeadListing);
