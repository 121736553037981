import React, { Fragment, useState, useEffect } from "react";

import moment from "moment";
import '../createLead.scss';
import '../leadDetails.scss';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import VideocamIcon from '@mui/icons-material/Videocam';
import SmsIcon from '@mui/icons-material/Sms';
import MailIcon from '@mui/icons-material/Mail';
import NavigationIcon from '@mui/icons-material/Navigation';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import HistoryCard from "../historyCard";
import CheckinMarker from '../../../../assets/img/checkInMarkerDetails.svg';
import { getDateDuration, getMobileOS } from '../../../../utils/utils';
import { Drawer } from "@mui/material";
import MapRoute from "../mapRoute";
import { useParams } from "react-router";
import { ALLOWED_CHECK_IN_LEAD } from "../../../../utils/config";
import useOnlineStatus from "../../../../hooks/useOnlineStatus";
import ModalPopup from "f1-modal-react";
import { Button } from "@material-ui/core";
import BarChartIcon from "@mui/icons-material/BarChart";
import { getSubTitlesForCampaigns } from '../../activity/helper'
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { loggInfo } from "../../../../redux/actions/userAction";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const LeadLayout = (props) => {
    const { id } = useParams();
    const isOnline = useOnlineStatus();
    const locationData = useLocation();
    const { AssignedToName, CampaignName, SalutationName, Email, LeadSourceName,
        ExpectedPremium, PriorityName, ActivityName, SPTypeName,
        BranchCode, BranchName, ClientID, MeetingwithLead, CampaignEndDate,
        MobileNo, NewLeadLocation, ProductName, Remarks
        , CreatedDate, AlternateNo, LeadStatus,
        CheckinCount, LeadStatusId, IsClaimRequired, IsCheckin, MeetingStartDateTime,
        MeetingEndDateTime, FirstName, MiddleName, LastName,
        LeadCampaignID, LGCode, SPCode, ZMAName, PremierZMAName, AdvisorName, ExpectedLeadClosureDate } = props.getCampaignLeadData;
    const ActivityIDlabel = `${BranchName ? BranchName : `N/A`}-${moment(MeetingStartDateTime).format('MMM-YY')}`;

    let ExpectedLeadClosure = ExpectedLeadClosureDate ? ExpectedLeadClosureDate : null;
    const [drawerList, setDrwerList] = useState({
        right: false
    })
    const [showModalSetup, setShowModalSetup] = useState(false);
    const [coords, setCoords] = useState({})
    const commonState = useSelector(state => state)
    const userState = commonState.userReducer;
    useEffect(() => {
        console.log("coords", coords);
    }, [])
    const getIntials = (name) => {
        if (name != null) {
            return name.slice(0, 1).toUpperCase();
        } else {
            return "";
        }
    }
    const leadlistlable = {
        'word-break': 'break-all',
        'margin-right': '17px',
    }
    const icons = {
        'font-size': ' 0.6rem',
        //'margin-left': '15%',
    }
    const iconalign = {
        'text-align': 'center',
    }

    const PriorityColor = {
        Hot: '#DE4039',
        Warm: '#EF7A22',
        Cold: '#0093DE',
        High: '#DE4039',
        Medium: '#EF7A22',
        Low: '#0093DE'
    };

    const formatPhoneNumber = (str) => {
        const cleaned = `( ${str})`.replace(/\D/g, '');

        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `( ${match[1]} ) ${match[2]} ${match[3]}`.replace(/\d{4}$/, '****');
        }

        return null
    };
    const dateformat = "DD MMM YYYY";
    const AlternateNoForm = formatPhoneNumber(AlternateNo);


    const toggleDrawer = (anchor, open) => (event) => {
        if (isOnline) {
            navigator.geolocation.getCurrentPosition((res) => {
                setCoords({ lat: res.coords.latitude, lng: res.coords.longitude })
                setShowModalSetup(false)
                if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                    return;
                }
                setDrwerList({ ...drawerList, [anchor]: open })
            }, (error) => {
                console.log('getCurrentPosition error CL leadLayout', error)
                setCoords({ lat: null, lng: null })
                setDrwerList(false)
                setShowModalSetup(true)
            })

        }
        if (open) {
            const employeeCode = userState.profileInfo.employeeCode;
            const deviceId = userState.profileInfo.deviceId;
            const logInfoRequest = {
                processname: 'Check-in drawer open',
                employeeCode: employeeCode,
                ipAddress: "",
                requestFor: `${'Check-In checkinpersistencylead'}`,
                leadActivityId: id,
                deviceId: deviceId,
                routUrl: locationData.pathname
            }
            loggInfo(logInfoRequest);
        }
    };

    const handleClose = () => {
        setShowModalSetup(false)
        toggleDrawer('right', true)
    }

    // const toggleDrawer = (anchor, open) => (event) => {
    //     if (isOnline) {
    //         if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //             return;
    //         }
    //         setDrwerList({ ...drawerList, [anchor]: open });
    //     }
    // };

    const handleAudioCall = (MobileNum) => {
        MobileNum && window.open(`tel:${MobileNum}`)
    }

    const handleSMS = (MobileNum) => {
        MobileNum && window.open(`sms:${MobileNum}`)
    }

    const handleVideoCall = () => {
        //video
    }

    const handleNavigate = () => {
        //naviagate

    }
    const handleEmail = (mail) => {
        mail && window.open(`mailto:${mail}`)

    }
    const getUpdatedHistory = () => {
        props.onGetUpdatedHistory();
    }
    const isMobileOS = getMobileOS()

    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const channel = JSON.parse(userData.userReducer).profileInfo.channelid;

    return < div >
        <Box sx={{ flexGrow: 1 }}>
            <Grid className="leadDetailsSec" container spacing={3}>
                <Grid item xs={12} md={4} >

                    <Paper className={props.classes.paper} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid className="branchDiv" item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={4} md={4} >
                                        <Paper className={props.classes.activityLogo} style={{ color: "blue" }}>
                                            <Typography variant="h5" component="h2">
                                                {getIntials(FirstName)}{" "}
                                                {getIntials(LastName)}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={8} md={8}>
                                        <Typography variant="h5" component="h2" className={props.classes.heading} style={leadlistlable}>
                                            <b>{SalutationName}{" "}{FirstName} {" "} {LastName}</b>
                                        </Typography>

                                        <Grid>
                                            <span className="hotClass">
                                                <span className="icnNo">
                                                    <BarChartIcon
                                                        style={{ color: PriorityColor[PriorityName] }}
                                                    />
                                                </span>
                                                <span
                                                    className="subTitle"
                                                    style={{ color: PriorityColor[PriorityName] }}
                                                >
                                                    {PriorityName} Lead
                                                </span>

                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Lead ID</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {LeadCampaignID ? LeadCampaignID : "N/A"}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Current Status</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {LeadStatus}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Assigned To</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {AssignedToName}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Campaign</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {CampaignName}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Created Date</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span className="date">{CreatedDate ? moment(CreatedDate).format(dateformat) : ""}</span>
                                                <span className="time">{CreatedDate ? moment(CreatedDate).format('h:mma') : ""}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Meeting Date</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span className="date">
                                                    {MeetingStartDateTime ? moment(MeetingStartDateTime).format(dateformat) : ""}
                                                </span>
                                                <span className="time">
                                                    {MeetingStartDateTime ? moment(MeetingStartDateTime).format('h:mma') : ""}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    {ExpectedLeadClosure !== null && userState.profileInfo.channelid == 1 &&
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Expected lead closure date</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span className="date">
                                                        {ExpectedLeadClosure ? moment(ExpectedLeadClosure).format(dateformat) : ""}
                                                    </span>

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                    {!ALLOWED_CHECK_IN_LEAD.includes(LeadStatus) && (isMobileOS !== "Other") &&
                        <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                            <Grid container spacing={1}>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <ListItem alignItems="flex-start" onClick={toggleDrawer('right', true)}>
                                        <Grid className='checkInDiv' container spacing={4}>
                                            <Grid item xs={4} md={4} >
                                                <img src={CheckinMarker} alt={'Marker'}></img>
                                            </Grid>
                                            <Grid item xs={7} md={7} >
                                                <label style={{ color: 'black' }}><b>Check In</b></label>
                                            </Grid>
                                            <Grid item xs={1} md={1} >
                                                <span style={{ cursor: isOnline ? 'pointer' : 'not-allowed' }} onClick={toggleDrawer('right', true)}>
                                                    <ArrowRightAltIcon />
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    {['right'].map((anchor) => (
                                        <Fragment key={anchor}>
                                            <Drawer
                                                anchor={anchor}
                                                open={drawerList[anchor]}
                                                onClose={toggleDrawer(anchor, false)}>

                                                <MapRoute status="checkincampaignlead"
                                                    checkinCount={CheckinCount}
                                                    id={id} statusId={LeadStatusId}
                                                    setDrwerList={setDrwerList}
                                                    onCheckInComplete={getUpdatedHistory}
                                                />

                                            </Drawer>
                                        </Fragment>
                                    ))}
                                </List>
                            </Grid>
                        </Paper>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>Lead Personal Details </b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="leadPersonalDiv" item xs={12} md={12} >

                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Salutation</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {SalutationName}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>First Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span style={leadlistlable} >{FirstName}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Middle Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span style={leadlistlable} >{MiddleName}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Last Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7}>
                                                <span style={leadlistlable} > {LastName}</span>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Mobile No.</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {'+91 '}{MobileNo?.replace(/\d{4}$/, '****')}
                                                <Grid container>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={() => handleAudioCall(MobileNo)}>
                                                                <WifiCalling3Icon />
                                                                <em>Audio Call</em>
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={handleVideoCall}>
                                                                <VideocamIcon />
                                                                <em>Video Call</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className="bxSvg" onClick={() => handleSMS(MobileNo)}>
                                                                <SmsIcon />
                                                                <em>SMS</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Alternate Mobile No.</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {AlternateNoForm ? `+1 ${AlternateNoForm}` : ''}
                                                <Grid container>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className={AlternateNoForm ? 'bxSvg' : 'bxSvg activeClass'} onClick={() => handleAudioCall(AlternateNoForm)}>
                                                                <WifiCalling3Icon />
                                                                <em>Audio Call</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className={AlternateNoForm ? 'bxSvg' : 'bxSvg activeClass'} onClick={handleVideoCall}>
                                                                <VideocamIcon />
                                                                <em>Video Call</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <Grid item xs={12} md={12} style={iconalign}>
                                                            <span className={AlternateNoForm ? 'bxSvg' : 'bxSvg activeClass'} onClick={() => handleSMS(AlternateNoForm)}>
                                                                <SmsIcon />
                                                                <em>SMS</em>
                                                            </span>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Email</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                <span style={leadlistlable}>{Email}</span>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={4} md={5} className="buttonBox" style={iconalign}>
                                                        <span className="bxSvg" onClick={() => handleEmail(Email)}>
                                                            <MailIcon />
                                                            <em>Email</em>
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>New Lead Location</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >

                                                {NewLeadLocation}
                                                <Grid container spacing={4}>
                                                    <Grid item xs={4} md={5} className="buttonBox" style={iconalign}>
                                                        <span className="bxSvg" onClick={handleNavigate}>
                                                            <NavigationIcon />
                                                            <em style={icons}>Navigate</em>
                                                        </span>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <Divider />
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>
                                            Product Details</b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="listPT0 prodList" item xs={12} md={12} >
                                <List>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Product Name</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {ProductName}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                    <ListItemText primaryTypographyProps={{ fontSize: 12 }}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={5} md={5} >
                                                <label>Expected Premium</label>
                                            </Grid>
                                            <Grid className="lastitem" item xs={7} md={7} >
                                                {ExpectedPremium}
                                            </Grid>
                                        </Grid>
                                    </ListItemText>
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={props.classes.paper2} sx={{ mb: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} >
                                        <Item className="activityDetailsHead"><b>Business Details</b></Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="listPT0" item xs={12} md={12} >
                                <Grid item xs={12} md={12} >
                                    <List>
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Lead Status</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {PriorityName} Lead
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Lead source</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {LeadSourceName}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Activity ID</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {getSubTitlesForCampaigns(ActivityName, "", "", MeetingStartDateTime)}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Activity Name</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {ActivityName}

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        {channel != 3 && <>
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Type Of SP</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {SPTypeName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Branch Name & Code</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {BranchName}{" "} {BranchCode}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>LG CODE </label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {LGCode}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>SP CODE </label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {SPCode}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Client id </label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {ClientID}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                        </>}
                                        {channel == 3 && <>
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>ZMA Name</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {ZMAName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Premier ZMA Name</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {PremierZMAName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={5} md={5} >
                                                        <label>Advisor</label>
                                                    </Grid>
                                                    <Grid className="lastitem" item xs={7} md={7} >
                                                        {AdvisorName}
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                            <Divider />
                                        </>
                                        }
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Campaign Name</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {CampaignName}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Meeting with Lead?</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {MeetingwithLead ? "Yes" : "No"}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />

                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Meeting Date & Time</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span className="date">
                                                        {MeetingStartDateTime ? moment(MeetingStartDateTime).format(dateformat) : "N/A"}
                                                    </span>
                                                    <label className='time'>
                                                        {MeetingStartDateTime ? moment(MeetingStartDateTime).format('h:mma') : ""}
                                                        -
                                                        {/* {MeetingStartDateTime ? moment(addMinuteToDate(MeetingEndDateTime)).format('h:mma') : ""} */}
                                                        {MeetingStartDateTime ? moment(MeetingEndDateTime).format('h:mma') : ""}
                                                    </label><br></br>

                                                    {(ExpectedLeadClosure !== null && userState.profileInfo.channelid == 1) ? <> </> :
                                                        <label className='duration'>
                                                            {MeetingStartDateTime && "("}
                                                            {MeetingStartDateTime ? `${getDateDuration(MeetingStartDateTime, MeetingEndDateTime, 'HOUR')}` : ''}
                                                            {' '}
                                                            {MeetingStartDateTime ?
                                                                `${getDateDuration(MeetingStartDateTime, MeetingEndDateTime, 'MINUTE')}`
                                                                : ''}
                                                            {MeetingStartDateTime && ")"}
                                                        </label>
                                                    }

                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>End Date</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    <span className="date">{CampaignEndDate ? moment(CampaignEndDate).format(dateformat) : ""}</span>
                                                    <span className="time">{CampaignEndDate ? moment(CampaignEndDate).format('h:mma') : ""}</span>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                        <Divider />
                                        <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={5} md={5} >
                                                    <label>Remarks</label>
                                                </Grid>
                                                <Grid className="lastitem" item xs={7} md={7} >
                                                    {Remarks ? Remarks : '-'}
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <HistoryCard classes={props.classes} leadHistoryData={props.hitory}
                        Item={Item} ActivityIDlabel={ActivityIDlabel}
                        IsCheckin={IsCheckin} IsClaimRequired={IsClaimRequired} />
                </Grid>

            </Grid>
        </Box>
        <ModalPopup
            show={showModalSetup}
            onHide={() => setShowModalSetup(!showModalSetup)}
            size={"sm"}
            centered={true}
            modelHeader={"Please enable your location"}
            modelContent={
                <Button
                    onClick={handleClose}
                >
                    CLOSE
                </Button>
            }
            backdrop={"static"}
        />
    </div >
}
export default LeadLayout;
