import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { useSelector } from 'react-redux';
const PrimaryDetailsCard = (props) => {

    const { activityData, classes } = props;
    const commonState = useSelector((state) => state)
    const roleId = commonState.userReducer.profileInfo


    const takeWordFirstLetter = (name) => {
        const str = []
        if (name) {
            name.split(" ").map(el => {
                if (el.charAt(0) !== "(" && el.charAt(0) === el.charAt(0).toUpperCase()) {
                    str.push(el.charAt(0))
                }
            })
        }
        return str.toString().replaceAll(',', '')
    }

    return <Paper className={classes.paper} sx={{ mb: 2 }}>
        <Grid className='activityDetailsSec' container spacing={3}>
            <Grid className='branchDiv' item xs={12} md={12} >
                <Grid container spacing={3}>
                    <Grid item xs={4} md={4} >
                        <Paper className={classes.activityLogo}>
                            <Typography variant="h5" component="h3">
                                {takeWordFirstLetter(activityData.ActivityIdName === "Meeting/Visit" ? (activityData.ActivityIdName.replace('/', ' ')) : activityData.ActivityIdName)}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={8} md={8}>
                        <Typography variant="h6" component="h2" className={classes.heading}>
                            <b>{activityData.ActivityIdName}</b>
                        </Typography>
                        <span className='activityName'>
                            {activityData.ActivityName}
                        </span>
                    </Grid>
                </Grid>
                <Divider />
            </Grid>

            <Grid item xs={12} md={12} >
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={5} md={5} >
                                <label>Activity ID</label>
                            </Grid>
                            {activityData.TrnActivityID === undefined &&
                                <Grid item xs={7} md={7} className="lastitem">
                                    {activityData.CampaignActivityid}
                                </Grid>
                            }
                            {activityData.TrnActivityID!== "" && activityData.CampaignActivityid ===undefined &&
                                <Grid item xs={7} md={7} className="lastitem">
                                    {activityData.TrnActivityID}
                                </Grid>
                            }
                        </Grid>
                    </ListItemText>
                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={5} md={5} >
                                <label>Activity Status</label>
                            </Grid>
                            {activityData.TrnActivityID === undefined && activityData.CampaignActivityid ===undefined &&
                                <Grid item xs={7} md={7} className="lastitem" >
                                    Completed
                                </Grid>
                            }
                            {activityData.TrnActivityID!== "" &&
                                <Grid item xs={7} md={7} className="lastitem" >
                                    {activityData.ActivityStatus}
                                </Grid>
                            }
                        </Grid>
                    </ListItemText>
                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={5} md={5} >
                                <label>Assigned To</label>
                            </Grid>
                            {activityData.TrnActivityID === undefined && activityData.CampaignActivityid === undefined &&
                                <Grid item xs={7} md={7} className="lastitem">
                                    {activityData.UserName}
                                </Grid>
                            }
                            {activityData.TrnActivityID!=="" &&
                                <Grid item xs={7} md={7} className="lastitem">
                                    {activityData?.BDMName?.trim() !== '' ? activityData.BDMName : activityData.UserName}
                                </Grid>
                            }
                        </Grid>
                    </ListItemText>
                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={5} md={5} >
                                <label>Assigned By</label>
                            </Grid>
                            <Grid item xs={7} md={7} className="lastitem">
                                {
                                    roleId !== "ABH" && activityData?.ABHName && activityData?.ABHName !== ' ' ?
                                        <>{activityData?.ABHName}</>
                                        :
                                        <>
                                            Self
                                        </>
                                }
                            </Grid>
                        </Grid>
                    </ListItemText>
                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={5} md={5} >
                                <label>Created Date</label>
                            </Grid>
                            <Grid item xs={7} md={7} className="lastitem">
                                <span className="date">{activityData.CreatedDate ? moment(activityData.CreatedDate).format('DD MMM YYYY') : ""}</span>
                                <span className="time">{activityData.CreatedDate ? moment(activityData.CreatedDate).format('h:mma') : ""}</span>
                            </Grid>
                        </Grid>
                    </ListItemText>
                    <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={5} md={5} >
                                <label>Meeting Date</label>
                            </Grid>
                            <Grid item xs={7} md={7} className="lastitem">
                                <span className="date">{activityData.MeetingStartDateTime ? moment(activityData.MeetingStartDateTime).format('DD MMM YYYY') : ""}</span>
                                <span className="time">{activityData.MeetingStartDateTime ? moment(activityData.MeetingStartDateTime).format('h:mma') : ""}</span>
                            </Grid>
                        </Grid>
                    </ListItemText>
                    {activityData?.Month_Year && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={5} md={5} >
                                <label>Month and Year</label>
                            </Grid>
                            <Grid item xs={7} md={7} className="lastitem">
                                <span className="date">{activityData.Month_Year ? activityData.Month_Year : ""}</span>
                            </Grid>
                        </Grid>
                    </ListItemText>}
                </List>
            </Grid>
        </Grid>
    </Paper>
}

export default PrimaryDetailsCard
