import React, { useState } from "react";
import LeadListingPerSistancyDatagrid from "./persistencyLeadDatagridListing";
import { connect ,useSelector } from "react-redux";
import {
  activateLoader,
} from "../../../redux/actions/common";


export const PersistancyAgencyListing = () => {
  const ChannelId = 3;
  const PERSISTANCY_LEAD_AGENCY = "Persistency Agency Lead Listing";
  const filetype = "persistencyleadagency";
  const persistencyid = "2";
  const fileName = "PersistencyLeadsAgency_";

  return (
    <>
      <LeadListingPerSistancyDatagrid
        ChannelId={ChannelId}
        Listingtitle={PERSISTANCY_LEAD_AGENCY}
        persistencyid={persistencyid}
        filetype={filetype}
        fileName={fileName}
      />
    </>
  );
};

export const PersistancyBancaListing = () => {
  const filetype = "persistencylead";
  const ChannelId = 1;
  const PERSISTANCY_LEAD_BANCA = "Persistency Banca Lead Listing";
  const persistencyid = "1";
  const fileName = "PersistencyLeadsBanca_";

  return (
    <>
      <LeadListingPerSistancyDatagrid
        ChannelId={ChannelId}
        Listingtitle={PERSISTANCY_LEAD_BANCA}
        filetype={filetype}
        persistencyid={persistencyid}
        fileName={fileName}
      />
    </>
  );
};

export const PersistancyCscListing = () => {
  const ChannelId = 2;
  const PERSISTANCY_LEAD_CSC = "Persistency CSC Lead Listing";
  const filetype = "persistencyleadcsc";
  const persistencyid = "3";
  const fileName = "PersistencyLeadsCSC_";
  return (
    <>
      <LeadListingPerSistancyDatagrid
        ChannelId={ChannelId}
        Listingtitle={PERSISTANCY_LEAD_CSC}
        filetype={filetype}
        persistencyid={persistencyid}
        fileName={fileName}
      />
    </>
  );
};
const PersistancyListingAll  = (props) => {
  const commonState = useSelector((state) => state);
  const userState = commonState.userReducer;
  const [channelId] = useState(userState.profileInfo.channelid)
  const LEAD_TITLE = "Persistency Lead Listing";
  const [recruitment] = useState(true);
  const fileName = "PersistencyLeads_";
return (
  <>
      <LeadListingPerSistancyDatagrid
      ChannelId={channelId}
      Listingtitle={LEAD_TITLE}
      Isrecruitmentlead={recruitment}
      fileName={fileName}
    />
  </>
);
}

const mapStateToProps = (state) => ({
masterDataState: state.commonReducer,
});
export default (
connect(mapStateToProps, {
  activateLoader,
})(PersistancyListingAll)
);
