import { isAlphabetOnly, isBranchValid, isEmailValid, isMeetindDate, numberStartWith6789Validator, isPositiveNumber ,isTwoDigitIntiger, isAlphanumeric } from "../../../../utils/validators";
import { format } from "date-fns";
import { ALLOWED_FUTURE_DAYS } from "../../../../utils/config";
import { getDateAfterDays } from "../../../../utils/utils";
import moment from "moment";
const dateFormate = 'yyyy-MM-dd';
const minDate = format(new Date(), dateFormate) + "T" + format(new Date(), 'kk:mm');
const endMaxDate = moment(new Date()).endOf('day').format("YYYY-MM-DD kk:mm");
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";
const meetingStartDateAndTimeRequired = "Meeting start time is required.";
const meetingEndDateAndTimeRequired = "Meeting end time is required."
const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
const datetimelocal = "datetime-local"

export const customValidators = [
    {
        key: 'FirstName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'LastName',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'CoBorrower',
        validator: isAlphabetOnly,
        message: 'Please enter alphabets only.'
    },
    {
        key: 'Email',
        validator: isEmailValid,
        message: 'invalid email id with missing domain name, @, .com, gmail id etc.'
    },
    {
        key: 'BranchID',
        validator: isBranchValid,
        message: "Branch code and activity id's branch code should be same."
    },
    {
        key: 'MobileNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
    },
    {
        key: 'AlternateNo',
        validator: numberStartWith6789Validator,
        message: '10 digit phone number starting with 6,7,8,9 only.'
    },
    {
        key: 'MeetingDate',
        validator: isMeetindDate,
        message: "Meeting already exits."
    },
    {
        key: "ExpectedPremiumAmount",
        validator: isPositiveNumber, // function to be called
        message: "Expected Premium amount should be greater than 0", // message to show
    },
    {
        key: "LoanAmount",
        validator: isPositiveNumber, // function to be called
        message: "Loan amount  should be greater than 0", // message to show
    },
    {
        key: "RateOfInterest",
        validator: isTwoDigitIntiger, // function to be called
        message: "Maximum 2 number and decimals upto 3 number allowed",
    },
    {
        key: 'LGCODE',
        validator: isAlphanumeric,
        message: 'Please enter alphanumeric only.'
    },
]


export const GCLLeadForm = [
    {
        "key": "SalutationID", "type": "select", "disabled": false, "id": "SalutationID", "label": "Salutation",
        "validators": [{ "name": "required", "value": true, "validationMessage": "This field is required"    }],
        "options": []
    },
    {
        "key": "FirstName", "type": "text", "id": "FirstName", "label": "First Name",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "key": "LastName", "type": "text", "id": "LastName", "label": "Last Name",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 letters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "key": "MobileNo", "type": "number", "id": "MobileNo", "label": "Mobile No.",
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required." },
            { "name": "minLength", "value": 10, "validationMessage": "Min length for Mobile No is 10 digits" },
            { "name": "maxLength", "value": 10, "validationMessage": "Mobile number field require 10 digits." }]
    },
    {
        "key": "BranchID", "type": "select", "disabled": false, "id": "BranchID", "label": "Branch code (Sol ID)", "validators": [{ "name": "required", "value": true,"validationMessage": "Please select branch code." }],
        "options": []
    },
    {
        "key": "BranchName", "type": "text", "id": "BranchName", "label": "Branch name", "hide": true,disabled:true,
        "validators": [{ "name": "required", "value": true,"validationMessage": "Please select branch name." }]
    },
    {
        "key": "BankName", "type": "select", "id": "BankName", "label": "Bank name (Lead from)", "hide": true,
        "validators": [{ "name": "required", "value": true,"validationMessage": "Please select bank name." }],
        "options": []
    },
    {
        "key": "SMSId", "type": "select", "id": "SMSId", "label": "SMS code", "hide": true,
        "validators": [{ "name": "required", "value": true ,"validationMessage": "Please select sms code."}],
        "options": []
    },
    {
        "key": "LGID", "type": "select", "id": "LGID", "label": "LG code", "hide": true,
        "validators": [{ "name": "required", "value": true , "validationMessage": "Please select LG code."}],
        "options": []
    },
    {
        "key": "LGCode", "type": "text", "id": "LGCode", "label": "LG code", "hide": true,
        "validators": [{ "name": "required", "value": true , "validationMessage": "This field is required"},
        { "name": "minLength", "value": 4, "validationMessage": "Please enter at least 4 characters" },
        { "name": "maxLength", "value": 9, "validationMessage": "Please enter no more than 9 characters." }]
    },
    {
        "key": "GCLLeadSource", "type": "text", "id": "GCLLeadSource", "label": "Lead source", "hide": false, "disabled":true
    },
    {
        "key": "LoanTypeID", "type": "select", "id": "LoanTypeID", "label": "Loan Type", "hide": false,
        "validators": [{ "name": "required", "value": true , "validationMessage": "Please select loan type" }],
        "options": []
    },
    {
        "key": "LoanAmount", "type": "number", "id": "LoanAmount", "label": "Loan amount", "hide": false,
        "validators": [{ "name": "required", "value": true , "validationMessage": "Please enter loan amount" },
            { "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 digit" },
            { "name": "maxLength", "value": 9, "validationMessage": "Please enter no more than 9 digit." }]
    },
    {
        "key": "ExpectedPremiumAmount", "type": "number", "id": "ExpectedPremiumAmount", "label": "Expected premium amount", "hide": false,
        "validators": [
            { "name": "required", "value": true, "validationMessage": "Please enter expected premium amount" },
            { "name": "minLength", "value": 1, "validationMessage": "Please enter at least 1 digit" },
            { "name": "maxLength", "value": 7, "validationMessage": "Please enter no more than 7 digit." }
        ]
    },
    {
        "key": "LoanACReferenceNo", "type": "text", "id": "LoanACReferenceNo", "label": "Loan a/c Reference no. | File", "hide": false,
        "validators": [
            { "name": "maxLength", "value": 40, "validationMessage": "Please enter no more than 40 characters." }
        ]
    },
    {
        "key": "PriorityID", "type": "select", "disabled": false, "id": "PriorityID", "label": "Lead Status",
        "options": []
    },
    {
        "key": "MeetingStartDateTime", "noDate": null, "min": minDate,
        "max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormate) + "T" + "23:59",
        "type": datetimelocal, "id": "MeetingStartDateTime",
        "label": meetingStartDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingStartDateAndTimeRequired }]
      },
      {
        "key": "MeetingEndDateTime", "noDate": null, "min": minDate, "type": datetimelocal, "id": "MeetingEndDateTime",
        "max": endMaxDate,
        "label": meetingEndDateAndTimeLabel, "validators": [{ "name": "required", "value": true, "validationMessage": meetingEndDateAndTimeRequired }]
      },
    {
        "key": "GCLCallDurationId", "type": "select", "disabled": false, "id": "GCLCallDurationId", "label": "Duration", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select meeting duration." }],
        "options": []
    },
    {
        "key": "Gender", "type": "select", "disabled": false, "id": "Gender", "label": "Gender", "validators": [{ "name": "required", "value": true, "validationMessage": "Please select gender." }],
        "options": []
    },
    {
        "key": "DOB", "type": "date", "disabled": false, "id": "DOB", "label": "DOB",
        "max": format(new Date(), 'yyyy-MM-dd'),
         "validators": [{ "name": "required", "value": true , "validationMessage": "Please select DOB."}],
    },
    {
        "key": "Email", "type": "email", "id": "Email", "label": "Email", "hide": false,
        // "validators": [{ "name": "isEmail", "value": true, "validationMessage": "Please enter a valid email ID" }]
    },
    {
        "key": "RateOfInterest", "type": "text", "id": "RateOfInterest", "label": "Rate of Interest", "hide": false,
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
        ]
    },
    {
        "key": "CoBorrower", "type": "text", "id": "CoBorrower", "label": "Co-Borrower", "hide": false,
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
            { "name": "minLength", "value": 3, "validationMessage": "Please enter at least 3 characters" },
            { "name": "maxLength", "value": 30, "validationMessage": "Please enter no more than 30 characters." }
        ]
    },
    {
        "key": "InsuranceRequired", "type": "select", "id": "InsuranceRequired", "label": "Insurance Required", "hide": false,
        "options": [{ "key": "Yes", "label": "Yes", "value": "Yes" },{ "key": "No", "label": "No", "value": "No" }],
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
        ]
    },
    {
        "key": "MemberFormFilled", "type": "select", "id": "MemberFormFilled", "label": "Member form filled and pending for collection", "hide": false,
        "options": [{ "key": "No", "label": "No", "value": "No" },{ "key": "Yes", "label": "Yes", "value": "Yes" }],
        "validators": [
            { "name": "required", "value": true, "validationMessage": "This field is required" },
        ]
    },
]









