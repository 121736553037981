const dateFormat = 'yyyy-MM-dd';
const max100Charmessage = "Maximum 100 charactors allowed.";
const claimtravelExpenseLabel = "Do You Want to Claim Travel Expense?";
const optionSelectValidationMessage = "Please select one option.";
const cssBorder = "1px solid #ddd";
const cssactiveBoxShadow = "0px 0px 1px 2px #fffc35";
const modeOfTransportLabel = "Mode of Transport";
const travelExpenseLabel = "Travel Expense";
const travelExpenseValidationmessage = "Travel expense is required.";
const fourDigitAllowed = "Maximum 4 digits allowed";
const billPhoto="Upload bill photo";
const uploadBill = "Please Upload Bill Copy";
const acceptFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const vehicleType="Vehicle Type";
const modeOftransportRequired="mode of Trasport is required.";

export const ActivityCompletedMeetingVisitForm = [
    {
      "key": "SubjectAgendaOfMeeting",
       "props": { "min": "0" }, 
       "hide": false,
      "type": "text", "disabled": false, "id": "SubjectAgendaOfMeeting",
       "label": "Subject/Agenda of meeting",
        "validators": [
        { "name": "required",
        "value": true,
        "validationMessage": "Subject/Agenda of meeting is required." },
        { "name": "maxLength", "value": 30, "validationMessage": "Maximum 30 digits allowed" }]
    },
    { "key": "Remarks",
     "hide": false,
      "type": "text",
       "disabled": false,
       "id": "Remarks",
       "label": "Remarks",
        "validators": [
          { "name": "required", "value": false, "validationMessage": "Remarks is required." },
           { "name": "maxLength", "value": 100, "validationMessage": max100Charmessage }] },
    {
      "key": "IsClaimRequired",
      "name": "IsClaimRequired",
      "type": "CustomSwitch",
      "disabled": false,
      "id": "IsClaimRequired",
      "label": claimtravelExpenseLabel,
      "validators": [{ "name": "required", "value": false, "validationMessage": optionSelectValidationMessage }], handleDiameter: 45,
      offColor: "#08f",
      onColor: "#0ff",
      offHandleColor: "#0ff",
      onHandleColor: "#08f",
      className: "radioBoxDiv",
      height: 26,
      width: 75,
      borderRadius: 24,
      border: cssBorder,
      activeBoxShadow: cssactiveBoxShadow,
      uncheckedIcon: <div className="switchUnChecked">Yes</div>,
      checkedIcon: <div className="switchChecked">No</div>
    },
    {
      "key": "UploadPhoto",
      "name": "UploadPhoto",
      "hide": false,
      "type": "file",
      "dropTitle":"Photo Upload" ,
      "acceptFileTypes": acceptFileType,
      "componentType": "DRAG_DROP_BROWSE",
      "layout": "1column",
      "restrictMultipleFiles": true,
      "disabled": false,
      "id": "UploadPhoto",
      "label": "Upload Photo",
      "validators": []
    }
  ]
