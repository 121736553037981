import { get, post } from "../api/api";
import {
  GET_ACTIVITY_BY_USER_ID,
  GET_BRANCH_DETAILS,
  GET_LG_SP_DETAILS,
  GET_TEAM_LIST,
  GET_MASTER_DATA,
  PULL_HISTORICAL_LEADS,
  PULL_LEAD,
  PUSH_LEAD,
  LMS_GENERIC_API_BASE_URL,
  GET_SRM_DETAILS,
} from "../api/baseURL";
import {
  addHistoricalLeadsInDb,
  addLeadInDb,
  addUpdateLastLeadSyncTime,
  getAllLeadOffline,
  deleteLeads,
} from "./leadOfflineManager";
import { add } from "./localbase";

export const getAutoCompleteActivities = async (payload) => {
  try {
    await get(`${GET_ACTIVITY_BY_USER_ID}/${payload.userId}`).then(
      (response) => {
        if (response.status === 200) {
          return getAutoCompleteItems(response.data);
        }
        return null;
      }
    );
  } catch (e) {
    return console.error(e);
  }
};
const getAutoCompleteItems = (autoCompleteItemsArr) => {
  let options = [];
  if (autoCompleteItemsArr && autoCompleteItemsArr.length > 0) {
    options = autoCompleteItemsArr.map((ac, idx) => {});
  }
  options.unshift({ id: "", label: "select" });
  return options;
};
export const getLeadSourceList = () => {
  return new Promise((resolve, reject) => {
    get(`${GET_MASTER_DATA}${"LeadSource"}`)
      .then((response) => {
        const dataObject = {
          leadSource: response.data,
        };
        add("LeadSource", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getZMAList = () => {
  new Promise((resolve, reject) => {
    get(`${GET_MASTER_DATA}${"ZMADetails"}`)
      .then((response) => {
        const dataObject = {
          zMADetails: response.data,
        };
        add("ZMADetails", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getProductDetails = () => {
  new Promise((resolve, reject) => {
    get(`${GET_MASTER_DATA}${"ProductDetails"}`)
      .then((response) => {
        const dataObject = {
          productDetails: response.data,
        };
        add("ProductDetails", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getSalutationsDetails = () => {
  new Promise((resolve, reject) => {
    get(`${GET_MASTER_DATA}${"Salutation"}`)
      .then((response) => {
        const dataObject = {
          salutation: response.data,
        };
        add("Salutation", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getSPTypeDetails = () => {
  new Promise((resolve, reject) => {
    get(`${GET_MASTER_DATA}${"SPType"}`)
      .then((response) => {
        const dataObject = {
          sPType: response.data,
        };
        add("SPType", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getLeadTypeDetails = () => {
  new Promise((resolve, reject) => {
    get(`${GET_MASTER_DATA}${"LeadType"}`)
      .then((response) => {
        const dataObject = {
          leadType: response.data,
        };
        add("LeadType", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getBranchDetailsForUser = (userId) => {
  new Promise((resolve, reject) => {
    get(`${GET_BRANCH_DETAILS}/${userId}`)
      .then((response) => {
        const dataObject = {
          branchDetails: response.data,
        };
        // getLGValueReducer(response.data[0].BranchCode);
        add("BranchDetails", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getLGValueReducer = (branchCode) => {
  return new Promise((resolve, reject) => {
    get(`${GET_LG_SP_DETAILS}/${branchCode}`)
      .then((response) => {
        const dataObject = {
          lgSp: response.data,
        };
        add("LgSpDetails", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};
export const getTeamListForUSer = (userId) => {
  new Promise((resolve, reject) => {
    get(`${GET_TEAM_LIST}/${userId}`)
      .then((response) => {
        const dataObject = {
          teamList: response.data,
        };
        add("TeamList", dataObject)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => reject(err));
  });
};
export const pullLeadList = (id, lastSyncTime) => {
  return new Promise((resolve, reject) => {
    const url = lastSyncTime
      ? `${PULL_LEAD}${"/"}${id}${"?leadlastSyncTime="}${lastSyncTime}`
      : `${PULL_LEAD}${"/"}${id}`;
    get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => reject(false));
  });
};
export const pushLeadList = (leadData) => {
  const formdata = {
    jsondata: leadData,
  };
  return new Promise((resolve, reject) => {
    post(PUSH_LEAD, formdata)
      .then((response) => {
        console.log("pushLeadList response ", response.data);
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const pullLeadSave = async (userId, leadLastSyncTime) => {
  return pullLeadList(userId, leadLastSyncTime)
    .then((res) => {
      const leadCount = res.length;
      if (res) {
        addLeadInDb(res).then((result) => {
          console.log("::: pullLeadSave lead added in db :::");
          if (result && result.length === leadCount) {
            // update the lead sync time
            const numberOfMillisecondsSinceEpoch = Date.now();
            console.log("lead update time ", numberOfMillisecondsSinceEpoch);
            addUpdateLastLeadSyncTime(numberOfMillisecondsSinceEpoch);
            return true;
          }
          return false;
        });
      } else {
        console.log("pullLeadList false", res);
      }
    })
    .catch((err) => {
      console.log("pullLeadList err", err);
    });
};
export const initLeadPull = (userId, leadLastSyncTime) => {
  console.log("timer set to pull leads");
  setTimeout(() => {
    pullLeadSave(userId, leadLastSyncTime);
  }, 15000);
};
export const initPushLead = (userId, leadLastSyncTime) => {
  return getAllLeadOffline().then((res) => {
    if (res && res.length > 0) {
      const leadList = res.filter((lead) => lead.IsLmsSync === false);
      const last3Again = leadList;
      console.log(":: available leads for push::: ", leadList);
      if (leadList.length > 0) {
        pushLeadList(last3Again)
          .then((result) => {
            console.log("on push lead success", result);
            if (result.GuidDetails && result.GuidDetails.length > 0) {
              // write a logic to update the leads in indexDB
              initLeadPull(userId, leadLastSyncTime);
              deletePushedLeads(result.GuidDetails);
            }
          })
          .catch((err) => {
            console.log("on push lead error", err);
            initLeadPull(userId, leadLastSyncTime);
          });
      } else {
        initLeadPull(userId, leadLastSyncTime);
      }
    } else {
      initLeadPull(userId, leadLastSyncTime);
    }
  });
};
export const getSyncConfigData = () => {
  get(`${LMS_GENERIC_API_BASE_URL}${"/api/configurationinfo"}`).then(
    (response) => {
      const dataObject = {
        configData: response.data,
      };
      add("configData", dataObject);
    }
  );
};
export const getLgSPForUSer = async (userId) => {
  return new Promise(async (resolve, reject) => {
    await get(`${GET_LG_SP_DETAILS}${"/"}${userId}`)
      .then((response) => {
        if (response) {
          const dataObject = {
            LgSpList: response.data,
          };
          add("LgSpList", dataObject)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
              reject();
            });
        }
      })
      .catch((err) => {
        console.log(err);
        reject();
      });
  });
};
const deletePushedLeads = (leads) => {
  deleteLeads(leads).then((result) => {
    console.log("::: deletePushedLeads :::", result);
  });
};

//FPM Lead
// export const getSRMByBranch = (branchCode) => {
//     new Promise((resolve, reject) => {
//         get(`${GET_SRM_DETAILS}/${branchCode}`)
//         .then(response => {
//             const dataObject = {
//                 SRMDetails: response.data
//             }
//             add('SRMDetails', dataObject).then(res => {
//                 resolve(res)
//             }).catch(err => reject(err))
//         }).catch(err => reject(err))
//     })
// }

export const getSRMforUser = async (userId) => {
  return new Promise(async (resolve, reject) => {
    await get(`${GET_SRM_DETAILS}${"/"}${userId}`)
      .then((response) => {
        if (response) {
          const dataObject = {
            SRMList: response.data,
          };
          add("SRMList", dataObject)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              console.log(err);
              reject();
            });
        }
      })
      .catch((err) => {
        console.log(err);
        reject();
      });
  });
};

//Historical Lead

export const pullHistoriCalLeadsList = (
  leadTypeID,
  userID,
  lastSyncTime = 0
) => {
  return new Promise((resolve, reject) => {
    const url = `${PULL_HISTORICAL_LEADS}/${leadTypeID}/${userID}${"?leadlastSyncTime="}${lastSyncTime}`;
    get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => reject(false));
  });
};

const pullHistoricalLeadsSave = async (
  LeadtypeID,
  userId,
  leadLastSyncTime
) => {
  return pullHistoriCalLeadsList(LeadtypeID, userId, leadLastSyncTime)
    .then((res) => {
      const leadCount = res.length;
      if (res) {
        addHistoricalLeadsInDb(res).then((result) => {
          if (result && result.length === leadCount) {
            const numberOfMillisecondsSinceEpoch = Date.now();
            addUpdateLastLeadSyncTime(numberOfMillisecondsSinceEpoch);
            return true;
          }
          return false;
        });
      } else {
        console.log("pullHistoricallead false", res);
      }
    })
    .catch((err) => {
      console.log("pullHistoricallead err", err);
    });
};

export const initHistoricalLeadPull = (userId, leadLastSyncTime) => {
  setTimeout(() => {
    pullHistoricalLeadsSave(1, userId, leadLastSyncTime);
  }, 15000);
};
