import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => {
  return createStyles({
    bar: {
      top:0,
      left:0,
      width: '100%',
      position:"absolute",
      zIndex:"199999",
      '& > * + *': {
        marginTop: theme.spacing(1),
        marginButtom:theme.spacing(1)
      },
    },
  });
});

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.bar} >
      <LinearProgress />
    </div>
  );
};
