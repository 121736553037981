import React, { useEffect } from "react"
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useHistory } from "react-router-dom";
import "./tableNcard.scss";
//import { getOptionById } from "../../pages/postlogin/activity/helper";

const appactivityupdate = '/app/activity/update';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  activityLogo: {
    textAlign: 'center',
  },
  heading: {
    padding: "10px 20px 20px 10px"
  },
  subHeading: {
    fontSize: 16,
    fontWeight: '400',
    padding: "5px 10px !important"
  },
  subHeadingBold: {
    fontWeight: 'bold'
  }
}));
const Card = ({ isForSMA,item, activityData ,isForCampaign}) => {
  const history = useHistory();
  // console.log(item)
  const { ActivityIdName, ActivityName, ActivityStatus, AssignedToName, CreatedDate, MeetingStartDateTime } = item;
  //const commonState = useSelector(state => state);
  // const [branchNameData, setbranchNameData] = useState("");
  // const [branchCodeData, setbranchCodeData] = useState("");
  useEffect(() => {
    //const branchName = getOptionById(BranchId?.toString(), commonState.commonReducer?.Branches)
    // if (branchName) {
    //   setbranchNameData(branchName.label)
    //   setbranchCodeData(branchName.key)
    // }
  }, [activityData]);

  const takeWordFirstLetter = (name) => {
    const str = []
    if (name) {
      const strings = name.split(" ");
      strings.map(el => {
        if(el.charAt(0) !== '(' && el.charAt(0) === el.charAt(0).toUpperCase()){
        str.push(
          el.charAt(0)
        )
        }
      })
    } else {
      return null;
    }
    return str.toString().replaceAll(',', '')
  }
  const classes = useStyles();
  return (
    <div className="listingCard" onClick={() => {
      isForSMA ? history.push(`/app/salesmanagement/detail/${item.TrnActivityID || item.LmsActivityGuid}`):
       isForCampaign ? history.push(`/app/activity/campaigndetails/${item.CampaignActivityid}`):
      history.push(`/app/activity/detail/${item.TrnActivityID || item.LmsActivityGuid}`)
    }
    }>
      {ActivityStatus!== "Activity Drafted" &&
      <Grid container spacing={3} textalign="left">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={3} style={{ position: "relative" }} className="cardBoxAcivity">
              <Grid item xs={2} md={2} className={classes.activityLogo} style={{ justifyContent: 'space-around' }}>
                <Typography variant="h5" component="h2" className="boxName">
                  {takeWordFirstLetter(ActivityIdName === "Meeting/Visit" ? (ActivityIdName.replace('/',' ')) : ActivityIdName)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Grid container spacing={3} className="cardTop">
                  <Grid item xs={12} md={12}>
                    <Typography variant="h5" component="h2" className={classes.heading}>
                      <b className="mobHeader">{ActivityIdName}</b>
                      {/* <span className="branchName">{getSubTitles(ActivityIdName, branchCodeData, branchNameData, CreatedDate, branchCodeData)}</span> */}
                      <span className="branchName">{ActivityName}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5} className="buttonBox">
                    <span
                      className="icn rightIcn"><ArrowRightAltIcon /></span>
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="labelGroup">
                  <Grid item xs={12} md={3} className={classes.subHeading}><label>Created Date</label><br />
                    <span
                      className={classes.subHeadingBold}>
                      {CreatedDate ? moment(CreatedDate).format('DD MMM YYYY') : ""}
                    </span>
                    <span className="timeStamp">
                      {CreatedDate ? moment(CreatedDate).format('h:mma') : ""}
                    </span>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.subHeading}>
                    <label>Meeting Date</label><br />
                    <span className={classes.subHeadingBold}>
                      {MeetingStartDateTime ? moment(MeetingStartDateTime).format('DD MMM YYYY') : ""}
                    </span>
                    <span className="timeStamp">
                      {MeetingStartDateTime ? moment(MeetingStartDateTime).format('h:mma') : ""}
                    </span>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.subHeading}><label>Activity Status</label><br />
                    <span className="subHeadBlue"><span className={classes.subHeadingBold}>{ActivityStatus}</span></span>
                  </Grid>
                  <Grid item xs={12} md={3} className={classes.subHeading}><label>Assigned To</label><br />
                    <span className={classes.subHeadingBold} >{AssignedToName}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      }
    </div>
  )
}
export default Card
