import React from "react";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AgencyActivityForm, handleActivitySelection, initFormData, getAutoCompleteItems, validateExtraValidations ,tiketsizeExtraValidations} from "./agency/createActivityForm";
import { addDynamicData,updateDynamicData, getMasterData, uploadFileToBlob, activateSuccessMessageBar, activateErrorMessageBar,
   activateLoader, deActivateLoader, getUserBranches, setBreadcrumbs
  } from "../../../redux/actions/common";
import { validateActivity } from "../../../redux/actions/createActivityActions";
import { CONFIGS, FOUR_WHEELER, PUBLIC_TRANSPORT, SELF_OWNED_TRANSPORT, TWO_WHEELER, STATUS_ACTIVITY_DRAFTED, STATUS_ACTIVITY_PLANNED ,checkInConfig} from "../../../utils/config";
import './createActivity.scss';
import { isPositiveNumber ,checkDateIsValid} from "../../../utils/validators";
import moment from "moment";
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { getOptionByCode } from "./helper";
import SaveAsDraftDrawer from "./saveAsDraftDrawer";
import { saveActivityOffline } from "../../../offlineManager/activityOfflineManager"
import { v4 as uuidv4 } from 'uuid';

import { finishDayActivityCreate,getAdvisorZMAPremierZMAListing } from "../../../redux/actions";
import { getActivityDetails } from '../../../redux/actions/activityDetailsAction';

const customValidators = [
  {
    'key': 'ExpectedLead',
    'validator': isPositiveNumber, // function to be called
    'message': 'Expected leads should be greater than 0' // message to show
  },
  {
    'key': 'ExpectedConversion',
    'validator': isPositiveNumber, // function to be called
    'message': 'Expected conversion should be greater than 0' // message to show
  },
  {
    'key': 'TicketSize',
    'validator': isPositiveNumber, // function to be called
    'message': 'Ticket size should be greater than 0' // message to show
  },
  {
    'key': 'ExpenseAmount',
    'validator': isPositiveNumber, // function to be called
    'message': 'Expense amount should be greater than 0' // message to show
  },
  {
    'key': 'BillNumber',
    'validator': isPositiveNumber, // function to be called
    'message': 'Bill number should be greater than 0' // message to show
  },
  {
    'key': 'TravelExpene',
    'validator': isPositiveNumber, // function to be called
    'message': 'Travel expense should be greater than 0' // message to show
  }
]

const createActivityTitle = "Create Activity";
const activityListing = '/app/activity/all' ;
const _formatDate = "YYYY-MM-DDTHH:mm"
class ResumeDraftedActivityAgency extends React.Component {
  constructor() {
    super();
    this.dyFormRef = React.createRef();
    this.state = {
      current: {
        id: 1,
        MeetingEndDateTime: '',
        IsClaimRequired:false,
        BranchIdlabel:"",
        
      },
      formData: AgencyActivityForm,
      autoCompleteItems: {
        BranchId: []
      },
      uploadedFile: '',
      originalFileName: '',
      extErrors: {},
      ExpectedConversion: '',
      ExpectedLead: '',
      isActiveMobileDraft: false,
      
      isFormSUbmitDisabled: false,
      responseMessage:""
    }
  }


  loadBreadCrumbs = () =>{
    const breadcrumbs = [
      { name: 'Activity', url: activityListing },
      { name: createActivityTitle, url: '/app/activity/create' },
    ]
    this.props.setBreadcrumbs(breadcrumbs);
  }

  isValidBusinessActivity = async (formData) =>{
    if(formData.hasOwnProperty('MeetingStartDateTime') && formData.hasOwnProperty('MeetingEndDateTime')){
      const checkActivity = await this.props.validateActivity({user_id: this.props.userState.profileInfo.userId, formData: formData});
      if(checkActivity.status === 200 && checkActivity.data.Responsestatus === true){
        return true
      }else{
        this.setState({
          responseMessage: checkActivity.data.Responsemessage
        })
      }
      return false
    }
    return true
  }

  reset = (model) => {
    for(const [key] of Object.entries(model)){
      model[key] = ''
    }
    this.setState({uploadedFile: '', originalFileName: '', extErrors: {}})
    this.dyFormRef.reset(model)
  }

  onSubmitHandler = async (model) => {
    if(!model.isValidForm || Object.keys(this.state.extErrors).length > 0 || Object.keys(model.errors).length > 0){
      return false;
    }
    const formData = {...model};
    delete formData['advanceLayoutFields'];
    delete formData['collapseStates'];
    delete formData['errors'];
    delete formData['isValidForm'];
    delete formData['id'];
    delete formData['BranchIdlabel']
    formData["IsBacklog"] = false;
    if(formData.hasOwnProperty("ZMAID") && (!formData.hasOwnProperty("RecruitmentFor") || formData.RecruitmentFor==null)&& (!formData.hasOwnProperty("VisitToID")||formData.VisitToID==null)){

      let takeIdString = formData["ZMAID"]?.map(el=>{
        return el.value
      })
      formData["ZMAID"] = takeIdString?.toString()
    }

    if(formData.hasOwnProperty("AdvisorID") && (!formData.hasOwnProperty("RecruitmentFor") || formData.RecruitmentFor==null)&& (!formData.hasOwnProperty("VisitToID")||formData.VisitToID==null)) {

      let takeIdStringForAdvisorId = formData["AdvisorID"]?.map(el=>{
        return el.value
      })
      formData["AdvisorID"] = takeIdStringForAdvisorId?.toString()
    }

    if(formData.hasOwnProperty("PremierZMAID") && (!formData.hasOwnProperty("RecruitmentFor") || formData.RecruitmentFor==null)&& (!formData.hasOwnProperty("VisitToID")||formData.VisitToID==null)){

      let takeIdStringForPremierZMA = formData["PremierZMAID"]?.map(el=>{
        return el.value
      })
      formData["PremierZMAID"] = takeIdStringForPremierZMA?.toString()
    }

    if(formData.hasOwnProperty('MeetingStartDateTime')){
      formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"].replace("T", ' ')
    }
    if(formData.hasOwnProperty('MeetingEndDateTime')){
      formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace("T", ' ')
    }
    if(formData.hasOwnProperty('UploadBill')){
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }

    const statusOption =  getOptionByCode(STATUS_ACTIVITY_PLANNED, this.props.masterDataState.ActivityStatus)
    formData["ActivityStatusId"] = statusOption.value;
    formData["ActivityStatus"] = statusOption.label
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format("YYYY-MM-DD HH:mm:ss");
    formData["ModifiedDate"] =  moment().format("YYYY-MM-DD HH:mm:ss");
    formData["LmsleadGuid"] = uuidv4();
    formData["LmsleadlastSyncTime"] = new Date().toISOString().replace('T', ' ').replace('Z', '');
    formData["IsLmsSync"] = navigator.onLine;
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;

    // validate the activity from server side
    if (navigator.onLine){
      this.props.activateLoader();
      const isValidActivity = await this.isValidBusinessActivity(formData)
      if(isValidActivity){

        const result = await this.props.updateDynamicData({'data': [formData],
         'formName': CONFIGS.UPDATE_ACTIVITY_FORM,formBuilder:"", 
         userID: this.props.userState.profileInfo.userId});
        if(result.status === 200 && result.data.Responsestatus === true){
          this.props.activateSuccessMessageBar("Activity has been created successfully.");
          this.props.deActivateLoader();
          const saveFormData = {...formData};
          //  SAVE activity in indexDb online
          saveFormData['TrnActivityID'] = result.data.Transactionid;
          saveActivityOffline(saveFormData).then((res) => {
            if (res) {
              //this.reset(formData);
              this.props.history.push(`/app/activity/detail/${result.data.Transactionid}`)
            }
          })
        }
        else{
          this.props.deActivateLoader();
          this.props.activateSuccessMessageBar("Something went wrong! please try again later.")
        }
      }
      else{
        this.props.activateErrorMessageBar(this.state.responseMessage)
        this.props.deActivateLoader()
      }
    } else {
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          const {data: {key}} = res;
          this.props.history.push(`/app/activity/detail/${key}`)
        }
      })
    }
  }


  handleSaveAsDraft = async (e) =>{
    e.preventDefault();
    this.props.activateLoader();
    const formData = {...this.dyFormRef.state};
    delete formData['advanceLayoutFields'];
    delete formData['collapseStates'];
    delete formData['errors'];
    delete formData['isValidForm'];
    delete formData['id'];
    delete formData['BranchIdlabel']
    formData["IsBacklog"] = false;
    if(formData.hasOwnProperty("ZMAID") && (!formData.hasOwnProperty("RecruitmentFor") || formData.RecruitmentFor==null)&& (!formData.hasOwnProperty("VisitToID")||formData.VisitToID==null)){

      let takeIdString =formData["ZMAID"]?.map(el=>{
        return el.value
      })
      formData["ZMAID"] = takeIdString?.toString()
    }

    if(formData.hasOwnProperty("AdvisorID") && (!formData.hasOwnProperty("RecruitmentFor") || formData.RecruitmentFor==null)&& (!formData.hasOwnProperty("VisitToID")||formData.VisitToID==null)) {
      let takeIdStringForAdvisorId = formData["AdvisorID"]?.map(el=>{
        return el.value
      })
      formData["AdvisorID"] = takeIdStringForAdvisorId?.toString()
    }

    if(formData.hasOwnProperty("PremierZMAID") && (!formData.hasOwnProperty("RecruitmentFor") || formData.RecruitmentFor==null)&& (!formData.hasOwnProperty("VisitToID")||formData.VisitToID==null)){
      let takeIdStringForPremierZMA = formData["PremierZMAID"] && formData["PremierZMAID"]?.map(el=>{
        return el.value
      })
      formData["PremierZMAID"] = takeIdStringForPremierZMA?.toString()
    }
    if(formData.hasOwnProperty('MeetingStartDateTime')){
      formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"].replace("T", ' ')
    }
    if(formData.hasOwnProperty('MeetingEndDateTime')){
      formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace("T", ' ')
    }
    if(formData.hasOwnProperty('UploadBill')){
      formData["UploadBill"] = this.state.uploadedFile;
      formData["BillFileName"] = this.state.originalFileName;
    }
    const statusOption =  getOptionByCode(STATUS_ACTIVITY_DRAFTED, this.props.masterDataState.ActivityStatus)
    formData["ActivityStatusId"] = statusOption.value
    formData["UserId"] = this.props.userState.profileInfo.userId;
    formData["AssignedTo"] = this.props.userState.profileInfo.userId;
    formData["CreatedDate"] = new Date().toISOString().replace('T', ' ').replace('Z', '');
    formData["ModifiedDate"] =  new Date().toISOString().replace('T', ' ').replace('Z', '');
    formData["CreatedBy"] = this.props.userState.profileInfo.userId;
    //formData["IsClaimRequired"] = formData["IsClaimRequired"] ? "Yes" : "No"

    const result = await this.props.updateDynamicData({'data': [formData],
     'formName': CONFIGS.UPDATE_ACTIVITY_FORM,formBuilder:"", 
     userID: this.props.userState.profileInfo.userId})
    if(result.status === 200 && result.data.Responsestatus === true){
      this.props.activateSuccessMessageBar("Activity has been drafted successfully.")
    //  this.reset(formData);
      this.props.deActivateLoader();
      this.props.history.push(activityListing)
    }
    else{
      this.props.deActivateLoader();
      this.props.activateSuccessMessageBar("Something went wrong! please try again later.")
    }
  }
  getSelectedAutocompletList =(fullList,ids)=>{
    let filteredList = fullList?.filter(ele => ids?.includes(ele.UserID.toString())).map((el5)=>{
      return {key: `${el5['Name']+" "+el5['Code']}`, label: `${el5['Name']}`, value: el5['UserID']}
   })

    

    return filteredList
  }
  getActivityDraftData = async()=>{
    this.props.activateLoader()
    const activityId = this.props.match.params.id;
    const result = await this.props.getActivityDetails({ 'activityId': activityId })
    if (result.status === 200) {
     const data = result.data;
     let selectedFormData =this.state.formData;
     const defaultState=data;
    
     if(data?.ActivityID){
    const formData = handleActivitySelection({target:{value:data.ActivityID.toString()}}, 
    "ActivityID", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId);
    selectedFormData=[...formData];
    defaultState.ActivityID=data.ActivityID.toString();
    selectedFormData[2].options = await this.getAgencyAdvisorMapping("Advisor");
    selectedFormData[3].options = await this.getAgencyAdvisorMapping("ZMA");
   
    if(data?.ActivityID==15){
      //
    }else{
    defaultState.AdvisorID= this.getSelectedAutocompletList(this.props.masterDataState?.AdvisorList,data.AdvisorID?.split(","));
    defaultState.ZMAID= this.getSelectedAutocompletList(this.props.masterDataState?.ZMAList,data.ZMAID?.split(","));
    defaultState.PremierZMAID= this.getSelectedAutocompletList(this.props.masterDataState?.PremierZMA,data.PremierZMAID?.split(","));
   
      // delete defaultState.AdvisorID;
      // delete defaultState.ZMAID;
    }
     }
   
     if(data.TicketSize){
      defaultState.TicketSize=data.TicketSize.toString()
     }
     if(data.ExpectedLead){
      defaultState.ExpectedLead=data.ExpectedLead.toString()
     }
     if(data.TravelExpence){
      defaultState.TravelExpence=data.TravelExpence.toString()
     }
     if(data.ExpectedConversion ){
      defaultState.ExpectedConversion=data.ExpectedConversion.toString()
     }
     if(data?.VisitToID){
      defaultState.VisitToID=data.VisitToID.toString();
      const formData = handleActivitySelection({target:{value:data.VisitToID.toString()}}, 
      "VisitToID", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId);
      selectedFormData=[...formData];
      
     }
     if(data?.ActivityDoneWith ==1){
      selectedFormData[2].hide=false
     }
     if(data?.ActivityDoneWith ==2){
      selectedFormData[3].hide=false
     }
     if(data?.ActivityDoneWith ==3){
      selectedFormData[4].hide=false
     }
     if(data?.BranchId){
      defaultState.BranchId=data.BranchId.toString();
      defaultState.BranchName=data.BranchName;
      defaultState.BranchIdlabel=data.BranchName;
       }
       if(data?.ActivitySubTypeId){
        const formData = handleActivitySelection({target:{value:data.ActivitySubTypeId.toString()}}, 
        "ActivitySubTypeId", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId)
        selectedFormData=[...formData];
        defaultState.ActivitySubTypeId=data.ActivitySubTypeId.toString();
       }
     if(data?.IsClaimRequired===("True" || true)||data?.IsClaimRequired===("False" || false)){
         const flag=data?.IsClaimRequired===("True" || true)?true:false
        const formData = handleActivitySelection({target:{value:flag}},
           "IsClaimRequired",selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId)
        selectedFormData=[...formData]
    }
      this.setState({formData : selectedFormData, current:defaultState})
      this.props.deActivateLoader()
    }

  }

  componentDidMount = () =>{
    this.loadBreadCrumbs();
    const formData = this.props.masterDataState ? initFormData(this.state.formData, this.props.masterDataState) : this.state.formData;
    const autoCompleteItems = {...this.state.autoCompleteItems};
    autoCompleteItems.BranchId = this.props.masterDataState ? getAutoCompleteItems(this.props.masterDataState, CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME) : []
    this.setState({formData : formData, autoCompleteItems: autoCompleteItems})
    this.getActivityDraftData();
  }

  uploadFile = async (files) =>{
    const file = files[0]
    if(file){
      const fileName = new Date().getTime() + file.name;
      const fileRes = await this.props.uploadFileToBlob({'file': file, 'fileName': fileName});
      if(fileRes.status === 200){
        this.setState({uploadedFile: fileName, originalFileName: file.name})
      }
    }
  }
  checkFinishDayActivity=async(value)=>{
    if (value === "6") {
      try {
        const result = await this.props.finishDayActivityCreate(
          this.props.userState.profileInfo.userId
        );
        if (result.status === 200) {
          this.setState({ isFormSUbmitDisabled: false });
        }
      } catch (e) {
        this.setState({ isFormSUbmitDisabled: true });
      }
    } else {
      this.setState({ isFormSUbmitDisabled: false })
    }
  }
  getAgencyAdvisorMapping = async (status) => {
    const AgencyAdvisorMappingList = await this.props.getAdvisorZMAPremierZMAListing({
      userId: this.props.userState.profileInfo.userId,
      status: status
    });
    let arr = [];
    if (AgencyAdvisorMappingList && AgencyAdvisorMappingList.data.length > 0) {
      arr = AgencyAdvisorMappingList.data.map((option) => {
        if(status==="ZMA" || status==="Advisor" || status==="Premier ZMA"){
          return { key: `${option['Name']+" "+option['Code']}`, label: `${option['Name']}`, value: option['UserID'] }
        }else{
        return { key: option['UserID'], label: `${option['Name']}`, value: option['UserID'] }
        }
      })
    }

    arr.unshift({ "key": "select", "label": "select", "value": "" })
    return arr;
  }
  onChangeHandler = async (event, key,model,newValue) => {
    if (key === "IsClaimRequired" && this.state.checkedinCount == 0) {
      this.props.activateErrorMessageBar(
        "No Checkin Found For Today."
      );
      this.setState({
        current: { ...this.state.current, IsClaimRequired: false },
      });
      return false

    }
    
    if (key === 'ActivityDoneWith') {
      this.props.activateLoader();
    
      this.setState({current:{...this.state.current, ActivityDoneWith:event.target.value} })
      const form = [...this.state.formData]
      switch (parseInt(event.target.value)) {
        case 1:
          form[2].options = await this.getAgencyAdvisorMapping("Advisor");
          form[2].hide = false;
          form[3].hide = true;
          form[4].hide = true;
          form[2].validators = [{ name: "required", value: true, validationMessage: "This is required." },];
          form[3].validators = [];
          form[4].validators = [];
          this.setState({ formData: form})
          this.props.deActivateLoader();
          break;
        case 2:
          delete this.state.current.AdvisorID;
          form[3].hide = false;
          form[3].options = await this.getAgencyAdvisorMapping("ZMA");
          form[2].hide = true;
          form[4].hide = true;
          form[3].validators = [{ name: "required", value: true, validationMessage: "This is required." },];
          form[2].validators = [];
          form[4].validators = [];
          this.setState({ formData: form })
          this.props.deActivateLoader();
          break;
        case 3:
          delete this.state.current.AdvisorID;
          form[4].hide = false;
          form[4].options = await this.getAgencyAdvisorMapping("Premier ZMA");
          form[2].hide = true;
          form[3].hide = true;
          form[4].validators = [{ name: "required", value: true, validationMessage: "This is required." },];
          form[3].validators = [];
          form[2].validators = [];

          this.setState({ formData: form })
          this.props.deActivateLoader();
          break;
        default:

          break;


      }/*EOF Switch*/
      
    }

    
    if (key === "MeetingStartDateTime") {
      var StartDate = moment(event.target.value).format(_formatDate);
      const newDate = moment(event.target.value)
        .add(30, "m")
        .format(_formatDate);
      this.state.formData.map((option) => {
        if (option.key === "MeetingEndDateTime") {
          option.max = moment(StartDate).endOf('day').format("YYYY-MM-DD kk:mm");
          option.min = StartDate;
        }
        return option
      })
      if (!checkDateIsValid(event.target.value, moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"))) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, [key]: "please select future value" }
        })
      } else {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors }
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingStartDateTime: StartDate, MeetingEndDateTime: newDate },
      });
    }
    if (key === "MeetingEndDateTime") {
      const newDate = moment(event.target.value).format(_formatDate);
      if (!checkDateIsValid(event.target.value, this.state.current.MeetingStartDateTime)) {
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors, "MeetingEndDateTime": "meeting end date should not less than meeting starting date" },
        })
      } else {
        delete this.state.extErrors?.MeetingEndDateTime;
        this.setState({
          ...this.state, extErrors: { ...this.state.extErrors },
        })
      }
      this.setState({
        current: { ...this.state.current, MeetingEndDateTime: newDate }
      });
    }
    if (key === "Location") {
      model.errors={}
      this.setState({ [key]: event.target?event.target.value:event,  current: { ...this.state.current, Location: event.target?event.target.value:event } })
    }
    if (key === "VisitToID" || key ==="RecruitmentFor"){
      this.setState({ [key]: event.target.value,  current: { ...this.state.current, [key]: event.target.value } })
    }
    
    if(event.target&&event.target.value>0 &&( key ==="ZMAID" ||key ==="PremierZMAID"||key==="EAPIDs"||key==="AdvisorID")) {
      this.setState({ [key]: event.target?event.target.value:[],  current: { ...this.state.current, [key]: event.target?event.target.value:[] } })
    }else{
      console.log(model)
    
    }
    if(newValue !=undefined&&newValue?.length>0 && (key ==="ZMAID" ||key ==="PremierZMAID"||key==="EAPIDs"||key==="AdvisorID")){
      let uniqueIds=[];
        const unique = newValue.filter(element => {
          const isDuplicate = uniqueIds.includes(element.key);
          if (!isDuplicate) {
            uniqueIds.push(element.key);
            return true;
          }
          return false;
        });
      this.setState({current:{...this.state.current,[key]:unique}})
//console.log(newValue)
    }
    const formData = handleActivitySelection(
      event,
      key,
      this.state.formData,
      this.props.masterDataState,
      this.props.userState.profileInfo.roleId
    );
    this.setState({ formData: formData }, () => {
      let form = [...this.state.formData];
      if (key === "ActivityID") {
        const activityDoneWith = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY_DONE_WITH.TABLE_NAME];
        const RecruitmentFor = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.RECRUITMENT_FOR.TABLE_NAME];
        const VisitTo = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VISIT_TO.TABLE_NAME];

        form = form.map((val) => {
          if (val.key === "ActivityDoneWith") {
            val.options = activityDoneWith;
          }
          if (val.key === "RecruitmentFor") {
            val.options = RecruitmentFor;
          }
          if (val.key === "VisitToID") {
            val.options = VisitTo;
          }

          return val;
        })
      }

      this.setState({ formData: form })
    });

  
    if (key === "ExpectedLead" || key === "ExpectedConversion") {
      const extErrors = validateExtraValidations({ ...this.state, [key]: event.target.value });
      this.setState({ current: { ...this.state.current, [key]: event.target.value } })
      if (extErrors.hasOwnProperty('ExpectedConversion')) {
        this.setState({ [key]: event.target.value, current: { ...this.state.current ,[key]: event.target.value}, extErrors: { ...this.state.extErrors, ...extErrors } })
      } else if (extErrors.hasOwnProperty('ExpectedLead')) {
        this.setState({ [key]: event.target.value, current: { ...this.state.current ,[key]: event.target.value}, extErrors: { ...this.state.extErrors, ...extErrors } })
      } else {
        delete this.state.extErrors?.ExpectedConversion;
        // this.setState({ [key]: event.target.value,current:{...this.state.current,[key]: event.target.value,ExpectedConversion:event.target.value}, extErrors: {...this.state.extErrors}})
        this.setState({ [key]: event.target.value, current: { ...this.state.current ,[key]: event.target.value}, extErrors: { ...this.state.extErrors } })

      }
    }
    if (key === "TicketSize") {

      if (event.target.value > 0) {
        delete this.state.extErrors?.TicketSize;
        this.setState({ [key]: event.target.value, extErrors: { ...this.state.extErrors },current:{...this.state.current,[key]: event.target.value} });
      } else {
        const extErrors = tiketsizeExtraValidations(this.state);
        this.setState({ [key]: event.target.value, extErrors: { ...this.state.extErrors, ...extErrors } });
      }
    }

    if (key === "ActivityID") {
      this.setState({ current: { ...this.state.current, ActivityID: event.target.value } })
    }
    if (key === "TrainingTopic") {
      this.setState({ current: { ...this.state.current, TrainingTopic: event.target.value } })
    }
  
  
  }
  onAutocompleteSelectHandler = (key, value, item) =>{
   this.setState({current:{...this.state.current,[key]:value},[key]:value})
  }

  onCancelHandler = () =>{
    const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
    if(isMobile){
      this.setState({isActiveMobileDraft: true})
    }
    else{
      this.props.history.push(activityListing)
    }
  }

  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({uploadedFile: ''})
  }

  renderUploadedFilesItem = (file, index, key) =>{
      return (
        <li style={{borderColor:'#ccc'}} key={index}>
          <span className="file-name">{file.name}</span>
          <span className="action" onClick={(e)=>this.removeFile(e, file, index, key)}>
            <i className="fa fa-trash"></i>
          </span>
        </li>
      );
  }

  renderRadioIcons = (radioOption) =>{
    let icon = ""
    if(radioOption.key === PUBLIC_TRANSPORT){
      icon = <DirectionsBusIcon></DirectionsBusIcon>
    }
    else if(radioOption.key === SELF_OWNED_TRANSPORT){
      icon = <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if(radioOption.key === TWO_WHEELER){
      icon = <TwoWheelerIcon></TwoWheelerIcon>
    }
    else if(radioOption.key === FOUR_WHEELER){
      icon = <DirectionsCarIcon></DirectionsCarIcon>
    }
    return icon
  }

  toggleDrawer = (flag) =>{
    this.setState({isActiveMobileDraft: flag});
  }

  onDontSaveDraftHandler = () =>{
    this.props.history.push(activityListing)
  }

  onSaveDraftHandler = () =>{
    this.handleSaveAsDraft(new Event('test'))
  }



  render() {
    const { t } = this.props;
    return <div className="mainContainer createActivity">
      <Breadcrumb></Breadcrumb>
      {/* <h3 className="pageHead">{createActivityTitle}</h3> */}
      <span className="formInfo">{t('Please fill in the activity information')}</span>
      <div className="formBox">
        <DynamicForm
                  key={this.state.current.id}
                  onRef={ref => this.dyFormRef=ref}
                  title={t(createActivityTitle)}
                  buttonSubmit={t('Submit')}
                  defaultValues={this.state.current}
                  model={this.state.formData}
                  onSubmit={(model) => {
                    this.onSubmitHandler(model);
                  }}
                  disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
                  design="bootrstap"
                  materialOutline={false}
                  onChange={(event, key,model,newValue)=>this.onChangeHandler(event, key,model,newValue)}
                  buttonNormalClick={(id,key,obj)=>{
                  }}
                  onAutocompleteSelect={this.onAutocompleteSelectHandler}
                  autoCompleteItems={this.state.autoCompleteItems}
                  showAstersikOnRequiredFields={true}
                  extErrors={this.state.extErrors}
                  checkInConfig={checkInConfig}
                  className="activityTypeDiv"
                  extaraActionFormButton={<>
                    <a className="savedraftLink" onClick={(e)=>this.handleSaveAsDraft(e)} href="#">SAVE AS DRAFT</a>
                    <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{t('Cancel')}</button>
                    </>}
                  fileUploaderFields={['UploadBill']}
                  renderUploadedFilesItem={this.renderUploadedFilesItem}
                  customValidators={customValidators}
                  renderRadioIcons={(option)=>this.renderRadioIcons(option)}
                />
            </div>
            <SaveAsDraftDrawer
              isActiveMobileDraft={this.state.isActiveMobileDraft}
              toggleDrawer={this.toggleDrawer}
              onDontSaveHandler={this.onDontSaveDraftHandler}
              onSaveDraftHandler={this.onSaveDraftHandler}
            ></SaveAsDraftDrawer>
    </div>
  }
}

const mapStateToProps = state => ({
  masterDataState: state.commonReducer,
  userState: state.userReducer
});
const mapDispatchToProps = dispatch => ({
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
  getMasterData: (payload) => dispatch(getMasterData(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  getUserBranches: (payload) => dispatch(getUserBranches(payload)),
  getActivityDetails: (payload) => dispatch(getActivityDetails(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  
  getAdvisorZMAPremierZMAListing: (payload) => dispatch(getAdvisorZMAPremierZMAListing(payload)),
  finishDayActivityCreate: (payload) =>
    dispatch(finishDayActivityCreate(payload)),
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(ResumeDraftedActivityAgency));
