import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.scss'
import CommonCalendarCard from "./commonCalendarCard";
import { getCalendarLeadandActivityById } from '../../../redux/actions/calendarActions';
import { withTranslation } from 'react-i18next';
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Card from '@mui/material/Card';
import { activateLoader, deActivateLoader } from "../../../redux/actions/common";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import useOnlineStatus from '../../../hooks/useOnlineStatus';
import WeeklyCalendar from './commonWeeklyCalendar';
import CalenderFilter from "./calenderFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {  levelbyUser } from "../../../utils/config"; 
import { getCalendarListData, setCalendarSelectedData } from "../../../redux/reducers/calendarActivityReducer";
import PlanYourDay from "./planYourDay/planYourDay";
import { getFilteredCalendarData, activateErrorMessageBar } from "../../../redux/actions";
import ModalPopup from "f1-modal-react";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { loggInfo } from "../../../redux/actions/userAction";

const DateFormatString = 'YYYY-MM-DD'

const useStyles = makeStyles((theme) => ({
    calendarIcon: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block"
        }
    },
    calendarWeeklyView: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block"
        }
    },
    calendarMonthlyView: {
        display: "block",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    drawerMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
            display: "block",
            position: "fixed",
            bottom: 69,
            right: 20

        }
    },
    desktopFilter: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    }
}))

const CommonCalendar = (props)  => {
    const commonState = useSelector(state => state);
    const classes = useStyles();
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();
    const locationData = useLocation();
    const [BDMSelected, setBDMSelected] = useState(props.selectedBDM);
    const [viewSelected, setViewSelected] = useState(props.selectedView);
    const [date, setDate] = useState(new Date());
    const [userId] = useState(commonState.userReducer.profileInfo.userId)
    const [channelid] = useState(commonState.userReducer.profileInfo.channelid)
    const { leadActivityCalendarList, totalCount } = useSelector(state => state.calendarActivityReducer);
    const [showModalSetup, setShowModalSetup] = useState(false);
    const [showCalendar, setShowCalendar] = useState(true);
    const [filterClose, setFilterClose] = useState(true)
    const changeDate = (e) => {
        setDate(e)
        const updatedDate = moment(e).format(DateFormatString);
        dispatch(setCalendarSelectedData(updatedDate))
        getCalendarLeadActivityListBYDateChange(updatedDate)
    }
    //  const history = useHistory();

    const userState = commonState.userReducer;
    const userViewFor = commonState.commonReducer.viewForStatus;
    const GetBDMByABH = commonState.commonReducer.GetBDMByABH;
    const roleId = userState?.profileInfo?.roleId;
    const bdmList = commonState.leadFormReducer.BDMList.UsersDetails;
    const ABHListForZBH = commonState.leadFormReducer.ABHListForZBH;
    const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelSubordinateList?.UsersDetails;
    const thirdLevelSubordinateList = commonState.leadFormReducer?.thirdLevelSubordinateList?.UsersDetails;
    const fourthLevelSubordinateList = commonState.leadFormReducer?.fourthLevelSubordinateList?.UsersDetails;
    const All = "all";

    const getCalendarLeadActivityList = async (newDate) => {
        setBDMSelected(props.selectedBDM);
        setViewSelected(props.selectedView);
        if (isOnline) {
            let payload = {}
            if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
                if (props.selectedBDM === 0) {
                    payload = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: props.selectedView,
                        subordinateUserIds: props.selectedBDM === 0 ? "ALL" : props.selectedBDM,
                        MeetingDate: newDate
                    };
                } else if (props.selectedFourthLevelSubordinate) {
                    const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === props.selectedFourthLevelSubordinate);
                    payload = {
                        userId: selectedBDMDetails[0]?.UserID,
                        roleName: selectedBDMDetails[0]?.Level,
                        viewFor: levelbyUser(channelid, roleId, "Five"),
                        subordinateUserIds: All,
                        MeetingDate: newDate,
                    };
                } else if (props.selectedThirdLevelSubordinate) {
                    const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === props.selectedThirdLevelSubordinate);
                    payload = {
                        userId: selectedABHDetails[0]?.UserID,
                        roleName: selectedABHDetails[0]?.Level,
                        viewFor: levelbyUser(channelid, roleId, "Four"),
                        subordinateUserIds: All,
                        MeetingDate: newDate,
                    };

                } else if (props.selectedSecondLevelSubordinate && props.selectedSecondLevelSubordinate !== "ALL") {
                    if (channelid === 2 && roleId === "NH") {
                        const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === props.selectedSecondLevelSubordinate);
                        payload = {
                            userId: selectedBDMDetails[0]?.userID,
                            roleName: selectedBDMDetails[0]?.level,
                            viewFor: levelbyUser(channelid, roleId, "Three"),
                            subordinateUserIds: All,
                            MeetingDate: newDate,
                        };
                    } else {
                        const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === props.selectedSecondLevelSubordinate);
                        payload = {
                            userId: selectedBDMDetails[0]?.UserID,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: levelbyUser(channelid, roleId, "Three"),
                            subordinateUserIds: All,
                            MeetingDate: newDate,
                        };
                    }

                } else if (props.selectedSecondLevelSubordinate === "ALL") {
                    if (channelid === 2 && roleId === "ZBH") {
                        const selectedBDMDetail = ABHListForZBH?.filter((bdm) => bdm.userID === props.selectedBDM);
                        payload = {
                            userId: props.selectedBDM,
                            roleName: selectedBDMDetail[0].level,
                            viewFor: userViewFor,
                            subordinateUserIds: "ALL",
                            MeetingDate: newDate,
                        };
                    } else {
                        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === props.selectedBDM);

                        payload = {
                            userId: props.selectedBDM,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: userViewFor,
                            subordinateUserIds: "ALL",
                            MeetingDate: newDate
                        };
                    }
                } else if (userViewFor === "SELF") {
                    payload = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: userViewFor,
                        subordinateUserIds: "ALL",
                        MeetingDate: newDate
                    };
                } else if (userViewFor !== "SELF" && props.selectedBDM === undefined && props.selectedSecondLevelSubordinate === undefined) {
                    payload = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: "SELF",
                        subordinateUserIds: "ALL",
                        MeetingDate: newDate
                    };
                } else {

                    if (channelid === 2 && roleId === "ZBH") {
                        const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === props.selectedBDM);
                        payload = {
                            userId: selectedBDMDetails[0]?.userID,
                            roleName: selectedBDMDetails[0].level,
                            viewFor: levelbyUser(channelid, roleId, "Second"),
                            subordinateUserIds: All,
                            MeetingDate: newDate,
                        };
                    } else {
                        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === props.selectedBDM);
                        payload = {
                            userId: props.selectedBDM,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: levelbyUser(channelid, roleId, "Second"),
                            subordinateUserIds: All,
                            MeetingDate: newDate
                        };
                    }
                }
            } else {
                // if (props.selectedBDM === 0 || props.selectedBDM === undefined) {
                //     payload = {
                //         userId: userId,
                //         roleName: roleId,
                //         viewFor: "self",
                //         subordinateUserIds: "all",
                //         MeetingDate: newDate
                //     };
                // } else {
                //     payload = {
                //         userId: userId,
                //         roleName: roleId,
                //         viewFor: props.selectedView === 'ALL' ? 'BDM' : props.selectedView,
                //         subordinateUserIds: props.selectedBDM ? props.selectedBDM?.toString() : "all",
                //         MeetingDate: newDate,
                //     };
                // }

                payload = {
                    userId: userId,
                    roleName: roleId,
                    viewFor: userViewFor ? userViewFor : props.selectedView,
                    subordinateUserIds: GetBDMByABH,
                    MeetingDate: newDate
                };
            }



            props.activateLoader()
            //  console.log("date and userid", userId, date)
            const res = await props.getCalendarLeadandActivityById(payload)
            if (res.status === 200) {
                // console.log("response", res.data)
                dispatch(getCalendarListData(res.data))
                props.deActivateLoader()
            } else {
                props.deActivateLoader()
            }
        }
    }
    const getCalendarLeadActivityListBYDateChange = async (newDate) => {
        if (isOnline) {
            let payload = {}
            if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
                if (props.selectedBDM === 0) {
                    payload = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: props.selectedView,
                        subordinateUserIds: props.selectedBDM === 0 ? "ALL" : props.selectedBDM,
                        MeetingDate: newDate
                    };
                } else if (props.selectedFourthLevelSubordinate) {
                    const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === props.selectedFourthLevelSubordinate);
                    payload = {
                        userId: selectedBDMDetails[0]?.UserID,
                        roleName: selectedBDMDetails[0]?.Level,
                        viewFor: levelbyUser(channelid, roleId, "Five"),
                        subordinateUserIds: All,
                        MeetingDate: newDate,
                    };
                } else if (props.selectedThirdLevelSubordinate) {
                    const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === props.selectedThirdLevelSubordinate);
                    payload = {
                        userId: selectedABHDetails[0]?.UserID,
                        roleName: selectedABHDetails[0]?.Level,
                        viewFor: levelbyUser(channelid, roleId, "Four"),
                        subordinateUserIds: All,
                        MeetingDate: newDate,
                    };
                } else if (props.selectedSecondLevelSubordinate && props.selectedSecondLevelSubordinate !== "ALL") {
                    if (channelid === 2 && roleId === "NH") {
                        const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === props.selectedSecondLevelSubordinate);
                        payload = {
                            userId: selectedBDMDetails[0]?.userID,
                            roleName: selectedBDMDetails[0]?.level,
                            viewFor: levelbyUser(channelid, roleId, "Three"),
                            subordinateUserIds: All,
                            MeetingDate: newDate,
                        };
                    } else {
                        const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === props.selectedSecondLevelSubordinate);
                        payload = {
                            userId: selectedBDMDetails[0]?.UserID,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: levelbyUser(channelid, roleId, "Three"),
                            subordinateUserIds: All,
                            MeetingDate: newDate
                        };
                    }

                } else if (props.selectedSecondLevelSubordinate === "ALL") {

                    if (channelid === 2 && roleId === "ZBH") {
                        const selectedBDMDetail = ABHListForZBH?.filter((bdm) => bdm.userID === props.selectedBDM);
                        payload = {
                            userId: props.selectedBDM,
                            roleName: selectedBDMDetail[0].level,
                            viewFor: userViewFor,
                            subordinateUserIds: "ALL",
                            MeetingDate: newDate,
                        };
                    } else {
                        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === props.selectedBDM);

                        payload = {
                            userId: props.selectedBDM,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: userViewFor,
                            subordinateUserIds: "ALL",
                            MeetingDate: newDate,
                        };
                    }



                } else if (userViewFor === "SELF") {
                    payload = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: userViewFor,
                        subordinateUserIds: "ALL",
                        MeetingDate: newDate
                    };
                } else if (userViewFor !== "SELF" && props.selectedBDM === undefined && props.selectedSecondLevelSubordinate === undefined) {
                    payload = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: "SELF",
                        subordinateUserIds: "ALL",
                        MeetingDate: newDate
                    };
                } else {
                    if (channelid === 2 && roleId === "ZBH") {
                        const selectedBDMDetails = ABHListForZBH?.filter((bdm) => bdm.userID === props.selectedBDM);
                        payload = {
                            userId: selectedBDMDetails[0]?.userID,
                            roleName: selectedBDMDetails[0].level,
                            viewFor: levelbyUser(channelid, roleId, "Second"),
                            subordinateUserIds: All,
                            MeetingDate: newDate
                        };
                    } else {
                        const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === props.selectedBDM);
                        payload = {
                            userId: props.selectedBDM,
                            roleName: selectedBDMDetails[0].Level,
                            viewFor: levelbyUser(channelid, roleId, "Second"),
                            subordinateUserIds: All,
                            MeetingDate: newDate
                        };
                    }


                }
            } else {
                if (userViewFor === 'SELF') {
                    if (BDMSelected === 0 || BDMSelected === undefined) {
                        payload = {
                            userId: userId,
                            roleName: roleId,
                            viewFor: "self",
                            subordinateUserIds: "all",
                            MeetingDate: newDate
                        };
                    } else {
                        payload = {
                            userId: userId,
                            roleName: roleId,
                            viewFor: viewSelected === 'ALL' ? 'BDM' : viewSelected,
                            subordinateUserIds: BDMSelected ? BDMSelected?.toString() : "all",
                            MeetingDate: newDate,
                        };
                    }
                } else {
                    payload = {
                        userId: userId,
                        roleName: roleId,
                        viewFor: userViewFor ? userViewFor : "SELF",
                        subordinateUserIds: GetBDMByABH,
                        MeetingDate: newDate
                    };
                }
            }
            props.activateLoader()
            console.log("date and userid", userId, date)
            const res = await props.getCalendarLeadandActivityById(payload)
            if (res.status === 200) {
                console.log("response", res.data)
                dispatch(getCalendarListData(res.data))
                props.deActivateLoader()
            } else {
                props.deActivateLoader()
            }
        }
    }

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar)
    }


    //const [state, setState] = useState({})
    const [pydState, setPydState] = useState({ 'right': false });
    const [filterDrawer, setFilterDrawer] = useState({})
    // const toggleDrawer = (anchor, open) => (event) => {
    //     if ( 
    //         event &&
    //         event.type === 'keydown' &&
    //         (event.key === 'Tab' || event.key === 'Shift')
    //     ) {
    //         return;
    //     }

    //     setState({ ...state, [anchor]: open });
    // };

    const toggleFilterDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setFilterDrawer({ ...filterDrawer, [anchor]: open });
    };

    const showDetailsHandle = (e, dayStr, selectedDate) => {
        setDate(selectedDate)

        const updatedDate = moment(selectedDate).format(DateFormatString);
        getCalendarLeadActivityList(updatedDate)
        dispatch(setCalendarSelectedData(updatedDate))
    };
    const getCurrentWeekStartAndEndDate = (startDate, endDate) => {
        // console.log("startDate,endDate", startDate, endDate)
    }

    const onSubmitHandler = async (model) => {
        const { userType, teamMember } = model
        props.activateLoader()
        setFilterClose(true)
        if (userType == 'team' && teamMember == "") {
            dispatch(activateErrorMessageBar('Please Select a Team Member'))
            props.deActivateLoader()
            return false
        }
        const user = userId
        const subUserId = userType == 'self' ? "all" : teamMember
        setBDMSelected(subUserId)
        setViewSelected(userType)
        const result = await getFilteredCalendarData(user, moment(date).format(DateFormatString), roleId, userType.toUpperCase(), subUserId)
        dispatch(getCalendarListData(result))
        setFilterDrawer({ "right": false })
        props.deActivateLoader()
    }

    useEffect(() => {
        const newDate = moment(date).format(DateFormatString);
        getCalendarLeadActivityList(newDate);
        dispatch(setCalendarSelectedData(newDate))
        //  console.log("props.selectedBDM,props.selectedView",props.selectedBDM,props.selectedView)
    }, [props.selectedBDM, props.selectedView, props.selectedSecondLevelSubordinate, props.selectedThirdLevelSubordinate, props.selectedFourthLevelSubordinate])

    let scheduleListLength = leadActivityCalendarList?.calenderActivities?.length > 0 && leadActivityCalendarList?.calenderActivities.filter(
        el => el.ActivityStatusId === 1 ||
            el.ActivityStatusId === 9 ||
            el.ActivityStatusId === 17 ||
            el.ActivityStatusId === 25 ||
            el.ActivityStatusId === 3 ||
            el.ActivityStatusId === 11 ||
            el.ActivityStatusId === 19 ||
            el.ActivityStatusId === 33 ||
            el.ActivityStatusId === 35 ||
            el.ActivityStatusId === 27 ||
            el.ActivityStatusId === 40 ||
            el.ActivityStatusId === 42
    )
    let totalLengthminus = parseInt(leadActivityCalendarList?.calenderActivities?.length) - parseInt(scheduleListLength?.length ? scheduleListLength.length : 0)
    let actualCount = parseInt(totalCount) - parseInt(totalLengthminus)
    let campaignScheduleListLength = leadActivityCalendarList?.campaignActivities?.length > 0 && leadActivityCalendarList?.campaignActivities.filter(
        el => el.ActivityStatusId == 9 ||
            el.ActivityStatusId == 11
    )
    actualCount = actualCount + parseInt(campaignScheduleListLength?.length ? campaignScheduleListLength.length : 0)
    // const list = (anchor) => (
    //     <Box
    //         sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
    //         role="presentation"
    //         onClick={toggleDrawer(anchor, false)}
    //         onKeyDown={toggleDrawer(anchor, false)}
    //     >
    //         <List className="updatePopupDiv">
    //             <ListItem color="black" sx={{ fontWeight: 'bold', fontSize: 18 }} > Update Activity
    //                 <CloseIcon />
    //             </ListItem>
    //             <ListItem


    //                 onClick={() => history.push('/app/activity/create')}>
    //                 <ListItemIcon >
    //                     <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
    //                         <PendingActionsIcon />
    //                     </Fab> Create Activity
    //                 </ListItemIcon>
    //                 <ListItemText />
    //             </ListItem>
    //             {userState.profileInfo.channelid !== 2 &&
    //                 <ListItem
    //                     onClick={() => history.push('/app/lead/create')}>
    //                     <ListItemIcon >
    //                         <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
    //                             <PendingActionsIcon />
    //                         </Fab> Create Lead
    //                     </ListItemIcon>
    //                     <ListItemText />
    //                 </ListItem>}

    //         </List>
    //         <Divider />
    //     </Box>
    // );
    // const togglePYDmap = (anchor, open) => (event)=> {
    //     if (
    //         event &&
    //         event.type === 'keydown' &&
    //         (event.key === 'Tab' || event.key === 'Shift')
    //     ) {
    //         return;
    //     }
    //     setPydState({ ...pydState, right: open });
    // }

    const togglePYDmap = (anchor, open) => (event) => {
        if (isOnline) {
            navigator.geolocation.getCurrentPosition((res) => {
                setShowModalSetup(false)
                if (
                    event &&
                    event.type === 'keydown' &&
                    (event.key === 'Tab' || event.key === 'Shift')
                ) {
                    return;
                }
                setPydState({ ...pydState, right: open });
            },
                (error) => {
                    setShowModalSetup(true)
                },
                {
                    timeout: 20000,
                    maximumAge: 20000,
                    enableHighAccuracy: true
                });
        }
        if (open) {
            const employeeCode = userState.profileInfo.employeeCode;
            const deviceId = userState.profileInfo.deviceId;
            const logInfoRequest = {
                processname: 'Dashboard calendar drawer open',
                employeeCode: employeeCode,
                ipAddress: "",
                requestFor: `${'Dashboard calendar drawer open'}`,
                leadActivityId: "",
                deviceId: deviceId,
                routUrl: locationData.pathname
            }
            loggInfo(logInfoRequest);
        }
    };

    const handleClose = () => {
        setShowModalSetup(false)
        togglePYDmap('right', true)
    }
    // const getActivityTypesForRole = (roleName, el) => {
    //     if (roleName === 'ABH') {
    //         return (
    //             el.ActivityStatusId === 1 ||
    //             el.ActivityStatusId === 9 ||
    //             el.ActivityStatusId === 17 ||
    //             el.ActivityStatusId === 25 ||
    //             el.ActivityStatusId === 3 ||
    //             el.ActivityStatusId === 11 ||
    //             el.ActivityStatusId === 19 ||
    //             el.ActivityStatusId === 26 ||
    //             el.ActivityStatusId === 28 ||
    //             el.ActivityStatusId === 29 ||
    //             el.ActivityStatusId === 30 ||
    //             el.ActivityStatusId === 31 ||
    //             el.ActivityStatusId === 27
    //         )
    //     } else {
    //         return (
    //             el.ActivityStatusId === 1 ||
    //             el.ActivityStatusId === 9 ||
    //             el.ActivityStatusId === 17 ||
    //             el.ActivityStatusId === 25 ||
    //             el.ActivityStatusId === 3 ||
    //             el.ActivityStatusId === 11 ||
    //             el.ActivityStatusId === 19 ||
    //             el.ActivityStatusId === 26 ||
    //             el.ActivityStatusId === 29 ||
    //             el.ActivityStatusId === 30 ||
    //             el.ActivityStatusId === 27 ||
    //             el.ActivityStatusId === 33 ||
    //             el.ActivityStatusId === 35 ||
    //             el.ActivityStatusId === 39
    //         )
    //     }
    // }
    return (
        <div className={props.renderFrom === "dashboard" ? "commonCalendarDashboard" : "commonCalendar"}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={props.renderFrom === "dashboard" ? 12 : (roleId !== "BDM" && roleId !== "FPM") ? 6 : 8} style={{ textAlign: "center" }}>
                    <div className={classes.calendarWeeklyView}>
                        <Card>
                            <WeeklyCalendar
                                showDetailsHandle={showDetailsHandle}
                                getCurrentWeekStartAndEndDate={getCurrentWeekStartAndEndDate} />
                        </Card>
                    </div>
                    {/* <Calendar/> */}
                    <div className={classes.calendarMonthlyView}>
                        <Card>
                            {showCalendar ?
                                <Calendar
                                    onChange={changeDate}
                                    value={date}
                                /> : ""}
                            <span className={classes.calendarIcon} >
                                {showCalendar ? <KeyboardDoubleArrowUpIcon onClick={toggleCalendar} /> :
                                    <KeyboardDoubleArrowDownIcon onClick={toggleCalendar} />
                                }
                            </span>
                        </Card>
                    </div>

                </Grid>
                <Grid item xs={12} md={props.renderFrom === "dashboard" ? 12 : (roleId !== "BDM" && roleId !== "FPM") ? 3 : 4}>
                    <div className="cardDate">
                        <span className="taskDiv">{isNaN(actualCount) ? 0 : actualCount} {actualCount === 1 || actualCount === 0 || isNaN(actualCount) ? 'Event' : 'Events'}</span>
                        {moment(date).format("dddd, MMMM Do YYYY")}
                    </div>
                    <div className="calendarHeightScroll">
                        {totalCount === 0 ? "No Records to display" : ''}
                        {leadActivityCalendarList?.calenderActivities?.length > 0 && leadActivityCalendarList?.calenderActivities.filter(
                            el => {
                                if (roleId === 'ABH') {
                                    return el.ActivityStatusId === 1 ||
                                        el.ActivityStatusId === 9 ||
                                        el.ActivityStatusId === 17 ||
                                        el.ActivityStatusId === 25 ||
                                        el.ActivityStatusId === 3 ||
                                        el.ActivityStatusId === 11 ||
                                        el.ActivityStatusId === 19 ||
                                        // el.ActivityStatusId == 26 ||
                                        // el.ActivityStatusId == 28 ||
                                        el.ActivityStatusId === 29 ||
                                        // el.ActivityStatusId == 30 ||
                                        el.ActivityStatusId == 31 ||
                                        el.ActivityStatusId == 27 ||
                                        el.ActivityStatusId == 40 ||
                                        el.ActivityStatusId == 42 ||
                                        el.ActivityStatusId == 33 ||
                                        el.ActivityStatusId == 35 ||
                                        el.ActivityStatusId == 47 ||
                                        el.ActivityStatusId == 49
                                } else {
                                    return el.ActivityStatusId === 1 ||
                                        el.ActivityStatusId === 9 ||
                                        el.ActivityStatusId === 17 ||
                                        el.ActivityStatusId === 25 ||
                                        el.ActivityStatusId === 3 ||
                                        el.ActivityStatusId === 11 ||
                                        el.ActivityStatusId === 19 ||
                                        // el.ActivityStatusId == 26 ||

                                        el.ActivityStatusId == 29 ||
                                        el.ActivityStatusId == 30 ||
                                        el.ActivityStatusId == 27 ||
                                        el.ActivityStatusId == 33 ||
                                        el.ActivityStatusId == 35 ||
                                        el.ActivityStatusId == 39 ||
                                        el.ActivityStatusId == 40 ||
                                        el.ActivityStatusId == 42 ||
                                        el.ActivityStatusId == 47 ||
                                        el.ActivityStatusId == 49
                                }
                            }
                        ).map(act => <CommonCalendarCard
                            key={act.TrnActivityID}
                            cardItem={{
                                type: "Activity",
                                status: act.ActivityStatus,
                                name: channelid == 19 ? act.ActivityIdName: act.ActivityName,
                                BranchCode: act.BranchCode,
                                BranchName: act.BranchName,
                                MeetingStartDateTime: act.MeetingStartDateTime,
                                MeetingEndDateTime: act.MeetingEndDateTime,
                                id: act.TrnActivityID,
                                activityTypeId: act.ActivityID,
                            }}
                            onShowPYDmap={() => togglePYDmap('right', true)}
                        />
                        )}
                        {leadActivityCalendarList?.campaignActivities?.length > 0 && channelid !== 3 && leadActivityCalendarList?.campaignActivities?.filter(el => {
                            return el.ActivityStatusId === 9 ||
                                el.ActivityStatusId === 11 ||
                                el.ActivityStatusId === 13 ||
                                el.ActivityStatusId === 15 ||
                                el.ActivityStatusId === 16
                        }
                        ).map(act => {
                            return <CommonCalendarCard
                                key={act.CampaignActivityid}
                                cardItem={{
                                    type: "Campaign Activity",
                                    status: act.ActivityStatus,
                                    name: act.ActivityIdName,
                                    MeetingStartDateTime: act.MeetingStartDateTime,
                                    MeetingEndDateTime: act.MeetingEndDateTime,
                                    id: act.CampaignActivityid,
                                    activityTypeId: act.ActivityID,
                                }}
                                onShowPYDmap={() => togglePYDmap('right', true)}
                            />
                        })}

                        {leadActivityCalendarList?.calenderLeads?.length > 0 && channelid !== 3 && leadActivityCalendarList?.calenderLeads?.map(lead => {
                            return <CommonCalendarCard
                                key={lead.LeadId}
                                cardItem={{
                                    type: "Lead",
                                    status: lead.LeadStatus,
                                    name: lead.LeadName,
                                    MeetingStartDateTime: lead.MeetingStartDateTime,
                                    MeetingEndDateTime: lead.MeetingEndDateTime,
                                    id: lead.LeadId,
                                }}
                                onShowPYDmap={() => togglePYDmap('right', true)}
                            />
                        })}
                        {leadActivityCalendarList?.calenderLeads?.length > 0 && channelid === 3 && leadActivityCalendarList?.calenderLeads?.map(lead => {
                            return <CommonCalendarCard
                                key={lead.LeadId}
                                cardItem={{
                                    type: "Business Lead",
                                    status: lead.LeadStatus,
                                    name: lead.LeadName,
                                    MeetingStartDateTime: lead.MeetingStartDateTime,
                                    MeetingEndDateTime: lead.MeetingEndDateTime,
                                    id: lead.LeadId,
                                }}
                                onShowPYDmap={() => togglePYDmap('right', true)}
                            />
                        })}

                        {leadActivityCalendarList?.calenderRecruitmentLead?.length > 0 && channelid === 3 && leadActivityCalendarList?.calenderRecruitmentLead?.map(lead => {
                            return <CommonCalendarCard
                                key={lead.LeadId}
                                cardItem={{
                                    type: "Recruitment Lead",
                                    status: lead.LeadStatus,
                                    name: lead.LeadName,
                                    MeetingStartDateTime: lead.MeetingStartDateTime,
                                    MeetingEndDateTime: lead.MeetingEndDateTime,
                                    id: lead.LeadId,
                                }}
                                onShowPYDmap={() => togglePYDmap('right', true)}
                            />
                        })}
                        {leadActivityCalendarList?.calenderPersistencyLeads?.length > 0 && leadActivityCalendarList?.calenderPersistencyLeads?.map(lead => {
                            return <CommonCalendarCard
                                key={lead.LeadID}
                                cardItem={{
                                    type: "Persistency Lead",
                                    status: lead.LeadStatus,
                                    name: lead.CustomerName,
                                    MeetingStartDateTime: lead.MeetingStartDateTime,
                                    MeetingEndDateTime: lead.MeetingEndDateTime,
                                    id: lead.LeadID,
                                }}
                                onShowPYDmap={() => togglePYDmap('right', true)}
                            />
                        })}
                        {leadActivityCalendarList?.calenderCampaignLeads?.length > 0 && leadActivityCalendarList?.calenderCampaignLeads?.map(lead => {
                            return <CommonCalendarCard
                                key={lead.LeadCampaignID}
                                cardItem={{
                                    type: "Campaign Lead",
                                    status: lead.LeadStatus,
                                    name: `${lead.FirstName} ${lead.LastName}`,
                                    MeetingStartDateTime: lead.MeetingStartDateTime,
                                    MeetingEndDateTime: lead.MeetingEndDateTime,
                                    id: lead.LeadCampaignID
                                }}
                                onShowPYDmap={() => togglePYDmap('right', true)}
                            />
                        })}
                    </div>
                </Grid>
                {roleId !== "BDM" && roleId !== "FPM" && props.renderFrom !== "dashboard" && userViewFor === "SELF" &&

                    <Grid item xs={12} md={3} className="rightCol" >
                        <div className={classes.desktopFilter}>
                            <CalenderFilter
                                onSubmitHandler={onSubmitHandler}
                                filterClose={filterClose}
                                setFilterClose={setFilterClose}

                            />
                        </div>
                    </Grid>
                }

                {roleId !== "BDM" && roleId !== "FPM" && props.renderFrom !== "dashboard" &&
                    <span className={classes.drawerMobile}>
                        <Box
                            // className="editBtn"
                            // sx={{ display: { xs: "flex", md: "none" } }}
                            onClick={toggleFilterDrawer("bottom", true)}
                        >
                            <Fab color="primary" aria-label="add" sx={{ float: "right" }}>
                                <FilterAltIcon />
                            </Fab>
                        </Box>

                        <span>
                            {["bottom"].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <SwipeableDrawer
                                        anchor={anchor}
                                        open={filterDrawer[anchor]}
                                        onClose={toggleFilterDrawer(anchor, false)}
                                        onOpen={toggleFilterDrawer(anchor, true)}
                                    >
                                        <CalenderFilter handleClose={toggleFilterDrawer(anchor, false)} onSubmitHandler={onSubmitHandler}
                                            filterClose={filterClose}
                                            setFilterClose={setFilterClose} />
                                    </SwipeableDrawer>
                                </React.Fragment>
                            ))}
                        </span>
                    </span>
                }
                {/* this is no longer in use, as it is added on common place. MenuDrawer.js */}
                {/* <span>
                    {['bottom'].map((anchor) => (
                        <React.Fragment key={anchor}>

                            {renderFrom === "dashboard"?"":<Box className="editBtn" sx={{ '& > :not(style)': { m: 1 } }} onClick={toggleDrawer(anchor, true)} >
                                <Fab color="primary" aria-label="add" sx={{ float: 'right' }}>
                                    <AddIcon />
                                </Fab>
                            </Box>}

                            <SwipeableDrawer
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                                onOpen={toggleDrawer(anchor, true)}
                            >
                                {list(anchor,)}
                            </SwipeableDrawer>
                        </React.Fragment>
                    ))}
                </span> */}
                {totalCount !== 0 &&
                    <span>
                        {['right'].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <SwipeableDrawer
                                    anchor={anchor}
                                    open={pydState[anchor]}
                                    onClose={togglePYDmap(anchor, false)}
                                    onOpen={togglePYDmap(anchor, true)}
                                >
                                    {pydState[anchor] &&
                                        <PlanYourDay
                                            toggleDrawer={togglePYDmap}
                                        />
                                    }

                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </span>
                }
                <ModalPopup
                    show={showModalSetup}
                    onHide={() => setShowModalSetup(!showModalSetup)}
                    size={"sm"}
                    centered={true}
                    modelHeader={"Please enable your location"}
                    modelContent={
                        <Button
                            onClick={handleClose}
                        >
                            CLOSE
                        </Button>
                    }
                    backdrop={"static"}
                />
            </Grid>
        </div>
    )
}

const mapStateToProps = (state) => ({
    masterDataState: state.commonReducer,
})
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, { getCalendarLeadandActivityById, activateLoader, deActivateLoader })(CommonCalendar));
