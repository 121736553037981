import "font-awesome/css/font-awesome.min.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PersonIcon from "@material-ui/icons/Person";
import { setPehlaKdamOverview, setABHByRBH } from "../../../redux-reducer/reducer/pehlaKadamReducer";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PehlaKadamDashboardData } from '../../../redux-reducer/reducer'
import { useEffect } from "react";

const PehlaKadamOverViewCard = (props) => {
  const { classes, selectedView, roleId, selectedBDM } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { getPehlaKdamDashbordOverview } = useSelector((state) => state.pehlaKadamReducer); //getABHByRBH

  const { viewForStatus, GetBDMByABH } = useSelector(state => state.commonReducer)

  const { Pending, Completed, Total, NotCompleted } = getPehlaKdamDashbordOverview;
  const userId = useSelector((state) => state.userReducer.profileInfo.userId)
  const commonState = useSelector((state) => state);
  const userState = commonState.userReducer;
  const setOverviewSelection = (type) => {
    dispatch(setPehlaKdamOverview(type));
    // if (roleId === 'ABH') {
    history.push('/app/pehlaKadam/all')
    // }
  };
  useEffect(() => {
    let payload = {}
    if (roleId === 'RBH') {
      if (selectedBDM === 0) {
        payload = {
          userId: userId,
          roleName: roleId,
          // viewFor: selectedView === 'ALL' ? 'ABH' : selectedView,
          viewFor: viewForStatus,
          subordinateUserIds: GetBDMByABH,
        };
      } else {
        payload = {
          userId: userId,
          roleName: roleId,
          viewFor: 'ABH',
          subordinateUserIds: GetBDMByABH?.toString(),
        };
      }
    } else {
      payload = {
        userId: userId,
        roleName: roleId,
        viewFor: 'BDM',
        subordinateUserIds: "all",
      };
    }
    dispatch(PehlaKadamDashboardData(payload))
  }, [])

  useEffect(() => {
    let payload = {}
    if (roleId === 'RBH') {
      if (selectedBDM === 0) {
        payload = {
          userId: userId,
          roleName: roleId,
          // viewFor: selectedView === 'ALL' ? 'ABH' : selectedView,
          viewFor: viewForStatus,
          subordinateUserIds: GetBDMByABH,
        };
      } else {
        payload = {
          userId: userId,
          roleName: roleId,
          viewFor: viewForStatus,
          subordinateUserIds: GetBDMByABH?.toString(),
        };
      }
    } else {
      payload = {
        userId: userId,
        roleName: roleId,
        viewFor: 'BDM',
        subordinateUserIds: "all",
      };
    }
    dispatch(PehlaKadamDashboardData(payload))
    dispatch(setABHByRBH(GetBDMByABH !== "all" ? GetBDMByABH : 0))
  }, [selectedBDM, selectedView])


  return (
    <>
      {userState.profileInfo.channelid !== 2 && (
        <Paper className={classes.paper2} sx={{ mb: 2 }}>
          <Grid className="leadOverviewSec" container spacing={3}>
            {/* <ZoomInIcon className="zoomIcon" /> */}
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="h5" gutterBottom>
                    Pehla Kadam Overview
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <p className="viewAll" onClick={() => setOverviewSelection("all")} >View All</p>
                </Grid>
              </Grid>
              <Grid container className="greyView greyViewSml">
                <Grid item xs={6} sm={3} onClick={() => setOverviewSelection("Pending")}>
                  <div className="greyViewInner">
                    <ArrowRightAltIcon
                      className="arrowIcon"
                    />

                    <PersonIcon className="navyBlueBG" />
                    <div className="clearfix"></div>
                    <div className="titleActivity">Pending CheckList</div>
                    <div className="numDiv">
                      <span>{Pending ? Pending : 0}</span> / {Total}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={3} onClick={() => setOverviewSelection("Completed")}>
                  <div className="greyViewInner">
                    <ArrowRightAltIcon
                      className="arrowIcon"
                    />
                    <PersonIcon className="blueBG" />
                    <div className="clearfix"></div>
                    <div className="titleActivity">Completed CheckList</div>
                    <div className="numDiv">
                      <span>{Completed ? Completed : 0}</span> / {Total}
                    </div>

                  </div>
                </Grid>
                <Grid item xs={6} sm={3} onClick={() => setOverviewSelection("Not-Completed")}>
                  <div className="greyViewInner">
                    <ArrowRightAltIcon
                      className="arrowIcon"
                    />
                    <PersonIcon className="orangeBG" />
                    <div className="clearfix"></div>
                    <div className="titleActivity">Not Completed CheckList</div>
                    <div className="numDiv">
                      <span>{NotCompleted ? NotCompleted : 0}</span> / {Total}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default PehlaKadamOverViewCard;
