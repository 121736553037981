import React, { useState, useEffect } from 'react';
import './footer.scss'
import F1Footer from 'f1-footer-react'
import { getLocalStorage } from '../../utils/utils';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

    const COMMUNICATION_RETRY_TIMEOUT = 30 * 60 * 1000;
    function Footer() {
      const [showChatError, setShowChatError] = useState(false);
      const [showChatSuccess, setShowChatSuccess] = useState(false);
      useEffect(() => {
      });
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
      };
      const wrapperStyle = {}
      const linkBlockStyle = {}
      const copyrightBlockStyle = {}
      const socialBlockStyle = {};
      const socialContent = "";
      const copyrightBlockContent = <div data-test="container" className="container-fluid">© 1994-2021 </div>
      return (
        <div className="footer-container">
          <F1Footer
            wrapperStyle={wrapperStyle}
            wrapperClass="custom-footer-wrapper"
            socialBlockStyle={socialBlockStyle}
            socialBlockClass="custom-social-block"
            socialContent={socialContent}
            displaySocialBlock={false}
            displayLinksBlock={true}
            linkBlockStyle={linkBlockStyle}
            linkBlockClass="custom-footer-links"
            displayCopyrightBlock={true}
            copyrightBlockStyle={copyrightBlockStyle}
            copyrightBlockClass="custom-copyright"
            copyrightBlockContent={copyrightBlockContent}
          />
           <Snackbar open={showChatError} autoHideDuration={3000} onClose={() => handleClose({ vertical: 'bottom', horizontal: 'right' })}>
             <Alert severity="error" >{getLocalStorage('communicationError')}</Alert></Snackbar>
           <Snackbar open={showChatSuccess} autoHideDuration={3000} onClose={() => handleClose({ vertical: 'bottom', horizontal: 'right' })}>
             <Alert severity="success" >You have successfully authenticated for the chat. You can start chatting now.</Alert></Snackbar>
        </div>
      );
    }

    export default Footer;
