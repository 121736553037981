import { get, post} from '../../api/api'
import { GET_CALEDNDER_LEAD_ACTIVTIES_BY_USER, USER_PLAN_LIST } from '../../api/baseURL'
import { getCalendarListData } from '../reducers/calendarActivityReducer'


export const getCalendarLeadandActivityById = (payload)=>(dispatch,getState)=>{
    return new Promise(async (resolve, reject)=>{
        try{
            const res = await get(`${GET_CALEDNDER_LEAD_ACTIVTIES_BY_USER}${payload.userId}&MeetingDate=${payload.MeetingDate}&rolename=${payload.roleName}&viewFor=${payload.viewFor}&subordinateUserIds=${payload.subordinateUserIds}`)
            getCalendarListData(res.data)
            resolve(res)
        } catch (err){
            reject(err)
        }
    })
}
export const getUserPlan = (request) => {
    return new Promise(async (resolve, reject) => {
        try{
            const res = await post(`${USER_PLAN_LIST}`, request);
            resolve(res)
        } catch (err){
            reject(err)
        }
    })
}
