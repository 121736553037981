import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from 'react-router-dom'
import './../home.scss'
import { useDispatch, useSelector } from 'react-redux';
//import { loadMasterData, setBreadcrumbs, getActivityTypeDetailsCountsByUserId } from '../../../../redux/actions/common';
import { resetMasterDataSyncTime } from '../../../../offlineManager/leadOfflineManager';
//import { isMasterSyncRequired, validateUserOfflineSession } from '../../../../offlineManager/userAuthOfflineManager';
import { logoutUserReducer } from "../../../../redux-reducer/reducer";
import ModalPopup from 'f1-modal-react';
import { Button } from "@material-ui/core";
import { Typography } from "@mui/material";
import useOnlineStatus from "../../../../hooks/useOnlineStatus";
import { getUserBranches } from "../../../../redux/actions/common";

const Home = () => {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const history = useHistory();
  const commonState = useSelector(state => state);
  const userState = commonState.userReducer;
  const [UserId] = useState(userState.profileInfo.userId);
  const [showModal, setShowModal] = useState(false);
  const [showModalSetup, setShowModalSetup] = useState(false);
  useEffect(() => {
    dispatch(getUserBranches({userId: UserId}))
  }, []);
  const logoutHandler = () => {
    setShowModal(!showModal);
    // this will reset the master data sync time, so on next login it will push all master data again
    resetMasterDataSyncTime().then((res) => {
      dispatch(logoutUserReducer());
    });
    localStorage.removeItem("roleId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
    localStorage.removeItem("expiration");
    localStorage.removeItem("token");
    sessionStorage.clear();
    history.replace("/");
  };
  return (
    <div className="mainContainer dashboardDetail">
      <div className="dashboardHead">
        {
          !isOnline &&
          <Typography variant="h6" style={{ color: 'red', fontSize: '13px' }}>You are offline! to see actual data, enable internet connection</Typography>
        }
        <Typography variant="h4">Admin Dashboard</Typography>
        <div className="optionClick">
          {/* <PanToolIcon />
        <SettingsApplicationsIcon />
        <AppsIcon className="active" />
        <ViewListIcon /> */}
        </div>
      </div>




      <ModalPopup
        className="modalDiv"
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        size={"sm"}
        centered={true}
        modelHeader={"Offline session expire!"}
        modelContent={
          <div className="footerButtons">
            <p>Your session will expire in 1 day, please login online to continue.</p>
            <span>
              <Button
                onClick={() => {
                  logoutHandler();
                }}
              >
                Login
              </Button>
            </span>
          </div>
        }
        backdrop={"static"}
      />
      <ModalPopup
        className="modalDiv"
        show={showModalSetup}
        onHide={() => setShowModalSetup(!showModalSetup)}
        size={"sm"}
        centered={true}
        modelHeader={"Setting up app, Please wait..."}
        modelContent={
          <></>
        }
        backdrop={"static"}
      />
    </div>
  );
}
export default withRouter(Home);
