import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';


const COIIssuedHistory = (props) => {
    const { history } = props
    const historyDate = history.GCLDateOfIssue ? moment(history.GCLDateOfIssue).format('DD MMM YYYY') : ''
    const historyTime = history.GCLDateOfIssue ? moment(history.GCLDateOfIssue).format('h:mma') : "";
    return <>
        {history.LeadStatusId === 22 ?
            <>
                           <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>COINumber </label>
                        <label>{history.COINumber}</label>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem sx={props.styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Date Of Issue</label>
                        <label>
                            {historyDate}
                            {' '} {historyTime}
                        </label>
                    </TimelineContent>
                </TimelineItem>
            </>
            : ''
        }
    </>
}

export default COIIssuedHistory
