/**
 * @author [Arun Kumar]
 * @email [fdc0120@fulcrumdigital.com]
 * @create date 03-06-2022 10:44:10
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./searchListing.scss";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

function SearchListing() {
    const history = useHistory()
    const [leadList, setLeadList] = useState([]);

    const { searchLeadData } = useSelector(
        (state) => state.leadListReducer
    );
    useEffect(() => {
        setLeadList(searchLeadData);
    }, [searchLeadData]);


    const TypeofleadList = (Typeoflead) => {
        if (Typeoflead === 'PersistenceLead') {
            Typeoflead = 'persistancylead'
        }
        return Typeoflead
    }
    const goToLeadPage = (id, statusId, Typeoflead) => {
        if (statusId === 11) {
            history.push(`/app/lead/update/${id}`);
        } else {
            history.push(`/app/${Typeoflead}/detail/${id}`);
        }
    };


    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(3),
            textAlign: "left",
            color: theme.palette.text.secondary,
        },
        subHeadingBold: {
            fontWeight: "bold",
        },
    }));
    const classes = useStyles();

    return (
        <>
            <Grid item xs={12} md={9} className="leftCol serachResultsDiv">
                <h3 className='title'>Search Results <span>Showing {leadList.length} {leadList.length === 1 ? 'Result' : 'Results'}</span></h3>
                <Paper className={classes.paper}>
                    {leadList && leadList.length > 0 && leadList
                    .map((lead, index) => (
                        <div className="listingCard" key={lead.LeadId} onClick={() =>
                            goToLeadPage(lead.Id, lead.LeadStatusId, TypeofleadList(lead.Typeoflead))
                        }>
                            <Grid container textAlign="left">
                                <Grid container sx={{ mt: 1 }}>
                                    <Grid item xs={12} md={6} className={classes.subHeading}>
                                        <span className="icn rightIcn">
                                            <ArrowRightAltIcon />
                                        </span>
                                        <label>New Customer</label>
                                        <span className={`consumer ${classes.subHeadingBold}`}>
                                            {lead.LeadName || 'N/A'}
                                        </span>
                                        <div className="lastUpdateDiv">
                                        <span>Last Updated Date</span>
                                        <span>25 Feb 2022</span>
                                        <span>10:00 am</span>
                                        </div>
                                    </Grid>                                                            
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </Paper>
                    {leadList && leadList.length === 0 &&
                    <div className="noResultsDiv">
                        <TravelExploreIcon />
                        <Typography variant="h5" gutterBottom component="div">Sorry No results Found!
                        <span>We couldn’t find what you are looking for</span></Typography>
                    </div>
                    }
                
            </Grid>
        </>
    );
}

export default SearchListing;

