import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import "../leadList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getPersistancyLeadDraftListFilter,
  getLeadDraftList,
  getPersistancyLeadBacklogList,
  getLeadStatusList,
  getPersistencyLeadBacklogListFilter,
} from "../../../../redux-reducer/reducer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import PersistancyLeadFilter from "./persistancyLeadFilter";
import PersistancyLeadBacklogsFilter from "./persistancyLeadBacklogsFilter";

import PersistancydraftListing from "../../../../components/draftListing/persistancydraftListing";
import useOnlineStatus from "../../../../hooks/useOnlineStatus";
import {
  getAllLeadOffline,
} from "../../../../offlineManager/leadOfflineManager";
import { getPersistancyLeadList, setPersistanyLeadsOverview } from "../../../../redux-reducer/reducer/leadListReducer";
import ListingPersistancy from "../../../../components/listing/listingPersistancy";
import { getFilteredPersistancyLeadList, getactivitiesforpersistencyfilter } from '../../../../redux-reducer/reducer/index'
import { withTranslation } from "react-i18next";
import { activateLoader, deActivateLoader, getLeadCsvFile } from "../../../../redux/actions/common";
import { DownloadFiles } from "../../../../utils/utils";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function LeadListingPersistancy(
  props
) {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const formRef = useRef();
  const select = useSelector(mainState => mainState)
  const { getPersistancyLeadsOverView } = select.leadListReducer
  const { viewForStatus ,GetBDMByABH} = select.commonReducer


  const { allPersistancyLeadList } = select.persistancyLeadListReducer
  const { Listofleads, TotalnoofRows } = allPersistancyLeadList
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userId = JSON.parse(userData.userReducer).profileInfo.userId;
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const channelId=JSON.parse(userData.userReducer).profileInfo.channelid;
  const [state, setState] = React.useState({
    bottom: false,
  });
  const [selectedFilterPersistency, setSelectedFilterPersistency] = useState({
    roleId: roleId,
    viewFor: ""
  })

  const [selectedFilterBacklog, setSelectedFilterBacklog] = useState({
    roleId: roleId,
    viewFor: ""
  })



  const [selectedTeamMemberPersistency, setSelectedTeamMemberPersistency] = useState("")
  const [selectedTeamMemberBacklog, setSelectedTeamMemberBacklog] = useState("")

  const [value, setValue] = React.useState(0);
  const [totalDraftRecord, setTotalDraftRecord] = useState(10);
  const [totalBacklogRecord, setTotalBacklogRecord] = useState(5);
  const { getPersistancyLeadDraftData } = select.leadListReducer

  const [NoOfRecordperPage, setNoOfRecordperPage] = useState(5);
  const [allOfflineLeads, setAllOfflineLeads] = useState([]);
  const { getPersistancyBacklogData } = select.leadListReducer
  const { Persistencyleaddata } = getPersistancyBacklogData;
  const [isLeadFilterApplied, setLeadFilterApplied] = useState(false);
  const [leadFilterState, setLeadFilterState] = useState({
    UserName: null,
    LeadName: null,
    CreatedDateTime: null,
    CreatedDateStartTIme: null,
    CreatedDateEndTIme: null,
    MeetingOn: null,
    persistancycurrentStatus: [],
    LastUpdateDateTime: null,
    currentStatus: null,
    product: 0,
    activityID: null,
    MeetingDate: null,
    fromdate: null,
    todate: null,
    MobileNo: null,
  });
  const [backlogFilterState, setBacklogFilterState] = useState({
    Username: "",
    LeadID: null,
    LeadName: null,
    CurrentStatus: null,
    MeetingStartDate: null,
    persistancycurrentStatus: [],
    MeetingEndDate: null,
    Createstartdate: null,
    Createenddate: null,
    ActivityID: null,
    ProductID: null,
    MobileNo: null,
    "IsBacklog": true
  });
  // const BacklogReq = {
  //   Username: "",
  //   LeadID: null,
  //   LeadName: null,
  //   CurrentStatus: null,
  //   MeetingStartDate: null,
  //   MeetingEndDate: null,
  //   Createstartdate: null,
  //   Createenddate: null,
  //   ActivityID: null,
  //   ProductID: null,
  //   MobileNo: null,
  //   "IsBacklog": true
  // };

  const draftReq = {
    Username: "",
    LeadID: null,
    LeadName: null,
    CurrentStatus: "11",//2
    MeetingStartDate: null,
    MeetingEndDate: null,
    Createstartdate: null,
    Createenddate: null,
    ActivityID: null,
    ProductID: null,
    MobileNo: null
  };

  const [leadReq, setLeadReq] = useState({
    Username: null,
    LeadID: null,
    LeadName: null,
    CurrentStatus: "1,2,3,4,5,6,7,8,9,10,14,25",
    MeetingStartDate: null,
    MeetingEndDate: null,
    Createstartdate: null,
    Createenddate: null,
    ActivityID: null,
    ProductID: null,
    MobileNo: null,
    IsBacklog: false
  });

  const [backlogleadReq, setbacklogLeadReq] = useState({
    Username: null,
    LeadID: null,
    LeadName: null,
    CurrentStatus: "1,2,3,4,5,6,7,8,9,10,14,25",
    MeetingStartDate: null,
    MeetingEndDate: null,
    Createstartdate: null,
    Createenddate: null,
    ActivityID: null,
    ProductID: null,
    MobileNo: null,
    IsBacklog: true
  });


  const getAllBacklogListData = (reqFilterState, teamID,reqPayload) => {
    setSelectedTeamMemberBacklog(teamID ? teamID : GetBDMByABH)
    setSelectedFilterBacklog({
      roleId: "ABH",
      viewFor: teamID ? "BDM" : viewForStatus
    })
    const Backlogpayload = { ...reqFilterState, CurrentStatus: getLeadStatusId(getPersistancyLeadsOverView) }
    const BacklogReq = { ...reqPayload, CurrentStatus: getLeadStatusId(getPersistancyLeadsOverView) }
    setbacklogLeadReq({ ...BacklogReq})
    setBacklogFilterState({ ...Backlogpayload })
    if (isOnline) {
      dispatch(getPersistancyLeadBacklogList(userId, 1, totalBacklogRecord, BacklogReq, roleId, viewForStatus, GetBDMByABH));
    }
  };

  const getFilterBacklogList = (backlogRequest, teamMemberID, persistancyActivityValue) => {
    setLeadFilterApplied(true)
    const { CreatedDateEndTIme, LeadName, CreatedDateStartTIme, currentStatus, product, activityID, fromdate, todate, MobileNo ,DueStartDate,DueEndDate,BranchId} = backlogRequest
    scrollToTop();
    const req = {
      "LeadID": null,
      "LeadName": LeadName,
      "CurrentStatus": currentStatus,
      "MeetingStartDate": fromdate !== 'null' ? fromdate : null,
      "MeetingEndDate": todate !== 'null' ? todate : null,
      "Createstartdate": CreatedDateStartTIme !== 'null' ? CreatedDateStartTIme : null,
      "Createenddate": CreatedDateEndTIme !== 'null' ? CreatedDateEndTIme : null,
      "ActivityID": !persistancyActivityValue ? null : activityID,
      "ProductID": product === 0 ? null : parseInt(product),
      "MobileNo": MobileNo,
      "DueStartDate": DueStartDate !== 'null' ? DueStartDate : null,
      "DueEndDate": DueEndDate !== 'null' ? DueEndDate : null,
      "BranchId": BranchId === '' ? null : BranchId,
      "IsBacklog": true
    }
    setBacklogFilterState({ ...req, })
    setbacklogLeadReq({ ...req})
    if (isOnline) {
      const { viewFor } = selectedFilterBacklog
      dispatch(getPersistencyLeadBacklogListFilter(userId, 1, 5, req, selectedFilterBacklog, teamMemberID ? viewFor : viewForStatus, teamMemberID ? teamMemberID : GetBDMByABH));
      setSelectedTeamMemberBacklog(teamMemberID ? teamMemberID : GetBDMByABH)
    } else {
      // filter leads offline
      // let filterLeads = getFilterLeads(allOfflineLeads, req);
      // filterLeads = filterLeads.sort(
      //   (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
      // );
      // const initialLeadList = filterLeads;
      // const leadResponse = {
      //   Noofpages: Math.round(filterLeads.length / NoOfRecordperPage),
      //   TotalnoofRows: filterLeads.length,
      //   Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
      // };
      // setAllOfflineLeads(filterLeads);
      // dispatch(getLeadList(leadResponse));
    }
  };
  const getLeadByOverviewSelection = (type) => {
    switch (type) {
      case "open":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "2,3,4,7" },
        };
      case "un-actioned":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "1" },
        };
      case "collected":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "14" },
        };
      case 'dropped':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "5" },
        };
      case 'all':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq },
        };
    }
  };
  useEffect(() => {
    if (isOnline) {
      const payload = getLeadByOverviewSelection(getPersistancyLeadsOverView)
      setLeadReq({ ...leadReq, CurrentStatus: payload.filterReq.CurrentStatus });
      if(value == 0){
        dispatch(getFilteredPersistancyLeadList(payload.UserId,
          payload.PageNo,
          payload.NoOfRecordperPage,
          payload.filterReq,
          selectedFilterPersistency,
          viewForStatus, GetBDMByABH));
      } else if(value==2){
        getAllBacklogListData(backlogFilterState, selectedTeamMemberBacklog,backlogleadReq);
      } else {
        dispatch(getFilteredPersistancyLeadList(payload.UserId,
          payload.PageNo,
          payload.NoOfRecordperPage,
          payload.filterReq,
          selectedFilterPersistency,
          viewForStatus, GetBDMByABH));
      }
    
      // dispatch(getPersistancyLeadDraftListFilter(userId, 1, totalDraftRecord, draftReq));
      
      dispatch(getactivitiesforpersistencyfilter(userId));

    } else {
      getAllLeadOffline().then((leads) => {
        leads = leads.sort(
          (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
        );
        const initialLeadList = leads;
        const leadResponse = {
          Noofpages: Math.round(leads.length / NoOfRecordperPage),
          TotalnoofRows: leads.length,
          Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
        };
        setAllOfflineLeads(leads);
        dispatch(getPersistancyLeadList(leadResponse));
      });
    }
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(setPersistanyLeadsOverview("all"));
    }

  }, [value]);

  useEffect(() => {
    if (isOnline) {
      dispatch(getLeadStatusList());
    }
    console.log(isLeadFilterApplied)
  }, []);

  const loadMoreRecord = () => {
    const payload = {
      UserId: userId,// 1
      PageNo: 1,
      NoOfRecordperPage: NoOfRecordperPage + 5,
      filterReq: {
        ...leadReq,
      },
    };
    if (isOnline) {
      const { viewFor } = selectedFilterPersistency;
      dispatch(getFilteredPersistancyLeadList(payload.UserId,
        payload.PageNo,
        payload.NoOfRecordperPage,
        payload.filterReq,
        selectedFilterPersistency,
        selectedTeamMemberPersistency ? viewFor : viewForStatus,
        selectedTeamMemberPersistency ? selectedTeamMemberPersistency : GetBDMByABH))
      setNoOfRecordperPage(NoOfRecordperPage + 5);
    } else {
      let batchLeads = [...allOfflineLeads];
      batchLeads = batchLeads.splice(NoOfRecordperPage, NoOfRecordperPage + 5);
      const leadResponse = {
        Noofpages: batchLeads.length / NoOfRecordperPage,
        TotalnoofRows: allOfflineLeads.length,
        Listofleads: batchLeads,
      };
      setNoOfRecordperPage(NoOfRecordperPage + 5);
      dispatch(getPersistancyLeadList(leadResponse));
    }
  };
  const loadMoreBacklogs = () => {
    const { viewFor } = selectedFilterBacklog;
    if (isOnline) {
      dispatch(
        getPersistancyLeadBacklogList(userId, 1, totalBacklogRecord + 5, backlogFilterState, roleId, selectedTeamMemberBacklog ? viewFor : viewForStatus, selectedTeamMemberBacklog ? selectedTeamMemberBacklog : GetBDMByABH)//userId
      );
      setTotalBacklogRecord(totalBacklogRecord + 5);
    }
  };

  const loadMoreDraftRecord = () => {
    if (isOnline) {
      dispatch(getPersistancyLeadDraftListFilter(userId, 1, totalDraftRecord + 5, draftReq));
      setTotalDraftRecord(totalDraftRecord + 5);
    }
  };

  const getFilteredLeads = (filterRequest, teamMemberID, persistancyActivityValue) => {
    setLeadFilterApplied(true)
    const { CreatedDateEndTIme, LeadName, CreatedDateStartTIme, currentStatus, product, activityID, fromdate, todate, MobileNo,DueStartDate,DueEndDate,BranchId } = filterRequest
    scrollToTop();
    const req = {
      "LeadID": null,
      "LeadName": LeadName,
      "CurrentStatus": currentStatus,
      "MeetingStartDate": fromdate !== 'null' ? fromdate : null,
      "MeetingEndDate": todate !== 'null' ? todate : null,
      "Createstartdate": CreatedDateStartTIme !== 'null' ? CreatedDateStartTIme : null,
      "Createenddate": CreatedDateEndTIme !== 'null' ? CreatedDateEndTIme : null,
      "DueStartDate": DueStartDate !== 'null' ? DueStartDate : null,
      "DueEndDate": DueEndDate !== 'null' ? DueEndDate : null,
      "ActivityID": !persistancyActivityValue ? null : activityID,
      "ProductID": product === 0 ? null : parseInt(product),
      "BranchId": BranchId === '' ? null : BranchId,
      "MobileNo": MobileNo,
      "IsBacklog": false

    }
    setLeadReq({ ...req })
    if (isOnline) {
      const { viewFor } = selectedFilterPersistency
      dispatch(getFilteredPersistancyLeadList(userId, 1, 5, req, selectedFilterPersistency, teamMemberID ? viewFor : viewForStatus, teamMemberID ? teamMemberID : GetBDMByABH));
      setSelectedTeamMemberPersistency(teamMemberID ? teamMemberID : GetBDMByABH)
    } else {
      // filter leads offline
      // let filterLeads = getFilterLeads(allOfflineLeads, req);
      // filterLeads = filterLeads.sort(
      //   (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
      // );
      // const initialLeadList = filterLeads;
      // const leadResponse = {
      //   Noofpages: Math.round(filterLeads.length / NoOfRecordperPage),
      //   TotalnoofRows: filterLeads.length,
      //   Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
      // };
      // setAllOfflineLeads(filterLeads);
      // dispatch(getLeadList(leadResponse));
    }
  };

  const getLeadStatusId = (type) => {
    switch (type) {
      case "open":
        return "2,3,4,7"
      case "un-actioned":
        return "1"
      case "collected":
        return "14"
      case 'dropped':
        return "5"
      case 'all':
        return "1,2,3,4,5,6,7,8,9,10,14,25"
    }
  };
  const getAllPersistancyLeads = () => {
    scrollToTop();
    const payload = {
      UserId: userId,
      PageNo: 1,
      NoOfRecordperPage: 5,
      filterReq: {
        Username: "",
        LeadID: null,
        LeadName: null,
        CurrentStatus: getLeadStatusId(getPersistancyLeadsOverView),
        MeetingStartDate: null,
        MeetingEndDate: null,
        Createstartdate: null,
        Createenddate: null,
        ActivityID: null,
        ProductID: null,
        MobileNo: null,
        IsBacklog: false
      }
    }
    setLeadReq({ ...payload.filterReq })
    setSelectedTeamMemberPersistency("")

    if (isOnline) {
      dispatch(getFilteredPersistancyLeadList(payload.UserId,
        payload.PageNo,
        payload.NoOfRecordperPage,
        payload.filterReq,
        selectedFilterPersistency,
        viewForStatus, GetBDMByABH));
    }
  };


  const scrollToTop = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLeadFilterState = (item) => {
    setLeadFilterState({
      ...item,
    });
  };
  const handleBacklogFilterState = (item) => {
    setBacklogFilterState({
      ...item,
    });
  };

  // const handleCsvDownload = async () => {
  //   const leadType = "Persistencylead"
  //   if (isOnline) {
  //     props.activateLoader();
  //     const result = await props.getLeadCsvFile(
  //       leadReq,
  //       userId,
  //       leadType,
  //       roleId,
  //       viewForStatus !== "" ? viewForStatus : "SELF"
  //     );
  //     if (result.status === 200) {
  //       DownloadFiles(result.data)
  //       props.deActivateLoader();
  //     } else {
  //       props.deActivateLoader();
  //     }
  //   }
  // }
  return (
    <>
      <div className="tabBox">
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="tabs example"
        >
          <Tab label="Persistency Leads" {...a11yProps(0)} />
          <Tab sx={{ display: 'none' }} label="Drafts" {...a11yProps(1)} />
          <Tab label="Backlog" {...a11yProps(2)} />
        </Tabs>
      </div>
      {/* {roleId === "ABH" && channelId !=13 &&
        <div>
          <Button onClick={handleCsvDownload} variant="contained">CSV Download</Button>
        </div>
      } */}
      <TabPanel value={value} index={0}>
        <div ref={formRef}>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <ListingPersistancy
              roleId={roleId}
              data={Listofleads}
              toggleDrawer={toggleDrawer}
              loadMoreRecord={loadMoreRecord}
              NoOfRecordperPage={NoOfRecordperPage}
              TotalnoofRows={TotalnoofRows}
            />

            <Grid
              item
              xs={12}
              md={3}
              className="rightCol"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <PersistancyLeadFilter
                filterState={leadFilterState}
                setFilterState={handleLeadFilterState}
                getFilteredLeads={getFilteredLeads}
                getAllLeads={getAllPersistancyLeads}
                parent={"sidebar"}
                handleClose={toggleDrawer(state.bottom, false)}
                setFilterDisabled={() => setLeadFilterApplied(false)}
                setSelectedFilter={setSelectedFilterPersistency}
              />
            </Grid>
          </Grid>
          {/* <Loader></Loader> */}
        </div>
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <PersistancyLeadFilter
                  anchor={anchor}
                  filterState={leadFilterState}
                  setFilterState={handleLeadFilterState}
                  getFilteredLeads={getFilteredLeads}
                  getAllLeads={getAllPersistancyLeads}
                  parent={"drawer"}
                  handleClose={toggleDrawer(anchor, false)}
                  setFilterDisabled={() => setLeadFilterApplied(false)}
                  setSelectedFilter={setSelectedFilterPersistency}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PersistancydraftListing
          ListOfDrafts={getPersistancyLeadDraftData.Persistencyleaddata}
          TotalNoOfRows={getPersistancyLeadDraftData.TotalnoofRows}
          loadMoreRecord={loadMoreDraftRecord}
          NoOfRecordperPage={totalDraftRecord}
          getAllDraftsData={() =>
            dispatch(getLeadDraftList(userId, 1, totalDraftRecord, draftReq))
          }
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div ref={formRef}>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <ListingPersistancy
              data={Persistencyleaddata}
              toggleDrawer={toggleDrawer}
              loadMoreRecord={loadMoreBacklogs}
              NoOfRecordperPage={totalBacklogRecord}
              TotalnoofRows={getPersistancyBacklogData.TotalnoofRows}
            />

            <Grid
              item
              xs={12}
              md={3}
              className="rightCol"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              {
                <PersistancyLeadBacklogsFilter
                  filterState={backlogFilterState}
                  setFilterState={handleBacklogFilterState}
                  setbacklogLeadReq = {setbacklogLeadReq}
                  getFilteredBacklogList={getFilterBacklogList}
                  getAllBacklogList={getAllBacklogListData}
                  parent={"drawer"}
                  handleClose={toggleDrawer(state.bottom, false)}
                  setSelectedFilter={setSelectedFilterBacklog}
                  setFilterDisabled={() => setLeadFilterApplied(false)}
                />
              }
            </Grid>
          </Grid>
        </div>
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {
                  <PersistancyLeadBacklogsFilter
                    anchor={anchor}
                    filterState={backlogFilterState}
                    setFilterState={handleBacklogFilterState}
                    setbacklogLeadReq = {setbacklogLeadReq}
                    getFilteredBacklogList={getFilterBacklogList}
                    getAllBacklogList={getAllBacklogListData}
                    parent={"drawer"}
                    handleClose={toggleDrawer(anchor, false)}
                    setSelectedFilter={setSelectedFilterBacklog}
                    setFilterDisabled={() => setLeadFilterApplied(false)}
                  />
                }
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      </TabPanel>
    </>
  );
}


const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
    getLeadCsvFile,
    activateLoader,
    deActivateLoader,
  })(LeadListingPersistancy)
);