import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import {CALLING_TO_VLE_RAP_DM} from "../../../../utils/config";

const UpdateCallsHistory = (props) => {
  const { history, activityOption } = props;

    return <>
    {history.ActivityStatus ===  "Activity Update Calls"&&
      activityOption&&activityOption.key===CALLING_TO_VLE_RAP_DM&&
           <>
           <TimelineItem>
             <TimelineSeparator>
               <span className="separator">-</span>
               <TimelineConnector />
             </TimelineSeparator>
             <TimelineContent>
               <label>Calling to VLE/RAP/DM</label>
               <label>{history.CallingToVLERAPDM}</label>
             </TimelineContent>
           </TimelineItem>
 
           <TimelineItem>
             <TimelineSeparator>
               <span className="separator">-</span>
             </TimelineSeparator>
             <TimelineContent>
               <label>VLE/RAP/DM Mobile No</label>
               <label>{history.VLERAPDMMobile}</label>
             </TimelineContent>
           </TimelineItem>

           <TimelineItem>
             <TimelineSeparator>
               <span className="separator">-</span>
             </TimelineSeparator>
             <TimelineContent>
               <label>Outcome of the call </label>
               <label>{history.OutcomeOfCall}</label>
             </TimelineContent>
           </TimelineItem>
         </>
            }
    </>
}
export default UpdateCallsHistory;


