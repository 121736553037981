import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n/config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';

import configureStore from './redux/store';
import { persistStore } from 'redux-persist'

import { HashRouter } from "react-router-dom";
import { createDb } from './offlineManager/localbase';


const store = configureStore();

persistStore(store, {}, () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <HashRouter forceRefresh={false}>
          <App />
        </HashRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
setTimeout(() => {
  createDb();
});
