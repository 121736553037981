
// import { isNumberOnly, isValidMobileNumber } from "../../../utils/validators";
// import moment from "moment";

// export const resetField = (formFields) => {
//   for (const [index] of formFields.entries()) {
//     if (index === 0) {
//       continue;
//     }
//     formFields[index].hide = true;
//   }
//   return formFields;
// };

export const checkListFilterForm = [
  {
    colmd: "12",
    key: "BDMUserId",
    label: "ABH/BDM Name",
    hide: false,
    type: "select",
    id: "BDMUserId",
    name: "BDMUserId",
    options: [
    ],
    validators: [{ "name": "required", "value": false }]
  },
  {
    colmd: "12",
    key: "CheckListType",
    label: "CheckList Type",
    hide: false,
    type: "select",
    id: "CheckListType",
    name: "CheckListType",
    options: [
    ],
    validators: [{ "name": "required", "value": false }]
  },
  {
    colmd: "12",
    key: "Status",
    label: "CheckList Status",
    hide: false,
    type: "select",
    id: "Status",
    name: "checklistStatus",
    options: [
    ],
    validators: [{ "name": "required", "value": false }]
  }
];

export const customValidators = [
//   {
//     key: 'MobileNo',
//     validator: isNumberOnly,
//     message: 'Please enter numbers only'
//   }
];
