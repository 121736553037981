import "font-awesome/css/font-awesome.min.css";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PersonIcon from "@material-ui/icons/Person";
import { setRecruitmentLeadsOverview } from "../../../redux-reducer/reducer/leadListReducer";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RecruitmentLeadOverviewData } from '../../../redux-reducer/reducer'
import { useEffect, useState } from "react";
import BarChart from "../../../components/barchart/barChart";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { levelbyUser } from "../../../utils/config";

const RecruitmentLeadOverviewCard = (props) => {
    const { classes, selectedView, roleId, selectedBDM, selectedSecondLevelSubordinate,
        selectedThirdLevelSubordinate, selectedFourthLevelSubordinate } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const { RecruitmentLeadOverViewDashboard } = useSelector(
        (state) => state.leadListReducer
    );
    const userId = useSelector((state) => state.userReducer.profileInfo.userId)
    const channelid = useSelector((state) => state.userReducer.profileInfo.channelid)

    const { OpenLead, DroppedLead, ConvertedLead, AllLead, UnActionLead } = RecruitmentLeadOverViewDashboard
    const { DateDetails } = RecruitmentLeadOverViewDashboard;
    const [selectedDays, setSelectedDays] = useState("TODAY");
    const [toggleChart, setToggleChart] = useState(false)
    const [toggleCard, setToggleCard] = useState(false);
    const commonState = useSelector(state => state)
    const { viewForStatus, GetBDMByABH } = useSelector(state => state.commonReducer)

    const bdmList = commonState.leadFormReducer.BDMList.UsersDetails;
    const secondLevelSubordinateList = commonState.leadFormReducer?.secondLevelSubordinateList?.UsersDetails;
    const thirdLevelSubordinateList = commonState.leadFormReducer?.thirdLevelSubordinateList?.UsersDetails;
    const fourthLevelSubordinateList = commonState.leadFormReducer?.fourthLevelSubordinateList?.UsersDetails;
    const All = "all"
    const getYaxisLeadData = (type) => {
        const arr = []
        DateDetails && DateDetails.forEach((element) => {
            arr.push(element.HourDetails?.reduce((a, c) => {
                return a + c[type]
            }, 0))
        })
        return arr
    }

    const getChartDataForToday = (type) => {
        const arr = []
        DateDetails && DateDetails?.length > 0 && DateDetails[0].HourDetails.forEach((ele) => arr.push(ele[type]))
        return arr
    }
    const getChartData = (type) => {
        const chartDatas = {}
        switch (type) {
            case 'TODAY':
                chartDatas.labels = getChartDataForToday('Hour')
                chartDatas.datasets = [
                    {
                        label: "OpenLead",
                        backgroundColor: "rgb(27,68,125)",
                        data: getChartDataForToday('OpenLead')
                    },
                    {
                        label: "UnActionLead",
                        backgroundColor: "rgb(0,147,222)",
                        data: getChartDataForToday('UnActionLead')
                    },
                    {
                        label: "ConvertedLead",
                        backgroundColor: "rgb(221,117,40)",
                        data: getChartDataForToday('ConvertedLead')
                    },
                    {
                        label: "DroppedLead",
                        backgroundColor: "rgb(191,56,62)",
                        data: getChartDataForToday('DroppedLead')
                    }
                ]
                break
            case "abc":
                break
            default:
                const labelsArr = DateDetails && DateDetails.map((item) => item.CreatedDate)
                chartDatas.labels = labelsArr
                chartDatas.datasets = [
                    {
                        label: "OpenLead",
                        backgroundColor: "rgb(27,68,125)",
                        data: getYaxisLeadData('OpenLead')
                    },
                    {
                        label: "UnActionLead",
                        backgroundColor: "rgb(0,147,222)",
                        data: getYaxisLeadData('UnActionLead')
                    },
                    {
                        label: "ConvertedLead",
                        backgroundColor: "rgb(221,117,40)",
                        data: getYaxisLeadData('ConvertedLead')
                    },
                    {
                        label: "DroppedLead",
                        backgroundColor: "rgb(191,56,62)",
                        data: getYaxisLeadData('DroppedLead')
                    }
                ]
        }
        return chartDatas
    }

    const setLeadOverview = (type) => {
        dispatch(setRecruitmentLeadsOverview(type));
        if (roleId == "NH" || roleId == "ZBH" || roleId == "RBH") {
            return false
        } else {
            history.push('/app/recruitmentlead/all')
        }
    };

    const getLeadOverViewDashboardData = (payload) => {
        // dispatch(RecruitmentLeadOverviewDashboardData(payload))
        dispatch(RecruitmentLeadOverviewData(payload))
    }

    const onChangehandler = (event) => {
        let payload = {}
        if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
            if (selectedBDM === 0) {
                payload = {
                    userId: userId,
                    roleName: roleId,
                    viewFor: selectedView,
                    subordinateUserIds: selectedBDM === 0 ? "ALL" : selectedBDM,
                    viewByDate: event.target.value,
                };
            } else if (selectedFourthLevelSubordinate) {
                const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedFourthLevelSubordinate);
                payload = {
                    userId: selectedBDMDetails[0]?.UserID,
                    roleName: selectedBDMDetails[0]?.Level,
                    viewFor: levelbyUser(channelid, roleId, "Five"),
                    subordinateUserIds: All,
                    viewByDate: event.target.value,
                };
            } else if (selectedThirdLevelSubordinate) {
                const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === selectedThirdLevelSubordinate);
                payload = {
                    userId: selectedABHDetails[0]?.UserID,
                    roleName: selectedABHDetails[0]?.Level,
                    viewFor: levelbyUser(channelid, roleId, "Four"),
                    subordinateUserIds: All,
                    viewByDate: event.target.value,
                };
            } else if (selectedSecondLevelSubordinate && selectedSecondLevelSubordinate !== "ALL") {
                const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedSecondLevelSubordinate);

                payload = {
                    userId: selectedBDMDetails[0]?.UserID,
                    roleName: selectedBDMDetails[0]?.Level,
                    viewFor: levelbyUser(channelid, roleId, "Three"),
                    subordinateUserIds: All,
                    viewByDate: event.target.value,
                };

            } else if (selectedSecondLevelSubordinate === "ALL") {
                const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
                payload = {
                    userId: selectedBDM,
                    roleName: selectedBDMDetails[0].Level,
                    viewFor: viewForStatus,
                    subordinateUserIds: "ALL",
                    viewByDate: event.target.value,
                };
            } else {
                const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
                payload = {
                    userId: selectedBDM,
                    roleName: selectedBDMDetails[0].Level,
                    viewFor: levelbyUser(channelid, roleId, "Second"),
                    subordinateUserIds: All,
                    viewByDate: event.target.value,
                };
            }
        } else {
            if (selectedBDM === 0) {
                payload = {
                    userId: userId,
                    roleName: roleId,
                    viewFor: selectedView === 'ALL' ? 'BDM' : selectedView,
                    subordinateUserIds: GetBDMByABH,
                    viewByDate: event.target.value
                };
            } else {
                payload = {
                    userId: userId,
                    roleName: roleId,
                    viewFor: 'BDM',
                    subordinateUserIds: selectedBDM?.toString(),
                    viewByDate: event.target.value,
                };
            }
        }
        getLeadOverViewDashboardData(payload)
        setSelectedDays(event.target.value)
    }

    const onIconClick = () => {
        setToggleChart(!toggleChart)
    }


    useEffect(() => {
        setToggleCard(false)
    }, [selectedBDM, selectedView])

    const getRecruitmentLeadCardData = () => {
        let payload = {}
        if (roleId === "NH" || roleId === "RBH" || roleId === "ZBH") {
            if (selectedBDM === 0) {
                payload = {
                    userId: userId,
                    roleName: roleId,
                    viewFor: selectedView,
                    subordinateUserIds: selectedBDM === 0 ? "ALL" : selectedBDM,
                    viewByDate: selectedDays,
                };
            } else if (selectedFourthLevelSubordinate) {
                const selectedBDMDetails = fourthLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedFourthLevelSubordinate);
                payload = {
                    userId: selectedBDMDetails[0]?.UserID,
                    roleName: selectedBDMDetails[0]?.Level,
                    viewFor: levelbyUser(channelid, roleId, "Five"),
                    subordinateUserIds: All,
                    viewByDate: selectedDays,
                };
            } else if (selectedThirdLevelSubordinate) {
                const selectedABHDetails = thirdLevelSubordinateList?.filter((abh) => abh.UserID === selectedThirdLevelSubordinate);
                payload = {
                    userId: selectedABHDetails[0]?.UserID,
                    roleName: selectedABHDetails[0]?.Level,
                    viewFor: levelbyUser(channelid, roleId, "Four"),
                    subordinateUserIds: All,
                    viewByDate: selectedDays,
                };
            } else if (selectedSecondLevelSubordinate && selectedSecondLevelSubordinate !== "ALL") {
                const selectedBDMDetails = secondLevelSubordinateList?.filter((bdm) => bdm.UserID === selectedSecondLevelSubordinate);
                payload = {
                    userId: selectedBDMDetails[0]?.UserID,
                    roleName: selectedBDMDetails[0]?.Level,
                    viewFor: levelbyUser(channelid, roleId, "Three"),
                    subordinateUserIds: All,
                    viewByDate: selectedDays,
                };

            } else if (selectedSecondLevelSubordinate === "ALL") {
                const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
                payload = {
                    userId: selectedBDM,
                    roleName: selectedBDMDetails[0].Level,
                    viewFor: viewForStatus,
                    subordinateUserIds: "ALL",
                    viewByDate: selectedDays,
                };
            } else {
                const selectedBDMDetails = bdmList.filter((bdm) => bdm.UserID === selectedBDM);
                payload = {
                    userId: selectedBDM,
                    roleName: selectedBDMDetails[0].Level,
                    viewFor: levelbyUser(channelid, roleId, "Second"),
                    subordinateUserIds: All,
                    viewByDate: selectedDays,
                };
            }
        } else {
            if (selectedBDM === 0) {
                payload = {
                    userId: userId,
                    roleName: roleId,
                    viewFor: selectedView === 'ALL' ? 'BDM' : selectedView,
                    subordinateUserIds: GetBDMByABH,
                    viewByDate: selectedDays
                };
            } else {
                payload = {
                    userId: userId,
                    roleName: roleId,
                    viewFor: 'BDM',
                    subordinateUserIds: selectedBDM?.toString(),
                    viewByDate: selectedDays,
                };
            }
        }
        setSelectedDays(selectedDays)
        getLeadOverViewDashboardData(payload)
    }
    const onCardIconClick = (value) => {
        if (!value) {
            handleRefresh();
        }
        setToggleCard(!toggleCard)
    }
    const handleRefresh = () => {
        getRecruitmentLeadCardData();
    }

    useEffect(() => {
        // setToggleCard(false)
        if (toggleCard) {
            getRecruitmentLeadCardData()
        }
    }, [selectedBDM, selectedView, selectedSecondLevelSubordinate, selectedThirdLevelSubordinate, selectedFourthLevelSubordinate])


    return (
        <>
            <Paper className={classes.paper2} sx={{ mb: 2 }}>
                <Grid className="leadOverviewSec" container spacing={3}>
                    {/* <ZoomInIcon className="zoomIcon" /> */}
                    <Grid item xs={12} md={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="h5" gutterBottom>
                                    <span onClick={() => onCardIconClick(toggleCard)}>Recruitment Leads Overview {toggleCard ? <RemoveIcon /> : <AddIcon />}</span>
                                </Typography>
                                <p className="viewAll" onClick={() => setLeadOverview("all")} >View All</p>
                            </Grid>
                            {
                                toggleCard ?
                                    <Grid item xs={6} textAlign="right">
                                        <FormControl className="selectBx">
                                            <Select label="Day2" onChange={onChangehandler} value={selectedDays}>
                                                <MenuItem value={'TODAY'}>Today </MenuItem>
                                                <MenuItem value={'7 DAY'}>Last 7 Days</MenuItem>
                                                <MenuItem value={'15 DAYS'}>Last 15 Days</MenuItem>
                                                <MenuItem value={'MONTHLY'}>Last 30 Days</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    :
                                    ''
                            }
                        </Grid>
                        {
                            toggleCard ?
                                <>
                                    <Grid container className="greyView greyViewSml">
                                        <Grid item xs={6} sm={3} onClick={() => setLeadOverview("open")}>
                                            <div className="greyViewInner">
                                                <ArrowRightAltIcon
                                                    className="arrowIcon"
                                                />

                                                <PersonIcon className="navyBlueBG" />
                                                <div className="clearfix"></div>
                                                <div className="titleActivity">Open Leads</div>
                                                <div className="numDiv">
                                                    <span>{OpenLead}</span> / {AllLead}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3} onClick={() => setLeadOverview("un-actioned")}>
                                            <div className="greyViewInner">
                                                <ArrowRightAltIcon
                                                    className="arrowIcon"
                                                />
                                                <PersonIcon className="blueBG" />
                                                <div className="clearfix"></div>
                                                <div className="titleActivity">Un- actioned Leads</div>
                                                <div className="numDiv">
                                                    <span>{UnActionLead ? UnActionLead : 0}</span> / {AllLead}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3} onClick={() => setLeadOverview("converted")}>
                                            <div className="greyViewInner">
                                                <ArrowRightAltIcon
                                                    className="arrowIcon"
                                                />
                                                <PersonIcon className="orangeBG" />
                                                <div className="clearfix"></div>
                                                <div className="titleActivity">Converted Leads</div>
                                                <div className="numDiv">
                                                    <span>{ConvertedLead ? ConvertedLead : 0}</span> / {AllLead}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} sm={3} onClick={() => setLeadOverview("dropped")}>
                                            <div className="greyViewInner">
                                                <ArrowRightAltIcon
                                                    className="arrowIcon"
                                                />
                                                <PersonIcon className="redBG" />
                                                <div className="clearfix"></div>
                                                <div className="titleActivity">Dropped Leads</div>
                                                <div className="numDiv">
                                                    <span>{DroppedLead ? DroppedLead : 0}</span> / {AllLead}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h5" gutterBottom>
                                                <span onClick={onIconClick}>Analytical Overview {toggleChart ? <RemoveIcon /> : <AddIcon />}</span>
                                            </Typography>
                                            {toggleChart ? <BarChart chartData={getChartData(selectedDays)} /> : ""}
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                ''
                        }
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default RecruitmentLeadOverviewCard;
