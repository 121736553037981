
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';

const DraftedStatusHistory = (props) => {
    const history = props.history

    return <>
        {history.LeadStatus === "Drafted" ?
            <>
            <TimelineItem sx={props.styletimeline}>
                <TimelineSeparator>
                    <span className="separator">-</span>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <label>Lead Source Name </label><br></br>
                    <label>{history.LeadSourceName}</label>
                </TimelineContent>
            </TimelineItem>
            </>
            : ''}
    </>
}

export default DraftedStatusHistory
