import { format } from "date-fns";
import moment from "moment";
import { CONFIGS } from "../../../utils/config";
const dateFormate = "yyyy-MM-dd";
const _formatDate = "YYYY-MM-DDTHH:mm";
const meetingStartDateAndTimeLabel = "Meeting Start Date & Time";
const meetingEndDateAndTimeLabel = "Meeting End Date & Time";
const minDate =
  format(new Date(), dateFormate) + "T" + format(new Date(), "kk:mm");
const datetimelocal = "datetime-local";
const BDMNameErrorMessege = "Please select BDM";
const ABHNameErrorMessege = "Please select ABH";
const maxDate=moment(new Date()).endOf("day").format(_formatDate)
const nextDateMonth = new Date();
nextDateMonth.setMonth(nextDateMonth.getMonth() + 2, 0);
const getNextDateMonth = format(nextDateMonth, dateFormate);

const prevousDateMonth = new Date();
const getPrevousDateMonth = format(prevousDateMonth, dateFormate);

let newDate = new Date();
let currentmonth = newDate.getMonth()+1;
let nextmonth = newDate.getMonth() + 2;
let currentyear = newDate.getFullYear();
let currentmonthyear=currentmonth + " " + currentyear;
let nextmonthyear=nextmonth + " " + currentyear;

var monthNames = ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"];
var d = new Date();
const currentMonthYear = monthNames[d.getMonth()]+ " " +d.getFullYear()
let nextMonthYear;
if (d.getMonth() == 11) {
   nextMonthYear = monthNames[0]+ " " +(d.getFullYear() + 1)
} else {
   nextMonthYear = monthNames[d.getMonth()+1]+ " " +d.getFullYear()
}

export const BankaSalesMangementActvityForm = [
  {
    key: "SalesMangementID",

    type: "select",

    id: "SalesMangementID",

    label: "Sales management activity type",

    validators: [
      {
        name: "required",

        value: true,

        validationMessage: "Please select sales management activity type",
      },
    ],
    options: [{ key: "Select", label: "Select", value: "" }],
  },
];

export const Schedule_BDM = [
  {
    key: "BDMName",
    type: "select",
    id: "BDMName",
    label: "BDM Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: BDMNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "ABHID",
    type: "text",
    id: "ABHID",
    label: "ABH Name",
    disabled: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Enter ABH Name",
      },
    ],
  },
  {
    key: "Month_Year",
    type: "select",
    id: "Month_Year",
    label: "Month / Year",
    min: getPrevousDateMonth,
    max: getNextDateMonth,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select month/year",
      },
    ],
    options: [{ key: "Select", label: "Select", value: "" },
    { key: currentMonthYear, label: currentMonthYear, value: currentMonthYear },
    { key: nextMonthYear, label: nextMonthYear, value: nextMonthYear }],
  },
];

export const SCHEDULE_STRUCTURED_REVIEW_ABH = [
    {
    key: "RBHName",
    type: "text",
    id: "RBHName",
    label: "RBH Name",
    disabled: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Enter ABH Name",
      },
    ],
  },
  // {
  //   key: "country1",
  //   label: "Country1",
  //   type: "autocompleteMultiselect",
  //   disabled: false,
  //   placeholder:"DEmo",
  //   options: [

  //     {
  //       key: "Chicago",
  //       label: "Chicago",
  //       value: "Chicago",
  //       disabled: true,
  //     },
  //     { key: "Dellas", label: "Dellas", value: "Dellas" },
  //     { key: "NewYork", label: "New York", value: "New York" },
  //   ],
  //   validators: [{ name: "required", value: true }],
  // },
  {
    key: "ABHID",
    type: "select",
    id: "ABHID",
    label: "ABH Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: ABHNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "Month_Year",
    type: "select",
    id: "Month_Year",
    label: "Month / Year",
    min: getPrevousDateMonth,
    max: getNextDateMonth,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select month/year",
      },
    ],
    options: [{ key: "Select", label: "Select", value: "" },
    { key: currentMonthYear, label: currentMonthYear, value: currentMonthYear },
    { key: nextMonthYear, label: nextMonthYear, value: nextMonthYear }],
  },
]

export const BRANCH_VISIT_WITH_BDM_AND_ABH = [
  {
    key: "ABHID",
    type: "select",
    id: "ABHID",
    label: "ABH Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: ABHNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "BDMName",
    type: "select",
    id: "BDMName",
    label: "BDM Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: BDMNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "BranchName",
    type: "select",
    id: "BranchName",
    label: "Branch Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select branch name.",
      },
    ],
    options: [{ key: "Select", label: "Select", value: "" }],
  },
  {
    key: "BranchCode",
    type: "text",
    id: "BranchCode",
    label: "Branch Code",
    disabled: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Enter ABH Name",
      },
    ],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Branch_Visit_Plan = [
  {
    key: "BDMName",
    type: "select",
    id: "BDMName",
    label: "BDM Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: BDMNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "BranchName",
    type: "select",
    id: "BranchName",
    label: "Branch Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select branch name.",
      },
    ],
    options: [{ key: "Select", label: "Select", value: "" }],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
];

export const STRUCTURED_ZONAL_HEAD_MEETING = [
  {
    key: "bankZoneId",
    type: "select",
    id: "bankZoneId",
    label: "Bank Zone Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select bank zone name.",
      },
    ],
    options: [],
  },
  {
    key: "bankZHId",
    type: "select",
    id: "bankZHId",
    label: "Bank ZH Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select bank ZH name.",
      },
    ],
    options: [],
  },
  {
    key: "ABHID",
    type: "select",
    id: "ABHID",
    label: "ABH Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Enter ABH Name",
      },
    ],
    options: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "Month_Year",
    type: "select",
    id: "Month_Year",
    label: "Month / Year",
    min: getPrevousDateMonth,
    max: getNextDateMonth,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select month/year",
      },
    ],
    options: [{ key: "Select", label: "Select", value: "" },
    { key: currentMonthYear, label: currentMonthYear, value: currentMonthYear },
    { key: nextMonthYear, label: nextMonthYear, value: nextMonthYear }],
  },
]

export const MEETING_WITH_TOP_10_BDM = [
  {
    key: "BDMName",
    type: "autocompleteMultiselect",
    id: "BDMName",
    label: "BDM Name",
    placeholder: "select",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: BDMNameErrorMessege,
      },
    ],
    options: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const REGION_VISIT_WITH_ABH = [
  {
    key: "bankRegion",
    type: "select",
    id: "bankRegion",
    label: "Region and Region Head Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select bank region name.",
      },
    ],
    options: [],
  },
  {
    key: "ABHID",
    type: "select",
    id: "ABHID",
    label: "ABH Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Enter ABH Name",
      },
    ],
    options: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Branch_REGIONAL_OFFICE_MEETING_ABH = [
  {
    key: "bankRegion",
    type: "select",
    id: "bankRegion",
    label: "Bank Region Name",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select bank region name.",
      },
    ],
    options: [],
  },
  {
    key: "ABHID",
    type: "text",
    id: "ABHID",
    label: "ABH Name",
    disabled: true,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Enter ABH Name",
      },
    ],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "Month_Year",
    type: "select",
    id: "Month_Year",
    label: "Month / Year",
    min: getPrevousDateMonth,
    max: getNextDateMonth,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please select month/year",
      },
    ],
    options: [{ key: "Select", label: "Select", value: "" },
    { key: currentMonthYear, label: currentMonthYear, value: currentMonthYear },
    { key: nextMonthYear, label: nextMonthYear, value: nextMonthYear }],
  },
];

export const Branch_SALES_TRAINING_PLAN_BDM = [
  {
    key: "BDMName",

    type: "select",

    id: "BDMName",

    label: "BDM Name",

    validators: [
      {
        name: "required",

        value: true,

        validationMessage: BDMNameErrorMessege,
      },
    ],

    options: [],
  },
  {
    key: "typeOfTraning",

    type: "select",

    id: "typeOfTraning",

    label: "Type Of Training",

    validators: [
      {
        name: "required",

        value: true,

        validationMessage: "Please select training type.",
      },
    ],

    options: [],
    // options: ACTIVITY_TYPE_OPTIONS_TYPE_OF_TRAINING,
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
];

export const JOINT_CALLS_WITH_CUSTOMER = [
  {
    key: "BDMName",

    type: "select",

    id: "BDMName",

    label: "BDM Name",

    validators: [
      {
        name: "required",

        value: true,

        validationMessage: BDMNameErrorMessege,
      },
    ],

    options: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
]

export const Branch_JOINT_CALLS_BDM = [
  {
    key: "BDMName",

    type: "select",

    id: "BDMName",

    label: "BDM Name",

    validators: [
      {
        name: "required",

        value: true,

        validationMessage: BDMNameErrorMessege,
      },
    ],

    options: [],
  },
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
];

export const Branch_CIRCULAR_FOR_SALES_ENABLEMENT_ABH = [
  {
    key: "MeetingStartDateTime",
    noDate: null,
    min: minDate,
    type: datetimelocal,
    id: "MeetingStartDateTime",
    label: meetingStartDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    noDate: null,
    min: minDate,
    // max: maxDate,
    type: datetimelocal,
    id: "MeetingEndDateTime",
    label: meetingEndDateAndTimeLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Please Select Date",
      },
    ],
  },
];

export const getOptionById = (id, optionValues) => {
  return optionValues.find((item) => item.value === id);
};

export const handleSmaActivitySelection = (
  event,
  key,
  formFields,
  masterDataState,
  BDMList,
  trainingType,
  sortBankRegion,
  abhName,
  bdmByRBHName
) => {
  let formData = [...formFields];
  formData[0].options.value="27"
  if (key === "SalesMangementID") {
      formData = BankaSalesMangementActvityForm;
      const selectedActivity = getOptionById(
        event.target.value,
        masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY.TABLE_NAME]
      );
      switch (selectedActivity.key) {
        case"":
        formData = BankaSalesMangementActvityForm
        break;
        case "SCHEDULE_STRUCTURED_REVIEW_BDM":
          formData = formData.concat(Schedule_BDM);
          formData[1].options = BDMList;
          break;
        case "BRANCH_VISIT_PLAN_BDM":
          formData = formData.concat(Branch_Visit_Plan);
          formData[1].options = BDMList;
          break;
        case "REGIONAL_OFFICE_MEETING_ABH":
          formData = formData.concat(Branch_REGIONAL_OFFICE_MEETING_ABH);
          formData[1].options=sortBankRegion
          break;
        case "SALES_TRAINING_PLAN_BDM":
          formData = formData.concat(Branch_SALES_TRAINING_PLAN_BDM);
          formData[1].options = BDMList;
          formData[2].options = trainingType;
          break;
        case "JOINT_CALLS_BDM":
          formData = formData.concat(Branch_JOINT_CALLS_BDM);
          formData[1].options = BDMList;
          break;
        case "CIRCULAR_FOR_SALES_ENABLEMENT_ABH":
          formData = formData.concat(Branch_CIRCULAR_FOR_SALES_ENABLEMENT_ABH);
          break;
        case "SCHEDULE_STRUCTURED_REVIEW_(ABH’S)":
          formData = formData.concat(SCHEDULE_STRUCTURED_REVIEW_ABH);
          formData[2].options = abhName;
          break;
        case "BRANCH_VISIT_WITH_BDM_&_ABH":
          formData = formData.concat(BRANCH_VISIT_WITH_BDM_AND_ABH);
          formData[1].options = abhName;
        break;
        case "STRUCTURED_ZONAL_HEAD_MEETING":
          formData = formData.concat(STRUCTURED_ZONAL_HEAD_MEETING);
          formData[3].options=abhName
        break;
        case "MEETING_WITH_TOP_10_BDM’S":
        case "ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM’S":
          formData = formData.concat(MEETING_WITH_TOP_10_BDM);
          formData[1].options = bdmByRBHName;
          break;
        case "REGION_VISIT_WITH_ABH":
          formData = formData.concat(REGION_VISIT_WITH_ABH);
          formData[1].options=sortBankRegion
          formData[2].options=abhName
          break;
        case "JOINT_CALLS_WITH_CUSTOMER":
          formData = formData.concat(JOINT_CALLS_WITH_CUSTOMER);
          formData[1].options = bdmByRBHName;
          break;
        default:
          formData = formData.concat(Schedule_BDM);
          break;
      }
  }

  return formData;
};
