/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-17 11:26:58
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */

 import Localbase from 'localbase'

 let db
 export const createDb = () => {
     db = new Localbase('ifl-lms')
     db.config.debug = false;
 }
 export const deleteDb = () => {
     db.delete();
 }
 export const add = (collectionName, data) => {
     return db.collection(collectionName)
     .add(data, collectionName.toLowerCase())
 }
 export const getAllOfTable = (collectionName) => {
     console.log('getAllOfTable', collectionName);
     return db.collection(collectionName).get()
 }
 export const addSingleLead = (newLead) => {
     return new Promise((resolve, reject) => {
         const mKey = newLead.LeadId ? newLead.LeadId : newLead.LmsleadGuid;
         return db.collection('Leads')
         .add(newLead, mKey).then((res) => resolve(res)).catch(err => reject(false))
     })
 }
 export const getSingleLead = (id) => {
     return db.collection('Leads').doc(id).get()
 }
 export const updateLead = (key, object) => {
     return db.collection('Leads').doc(key).update(object)
 }
 export const addAllLeads = (leadList) => {
     return Promise.all(leadList.map(lead => {
         lead.IsLmsSync = true;
         db.collection('Leads')
         .add(lead, lead.LeadId)
     }))
 }
 export const removeAllLeads = (leadList) => {
     return Promise.all(leadList.map(lead => {
         db.collection('Leads').doc(lead.LmsleadGuid).delete();
     }))
 }
 export const removeAllActivities = (activityList) => {
     return Promise.all(activityList.map(activity => {
         db.collection('Activity').doc(activity.LmsActivityGuid).delete();
     }))
 }
 export const addAllHistoricalLeads = (leadList) => {
     return Promise.all(leadList.map(lead => {
         lead.IsLmsSync = true;
         db.collection('HistoricalLead')
             .add(lead, lead.LeadId)
     }))
 }
 
 export const addUserData = (userData) => {
     return db.collection('UserInfo')
     .add(userData, userData.id)
 }
 export const addLastSyncTime = (syncTime, key) => {
     return db.collection('LastSyncTimes')
     .add(syncTime, key)
 }
 export const getLastSyncByKey = (key) => {
     if (!db) {
         createDb();
         return db.collection('LastSyncTimes').doc(key).get()
     } else {
         return db.collection('LastSyncTimes').doc(key).get()
     }
 }
 export const getConfigData = (key) => {
     if (!db) {
         createDb();
         return db?.collection('configData').doc(key).get()
     } else {
         return db?.collection('configData').doc(key).get()
     }
 }
 export const getUserSession = (key) => {
     return db?.collection('UserInfo').get()
 }
 export const addSingleActivity = (newActivity) => {
     console.log('newActivity ', newActivity);
     return new Promise((resolve, reject) => {
         const mKey = newActivity.TrnActivityID ? newActivity.TrnActivityID : newActivity.LmsActivityGuid;
         return db.collection('Activity')
         .add(newActivity, mKey).then((res) => resolve(res)).catch(err => reject(false))
     })
 }
 export const getSingleActivity = (id) => {
     return db.collection('Activity').doc(id).get()
 }
 export const addAllActivity = (activityList) => {
     return Promise.all(activityList.map(activity => {
         db.collection('Activity')
         .add(activity, activity.TrnActivityID)
     }))
 }
 export const addAllHistoricalActivity = (activityList) => {
     return Promise.all(activityList.map(activity => {
         db.collection('HistoricalActivity')
             .add(activity, activity.TrnActivityID)
     }))
 }
 
 export const deleteDocFromCollection = (collectionName, key) => {
     return db.collection(collectionName).doc(key).delete();
 }
 export const updateActivityByKey = (key, activityObj) => {
     return db.collection('Activity').doc(key).set(activityObj);
 }
 export const leadHistory = (id) => {
     return db.collection('HistoricalLead').doc(id).get();
 }
 export const activityHistory = (id) => {
     return db.collection('HistoricalActivity').doc(id).get();
 }