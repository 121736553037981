import React from "react";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import DynamicForm from "f1-dynamic-form-react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    CscActivityForm,
    handleActivitySelection, initFormData, getAutoCompleteItems
} from "./csc/createActivityForm";

import {
    addDynamicData, updateDynamicData, getMasterData, uploadFileToBlob, activateSuccessMessageBar, activateErrorMessageBar,
    activateLoader, deActivateLoader, getUserBranches, setBreadcrumbs
} from "../../../redux/actions/common";
import { validateActivity } from "../../../redux/actions/createActivityActions";
import { CONFIGS, FOUR_WHEELER, PUBLIC_TRANSPORT, SELF_OWNED_TRANSPORT, TWO_WHEELER, STATUS_ACTIVITY_DRAFTED, STATUS_ACTIVITY_PLANNED, checkInConfig } from "../../../utils/config";
import './createActivity.scss';
import { isPositiveNumber } from "../../../utils/validators";
import moment from "moment";
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { getOptionByCode } from "./helper";
import SaveAsDraftDrawer from "./saveAsDraftDrawer";
import { saveActivityOffline } from "../../../offlineManager/activityOfflineManager"
import { v4 as uuidv4 } from 'uuid';

import { finishDayActivityCreate, DistrictList } from "../../../redux/actions";
import { getActivityDetails } from '../../../redux/actions/activityDetailsAction';
import { addMinuteToDate } from "../../../utils/utils";

const customValidators = [
    {
        'key': 'ExpectedLead',
        'validator': isPositiveNumber, // function to be called
        'message': 'Expected leads should be greater than 0' // message to show
    },
    {
        'key': 'ExpectedConversion',
        'validator': isPositiveNumber, // function to be called
        'message': 'Expected conversion should be greater than 0' // message to show
    },
    {
        'key': 'TicketSize',
        'validator': isPositiveNumber, // function to be called
        'message': 'Ticket size should be greater than 0' // message to show
    },
    {
        'key': 'ExpenseAmount',
        'validator': isPositiveNumber, // function to be called
        'message': 'Expense amount should be greater than 0' // message to show
    },
    {
        'key': 'BillNumber',
        'validator': isPositiveNumber, // function to be called
        'message': 'Bill number should be greater than 0' // message to show
    },
    {
        'key': 'TravelExpene',
        'validator': isPositiveNumber, // function to be called
        'message': 'Travel expense should be greater than 0' // message to show
    }
]

const createActivityTitle = "Create Activity";
const activityListing = '/app/activity/all';
class ResumeDraftedActivity extends React.Component {
    constructor() {
        super();
        this.dyFormRef = React.createRef();
        this.state = {
            current: {
                id: 1,
                MeetingEndDateTime: '',
                IsClaimRequired: false,
                BranchIdlabel: "asdasd",
                StateID: '',
                MeetingType: '',
                DistrictID: '',


            },
            formData: CscActivityForm,
            autoCompleteItems: {
                BranchId: [],
                StateID: [],
                DistrictID: [],
                MeetingType: [],
            },
            selectMeetingType: "",
            uploadedFile: '',
            originalFileName: '',
            extErrors: {},
            ExpectedConversion: '',
            ExpectedLead: '',
            isActiveMobileDraft: false,
            isFormSUbmitDisabled: false,
            channelId: "",
            responseMessage: ""
        }
    }


    loadBreadCrumbs = () => {
        const breadcrumbs = [
            { name: 'Activity', url: activityListing },
            { name: createActivityTitle, url: '/app/activity/create' },
        ]
        this.props.setBreadcrumbs(breadcrumbs);
    }

    isValidBusinessActivity = async (formData) => {

        if (formData.hasOwnProperty('MeetingStartDateTime') && formData.hasOwnProperty('MeetingEndDateTime')) {
            const checkActivity = await this.props.validateActivity({ user_id: this.props.userState.profileInfo.userId, formData: formData });
            if (checkActivity.status === 200 && checkActivity.data.Responsestatus === true) {
                return true
            } else {
                this.setState({
                    responseMessage: checkActivity.data.Responsemessage
                })
            }
            return false
        }
        return true
    }

    reset = (model) => {
        for (const [key] of Object.entries(model)) {
            model[key] = ''
        }
        this.setState({ uploadedFile: '', originalFileName: '', extErrors: {} })
        this.dyFormRef.reset(model)
    }

    onSubmitHandler = async (model) => {
        if(model.AverageTicketSize !== "" && model.AverageTicketSize !== null){
            delete model.errors['AverageTicketSize'];
        }
        
        if ((!model.isValidForm && Object.keys(model.errors).length > 0) || Object.keys(this.state.extErrors).length > 0 || Object.keys(model.errors).length > 0) {
            return false;
        }
        const formData = { ...model };
        delete formData['advanceLayoutFields'];
        delete formData['collapseStates'];
        delete formData['errors'];
        delete formData['isValidForm'];
        delete formData['id'];
        delete formData['BranchIdlabel']
        formData["IsBacklog"] = false;
        if (formData.hasOwnProperty('MeetingStartDateTime')) {
            formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"].replace("T", ' ')
        }
        if (formData.hasOwnProperty('MeetingEndDateTime')) {
            formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace("T", ' ')
        }
        if (formData.hasOwnProperty('UploadBill')) {
            formData["UploadBill"] = this.state.uploadedFile;
            formData["BillFileName"] = this.state.originalFileName;
        }

        const statusOption = getOptionByCode(STATUS_ACTIVITY_PLANNED, this.props.masterDataState.ActivityStatus)
        formData["ActivityStatusId"] = statusOption.value;
        formData["ActivityStatus"] = statusOption.label
        formData["UserId"] = this.props.userState.profileInfo.userId;
        formData["AssignedTo"] = parseInt(this.props.userState.profileInfo.userId);
        formData["CreatedDate"] = moment().format("YYYY-MM-DD HH:mm:ss");
        formData["ModifiedDate"] = moment().format("YYYY-MM-DD HH:mm:ss");
        formData["LmsleadGuid"] = uuidv4();
        formData["LmsleadlastSyncTime"] = new Date().toISOString().replace('T', ' ').replace('Z', '');
        formData["IsLmsSync"] = navigator.onLine;
        formData["CreatedBy"] = parseInt(this.props.userState.profileInfo.userId);
        // validate the activity from server side
        if (navigator.onLine) {
            this.props.activateLoader();
            const isValidActivity = await this.isValidBusinessActivity(formData)
            if (isValidActivity) {
                const result = await this.props.updateDynamicData({
                    'data': [formData], 'formName': CONFIGS.UPDATE_ACTIVITY_FORM,
                    formBuilder: "", userID: this.props.userState.profileInfo.userId
                });
                if (result.status === 200 && result.data.Responsestatus === true) {
                    this.props.activateSuccessMessageBar("Activity has been created successfully.");
                    this.props.deActivateLoader();
                    const saveFormData = { ...formData };
                    //  SAVE activity in indexDb online
                    saveFormData['TrnActivityID'] = result.data.Transactionid;
                    saveActivityOffline(saveFormData).then((res) => {
                        if (res) {
                            this.reset(formData);
                            this.props.history.push(`/app/activity/detail/${result.data.Transactionid}`)
                        }
                    })
                }
                else {
                    this.props.deActivateLoader();
                    this.props.activateSuccessMessageBar("Something went wrong! please try again later.")
                }
            }
            else {
                this.props.activateErrorMessageBar(this.state.responseMessage)
                this.props.deActivateLoader()
            }
        } else {
            //  SAVE activity in indexDb offline
            saveActivityOffline(formData).then((res) => {
                if (res) {
                    const { data: { key } } = res;
                    this.props.history.push(`/app/activity/detail/${key}`)
                }
            })
        }
    }


    handleSaveAsDraft = async (e) => {
        e.preventDefault();
        this.props.activateLoader();
        const _format = "YYYY-MM-DD HH:mm:ss";
        const formData = { ...this.dyFormRef.state };
        delete formData['advanceLayoutFields'];
        delete formData['collapseStates'];
        delete formData['errors'];
        delete formData['isValidForm'];
        delete formData['id'];
        delete formData['BranchIdlabel']
        formData["IsBacklog"] = false;
        if (formData.hasOwnProperty('MeetingStartDateTime')) {
            formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"].replace("T", ' ')
        }
        if (formData.hasOwnProperty('MeetingEndDateTime')) {
            formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace("T", ' ')
        }
        if (formData.hasOwnProperty('UploadBill')) {
            formData["UploadBill"] = this.state.uploadedFile;
            formData["BillFileName"] = this.state.originalFileName;
        }
        const statusOption = getOptionByCode(STATUS_ACTIVITY_DRAFTED, this.props.masterDataState.ActivityStatus)
        formData["ActivityStatusId"] = statusOption.value
        formData["UserId"] = this.props.userState.profileInfo.userId;
        formData["AssignedTo"] = this.props.userState.profileInfo.userId;
        formData["CreatedDate"] = moment(new Date()).format(_format);
        formData["ModifiedDate"] = new Date().toISOString().replace('T', ' ').replace('Z', '');

        const result = await this.props.updateDynamicData({
            'data': [formData],
            'formName': CONFIGS.UPDATE_ACTIVITY_FORM, formBuilder: "",
            userID: this.props.userState.profileInfo.userId
        })
        if (result.status === 200 && result.data.Responsestatus === true) {
            this.props.activateSuccessMessageBar("Activity has been drafted successfully.")
            this.reset(formData);
            this.props.deActivateLoader();
            this.props.history.push("/app/activity/all")
        }
        else {
            this.props.deActivateLoader();
            this.props.activateSuccessMessageBar("Something went wrong! please try again later.")
        }
    }

    getActivityDraftData = async () => {
        this.props.activateLoader()
        const activityId = this.props.match.params.id;
        const result = await this.props.getActivityDetails({ 'activityId': activityId })
        if (result.status === 200) {
            const data = result.data;
            let selectedFormData = this.state.formData;
            const defaultState = data;
            if (data?.ActivityID) {
                const formData = handleActivitySelection({ target: { value: data.ActivityID.toString() } },
                    "ActivityID", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId);
                selectedFormData = [...formData];
                defaultState.ActivityID = data.ActivityID.toString();
            }
            if (data?.TrainingFor) {
                const formData = handleActivitySelection({ target: { value: data.TrainingFor.toString() } },
                    "TrainingFor", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId)
                selectedFormData = [...formData];
                defaultState.TrainingFor = data.TrainingFor.toString();
            }

            if (data?.ReviewWith) {
                const formData = handleActivitySelection({ target: { value: data.ReviewWith.toString() } },
                    "ReviewWith", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId)
                selectedFormData = [...formData];
                defaultState.ReviewWith = data.ReviewWith.toString();
            }
            if (data?.MeetingWith) {
                const formData = handleActivitySelection({ target: { value: data.MeetingWith.toString() } },
                    "MeetingWith", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId)
                selectedFormData = [...formData];
                defaultState.MeetingWith = data.MeetingWith.toString();
            }

            if (data?.IsClaimRequired === ("True" || true) || data?.IsClaimRequired === ("False" || false)) {
                const flag = data?.IsClaimRequired === ("True" || true) ? true : false
                const formData = handleActivitySelection({ target: { value: flag } },
                    "IsClaimRequired", selectedFormData, this.props.masterDataState, this.props.userState.profileInfo.roleId)
                selectedFormData = [...formData]
            }
            if (data?.StateID) {
                defaultState.StateID = data.StateID.toString();
                defaultState.StateName = data.StateName;
                defaultState.StateIDlabel = data.StateName;
            } 
            if (data?.MeetingType) {
                defaultState.MeetingType = data.MeetingType.toString();
                defaultState.MeetingTypeName = data.MeetingTypeName;
                defaultState.MeetingTypelabel = data.MeetingTypeName;
            }

            if (data?.DistrictID) {
                defaultState.DistrictID = data.DistrictID.toString();
                defaultState.DistrictName = data.DistrictName;
                defaultState.DistrictIDlabel = data.DistrictName;
            }
            if (data?.MeetingEndDateTime) {
                defaultState["MeetingEndDateTime"] = addMinuteToDate(defaultState["MeetingEndDateTime"])
            }
            if (data?.AverageTicketSize) {
                defaultState.AverageTicketSize = data.AverageTicketSize;
            }
            this.setState({ formData: selectedFormData, current: defaultState })
            this.props.deActivateLoader()
        }

    }

    componentDidMount = () => {
        this.loadBreadCrumbs();
        const formData = this.props.masterDataState ? initFormData(this.state.formData, this.props.masterDataState) : this.state.formData;
        const autoCompleteItems = { ...this.state.autoCompleteItems };

        autoCompleteItems.BranchId = this.props.masterDataState ? getAutoCompleteItems(this.props.masterDataState, CONFIGS.MASTER_DATA_TABLES.BRANCHES.TABLE_NAME) : []

        autoCompleteItems.StateID = this.props.masterDataState
            ? getAutoCompleteItems(
                this.props.masterDataState,
                CONFIGS.MASTER_DATA_TABLES.STATE_ID.TABLE_NAME
            )
            : [];
        autoCompleteItems.MeetingType = this.props.masterDataState
            ? getAutoCompleteItems(
                this.props.masterDataState,
                CONFIGS.MASTER_DATA_TABLES.MEETING_TYPE.TABLE_NAME
            )
            : [];
        this.setState({ formData: formData, autoCompleteItems: autoCompleteItems, channelId: this.props.userState.profileInfo.channelid }, () => {
        });

        this.getActivityDraftData();
    }

    uploadFile = async (files) => {
        const file = files[0]
        if (file) {
            const fileName = new Date().getTime() + file.name;
            const fileRes = await this.props.uploadFileToBlob({ 'file': file, 'fileName': fileName });
            if (fileRes.status === 200) {
                this.setState({ uploadedFile: fileName, originalFileName: file.name })
            }
        }
    }
    checkFinishDayActivity = async (value) => {
        if (value === "6") {
            try {
                const result = await this.props.finishDayActivityCreate(
                    this.props.userState.profileInfo.userId
                );
                if (result.status === 200) {
                    this.setState({ isFormSUbmitDisabled: false });
                }
            } catch (e) {
                this.setState({ isFormSUbmitDisabled: true });
            }
        } else {
            this.setState({ isFormSUbmitDisabled: false })
        }
    }
    onChangeHandler = async (event, key) => {

        if (key === 'StateID' && this.state.formData[3].key === "DistrictID") {
            const districtOptions = await this.props.DistrictList(event.target.value)

            const form = [...this.state.formData]
            form[3].options = this.getDropdownData(districtOptions.data, 'DistrictCode', 'DistrictName', 'DistrictID',)
            form[3].hide = false;
            this.setState({ formData: form })
        }
        if (key === 'UploadBill') {
            if (navigator.onLine) {
                this.uploadFile(event);
            } else {
                this.props.activateErrorMessageBar(
                    "Cannot upload files in offline mode!"
                );
            }
        }
        else if (key === 'MeetingStartDateTime') {
            var newDate = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
            this.state.formData.map((option) => {
                if (option.key === "MeetingEndDateTime") {
                    option.max = moment(newDate).endOf('day').format("YYYY-MM-DD kk:mm");
                    option.min = newDate;
                }
                return option
            })

            this.setState({ current: { ...this.state.current, 
                MeetingStartDateTime: event.target.value, 
                MeetingEndDateTime: moment(new Date(newDate))
                .add(30, "m")
                .format('YYYY-MM-DDTHH:mm') } })

        }
        else if (key === 'MeetingEndDateTime') {
            const newDate = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
            this.setState({ current: { ...this.state.current, MeetingEndDateTime: newDate } })
        }
        else if (key === 'IsClaimRequired') {
            this.setState({ current: { ...this.state.current, IsClaimRequired: event } })
        }
        else if (key === "BranchId") {
            // Alert - if this empty else if removed automcomplete will not work
        }
        else if (key === "ActivityID") {
            this.setState({ current: { ...this.state.current, ActivityID: event.target.value, ActivityName: "" } })
            this.checkFinishDayActivity(event.target.value)
        }
        else if (key === "Location") {
            this.setState({
                current: { ...this.state.current, Location: event?.target ? event.target.value : event }
            })
        }
        else { this.setState({ current: { ...this.state.current, [key]: event.target.value } }) }

        const formData = handleActivitySelection(event, key, this.state.formData, this.props.masterDataState, this.props.userState.profileInfo.roleId)
        this.setState({ formData: formData });
    }

    onAutocompleteSelectHandler = (key, value, item) => {
        this.setState({ current: { ...this.state.current, [key]: value }, [key]: value })

    }

    onCancelHandler = () => {
        const isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
        if (isMobile) {
            this.setState({ isActiveMobileDraft: true })
        }
        else {
            this.props.history.push(activityListing)
        }
    }

    removeFile = (e, file, index, key) => {
        this.dyFormRef.removeFile(e, file, index, key);
        this.setState({ uploadedFile: '' })
    }

    renderUploadedFilesItem = (file, index, key) => {
        return (
            <li style={{ borderColor: '#ccc' }} key={index}>
                <span className="file-name">{file.name}</span>
                <span className="action" onClick={(e) => this.removeFile(e, file, index, key)}>
                    <i className="fa fa-trash"></i>
                </span>
            </li>
        );
    }

    renderRadioIcons = (radioOption) => {
        let icon = ""
        if (radioOption.key === PUBLIC_TRANSPORT) {
            icon = <DirectionsBusIcon></DirectionsBusIcon>
        }
        else if (radioOption.key === SELF_OWNED_TRANSPORT) {
            icon = <TwoWheelerIcon></TwoWheelerIcon>
        }
        else if (radioOption.key === TWO_WHEELER) {
            icon = <TwoWheelerIcon></TwoWheelerIcon>
        }
        else if (radioOption.key === FOUR_WHEELER) {
            icon = <DirectionsCarIcon></DirectionsCarIcon>
        }
        return icon
    }
    onCheckinHandler = (position, address) => {
        if (this.id) {
            this.setState({
                ...this.state.leadDetail, Location: address
            })
        }
    }

    toggleDrawer = (flag) => {
        this.setState({ isActiveMobileDraft: flag });
    }

    onDontSaveDraftHandler = () => {
        this.props.history.push(activityListing)
    }

    onSaveDraftHandler = () => {
        this.handleSaveAsDraft(new Event('test'))
    }



    render() {
        const { t } = this.props;
        return <div className="mainContainer createActivity">
            <Breadcrumb></Breadcrumb>
            <span className="formInfo">{t('Please fill in the activity information')}</span>
            <div className="formBox">
                <DynamicForm
                    key={this.state.current.id}
                    onRef={ref => this.dyFormRef = ref}
                    title={t(createActivityTitle)}
                    buttonSubmit={t('Submit')}
                    defaultValues={this.state.current}
                    model={this.state.formData}
                    onSubmit={(model) => {
                        this.onSubmitHandler(model);
                    }}
                    disableSubmitOnInValid={this.state.isFormSUbmitDisabled}
                    design="bootrstap"
                    materialOutline={false}
                    onChange={(event, key) => this.onChangeHandler(event, key)}
                    onAutocompleteSelect={this.onAutocompleteSelectHandler}
                    autoCompleteItems={this.state.autoCompleteItems}
                    showAstersikOnRequiredFields={true}
                    extErrors={this.state.extErrors}
                    checkInConfig={checkInConfig}
                    onCheckinHandler={this.onCheckinHandler}
                    className="activityTypeDiv"
                    extaraActionFormButton={<>
                        <a className="savedraftLink" onClick={(e) => this.handleSaveAsDraft(e)} href="#">SAVE AS DRAFT</a>
                        <button className="btn cancelBtn" key="cancel" id="cancel" type="button" onClick={this.onCancelHandler}>{t('Cancel')}</button>
                    </>}
                    fileUploaderFields={['UploadBill']}
                    renderUploadedFilesItem={this.renderUploadedFilesItem}
                    customValidators={customValidators}
                    renderRadioIcons={(option) => this.renderRadioIcons(option)}
                />
            </div>
            <SaveAsDraftDrawer
                isActiveMobileDraft={this.state.isActiveMobileDraft}
                toggleDrawer={this.toggleDrawer}
                onDontSaveHandler={this.onDontSaveDraftHandler}
                onSaveDraftHandler={this.onSaveDraftHandler}
            ></SaveAsDraftDrawer>
        </div>
    }
}

const mapStateToProps = state => ({
    masterDataState: state.commonReducer,
    userState: state.userReducer
});
const mapDispatchToProps = dispatch => ({
    addDynamicData: (payload) => dispatch(addDynamicData(payload)),
    updateDynamicData: (payload) => dispatch(updateDynamicData(payload)),
    getMasterData: (payload) => dispatch(getMasterData(payload)),
    uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
    validateActivity: (payload) => dispatch(validateActivity(payload)),
    activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
    activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
    activateLoader: (payload) => dispatch(activateLoader(payload)),
    deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
    getUserBranches: (payload) => dispatch(getUserBranches(payload)),
    getActivityDetails: (payload) => dispatch(getActivityDetails(payload)),
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
    finishDayActivityCreate: (payload) =>
        dispatch(finishDayActivityCreate(payload)),
    DistrictList: (payload) =>
        dispatch(DistrictList(payload)),
});
export default withTranslation('translation', { withRef: true })(connect(mapStateToProps, mapDispatchToProps)(ResumeDraftedActivity));
