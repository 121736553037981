import { createSlice } from '@reduxjs/toolkit';

const calendarActivityReducer = createSlice({
    name: "calendarActivityReducer",
    initialState: {
      leadActivityCalendarList: {},
      totalCount: 0,
      isLoading: false,
      error: false,
      selectedDate : ''
    },
    reducers: {
   
      getCalendarListData: (state, action) => {
        state.leadActivityCalendarList = action.payload;
        state.totalCount = action.payload.calenderActivities.length + action.payload.calenderLeads.length + action.payload.calenderCampaignLeads.length + action.payload.calenderPersistencyLeads.length + action.payload.calenderRecruitmentLead.length
        // state.isLoading = false;

        },
        setCalendarSelectedData:(state,action)=>{
            state.selectedDate = action.payload
        }
    }   
  });


  export const {getCalendarListData,setCalendarSelectedData} = calendarActivityReducer.actions
  export default calendarActivityReducer.reducer;

