
import { post, get } from '../api/api';
import { PULL_ACTIVITY, PULL_HISTORICAL_ACTIVITY, PUSH_ACTIVITY,PULL_HISTORICAL_CAMPAIGN_ACTIVITY } from '../api/baseURL';
import { addActivityInDb, addHistoricalActivityInDb, addUpdateLastActivitySyncTime, getAllActivityOffline } from './activityOfflineManager';
import { deleteActivities } from './leadOfflineManager';

 export const pushActivityList = (leadData) =>{
  const formdata = {
      "jsondata": leadData
  }
  return new Promise((resolve, reject) => {
      post(PUSH_ACTIVITY, formdata)
      .then(response => {
          resolve(response.data)
      }).catch(err => {
          reject(err)
      })
  })
}
export const pullActivityList = (id, lastSyncTime) => {
    return new Promise((resolve, reject) => {
        const url = lastSyncTime ? `${PULL_ACTIVITY}${'/'}${id}${'?activitylastSyncTime='}${lastSyncTime}` : `${PULL_ACTIVITY}${'/'}${id}`
        get(url)
        .then(response => {
            resolve(response.data)
        }).catch(() => reject(false))
    })
}
const pullActivitySave = async(userId, activityLastSyncTime) => {
    return pullActivityList(userId, activityLastSyncTime).then(res => {
      const leadCount = res.length
      if (res) {
        addActivityInDb(res).then((result)=> {
          if (result && result.length === leadCount) {
            const numberOfMillisecondsSinceEpoch = Date.now()
            addUpdateLastActivitySyncTime(numberOfMillisecondsSinceEpoch)
            return true
          }
          return false
        })
      } else {
        console.log('pullActivityList false', res);
      }
    }).catch((err) => {
        console.log('pullActivityList err', err);
    })
}
export const initActivityPull = (userId, activityLastSyncTime) => {
    pullActivitySave(userId, activityLastSyncTime)
}
export const initPushActivity = (userId, activityLastSyncTime) => {
  return getAllActivityOffline().then((res) => {
      if (res && res.length > 0) {
          const activityList = res.filter((lead) => lead.IsLmsSync === false)
          const last3Again = activityList;
          if (activityList.length > 0) {
              pushActivityList(last3Again).then((result) => {
                  if (result.GuidDetails && result.GuidDetails.length > 0) {
                      // write a logic to update the leads in indexDB
                      initActivityPull(userId, activityLastSyncTime);
                      deletePushedActivities(result.GuidDetails);
                  }
              }).catch((err) => {
                  initActivityPull(userId, activityLastSyncTime);
              })
          } else {
            initActivityPull(userId, activityLastSyncTime);
          }
      } else {
        initActivityPull(userId, activityLastSyncTime);
      }
  })
}
const deletePushedActivities = (activities) => {
  deleteActivities(activities).then((result)=> {
    console.log('::: deletePushedActivities :::', result);
  })
}

//Historical Activity

export const pullHistoricalActivityList = (userID, lastSyncTime=0) => {
  return new Promise((resolve, reject) => {
    const url = `${PULL_HISTORICAL_ACTIVITY}${'/'}${userID}${'?activitylastSyncTime='}${lastSyncTime}`
    get(url)
      .then(response => {
        resolve(response.data)
      }).catch(() => reject(false))
  })
}

const pullHistoricalActivitySave = async (userId, activityLastSyncTime) => {
  return pullHistoricalActivityList(userId, activityLastSyncTime).then(res => {
    const leadCount = res.length
    if (res) {
      addHistoricalActivityInDb(res).then((result) => {
        if (result && result.length === leadCount) {
          const numberOfMillisecondsSinceEpoch = Date.now()
          addUpdateLastActivitySyncTime(numberOfMillisecondsSinceEpoch)
          return true
        }
        return false
      })
    } else {
      console.log('pullHistoricalActivityList false', res);
    }
  }).catch((err) => {
    console.log('pullHistoricalActivityList err', err);
  })
}

export const initHistoricalActivityPull = (userId, activityLastSyncTime) => {
  pullHistoricalActivitySave(userId, activityLastSyncTime)
}

// HIstorical Campaign Activity

export const pullHistoricalCampaignActivityList = (userID, lastSyncTime=0) => {
  return new Promise((resolve, reject) => {
    const url = `${PULL_HISTORICAL_CAMPAIGN_ACTIVITY}${'/'}${userID}${'?activitylastSyncTime='}${lastSyncTime}`
    get(url)
      .then(response => {
        resolve(response.data)
      }).catch(() => reject(false))
  })
}

const pullHistoricalCampaignActivitySave = async (userId, activityLastSyncTime) => {
  return pullHistoricalCampaignActivityList(userId, activityLastSyncTime).then(res => {
    const leadCount = res.length
    if (res) {
      addHistoricalActivityInDb(res).then((result) => {
        if (result && result.length === leadCount) {
          const numberOfMillisecondsSinceEpoch = Date.now()
          addUpdateLastActivitySyncTime(numberOfMillisecondsSinceEpoch)
          return true
        }
        return false
      })
    } else {
      console.log('pullHistoricalCampaignActivityList false', res);
    }
  }).catch((err) => {
    console.log('pullHistoricalCampaignActivityList err', err);
  })
}

export const initHistoricalCampaignActivityPull = (userId, activityLastSyncTime) => {
  pullHistoricalCampaignActivitySave(userId, activityLastSyncTime)
}