import React, { useState, useEffect } from 'react'
import "font-awesome/css/font-awesome.min.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useOnlineStatus from '../../../hooks/useOnlineStatus';
import { activateLoader, deActivateLoader } from "../../../redux/actions/common";
import { getPerformanceStatDataById } from '../../../redux/actions/dashboardAction';
import { useSelector } from 'react-redux';
import LineChart from '../../../components/lineChart/lineChart';

const PerformanceStatsCard = (props) => {
    const { roleId } = props;
    const commonState = useSelector(state => state);
    const isOnline = useOnlineStatus();
    const [userId] = useState(commonState.userReducer.profileInfo.userId);
    const [timePeriod, setTimePeriod] = React.useState('monthly');
    const { viewForStatus, GetBDMByABH } = useSelector(state => state.commonReducer);
    const [dataSet, setDataSet] = useState(
        {
            labels: [],
            datasets: [
                {
                    label: "No. of Leads vs Days",
                    data: [],
                    borderColor: "rgba(2, 146, 221, 1)",
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.2)",
                    tension: 0.3
                }
            ]
        }
    );
    const [leadData, setLeadData] = useState({
        convertedLead: 0,
        totalWRP: 0
    });
    useEffect(() => {
        getPerformanceStatData();
    }, [])
    const handleChange = (event) => {
        setTimePeriod(event.target.value);
        getPerformanceStatData(event.target.value);
    };
    const getPerformanceStatData = async (query = 'monthly') => {
        if (isOnline) {
            activateLoader();
            const request = {
                userId: userId,
                roleName: roleId,
                viewFor: viewForStatus, //selectedView
                subordinateUserIds: GetBDMByABH,// selectedView
                viewByDate: query,
            };
            const res = await getPerformanceStatDataById(request);
            if (res.status === 200) {
                const { LeadDetails, ExpectedPremium, TotalLead } = res.data[0];
                setLeadData({ convertedLead: TotalLead, totalWRP: ExpectedPremium });
                const graphData = {
                    labels: [],
                    datasets: [
                        {
                            label: "No. of Leads vs Days",
                            data: [],
                            borderColor: "rgba(2, 146, 221, 1)",
                            fill: true,
                            backgroundColor: "rgba(75,192,192,0.2)",
                            tension: 0.3,
                        }
                    ]
                }
                LeadDetails?.map((item) => {
                    graphData.labels.push(item.DataGroup);
                    graphData.datasets[0].data.push(item.LeadCount);
                    return graphData;
                })
                // below code is to test the graph for random data
                // const daas = Array.from(Array(30).keys());
                // let dasd = Array(40).fill().map(() => Math.round(Math.random() * 40));
                // graphData.labels = daas;
                // graphData.datasets[0].data = dasd;
                setDataSet(graphData)
                deActivateLoader();

            } else {
                deActivateLoader();
            }
        }
    }

    return (
        <div className='performanceStatsContainer'>
            <Grid item xs={12} md={12}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h6" gutterBottom>
                            Total Converted Leads: <span className='countLabel'>{leadData?.convertedLead}</span>
                        </Typography>
                        {(commonState.userReducer.profileInfo.channelid !== 13 && commonState.userReducer.profileInfo.channelid !== 79) &&
                            <Typography variant="h6" gutterBottom>
                                Total WRP: <CurrencyRupeeIcon className='rupeeIcon' fontSize="small" />
                                <span className='countLabel'>{leadData?.totalWRP?.toLocaleString()}</span>
                            </Typography>
                        }
                        {(commonState.userReducer.profileInfo.channelid === 13 || commonState.userReducer.profileInfo.channelid === 79) &&
                            <Typography variant="h6" gutterBottom>
                                Total Premium: <CurrencyRupeeIcon className='rupeeIcon' fontSize="small" />
                                <span className='countLabel'>{leadData?.totalWRP?.toLocaleString()}</span>
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={4} textAlign="right">
                        <FormControl className='selBx' sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={timePeriod}
                                onChange={handleChange}
                            >
                                <MenuItem value='monthly'>Last 30 Days</MenuItem>
                                <MenuItem value='15 days'>Last 15 Days</MenuItem>
                                <MenuItem value='7 days'>Last 7 Days</MenuItem>
                                <MenuItem value='Today'>Today</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <LineChart chartData={dataSet} />
                    </Grid>
                </Grid>
            </Grid>

        </div>
    )
}
export default PerformanceStatsCard;
