import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckinMarker from '../../../../assets/img/checkInMarkerDetails.svg'
import { Fragment, useState } from 'react';
import { Drawer } from '@mui/material';
import MapRoute from '../../leads/mapRoute';
import { useParams } from 'react-router-dom';
import {useDispatch} from "react-redux"
import useOnlineStatus from '../../../../hooks/useOnlineStatus';
import ModalPopup from 'f1-modal-react';
import { Button } from '@material-ui/core';
import { activateErrorMessageBar } from '../../../../redux/actions';

const ADDRESS_NOT_FOUND = 'Couldn\'t find the address for the selected location!'
const CheckInCard = (props) => {
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch()
    const classes = props.classes
    const [drawerList, setDrwerList] = useState({
        right: false
    })
    const [showModalSetup, setShowModalSetup] = useState(false);
    const { id } = useParams();


    const toggleDrawer = (anchor, open) => (event) => {
        if (isOnline && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (success, error, options) {
                console.log("success, error, options", success, error, options);
                setShowModalSetup(false)
                if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                    return;
                }
                setDrwerList({ ...drawerList, [anchor]: open });
            },
                function (error) {
                    console.log("error", error);
                    setDrwerList(false)
                    dispatch(activateErrorMessageBar(ADDRESS_NOT_FOUND))
                    setShowModalSetup(true)
                    if (error.code == error.PERMISSION_DENIED)
                        setShowModalSetup(true)
                });
        } else{
            dispatch(activateErrorMessageBar('Geolocation API not supported by this browser.'))
        }
    };

    const handleClose = () => {
        setShowModalSetup(false)
        toggleDrawer('right', true)
    }
    const getUpdatedHistory = () => {
        console.log('check-in card getUpdatedHistory');
        props.onGetUpdatedHistory();
    }
    return <Paper className={classes.paper2} sx={{ mb: 2 }}>
        <Grid className='activityDetailsSec' container spacing={1}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem alignItems="flex-start" onClick={toggleDrawer('right', true)}>
                    <Grid className='checkInDiv' container spacing={4}>
                        <Grid item xs={4} md={4} >
                            {/* <PinDropIcon fontSize="large" sx={{ color: 'orange' }} /> */}
                            <img src={CheckinMarker} alt="checkInMarker"></img>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <label style={{ color: 'black' }}><b>Check In</b></label>
                        </Grid>
                        <Grid item xs={1} md={1}>
                            <span style={{ cursor: isOnline ? 'pointer' : 'not-allowed' }} onClick={toggleDrawer('right', true)}><ArrowRightAltIcon /></span>
                        </Grid>
                    </Grid>
                </ListItem>
                {['right'].map((anchor) => (
                    <Fragment key={anchor}>
                        <Drawer
                            anchor={anchor}
                            open={drawerList[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            <MapRoute status="checkinactivity" id={id} checkinCount={props.CheckinCount} statusId={props.statusId} setDrwerList={setDrwerList} onCheckInComplete={getUpdatedHistory} isCampiagn={props.isCampiagn} />
                        </Drawer>
                    </Fragment>
                ))}
            </List>
        </Grid>
        <ModalPopup
            show={showModalSetup}
            onHide={() => setShowModalSetup(!showModalSetup)}
            size={"sm"}
            centered={true}
            modelHeader={"Please enable your location"}
            modelContent={
                <Button
                    onClick={handleClose}
                >
                    CLOSE
                </Button>
            }
            backdrop={"static"}
        />
    </Paper>
}

export default CheckInCard
