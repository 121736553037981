import React, { useState, useEffect, useRef } from "react";
import Dynamicform from "f1-dynamic-form-react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  BrocaActivityForm,
  handleActivitySelection,
  initFormData,
  provideDetailsValidator,
} from "./createActivityForm";
import {
  checkDateIsValid,
  isAlphabetOnly,
  isEmailValid,
  isPositiveNumber,
  numberStartWith6789Validator,
  onlyAlphanumericSpaces,
  onlyLettersAndSpaces,
} from "../../../../utils/validators";
import {
  activateErrorMessageBar,
  activateLoader,
  activateSuccessMessageBar,
  addDynamicData,
  deActivateLoader,
  finishDayActivityCreate,
  validateActivity,
  setBreadcrumbs,
  getPartnerDetails,
} from "../../../../redux/actions";
import { getOptionByCode, getSubTitles, getSubTitlesBroca } from "../helper";
import { minusMinuteFromDate, uuidv4 } from "../../../../utils/utils";
import {
  CONFIGS,
  MUST_DO,
  PARTNER_ENGAGEMENT,
  STATUS_ACTIVITY_DRAFTED,
} from "../../../../utils/config";
import { saveActivityOffline } from "../../../../offlineManager/activityOfflineManager";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import SaveAsDraftDrawer from "../saveAsDraftDrawer";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import IdleTimer from "react-idle-timer";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";

const customValidators = [
  {
    key: "ContactDetails",
    validator: numberStartWith6789Validator,
    message: "10 digit phone number starting with 6,7,8,9 only.",
  },
  {
    key: "SecretaryMobileNo",
    validator: numberStartWith6789Validator,
    message: "10 digit phone number starting with 6,7,8,9 only.",
  },
  {
    key: "MobileNo",
    validator: numberStartWith6789Validator,
    message: "10 digit phone number starting with 6,7,8,9 only.",
  },
  {
    key: "EmailID",
    validator: isEmailValid,
    message:
      "invalid email id with missing domain name, @, .com, gmail id etc.",
  },
  {
    key: "NoOfAttendees",
    validator: isPositiveNumber, // function to be called
    message: "No of attendees  should be greater than 0",
  },
  {
    key: "NoOfLeads",
    validator: isPositiveNumber, // function to be called
    message: "No Of Leads  should be greater than 0",
  },
  {
    key: "SecretaryEmailID",
    validator: isEmailValid,
    message:
      "invalid email id with missing domain name, @, .com, gmail id etc.",
  },
  {
    key: "LaunchProductName",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "Name",
    validator: isAlphabetOnly,
    message: 'Please enter only alphabets'
  },
  {
    key: "NameOfTheProspect",
    validator: isAlphabetOnly,
    message: 'Please enter only alphabets'
  },
  {
    key: "OtherActivityPlanned",
    validator: onlyAlphanumericSpaces,
    message: 'Please enter alphabets and number only.'
  },
  {
    key: "OtherTrainingTopic",
    validator: onlyAlphanumericSpaces,
    message: 'Please enter alphabets and number only.'
  },
  {
    key: "OtherDesignation",
    validator: onlyAlphanumericSpaces,
    message: 'Please enter alphabets and number only.'
  },
  {
    key: "OtherJointCallActivity",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "CurrentPartner1",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "CurrentPartner2",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "CurrentPartner3",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "LastAssignmentPlace",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "SecretaryName",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "Location",
    validator: onlyLettersAndSpaces,
    message: 'Please enter only alphabets and space'
  },
  {
    key: "OtherCampaigningDone",
    validator: onlyAlphanumericSpaces,
    message: 'Please enter alphabets and number only.'
  },
];
const createActivityTitle = "Create Activity";
const _formatDate = "YYYY-MM-DDTHH:mm";
const dateFormate = "yyyy-MM-dd";
const _url = "/app/activity/all";

  const CreateActivityBroca = (props) => {
  let dyFormRef = useRef();
  const history = useHistory();
  let   idleTimer = useRef()

  const [current, setCurrent] = useState({
    id: 1,
    MeetingStartDateTime: moment(new Date()).format(_formatDate),
    MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
    SinceWhen: format(new Date(), dateFormate),
    RetirementDate: format(new Date(), dateFormate),
    MobileNo: "",
    EmailID: "",
    SecretaryEmailID: "",
  });

  const [formDataState, setFormDataState] = useState(BrocaActivityForm);
  const [extErrors, setextErrors] = useState({});
  const [responseMessage] = useState("");
  const [isActiveMobileDraft, setisActiveMobileDraft] = useState(false);
  // const [partnerDetails, setPartnerDetails] = useState([]);
  const [timeOutMsg,setTimeOutMsg] = useState(false)

  const onSubmit = async (model) => {
    if (model.OtherJointCallActivity === ''){
    delete model.errors["OtherJointCallActivity"];
    }
    if (model.CurrentPartner2 === ''){
    delete model.errors["CurrentPartner2"];
    }
    if (model.CurrentPartner3 === ''){
      delete model.errors["CurrentPartner3"];
    }
    if (model.SecretaryEmailID === "") {
      delete model.errors["SecretaryEmailID"];
    }
    if (model.SecretaryName === "") {
      delete model.errors["SecretaryName"];
    }
    if (model.OtherCampaigningDone === "") {
      delete model.errors["OtherCampaigningDone"];
    }
    if (model.ActivityID === "52") {
      if (model.EmailID === "" && model.MobileNo === "") {
        props.activateErrorMessageBar("Please enter email or contact details");
        return false;
      }
    }
    if (model.EmailID === "") {
      delete model.errors["EmailID"];
    }
    if (
      !model.isValidForm ||
      Object.keys(extErrors).length > 0 ||
      Object.keys(model.errors).length > 0
    ) {
      const errorListClasess = Object.keys(model.errors);
      const errArr = [];
      const formD = formDataState;
      formD.map((el, idx) => {
        if (el.key == errorListClasess[0]) {
          errArr.push(idx + 1);
        }
      });
      const errorElement = document.getElementsByClassName(
        `${"control"}${errArr[0]}`
      )[0];
      if (errorElement) {
        errorElement.scrollIntoView({
          block: "center",
        });
      }
      if (
        (!model.isValidForm && Object.keys(model.errors).length > 0) ||
        Object.keys(model.errors).length > 0 ||
        Object.keys(extErrors).length > 0
      ) {
        props.activateErrorMessageBar("Please fill all required fields");
        return false;
      }
    }

    const isBranchNameLabel = props.masterDataState.Branches.some(ele => ele.value === model.BranchId);
    const isBranchName = props.masterDataState.Branches.filter(ele => ele.value === model.BranchId);
    if (model.BranchId !== '' && isBranchNameLabel || (model.ActivityID == 53 || model.ActivityID == 52)) {
      delete model.errors.BranchId;
    }
    else if (!isBranchNameLabel) {
      const extError = { ...extErrors, 'BranchId': 'Invalid BranchId' }

      setextErrors(extError)
    }
    if (
      !model.isValidForm ||
      (Object.keys(extErrors).length > 0 &&
        !extErrors.MobileNo) ||
      Object.keys(model.errors).length > 0 ||
      !isBranchNameLabel
    ) {
      const errList = Object.keys(extErrors)
      if (errList && errList.length > 0 && errList.includes('BranchId')) {
        const errorElement = document.getElementsByClassName(`control-BranchId`)[0];
        if (errorElement) {
          errorElement.scrollIntoView({
            block: 'center'
          });
        }
        return false;
      }
    }

    const formData = { ...model };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    delete formData["BranchIdlabel"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = minusMinuteFromDate(
        formData["MeetingEndDateTime"]
      ).replace("T", " ");
    }

    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["UserId"] = props.userState.profileInfo.userId;
    formData["AssignedTo"] = props.userState.profileInfo.userId;
    formData["CreatedDate"] = moment().format(_format);
    formData["ModifiedDate"] = moment().format(_format);
    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(_format);
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["ActivityStatusId"] = props.masterDataState.ActivityStatus[1].value;
    
    const activityName = getActivityName(formData.ActivityID);
    if (formData.ActivityID == 53 || formData.ActivityID == 52){
      formData["ActivityName"] = getSubTitles(activityName, formData?.BranchId, undefined , formData.MeetingStartDateTime);
    }   else {
      formData["ActivityName"] = getSubTitlesBroca(activityName, formData?.BranchId, isBranchName, formData.MeetingStartDateTime);
   }
    // formData["ActivityName"] = getActivityName(formData.ActivityID);
    
    formData["AssignedToName"] = `${props.userState.profileInfo.firstName}${" "}${
      props.userState.profileInfo.lastName
    }`;
    formData["IsBacklog"] = false;
    formData["CreatedBy"] = parseInt(props.userState.profileInfo.userId);

    if (navigator.onLine) {
      props.activateLoader();
      let isValidActivity = await isValidBusinessActivity(formData);
      if (isValidActivity) {
        const result = await props.addDynamicData({
          data: { ...formData, IsBacklog: 0 },
          formName: CONFIGS.NEW_ACTIVITY_FORM,
        });
        if (result.status === 200 && result.data.Responsestatus === true) {
          props.activateSuccessMessageBar("Activity has been created successfully.");

          props.deActivateLoader();
          const saveFormData = { ...formData };
          //  SAVE activity in indexDb online
          saveFormData["TrnActivityID"] = result.data.Transactionid;
          saveFormData["IsBacklog"] = false;
          saveActivityOffline(saveFormData).then((res) => {
            if (res) {
              reset(formData);
              history.push(`/app/activity/detail/${result.data.Transactionid}`);
            }
          });
        } else {
          props.deActivateLoader();
          props.activateSuccessMessageBar(
            "Something went wrong! please try again later."
          );
        }
      } else {
        props.activateErrorMessageBar(responseMessage);
        props.deActivateLoader();
      }
    } else {
      props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          history.push(`/app/activity/all`);
        }
      });
    }
  };

  const onChangeHandler = async (event, key, model) => {
    if ( key === "SinceWhen" || key ===  "RetirementDate" || key === "SecretaryEmailID"){
      const details = { ...current, [key]: event?.target?.value };
      setCurrent(details);
    }
    const copyFormData = formDataState;
    switch (key) {
      case "ActivityID":
        model.errors = {};
        break;
      case "ActivityPlannedId":
        if (event.target.value === "5") {
          copyFormData[6].hide = false;
          copyFormData[6].validators = provideDetailsValidator;
        } else {
          copyFormData[6].hide = true;
          copyFormData[6].validators = [];
        }
        break;
      case "TrainingTopic":
        if (event.target.value === "Others") {
          copyFormData[5].hide = false;
          copyFormData[5].validators = provideDetailsValidator;
        } else {
          copyFormData[5].hide = true;
          copyFormData[5].validators = [];
        }
        break;
      case "JointCallActivityID":
        if (PARTNER_ENGAGEMENT.includes(event.target.value)) {
          copyFormData[6].options =
            props.masterDataState.BrocaJointCallActivityType.slice(1, 2);
          copyFormData[6].validators = [];
          setCurrent({ ...current, JointCAllACtivtytypeID: "1" });
        }
        if (MUST_DO.includes(event.target.value)) {
          const options = props.masterDataState.BrocaJointCallActivityType.slice(
            2,
            3
          );
          copyFormData[6].options = options;
          copyFormData[6].validators = [];
          setCurrent({ ...current, JointCAllACtivtytypeID: "2" });
        }
        if (event.target.value === "11") {
          copyFormData[6].options = props.masterDataState.BrocaJointCallActivityType;
          copyFormData[6].validators = [
            {
              name: "required",
              value: true,
              validationMessage: "Please select type",
            },
          ];
          setCurrent({ ...current, JointCAllACtivtytypeID: "" });
          copyFormData[5].hide = false;
        } else {
          copyFormData[5].hide = true;
        }
        break;
      case "JointCAllACtivtytypeID":
        setCurrent({ ...current, JointCAllACtivtytypeID: event.target.value });
        break;
      case "CampaigningDoneId":
        if (event.target.value === "3") {
          copyFormData[6].hide = false;
        } else {
          copyFormData[6].hide = true;
        }
        break;
      case "MeetingStartDateTime":
        let StartDate = moment(event.target.value).format(_formatDate);
        let newDate = moment(event.target.value)
          .add(30, "m")
          .format(_formatDate);
        formDataState.map((option) => {
          if (option.key === "MeetingEndDateTime") {
            option.max = moment(StartDate)
              .endOf("day")
              .format("YYYY-MM-DD kk:mm");
            option.min = StartDate;
          }
        });
        if (
          !checkDateIsValid(
            event.target.value,
            moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
          )
        ) {
          setextErrors({ ...extErrors, [key]: "please select future value" });
        } else {
          delete extErrors?.MeetingStartDateTime;
          setextErrors({ ...extErrors });
        }
        setCurrent({
          ...current,
          MeetingStartDateTime: StartDate,
          MeetingEndDateTime: newDate,
        });
        break;
      case "MeetingEndDateTime":
        if (
          !checkDateIsValid(event.target.value, current.MeetingStartDateTime)
        ) {
          setextErrors({
            ...extErrors,
            MeetingEndDateTime:
              "meeting end date should not less than meeting starting date",
          });
        } else {
          delete extErrors.MeetingEndDateTime;
          setextErrors({ ...extErrors });
        }
        setCurrent({
          ...current,
          MeetingEndDateTime: newDate,
        });
        break;
      case "BranchId":
        // const { Branches } = props.masterDataState;
        if (event.target.value && event.target.value !== "") {
          // const getBranchCode = Branches.filter(
          //   (item) => item.value == event.target.value
          // );
          // const res = await props.getPartnerDetails(getBranchCode[0].key);
          // setPartnerDetails(res.data);
          // copyFormData.find((elem, index) => {
          //   if (elem.key === "PartnerName") {
          //     copyFormData[index].options = getDropdownData(
          //       res.data,
          //       "Id",
          //       "PartnerName",
          //       "PartnerName"
          //     );
          //   }
          // });
        }
        break;
      case "EmailID":
        setCurrent({ ...current, EmailID: event.target.value });
        break;
      case "MobileNo":
        setCurrent({ ...current, MobileNo: event.target.value });
        break;
      case "PartnerName":
        const getPartnerMobileNO = props.masterDataState.getPatnerDetails.filter(
          (item) =>
          item.PartnerName == event.target.value
        );
        setCurrent({
          ...current,
          PartnerMobileNo: getPartnerMobileNO[0]?.MobileNo.toString(),
        });
        break;
      case "RetirementDate":
        if (
          !checkDateIsValid(
            event.target.value,
            model.SinceWhen
          )
        ) {
          setextErrors({ ...extErrors, [key]: "please select  Retirement date should be greater than Since when " });
        } else {
          delete extErrors?.[key];
          setextErrors({ ...extErrors });
        }

        break;
        default:
        break;
    }
    setFormDataState(copyFormData);
    const formData = handleActivitySelection(
      event,
      key,
      formDataState,
      props.masterDataState,
      props.userState.profileInfo.roleId
    );
    setFormDataState(formData);
  };

  const getActivityName = (inputId) => {
    const copyFormData = formDataState;
    const name = copyFormData[0].options.filter(
      (el) => el.value === inputId.toString()
    );
    if (name && name.length > 0) {
      return name[0].label;
    }
    return null;
  };
  const isValidBusinessActivity = async (formData) => {
    if (
      formData.hasOwnProperty("MeetingStartDateTime") &&
      formData.hasOwnProperty("MeetingEndDateTime")
    ) {
      const checkActivity = await props.validateActivity({
        user_id: props.userState.profileInfo.userId,
        formData: formData,
      });
      if (
        checkActivity.status === 200 &&
        checkActivity.data.Responsestatus === true
      ) {
        return true;
      } else {
        this.setState({
          responseMessage: checkActivity.data.Responsemessage,
        });
      }
      return false;
    }
    return true;
  };

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    const formData = { ...dyFormRef.current.state };
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["id"];
    if (formData.hasOwnProperty("MeetingStartDateTime")) {
      formData["MeetingStartDateTime"] = formData[
        "MeetingStartDateTime"
      ].replace("T", " ");
    }
    if (formData.hasOwnProperty("MeetingEndDateTime")) {
      formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"].replace(
        "T",
        " "
      );
    }

    const statusOption = getOptionByCode(
      STATUS_ACTIVITY_DRAFTED,
      props.masterDataState.ActivityStatus
    );
    formData["ActivityStatusId"] = statusOption.value;
    formData["UserId"] = props.userState.profileInfo.userId;
    formData["ModifiedBy"] = props.userState.profileInfo.userId;
    formData["CreatedBy"] = props.userState.profileInfo.userId;
    formData["AssignedTo"] = props.userState.profileInfo.userId;
    const _format = "YYYY-MM-DD HH:mm:ss";
    formData["CreatedDate"] = moment(new Date()).format(_format);
    formData["ModifiedDate"] = moment(new Date()).format(_format);

    formData["LmsActivityGuid"] = uuidv4();
    formData["LmsActivitylastSyncTime"] = moment().format(
      "YYYY-MM-DD HH:mm:ss"
    );
    formData["IsLmsSync"] = navigator.onLine;
    formData["TrnActivityID"] = null;
    formData["ActivityStatus"] = statusOption.label;
    formData["AssignedToName"] = `${props.userState.profileInfo.firstName}${" "}${
      props.userState.profileInfo.lastName
    }`;
    formData["IsBacklog"] = false;
    if (navigator.onLine) {
      const result = await props.addDynamicData({
        data: { ...formData, IsBacklog: 0 },
        formName: CONFIGS.NEW_ACTIVITY_FORM,
        CreatedBy: props.userState.profileInfo.userId,
      });
      if (result.status === 200 && result.data.Responsestatus === true) {
        props.activateSuccessMessageBar("Activity has been drafted successfully.");
        reset(formData);
        props.deActivateLoader();
        saveActivityOffline(formData);
        history.push("/app/activity/all");
      } else {
        props.deActivateLoader();
        props.activateSuccessMessageBar(
          "Something went wrong! please try again later."
        );
      }
    } else {
      props.deActivateLoader();
      //  SAVE activity in indexDb offline
      saveActivityOffline(formData).then((res) => {
        if (res) {
          history.push(`/app/activity/all`);
        }
      });
    }
  };

  const reset = (model) => {
    for (let [key] of Object.entries(model)) {
      model[key] = "";
    }
    dyFormRef.current = model;
  };

  const onCancelHandler = () => {
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 468 ||
      navigator.userAgent.indexOf("Mobi") > -1;
    if (isMobile) {
      setisActiveMobileDraft(true);
    } else {
      history.push(_url);
    }
  };

  // const getDropdownData = (optionsArr, key, label, value) => {
  //   let arr = [];
  //   if (optionsArr && optionsArr.length > 0) {
  //     arr = optionsArr?.map((option) => {
  //       return { key: option[key], label: option[label], value: option[value] };
  //     });
  //   }
  //   arr.unshift({ key: "select", label: "select", value: "" });
  //   return arr;
  // };
  const toggleDrawer = (flag) => {
    setisActiveMobileDraft(flag);
  };

  const onDontSaveDraftHandler = () => {
    history.push(_url);
  };
  const onSaveDraftHandler = () => {
    handleSaveAsDraft(new Event("test"));
  };
  const LoadBreadCrumbs = () => {
    const breadcrumbs = [
      { name: "Activity", url: _url },
      { name: createActivityTitle, url: "/app/activity/create" },
    ];
    props.setBreadcrumbs(breadcrumbs);
  };

  const handleOnAction = (event) => {
    if (timeOutMsg) {
      setTimeout(() => {
        history.push("/app/home")
      }, 3000)
    }
  }

  const handleOnActive = (event) => {
    if (idleTimer.current.getRemainingTime() === 0) {
      setTimeOutMsg(idleTimer.current.getRemainingTime() === 0)
    }
  }
  const handleOnIdle = (event) => {
  }

  useEffect(() => {
    const formData = initFormData(
      formDataState,
      props.masterDataState,
      props.userState.profileInfo.roleId,
      props.userState.profileInfo.channelid
    );
    LoadBreadCrumbs();
    setFormDataState(formData);
    props.getPartnerDetails()
  }, []);

  useEffect(()=>{
    if(timeOutMsg){
      setTimeout(() => {
        history.push("/app/home")
      }, 3000)
    }
  }, [timeOutMsg])

  return (
    <div>
      <IdleTimer
        ref={(ref) => {
          idleTimer.current = ref;
        }}
        timeout={1000 * 60 * 1}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        onAction={handleOnAction}
        debounce={250}
      />
      <Snackbar open={timeOutMsg} autoHideDuration={3000}>
        <Alert severity="error">
          Your session has been expired! You will be redirected to homepage in 3
          seconds.
        </Alert>
      </Snackbar>

      <div className="mainContainer createActivity">
        <Breadcrumb></Breadcrumb>
        <span className="formInfo">
          {"Please fill in the activity information"}
        </span>
        <div className="formBox">
          <Dynamicform
            key={current.id}
            className="form"
            onRef={(ref) => {
              dyFormRef.current = ref;
            }}
            title={createActivityTitle}
            defaultValues={current}
            model={formDataState}
            onSubmit={(model) => {
              onSubmit(model);
            }}
            showAstersikOnRequiredFields={true}
            customValidators={customValidators}
            extErrors={extErrors}
            onChange={(event, key, model) => onChangeHandler(event, key, model)}
            extaraActionFormButton={
              <>
                <a
                  className="savedraftLink"
                  onClick={(e) => handleSaveAsDraft(e)}
                  href="#"
                >
                  SAVE AS DRAFT
                </a>

                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={onCancelHandler}
                >
                  {"Cancel"}
                </button>
              </>
            }
          />
        </div>
        <SaveAsDraftDrawer
          isActiveMobileDraft={isActiveMobileDraft}
          toggleDrawer={toggleDrawer}
          onDontSaveHandler={onDontSaveDraftHandler}
          onSaveDraftHandler={onSaveDraftHandler}
        ></SaveAsDraftDrawer>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});
const mapDispatchToProps = (dispatch) => ({
  addDynamicData: (payload) => dispatch(addDynamicData(payload)),
  activateErrorMessageBar: (payload) => dispatch(activateErrorMessageBar(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  activateSuccessMessageBar: (payload) => dispatch(activateSuccessMessageBar(payload)),
  finishDayActivityCreate: (payload) => dispatch(finishDayActivityCreate(payload)),
  validateActivity: (payload) => dispatch(validateActivity(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  getPartnerDetails: (payload) => dispatch(getPartnerDetails(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(CreateActivityBroca)
);
