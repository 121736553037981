import { combineReducers } from "redux";
import userReducer from './userReducer';
import updateActivityReducer from "./updateActivityReducer";
import createActivityReducer from "./createActivityReducer";
import commonReducer from "./commonReducer";
import createLeadReducer from "../../redux-reducer/reducer/createLeadReducer";
import updateLeadReducer  from "../../redux-reducer/reducer/updateLeadReducer";
import leadFormReducer from "../../redux-reducer/reducer/leadFormReducer";
import leadListReducer from "../../redux-reducer/reducer/leadListReducer";
import persistancyLeadListReducer from "../../redux-reducer/reducer/persistancyLeadReducer";
import campaignLeadListReducer from "../../redux-reducer/reducer/campaignLeadReducer";
import pehlaKadamReducer from "../../redux-reducer/reducer/pehlaKadamReducer";
import storage from "redux-persist/lib/storage";
import calendarActivityReducer from "./calendarActivityReducer";
import updateSalesManagementActivityReducer from "./updateSalesManagementActivityReducer"

const appReducer = combineReducers({
    userReducer: userReducer,
    createActivityReducer: createActivityReducer,
    updateActivityReducer: updateActivityReducer,
    commonReducer: commonReducer,
    createLeadReducer: createLeadReducer,
    updateLeadReducer: updateLeadReducer,
    leadFormReducer: leadFormReducer,
    leadListReducer: leadListReducer,
    persistancyLeadListReducer:persistancyLeadListReducer,
    campaignLeadListReducer: campaignLeadListReducer,
    calendarActivityReducer:calendarActivityReducer,
    updateSalesManagementActivityReducer:updateSalesManagementActivityReducer,
    pehlaKadamReducer:pehlaKadamReducer
})
const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'USER_LOGGED_OUT') {
        storage.removeItem('persist:root')
        state = undefined;
        window.location.reload(true)
    }
    return appReducer(state, action);
};
export default rootReducer;
