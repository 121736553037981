import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import moment from 'moment';
import TravelHistory from '../common/travelHistory';

const PremiumCollectedStatusHistory = (props) => {
    const { history, styletimeline } = props
    const remarks = history.Remarks ? history.Remarks : 'N/A'
    const FundAccountDate = history.FundAccountDate ? moment(history.FundAccountDate).format('DD MMM YYYY') : ''
    const FundAccountDateTime = history.FundAccountDate ? moment(history.FundAccountDate).format('h:mma') : ""
    const ChequePaymentDate = history.ChequePaymentDate ? moment(history.ChequePaymentDate).format('DD MMM YYYY') : ''
    const ChequePaymentDateTime = history.ChequePaymentDate ? moment(history.ChequePaymentDate).format('h:mma') : ""

    return <>
        {history.LeadStatus === "Premium Collected" ?
            <>
                <TimelineItem sx={styletimeline}>
                    <TimelineSeparator>
                        <span className="separator">-</span>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <label>Payment Method</label>
                        <label>{history.Paymentname ? history.Paymentname : "N/A"}</label>
                    </TimelineContent>
                </TimelineItem>
                {history.Paymentname === "ECS" && <>
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Fund/Cheque</label>
                            <label>{history.PaymentSubTypeName}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label> {history.PaymentSubType === 1 ? "Fund Account Date" : "Cheque Payment Date"}</label>
                            <label>
                                {history.PaymentSubType === 1 ? <>{FundAccountDate}{' '} {FundAccountDateTime}</> : <>{ChequePaymentDate}{' '} {ChequePaymentDateTime}</>}
                            </label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Upload DGH Photo Copy</label>
                            <label>
                                {history.UploadDGHPhoto}
                            </label>
                        </TimelineContent>
                    </TimelineItem>

                </>}

                 {history.Paymentname === "Non-ECS" && <>
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Cheque Payment Date </label>
                            <label>{ChequePaymentDate} {""}{ChequePaymentDateTime}</label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Cheque No</label>
                            <label>
                                {history.ChequeNumber}
                            </label>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Upload DGH Photo Copy</label>
                            <label>
                                {history.UploadDGHPhoto}
                            </label>
                        </TimelineContent>
                    </TimelineItem>

                </>}

                {history.Paymentname === "ALREADY_PAID" && <>
                    <TimelineItem sx={styletimeline}>
                        <TimelineSeparator>
                            <span className="separator">-</span>
                        </TimelineSeparator>
                        <TimelineContent>
                            <label>Remarks </label>
                            <label>{remarks}</label>
                        </TimelineContent>
                    </TimelineItem>
                </>}
                {history.IsClaimRequired &&
                    <TravelHistory history={history} styletimeline={styletimeline} />
                }
            </>
            : ''}
    </>
}

export default PremiumCollectedStatusHistory
