import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import { getDateDuration, addMinuteToDate } from "../../../../utils/utils";
import { getOptionById } from "../helper";
import { useSelector } from "react-redux";
import {
  BDF,
  BRANCH_TRAINING,
  BRANCH_VISIT,
  HNI_MEET,
  ICE_E_SAMPARK,
  MONTHLY_REVIEW,
  TRAINING,
  MEETING_VISIT,
  CALLING_TO_VLE_RAP_DM,
  NEW_BUSINESS,
  NEW_RECRUITMENT_ACTIVITY,
  REVIEW_MEETING,
  JOINT_SALES_CALL_WITH_SRM,
} from "../../../../utils/config";

const CampaignActivitiesDetailsCard = (props) => {
  const commonState = useSelector((state) => state);
  const activityTypes = commonState.commonReducer.Activities;
  const SRM_LIST = commonState.commonReducer.SRMList;
  //const ZMA_LIST = commonState.commonReducer.ZMAList;
  const advisorList = commonState.commonReducer.AdvisorList;
  const PremierZMAList = commonState.commonReducer.PremierZMA;
  const visitToList = commonState.commonReducer.VisitTo;
  const recruitmentForList = commonState.commonReducer.RecruitmentFor;
  const EAPListForDetailCard = commonState.commonReducer.EAPList;
  // const Branches_List = commonState.commonReducer.Branches;

  // const acitivityDoneWithMasterData = useSelector(
  //   (state) => state.commonReducer.ActivityDoneWith
  // );

  const { activityData, classes, Item } = props;
  const activityOption = getOptionById(
    activityData.ActivityID ? activityData.ActivityID.toString() : "",
    activityTypes
  );

  //ZMA List
  // const zmaArr = activityData.ZMAID?.split(",");
  // const ZMA_LIST_NAME = [];
  // ZMA_LIST?.forEach((el1) => {
  //   zmaArr?.forEach((i) => {
  //     if (el1.UserID == i) {
  //       ZMA_LIST_NAME.push(el.Name+" ("+el1.Code+")");
  //     }
  //   });
  // });

  const SRMArr = activityData.SRMID
  const SRM_LIST_NAME = SRM_LIST?.filter(ele => ele.value == SRMArr).map((el2)=>{
    return el2.label+" ("+el2.key+")"
 })

  //const BranchId= activityData?.BranchId
//   const BRANCH_LIST_NAME =Branches_List?.filter(ele => ele.value == BranchId).map((el3)=>{
//     return el3.label+" ("+el3.key+")"
//  })


  const EAPArr = activityData.EAPIDs?.split(",")
  const EAP_LIST_NAME = EAPListForDetailCard?.filter(ele => EAPArr?.includes(ele.UserID.toString())).map((el4)=>{
    return el4.Name+" ("+el4.Code+")"
 })
  //Advoiser List

  const AdvisorIDArr = activityData.AdvisorID?.split(",");
  const Advisor_LIST_NAME = advisorList?.filter(ele => AdvisorIDArr?.includes(ele.UserID.toString())).map((el5)=>{
    return el5.Name+" ("+el5.Code+")"
 })

  //PremierZMAList
//  if (PremierZMAList && PremierZMAList.length > 0) {
    const PremierZMAListArr = activityData.PremierZMAID?.split(",");
    const PremierZMAList_NAME = PremierZMAList?.filter(ele => PremierZMAListArr?.includes(ele.UserID.toString())).map((el6)=>{
      return el6.Name+" ("+el6.Code+")"
   })
//  if (PremierZMAList && PremierZM
  //}

//   const getActivityDoneWith = (ActivityDoneWith) => {
//     const activityDoneWithData = [...acitivityDoneWithMasterData];
//     return activityDoneWithData.find((item) => item.value === ActivityDoneWith)[
//       "key"
//     ];
//   };
// const getActivityDone =async()=>{
//   const result = await getMasterDataForAdmin();
//   console.log("result from getActvityDone",result);

// }

  let getRecruitmentFor =  recruitmentForList && recruitmentForList.filter(el7=>el7.value == activityData.RecruitmentFor)
  
  //const EAPName = EAPListForDetailCard && EAPListForDetailCard.filter(el8=>el8.UserID == activityData.EAPIDs)
  

  const visitToName = visitToList && visitToList?.filter(el9=>el9.value == activityData?.VisitToID)
  //const premierZmaName = PremierZMAList && PremierZMAList?.filter(el10=>el10.value == activityData?.Premierzmaid)
  return (
    <Paper className={classes.paper2} sx={{ mb: 2 }}>
      <Grid className="activityDetailsSec" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Item className="activityDetailsHead">
                <b>Activity Details</b>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="listItemTextDiv" item xs={12} md={12}>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Activity Type</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.ActivityIdName}
                </Grid>
              </Grid>
            </ListItemText>

            <Divider />
            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Activity Name</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.ActivityName}
                </Grid>
              </Grid>
            </ListItemText>
            <Divider />

            {/* {activityData?.BranchName && <><ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Branch Name</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                {BRANCH_LIST_NAME?.length>0?BRANCH_LIST_NAME.toString():activityData.BranchName}
                </Grid>
              </Grid>
            </ListItemText>

            <Divider />
            </>
            } */}

            {activityData?.BankRegionName && <><ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Bank Region Name</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.BankRegionName}
                </Grid>
              </Grid>
            </ListItemText>

            <Divider />
            </>
            }

            {activityData?.TrainingType && <><ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Type Of Training</label>
                </Grid>
                <Grid item xs={7} md={7} className="lastitem">
                  {activityData.TrainingType}
                </Grid>
              </Grid>
            </ListItemText>

            <Divider />
            </>
            }

            {activityOption && activityOption.key === ICE_E_SAMPARK ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Sub Activity Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ActivitySubTypeName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}

            {activityOption &&
            activityOption.key === JOINT_SALES_CALL_WITH_SRM ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    {/* <Grid item xs={5} md={5}>
                      <label>Bank Branch</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {BRANCH_LIST_NAME?.length>0?BRANCH_LIST_NAME.toString():activityData.BranchName}
                    </Grid> */}
                    <Grid item xs={5} md={5}>
                      <label>Select  SRM</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                             {SRM_LIST_NAME?.length > 0
                              ? SRM_LIST_NAME.toString()
                              :  SRM_LIST.filter(
                                (ele) => activityData.SRMID == ele.value
                              )}
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <label>Meeting Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.FpmMeetingTypeName}
                    </Grid>
                    <Grid item xs={5} md={5}>
                      <label>Remark</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.Remarks}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}

            {activityOption &&
            activityOption.key === TRAINING &&
            activityOption.value === "7" ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training For</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.TrainingForName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Review with</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ReviewWithName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MEETING_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Meeting with</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.MeetingWithName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MEETING_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>RAP/VLE</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.RAP_VLE}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === NEW_BUSINESS ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Activity done with </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ActivityDoneWith}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                {activityData.AdvisorID !== " " &&
              
                  activityData.ActivityDoneWith == "1" && (
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={5} md={5}>
                          <label>Advisor Name </label>
                        </Grid>
                        <Grid item xs={7} md={7} className="lastitem">
                          {Advisor_LIST_NAME?.length > 0
                            ? Advisor_LIST_NAME.toString()
                            : activityData.AdvisorName}
                        </Grid>
                      </Grid>
                    </ListItemText>
                  )}

                <Divider />
                {activityData.ZMAID &&
                  !activityData.AdvisorID &&
                  activityData.ActivityDoneWith === "2" && (
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={5} md={5}>
                          <label>ZMA Name </label>
                        </Grid>
                        <Grid item xs={7} md={7} className="lastitem">
                          {/* {ZMA_LIST_NAME?.length > 0
                            ? ZMA_LIST_NAME.toString()
                            : activityData.ZMAName} */}
                            {activityData.ZMAName}
                        </Grid>
                      </Grid>
                    </ListItemText>

                  )}
                <Divider />
                {activityData.ActivityDoneWith === "3" && (
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={5} md={5}>
                        <label>Premier ZMA</label>
                      </Grid>
                      <Grid item xs={7} md={7} className="lastitem">
                      
                        {PremierZMAList_NAME?.length > 0
                          ? PremierZMAList_NAME.toString()
                          : activityData.PremierName}
                      </Grid>
                    </Grid>
                  </ListItemText>
                )}
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
            activityOption.key === NEW_RECRUITMENT_ACTIVITY ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Recruitment For</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {recruitmentForList.length!== 0 ?
                    getRecruitmentFor[0].label : " "
                    }
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              {recruitmentForList.length!== 0 && getRecruitmentFor[0].label=="Advisor for ZMA"&& <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>ZMA Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {/* {ZMA_LIST_NAME?.length > 0	
                            ? ZMA_LIST_NAME.toString()	
                            : activityData.ZMAName} */}
                            {activityData.ZMAName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
            (activityOption.key === NEW_BUSINESS ||
              activityOption.key === NEW_RECRUITMENT_ACTIVITY) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of expected leads generated</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ExpectedLead}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of expected leads converted</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ExpectedConversion}
                    </Grid>
                  </Grid>
                </ListItemText>
                {/* <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Ticket Size</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.TicketSize}
                    </Grid>
                  </Grid>
                </ListItemText> */}

                <Divider />
              </>
            ) : (
              ""
            )}

                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of expected leads generated</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ExpectedLeads}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of expected leads converted</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.ExpectedConversion}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Campaign Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.CampaignName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Remarks</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.Remarks}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Ticket Size</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.TicketSize}
                    </Grid>
                  </Grid>
                </ListItemText>

                
            {activityOption &&
            activityOption.key === TRAINING &&
            activityOption.value === "12" ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training to ZMAs </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {/* {ZMA_LIST_NAME?.length > 0	
                            ? ZMA_LIST_NAME.toString()	
                            : activityData.ZMAName} */}
                            {activityData.ZMAName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training to Advisors</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {Advisor_LIST_NAME?.length > 0	
                            ? Advisor_LIST_NAME.toString()	
                            : activityData.AdvisorName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}

            {(activityOption && activityOption.key === BRANCH_TRAINING) ||
            (activityOption && activityOption.key === TRAINING) ||
            (activityOption && activityOption.key === MONTHLY_REVIEW) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Training Topic</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.TrainingTopic}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === REVIEW_MEETING ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Visit To</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {visitToName.length>0?visitToName[0].label:""}
                    </Grid>
                  </Grid>
                </ListItemText>
                {activityData.PremierZMAID && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Premier ZMA</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {PremierZMAList_NAME?.length > 0	
                            ? PremierZMAList_NAME.toString()	
                            : activityData.PremierName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
                {activityData.ZMAID && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>ZMA </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {/* {ZMA_LIST_NAME?.length > 0	
                            ? ZMA_LIST_NAME.toString()	
                            : activityData.ZMAName} */}
                            {activityData.ZMAName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
                {activityData.AdvisorID &&<ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Advisor Name </label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {Advisor_LIST_NAME?.length > 0
                            ? Advisor_LIST_NAME.toString()
                            : activityData.AdvisorName}
                    </Grid>
                  </Grid>
                </ListItemText>}

                <Divider />

                {activityData.Premierzmaid && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Premier ZMA</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {PremierZMAList_NAME?.length > 0
                          ? PremierZMAList_NAME.toString()
                          : activityData.PremierName}
                        
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
                {activityData.EAPIDs && <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>EAP</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                    {EAP_LIST_NAME?.length>0?EAP_LIST_NAME.toString():activityData.EAPName}
                    </Grid>
                  </Grid>
                </ListItemText>}
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
            (activityOption.key === BRANCH_VISIT ||
              activityOption.key === HNI_MEET ||
              activityOption.key === ICE_E_SAMPARK ||
              activityOption.key === BDF ||
              activityOption.key === BRANCH_TRAINING) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Branch Name & Code</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.BranchName ?  `${activityData.BranchName} - ${activityData.branchCode}` : "NA"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === CALLING_TO_VLE_RAP_DM ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No of Calls Planned</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NoOfCallsPlanned}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
            (activityOption.key === BRANCH_VISIT ||
              activityOption.key === HNI_MEET ||
              activityOption.key === ICE_E_SAMPARK ||
              activityOption.key === BDF) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label># of Expected Leads</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.ExpectedLead ? activityData.ExpectedLead : "0"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label># of Expected Conversion</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.ExpectedConversion ? activityData.ExpectedConversion : "0"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Average Ticket Size:</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData?.TicketSize ?
                        `₹ ${activityData.TicketSize}` : "0"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}

            <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
              <Grid container spacing={4}>
                <Grid item xs={5} md={5}>
                  <label>Meeting Date & Time</label>
                </Grid>
                {activityData.MeetingStartDateTime && (
                  <Grid item xs={7} md={7} className="lastitem">
                    <label className="date">
                      {activityData.MeetingStartDateTime
                        ? moment(activityData.MeetingStartDateTime).format(
                            "DD MMM YYYY"
                          )
                        : ""}
                    </label>
                    <br />
                    <label className="time">
                      {activityData.MeetingStartDateTime
                        ? moment(activityData.MeetingStartDateTime).format(
                            "h:mma"
                          )
                        : ""}
                      -
                      {activityData.MeetingEndDateTime
                        ? moment(
                            addMinuteToDate(activityData.MeetingEndDateTime)
                          ).format("h:mma")
                        : ""}
                    </label>
                    <label className="duration">
                      (
                      {getDateDuration(
                        activityData.MeetingStartDateTime,
                        addMinuteToDate(activityData.MeetingEndDateTime),
                        "HOUR"
                      )}
                      {getDateDuration(
                        activityData.MeetingStartDateTime,
                        addMinuteToDate(activityData.MeetingEndDateTime),
                        "MINUTE"
                      )}
                      )
                    </label>
                  </Grid>
                )}
                {activityData.MeetingStartDateTime == null && (
                  <Grid item xs={7} md={7} className="lastitem">
                    <b>NA</b>
                  </Grid>
                )}
              </Grid>
            </ListItemText>
            <Divider />
            {commonState.userReducer.profileInfo.channelid === 13 &&
            activityOption &&
            activityOption?.key === BRANCH_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.GCLActivityName
                        ? activityData.GCLActivityName
                        : "Not available"}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption && activityOption.key === TRAINING) ||
            (activityOption && activityOption.key === MONTHLY_REVIEW) ||
            (activityOption && activityOption.key === MEETING_VISIT) ||
            (activityOption && activityOption.key === NEW_BUSINESS) ||
            activityOption.key === NEW_RECRUITMENT_ACTIVITY ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Location</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.Location}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption &&
            activityOption.key === TRAINING &&
            activityOption.value === "7" ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Planned for no. of people</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.PlannedForNoOfPeople}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MEETING_VISIT ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Meeting Type</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.MeetingTypeName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No. of Policies planned</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NoOfPoliciesPlanned}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Average Ticket Size</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.AverageTicketSize &&
                        `₹ ${activityData.AverageTicketSize}`}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {activityOption && activityOption.key === MONTHLY_REVIEW ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>No. of RAP activation planned</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.NoOfRAPActivationPlanned}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption && activityOption.key === MEETING_VISIT) ||
            activityOption.key === REVIEW_MEETING ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Remarks</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.Remarks}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption &&
              activityOption.key === TRAINING &&
              activityOption.value === "7") ||
            (activityOption && activityOption.key === MONTHLY_REVIEW) ||
            (activityOption && activityOption.key === MEETING_VISIT) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>State Name</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.StateName}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
            {(activityOption && activityOption.key === MONTHLY_REVIEW) ||
            (activityOption && activityOption.key === MEETING_VISIT) ||
            (activityOption.key === TRAINING &&
              activityOption.value === "7") ? (
              <>
                {activityData.DistrictName !== null && (
                  <>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: 12, ml: 2 }}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={5} md={5}>
                          <label>District Name</label>
                        </Grid>
                        <Grid item xs={7} md={7} className="lastitem">
                          {activityData.DistrictName}
                        </Grid>
                      </Grid>
                    </ListItemText>
                    <Divider />
                  </>
                )}
              </>
            ) : (
              ""
            )}
            {(activityOption &&
              activityOption.key === TRAINING &&
              activityOption.value === "7") ||
            (activityOption && activityOption.key === MONTHLY_REVIEW) ||
            (activityOption && activityOption.key === MEETING_VISIT) ? (
              <>
                <ListItemText primaryTypographyProps={{ fontSize: 12, ml: 2 }}>
                  <Grid container spacing={4}>
                    <Grid item xs={5} md={5}>
                      <label>Address</label>
                    </Grid>
                    <Grid item xs={7} md={7} className="lastitem">
                      {activityData.Address}
                    </Grid>
                  </Grid>
                </ListItemText>
                <Divider />
              </>
            ) : (
              ""
            )}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CampaignActivitiesDetailsCard;
