import React, { useEffect, useState } from "react";
import DynamicForm from "f1-dynamic-form-react";
import { t } from "i18next";
import { reAssignLeadForm } from "./reAssignLeadFilterForm";
import { useSelector, useDispatch } from "react-redux";
import { getBDMByBranchID, ReAssignLeadToBDM } from "../../../redux-reducer/reducer";
import { useHistory } from "react-router-dom";

const ReAssignLead = ({ handleClose }) => {
  const dynamicRef = React.useRef();
  const dispatch = useDispatch();
  const select =  useSelector((next) => next);
  const [state, setState] = useState({
    current: { id: 1 },
    formData: reAssignLeadForm,
    // autoCompleteItems:{
    //   BDM:[

    //   ]
    // }
  });
  const history = useHistory();
  const [initialState, setInitialState] = useState({
    BranchID: "",
    BDM: "",
  });
  const [extErrors, setExtErrors] = useState({

  })
  const [autoCompleteItems, setAutoCompleteItems] = useState({
    BDM: [],
  });

  const { Branches } = select.commonReducer
  const { selectedLeadId} = select.leadListReducer
  const userState = select.userReducer
  const UserId = userState?.profileInfo?.userId

  const getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr?.map((option) => {
        return {
          key: option[key],
          label: `${option[label]}`,
          value: option[value],
        };
      });
    }
    return arr;
  };

  useEffect(() => {
    reAssignLeadForm[1].hide = true
  }, [])

  const onChangeHandler = async (event, key) => {
    if (key === "BranchID") {
      const List = dispatch(getBDMByBranchID(UserId, event.target.value));
      List.then((res) => {
        reAssignLeadForm[1].hide = false
        const bdmListArray = getDropdownData(res, 'userID', 'userName', 'userID')
        setAutoCompleteItems({
          ...autoCompleteItems,
          BDM: bdmListArray,
        });
      });
      setInitialState(
        {
          ...initialState,
          BranchID: event.target.value
        }
      )
      setExtErrors({})
    }
    if (key === "BDM") {
      setInitialState(
        {
          ...initialState,
          BDM: event.target.value
        }
      )
    }
  };

  const onSubmitHandler = (model) => {
    const isValidBDM = autoCompleteItems.BDM && autoCompleteItems.BDM.length > 0 && autoCompleteItems.BDM.some(ele => ele.label === model.BDMlabel)
    if (model.BranchID === "") {
      return false
    }
    if (!isValidBDM) {
      setExtErrors({
        'BDM': 'Please Enter a Valid Input'
      })
      return false
    }
    setExtErrors({})
    const bdmID = autoCompleteItems.BDM && autoCompleteItems.BDM.length > 0 && autoCompleteItems.BDM.find((ele) => ele.label === model.BDMlabel)
    const payload = {
      LeadID: selectedLeadId.toString(),
      UserId: bdmID.value,
      LeadType: 'Lead',
      BDMName: model.BDMlabel,
      history: history
    }

    dispatch(ReAssignLeadToBDM(payload))

  };

  const onCancelHandler = () => {
    history.goBack()
  };

  const onAutocompleteSelectHandler = () => { };

  const initFormData = (formFields) => {
    const BranchesCopy = [...Branches];
    // BranchesCopy.unshift({ "key": "select", "label": "select", "value": "" })
    const formData = [...formFields];
    formData[0].options = BranchesCopy;
    return formData;
  };

  useEffect(() => {
    const formData = initFormData(state.formData);
    setState({ ...state, formData: formData });
  }, []);

  return (
    <>
      <div className="formBox">
        <div className="mainContainer reAssignLeadDiv">
          <DynamicForm
            key={1}
            ref={dynamicRef}
            className="dynamicForm leadFilters  leadFiltersMobile"
            title={" "}
            buttonSubmit={t("Assign Leads")}
            defaultValues={initialState}
            model={state.formData}
            onSubmit={(model) => onSubmitHandler(model)}
            disableSubmitOnInValid={false}
            design="bootstrap"
            materialOutline={false}
            onAutocompleteSelect={onAutocompleteSelectHandler}
            autoCompleteItems={autoCompleteItems}
            onChange={(event, key) => onChangeHandler(event, key)}
            extErrors={extErrors}
            // buttonNormalClick={(id, key, obj) => console.log(id, key, obj)}
            showAstersikOnRequiredFields={false}
            extaraActionFormButton={
              <button
                color="indigo"
                className="btn cancelBtn"
                key="extra"
                id="extra"
                type="button"
                onClick={(e) => onCancelHandler()}
              >
                Cancel
              </button>
            }
          />
        </div>
      </div>
    </>
  );
};
export default ReAssignLead;
