import React, { useEffect, useState } from 'react';
import { getallnotificationsbyuser, getMasterDataForNotification, readallnotificationsbyuser, readsinglenotificationsbyuser } from '../../redux/actions/notificationAction'
import BoltIcon from '@mui/icons-material/Bolt';
import EditIcon from '@mui/icons-material/Edit';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Grid from '@mui/material/Grid';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import "./notification.scss";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { readAllNotification, activateLoader, deActivateLoader } from '../../redux/actions/common';
import CallIcon from '@mui/icons-material/Call';
import { useDispatch } from 'react-redux';

const AllNotification = () => {
  const dispatch = useDispatch()
  const commonState = useSelector(state => state);
  const userState = commonState.userReducer
  const [UserId] = useState(userState?.profileInfo?.userId)
  const [RoleId] = useState(userState?.profileInfo?.roleId)

  const history = useHistory()
  const [notificationData, setNotificationData] = useState([])
  const [notificationTypes, setNotificationTypes] = useState([])
  const getNotificationTypes = async () => {
    const paylod = {
      tableName: "Notificationcategory"
    }

    const resposne = await dispatch(getMasterDataForNotification(paylod))
    setNotificationTypes(resposne.data)
    console.log("getNotificationTypes", resposne)
  }

  const categoryID = [16, 17, 18, 19, 20, 21, 32, 33, 34];
  let NotificationMessage;
  const getAllNotificationsByUser = async () => {
    dispatch(activateLoader())
    const res = await dispatch(getallnotificationsbyuser(UserId))
    if (res.status === 200) {
      dispatch(deActivateLoader())
      console.log("getAllNotificationsByUser", res.data.PushNotifications)
      let da = res.data?.PushNotifications;
      let details = []
      da.forEach((data) => {
        details.push({
          ...data, isShowMore: data.NotificationMessage.length > 67 ? false : true
        })
      })
      setNotificationData(details)
    }
    else {
      dispatch(deActivateLoader())
    }

  }

  const handleAudioCall = (e, MobileNo) => {
    e.stopPropagation()
    window.open(`tel:${MobileNo}`)
  }
  const getType = (el) => {
    switch (el.LeadtypeID) {
      case 1:
        history.push(`/app/lead/detail/${el.LeadId}`);
        break;
      case 2:
        history.push(`/app/persistencyLead/detail/${el.LeadId}`);
        break;
      case 3:
        history.push(`/app/campaignLead/detail/${el.LeadId}`);
        break;
      default:
        break;
    }
  }

  const getLeadId=(input)=>{
      const regex = /Lead ID (\d+)/;
       const matches = input.match(regex); 
       if (matches && matches.length > 1) {
       return matches[1];
       } else { return null;} 
  }

  const redirectToDetails = async (el) => {
    let BDM;
    switch (el.CategoryID) {
      case 1:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 2:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 3:
        history.push("/app/calendar");
        break;
      case 4:
        getType(el)
        break;
      case 5:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 6:
        getType(el)
        break;
      case 7:
        getType(el)
        break;
      case 8:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 9:
        history.push(`/app/activity/detail/${el.TrnActivityID}`);
        break;
      case 10:
        if (el.TrnActivityID) {
          history.push(`/app/activity/detail/${el.TrnActivityID}`);
        } else {
          switch (el.LeadtypeID) {
            case 1:
              history.push(`/app/lead/detail/${el.LeadId}`);
              break;
            case 2:
              history.push(`/app/persistencyLead/detail/${el.LeadId}`);
              break;
            case 3:
              history.push(`/app/campaignLead/detail/${el.LeadId}`);
              break;
            default:
              break;
          }
        }
        break;
      case 11:
      case 12:
      case 27:
        if (el.TrnActivityID) {
          history.push(`/app/salesmanagement/detail/${el.TrnActivityID}`);
        }
        break;
      case 13:
        history.push(`/app/pehlaKadam/checklistDetail/10`);
        break;
      case 14:
        history.push(`/app/pehlaKadam/checklistDetail/30`);
        break;
      case 15:
        history.push(`/app/pehlaKadam/checklistDetail/60`);
        break;
      case 16:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/question/${BDM[1].trim()}/10/Pending`);
        break;
      case 17:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/question/${BDM[1].trim()}/30/Pending`);
        break;
      case 18:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/question/${BDM[1].trim()}/60/Pending`);
        break;
      case 19:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/checklistDetail/10/${parseInt(BDM[1].trim())}`);
        break;
      case 20:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/checklistDetail/30/${parseInt(BDM[1].trim())}`);
        break;
      case 21:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/checklistDetail/60/${parseInt(BDM[1].trim())}`);
        break;
      case 30:
        history.push(`/app/activity/campaigndetails/${el.TrnActivityID}`);
        break;
      case 31:
        history.push(`/app/activity/campaigndetails/${el.TrnActivityID}`);
        break;
      case 32:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/checklistDetail/10/${parseInt(BDM[2].trim())}`);
        break;
      case 33:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/checklistDetail/30/${parseInt(BDM[2].trim())}`);
        break;
      case 34:
        BDM = el.NotificationMessage.split('-');
        history.push(`/app/pehlakadam/checklistDetail/60/${parseInt(BDM[2].trim())}`);
        break;
      case 36:
        let leadId = el.NotificationMessage.split(' ');
        if (RoleId === "BDM") {
          switch (el.LeadtypeID) {
            case 1:
              history.push(`/app/lead/detail/${leadId[2]}`);
              break;
            case 2:
              // history.push(`/app/persistencyLead/detail/${el.LeadId}`);
              break;
            case 3:
              history.push(`/app/campaignLead/detail/${leadId[2]}`);
              break;
            default:
              break;
          }

        }
        break;
        case 37:
        case 38:
          BDM = getLeadId (el.NotificationMessage)
          history.push(`/app/persistencyLead/detail/${parseInt(BDM)}`);
      default:
        break;
    }
    await dispatch(readsinglenotificationsbyuser(el.NotificationID))
    dispatch(readAllNotification(true))
    //   window.location.reload()
    //toggleNotificationDrawer()
  }

  const readAll = async () => {
    await dispatch(readallnotificationsbyuser(UserId))
    dispatch(readAllNotification(true))
  }

  const timeConvert = (inputDateTime) => {
    var now = moment();
    var displayValue = '';
    var dateString = inputDateTime;
    var targetDate = moment(dateString);
    var difference = now.diff(targetDate, 'days');

    if (difference > 6000 || difference < -3) {
      displayValue = targetDate.format('MM-DD-YYYY') + ' at ' + targetDate.format('hh:mm');
    } else {
      displayValue = targetDate.fromNow();
    }
    return displayValue;
  }

  useEffect(() => {
    getAllNotificationsByUser()
    getNotificationTypes()
  }, [])

  const handleSeeMore = (e, id) => {
    e.stopPropagation()
    let getData = notificationData[id]
    getData.isShowMore = !getData.isShowMore
    const data = [...notificationData]
    setNotificationData(data)
  }

  return (
    <div className="notificationPopupDiv notifiPage">
      <h3 className='title'>Notifications <span>Showing {notificationData?.length} Results <button className='readBtn' onClick={readAll}>Read All</button></span></h3>
      <div className="notifInner">
        {notificationData.map((el, index) => {
          if (categoryID.includes(el.CategoryID)) {
            let msg = el.NotificationMessage.split('-');
            msg.pop();
            NotificationMessage = msg.join("-")
          } else {
            NotificationMessage = el.NotificationMessage;
          }
          return <div key={index} >
            <div className={el.Isnotificationreadbyuser ? "readTxt listItem" : "unreadTxt listItem"}>
              <div onClick={() => { redirectToDetails(el) }} style={{ cursor: 'pointer' }}>
                <ArrowRightAltIcon className='arrowIcon' />
                {notificationTypes.map(notif => {
                  if (notif.CategoryID === el.CategoryID) {
                    return <h6 key={"de" + notif.CategoryID} style=
                      {{ color: el.Isnotificationreadbyuser ? "rgb(74 67 67)" : "#00427E" }} Isnotificationreadbyuser={el.Isnotificationreadbyuser}>
                      <BoltIcon />
                      {notif.CategoryName}
                    </h6>
                  } else {
                    return ""
                  }
                })}

                <div className="statusText">{el.isShowMore ? NotificationMessage : el.NotificationMessage.slice(0, 67)}
                  {!el.isShowMore && <span onClick={(e) => handleSeeMore(e, index)}>...see more</span>}
                </div>
                <div className="lastDate">
                  <span>{timeConvert(el.Notificationshownafter)}</span>
                  {/* { el.MeetingStartDateTime && el.MeetingEndDateTime &&<><b>Today</b>, {
                                        getDateDuration(el.MeetingStartDateTime, el.MeetingEndDateTime,'HOUR')+' '+
                                        getDateDuration(el.MeetingStartDateTime, el.MeetingEndDateTime,'MINUTE')}
                                        </>
                    } */}
                </div>
              </div>
              <Grid container justifyContent="center" alignItems="center" className="updateEdit" spacing={3}>


                {el.Mobileno !== null ? (
                  <Grid item xs={6}>
                    <span onClick={(e) => handleAudioCall(e, el.Mobileno)} style={{ cursor: 'pointer' }}>
                      <CallIcon />
                      Call
                    </span>
                  </Grid>
                ) : ""}

                <Grid item xs={6}>
                  <span onClick={() => { redirectToDetails(el) }}>
                    <EditIcon /> Details
                  </span>
                </Grid>
              </Grid>
            </div>

          </div>
        })}

      </div>

    </div>
  )
}

const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer
});

export default withTranslation('translation', { withRef: true })(connect(mapStateToProps)(AllNotification));
