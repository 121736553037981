import {
  CLAIM_TRAVEL_EXPENCE_OPTIONS,
  ACTIVITY_RESCHEDULED,
  ACTIVITY_CANCELLED,
  ACTIVITY_COMPLETED,
  CONFIGS,
  ROLE_BDM,
  ALLOWED_FUTURE_DAYS,
  JOINTCALL_COMPLETED,
  JOINT_CALL_DURATION,
  JOINTCALL_CANCELLED,
  JOINTCALL_RESCHEDULED,
  ROLE_FPM,
} from "../../../../utils/config";

import { format } from "date-fns";

import { getDateAfterDays, getDropdownDataBranch } from "../../../../utils/utils";

import { isAlphabetOnly, isBranchValid, isEmailValid, isMeetindDate, isPositiveNumber, numberStartWith6789Validator } from "../../../../utils/validators";
import { JOINTCALL_COMPLETED_FORM, JOINTCALL_COMPLETED_TRANSPORT_FORM, JOINTCALL_COMPLETED_TRAVEL_EXPENSE_FORM, JOINTCALL_COMPLETED_TYPE_OF_TRANSPORT, JOINT_CALL_LEAD_FORM } from "./fpmUpdateFormJson";
// import { BancaLeadForm } from "../../leads/createLeadForm";

const dateFormat = "yyyy-MM-dd";
const max100Charmessage = "Maximum 100 charactors allowed.";
const claimtravelExpenseLabel = "Do You Want to Claim Travel Expense?";
const optionSelectValidationMessage = "Please select one option.";
const cssBorder = "1px solid #ddd";
const cssactiveBoxShadow = "0px 0px 1px 2px #fffc35";
const modeOfTransportLabel = "Mode of Transport";
const travelExpenseLabel = "Travel Expense";
const travelExpenseValidationmessage = "Travel expense is required.";
const fourDigitAllowed = "Maximum 4 digits allowed";
const twoDigitAllowed = "Maximum 2 digits allowed";
const billPhoto = "Upload bill photo";
const uploadBill = "Please Upload Bill Copy";
const acceptFileType = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const vehicleType = "Vehicle Type";
const modeOftransportRequired = "mode of Trasport is required.";
export const ActivityRescheduledForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: "datetime-local",
    disabled: false,
    id: "MeetingStartDateTime",
    min: `${format(new Date(), dateFormat)}T${format(new Date(), "kk:mm")}`,
    max: `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
    label: "Reschedule Meeting Start Date & Time",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Meeting start time is required.",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: "datetime-local",
    disabled: false,
    id: "MeetingEndDateTime",
    min: `${format(new Date(), dateFormat)}T${format(new Date(), "kk:mm")}`,
    max: `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
    label: "Reschedule Meeting End Date & Time",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Meeting end time is required.",
      },
    ],
  },
  {
    key: "Reason",
    hide: false,
    type: "text",
    disabled: false,
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Reason is required.",
      },
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "IsClaimRequired",
    label: claimtravelExpenseLabel,
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: optionSelectValidationMessage,
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];
export const ActivityRescheduledFormPart1 = [
  {
    key: "TransportModeID",
    type: "radio",
    label: modeOfTransportLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Mode of transport is required.",
      },
    ],
    options: [],
  },
];

export const ActivityRescheduledFormPart2 = [
  {
    key: "TravelExpence",
    hide: false,
    props: { min: "1" },
    type: "number",
    disabled: false,
    id: "TravelExpence",
    label: travelExpenseLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: travelExpenseValidationmessage,
      },
      {
        name: "maxLength",
        value: 4,
        validationMessage: fourDigitAllowed,
      },
    ],
  },
  {
    key: "UploadBill",
    hide: false,
    type: "file",
    dropTitle: billPhoto,
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: uploadBill,
    "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
    validators: [],
  },
];
export const ActivityRescheduledFormPart3 = [
  {
    key: "VehicleTypeID",
    hide: false,
    type: "radio",
    disabled: false,
    id: "VehicleType",
    label: vehicleType,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: modeOftransportRequired,
      },
    ],
    options: [],
  },
];

export const JointSalesCallActivityRescheduledForm = [
  {
    key: "MeetingStartDateTime",
    hide: false,
    type: "datetime-local",
    disabled: false,
    id: "MeetingStartDateTime",
    min: `${format(new Date(), dateFormat)}T${format(new Date(), "kk:mm")}`,
    max: `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
    label: "Reschedule Meeting Start Date & Time",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Meeting start time is required.",
      },
    ],
  },
  {
    key: "MeetingEndDateTime",
    hide: false,
    type: "datetime-local",
    disabled: false,
    id: "MeetingEndDateTime",
    min: `${format(new Date(), dateFormat)}T${format(new Date(), "kk:mm")}`,
    max: `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
    label: "Reschedule Meeting End Date & Time",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Meeting end time is required.",
      },
    ],
  },
  {
    key: "Reason",
    hide: false,
    type: "select",
    disabled: false,
    id: "Reason",
    label: "Reason",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Reason is required.",
      },
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
    options: []
  },
  {
    "key": "FpmMeetingTypeID", "hide": false, "type": "select", "disabled": false, "id": "FpmMeetingTypeID", "label": "Meeting Type",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Sub activity type is required." }], "options": []
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "IsClaimRequired",
    label: claimtravelExpenseLabel,
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: optionSelectValidationMessage,
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];
export const JointSalesCallActivityRescheduledFormPart1 = [
  {
    key: "TransportModeID",
    type: "radio",
    label: modeOfTransportLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Mode of transport is required.",
      },
    ],
    options: [],
  },
];

export const JointSalesCallActivityRescheduledFormPart2 = [
  {
    key: "TravelExpence",
    hide: false,
    props: { min: "1" },
    type: "number",
    disabled: false,
    id: "TravelExpence",
    label: travelExpenseLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: travelExpenseValidationmessage,
      },
      {
        name: "maxLength",
        value: 4,
        validationMessage: fourDigitAllowed,
      },
    ],
  },
  {
    key: "UploadBill",
    hide: false,
    type: "file",
    dropTitle: billPhoto,
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: uploadBill,
    validators: [],
  },
];
export const JointSalesCallActivityRescheduledFormPart3 = [
  {
    key: "VehicleTypeID",
    hide: false,
    type: "radio",
    disabled: false,
    id: "VehicleType",
    label: vehicleType,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: modeOftransportRequired,
      },
    ],
    options: [],
  },
];
//Activity Cancel JSON start
export const ActivityCancelledForm = [
  {
    key: "Reason",
    hide: false,
    type: "text",
    disabled: false,
    id: "Reason",
    label: "Reason for Cancellation",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Reason for cancellation is required.",
      },
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
  },
  //  { "key": "IsClaimRequired", "hide": false, "type": "select", "disabled":false, "id":"IsClaimRequired", "label": "Do You Want to Claim Travel Expense",
  //   "validators":[{"name":"required", "value":true, "validationMessage": optionSelectValidationMessage}], "options": []},

  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "IsClaimRequired",
    label: claimtravelExpenseLabel,
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: optionSelectValidationMessage,
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

export const JointCallCancelForm = [
  {
    key: "Reason",
    hide: false,
    type: "select",
    disabled: false,
    id: "Reason",
    label: "Reason for Cancellation",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Reason for cancellation is required.",
      },
      // { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
    options: []
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
  },
  //  { "key": "IsClaimRequired", "hide": false, "type": "select", "disabled":false, "id":"IsClaimRequired", "label": "Do You Want to Claim Travel Expense",
  //   "validators":[{"name":"required", "value":true, "validationMessage": optionSelectValidationMessage}], "options": []},

  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "IsClaimRequired",
    label: claimtravelExpenseLabel,
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: optionSelectValidationMessage,
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

export const ActivityCancelledFormPart1 = [
  {
    key: "TransportModeID",
    type: "radio",
    label: modeOfTransportLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Mode of transport is required.",
      },
    ],
    options: [],
  },
];

export const ActivityCancelledFormPart2 = [
  {
    key: "TravelExpence",
    hide: false,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "TravelExpence",
    label: travelExpenseLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: travelExpenseValidationmessage,
      },
      { name: "maxLength", value: 4, validationMessage: fourDigitAllowed },
    ],
  },
  {
    key: "UploadBill",
    hide: false,
    type: "file",
    dropTitle: billPhoto,
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: uploadBill,
    "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
    validators: [],
  },
];
export const ActivityCancelledFormPart3 = [
  {
    key: "VehicleTypeID",
    hide: false,
    type: "radio",
    disabled: false,
    id: "VehicleType",
    label: vehicleType,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: modeOftransportRequired,
      },
    ],
    options: [],
  },
];
//Activity Cancel JSON End

//Activity Complet JSON start
export const ActivityCompletedForm = [
  {
    key: "ExpectedPremium",
    //props: { min: "0" },
    hide: false,
    type: "number",
    disabled: false,
    id: "ExpectedPremium",
    label: "Expected Premium",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Expected Premium is required.",
      },
      {
        name: "maxLength",
        value: 8,
        validationMessage: "Maximum 8 digits allowed",
      },
    ],
  },
  {
    key: "NoOfLeads",
    hide: false,
    type: "number",
    props: { min: "0" },
    disabled: false,
    id: "NoOfLeads",
    label: "# of Leads",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "# of Leads is required.",
      },
      { name: "maxLength", value: 2, validationMessage: twoDigitAllowed },
    ],
  },
  {
    key: "Remarks",
    hide: false,
    type: "text",
    disabled: false,
    id: "Remarks",
    label: "Remarks",
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: "Remarks is required.",
      },
      { name: "maxLength", value: 100, validationMessage: max100Charmessage },
    ],
  },
  {
    key: "UploadTrainingAttendancePhoto3",
    hide: true,
    type: "file",
    dropTitle: "Photo Of The Activity",
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadTrainingAttendancePhoto3",
    label: "Photo Of The Activity",
    name: "UploadTrainingAttendancePhoto3",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Photo Of The Activity is required",
      },
    ],
  },
  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "IsClaimRequired",
    label: claimtravelExpenseLabel,
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: optionSelectValidationMessage,
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

export const ActivityBranchTrainingCompletedForm = [
  {
    key: "DateOfTraining",
    hide: false,
    type: "datetime-local",
    disabled: false,
    id: "DateOfTraining",
    min: `${format(new Date(), dateFormat)}T${format(new Date(), "kk:mm")}`,
    max: `${format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat)}T23:59`,
    label: "Date Of Training",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "Meeting start time is required.",
      },
    ],
  },
  {
    key: "UploadTrainingAttendanceDocument1",
    hide: false,
    type: "file",
    dropTitle: "Supporting Documents",
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadTrainingAttendanceDocument1",
    label: "Training attendance document",
    name: "UploadTrainingAttendanceDocument1",
    validators: [],
  },
  {
    key: "ActualNoOfTrainees",
    props: { min: "0" },
    hide: false,
    type: "number",
    disabled: false,
    id: "ActualNoOfTrainees",
    label: "No of participants",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "No Of Participants are required.",
      },
      {
        name: "maxLength",
        value: 5,
        validationMessage: "Maximum 5 digits allowed",
      },
    ],
  },
  {
    key: "UploadTrainingAttendancePhoto1",
    hide: true,
    type: "file",
    dropTitle: "Group Photo Of Training",
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadTrainingAttendancePhoto1",
    label: "Group Photo Of Training",
    name: "UploadTrainingAttendancePhoto1",
    validators: [],
  },

  {
    key: "IsClaimRequired",
    name: "IsClaimRequired",
    type: "CustomSwitch",
    disabled: false,
    id: "IsClaimRequired",
    label: claimtravelExpenseLabel,
    validators: [
      {
        name: "required",
        value: false,
        validationMessage: optionSelectValidationMessage,
      },
    ],
    handleDiameter: 45,
    offColor: "#08f",
    onColor: "#0ff",
    offHandleColor: "#0ff",
    onHandleColor: "#08f",
    className: "radioBoxDiv",
    height: 26,
    width: 75,
    borderRadius: 24,
    border: cssBorder,
    activeBoxShadow: cssactiveBoxShadow,
    uncheckedIcon: <div className="switchUnChecked">Yes</div>,
    checkedIcon: <div className="switchChecked">No</div>,
  },
];

export const ActivityCompletedFormPart1 = [
  {
    key: "TransportModeID",
    hide: false,
    type: "radio",
    disabled: false,
    id: "ModeOfTransport",
    label: modeOfTransportLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: modeOftransportRequired,
      },
    ],
    options: [],
  },
];

export const ActivityCompletedFormPart2 = [
  {
    key: "TravelExpence",
    hide: false,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "TravelExpence",
    label: travelExpenseLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: travelExpenseValidationmessage,
      },
      { name: "maxLength", value: 4, validationMessage: fourDigitAllowed },
    ],
  },

  {
    key: "UploadBill",
    hide: false,
    type: "file",
    dropTitle: billPhoto,
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: uploadBill,
    "AllowedFileTypesErrorMessage": "File type not allowed. Allowed file types are image/jpeg, image/jpg, image/png, application/pdf",
    validators: [],
  },
];

export const ActivityCompletedFormPart3 = [
  {
    key: "VehicleTypeID",
    hide: false,
    type: "radio",
    disabled: false,
    id: "VehicleType",
    label: vehicleType,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: modeOftransportRequired,
      },
    ],
    options: [],
  },
];

//Activity Complet JSON End



export class ActivityFormBuilder {
  constructor(actionType, activityTypeForupdate) {
    this.actionType = actionType;
    this.activityTypeForupdate = activityTypeForupdate;
  }

  getFormTitle = () => {
    let formTitle = "";
    switch (this.actionType) {
      case JOINTCALL_CANCELLED:
        formTitle = "Activity Cancelled"
        break
      case JOINTCALL_RESCHEDULED:
        formTitle = "Activity Rescheduled";
        break;
      case ACTIVITY_RESCHEDULED:
        formTitle = "Activity Rescheduled";
        break;
      case ACTIVITY_CANCELLED:
        formTitle = "Activity Cancelled";
        break;
      case ACTIVITY_COMPLETED:
        formTitle = "Activity Completed";
        break;
      default:
        formTitle = "Activity Completed";
        break;
    }
    return formTitle;
  };

  getForm = () => {
    let form = [];
    switch (this.actionType) {
      case JOINTCALL_CANCELLED:
        form = JointCallCancelForm
        break;
      case JOINTCALL_RESCHEDULED:
        form = JointSalesCallActivityRescheduledForm
        break
      case ACTIVITY_RESCHEDULED:
        form = ActivityRescheduledForm;
        break;
      case ACTIVITY_CANCELLED:
        form = ActivityCancelledForm;
        break;
      case ACTIVITY_COMPLETED:
        if (this.activityTypeForupdate === "Branch Training") {
          form = ActivityBranchTrainingCompletedForm;
        } else if (this.activityTypeForupdate === "Branch Foundation Day") {
          form = ActivityCompletedForm;
          form[3].hide = false
        }
        else {
          form = ActivityCompletedForm;
          form[3].hide = true
          form[3].validators = []
        }
        break;
      case JOINTCALL_COMPLETED:
        form = JOINTCALL_COMPLETED_FORM;
        break;
      default:
        form = ActivityCompletedForm;
        break;
    }
    return form;
  };
}
const blockCode = (formData) => {
  if (formData.find((data) => data.key === "IsClaimRequired")) {
    formData.find((data) => data.key === "IsClaimRequired").options = CLAIM_TRAVEL_EXPENCE_OPTIONS
  }
}

export const initFormData = (formFields, masterDataState, actionType, roleId) => {
  let formData = [...formFields]
  // const {  Branches, PriorityStatus } = masterDataState;
  // const BranchesCopy = [...Branches];
  // const arrPriorityStatus = PriorityStatus.slice(0, 4);
  switch (actionType) {
    case ACTIVITY_RESCHEDULED:
      if (roleId === "RBH" || roleId === "ZBH" || roleId === "NH") {
        formData = formData.filter((option) => {
          if (option.key !== "Remarks") {
            return option;
          }
        });
      }
      blockCode(formData)
      break;
    case JOINTCALL_RESCHEDULED:
      formData.find(ele => ele.key === "FpmMeetingTypeID").options = masterDataState[CONFIGS.MASTER_DATA_TABLES.FPMFPH_MEETING_TYPE.TABLE_NAME]
      if (formData.find((data) => data.key === "IsClaimRequired")) {
        formData.find((data) => data.key === "IsClaimRequired").options = CLAIM_TRAVEL_EXPENCE_OPTIONS
      }
      break;
    case ACTIVITY_CANCELLED:
      if (formData.find((data) => data.key === "IsClaimRequired")) {
        formData.find((data) => data.key === "IsClaimRequired").options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
      }
      break;
    case (JOINTCALL_CANCELLED):
      blockCode(formData)
      // if(formData.find((data) => data.key === "IsClaimRequired") ){ 
      //   formData.find((data) => data.key === "IsClaimRequired").options = CLAIM_TRAVEL_EXPENCE_OPTIONS;
      // }
      break;
    case ACTIVITY_COMPLETED:
      // if(formData.find((data) => data.key === "IsClaimRequired") ){ 
      //   formData.find((data) => data.key === "IsClaimRequired").options = CLAIM_TRAVEL_EXPENCE_OPTIONS
      // }
      blockCode(formData)
      break;
    case JOINTCALL_COMPLETED:
      formData[0].options = JOINT_CALL_DURATION
      break;
    default:
      // if(formData.find((data) => data.key === "IsClaimRequired") ){ 
      //   formData.find((data) => data.key === "IsClaimRequired").options = CLAIM_TRAVEL_EXPENCE_OPTIONS
      // }
      blockCode(formData)
      break;
  }
  return formData;
};
export const removeSubArrayFromArray = (
  subArray,
  keyForSearch,
  arrOfObjects
) => {
  let newArray = [...arrOfObjects];
  for (const object of subArray) {
    newArray = newArray.filter(
      (item) => item[keyForSearch] !== object[keyForSearch]
    );
  }
  return newArray
}
export const handleDropdownSelection = (event, key, formFields, masterDataState, actionType, role = ROLE_BDM) => {
  let formData = [...formFields]
  const { ProductDetails, Salutation, SPType, LeadSource, Branches, PriorityStatus } = masterDataState;
  const SPTypeOption = SPType?.filter((item) => item['key'] !== "STAFF")
  const BranchesCopy = [...Branches];
  const arrPriorityStatus = PriorityStatus.slice(0, 4);
  switch (actionType) {
    case ACTIVITY_RESCHEDULED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityRescheduledFormPart1);
            formData.map((field, index) => {
              if (field.key === "TransportModeID") {
                formData[index].options = masterDataState["ModeofTransport"];
              }
            })
          } else {
            const arrayToRemove1 = ActivityRescheduledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = ActivityRescheduledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = ActivityRescheduledFormPart1;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        case "TransportModeID":
          if (event.target.value === "1") {
            formData = formData.concat(ActivityRescheduledFormPart2);
            //  formData[6].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]

            const arrayToRemove = ActivityRescheduledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === "2") {
            const arrayToRemove = ActivityRescheduledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            let roleValidation = [ROLE_BDM, ROLE_FPM];
            if (!roleValidation.includes(role)) {
              formData = formData.concat(ActivityRescheduledFormPart3);
              // formData[6].options =
              //   masterDataState[
              //   CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
              //   ];

              formData.map((field, index) => {
                if (field.key === "VehicleTypeID") {
                  formData[index].options = masterDataState[
                    CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
                  ];
                }
              })
            }
          } else {
            const arrayToRemove = ActivityRescheduledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;
    case JOINTCALL_RESCHEDULED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(JointSalesCallActivityRescheduledFormPart1);
            formData.find((ele) => ele.key === "TransportModeID").options = masterDataState["ModeofTransport"];

          } else {
            const arrayToRemove1 = JointSalesCallActivityRescheduledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = JointSalesCallActivityRescheduledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = JointSalesCallActivityRescheduledFormPart1;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        case "TransportModeID":

          if (event.target.value === "1") {
            formData = formData.concat(JointSalesCallActivityRescheduledFormPart2);
            //  formData[6].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]

            const arrayToRemove = JointSalesCallActivityRescheduledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === "2") {
            const arrayToRemove = JointSalesCallActivityRescheduledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            if (role !== ROLE_BDM && role !== ROLE_FPM) {
              formData = formData.concat(JointSalesCallActivityRescheduledFormPart3);
              formData[6].options =
                masterDataState[
                CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
                ];

            }
          } else {
            const arrayToRemove = JointSalesCallActivityRescheduledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;
    case ACTIVITY_CANCELLED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityCancelledFormPart1);
            formData[3].options = masterDataState["ModeofTransport"];
          } else {
            const arrayToRemove = ActivityCancelledFormPart1;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

            const arrayToRemove1 = ActivityCancelledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

            const arrayToRemove2 = ActivityCancelledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
          }
          break;
        case "TransportModeID":
          if (event.target.value === "1") {
            formData = formData.concat(ActivityCancelledFormPart2);

            const arrayToRemove1 = ActivityCancelledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
          } else if (event.target.value === "2") {
            const arrayToRemove = ActivityCancelledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            let roleValidation = [ROLE_BDM, ROLE_FPM];
            
            if (!roleValidation.includes(role)) {
              formData = formData.concat(ActivityRescheduledFormPart3);
              // formData[6].options =
              //   masterDataState[
              //   CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
              //   ];

              formData.map((field, index) => {
                if (field.key === "VehicleTypeID") {
                  formData[index].options = masterDataState[
                    CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
                  ];
                }
              })
            }
          } else {
            const arrayToRemove = ActivityCancelledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;

    case ACTIVITY_COMPLETED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityCompletedFormPart1);
            formData[4].options = masterDataState["ModeofTransport"];
          } else {
            const arrayToRemove1 = ActivityCompletedFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = ActivityCompletedFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = ActivityCompletedFormPart1;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        case "TransportModeID":
          if (event.target.value === "1") {
            formData = formData.concat(ActivityCompletedFormPart2);
            const arrayToRemove = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === "2") {
            const arrayToRemove1 = ActivityCompletedFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove = ActivityCompletedFormPart3
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData)
            let roleValidation = [ROLE_BDM, ROLE_FPM]; 

            if (!roleValidation.includes(role)) {
              formData = formData.concat(ActivityRescheduledFormPart3);
              // formData[6].options =
              //   masterDataState[
              //   CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
              //   ];

              formData.map((field, index) => {
                if (field.key === "VehicleTypeID") {
                  formData[index].options = masterDataState[
                    CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
                  ];
                }
              })
            } else {
              const arrayToRemovew = ActivityCompletedFormPart3
              formData = removeSubArrayFromArray(arrayToRemovew, "key", formData)
            }
          } else {
            const arrayToRemove = ActivityCompletedFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;
    case JOINTCALL_CANCELLED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData = formData.concat(ActivityCancelledFormPart1);
            formData[3].options = masterDataState["ModeofTransport"];
          } else {
            const arrayToRemove = ActivityCancelledFormPart1;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);

            const arrayToRemove1 = ActivityCancelledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);

            const arrayToRemove2 = ActivityCancelledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
          }
          break;
        case "TransportModeID":
          if (event.target.value === "1") {
            formData = formData.concat(ActivityCancelledFormPart2);

            const arrayToRemove1 = ActivityCancelledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
          } else if (event.target.value === "2") {
            const arrayToRemove = ActivityCancelledFormPart2;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            if (role !== ROLE_BDM && role !== ROLE_FPM) {
              formData = formData.concat(ActivityCancelledFormPart3);
              formData[4].options =
                masterDataState[
                CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME
                ];
            }
          } else {
            const arrayToRemove = ActivityCancelledFormPart3;
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
      }
      break;

    case JOINTCALL_COMPLETED:
      switch (key) {
        case "IsClaimRequired":
          if (event) {
            formData.splice(3, 0, JOINTCALL_COMPLETED_TRANSPORT_FORM[0]);
            formData[3].options = masterDataState['ModeofTransport']

          } else {
            const arrayToRemove1 = JOINTCALL_COMPLETED_TYPE_OF_TRANSPORT
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
            const arrayToRemove2 = JOINTCALL_COMPLETED_TRAVEL_EXPENSE_FORM
            formData = removeSubArrayFromArray(arrayToRemove2, "key", formData);
            const arrayToRemove = JOINTCALL_COMPLETED_TRANSPORT_FORM
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        case "TransportModeID":
          if (event.target.value === '1') {
            // formData = formData.concat(JOINTCALL_COMPLETED_TRAVEL_EXPENSE_FORM);
            formData.splice(4, 0, ...JOINTCALL_COMPLETED_TRAVEL_EXPENSE_FORM)
            const arrayToRemove = JOINTCALL_COMPLETED_TYPE_OF_TRANSPORT
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          } else if (event.target.value === '2') {
            const arrayToRemove = JOINTCALL_COMPLETED_TRAVEL_EXPENSE_FORM
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
            if (role !== ROLE_BDM && role !== ROLE_FPM) {
              formData = formData.concat(JOINTCALL_COMPLETED_TYPE_OF_TRANSPORT);
              formData[5].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
            }
          }
          else {
            const arrayToRemove = JOINTCALL_COMPLETED_TYPE_OF_TRANSPORT
            formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
          }
          break;
        case "leadgenerated":
          if (event) {
            formData.splice(3, 0, ...JOINT_CALL_LEAD_FORM);
            // formData = formData.concat(JOINT_CALL_LEAD_FORM);
            formData.find((data) => data.key === "SalutationID").options = Salutation
            formData.find((data) => data.key === "ProductID").options = ProductDetails
            formData.find((data) => data.key === "PriorityID").options = arrPriorityStatus
            formData.find((data) => data.key === "LeadSourceID").options = LeadSource
            formData.find((data) => data.key === "SPTypeID").options = SPTypeOption
            formData.find((data) => data.key === "BranchID").options = getDropdownDataBranch(BranchesCopy, 'key', 'label', 'value');
          } else {
            const arrayToRemove1 = JOINT_CALL_LEAD_FORM
            formData = removeSubArrayFromArray(arrayToRemove1, "key", formData);
          }
          break;
      }
      break;
  }
  return formData;
};

export const customValidators = [
  {
    key: "ExpectedPremium",
    validator: isPositiveNumber, // function to be called
    message: "Expected Premium should be greater than 0", // message to show
  },
  {
    key: "TravelExpence",
    validator: isPositiveNumber, // function to be called
    message: "Travel Expense should be greater than 0", // message to show
  },
  {
    key: 'FirstName',
    validator: isAlphabetOnly,
    message: 'Please enter alphabets only.'
  },
  {
    key: 'LastName',
    validator: isAlphabetOnly,
    message: 'Please enter alphabets only.'
  },
  {
    key: 'MiddleName',
    validator: isAlphabetOnly,
    message: 'Please enter alphabets only.'
  },
  {
    key: 'Email',
    validator: isEmailValid,
    message: 'invalid email id with missing domain name, @, .com, gmail id etc.'
  },
  {
    key: 'BranchID',
    validator: isBranchValid,
    message: "Branch code and activity id's branch code should be same."
  },
  {
    key: 'MobileNo',
    validator: numberStartWith6789Validator,
    message: '10 digit phone number starting with 6,7,8,9 only.'
  },
  {
    key: 'AlternateNo',
    validator: numberStartWith6789Validator,
    message: '10 digit phone number starting with 6,7,8,9 only.'
  },
  {
    key: 'MeetingDate',
    validator: isMeetindDate,
    message: "Meeting already exits."
  },
  {
    key: "ExpectedPremium",
    validator: isPositiveNumber, // function to be called
    message: "Expected Premium should be greater than 0", // message to show
  },
];
