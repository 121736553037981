import {
  FINISH_DAY, PUBLIC_TRANSPORT,
  CONFIGS, DROPDOWN_ACTIVITY_TYPE,
  DROPDOWN_EXPENCE_CLAIM, DROPDOWN_MODE_OF_TRANSPORT, TRAINING, NEW_BUSINESS,
  NEW_RECRUITMENT_ACTIVITY, REVIEW_MEETING, ROLE_BDM
} from "../../../../utils/config";
import { trainngActivityForm } from './trainingForm';
import { newBusinessActivityForm } from './newBusinessForm';
import {
  CSCFinishDayForm, CSCExpenceClaimForm,
  CSCPublicTransportForm, CSCSelfOwnedTransportForm
} from './finishDayActivityForm';

import { newRecruitmentActivityForm } from './newRecruitmentForm';
import { ReviewMeetingActivityForm, ReviewMeetingActivityForm1, ReviewMeetingActivityForm2, ReviewMeetingActivityForm3, ReviewMeetingActivityForm4, ReviewMeetingActivityForm5 } from './reviewMeetingForm';
export const AgencyActivityForm = [
  {
    "key": "ActivityID", "type": "select", "disabled": false,
    "id": "ActivityID", "label": "Activity Type",
    "validators": [{ "name": "required", "value": true, "validationMessage": "Activity type is required." }], "options": []
  },
]

export const initFormData = (formFields, masterDataState) => {
  const formData = [...formFields]
  formData[0].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY.TABLE_NAME]
  return formData
}

export const getAutoCompleteItems = (masterDataState, tableName) => {
  const options = []
  for (const option of masterDataState[tableName]) {
    options.push({ id: option.value.toString(), label: option.label })
  }
  return options
}

export const getDropDownOptions = (data, dropDownConfig, channelID = '') => {
  const options = [
    { "key": "Select", "label": "Select", "value": "" }
  ]
  for (const option of data) {
    if (channelID !== '' && channelID !== option[dropDownConfig.CHANNEL_ID]) {
      continue
    }
    options.push({ "key": option[dropDownConfig.CODE], "label": option[dropDownConfig.LABEL], "value": option[dropDownConfig.KEY] })
  }
  return options
}

export const getOptionById = (id, optionValues) => {
  return optionValues.find(item => item.value === id)
}

export const removeSubArrayFromArray = (subArray, keyForSearch, arrOfObjects) => {
  let newArray = [...arrOfObjects]
  for (const object of subArray) {
    newArray = newArray.filter((item) => item[keyForSearch] !== object[keyForSearch])
  }
  return newArray
}
export const removeExtraConcat = (data) => {
  return data.splice(0, 1);
}
const getAgencyAdvisorMapping = async (status) => {
  const AgencyAdvisorMappingList = await this.props.getAdvisorZMAPremierZMAListing({
    userId: this.props.userState.profileInfo.userId,
    status: status
  });
  let arr = [];
  if (AgencyAdvisorMappingList && AgencyAdvisorMappingList.data.length > 0) {
    arr = AgencyAdvisorMappingList.data.map((option) => {
      if (status === "ZMA" || status === "Advisor" || status === "Premier ZMA") {
        return { key: option['Name'], label: `${option['Name']}`, value: option['UserID'] }
      } else {
        return { key: option['UserID'], label: `${option['Name']}`, value: option['UserID'] }
      }
    })
  }

  arr.unshift({ "key": "select", "label": "select", "value": "" })
  return arr;
}

const dropdownListBind = (list) => {
  let arr = [];
  if (list && list.length > 0) {
    arr = list.map((option) => {
      return { key: `${option['Name'] + " " + option['Code']}`, label: `${option['Name']} ${option['Code']}`, value: option['UserID'] }
    })
  }
  arr.unshift({ "key": "select", "label": "select", "value": "" })
  return arr
}

export const handleActivitySelection = (event, key, formFields, masterDataState, role) => {
  let formData = [...formFields]
  switch (key) {
    case DROPDOWN_ACTIVITY_TYPE:
      formData = AgencyActivityForm;
      const selectedActivity = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.ACTIVITY.TABLE_NAME])
      switch (selectedActivity.key) {
        case TRAINING:
          formData = formData.concat(trainngActivityForm)
          formData[1].hide=false;
          formData[2].hide=false;
          console.log(masterDataState);
          let arr = [];
          if (masterDataState.ZMAList && masterDataState.ZMAList.length > 0) {
            arr = masterDataState.ZMAList.map((option) => {

              return { key: `${option['Name'] + " " + option['Code']}`, label: `${option['Name']}`, value: option['UserID'] }

            })
          }

          arr.unshift({ "key": "select", "label": "select", "value": "" })

          formData[1].options = arr;
          let arr2 = [];
          if (masterDataState.AdvisorList && masterDataState.AdvisorList.length > 0) {
            arr2 = masterDataState.AdvisorList.map((option) => {

              return { key: `${option['Name'] + " " + option['Code']}`, label: `${option['Name']}`, value: option['UserID'] }

            })
          }

          arr2.unshift({ "key": "select", "label": "select", "value": "" })
          formData[2].options = arr2;
          break;
        case NEW_BUSINESS:
          formData = formData.concat(newBusinessActivityForm)
          formData[1].options =masterDataState.ActivityDoneWith
          break;
        case NEW_RECRUITMENT_ACTIVITY:
          formData = formData.concat(newRecruitmentActivityForm)
          let array = [];
          if (masterDataState.ZMAList && masterDataState.ZMAList.length > 0) {
            array = masterDataState.ZMAList.map((option) => {
              return { key: option['UserID'], label: `${option['Name'] + " " + option['Code']}`, value: option['UserID'] }
            })
          }

          array.unshift({ "key": "select", "label": "select", "value": "" })

          formData[2].options = array;

          break;
        case REVIEW_MEETING:
          formData = formData.concat(ReviewMeetingActivityForm)
          formData[1].options =masterDataState.VisitTo
          formData = formData.concat(ReviewMeetingActivityForm5)
          break;
        case FINISH_DAY:
          formData = formData.concat(CSCFinishDayForm)
          break;
      }
      return formData;
    case DROPDOWN_EXPENCE_CLAIM:
      if (event) {
        formData = formData.concat(CSCExpenceClaimForm)
        formData[2].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME]
      } else {
        const arrayToRemove = CSCExpenceClaimForm.concat(CSCPublicTransportForm).concat(CSCSelfOwnedTransportForm)
        formData = removeSubArrayFromArray(arrayToRemove, "key", formData);
      }
      break;
    case DROPDOWN_MODE_OF_TRANSPORT:
      const selectetTransport = getOptionById(event.target.value, masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME])
      if (selectetTransport.key === PUBLIC_TRANSPORT) {
        formData = removeSubArrayFromArray(CSCSelfOwnedTransportForm, "key", formData);
        formData = formData.concat(CSCPublicTransportForm)
      } else {
        formData = removeSubArrayFromArray(CSCPublicTransportForm, "key", formData);
        let roleValidation = [ROLE_BDM]; // "RBH", "ZBH", "NH"

        if (!roleValidation.includes(role)) {
          formData = formData.concat(CSCSelfOwnedTransportForm)
          formData[3].options = masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME]
        }
      }
      break;
    case 'RecruitmentFor':
      if (event.target.value == "3") {
        formData[2].hide = false
        formData[2].validators[0].value=true
      } else {
        formData[2].hide = true
        formData[2].validators[0].value=false
      }

      break;

    case "VisitToID":
      if (event.target.value == 1) {
        formData.splice(2, 0, ReviewMeetingActivityForm2[0]);

        formData = formData?.filter(el => el.key !== "ZMAID" && el.key !== "PremierZMAID" && el.key !== "EAPIDs")
        formData[2].hide = false;
        formData[2].options = dropdownListBind(masterDataState.AdvisorList)
      } else if (event.target.value == 2) {
        //zma
        formData.splice(2, 0, ReviewMeetingActivityForm1[0]);
        formData = formData?.filter(el => el.key !== "AdvisorID" && el.key !== "PremierZMAID" && el.key !== "EAPIDs")
        formData[2].hide = false;
        formData[2].options = dropdownListBind(masterDataState.ZMAList)
      } else if (event.target.value == 3) {
        //p zma
        formData.splice(2, 0, ReviewMeetingActivityForm3[0]);
        formData = formData?.filter(el => el.key !== "ZMAID" && el.key !== "AdvisorID" && el.key !== "EAPIDs")
        formData[2].hide = false;
        formData[2].options = dropdownListBind(masterDataState.PremierZMA)
      }
      else if (event.target.value == 4) {
        formData.splice(2, 0, ReviewMeetingActivityForm4[0]);
        formData = formData?.filter(el => el.key !== "ZMAID" && el.key !== "AdvisorID" && el.key !== "PremierZMAID")
        //eap
        formData[2].hide = false;
        formData[2].options = dropdownListBind(masterDataState.EAPList)
      }
      break
    default:
      break;
  }
  return formData;
}


export const validateExtraValidations = (formData) => {
  // validate expected and converted leads
  let extraValidations = {}
  if (formData.hasOwnProperty('ExpectedLead') && formData.hasOwnProperty('ExpectedConversion') && formData["ExpectedLead"] !== '' && formData["ExpectedConversion"] !== '') {
    if (parseInt(formData['ExpectedConversion']) > parseInt(formData['ExpectedLead'])) {
      extraValidations['ExpectedConversion'] = 'Expected conversion should be less than or equal to expected leads.'
    } else if (parseInt(formData['ExpectedConversion']) <= parseInt(formData['ExpectedLead'])) {
      extraValidations = {}
    }
  }
  return extraValidations;
}
export const tiketsizeExtraValidations = (formData) => {
  // validate expected and converted leads
  const extraValidations = {}
  if (formData.hasOwnProperty('TicketSize') && formData.hasOwnProperty('ExpectedConversion') &&
    formData["TicketSize"] === '' && formData["ExpectedConversion"] !== '') {
    extraValidations['TicketSize'] = 'Ticket size should not be blank.'
  }
  return extraValidations;
}
