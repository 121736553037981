import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TeamListItem from '../../../components/teamListItem/teamListItem';
import moment from "moment";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Fab from '@mui/material/Fab';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { t } from "i18next";
import Box from '@mui/material/Box';
import DynamicForm from "f1-dynamic-form-react";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { setBreadcrumbs } from "../../../redux/actions/common";
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { useHistory } from "react-router-dom"

const MyTeamList = (props) => {
    const userStatusType = props.match.params.type;
    const dispatch = useDispatch();
    const history = useHistory();
    const commonState = useSelector(state => state);
    const bdmFormState = commonState.leadFormReducer.TeamList;
    const [drawerState, setState] = useState({'bottom': false});
    const [filterState, setFilterState] = useState({
      bdmName: 'all',
      bdmStatus: 'all'
    });
    const bdmftilerForm = [
      {
        colmd: "12",
        key: "bdmName",
        label: "BDM Name",
        hide: false,
        type: "select",
        id: "bdmName",
        name: "bdmName",
        options: [
        ],
        validators: []
      },
      {
        colmd: "12",
        key: "bdmStatus",
        label: "BDM Status",
        hide: false,
        type: "select",
        id: "bdmStatus",
        name: "bdmStatus",
        options: [
          { key: "all", label: "All", value: "all" },
          { key: "loggedIn", label: "Logged In", value: "1" },
          { key: "notLoggedIn", label: "Not Logged In", value: "0" }
        ],
        validators: []
      }
    ];
    const [formData, setFormData] = useState(bdmftilerForm);
    const [bdmList, setBdmList] = useState([]);
    const getIntials = (name) => {
        if (name != null) {
          var words = name.split(" ");
          return `${words[0].slice(0, 1).toUpperCase()}${' '}${words[1].slice(0, 1).toUpperCase()}`;
        } else {
          return "";
        }
    };
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...drawerState, [anchor]: open });
    };
    const handleClose = () => {
      setState({ ...drawerState, bottom: false });
    };
    const useStyles = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
      },
      drawerMobile: {
        display: "none",
        [theme.breakpoints.down('xs')]: {
          display: "block"
        }
      },
      desktopFilter: {
        [theme.breakpoints.down('xs')]: {
          display: "none"
        }
      }
    }));
    const classes = useStyles();
    const handleSubmit = () => {
      if (filterState.bdmStatus === '1' && filterState.bdmName !== 'all') {
        let userList = bdmFormState.UsersDetails;
        userList = userList.filter((user) => user.IsLogin === 1 && user.UserID.toString() === filterState.bdmName)
        setBdmList(userList);
      } else if (filterState.bdmStatus === '0' && filterState.bdmName !== 'all') {
        let userList = bdmFormState.UsersDetails;
        userList = userList.filter((user) => user.IsLogin === 0 && user.UserID.toString() === filterState.bdmName)
        setBdmList(userList);
      } else if (filterState.bdmStatus === '1' && filterState.bdmName === 'all') {
        let userList = bdmFormState.UsersDetails;
        userList = userList.filter((user) => user.IsLogin === 1)
        setBdmList(userList);
      } else if (filterState.bdmStatus === '0' && filterState.bdmName === 'all') {
        let userList = bdmFormState.UsersDetails;
        userList = userList.filter((user) => user.IsLogin === 0)
        setBdmList(userList);
      } else if (filterState.bdmStatus === 'all' && filterState.bdmName !== 'all') {
        let userList = bdmFormState.UsersDetails;
        userList = userList.filter((user) => user.UserID.toString() === filterState.bdmName)
        setBdmList(userList);
      } else if (filterState.bdmStatus !== 'all' && filterState.bdmName !== 'all') {
        const check = filterState.bdmStatus === '1' ? 1: 0;
        let userList = bdmFormState.UsersDetails;
        userList = userList.filter((user) =>user.IsLogin === check && user.UserID.toString() === filterState.bdmName)
        setBdmList(userList);
      } else {
        setBdmList(bdmFormState.UsersDetails);
      }
      handleClose();
    };
    const handleOnChange = (event, key) => {
      setFilterState({...filterState, [key]: event.target.value});
    };
    const handleReset = () => {
      setFilterState({
        bdmName: 'all',
        bdmStatus: 'all'
      })
      setBdmList(bdmFormState.UsersDetails);
      handleClose();
    };
    const bdmFilter = () => {
        return (
          <div className="filterBox">
            <h4 className="filterSec">
              {" "}
              <FilterAltIcon className="icnFilter" /> Filter By <CloseIcon sx={{ display: { xs: "flex", md: "none" } }} className={classes.drawerMobile} style={{ float: 'right', cursor: 'pointer' }} onClick={handleClose} />
            </h4>
            <div className="fformBox">
              <DynamicForm
                key={1}
                className="dynamicForm leadFilters  leadFiltersMobile"
                title={" "}
                buttonSubmit={t("Apply Filter")}
                defaultValues={filterState}
                model={formData}
                onSubmit={(model) => handleSubmit()}
                disableSubmitOnInValid={false}
                design="bootrstap"
                materialOutline={false}
                onChange={(event, key) => handleOnChange(event, key)}
                buttonNormalClick={(id, key, obj) => console.log(id, key, obj)}
                showAstersikOnRequiredFields={false}
                extaraActionFormButton={
                  <span className="reset" onClick={handleReset} ><RestartAltIcon /> Reset</span>
                }
              />
            </div>
          </div>
        );
    };
    useEffect(() => {
      if (bdmFormState && bdmFormState.UsersDetails && bdmFormState.UsersDetails.length > 0) {
        if (userStatusType && userStatusType.toLowerCase() === 'active') {
          let userList = bdmFormState.UsersDetails;
          userList = userList.filter((user) => user.IsLogin === 1)
          setBdmList(userList);
        } else if (userStatusType && userStatusType.toLowerCase() === 'non-active') {
          let userList = bdmFormState.UsersDetails;
          userList = userList.filter((user) => user.IsLogin === 0)
          setBdmList(userList);
        } else {
          setBdmList(bdmFormState.UsersDetails);
        }
      }
      dispatch(setBreadcrumbs(breadcrumbs));
    }, [bdmFormState])
    useEffect(() => {
      if (bdmList && bdmList.length > 0) {
        const mFormData = initFormData(formData);
        setFormData(mFormData);
      }
    }, [bdmList]);
    const getDropdownData = (optionsArr, key, label, value) => {
      let arr = [];
      if (optionsArr && optionsArr.length > 0) {
        arr = optionsArr.map((option) => {
          return { key: option[key], label: option[label], value: option[value] };
        });
      }
      arr.unshift({ key: "all", label: "All", value: "all" });
      return arr;
    };
    const BDM_LIST = getDropdownData(
      bdmFormState.UsersDetails,
      "UserID",
      "UserName",
      "UserID"
    )
    const initFormData = (formFields) => {
      const mformData = [...formFields];
      mformData[0].options = BDM_LIST
      return mformData;
    }
    const breadcrumbs = [
      { name: 'Dashboard', url: '/app/home' },
      { name: 'My Team', url: `${'/app/home/myTeam/'}${userStatusType}`}
    ]
    return (
      <div>
          <div className='myTeamDiv'>
              <Breadcrumb />
              <h3 className='title'><span>{bdmList.length}/{bdmList.length} Result(s)</span></h3>
              <Grid container>
                <Grid item xs={12} md={9}>
                    {
                      bdmList.length > 0 && bdmList.map((user, index)=> {
                          return(<TeamListItem
                              key={index}
                              userName={user.UserName}
                              initials={getIntials(user.UserName)}
                              loggedIn={moment(user.LastLoginDateTime).format("dddd, MMMM Do, h:mm a")}
                              lastSeen={moment(user.lastSeen).format("dddd, MMMM Do, h:mm a")}
                              isLogin={user.IsLogin}
                              userID={user.UserID}
                              history={history}
                          />)
                      })
                  }
                </Grid>
                <Grid item xs={12} md={3} sx={{ display: { xs: "none", md: "flex" }}}>
                  <Grid container>
                      <Grid item xs={12} md={12}>
                      {bdmFilter()}
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
          </div>
          <span>
              {['bottom'].map((anchor) => (
                  <React.Fragment key={anchor}>
                      <Box className="editBtn" sx={{ '& > :not(style)': { m: 1 }, display: { xs: "flex", md: "none" } }} onClick={toggleDrawer(anchor, true)} >
                          <Fab color="primary" aria-label="add" sx={{ float: 'right' }}>
                              <FilterAltIcon />
                          </Fab>
                      </Box>
                      <SwipeableDrawer
                          anchor={anchor}
                          open={drawerState[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                      >
                          {bdmFilter()}
                      </SwipeableDrawer>
                  </React.Fragment>
              ))}
          </span>
      </div>
    )
}

export default MyTeamList