import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import Footer from "../../../layouts/footer/footer";
import { getForm } from "../../../pages/postlogin/leads/createLeadForm";
import { getNextLeadByStatus, getLeadHistory, getPersistancyLeadListById } from "../../../redux-reducer/reducer";
import { BANCA_LEAD, PERSISTANCY } from "../../../utils/config";
import { useParams } from "react-router";
import Loader from "../../../components/loader/loader";
import '../../../../src/pages/postlogin/leads/createLead.scss'
import { PERSISTANCY_LEAD_PATH } from "../../../route/routePath";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon' ;
import ListItemText from '@mui/material/ListItemText';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import PersistencyLeadLayout from "./persistencyLeadLayout";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { setBreadcrumbs } from "../../../redux/actions/common";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { isUserSame } from "../../../utils/utils";

const AdminPersistancyLeadDetails = () => {
    const { id } = useParams();
    const history = useHistory();
    const isOnline = useOnlineStatus()
    const formName = BANCA_LEAD;
    const { lead_action_list, lead_history } = useSelector(state => state.updateLeadReducer);
    const { isActiveLoader } = useSelector(activate => activate.commonReducer);
    const [stateSelect, setStateSelect] = useState({
        current: { id: 1 },
        formData: getForm(formName)
    })
    const dispatch = useDispatch();
    const [leadDetails, setLeadDetails] = useState();
    const userData = JSON.parse(localStorage.getItem("persist:root"));
    const userId = JSON.parse(userData.userReducer).profileInfo.userId;
    const [leadMsg, setLeadMsg] = useState();
    const [leadActionList, setLeadActionList] = useState();
    const { getPersistancyLeadData } = useSelector(leadData => leadData.leadFormReducer);
    const { LeadStatusId } = getPersistancyLeadData;
    const goTo = (action, ids) => {
        history.push(`/app/lead/update/${PERSISTANCY}/${action}/${ids}`);
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setStateSelect({ ...stateSelect, [anchor]: open });
    }
    const breadcrumbs = [
        { name: 'Persistency Leads', url: PERSISTANCY_LEAD_PATH },
        { name: 'Lead Details' }
    ]

    useEffect(() => {
        console.log("useEffect");
        if (isOnline) {
            dispatch(getPersistancyLeadListById(id));
            dispatch(getLeadHistory(id, PERSISTANCY));
        }
    }, [])

    const list = (anchor, leadId) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List className="updatePopupDiv">
                <ListItem color="black" sx={{ fontWeight: 'bold', fontSize: 20 }} > Update Lead
                    <CloseIcon />
                </ListItem>
                {leadActionList && leadActionList.length > 0 && (parseInt(leadDetails.AssignedTo) === parseInt(userId)) && leadActionList.map((actn) => (
                    <ListItem className={`editBtn ${actn.ISActionable ? "" : "disabled"}`} button onClick={() => actn.ISActionable ? goTo(actn.LeadActionCode, leadId) : ""}>
                        <ListItemIcon >
                            <Fab size="small" color="primary" aria-label="add" sx={{ mr: 2 }}>
                                <EditIcon />
                            </Fab> {actn.LeadActionName}
                        </ListItemIcon>
                        <ListItemText />
                    </ListItem>
                ))}
            </List>
            <Divider />

        </Box>
    )
    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
        dispatch({
            type: "ACTIVATE_LOADER",
            payload: false
        })
    }, [])

    useEffect(() => {
        if (isOnline) {
            dispatch(getPersistancyLeadListById(id));
            dispatch(getLeadHistory(id, PERSISTANCY));
        }
    }, [id])

    useEffect(() => {
        if (isOnline && getPersistancyLeadData) {
            setLeadDetails(getPersistancyLeadData)
        } else {
            setLeadMsg("Lead details not found");
        }
    }, [getPersistancyLeadData])

    useEffect(() => {
        if (isOnline) {
            LeadStatusId && dispatch(getNextLeadByStatus(LeadStatusId,PERSISTANCY));//LeadStatusId
        }
    }, [LeadStatusId])

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            textAlign: 'left',
            color: theme.palette.text.secondary,
            //height: '100%',
            padding: theme.spacing(2),

        },
        paper2: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color: theme.palette.text.secondary,
            // height: '300px',
        },
        activityLogo: {
            //  borderRadius: 10,
            textAlign: 'center',
            backgroundColor: 'rgb(0 66 126 / 27%)',
            height: '50px',
            padding: theme.spacing(2),
        },
        heading: {
            padding: "1px 2px 2px 1px",
        }
    }));
    const classes = useStyles();
    useEffect(() => {
        if (lead_action_list) {
            const restrictedArr = ['INTRESTED', 'CONVERTED', 'APPLICATION_CREATED']

            const permittedActionList = lead_action_list.filter((item) => !restrictedArr.includes(item.LeadActionCode))
            setLeadActionList(permittedActionList);
        }
    }, [lead_action_list])

    const getHistory = () => {
        dispatch(getLeadHistory(id, PERSISTANCY));
    }
    return (
        <div className="mainContainer leadDetails">
            <Breadcrumb />
            {
                leadDetails ?
                    <PersistencyLeadLayout hitory={lead_history} leadDetails={leadDetails} classes={classes} onGetUpdatedHistory={getHistory} />
                    :
                    <h4>{leadMsg}</h4>
            }
            <span>
                {leadActionList && isUserSame(leadDetails.AssignedTo, userId) && leadActionList.length > 0 && ['bottom'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>  */}
                        <Box className="editBtn" sx={{ '& > :not(style)': { m: 1 } }} onClick={toggleDrawer(anchor, true)} >
                            <Fab color="primary" aria-label="add" sx={{ float: 'right' }}>
                                <EditIcon />
                            </Fab>
                        </Box>
                        <SwipeableDrawer
                            anchor={anchor}
                            open={stateSelect[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                        >
                            {list(anchor, id)}
                        </SwipeableDrawer>
                    </React.Fragment>
                ))}
            </span>
            <Footer />
            {isActiveLoader && <Loader />}
        </div >
    )
}
export default AdminPersistancyLeadDetails;
