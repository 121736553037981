import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import "../leadList.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCampaignLeadBacklogList,
  getLeadStatusList,
  getFilteredCampaignLeadList,
  getCampaignLeadBacklogListFilter,
  getCampaignLeadListByIdForTable,
} from "../../../../redux-reducer/reducer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CampaignLeadFilter from "./campaignLeadFilter";
import CampaignLeadsBacklogFilter from "./campaignLeadBacklogsFilter";
import useOnlineStatus from "../../../../hooks/useOnlineStatus";
import {
  getAllLeadOffline
} from "../../../../offlineManager/leadOfflineManager";
import { getCampaignLeadList, setLeadsOverview } from "../../../../redux-reducer/reducer/leadListReducer";
import ListingCampaign from "../../../../components/listing/listingCampaign";

import { activateLoader, deActivateLoader, getLeadCsvFile } from "../../../../redux/actions/common";
import { withTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CampaignLeadListing(
  props,
  //  activateLoader, deActivateLoader, getLeadCsvFile
) {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();
  const formRef = useRef();
  const { getLeadsOverView } = useSelector(
    (mainState) => mainState.leadListReducer
  );
  const select = useSelector(statelist => statelist);
  const { allcampaignLeadList } = select.campaignLeadListReducer;
  const { viewForStatus } = useSelector(nextState => nextState.commonReducer)
  const { GetBDMByABH } = select.commonReducer;
  // console.log(viewForStatus)
  const { TotalnoofRows } = allcampaignLeadList
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const userId = JSON.parse(userData.userReducer).profileInfo.userId;
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  // const channelId = JSON.parse(userData.userReducer).profileInfo.channelid;
  const [state, setState] = React.useState({
    bottom: false,
  });

  const [value, setValue] = React.useState(0);
  const [totalBacklogRecord, setTotalBacklogRecord] = useState(5);
  const [selectedFilterCampaign, setSelectedFilterCampaign] = useState({
    roleId: roleId,
    viewFor: ""
  })

  const [selectedFilterBacklog, setSelectedFilterBacklog] = useState({
    roleId: roleId,
    viewFor: ""
  })

  const [selectedTeamIDCampaign, setSelectedTeamIDCampaign] = useState("")
  const [selectedTeamIDBacklog, setSelectedTeamIDBacklog] = useState("")
  const [NoOfRecordperPage, setNoOfRecordperPage] = useState(5);
  const [allOfflineLeads, setAllOfflineLeads] = useState([]);
  const { getCampaignBacklogData } = useSelector((reducer) => reducer.leadListReducer);
  const { Campaignleaddata } = getCampaignBacklogData;

  const [leadFilterState, setLeadFilterState] = useState({
    UserName: null,
    LeadName: null,
    CreatedDateTime: null,
    CreatedDateStartTIme: "null",
    CreatedDateEndTIme: "null",
    MeetingOn: null,
    campaigncurrentStatus: [],
    LastUpdateDateTime: null,
    currentStatus: null,
    product: 0,
    activityID: 0,
    MeetingDate: null,
    CampaignName: null,
    fromdate: "null",
    todate: "null",
    MobileNo: null,
    ExpectedLeadClosureDate: "null",

  });

  const [backlogFilterState, setBacklogFilterState] = useState({
    LeadID: null,
    Leadname: null,
    ActivityID: null,
    Createddate: null,
    CurrentStatus: "1,2,3,4,5,6,7,8,9,10",
    Createstartdate: null,
    Createenddate: null,
    CampaignName: null,
    ProductID: null,
    MeetingStartdate: null,
    MeetingEnddate: null,
    IsBacklog: true,
    MobileNo: null,
    Username: null,
    ExpectedLeadClosureDate: "null",
  });

  const [leadReq, setLeadReq] = useState({
    Noofdays: 0,
    LeadtypeID: null,
    Leadname: null,
    ActivityID: null,
    CreatedBy: userId.toString(),
    Createddate: null,
    CurrentStatus: "1,2,3,4,5,6,7,8,9,10",
    MeetingDate: null,
    Modifieddate: null,
    LeadSourceID: null,
    ProductID: null,
    SPTypeID: null,
    BranchID: null,
    MeetingStartdate: null,
    MeetingEnddate: null,
    IsBacklog: false,
    ExpectedLeadClosureDate: null,
  });

  const [backlogleadReq, setbacklogLeadReq] = useState({
    Noofdays: 0,
    LeadtypeID: null,
    Leadname: null,
    ActivityID: null,
    CreatedBy: userId.toString(),
    Createddate: null,
    CurrentStatus: "1,2,3,4,5,6,7,8,9,10",
    MeetingDate: null,
    Modifieddate: null,
    LeadSourceID: null,
    ProductID: null,
    SPTypeID: null,
    BranchID: null,
    MeetingStartdate: null,
    MeetingEnddate: null,
    IsBacklog: true,
    CampaignName: null,
    ExpectedLeadClosureDate: null,
  });


  const getAllBacklogListData = (req, teamMember, backlogleadReqs) => {
    setSelectedTeamIDBacklog(teamMember ? teamMember : GetBDMByABH)
    const Backlogpayload = { ...req, CurrentStatus: getLeadStatusId(getLeadsOverView) }
    const BacklogReq = { ...backlogleadReqs, CurrentStatus: getLeadStatusId(getLeadsOverView) }

    setBacklogFilterState({ ...Backlogpayload })
    setbacklogLeadReq({ ...BacklogReq })
    if (isOnline) {
      dispatch(getCampaignLeadBacklogList(userId, 1, totalBacklogRecord, BacklogReq, roleId, viewForStatus, GetBDMByABH));
    }
  };

  const getFilterBacklogList = (backlogRequest, teamMemberID, branchNameValue) => {
    const { CreatedDateEndTIme, LeadName, CreatedDateStartTIme, CurrentStatus, product, activityID, fromdate, todate, MobileNo, branchNameCode, CampaignName, ExpectedLeadClosureDate } = backlogRequest
    scrollToTop();
    const req = {
      "LeadID": null,
      "LeadName": LeadName,
      "CurrentStatus": CurrentStatus,
      "MeetingStartDate": fromdate !== 'null' ? fromdate : null,
      "MeetingEndDate": todate !== 'null' ? todate : null,
      "Createstartdate": CreatedDateStartTIme !== 'null' ? CreatedDateStartTIme : null,
      "Createenddate": CreatedDateEndTIme !== 'null' ? CreatedDateEndTIme : null,
      "ActivityID": activityID === 0 ? null : parseInt(activityID),
      "ProductID": product === 0 ? null : parseInt(product),
      "MobileNo": MobileNo,
      "IsBacklog": true,
      BranchID: !branchNameValue ? null : parseInt(branchNameCode),
      CampaignName: CampaignName,
      ExpectedLeadClosureDate: ExpectedLeadClosureDate !== "null" ? ExpectedLeadClosureDate : null
    }
    setBacklogFilterState({ ...req })
    if (isOnline) {
      const { viewFor } = selectedFilterBacklog
      dispatch(getCampaignLeadBacklogListFilter(userId, 1, 5, req, selectedFilterBacklog, teamMemberID ? viewFor : viewForStatus, teamMemberID ? teamMemberID : GetBDMByABH));
      setSelectedTeamIDBacklog(teamMemberID ? teamMemberID : GetBDMByABH)
    }
  };

  const getLeadByOverviewSelection = (type) => {
    switch (type) {
      case "open":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "2,3,4,7" },
        };
      case "un-actioned":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "1" },
        };
      case "converted":
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "6" },
        };
      case 'dropped':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq, CurrentStatus: "5" },
        };
      case 'all':
        return {
          UserId: userId,
          PageNo: 1,
          NoOfRecordperPage: NoOfRecordperPage,
          filterReq: { ...leadReq },
        };
      default:
        break;
    }
  };


  useEffect(() => {
    if (isOnline) {
      const payload = getLeadByOverviewSelection(getLeadsOverView)
      setLeadReq({ ...leadReq, LeadStatusId: payload.filterReq.LeadStatusId })
      if (value == 0) {
        dispatch(getCampaignLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
      } else if (value == 1) {
        getAllBacklogListData(backlogFilterState, selectedTeamIDBacklog, backlogleadReq);
      } else {
        dispatch(getCampaignLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
      }
      //getAllCampaignLeads()
      // dispatch(getCampaignLeadDraftListFilter(userId, 1, totalDraftRecord, draftReq));
    } else {
      getAllLeadOffline().then((leads) => {
        leads = leads.sort(
          (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
        );
        // const initialLeadList = leads;
        const leadResponse = {
          Noofpages: Math.round(leads.length / NoOfRecordperPage),
          TotalnoofRows: leads.length,
          // Listofleads: initialLeadList.slice(0, NoOfRecordperPage),
        };
        setAllOfflineLeads(leads);
        dispatch(getCampaignLeadList(leadResponse));
      });
    }
    return () => {
      // Anything in here is fired on component unmount.
      dispatch(setLeadsOverview("all"));
    }

  }, [value]);

  useEffect(() => {
    if (isOnline) {
      dispatch(getLeadStatusList());
    }

    setSelectedFilterCampaign({
      roleId: roleId,
      viewFor: viewForStatus
    })
    setSelectedFilterBacklog({
      roleId: roleId,
      viewFor: viewForStatus
    })

  }, []);

  const loadMoreRecord = () => {
    const payload = {
      UserId: leadReq.teamMemberID !== null ? leadReq.teamMemberID : userId,// 1
      PageNo: 1,
      NoOfRecordperPage: NoOfRecordperPage + 5,
      filterReq: {
        ...leadReq,
      },
    };
    if (isOnline) {
      // if (!isLeadFilterApplied) {
      //   dispatch(getAllCampaignLeadList(payload))
      // } else {
      //   // dispatch(getFilteredCampaignLeadList(leadReq))
      // }
      dispatch(getCampaignLeadListByIdForTable(payload, userId, roleId, selectedTeamIDCampaign ? "BDM" : viewForStatus, selectedTeamIDCampaign ? selectedTeamIDCampaign : GetBDMByABH));//selectedFilterCampaign.viewFor, 
      setNoOfRecordperPage(NoOfRecordperPage + 5);
    } else {
      let batchLeads = [...allOfflineLeads];
      batchLeads = batchLeads.splice(NoOfRecordperPage, NoOfRecordperPage + 5);
      const leadResponse = {
        Noofpages: batchLeads.length / NoOfRecordperPage,
        TotalnoofRows: allOfflineLeads.length,
        //Listofleads: batchLeads,
      };
      setNoOfRecordperPage(NoOfRecordperPage + 5);
      dispatch(getCampaignLeadList(leadResponse));
    }
  };

  const loadMoreBacklogs = () => {
    if (isOnline) {
      dispatch(
        getCampaignLeadBacklogList(userId, 1, totalBacklogRecord + 5, backlogleadReq, roleId, viewForStatus, selectedTeamIDBacklog ? selectedTeamIDBacklog : GetBDMByABH)//userId//selectedTeamIDBacklog ? "BDM" : viewForStatus
      );
      setTotalBacklogRecord(totalBacklogRecord + 5);
    }
  };

  // const loadMoreDraftRecord = () => {
  //   if (isOnline) {
  //     dispatch(getCampaignLeadDraftListFilter(userId, 1, totalDraftRecord + 5, draftReq));
  //     setTotalDraftRecord(totalDraftRecord + 5);
  //   }
  // };

  const getFilteredLeads = (filterRequest, teamMemberID, branchNameValue) => {
    const { CreatedDateEndTIme, LeadName, CreatedDateStartTIme, currentStatus, product, activityID, fromdate, todate, MobileNo, CampaignName, ExpectedLeadClosureDate } = filterRequest
    scrollToTop();
    const req = {
      "LeadID": null,
      "LeadName": LeadName,
      "CurrentStatus": currentStatus,
      "MeetingStartDate": fromdate !== 'null' ? fromdate : null,
      "MeetingEndDate": todate !== 'null' ? todate : null,
      "Createstartdate": CreatedDateStartTIme !== 'null' ? CreatedDateStartTIme : null,
      "Createenddate": CreatedDateEndTIme !== 'null' ? CreatedDateEndTIme : null,
      "ActivityID": activityID === 0 ? null : parseInt(activityID),
      "ProductID": product === 0 ? null : parseInt(product),
      "MobileNo": MobileNo,
      "CampaignName": CampaignName,
      "teamMemberID": teamMemberID,
      BranchID: !branchNameValue ? null : parseInt(filterRequest.branchNameCode),
      IsBacklog: false,
      ExpectedLeadClosureDate: ExpectedLeadClosureDate !== 'null' ? ExpectedLeadClosureDate : null,
    }
    setLeadReq({ ...req })
    if (isOnline) {
      const { viewFor } = selectedFilterCampaign
      dispatch(getFilteredCampaignLeadList(userId, 1, 5, req, selectedFilterCampaign, teamMemberID ? viewFor : viewForStatus, teamMemberID ? teamMemberID : GetBDMByABH));
      setSelectedTeamIDCampaign(teamMemberID ? teamMemberID : GetBDMByABH)
    }
  };

  const getLeadStatusId = (type) => {
    switch (type) {
      case "open":
        return "2,3,4,7"
      case "un-actioned":
        return "1"
      case "converted":
        return "6"
      case 'dropped':
        return "5"
      case 'all':
        return "1,2,3,4,5,6,7,8,9,10"
      default:
        break
    }
  };
  const getAllCampaignLeads = () => {
    const payload = {
      UserId: userId,
      PageNo: 1,
      NoOfRecordperPage: 5,
      filterReq: {
        Noofdays: 0,
        LeadtypeID: null,
        Leadname: null,
        ActivityID: null,
        CreatedBy: userId.toString(),
        Createddate: null,
        CurrentStatus: getLeadStatusId(getLeadsOverView),
        MeetingDate: null,
        Modifieddate: null,
        LeadSourceID: null,
        ProductID: null,
        SPTypeID: null,
        BranchID: null,
        MeetingStartdate: null,
        MeetingEnddate: null,
        IsBacklog: false,
        ExpectedLeadClosureDate: null,
      }
    }
    setLeadReq({ ...payload.filterReq })
    setSelectedTeamIDCampaign("")
    dispatch(getCampaignLeadListByIdForTable(payload, userId, roleId, viewForStatus, GetBDMByABH));
    scrollToTop();
  };


  const scrollToTop = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLeadFilterState = (item) => {
    setLeadFilterState({
      ...item,
    });
  };
  const handleBacklogFilterState = (item) => {
    setBacklogFilterState({
      ...item,
    });
  };

  // const handleCsvDownload = async () => {
  //   const leadType = "CampaignLead"
  //   if (isOnline) {
  //     props.activateLoader();
  //     const result = await props.getLeadCsvFile(
  //       leadReq,
  //       userId,
  //       leadType,
  //       roleId,
  //       viewForStatus !== "" ? viewForStatus : "SELF"
  //     );
  //     if (result.status === 200) {
  //       DownloadFiles(result.data)
  //       props.deActivateLoader();
  //     } else {
  //       props.deActivateLoader();
  //     }
  //   }
  // }
  return (
    <>
      <div className="tabBox">
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="tabs example"
        >
          <Tab label="Campaign Leads" {...a11yProps(0)} />
          {/* <Tab label="Drafts" {...a11yProps(1)} /> */}
          <Tab label="Backlog" {...a11yProps(1)} />
        </Tabs>
      </div>
      {/* {roleId === "ABH" && channelId !=13 &&
        <div>
          <Button onClick={handleCsvDownload} variant="contained">CSV Download</Button>
        </div>
      } */}
      <TabPanel value={value} index={0}>
        <div ref={formRef}>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <ListingCampaign
              roleId={roleId}
              data={allcampaignLeadList.Campaignleaddata}
              toggleDrawer={toggleDrawer}
              loadMoreRecord={loadMoreRecord}
              NoOfRecordperPage={NoOfRecordperPage}
              TotalnoofRows={TotalnoofRows}
            />

            <Grid
              item
              xs={12}
              md={3}
              className="rightCol"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <CampaignLeadFilter
                filterState={leadFilterState}
                setFilterState={handleLeadFilterState}
                getFilteredLeads={getFilteredLeads}
                getAllLeads={getAllCampaignLeads}
                parent={"sidebar"}
                handleClose={toggleDrawer(state.bottom, false)}
                setSelectedFilter={setSelectedFilterCampaign}
              />
            </Grid>
          </Grid>
          {/* <Loader></Loader> */}
        </div>
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <CampaignLeadFilter
                  anchor={anchor}
                  filterState={leadFilterState}
                  setFilterState={handleLeadFilterState}
                  getFilteredLeads={getFilteredLeads}
                  getAllLeads={getAllCampaignLeads}
                  parent={"drawer"}
                  handleClose={toggleDrawer(anchor, false)}
                  setSelectedFilter={setSelectedFilterCampaign}
                />
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <DraftListing
          // ListOfDrafts={getCampaignLeadDraftData.Campaignleaddata}
          // TotalNoOfRows={getCampaignLeadDraftData.TotalnoofRows}
          loadMoreRecord={loadMoreDraftRecord}
          NoOfRecordperPage={totalDraftRecord}
          getAllDraftsData={() =>
            dispatch(getLeadDraftList(userId, 1, totalDraftRecord, draftReq))
          }
        />
      </TabPanel> */}
      <TabPanel value={value} index={1}>
        <div ref={formRef}>
          <Grid container columns={{ xs: 4, md: 12 }}>
            <ListingCampaign
              roleId={roleId}
              data={Campaignleaddata}
              toggleDrawer={toggleDrawer}
              loadMoreRecord={loadMoreBacklogs}
              NoOfRecordperPage={totalBacklogRecord}
              TotalnoofRows={getCampaignBacklogData.TotalnoofRows}
            />

            <Grid
              item
              xs={12}
              md={3}
              className="rightCol"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              {
                <CampaignLeadsBacklogFilter
                  filterState={backlogFilterState}
                  setFilterState={handleBacklogFilterState}
                  getFilteredBacklogList={getFilterBacklogList}
                  getAllBacklogList={getAllBacklogListData}
                  parent={"drawer"}
                  handleClose={toggleDrawer(state.bottom, false)}
                  setSelectedFilter={setSelectedFilterBacklog}
                  setbacklogLeadReq={setbacklogLeadReq}
                />
              }
            </Grid>
          </Grid>
        </div>
        <span>
          {["bottom"].map((anchor) => (
            <React.Fragment key={anchor}>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {
                  <CampaignLeadsBacklogFilter
                    anchor={anchor}
                    filterState={backlogFilterState}
                    setFilterState={handleBacklogFilterState}
                    getFilteredBacklogList={getFilterBacklogList}
                    getAllBacklogList={getAllBacklogListData}
                    parent={"drawer"}
                    handleClose={toggleDrawer(anchor, false)}
                    setSelectedFilter={setSelectedFilterBacklog}
                    setbacklogLeadReq={setbacklogLeadReq}
                  />
                }
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </span>
      </TabPanel>
    </>
  );
}


const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
    getLeadCsvFile,
    activateLoader,
    deActivateLoader,

  })(CampaignLeadListing)
);