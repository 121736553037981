import Dynamicform from "f1-dynamic-form-react";
import React, { Component } from "react";
import Loader from "../../../../components/loader/loader";
import MessageBar from "../../../../components/messageBar/messageBar";
import Footer from "../../../../layouts/footer/footer";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  getCallBackReasonList,
  getDroppedReasonList,
  getGCLCallDurationList,
  getFollowupReasonList,
  getLeadListById,
  getRescheduleReasonList,
  getTeamList,
  getVehicleTypeList,
  ModeofTransportList,
  UpdateLeadForm,
  validateMeetingDateStatus,
  validateIsCheckin,
  UpdatePersistancyLeadForm,
  UpdateCampaignLeadForm,
  getPersistancyLeadListById,
  validateMeetingDateStatusPersistancy,
  getCampaginLeadListById,
} from "../../../../redux-reducer/reducer";
import {
  activateLoader,
  deActivateLoader,
  uploadFileToBlob,
  activateErrorMessageBar,
} from "../../../../redux/actions";
import {
  customValidators,
  getForm,
  handleDropdownSelection,
} from "./updateLeadFormGCL";
import {
  CALLBACK,
  checkInConfig,
  CONFIGS,
  FOLLOWUP,
  FORMSTATUSID,
  FOUR_WHEELER,
  MEETINGSCHEDULE,
  PUBLIC_TRANSPORT,
  RESCHEDULE,
  ROLE_BDM,
  SELF_OWNED_TRANSPORT,
  TWO_WHEELER,
  LEAD,
  PERSISTANCY,
  CAMPAIGNLEAD,
  ASSIGN,
} from "../../../../utils/config";
import {
  LEADS_PATH,
  PERSISTANCY_LEAD_PATH,
  CAMPAIGN_LEAD_PATH,
} from "../../../../route/routePath";
import moment from "moment";
import Breadcrumb from "../../../../components/breadcrumb/breadcrumb";
import { setBreadcrumbs } from "../../../../redux/actions/common";
import "./updateLead.scss";
import {
  getLeadOffline,
  updateLeadOffline,
} from "../../../../offlineManager/leadOfflineManager";
// import { format } from 'date-fns';
import { checkDateIsValid } from "../../../../utils/validators";
const _formatDate = "YYYY-MM-DDTHH:mm";
const createformat = "YYYY-MM-DD HH:mm:ss";
const selectOption = [{ key: "Select", label: "Select", value: "" }];
class updateLeadMSME extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
    this.leadType = this.props.match.params.leadType;
    console.log("===============>",this.props.match.params)
    this.userData = JSON.parse(localStorage.getItem("persist:root"));
    this.userId = JSON.parse(this.userData?.userReducer)?.profileInfo?.userId;
    this.employeeCode = JSON.parse(
      this.userData.userReducer
    ).profileInfo.employeeCode;

    this.formBuilder = this.props.match.params.updateAction;
    this.channelId = this.props.userState.profileInfo.channelid;

    this.dyFormRef = React.createRef();
    this.state = {
      defaultFormVal: {
        id: 1,
        MeetingStartDateTime: moment(new Date()).format(_formatDate),
        MeetingEndDateTime: moment(new Date()).add(30, "m").format(_formatDate),
        IsClaimRequired: false,
        LoanDisbursementDate: moment(new Date()).endOf('day').format("YYYY-MM-DD"),
        GCLDateOfIssue: moment(new Date()).endOf('day').format("YYYY-MM-DD"),
        MedicalcompletedDate: moment(new Date()).endOf('day').format("YYYY-MM-DD"),
        MedicalScheduledDate: moment(new Date()).endOf('day').format("YYYY-MM-DD")
      },
      autoCompleteItems: {
        AssignedTo: [],
      },
      current: {
        id: 1,
      },
      formData: getForm(this.formBuilder),
      extErrors: {},
      uploadedFile: "",
      uploadedDGHFile: "",
      startMeetingDate: moment(new Date()).format(_formatDate),
      endMeetingDate: moment(new Date()).add(30, "m").format(_formatDate),
      leadData: null,
      TravelExpense: "",
      isSubmitbuttonDisable: false,
    };
  }

  loadBreadCrumbs = () => {
    let label;
    if (this.leadType === LEAD) {
      label = "Lead";
    } else if (this.leadType === PERSISTANCY) {
      label = "Persistency Lead";
    } else {
      label = "Campaign Lead";
    }

    const { lead_action_list } = this.props.updateLeadData;

    let lead = ""

    lead_action_list.forEach((ele) => {
      if(this.formBuilder === ele.LeadActionCode){
        lead = ele.LeadActionName
      }
    })

    const breadcrumbs = [
      { name: label, url: `/app/${this.leadType}/all` },
      { name: lead, url: "#" },
    ];
    this.props.setBreadcrumbs(breadcrumbs);
  };

  getDropdownData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr?.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    arr.unshift({ key: "select", label: "select", value: "" });
    return arr;
  };

  renderRadioIcons = (radioOption) => {
    let icon = "";
    if (radioOption.key === PUBLIC_TRANSPORT) {
      icon = <DirectionsBusIcon></DirectionsBusIcon>;
    } else if (radioOption.key === SELF_OWNED_TRANSPORT) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === TWO_WHEELER) {
      icon = <TwoWheelerIcon></TwoWheelerIcon>;
    } else if (radioOption.key === FOUR_WHEELER) {
      icon = <DirectionsCarIcon></DirectionsCarIcon>;
    }
    return icon;
  };

  getRadioData = (optionsArr, key, label, value) => {
    let arr = [];
    if (optionsArr && optionsArr.length > 0) {
      arr = optionsArr.map((option) => {
        return { key: option[key], label: option[label], value: option[value] };
      });
    }
    return arr;
  };
  componentWillMount() {
    // this.setState({
    //   autoCompleteItems: this.props.updateLeadData?.AssignedTo,
    // });
  }

  initFormData = async (formFields, actionType, role = ROLE_BDM) => {
    this.props.activateLoader();
    if (actionType === ASSIGN){
      this.setState({
        autoCompleteItems: this.props.updateLeadData?.AssignedTo
      })
    }
    let formData = [...formFields];
    const {GCLCallDuration} = this.props.masterDataState
    formData.find((val, index) => {
      if (val.key === "TransportModeID" || val.key === "VehicleTypeID"  || val.key === "TravelExpense" || val.key === "UploadBill") {
        formData[index].hide = true;
      }
      if (val.key==="TransportModeID"){
        formData[index].options =this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.MODE_OF_TRANSPORT.TABLE_NAME];
      }
      if (val.key==="VehicleTypeID"){
        formData[index].options = this.props.masterDataState[CONFIGS.MASTER_DATA_TABLES.VEHICLE_TYPE.TABLE_NAME];
      }
      if (val.key == "GCLCallDurationId"){
        formData[index].options = GCLCallDuration
      }
    })
    this.props.deActivateLoader();
    return formData;
  };

  getOptionActivityDuration = (optionList) => {
    const options = optionList.map((item) => {
      return {
        key: item["ID"],
        label: `${item["Name"]}`,
        value: item["ID"],
      };
    });
    return [...selectOption, ...options];
  };

  getGCLCallDuration = async () => {
    this.props.activateLoader();
    const res = await this.props.getGCLCallDurationList();
    this.props.deActivateLoader();
    console.log("res", res);
    return res;
  };

  fetchLeadOffline = () => {
    const leadId = this.props.match.params.id;
    getLeadOffline(leadId).then((res) => {
      if (res) {
        this.setState({ leadData: res });
      }
    });
  };
  async componentDidMount() {
    // console.log("===============>",this.id)
    if (this.props.match.params.id) {
      if (this.leadType === LEAD) {
        this.props.getLeadListById(this.id);
      } else if (this.leadType === PERSISTANCY) {
        this.props.getPersistancyLeadListById(this.id);
      } else {
        this.props.getCampaginLeadListById(this.id);
      }
    }
    this.props.deActivateLoader();
    this.loadBreadCrumbs();
    let formData = await this.initFormData(
      this.state.formData,
      this.formBuilder,
      this.props.userState.profileInfo.roleId
    );
    this.setState({ formData: formData });
    this.fetchLeadOffline();
  }

  reset = (model) => {
    for (const [key] of Object.entries(model)) {
      model[key] = "";
    }
    if (this.leadType === LEAD) {
      this.props.history.push(LEADS_PATH);
    }
    if (this.leadType === PERSISTANCY) {
      this.props.history.push(PERSISTANCY_LEAD_PATH);
    }
    if (this.leadType === CAMPAIGNLEAD) {
      this.props.history.push(CAMPAIGN_LEAD_PATH);
    }
    return true;
  };
  onSubmitHandler = (model) => {
    // !model.isValidForm || 
    const ref = this.dyFormRef.current.state?.ReasonForDropping
      if(ref !== "Other"){
        delete model.errors["OtherReason1"]
        delete model["OtherReason1"]
      }
    if (Object.keys(model.errors).length > 0 || Object.keys(this.state.extErrors).length > 0) {
      if (model.PaymentTypeID !== "3") {
        return false;
      }
    }
    let formData;
    if (this.leadType === LEAD) {
      formData = navigator.onLine
        ? { ...this.props.getLeadData.getLeadData }
        : { ...this.state.leadData };
    }

    formData["Remarks"] = null;
    model.PaymentTypeID === "1"
      ? (formData["PaymentSubType"] = model.PaymentSubType)
      : (formData["PaymentSubType"] = "");
    for (let key in model) {
      formData[key] = model[key];
    }
    delete formData["advanceLayoutFields"];
    delete formData["collapseStates"];
    delete formData["errors"];
    delete formData["isValidForm"];
    delete formData["Paymentname"];
    delete formData["id"];
    formData["IsCheckin"] = false;
    if (formData["IsCheckin"] && formData["IsClaimRequired"]) {
      this.props.activateErrorMessageBar("Lead is not updated");
      return false;
    }
    formData["IsLmsSync"] = navigator.onLine;
    formData["LmsleadlastSyncTime"] = moment(new Date()).format(createformat);
    formData["IsBacklog"] = false;
    if (formData.hasOwnProperty("UploadBill")) {
      formData["UploadBill"] = this.state.uploadedFile;
    }
    if (formData.hasOwnProperty("UploadMemberForm")) {
      formData["UploadMemberForm1"] = this.state.uploadMemberForm[0]?.name;
      formData["UploadMemberForm2"] = this.state.uploadMemberForm[1]?.name;
      formData["UploadMemberForm3"] = this.state.uploadMemberForm[2]?.name;
    }
    if (formData.hasOwnProperty("UploadDGHPhoto")) {
      formData["UploadDGHPhoto"] = this.state.uploadedDGHFile;
    }
    formData["LeadStatusId"] = FORMSTATUSID[this.formBuilder];
    formData["LeadStatus"] = this.formBuilder;

    formData["UserId"] = this.userId;
    formData["ChannelID"] = parseInt(this.channelId);
    formData["AssignedTo"] = this.userId;
    formData["EmployeeCode"] = this.employeeCode;
    const validateMeetingStatusForm = [
      RESCHEDULE,
      MEETINGSCHEDULE,
      CALLBACK,
      FOLLOWUP,
    ];
    if (model.IsClaimRequired) {
      formData["TransportID"] = model.TransportModeID;
      formData["TransportModeID"] = parseInt(model.TransportModeID);
      formData["TravelExpense"] = model.TravelExpense;
      formData["TransportMode"] =
        model.TransportModeID === "1"
          ? "Public Transport"
          : "Self Owned Transport";
    }
    // return false;
    if (validateMeetingStatusForm.includes(this.formBuilder)) {
      if (this.state.startMeetingDate) {
        formData["MeetingStartDateTime"] = this.state.startMeetingDate.replace(
          "T",
          " "
        );
      } else {
        formData["MeetingStartDateTime"] = null;
      }
      if (this.state.endMeetingDate) {
        formData["MeetingEndDateTime"] = this.state.endMeetingDate.replace(
          "T",
          " "
        );
      } else {
        formData["MeetingEndDateTime"] = null;
      }
    } else {
      formData["MeetingStartDateTime"] = null;
      formData["MeetingEndDateTime"] = null;
    }
    if (formData["LeadStatus"] === "DROPPED") {
      formData["MeetingStartDateTime"] = formData["MeetingStartDateTime"]
        ? moment(formData["MeetingStartDateTime"]).format("YYYY-MM-DD HH:mm:ss")
        : null;
      formData["MeetingEndDateTime"] = formData["MeetingEndDateTime"]
        ? moment(formData["MeetingEndDateTime"]).format("YYYY-MM-DD HH:mm:ss")
        : null;
    }
    if (formData.hasOwnProperty("OtherReason1")) {
      formData["OtherReason"] = formData["OtherReason1"];
    }
    this.props.activateLoader();
    if (this.leadType === LEAD) {
      if (validateMeetingStatusForm.includes(this.formBuilder)) {
        if (navigator.onLine) {
          this.props.validateMeetingDateStatus({
            startDate: this.state.startMeetingDate,
            endDate: this.state.endMeetingDate,
            id: this.userId, // (this.leadType === LEAD) ? this.userId : ,
            formData: formData,
            formBuilder: this.formBuilder,
            history: this.props.history,
            leadType: 1,
          });
          // this method will update the lead in indexDb for offline version
          updateLeadOffline(formData);
        } else {
          updateLeadOffline(formData)
            .then((res) => {
              this.props.history.push(LEADS_PATH);
            })
            .catch((err) => {
              console.log(err);
              this.props.history.push(LEADS_PATH);
            });
        }
      } else {
        if (navigator.onLine) {
          this.props.UpdateLeadForm({
            model: formData,
            formBuilder: this.formBuilder,
            history: this.props.history,
          });
        } else {
          // update lead in offline
          updateLeadOffline()
            .then((res) => {
              this.props.history.push(LEADS_PATH);
            })
            .catch((err) => {
              console.log(err);
              this.props.history.push(LEADS_PATH);
            });
        }
      }
    } else {
      if (validateMeetingStatusForm.includes(this.formBuilder)) {
        if (navigator.onLine) {
          if (validateMeetingStatusForm.includes(this.formBuilder)) {
            this.props.validateMeetingDateStatusPersistancy({
              startDate: this.state.startMeetingDate,
              endDate: this.state.endMeetingDate,
              id: this.userId,
              formData: formData,
              formBuilder: this.formBuilder,
              history: this.props.history,
              leadType: this.leadType,
            });
          }
        }
      // } else {
      //   if (navigator.onLine) {
      //     if (this.leadType === PERSISTANCY) {
      //       this.props.UpdatePersistancyLeadForm({
      //         model: formData,
      //         formBuilder: this.formBuilder,
      //         history: this.props.history,
      //       });
      //     } else {
      //       this.props.UpdateCampaignLeadForm({
      //         model: formData,
      //         formBuilder: this.formBuilder,
      //         history: this.props.history,
      //       });
      //     }
      //   }
      // }
    }

    if (this.props.updateLeadData.updateLead.responsestatus === true) {
      this.reset(formData);
    }
  }
}

  onChangeHandler = async (event, key, model) => {
    // this will enable the submit button
    if (key === "UploadBill") {
      this.uploadFile(event, key);
    }
    if (key === "UploadDGHPhoto") {
      this.uploadFile(event, key);
    }
    if (key === "UploadMemberForm") {
      delete model.errors["UploadMemberForm"];
      event.forEach((element) => {
        this.uploadMemberFile(element, event);
      })
    }

    if(key === "ReasonForDropping"){
      if(event.target.value === "Other"){
        this.state.formData[1].hide = false
      } else {
        this.state.formData[1].hide = true
      }
    }

    const formData = handleDropdownSelection(
      event,
      key,
      this.state.formData,
      this.props.match.params.updateAction,
      this.props.masterDataState,
      this.props.updateLeadData,
      this.props.userState.profileInfo.roleId
    );
    const isExpense = formData && formData.length > 0 && formData.find((data) => data.key === "TransportModeID");
    const isVehicleType = formData && formData.length > 0 && formData.find((data) => data.key === "VehicleTypeID");
    const isTravelExpense = formData && formData.length > 0 && formData.find((data) => data.key === "TravelExpense");
    const isUploadBill = formData && formData.length > 0 && formData.find((data) => data.key === "UploadBill");
    this.setState({ formData: formData });

    switch (key) {
      case "MeetingStartDateTime":
        this.setState({
          extErrors: { MeetingEndDateTime: null, MeetingStartDateTime: null },
        });
        delete model.errors["MeetingEndDateTime"];

        var startDate = moment(event.target.value).format(_formatDate);
        this.state.formData.map((option) => {
          if (option.key === "MeetingEndDateTime") {
            option.max = moment(startDate)
              .endOf("day")
              .format("YYYY-MM-DD kk:mm");
            option.min = startDate;
          }
        });

        this.setState({ startMeetingDate: startDate.replace("T", " ") });
        const endMeetDate = moment(startDate).add(30, "m").toString();
        if (
          !checkDateIsValid(
            event.target.value,
            moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
          )
        ) {
          this.setState({
            startMeetingDate: startDate.replace("T", " "),
            extErrors: {
              ...this.state.extErrors,
              MeetingStartDateTime: "please select future value",
            },
          });
        } else {
          this.setState({
            defaultFormVal: {
              MeetingEndDateTime: moment(endMeetDate).format(
                "YYYY-MM-DDTHH:mm:ss"
              ),
            },
            extErrors: {},
          });
          this.setState({
            endMeetingDate: moment(endMeetDate)
              .format("YYYY-MM-DDTHH:mm:ss")
              .replace("T", " "),
          });
        }

        break;
      case "MeetingEndDateTime":
        const endDate = event.target.value;
        this.setState({ endMeetingDate: endDate.replace("T", " ") });
        this.setState({ ...this.state, extErrors: {} });
        if (
          !checkDateIsValid(
            event.target.value,
            this.state.current.MeetingStartDateTime
          )
        ) {
          this.setState({
            defaultFormVal: {
              MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
            },
            extErrors: {
              ...this.state.extErrors,
              MeetingEndDateTime:
                "meeting end date should not less than meeting starting date",
            },
          });
        } else {
          this.setState({
            defaultFormVal: {
              MeetingEndDateTime: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
            },
            extErrors: {},
            endMeetingDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss"),
          });
        }

        break;
      case "IsClaimRequired":
        if (event === false){
           formData.find((val, index)=>{
            if (val.key==="VehicleTypeID"){
              formData[index].validators =  []
            }
            if (val.key==="TravelExpense"){
              formData[index].validators =  []
            }
            if (val.key==="TransportModeID"){
              formData[index].validators =  []
            }
          })
        }
        if (event === true) {
          this.setState({
            defaultFormVal: {
              IsClaimRequired: true,
            },
          });
          isExpense.hide = false;
          let checkIn;
              checkIn = await this.props.validateIsCheckin({ checkinFor: LEAD, Id: this.props.match.params.id })
          const { data } = checkIn
          // const data = true;
            if (data === true){
            formData.find((val, index) => {
            if (val.key === "TransportModeID"){
            formData[index].validators = [
              {
                name: "required",
                value: true,
                validationMessage: "This field is required",
              }]
              }
            })
          }
          if (data) {
            this.setState({
              defaultFormVal: {
                IsClaimRequired: data,
                TransportModeID: null,
                TravelExpense: null,
              UploadBill: null,
              VehicleTypeID: null
              },
            });
            isExpense.hide = false;
            // isVehicleType.hide = false
            // isTravelExpense.hide = false
            // isUploadBill.hide = false

            // const errorElement = document.querySelectorAll("input[type=radio]");
            // errorElement[0].checked = false;
            // errorElement[1].checked = false;

            const errorElement1 =
              document.getElementsByClassName(`activeRadio`);
            if (errorElement1.length > 0) {
              errorElement1[0].classList.remove("activeRadio");
            }
          } else {
            this.setState({
              defaultFormVal: {
                IsClaimRequired: false,
              },
            });
            isExpense.hide = true;
            isVehicleType.hide = true;
            isTravelExpense.hide = true;
            isTravelExpense.validators = [];
            isUploadBill.hide = true;
            this.props.activateErrorMessageBar(
              "Please Check-In first to claim the expenses"
            );
            delete model.errors["UploadBill"];
          }
        } else {
          this.setState({
            defaultFormVal: {
              IsClaimRequired: false,
            },
          });
          isExpense.hide = true;
          isVehicleType.hide = true;
          isTravelExpense.hide = true;
          isUploadBill.hide = true;
        }

        break;
      case "TransportModeID":
        const TransportModeID = event.target.value;
        if (TransportModeID === "1") {
          // isVehicleType.hide = true;
          isTravelExpense.hide = false;
          isUploadBill.hide = false;
          this.setState({
            defaultFormVal: {
              VehicleTypeID: null,
            },
          });
        } else {
          // isVehicleType.hide = true;
          isTravelExpense.hide = true;
          isUploadBill.hide = true;
          this.setState({
            defaultFormVal: {
              TravelExpense: null,
              UploadBill: null,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  uploadFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await this.props.uploadFileToBlob({ file: files[0] });
      const { name } = files[0];
      if (fileRes.status === 200) {
        if (key === "UploadDGHPhoto") {
          this.setState({ uploadedDGHFile: name });
        } else {
          this.setState({ uploadedFile: name });
        }
      }
    } else {
      this.props.activateErrorMessageBar(
        "Cannot upload files in offline mode!"
      );
    }
  };

  uploadMemberFile = async (files, key) => {
    if (navigator.onLine) {
      const fileRes = await this.props.uploadFileToBlob({ file: files });
      if (fileRes.status === 200) {
        this.setState({ uploadMemberForm: key });
      }
    } else {
      this.props.activateErrorMessageBar(
        "Cannot upload files in offline mode!"
      );
    }
  };

  removeFile = (e, file, index, key) => {
    this.dyFormRef.removeFile(e, file, index, key);
    this.setState({ uploadedFile: "", uploadedDGHFile: "" });
  };

  renderUploadedFilesItem = (file, index, key) => {
    return (
      <li style={{ borderColor: "#ccc" }} key={index}>
        <span className="file-name">{file.name}</span>
        <span
          className="action"
          onClick={(e) => this.removeFile(e, file, index, key)}
        >
          <i className="fa fa-trash"></i>
        </span>
      </li>
    );
  };

  onCancelHandler = () => {
    this.props.history.push(`/app/${this.leadType}/detail/${this.id}`);
  };

  onAutocompleteSelectHandler = (key, value, item) => {};

  onCheckinHandler = (position, address) => {};

  render() {
    const { getLeadData, getPersistancyLeadData, getCampaginLeadData } =
      this.props.getLeadData;
    let label;
    if (this.leadType === LEAD) {
      label = `${getLeadData.Salutation} ${getLeadData.FirstName} ${getLeadData.LastName}`;
    }
    if (this.leadType === PERSISTANCY) {
      label = getPersistancyLeadData.CustomerName;
    }
    if (this.leadType === CAMPAIGNLEAD) {
      label = `${getCampaginLeadData.SalutationName} ${getCampaginLeadData.FirstName} ${getCampaginLeadData.LastName}`;
    }

    return (
      <div className="mainContainer updateLead">
        <MessageBar />
        <Breadcrumb />
        <span className="formInfo">{label}</span>
        <div className="formBox">
          <Dynamicform
            key={this.state?.current?.id}
            onRef={(ref) => (this.dyFormRef.current = ref)}
            className="updateTypeDiv"
            buttonSubmit="Update"
            defaultValues={this.state.defaultFormVal}
            model={this.state.formData}
            onSubmit={(model) => {
              this.onSubmitHandler(model);
            }}
            disableSubmitOnInValid={this.state.isSubmitbuttonDisable}
            design="bootrstap"
            materialOutline={false}
            onChange={(event, key, model) =>
              this.onChangeHandler(event, key, model)
            }
            buttonNormalClick={(id, key, obj) => console.log(id, key, obj)}
            showAstersikOnRequiredFields={true}
            fileUploaderFields={["UploadBill", "UploadDGHPhoto"]}
            renderUploadedFilesItem={this.renderUploadedFilesItem}
            onAutocompleteSelect={this.onAutocompleteSelectHandler}
            autoCompleteItems={this.state.autoCompleteItems}
            extErrors={this.state.extErrors}
            checkInConfig={checkInConfig}
            onCheckinHandler={this.onCheckinHandler}
            extaraActionFormButton={
              <>
                <button
                  className="btn cancelBtn"
                  key="cancel"
                  id="cancel"
                  type="button"
                  onClick={this.onCancelHandler}
                >
                  {"Cancel"}
                </button>
              </>
            }
            renderRadioIcons={(option) => this.renderRadioIcons(option)}
            customValidators={customValidators}
          />
        </div>
        <Footer></Footer>
        {this.props.masterDataState.isActiveLoader && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  updateLeadData: state.updateLeadReducer,
  getLeadData: state.leadFormReducer,
  masterDataState: state.commonReducer,
  userState: state.userReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getPersistancyLeadListById: (payload) =>
    dispatch(getPersistancyLeadListById(payload)),
  getLeadListById: (payload) => dispatch(getLeadListById(payload)),
  activateLoader: (payload) => dispatch(activateLoader(payload)),
  getCallBackReasonList: (payload) => dispatch(getCallBackReasonList(payload)),
  getRescheduleReasonList: (payload) =>
    dispatch(getRescheduleReasonList(payload)),
  ModeofTransportList: (payload) => dispatch(ModeofTransportList(payload)),
  getVehicleTypeList: (payload) => dispatch(getVehicleTypeList(payload)),
  getFollowupReasonList: (payload) => dispatch(getFollowupReasonList(payload)),
  getGCLCallDurationList: (payload) =>
    dispatch(getGCLCallDurationList(payload)),
  UpdateLeadForm: (payload) => dispatch(UpdateLeadForm(payload)),
  validateMeetingDateStatus: (payload) =>
    dispatch(validateMeetingDateStatus(payload)),
  validateMeetingDateStatusPersistancy: (payload) =>
    dispatch(validateMeetingDateStatusPersistancy(payload)),
  getTeamList: (props) => dispatch(getTeamList(props)),
  deActivateLoader: (payload) => dispatch(deActivateLoader(payload)),
  setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
  getDroppedReasonList: (payload) => dispatch(getDroppedReasonList(payload)),
  validateIsCheckin: (payload) => dispatch(validateIsCheckin(payload)),
  activateErrorMessageBar: (payload) =>
    dispatch(activateErrorMessageBar(payload)),
  UpdatePersistancyLeadForm: (payload) =>
    dispatch(UpdatePersistancyLeadForm(payload)),
  uploadFileToBlob: (payload) => dispatch(uploadFileToBlob(payload)),
  getCampaginLeadListById: (payload) =>
    dispatch(getCampaginLeadListById(payload)),
  UpdateCampaignLeadForm: (payload) =>
    dispatch(UpdateCampaignLeadForm(payload)),
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps)(updateLeadMSME)
);
