import React, { useEffect, useState } from "react";
import { getCampaignActivityListing, getSalesMangmentActivityListing } from "../../../redux/actions/updateActivityAction";
import { useHistory, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {
  activateLoader,
  deActivateLoader,
  getSubordinateList,
  downloadCampaignActivitiesWithId,
  NHList,
  downloadAbhSmaActivitiesWithId,
  getFilterByLevel,
  downloadGCLCampaignActivitiesWithId
} from "../../../redux/actions/common";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DynamicForm from "f1-dynamic-form-react";
import { filterFormData } from "./campaignActivityGridfilterForm";
import { ADMIN_HIERARCHY_TYPE } from "../../../utils/config";
import "../../../components/scheduleActivityTable/tableNcard.scss";
import {
  activateErrorMessageBar,
  setFilterBy,
  getMasterDataForAdminByChannelId,
  getCampaignName,
  downloadCampaignActivities,
  downloadBrocaActivities,
  downloadAbhSmaActivities,
  downloadGCLActivities
} from "../../../redux/actions";
import useOnlineStatus from "../../../hooks/useOnlineStatus";
import { isBrowser, isMobile } from "react-device-detect";
import ModalPopup from "f1-modal-react";
import MatDatagrid from "f1-mat-data-grid-react";
import { downloadFilesAdmin } from "../../../utils/utils";
import DataGridModalPopup from "./../../datagridpopup";
import { Paginator } from "./../../paginator";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  drawerMobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopFilter: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const date = new Date();
const backThreeMonth = date.setMonth(date.getMonth() - 3);
const getBackThreeMonth = moment(backThreeMonth)

const Activitytypeidpayload = "1,2,3,4,5,7,8,9,10,12,13,14,15,45,46,47,48,49,50,51,52,53,19,20,21,22,54,55,56,57,58,59,60,61,63,64,65,66,67,68";
const ActivityStatusIdpayload = "1,2,3,4,5,9,10,11,12,13,17,18,19,20,21,25,26,27,33,34,35,36,25,26,27,28,40,41,42,43,44,46,47,48,49,50";
const smaActivitytypeidpayload = "26,27,28,29,30,31";
const samActivityStatusIdpayload = "1,2,3,4";
const ActivityListing = (props) => {

  const commonState = useSelector((state) => state);
  let location = useLocation();
  const isSMARoute = location.pathname.includes("smaActivities");
  const [isForSMA] = useState(isSMARoute);
  const classes = useStyles();
  const [stateSelect, setStateSelect] = useState({ top: false });
  const [campaignname, setCampaignName] = useState([]);
  const [NhUsers, setNhUsers] = useState([]);
  const [RBHUsers, setRBHUsers] = useState([]);
  const [ABHUsers, setABHUsers] = useState([{
    key: "",
    label:"select",
    value: '',
  }]);

  const [UserID, setUserID] = useState();
  const userData = JSON.parse(localStorage.getItem("persist:root"));
  const roleId = JSON.parse(userData.userReducer).profileInfo.roleId;
  const dispatch = useDispatch();
  const userState = commonState.userReducer;
  const [activitiesByUserTable, setActivitiesByUserTable] = useState([]);
  const history = useHistory();
  const [UserId] = useState(null);
  const fulln = `${userState.profileInfo.firstName} ${userState.profileInfo.lastName}`;
  const [UserFullName] = useState(fulln);
  const [currentStatus, setCurrentStatus] = useState([]);
  const [showModal] = useState(true);
  const [activityChannelWise, setActivityChannelWise] = useState([]);
  const { page1 } = commonState.updateActivityReducer.activityLandingPage;
  let activepage;
  if (page1 !== 0 && commonState.updateActivityReducer.filterby === "planned") {
    activepage = 2;
  } else {
    activepage = 0;
  }
  const [tabValue] = React.useState(activepage);
  const isOnline = useOnlineStatus();
  const chanelId = props.ChannelId;
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  let [rowPerPage, setRowPerPage] = useState(20);
  let [rowChangeEvent, setRowChangeEvent] = useState(0);
  let [totalCount, setTotalCount] = useState(0);
  let [initialPageNo, setInitialPageNo] = useState(0);
  const isBroca = history?.location?.pathname === "/app/admin/campaignactivity/broca/all" || chanelId === 21
  async function fetchActivityTableData(payload, ChannelId, page, rowPerPageCount) {
    if (isOnline) {
      props.activateLoader();
      let result;
      if (isSMARoute) {
        result = await props.getSalesMangmentActivityListing(
          payload,
          ChannelId,
          page,
          rowPerPageCount
        )
      } else {
        result = await props.getCampaignActivityListing(
          payload,
          ChannelId,
          page,
          rowPerPageCount
        );
      }
      if (result.status === 200) {
        var responseData = [];
        for (
          let i = 0;
          i < result.data.Getallcampaignactivitieslistbychannel.length;
          i++
        ) {
          if (
            result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate !==
            null
          ) {
            result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate =
              moment(
                result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate
              ).format("DD/MM/YYYY");
          }
          if (
            result.data.Getallcampaignactivitieslistbychannel[i]
              .LastUpdatedDate !== null
          ) {
            result.data.Getallcampaignactivitieslistbychannel[
              i
            ].LastUpdatedDate = moment(
              result.data.Getallcampaignactivitieslistbychannel[i]
                .LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          responseData.push(
            result.data.Getallcampaignactivitieslistbychannel[i]
          );
        }
        props.deActivateLoader();
        setActivitiesByUserTable(responseData);
        console.log(
          "result.data.Getallcampaignactivitieslistbychannel ",
          result.data.Getallcampaignactivitieslistbychannel
        );
        setTotalCount(result.data.TotalnoofRows);
        console.log("Activities are ", activitiesByUserTable);
      } else {
        props.deActivateLoader();
      }
    }
  }

  const clearSetFilterBy = async () => {
    await props.setFilterBy("");
  };

  useEffect(() => {
    // console.log("masterDatais", masterDataState, channelid);
    if (
      history?.location?.pathname === "/app/admin/campaignactivity/agency/all" || (history?.location?.pathname === "/app/report/activity/all" && chanelId === 3)
    ) {
      filterFormData.find(
        (item) => item.key === "branchNameAndCode"
      ).hide = true;
      filterFormData.find((item) => item.key === "CampaignName").hide = false;
    }
    if (
      history?.location?.pathname === "/app/admin/campaignactivity/banca/all" || (history?.location?.pathname === "/app/report/activity/all" && chanelId === 1) || history?.location?.pathname === "/app/admin/smaActivities/banca/all"
      || (history?.location?.pathname === "/app/report/smaActivities/all" && chanelId === 1)
    ) {
      filterFormData.find(
        (item) => item.key === "branchNameAndCode"
      ).hide = false;
      filterFormData.find((item) => item.key === "CampaignName").hide = false;
    }
    if (history?.location?.pathname === "/app/admin/activity/csc/all" || (history?.location?.pathname === "/app/report/activity/all" && chanelId === 2)) {
      filterFormData.find(
        (item) => item.key === "branchNameAndCode"
      ).hide = true;
      filterFormData.find((item) => item.key === "CampaignName").hide = true;
    }
    if (
      history?.location?.pathname === "/app/admin/campaignactivity/broca/all" || (history?.location?.pathname === "/app/report/activity/all" && chanelId === 21)
    ) {
      filterFormData.find(
        (item) => item.key === "branchNameAndCode"
      ).hide = false;
      filterFormData.find((item) => item.key === "CampaignName").hide = true;
    }
    if (
      history?.location?.pathname === "/app/report/activity/all" && chanelId === 13
    ) {
      filterFormData.find(
        (item) => item.key === "branchNameAndCode"
      ).hide = false;
      filterFormData.find((item) => item.key === "CampaignName").hide = true;
    }
    if (
      history?.location?.pathname === "/app/report/activity/all" && chanelId === 79
    ) {
      filterFormData.find(
        (item) => item.key === "branchNameAndCode"
      ).hide = false;
      filterFormData.find((item) => item.key === "CampaignName").hide = true;
    }
    if (history?.location.pathname === "/app/admin/campaignactivity/gcl/all" && chanelId === 13) {
      filterFormData.find(
        (item) => item.key === "branchNameAndCode"
      ).hide = false;
      filterFormData.find((item) => item.key === "CampaignName").hide = true;
    }

    const payload = {
      CreateStartDate: null,
      CreateEndDate: null,
      MeetingStartDate: null,
      MeetingEndDate: null,
      CurrentStatus: isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
      ActivityName: null,
      ActivityType: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
      UserId: null,
      BranchId: null,
      CampaignName: null,
      EmployeeCode: null,
    };
    if (userState.profileInfo.roleId !== "ADM") {
      payload.UserId = userState.profileInfo.userId;
      setActivityReq({
        ...activityReq,
        UserId: userState.profileInfo.userId,
      });
    }
    if (isOnline) {
      fetchActivityTableData(payload, props.ChannelId, 1, rowPerPage);
    }
  }, []);
  const [newUserList, setNewUserList] = useState([
    {
      key: UserId,
      label: UserFullName,
      value: UserId,
    },
  ]);

  const [filterState, setFilterState] = useState({
    activityType: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload, //"1,2,3,4,5"
    branchNameAndCode: null,
    createdOn: "null",
    meetingOn: "null",
    activityStatus: isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
    CreatedDateStartTIme: "null",
    CreatedDateEndTIme: "null",
    fromdate: "null",
    todate: "null",
    Activitytypeid: null,
    LeadTypeID: [],
    CampaignName: null,
    UserID: null,
    EmployeeCode: null,
  });

  const [activityReq, setActivityReq] = useState({
    CreateStartDate: null,
    CreateEndDate: null,
    MeetingStartDate: null,
    MeetingEndDate: null,
    CurrentStatus: isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
    ActivityName: null,
    ActivityType: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
    UserId: null,
    BranchId: null,
    CampaignName: null,
    EmployeeCode: null,
  });

  useEffect(() => {
    const getActivityStatusChannelId = async () => {
      const response = await props.getMasterDataForAdminByChannelId({
        tableName: "ActivityStatus",
        channelId: props.ChannelId,
        config: {
          TABLE_NAME: "ActivityStatus",
          KEY: "ActivityStatusId",
          LABEL: "ActivityStatus",
          CODE: "ActivityStatusCode",
          CHANNEL_ID: "",
          FIELD_NAME: "",
        },
      });
      const data = response.data.data.ActivityStatus;
      setCurrentStatus(data);
      console.log("current Status ", currentStatus);
    };
    if (!currentStatus.length) {
      getActivityStatusChannelId();
    } else if (currentStatus.length) {
      getOptionData();
      console.log("current Status is ", currentStatus);
    }
  }, [currentStatus]);

  const getActivityByChannelId = async () => {
    const response = await props.getMasterDataForAdminByChannelId({
      tableName: "Activities",
      channelId: props.ChannelId,
      config: {
        TABLE_NAME: "Activities",
        KEY: "ActivityId",
        LABEL: "ActivityName",
        CODE: "ActivityCode",
        CHANNEL_ID: "ChannelID",
      },
    });
    const data = response.data.data.Activities;

    console.log("activities ", data);
    setActivityChannelWise(data);

  };

  useEffect(() => {
    // const getActivityByChannelId = async () => {
    //   debugger
    //   const response = await props.getMasterDataForAdminByChannelId({
    //     tableName: "Activities",
    //     channelId: props.ChannelId,
    //     config: {
    //       TABLE_NAME: "Activities",
    //       KEY: "ActivityId",
    //       LABEL: "ActivityName",
    //       CODE: "ActivityCode",
    //       CHANNEL_ID: "ChannelID",
    //     },
    //   });
    //   const data = response.data.data.Activities;

    //   console.log("activities ", data);
    //   setActivityChannelWise(data);

    // };
    if (!activityChannelWise.length) {
      getActivityByChannelId();
    } else if (activityChannelWise.length) {
      getOptionData();
      console.log("activities are ", activityChannelWise);
    }
  }, [activityChannelWise]);

  const getcampaign = async () => {
    const result = await props.getCampaignName({
      ChannelId: props.ChannelId,
      CampaignNameFor: "CampaignActivity",
    });
    console.log("campaign name", result);
    const r = result.data.map((el) => {
      return {
        key: el.CampaignName,
        label: el.CampaignName,
        value: el.CampaignName,
      };
    });
    setCampaignName([...campaignname, ...r]);
    filterFormData[17].options = [...campaignname, ...r];
  };

  useEffect(() => {
    filterFormData[0].options = ADMIN_HIERARCHY_TYPE;
    NhUsers.push({ key: "Select", label: "Select", value: "Select" });
    RBHUsers.push({ key: "Select", label: "Select", value: "" });
    campaignname.push({ key: "Select", label: "Select", value: "Select" });
    getNhList();
    filterFormData[1].hide = true;
    filterFormData[2].hide = true;
    filterFormData[3].hide = true;
    filterFormData[4].hide = true;
    filterFormData[5].hide = true;
    filterFormData[6].hide = true;
    filterFormData[7].hide = true;
    filterFormData[11].hide = true;
    filterFormData[12].hide = true;
    filterFormData[14].hide = true;
    filterFormData[15].hide = true;
    getcampaign();
    if (isBroca) {
      getRBHList()
    }else if(chanelId == 13){
      getABHList()
    }
    getActivityByChannelId();

  }, []);

  const getSub = async (key, getUser) => {
    const result = await props.getSubordinateList({
      userId: getUser,
    });

    const r = result.data.map((el) => {
      return {
        key: el.userID,
        label: el.userName,
        value: el.userID,
      };
    });
    setTimeout(() => {
      setNewUserList([newUserList, ...r]);
      props.deActivateLoader();
    });

    if (key === "User") {
      filterFormData[4].options = [newUserList, ...r];
    } else if (key === "UserOne") {
      if (chanelId === 2) {
        filterFormData[6].options = [newUserList, ...r];
      } else {
        filterFormData[5].options = [newUserList, ...r];
      }
    } else if (key === "UserTwo") {
      filterFormData[6].options = [newUserList, ...r];
    } else if (key === "UserThree") {
      filterFormData[7].options = [newUserList, ...r];
    }
  };

  const getNhList = async () => {
    const result = await props.NHList({ channelId: chanelId });
    console.log("nh users are", result.data);
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setNhUsers([...NhUsers, ...r]);
    filterFormData[3].options = [...NhUsers, ...r];
  };
  const getRBHList = async () => {
    const result = await props.getFilterByLevel({ userLevel: "RBH", channelId: chanelId });
    console.log("RBH users are", result.data);
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setRBHUsers([...RBHUsers, ...r]);
    filterFormData[5].options = [...RBHUsers, ...r];
  };
  const getABHList = async () => {
    const result = await props.getFilterByLevel({ userLevel: "ABH", channelId: chanelId });
    console.log("ABH users are", result.data);
    const r = result.data.map((el) => {
      return {
        key: el.UserID,
        label: el.FirstName + " " + el.LastName,
        value: el.UserID,
      };
    });
    setABHUsers([...ABHUsers, ...r]);
    filterFormData[6].options = [...ABHUsers, ...r];
  };

  const getOptionData = () => {
    filterFormData.map((item) => {
      if (item.name === "activityType") {
        let ACTIVITY_TYPE = [];
        if (
          history?.location?.pathname ===
          "/app/admin/campaignactivity/banca/all" || (chanelId === 1 && history?.location?.pathname === "/app/report/activity/all")
        ) {
          ACTIVITY_TYPE = activityChannelWise?.filter(
            (ele) =>
              ele.value === "" ||
              ele.value === "1" ||
              ele.value === "2" ||
              ele.value === "3" ||
              ele.value === "4" ||
              ele.value === "5" ||
              ele.value === "6" ||
              ele.value === "69"

          );
        }
        else if (
          history?.location?.pathname ===
          "/app/admin/smaActivities/banca/all" || (chanelId === 1 && history?.location?.pathname === "/app/report/smaActivities/all")
        ) {
          ACTIVITY_TYPE = activityChannelWise?.filter(
            (ele) =>
              ele.value === "" ||
              ele.value === "26" ||
              ele.value === "27" ||
              ele.value === "28" ||
              ele.value === "29" ||
              ele.value === "30" ||
              ele.value === "31"
          );
        }
        else if (
          history?.location?.pathname ===
          "/app/admin/campaignactivity/broca/all" || chanelId === 21
        ) {
          ACTIVITY_TYPE = activityChannelWise?.filter(
            (ele) =>
              ele.value === "" ||
              ele.value === "45" ||
              ele.value === "46" ||
              ele.value === "47" ||
              ele.value === "48" ||
              ele.value === "49" ||
              ele.value === "50" ||
              ele.value === "51" ||
              ele.value === "52" ||
              ele.value === "53" ||
              ele.value === "54" ||
              ele.value === "55"
          );
        }else {
          ACTIVITY_TYPE = activityChannelWise;
        }
        let filter_arr = ACTIVITY_TYPE;
        item.options = filter_arr;
      }
      if (item.name === "activityStatus") {
        const getDropdownData = (optionsArr, key, label, value) => {
          let arr = [];
          if (optionsArr && optionsArr.length > 0) {
            arr = optionsArr.map((option) => {
              return {
                key: option[key],
                label: option[label],
                value: option[value],
              };
            });
          }
          // arr.unshift({ key: "select", label: "Select", value: "" });
          return arr;
        };
        let ACTIVITY_STATUS = [];
        if (
          history?.location?.pathname ===
          "/app/admin/campaignactivity/banca/all" || chanelId === 1
        ) {
          ACTIVITY_STATUS = currentStatus?.filter(
            (ele) =>
              ele.value === "1" ||
              ele.value === "2" ||
              ele.value === "3" ||
              ele.value === "4"
          );
        }
        if (
          chanelId === 79
        ) {
          ACTIVITY_STATUS = currentStatus?.filter(
            (ele) =>
              ele.value === "40" ||
              ele.value === "41" ||
              ele.value === "42" ||
              ele.value === "43"
          );
        }
        if (
          history?.location?.pathname ===
          "/app/admin/campaignactivity/agency/all" || chanelId === 3
        ) {
          ACTIVITY_STATUS = currentStatus?.filter(
            (ele) =>
              ele.value === "17" ||
              ele.value === "18" ||
              ele.value === "19" ||
              ele.value === "20"
          );
        }
        if (history?.location?.pathname === "/app/admin/activity/csc/all" || chanelId === 2) {
          ACTIVITY_STATUS = currentStatus?.filter(
            (ele) =>
              ele.value === "9" ||
              ele.value === "10" ||
              ele.value === "11" ||
              ele.value === "12"
          );
        }
        if (
          history?.location?.pathname ===
          "/app/admin/campaignactivity/broca/all" || chanelId === 21
        ) {
          ACTIVITY_STATUS = currentStatus?.filter(
            (ele) =>
              ele.value === "33" ||
              ele.value === "34" ||
              ele.value === "35" ||
              ele.value === "36"
          );
        }
        if (
          chanelId === 13
        ) {
          ACTIVITY_STATUS = currentStatus?.filter(
            (ele) =>
              ele.value === "25" ||
              ele.value === "26" ||
              ele.value === "27" ||
              ele.value === "28"
          );
        }

        if (
          history?.location?.pathname ===
          "/app/report/activity/all" && chanelId === 19
        ) {
          ACTIVITY_STATUS = currentStatus?.filter(
            (ele) =>
              ele.value === "47" ||
              ele.value === "48" ||
              ele.value === "49" ||
              ele.value === "50"
          );
        }
        let arr_status = ACTIVITY_STATUS;
        item.options = getDropdownData(arr_status, "label", "label", "value");
      }

      return item;
    });
  };
  const resetFilters = (anchor) => {
    filterFormData[11].hide = true;
    filterFormData[12].hide = true;
    filterFormData[14].hide = true;
    filterFormData[15].hide = true;
    filterFormData[1].hide = true;
    filterFormData[2].hide = true;
    filterFormData[3].hide = true;
    filterFormData[4].hide = true;
    filterFormData[5].hide = true;
    filterFormData[6].hide = true;
    filterFormData[7].hide = true;
    filterFormData[14].min = getBackThreeMonth;
    clearSetFilterBy();
    const payload = {
      CreateStartDate: null,
      CreateEndDate: null,
      MeetingStartDate: null,
      MeetingEndDate: null,
      CurrentStatus: isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
      ActivityName: null,
      ActivityType: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
      UserId: null,
      BranchId: null,
      CampaignName: null,
      EmployeeCode: null,
    };
    setRowPerPage(20);
    pageReset();
    if (isOnline) {
      if (userState.profileInfo.roleId !== "ADM") {
        payload.UserId = userState.profileInfo.userId;
      }
      fetchActivityTableData(payload, chanelId, 1, 20);
      setSelectedRows([]);
    }
    setFilterState({
      activityType: "null",
      branchNameAndCode: "",
      createdOn: "null",
      meetingOn: "null",
      activityStatus: null,
      lastUpdateDate: "null",
      CreatedDateStartTIme: "null",
      CreatedDateEndTIme: "null",
      fromdate: "null",
      todate: "null",
      LeadTypeID: [],
      CampaignName: "null",
      UserID: null,
      NhUsers: [],
      RBHUsers: [],
      newUserList: [],
      teamMember: null,
      User: null,
      UserOne: null,
      UserTwo: null,
      UserThree: null,
      UserFour: null,
      EmployeeCode: null,
    });
    setActivityReq({
      CreateStartDate: null,
      CreateEndDate: null,
      MeetingStartDate: null,
      MeetingEndDate: null,
      CurrentStatus: isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
      ActivityName: null,
      ActivityType: isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
      UserId: payload.UserId !== null ? payload.UserId : null,
      BranchId: null,
      CampaignName: null,
      EmployeeCode: null,
    });
  };
  const pageReset = () => {
    setInitialPageNo(0);
  };
  const onAutocompleteSelectHandler = (key, value, item) => { };
  const columns = [
    {
      title: "Created Date ",
      field: "CreatedDate",
      type: "date",
      sorting: true,
      render: (rowData) => moment(rowData.CreatedDate).format("DD/MM/YYYY"),
    },
    {
      title: "Meeting Date ",
      field: "MeetingDate",
      type: "date",
      sorting: true,
    },
    { title: "Current Status", field: "CurrentStatus", sorting: true },
    { title: "Assigned To", field: "Assignedto", sorting: true },
    {
      title: "Last Updated date ",
      field: "LastUpdatedDate",
      type: "date",
      sorting: true,
    },
    { title: "Activity Name", field: "ActivityName", sorting: true },
    { title: "Activity Type", field: "ActivityType", sorting: true },
  ];
  const onSubmitHandler = (model, anchor) => {
    clearSetFilterBy();
    const {
      activityType,
      branchNameAndCode,
      createdOn,
      meetingOn,
      LeadTypeID,
      CampaignName,
      EmployeeCode,
    } = model;
    const activity_status = LeadTypeID?.map((val) => {
      return val.value;
    });
    let ActivityMultiStausts = activity_status?.join(",");
    if (ActivityMultiStausts === "" || ActivityMultiStausts === "undefined") {
      ActivityMultiStausts = "null";
    }
    let meetingstart = null;
    let meetingend = null;
    let createdstart = null;
    let createdend = null;
    if (
      meetingOn !== "null" &&
      meetingOn !== "duration" &&
      meetingOn !== undefined
    ) {
      const lastdatearr = model.meetingOn.split(",");
      meetingstart = lastdatearr[0] ? lastdatearr[0] : null;
      meetingend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (
      createdOn !== "null" &&
      createdOn !== "duration" &&
      createdOn !== undefined
    ) {
      const lastdatearr = model.createdOn.split(",");
      createdstart = lastdatearr[0] ? lastdatearr[0] : null;
      createdend = lastdatearr[1] ? lastdatearr[1] : null;
    }
    if (meetingOn === "duration") {
      if (model.fromdate === "null" || model.todate === "null") {
        dispatch(
          props.activateErrorMessageBar(
            "Please Select Value For Both Meeting Start Date and End Date"
          )
        );
        return false;
      }
      meetingstart = model.fromdate;
      meetingend = model.todate;
    }
    if (createdOn === "duration") {
      if (
        model.CreatedDateStartTIme === "null" ||
        model.CreatedDateEndTIme === "null"
      ) {
        dispatch(
          props.activateErrorMessageBar(
            "Please Select Value For Both Created Start Date and End Date"
          )
        );
        return false;
      }
      createdstart = model.CreatedDateStartTIme;
      createdend = model.CreatedDateEndTIme;
    }
    model.fromdate = meetingstart;
    model.todate = meetingend;
    model.CreatedDateStartTIme = createdstart;
    model.CreatedDateEndTIme = createdend;
    if (
      activityType !== "" ||
      branchNameAndCode !== 0 ||
      createdOn !== "null" ||
      meetingOn !== "null" ||
      ActivityMultiStausts !== "null"
      // CampaignName !== "null"
    ) {
      const payload = {
        UserId:
          model.UserID !== null
            ? model.UserID
            : userState.profileInfo.roleId !== "ADM"
              ? userState.profileInfo.userId
              : null,
        CreateStartDate:
          model.CreatedDateStartTIme !== "null"
            ? model.CreatedDateStartTIme
            : null,
        CreateEndDate:
          model.CreatedDateEndTIme !== "null" ? model.CreatedDateEndTIme : null,
        MeetingStartDate: model.fromdate !== "null" ? model.fromdate : null,
        MeetingEndDate: model.todate !== "null" ? model.todate : null,
        BranchId: branchNameAndCode ? branchNameAndCode : null,
        CampaignName: CampaignName !== "null" ? CampaignName : null,
        ActivityType:
          activityType && activityType != "null"
            ? activityType
            : isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
        CurrentStatus:
          ActivityMultiStausts !== "null"
            ? ActivityMultiStausts
            : isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
        EmployeeCode: EmployeeCode ? EmployeeCode : null,
      };
      setRowPerPage(20);
      if (isOnline) {
        fetchActivityTableData(payload, props.ChannelId, 1, 20);
        setSelectedRows([])
      }
      setFilterState({
        UserId: model.UserID,
        activityType: activityType,
        branchNameAndCode: branchNameAndCode,
        createdOn: createdOn,
        meetingOn: meetingOn,
        activityStatus:
          ActivityMultiStausts !== "null"
            ? ActivityMultiStausts
            : isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
        fromdate: model.fromdate,
        todate: model.todate,
        CreatedDateStartTIme: model.CreatedDateStartTIme,
        CreatedDateEndTIme: model.CreatedDateEndTIme,
        CampaignName: CampaignName,
        EmployeeCode: EmployeeCode,
      });
      if (userState.profileInfo.roleId !== "ADM") {
        payload.UserId = userState.profileInfo.userId;
        // setActivityReq({
        //   ...activityReq,
        //   UserId: userState.profileInfo.userId,
        // });
      }
      setActivityReq({
        UserId:
          model.UserID !== null
            ? model.UserID
            : userState.profileInfo.roleId !== "ADM"
              ? userState.profileInfo.userId
              : null,
        // filterRequest.UserID !== null
        // ? filterRequest.UserID
        // : userState.profileInfo.roleId !== "ADM"
        // ? userState.profileInfo.userId
        // : null,
        CreateStartDate:
          model.CreatedDateStartTIme !== "null"
            ? model.CreatedDateStartTIme
            : null,
        CreateEndDate:
          model.CreatedDateEndTIme !== "null" ? model.CreatedDateEndTIme : null,
        MeetingStartDate: model.fromdate !== "null" ? model.fromdate : null,
        MeetingEndDate: model.todate !== "null" ? model.todate : null,
        BranchId: branchNameAndCode ? branchNameAndCode : null,
        CampaignName: CampaignName !== "null" ? CampaignName : null,
        ActivityType:
          activityType && activityType != "null"
            ? activityType
            : isForSMA ? smaActivitytypeidpayload : Activitytypeidpayload,
        CurrentStatus:
          ActivityMultiStausts !== "null"
            ? ActivityMultiStausts
            : isForSMA ? samActivityStatusIdpayload : ActivityStatusIdpayload,
        EmployeeCode: EmployeeCode ? EmployeeCode : null,
      });
      if (anchor) {
        setStateSelect({ ...stateSelect, [anchor]: false });
      }
    } else {
      props.activateErrorMessageBar("Please apply atleast any one option");
    }
  };

  const onFilterChange = async (event, key) => {
    switch (key) {
      case "createdOn":
        if (event.target.value === "duration") {
          filterFormData[11].hide = false;
          filterFormData[12].hide = false;
        } else {
          filterFormData[11].hide = true;
          filterFormData[12].hide = true;
        }
        break;

      case "meetingOn":
        if (event.target.value === "duration") {
          filterFormData[14].hide = false;
          filterFormData[15].hide = false;
        } else {
          filterFormData[14].hide = true;
          filterFormData[15].hide = true;
        }
        break;
      case "CreatedDateStartTIme":
        filterFormData[12].min = event.target.value;
        break;
      case "fromdate":
        filterFormData[14].min = event.target.value;
        break;
      case "userType":
        if (userState.profileInfo.roleId !== "ADM") {
          manageUserHeirarcheyForNonAdminUsers(
            event.target.value,
            key
          );
        }
        else {
          const getUserType = event.target.value;
          if (getUserType === "1") {
            setFilterState({
              ...filterState,
              teamMember: null,
              User: null,
              UserOne: null,
              UserTwo: null,
              UserThree: null,
              UserFour: null,
              UserID: null,
            });
            setActivityReq({
              ...activityReq,
              UserId: null,
            });
            filterFormData[1].hide = false;
            filterFormData[2].hide = true;
            filterFormData[3].hide = true;
            filterFormData[6].hide = true;
            filterFormData[4].hide = true;
            filterFormData[5].hide = true;
            filterFormData[7].hide = true;
          } else if (getUserType === "2") {
            setFilterState({
              ...filterState,
              EmployeeCode: null,
              UserID: null,
            });
            setActivityReq({
              ...activityReq,
              EmployeeCode: null,
            });
            filterFormData[2].hide = false;
            filterFormData[3].hide = true;
            filterFormData[1].hide = true;
            filterFormData[6].hide = true;
            filterFormData[4].hide = true;
            filterFormData[5].hide = true;
            filterFormData[7].hide = true;
          }
        }
        break;
      case "teamMember":
        
        const getUser = event.target.value;
        if (getUser === "Team") {
          if (isBroca) {
            filterFormData[5].hide = false;
          } else if (chanelId === 13) {
            filterFormData[6].hide = false;
          }else {
            filterFormData[3].hide = false;
          }
          
        } else {
          filterFormData[3].hide = true;
          filterFormData[4].hide = true;
          filterFormData[5].hide = true;
          filterFormData[6].hide = true;
          filterFormData[7].hide = true;
        }
        break;
      case "EmployeeCode":
        setFilterState({
          ...filterState,
          EmployeeCode: event.target.value,
          teamMember: 0,
          User: null,
          UserID: 0,
        });
        break;
      case "User":
        props.activateLoader();
        setFilterState({
          ...filterState,
          UserID: event.target.value,
        });
        const getUserNH = event.target.value;
        setUserID(getUserNH);
        console.log("last user's user id is:", UserID);
        await getSub("User", getUserNH);
        if (getUserNH) {
          filterFormData[4].hide = false;
          filterFormData[5].hide = true;
          filterFormData[6].hide = true;
          filterFormData[7].hide = true;
        }
        break;
      case "UserOne":
        props.activateLoader();
        setFilterState({
          ...filterState,
          UserID: event.target.value,
        });
        const getUserZBH = event.target.value;
        setUserID(getUserZBH);
        await getSub("UserOne", getUserZBH);
        console.log("last user's user id is:", UserID);
        if (getUserZBH) {
          if (chanelId === 2) {
            props.deActivateLoader();
            filterFormData[6].hide = false;
            filterFormData[5].hide = true;
          } else {
            props.deActivateLoader();
            filterFormData[5].hide = false;
            filterFormData[6].hide = true;
            filterFormData[7].hide = true;
          }
        }
        break;
      case "UserTwo":
        props.activateLoader();
        setFilterState({
          ...filterState,
          UserID: event.target.value,
        });
        const getUserRBH = event.target.value;
        setUserID(getUserRBH);
        console.log("last user's user id is:", UserID);
        await getSub("UserTwo", getUserRBH);
        if (getUserRBH) {
          filterFormData[6].hide = false;
        }
        break;
      case "UserThree":
        props.activateLoader();
        setFilterState({
          ...filterState,
          UserID: event.target.value,
        });
        const getUserABH = event.target.value;
        setUserID(getUserABH);
        console.log("last user's user id is:", UserID);
        await getSub("UserThree", getUserABH);
        if (getUserABH) {
          if (isSMARoute) {
            filterFormData[7].hide = true;
          } else {
            filterFormData[7].hide = false;
          }
        }
        break;
      case "UserFour":
        setFilterState({
          ...filterState,
          UserID: event.target.value,
        });
        const getUserADM = event.target.value;
        setUserID(getUserADM);
        console.log("last user's user id is:", UserID);
        break;
      default:
        break;
    }
  };
  const manageUserHeirarcheyForNonAdminUsers = async (
    userId,
    key
  ) => {
    props.activateLoader();
    console.log("filterFormData ", filterFormData);
    // if user is non admin, set filter level accordingly
    if (userState.profileInfo.roleId !== "ADM") {
      const userRole = userState.profileInfo.roleId;
      switch (userRole) {
        case "BDM":
          console.log("inside of ", userRole);
          break;

        case "ABH":
          console.log("inside of ", userRole);
          setUserID(userState.profileInfo.userId);
          await getSub("UserThree", userState.profileInfo.userId);
          if (userState.profileInfo.userId) {
            filterFormData[7].hide = false;
            filterFormData[6].hide = true;
            filterFormData[5].hide = true;
            filterFormData[4].hide = true;
            filterFormData[2].hide = true;
          }
          break;
        case "RBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("UserTwo", userState.profileInfo.userId);
          filterFormData[7].hide = false;
          filterFormData[6].hide = false;
          filterFormData[5].hide = true;
          filterFormData[4].hide = true;
          filterFormData[2].hide = true;
          break;
        case "ZBH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("UserOne", userState.profileInfo.userId);
          filterFormData[7].hide = false;
          filterFormData[6].hide = false;
          if (chanelId === 2) {
            filterFormData[5].hide = true;
          } else {
            filterFormData[5].hide = false;
          }
          filterFormData[4].hide = true;
          filterFormData[2].hide = true;
          break;
        case "NH":
          console.log("inside of ", userRole, userId);
          setUserID(userState.profileInfo.userId);
          await getSub("User", userState.profileInfo.userId);
          filterFormData[7].hide = false;
          filterFormData[6].hide = false;
          if (chanelId === 2) {
            filterFormData[5].hide = true;
          } else {
            filterFormData[5].hide = false;
          }
          filterFormData[4].hide = false;
          filterFormData[2].hide = true;
          break;
        default:
          break;
      }
    }
  };
  const csvExp = async () => {
    console.log("sel row data", selectedRows);
    const IDs = [];
    if (selectedRows.length > 0) {
      const rows = selectedRows;
      if (isSMARoute) {
        for (const key in rows) {
          console.log("rows data is", rows[key].TrnActivityID);
          IDs.push(rows[key].TrnActivityID);
        }
      } else {
        for (const key in rows) {
          console.log("rows data is", rows[key].CampaignActivityid);
          IDs.push(rows[key].CampaignActivityid);
        }
      }
      let ids = IDs.map((id) => id).join(",");
      console.log("idss", ids);
      let storedIds = ids.toString();
      try {
        props.activateLoader();
        let downloadRes;
        if (isForSMA) {
          downloadRes = await props.downloadAbhSmaActivitiesWithId({
            ChannelId: chanelId,
            ids: storedIds,
          });
        } else if (chanelId == 21) {
          const payload = {
            filterReq: { ...activityReq, ids: storedIds },
          };
          downloadRes = await props.downloadBrocaActivities(
            { ChannelId: props.ChannelId },
            payload.filterReq
          );
        }else if (chanelId == 13) {
          // const payload = {
          //   filterReq: { ...activityReq, ids: storedIds },
          // };
          downloadRes = await props.downloadGCLCampaignActivitiesWithId(
            {  ChannelId: chanelId,
              ids: storedIds },
            );
        } else {
          downloadRes = await props.downloadCampaignActivitiesWithId({
            ChannelId: chanelId,
            ids: storedIds,
          });
        }
        console.log("ids apis res is", downloadRes);
        downloadFilesAdmin(downloadRes, props.fileName);
        props.deActivateLoader();
      }
      catch (err) {
        props.deActivateLoader();
      }
    } else {
      const payload = {
        filterReq: { ...activityReq },
      };

      try {
        props.activateLoader();
        let downloadData;
        if (isForSMA) {
          downloadData = await props.downloadAbhSmaActivities(
            { ChannelId: props.ChannelId },
            payload.filterReq
          );
        } else if (chanelId == 21) {
          downloadData = await props.downloadBrocaActivities(
            { ChannelId: props.ChannelId },
            payload.filterReq
          );
        }else if (chanelId == 13) {
          downloadData = await props.downloadGCLActivities(
            { ChannelId: props.ChannelId },
            payload.filterReq
          );
        } else {
          downloadData = await props.downloadCampaignActivities(
            { ChannelId: props.ChannelId },
            payload.filterReq
          );
        }
        if (downloadData.status === 200) {
          props.deActivateLoader();
          downloadFilesAdmin(downloadData, props.fileName);
        } else {
          props.deActivateLoader();
        }
      } catch (err) {
        props.deActivateLoader();
      }
    }
  };

  const isShowPopup = (status) => {
    setShowModalPopup(status);
    if (successMsg !== "") {
      setSuccessMsg("");
    }
  };
  const handleChangeRowPer = async (e) => {
    setRowPerPage(e.target.value);
    const payload = {
      filterReq: { ...activityReq },
    };
    if (isOnline) {
      props.activateLoader();
      let result;
      if (isSMARoute) {
        result = await props.getSalesMangmentActivityListing(
          payload.filterReq,
          props.ChannelId,
          1,
          e.target.value
        )
      } else {
        result = await props.getCampaignActivityListing(
          payload.filterReq,
          props.ChannelId,
          1,
          e.target.value
        );
      }
      if (result.status === 200) {
        var responseData = [];
        for (
          let i = 0;
          i < result.data.Getallcampaignactivitieslistbychannel.length;
          i++
        ) {
          if (
            result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate !==
            null
          ) {
            result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate =
              moment(
                result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate
              ).format("DD/MM/YYYY");
          }
          if (
            result.data.Getallcampaignactivitieslistbychannel[i]
              .LastUpdatedDate !== null
          ) {
            result.data.Getallcampaignactivitieslistbychannel[
              i
            ].LastUpdatedDate = moment(
              result.data.Getallcampaignactivitieslistbychannel[i]
                .LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          responseData.push(
            result.data.Getallcampaignactivitieslistbychannel[i]
          );
        }
        props.deActivateLoader();
        setActivitiesByUserTable(responseData);
        console.log(
          "result.data.Getallcampaignactivitieslistbychannel ",
          result.data.Getallcampaignactivitieslistbychannel
        );
        setRowChangeEvent(e.target.value);
        setTotalCount(result.data.TotalnoofRows);
        setSelectedRows([]);
        console.log("Activities are ", activitiesByUserTable);
      } else {
        props.deActivateLoader();
      }
    }
  };

  const onChildMount = React.useCallback((dataFromChild) => {
    setInitialPageNo(dataFromChild);
  });
  const onChangePage = async (e, page) => {
    const payload = {
      filterReq: { ...activityReq },
    };
    if (isOnline) {
      props.activateLoader();
      let result;
      if (isSMARoute) {
        result = await props.getSalesMangmentActivityListing(
          payload.filterReq,
          props.ChannelId,
          page + 1,
          rowPerPage
        )
      } else {
        result = await props.getCampaignActivityListing(
          payload.filterReq,
          props.ChannelId,
          page + 1,
          rowPerPage
        );
      }
      if (result.status === 200) {
        var responseData = [];
        for (
          let i = 0;
          i < result.data.Getallcampaignactivitieslistbychannel.length;
          i++
        ) {
          if (
            result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate !==
            null
          ) {
            result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate =
              moment(
                result.data.Getallcampaignactivitieslistbychannel[i].MeetingDate
              ).format("DD/MM/YYYY");
          }
          if (
            result.data.Getallcampaignactivitieslistbychannel[i]
              .LastUpdatedDate !== null
          ) {
            result.data.Getallcampaignactivitieslistbychannel[
              i
            ].LastUpdatedDate = moment(
              result.data.Getallcampaignactivitieslistbychannel[i]
                .LastUpdatedDate
            ).format("DD/MM/YYYY");
          }
          // result.data.Listofleads[i].Assignedto= result.data.Listofleads[i].Assignedto +result.data.Listofleads[i].CurrenStatus
          responseData.push(
            result.data.Getallcampaignactivitieslistbychannel[i]
          );
        }
        props.deActivateLoader();
        setActivitiesByUserTable(responseData);
        console.log(
          "result.data.Getallcampaignactivitieslistbychannel ",
          result.data.Getallcampaignactivitieslistbychannel
        );
        setTotalCount(result.data.TotalnoofRows);
        setSelectedRows([]);
        console.log("Activities are ", activitiesByUserTable);
      } else {
        props.deActivateLoader();
      }
    }
  };

  const rowOnRowclick = (e, row) => {
    const activityId = row.CampaignActivityid;
    const TrnActivityID = row.TrnActivityID;
    const campaign = row.CampaignName;
    console.log("row clicked ", row);
    if (userState.profileInfo.roleId === "ADM") {
      if (campaign !== "") {
        history.push({
          pathname: `/app/campaignactivity/details/${activityId}`,
          campaign: campaign,
        });
      } else {
        if (isSMARoute) {
          history.push({
            pathname: `/app/activity/details/${TrnActivityID}`,
            campaign: campaign,
          });
        } else {
          history.push({
            pathname: `/app/activity/details/${activityId}`,
            campaign: campaign,
          });
        }
      }
    } else {
      if (isSMARoute) {
        history.push(`/app/activity/detail/${TrnActivityID}`);
      } else {
        history.push(`/app/activity/detail/${activityId}`);
      }
    }
  };
  return (
    <>
      {isBrowser && (
        <div className="activityList">
          <TabPanel value={tabValue} index={0}>
            <Grid
              className="activiPad0"
              container
              spacing={2}
              columns={{ xs: 4, md: 12 }}
            >
              <Grid item xs={12} md={9} className="leftCol">
                <div className="admindashCompain">
                  <div className="float-right">
                    <button className="btn btn-primary" onClick={csvExp}>
                      <span className="fa fa-download">&nbsp;</span> Download
                      List
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    {chanelId !== 21 && chanelId !== 13 && chanelId !== 2 && roleId === "ADM" && !isSMARoute && (
                      <button
                        className="btn btn-primary"
                        onClick={() => isShowPopup(true)}
                      >
                        <span className="fa fa-upload">&nbsp;</span> Bulk Upload
                      </button>
                    )}
                  </div>
                  <div className="clearfix"></div>
                  <DataGridModalPopup
                    showModalPopup={showModalPopup}
                    onPopupClose={isShowPopup}
                    id={props.campaignactivityid}
                    filetype={props.filetype}
                  ></DataGridModalPopup>
                  <MatDatagrid
                    rows={activitiesByUserTable ? activitiesByUserTable : []}
                    columns={columns}
                    props={{ labelRowsSelect: "records" }}
                    search={false}
                    grouping={false} //to show or hide grouping
                    addRowPosition={"first"} //"last"
                    pageSize={rowPerPage}
                    paging={false}
                    pageSizeOptions={[15, 20, 25, 50]}
                    onSelectionChange={(rows) => setSelectedRows(rows)}
                    treeView={false}
                    selection={true}
                    filtering={false}
                    title={props.Listingtitle}
                    showFirstLastPageButtons={false}
                    paginationType={"normal"} //"normal" or "stepped"
                    exportButton={false}
                    exportAllData={true}
                    onRowClick={rowOnRowclick}
                    customPagination={{
                      isDisplay: true,
                      totalCount: totalCount,
                    }}
                    editable={{
                      showDelete: false,
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: " ",
                      },
                    }}
                  />
                </div>
                <div className="float-right">
                  {totalCount && totalCount > 9 ? (
                    <>
                      <label for="selectRecordsPerPage">
                        Records per Page: &nbsp;{" "}
                      </label>
                      <Select
                        value={rowPerPage}
                        onChange={(e) => handleChangeRowPer(e)}
                      >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="clearfix">&nbsp;</div>
                {totalCount != 0 ? (
                  <Paginator
                    onMount={onChildMount}
                    rowCount={rowChangeEvent}
                    className="pagination"
                    totalRecoards={totalCount}
                    recordsPerPage={rowPerPage}
                    onPageChange={onChangePage}
                    maxPageLimit={10}
                    initialPage={initialPageNo}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={3} className="rightCol">
                <div className={classes.desktopFilter}>
                  <div className="filterBox">
                    <h4 className="filterSec">
                      {" "}
                      <FilterAltIcon className="icnFilter" /> Filter By
                    </h4>
                    <DynamicForm
                      key={"filterId"}
                      title={" "}
                      buttonSubmit={"APPLY FILTER"}
                      defaultValues={filterState}
                      model={filterFormData}
                      onSubmit={(model) => {
                        onSubmitHandler(model);
                      }}
                      disableSubmitOnInValid={false}
                      design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
                      materialOutline={false}
                      onChange={(event, key) => onFilterChange(event, key)}
                      onAutocompleteSelect={onAutocompleteSelectHandler}
                      showAstersikOnRequiredFields={true}
                      className="activityFilters"
                      extaraActionFormButton={
                        <span className="reset" onClick={resetFilters}>
                          <RestartAltIcon /> Reset
                        </span>
                      }
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
        </div>
      )}
      {isMobile && (
        <ModalPopup
          show={showModal}
          size={"sm"} // "lg","sm" or "xl"
          centered={true} // vertically center the Dialog in the window
          modelHeader={"Invalid Selection"} // to show model header
          modelContent={
            <div className="footerButtons">
              <p>Please view in Desktop/Laptop</p>
              {/* <Button onClick={()=>setShowModal(false)}>OK</Button> */}
            </div>
          }
          backdrop={"static"}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  masterDataState: state.commonReducer,
});
export default withTranslation("translation", { withRef: true })(
  connect(mapStateToProps, {
    getCampaignActivityListing,
    getSalesMangmentActivityListing,
    activateLoader,
    deActivateLoader,
    getSubordinateList,
    activateErrorMessageBar,
    setFilterBy,
    getMasterDataForAdminByChannelId,
    getCampaignName,
    NHList,
    downloadCampaignActivities,
    downloadBrocaActivities,
    downloadCampaignActivitiesWithId,
    downloadAbhSmaActivitiesWithId,
    downloadAbhSmaActivities,
    getFilterByLevel,
    downloadGCLActivities,
    downloadGCLCampaignActivitiesWithId
  })(ActivityListing)
);
