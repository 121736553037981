import { format } from 'date-fns';
import { ALLOWED_FUTURE_DAYS } from '../../../../utils/config';
import { dateFormat, getDateAfterDays } from '../../../../utils/utils';
const stateLabel = "State";
const AddressValidation = "Valid address is required";
const stateName = "State name is required.";
const PlannedForNoOfPeople = "Planned for No. of people";
const TrainingTopicLabel = "Topic for Training";
const topicRequired = "Topic is required";
const max30char = "Max 30 characters allowed."
const min10char = "Min 10 characters allowed."
const _format = 'yyyy-MM-dd';

const mindateFormat = format(new Date(), _format) + "T" + format(new Date(), "kk:mm");
const _dateTimeLocal = "datetime-local";
const _meetingStartTime = "Meeting Start Date and Time";
const _meetingEndTime = "Meeting End Date and Time";
const _meetingStartEndTimeValidator = "Meeting Start Date and Time is required.";
const _meetingEndTimeValidator = "Meeting End Date and Time is required.";
const currentAddLabel = "Current Address:";
const max3digit ="Max 3 digits allowed.";
const min1digit ="Min 1 digits allowed";
const Location = "location is required";
const PlannedforNoofpeopleValidation = "Planned for No. of people is required.";
const faMapMarker = "fa fa-map-marker";


export const CscTrainingForm = [
	{
		"key": "TrainingFor", "type": "select", "disabled": false,
		"id": "TrainingFor", "label": "Training for",
		"validators": [{ "name": "required", "value": true, "validationMessage": "Entity being trained is required." }], "options": []
	},
]

export const CscTrainingStateHead = [
	{
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": stateName }], "options": []
	},
	{
		"key": "Address",
		"type": "text", "disabled": false, "hide": false,
		"id": "Address", "label": "Address",
		"validators": [{ "name": "required", "value": false, "validationMessage":AddressValidation }]
	},
	{
		"key": "TrainingTopic",
		"type": "text", "disabled": false, "hide": false,
		"id": "TrainingTopic", "label": TrainingTopicLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": topicRequired },
        { "name": "maxLength", "value": 30, "validationMessage": max30char },
        { "name": "minLength", "value": 10, "validationMessage": min10char }]
	},
	{
		"key": "MeetingStartDateTime",
		"min": mindateFormat,
		"hide": false, "type":_dateTimeLocal, "disabled": false,
		"id": "MeetingStartDateTime", "label": _meetingStartTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "MeetingEndDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "Location", "locationIcon": faMapMarker, "label": "Location",
		"type": "checkinField", "name": "address", "locationLabel": currentAddLabel,
		"okayButtonText": "Submit", "cancelButtonText": "Cancel", "id": "Location",
		"validators": [{ "name": "required", "value": true, "validationMessage": "Location is required." }]
	},
	{
		"key": "PlannedForNoOfPeople", "hide": false, "type": "number", "disabled": false,
		"id": "PlannedForNoOfPeople", "label": PlannedForNoOfPeople,
		"validators": [{ "name": "required", "value": true, "validationMessage": PlannedforNoofpeopleValidation },
        { "name": "maxLength", "value": 3, "validationMessage": max3digit },
        { "name": "minLength", "value": 1, "validationMessage": min1digit }]
	},
]

export const CscTrainingStateManager = [
	{
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": stateName}], "options": []
	},
	{
		"key": "Address",
		"type": "text", "disabled": false, "hide": false,
		"id": "Address", "label": "Address",
		"validators": [{ "name": "required", "value": false, "validationMessage": AddressValidation }]
	},
	{
		"key": "TrainingTopic",
		"type": "text", "disabled": false, "hide": false,
		"id": "TrainingTopic", "label": TrainingTopicLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": topicRequired },
        { "name": "maxLength", "value": 30, "validationMessage": max30char },
        { "name": "minLength", "value": 10, "validationMessage": min10char }]
	},
	{
		"key": "MeetingStartDateTime",
		"min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false,
		"id": "MeetingStartDateTime", "label": _meetingStartTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "MeetingEndDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "Location", "locationIcon": faMapMarker, "label": "Location",
		"type": "checkinField", "name": "address", "locationLabel": currentAddLabel,
		"okayButtonText": "Submit", "cancelButtonText": "Cancel", "id": "Location",
		"validators": [{ "name": "required", "value": true ,"validationMessage": Location }]
	},
	{
		"key": "PlannedForNoOfPeople", "hide": false, "type": "number", "disabled": false,
		"id": "PlannedForNoOfPeople", "label": PlannedForNoOfPeople,
		"validators": [{ "name": "required", "value": true, "validationMessage": PlannedforNoofpeopleValidation },
        { "name": "maxLength", "value": 3, "validationMessage": max3digit },
        { "name": "minLength", "value": 1, "validationMessage": min1digit }]
	},
]

export const CscTrainingDistrictManager = [
	{
		"key": "StateID", "type": "select", "id": "StateID", "label": stateLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": stateName }], "options": []
	},
	{
		"key": "DistrictID", "type": "select",
		"disabled": false, "hide": true,
		"id": "DistrictID", "label": "District Name",
		"validators": [{ "name": "required", "value": true, "validationMessage": "District name is required." }], "options": []
	},
	{
		"key": "Address", "type": "text",
		"disabled": false, "hide": false, "id": "Address", "label": "Address",
		"validators": [{ "name": "required", "value": false, "validationMessage":AddressValidation}]
	},
	{
		"key": "TrainingTopic", "type": "text",
		"disabled": false, "id": "TrainingTopic",
		"label": TrainingTopicLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": topicRequired },
        { "name": "maxLength", "value": 30, "validationMessage": max30char },
        { "name": "minLength", "value": 10, "validationMessage": min10char }]
	},
	{
		"key": "MeetingStartDateTime", "min": mindateFormat,
		"hide": false, "type":_dateTimeLocal, "disabled": false, "id": "MeetingStartDateTime", "label": _meetingStartTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator}],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "MeetingEndDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "Location", "locationIcon": faMapMarker, "label": "Location",
		"type": "checkinField", "name": "address", "locationLabel": currentAddLabel,
		"okayButtonText": "Submit", "cancelButtonText": "Cancel", "id": "Location",
		"validators": [{ "name": "required", "value": true ,"validationMessage": Location }]
	},
	{
		"key": "PlannedForNoOfPeople", "hide": false, "type": "number", "disabled": false,
		"id": "PlannedForNoOfPeople", "label": PlannedForNoOfPeople,
		"validators": [{ "name": "required", "value": true, "validationMessage": PlannedforNoofpeopleValidation },
        { "name": "maxLength", "value": 3, "validationMessage": max3digit },
        { "name": "minLength", "value": 1, "validationMessage": min1digit }]
	},
]

export const CscTrainingRAPVLE = [
	{
		"key": "Address", "type": "text", "disabled": false, "hide": false, "id": "Address", "label": "Address",
		"validators": [{ "name": "required", "value": false, "validationMessage": AddressValidation }]
	},
	{
		"key": "TrainingTopic", "type": "text", "disabled": false, "hide": false, "id": "TrainingTopic", "label": TrainingTopicLabel,
		"validators": [{ "name": "required", "value": true, "validationMessage": topicRequired },
        { "name": "maxLength", "value": 30, "validationMessage": max30char },
        { "name": "minLength", "value": 10, "validationMessage": min10char }]
	},
	{
		"key": "MeetingStartDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingStartDateTime", "label": _meetingStartTime,
		"validators": [{ "name": "required", "value": true, "validationMessage": _meetingStartEndTimeValidator}],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "MeetingEndDateTime", "min": mindateFormat,
		"hide": false, "type": _dateTimeLocal, "disabled": false, "id": "MeetingEndDateTime", "label": _meetingEndTime,
		"validators": [{ "name": "required", "value": true, "validationMessage":_meetingEndTimeValidator }],
		"max": format(getDateAfterDays(ALLOWED_FUTURE_DAYS), dateFormat) + "T" + "23:59"
	},
	{
		"key": "Location", "locationIcon": faMapMarker, "label": "Meeting Location", "hide": false,
		"type": "checkinField", "name": "address", "locationLabel": currentAddLabel, "okayButtonText": "Submit", "cancelButtonText": "Cancel",
		"id": "Location", "validators": [{ "name": "required", "value": true, "validationMessage": Location }]
	},
	{
		"key": "PlannedForNoOfPeople", "hide": false, "type": "number", "disabled": false,
		"id": "PlannedForNoOfPeople", "label": PlannedForNoOfPeople,
		"validators": [{ "name": "required", "value": true, "validationMessage": PlannedforNoofpeopleValidation },
        { "name": "maxLength", "value": 3, "validationMessage": max3digit },
        { "name": "minLength", "value": 1, "validationMessage": min1digit }]
	},
]
