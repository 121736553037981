/**
 * @author Kiran Mohare
 * @email kiran.mohare@fulcrumdigital.com
 * @create date 2022-03-22 10:22:48
 * @modify date 2022-03-22 10:22:48
 * @desc [description]
 */

import {
  addUserData,
  addLastSyncTime,
  getUserSession,
  getLastSyncByKey,
  getConfigData,
} from "./localbase";

export const saveUserInfo = (userInfo) => {
  return new Promise((resolve, reject) => {
    addUserData(userInfo)
      .then((res) => resolve(true))
      .catch((err) => reject(false));
  });
};
export const addUpdateLastUserSyncTime = (syncTimeMili) => {
  const syncTime = {
    syncTime: syncTimeMili,
  };
  return addLastSyncTime(syncTime, "userSyncTime");
};
export const addUpdateLastCacheSyncTime = (syncTimeMili) => {
  const syncTime = {
    syncTime: syncTimeMili,
  };
  return addLastSyncTime(syncTime, "cacheSyncTime");
};
export const getCacheSyncTime = () => {
  return getLastSyncByKey("cacheSyncTime")
    .then((res) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};
export const addUpdateAppVersionInfo = (version) => {
  const appVersionInfo = {
    appVersionInfo: version,
  };
  return addLastSyncTime(appVersionInfo, "currentInsatlledAppVersion");
};
export const getCurrentInsatlledAppVersion = () => {
  return getLastSyncByKey("currentInsatlledAppVersion")
    .then((res) => {
      if (res) {
        return res;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
};
export const isOfflineSessionValid = () => {
  return getUserSession();
};
export const validateUserOfflineSession = () => {
  let isValid = false;
  return getLastSyncByKey("userSyncTime")
    .then((res) => {
      if (res) {
        const userSyncTime = res.syncTime;
        return getConfigData("configdata").then((result) => {
          if (result) {
            const userOfflineLoginSession =
              result.configData.userOfflineLoginSession;
            const numberOfMillisecondsSinceEpoch = Date.now();
            const sessionTime = numberOfMillisecondsSinceEpoch - userSyncTime;
            const minutesToMiliSeconds =
              parseInt(userOfflineLoginSession) * 60000;
            if (sessionTime > minutesToMiliSeconds) {
              isValid = false;
              return isValid;
            }
            isValid = true;
            return isValid;
          }
          isValid = true;
          return isValid;
        });
      } else {
        isValid = true;
        return isValid;
      }
    })
    .catch(() => {
      isValid = true;
      return isValid;
    });
};
export const getUserInfoDb = () => {
  return getUserSession();
};
export const isMasterSyncRequired = () => {
  let isValid = false;
  return getLastSyncByKey("masterDataSyncTime")
    .then((res) => {
      if (res) {
        const oldTime = res.syncTime;
        return getConfigData("configdata")
          .then((result) => {
            if (result) {
              let updatedTime = result.configData.masterDataSyncTime;
              updatedTime = parseInt(updatedTime);
              console.log("updatedTime > oldTime ", updatedTime > oldTime);
              if (updatedTime > oldTime) {
                isValid = true;
                return isValid;
              } else {
                isValid = false;
                return isValid;
              }
            }
            // isValid = true;
            // return isValid;
          })
          .catch(() => {
            isValid = false;
            return isValid;
          });
      } else {
        isValid = true;
        return isValid;
      }
    })
    .catch(() => {
      isValid = true;
      return isValid;
    });
};
