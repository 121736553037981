/**
 * @author [Arun Kumar]
 * @email [fdc0120@fulcrumdigital.com]
 * @create date 24-06-2022 18:44:10
 * @modify date 03-06-2022 10:44:10
 * @desc [description]
 */

import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
            Unless otherwise expressly set forth herein, any information, communication or material you transmit to this application including data, questions, comments, or suggestions will be treated as non-confidential and non-proprietary and will automatically become the property of IndiaFirst Life. By submitting and/or transmitting to this application any information, communication or material as mentioned above, you will be granting proprietary rights in such information, communication or material including but not limited to intellectual property rights under any relevant jurisdiction.<br></br> <br></br>Such information, communication or material may be used (subject to our Privacy Policy) for any purpose, including, but not limited to, reproduction, solicitations, disclosure, transmission, publication, broadcast, and posting. Furthermore, IndiaFirst Life is free to use any ideas, concepts, know-how, or techniques contained in any communication you send to this application for any purpose whatsoever, including, but not limited to, developing and marketing products using such information.
      </div>
    )
  }
}
