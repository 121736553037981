import { createSlice } from '@reduxjs/toolkit'
const campaignLeadListSlice = createSlice({
    name: "campaignLeadList",
    initialState: {
        campaignLeadListLoading: false,
        campaignLeadListError: false,
        allcampaignLeadList: {},
    },
    reducers: {
        campaignLeadListLoading: (state, action) => {
            state.campaignLeadListLoading = action.payload;
        },

        campaignLeadListSuccess: (state, action) => {
            state.allcampaignLeadList = action.payload;
            state.campaignLeadListLoading = false;
        },
        campaignLeadListError: (state, action) => {
            state.campaignLeadListError = action.payload;
            state.campaignLeadListLoading = false;
        },
        getFilteredCampaignLeads: (state, action) => {
            const campaignLead = {
                TotalnoofRows: action.payload.TotalnoofRows,
                Campaignleaddata: action.payload.Campaignleaddata,
                Noofpages: action.payload.Noofpages
            }
            state.allcampaignLeadList = campaignLead
        }
    }
});

export const { campaignLeadListLoading, campaignLeadListSuccess, campaignLeadListError, campaignLeadListLoadingEnd, getFilteredCampaignLeads
} = campaignLeadListSlice.actions
export default campaignLeadListSlice.reducer



