import {
  ACTIVITY_CANCELLED,
  ACTIVITY_COMPLETED,
  ACTIVITY_RESCHEDULED,
} from "../../../utils/config";


import {
  BankaSalesMangementRescheduledActivity,
  RescheduledActivitySchedule_BDM,
  RescheduledActivityBranchvisitplan,
  RescheduledActivityRegionalOfficemeeting,
  RescheduledActivitySalesTrainingplan,
  RescheduledActivityjointcallbdm,
  RescheduledActivityCircularforSalesEnablement,
  Rescheduled_SCHEDULE_STRUCTURED_REVIEW_ABH,
  Rescheduled_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM,
  Rescheduled_REGION_VISIT_WITH_ABH,
  Rescheduled_BRANCH_VISIT_WITH_BDM_AND_ABH,
  Rescheduled_JOINT_CALLS_WITH_CUSTOMER,
  Rescheduled_STRUCTURED_ZONAL_HEAD_MEETING,
} from "./updateSalesManagementActivityData/ActivityReschedule";

import {
  BankaSalesMangementCompletedActivity,
  CompletedActivitySchedule_BDM,
  CompletedActivityBranchvisitplan,
  CompletedActivityRegionalOfficemeeting,
  CompletedActivitySalesTrainingplan,
  CompletedActivityjointcallbdm,
  CompletedActivityCircularforSalesEnablement,
  Completed_SCHEDULE_STRUCTURED_REVIEW_ABH,
  Completed_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM,
  Completed_REGION_VISIT_WITH_ABH,
  Completed_BRANCH_VISIT_WITH_BDM_AND_ABH,
  Completed_JOINT_CALLS_WITH_CUSTOMER,
  Completed_STRUCTURED_ZONAL_HEAD_MEETING,
} from "./updateSalesManagementActivityData/ActivityCompleted";

import {
  BankaSalesMangementActivityCancel,
  CancelledActivitySchedule_BDM,
  CancelledActivityBranchvisitplan,
  CancelledActivityRegionalOfficemeeting,
  CancelledActivitySalesTrainingplan,
  CancelledActivityjointcallbdm,
  CancelledActivityCircularforSalesEnablement,
  Cancelled_SCHEDULE_STRUCTURED_REVIEW_ABH,
  Cancelled_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM,
  Cancelled_REGION_VISIT_WITH_ABH,
  Cancelled_BRANCH_VISIT_WITH_BDM_AND_ABH,
  Cancelled_JOINT_CALLS_WITH_CUSTOMER,
  Cancelled_STRUCTURED_ZONAL_HEAD_MEETING,
} from "./updateSalesManagementActivityData/ActivityCancel";

const array1 = ["Select","BDM Unavailable", "BDM On Leave", "I was on Leave", "Conflicting Meeting", "Unexpected Internal Meeting", "Other Reason"]

const array2  = ["Select","BDM Unavailable", "BDM On Leave", "Branch Manager Unavailable", "Branch Manager On Leave", "I was on Leave", "Conflicting Meeting", "Unexpected Internal Meeting", "Other Reason"]

const array3 = ["Select","BDM Unavailable", "BDM On Leave", "Customer Not Contactable","I was on Leave", "Conflicting Meeting", "Unexpected Internal Meeting", "Other Reason"]

const array4 = ["Select","Circular Not Published", "Other Reason"]

const travelExpenseLabel = "Travel Expense";
const travelExpenseValidationmessage = "Travel expense is required.";
const fourDigitAllowed = "Maximum 4 digits allowed";
const billPhoto = "Upload bill photo";
const uploadBill = "Please Upload Bill Copy";
const modeOftransportRequired = "mode of Trasport is required.";
const vehicleType = "Vehicle Type";

const acceptFileType = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

export const OtherReason = [{
  key: "Other_Reason",
  type: "text",
  id: "Other_Reason",
  label: "Other reason",
  validators: [
    {
      name: "required",
      value: true,
      validationMessage: "Please select reason",
    },
  ],
}];

export const ActivityCompletedFormPart2 = [
  {
    key: "TravelExpence",
    hide: false,
    type: "number",
    props: { min: "1" },
    disabled: false,
    id: "TravelExpence",
    label: travelExpenseLabel,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: travelExpenseValidationmessage,
      },
      { name: "maxLength", value: 4, validationMessage: fourDigitAllowed },
    ],
  },

  {
    key: "UploadBill",
    hide: false,
    type: "file",
    dropTitle: billPhoto,
    acceptFileTypes: acceptFileType,
    componentType: "DRAG_DROP_BROWSE",
    layout: "1column",
    restrictMultipleFiles: true,
    disabled: false,
    id: "UploadBill",
    label: uploadBill,
    validators: [],
  },
];

export const ActivityRescheduledFormPart3 = [
  {
    key: "VehicleTypeID",
    hide: false,
    type: "radio",
    disabled: false,
    id: "VehicleType",
    label: vehicleType,
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: modeOftransportRequired,
      },
    ],
    options: [],
  },
];

export const salesManagementRadio = [
  {
    key: "TransportModeID",
    type: "radio",
    label: "Mode Of transport",
    validators: [
      {
        name: "required",
        value: true,
        validationMessage: "please select transport mode",
      },
    ],
    options: [],
  },
];

export const getOptionById = (id, optionValues) => {
  return optionValues.find((item) => item.value === id);
};



export class SalesActivityFormBuilder {
  constructor(actionType, activityTypeForupdate,data,activityData,SmaActivityDuration,SmaReason,Reason) {
    this.actionType = actionType;
    this.activityTypeForupdate = activityTypeForupdate;
    this.data = data;
    this.activityData = activityData;
    this.SMAActivityDuration = SmaActivityDuration;
    this.SmaReason = SmaReason;
    this.Reason = Reason;
  }

  getFormTitle = () => {
    let formTitle = "";
    switch (this.actionType) {
      case ACTIVITY_RESCHEDULED:
        formTitle = "Activity Rescheduled";
        break;
      case ACTIVITY_CANCELLED:
        formTitle = "Activity Cancelled";
        break;
      case ACTIVITY_COMPLETED:
        formTitle = "Activity Completed";
        break;
      default:
        formTitle = "Activity Completed";
        break;
    }
    return formTitle;
  };

  getForm = () => {
    let form = [];
    switch (this.actionType) {
      case ACTIVITY_RESCHEDULED:
        switch (this.activityData) {
          case 26:
            form = RescheduledActivitySchedule_BDM;
            form[0].options=this.SmaReason.filter((ele)=>{
              return array1.includes(ele.label)
            })
            break;
          case 27:
            form = RescheduledActivityBranchvisitplan;
            form[0].options=this.SmaReason.filter((ele)=>{
              return array2.includes(ele.label)
            })
            break;
          case 28:
            form = RescheduledActivityRegionalOfficemeeting
            form[0].options=this.SmaReason.filter((ele)=>{
              return array2.includes(ele.label)
            })
            break;
          case 29:
            form = RescheduledActivitySalesTrainingplan
            form[0].options=this.SmaReason.filter((ele)=>{
              return array1.includes(ele.label)
            })
            break;
          case 30:
            form = RescheduledActivityjointcallbdm
            form[0].options=this.SmaReason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 31:
            form = RescheduledActivityCircularforSalesEnablement
            form[0].options=this.SmaReason.filter((ele)=>{
              return array4.includes(ele.label)
            })
            break;
          case 38:
            form = Rescheduled_SCHEDULE_STRUCTURED_REVIEW_ABH
            form[0].options=this.SmaReason.filter((ele)=>{
              return array1.includes(ele.label)
            })
            break;
          case 39:
          case 40:
            form = Rescheduled_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM
            form[0].options=this.SmaReason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 41:
            form = Rescheduled_REGION_VISIT_WITH_ABH
            form[0].options=this.SmaReason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 42:
            form = Rescheduled_BRANCH_VISIT_WITH_BDM_AND_ABH
            break;
          case 43:
            form = Rescheduled_JOINT_CALLS_WITH_CUSTOMER
            form[0].options=this.SmaReason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 44:
            form = Rescheduled_STRUCTURED_ZONAL_HEAD_MEETING
            break;
          default:
            form = RescheduledActivitySchedule_BDM;
            break;
        }
        break;
      case ACTIVITY_CANCELLED:
        switch (this.activityData) {
          case 26:
            form = CancelledActivitySchedule_BDM
            form[0].options=this.Reason.filter((ele)=>{
              return array1.includes(ele.label)
            })
            break;
          case 27:
            form = CancelledActivityBranchvisitplan
            form[0].options=this.Reason.filter((ele)=>{
              return array2.includes(ele.label)
            })
            break;
          case 28:
            form = CancelledActivityRegionalOfficemeeting
            form[0].options=this.Reason.filter((ele)=>{
              return array1.includes(ele.label)
            })
            break;
          case 29:
            form = CancelledActivitySalesTrainingplan
            form[0].options=this.Reason.filter((ele)=>{
              return array1.includes(ele.label)
            })
            break;
          case 30:
            form = CancelledActivityjointcallbdm
            form[0].options=this.Reason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 31:
            form = CancelledActivityCircularforSalesEnablement
            form[0].options=this.Reason.filter((ele)=>{
              return array4.includes(ele.label)
            })
            break;
          case 38:
            form = Cancelled_SCHEDULE_STRUCTURED_REVIEW_ABH
            form[0].options=this.Reason.filter((ele)=>{
              return array1.includes(ele.label)
            })
            break;
          case 39:
            form = Cancelled_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM
            form[0].options=this.Reason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 40:
            form = Cancelled_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM
            form[0].options=this.Reason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 41:
            form = Cancelled_REGION_VISIT_WITH_ABH
            form[0].options=this.Reason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 42:
            form = Cancelled_BRANCH_VISIT_WITH_BDM_AND_ABH
            break;
          case 43:
            form = Cancelled_JOINT_CALLS_WITH_CUSTOMER
            form[0].options=this.Reason.filter((ele)=>{
              return array3.includes(ele.label)
            })
            break;
          case 44:
            form = Cancelled_STRUCTURED_ZONAL_HEAD_MEETING
            break;
          default:
            form = BankaSalesMangementActivityCancel
            break;
        }
        break;
      case ACTIVITY_COMPLETED:
        switch (this.activityData) {
          case 26:
            const newEle = CompletedActivitySchedule_BDM.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration };
              } else {
                return val;
              }
            });
            form = newEle
            break;
          case 27:
            const newElePlan = CompletedActivityBranchvisitplan.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration };
              } else {
                return val;
              }
            });
            form = newElePlan
            break;
          case 28:
            const newEleMeeting = CompletedActivityRegionalOfficemeeting.map(
              (val) => {
                if (val.key == "ActivityDurationID") {
                  return { ...val, options: this.SMAActivityDuration };
                } else {
                  return val;
                }
              }
            );
            form = newEleMeeting
            break;
          case 29:
            const newEleTraining = CompletedActivitySalesTrainingplan.map(
              (val) => {
                if (val.key == "ActivityDurationID") {
                  return { ...val, options: this.SMAActivityDuration };
                } else {
                  return val;
                }
              }
            );
            form = newEleTraining
            break;
          case 30:
            const newEleJointCalls = CompletedActivityjointcallbdm.map(
              (val) => {
                if (val.key == "ActivityDurationID") {
                  return { ...val, options: this.SMAActivityDuration };
                } else {
                  return val;
                }
              }
            );
            form = newEleJointCalls
            break;
          case 31:
            form = CompletedActivityCircularforSalesEnablement
            break;
          case 38:
            const SCHEDULE_STRUCTURED_REVIEW_ABH = Completed_SCHEDULE_STRUCTURED_REVIEW_ABH.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration.slice(0,4) };
              } else {
                return val;
              }
            });
            form = SCHEDULE_STRUCTURED_REVIEW_ABH
            break;
          case 39:
            const MEETING_WITH_TOP_10_BDM = Completed_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration.slice(0,4) };
              } else {
                return val;
              }
            });
            form = MEETING_WITH_TOP_10_BDM
            break;
          case 40:
            const ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM = Completed_ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration.slice(0,4) };
              } else {
                return val;
              }
            });
            form = ONE_TO_ONE_REVIEW_WITH_BOTTOM_10_BDM
            break;
          case 41:
            const REGION_VISIT_WITH_ABH = Completed_REGION_VISIT_WITH_ABH.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration.slice(0,4) };
              } else {
                return val;
              }
            });
            form = REGION_VISIT_WITH_ABH
            break;
          case 42:
            const BRANCH_VISIT_WITH_BDM_AND_ABH = Completed_BRANCH_VISIT_WITH_BDM_AND_ABH.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration.slice(0,4) };
              } else {
                return val;
              }
            });
            form = BRANCH_VISIT_WITH_BDM_AND_ABH
            break;
          case 43:
            const JOINT_CALLS_WITH_CUSTOMER = Completed_JOINT_CALLS_WITH_CUSTOMER.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration.slice(0,4) };
              } else {
                return val;
              }
            });
            form = JOINT_CALLS_WITH_CUSTOMER
            break;
          case 44:
            const STRUCTURED_ZONAL_HEAD_MEETING = Completed_STRUCTURED_ZONAL_HEAD_MEETING.map((val) => {
              if (val.key == "ActivityDurationID") {
                return { ...val, options: this.SMAActivityDuration.slice(0,4) };
              } else {
                return val;
              }
            });
            form = STRUCTURED_ZONAL_HEAD_MEETING
            break;
          default:
            form = BankaSalesMangementCompletedActivity;
            break;
        }
        break;
      default:
        BankaSalesMangementRescheduledActivity[0].options = this.data
        form = BankaSalesMangementRescheduledActivity;
        break;
    }
    return form;
  };
}

